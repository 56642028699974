<h4>Introducer Payments</h4>

<table class="table table-striped">
  <thead>
    <tr>
      <th>Introducer Name</th>
      <th>Commission Paid</th>
      <th>Date Paid</th>
    </tr>
  </thead>

  <tbody>
    <tr>
      <td>{{ caseDetails?.introducer?.name }}</td>
      <td>{{ caseDetails?.introducerValue / 100 | toCurrency }}</td>
      <td>{{ caseDetails?.introducerPaymentDate | date: 'dd/MM/yyyy' }}</td>
    </tr>
  </tbody>
</table>

<hr />

<div class="row">
  <div class="col-md-3 text-right">
    <input
      (bsValueChange)="loadFinancialSummary($event)"
      [(ngModel)]="daterangepickerModel"
      [bsConfig]="{ adaptivePosition: true }"
      bsDaterangepicker
      class="form-control"
      placeholder="Enter Date Range to Display..."
      type="text"
    />
  </div>
</div>

<div *ngIf="caseDetails?.paymentScheduleCreated" class="print">
  <h4>
    <div class="pull-right">{{ caseDetails.uniqueReference }}</div>
    Receipts & Payments -
    <span *ngFor="let person of caseDetails.people; let isLast = last"
      >{{ person.title.name }} {{ person.firstName }} {{ person.lastName
      }}{{ isLast ? '' : ' & ' }}</span
    >
  </h4>

  <table class="table table-striped">
    <thead>
      <tr>
        <th width="30">&nbsp;</th>
        <th>Receipts</th>
        <th>&nbsp;</th>
        <th>&nbsp;</th>
        <th>&nbsp;</th>
      </tr>
    </thead>
    <tbody></tbody>
    <tr>
      <td>&nbsp;</td>
      <td>Customer Payments In</td>
      <td>&nbsp;</td>
      <td>&nbsp;</td>
      <td class="text-right">
        {{
          financialSummary?.financialValues?.customer_payments_in / 100
            | toAccountancy
        }}
      </td>
    </tr>
    <tr
      *ngFor="
        let eoIncome of financialSummary?.financialValues?.extraordinary_income
      "
    >
      <td>&nbsp;</td>
      <td><strong>Extra-Ordinary Income:</strong> {{ eoIncome.name }}</td>
      <td>&nbsp;</td>
      <td>&nbsp;</td>
      <td class="text-right">{{ eoIncome.value / 100 | toAccountancy }}</td>
    </tr>
    <tfoot>
      <tr>
        <th colspan="4"></th>
        <th class="text-right">
          {{ receiptsTotal / 100 | toAccountancy }}
        </th>
      </tr>
    </tfoot>
  </table>

  <table class="table table-striped">
    <thead>
      <tr>
        <th width="30">&nbsp;</th>
        <th>Payments</th>
        <th class="text-right">Total Expected</th>
        <th class="text-right">Received</th>
        <th class="text-right">Remaining</th>
      </tr>
    </thead>

    <tbody>
      <tr *ngFor="let item of financialSummary?.catOnes">
        <td class="text-center">
          <a
            *ngIf="item.invoice_count > 0"
            href="javascript:;"
            (click)="filterInvoices.next(item.item_id)"
            ><i class="fa fa-file"></i
          ></a>
        </td>
        <td>
          {{ item.item }}
          <sup
            *ngIf="item.estimated === true"
            style="cursor: help"
            tooltip="Estimated value proven by invoices"
            container="body"
          >
            <b><i class="fa fa-info-circle"></i></b>
          </sup>
        </td>
        <td class="text-right">
          <div *ngIf="item.estimated === false">
            {{ item.total_over_life / 100 | toAccountancy }}
          </div>

          <div *ngIf="item.estimated === true">
            <sup
              *ngIf="item.estimated === true"
              style="cursor: help"
              tooltip="Value proven by invoices, {{
                item.total_over_life / 100 | toAccountancy
              }} was estimated."
              container="body"
            >
              <b><i class="fa fa-info-circle"></i></b>
            </sup>
            {{ item.invoice_value / 100 | toAccountancy }}
          </div>
        </td>
        <td class="text-right">{{ (item.paid * -1) / 100 | toAccountancy }}</td>
        <td class="text-right">
          {{ remaining(item) | toAccountancy }}
        </td>
      </tr>
    </tbody>

    <tfoot>
      <tr>
        <th>&nbsp;</th>
        <th>&nbsp;</th>
        <th class="text-right">
          {{ totalPaymentsExpected / 100 | toAccountancy }}
        </th>
        <th class="text-right">
          {{ totalPaymentsPaid / 100 | toAccountancy }}
        </th>
        <th class="text-right">
          {{ totalPaymentsRemaining / 100 | toAccountancy }}
        </th>
      </tr>
      <tr>
        <th>&nbsp;</th>
        <th colspan="3">Total To Distribute:</th>
        <th class="text-right">
          {{ totalRemainingAllocations / 100 | toAccountancy }}
        </th>
      </tr>
    </tfoot>
  </table>

  <table class="table table-striped">
    <thead>
      <tr>
        <th width="30">&nbsp;</th>
        <th>Creditor</th>
        <th class="text-right">Pending</th>
        <th class="text-right">Retained</th>
        <th class="text-right">Allocated</th>
        <th class="text-right">Distributed</th>
        <th class="text-right">Total</th>
      </tr>
    </thead>

    <tbody>
      <tr *ngFor="let item of totalDistributions">
        <td width="30">&nbsp;</td>
        <td>{{ item.creditor }}</td>
        <td class="text-right">
          {{ item.pending / 100 | toAccountancy }}
        </td>
        <td class="text-right">
          {{ item.retained / 100 | toAccountancy }}
        </td>
        <td class="text-right">
          {{ item.allocated / 100 | toAccountancy }}
        </td>
        <td class="text-right">
          {{ item.distributed / 100 | toAccountancy }}
        </td>
        <td class="text-right">
          {{ item.total / 100 | toAccountancy }}
        </td>
      </tr>
    </tbody>

    <tfoot>
      <tr>
        <td width="30">&nbsp;</td>
        <th>&nbsp;</th>
        <th class="text-right">
          {{ totalCreditorPending / 100 | toAccountancy }}
        </th>
        <th class="text-right">
          {{ totalCreditorRetained / 100 | toAccountancy }}
        </th>
        <th class="text-right">
          {{ totalCreditorAllocations / 100 | toAccountancy }}
        </th>
        <th class="text-right">
          {{ totalCreditorDistributions / 100 | toAccountancy }}
        </th>
        <th class="text-right">
          {{ totalCreditorTotals / 100 | toAccountancy }}
        </th>
      </tr>
      <tr>
        <th width="30">&nbsp;</th>
        <th colspan="5">Total Remaining:</th>
        <th class="text-right">
          {{
            (totalRemainingAllocations - totalCreditorValue) / 100
              | toAccountancy
          }}
        </th>
      </tr>
      <tr>
        <th width="30">&nbsp;</th>
        <th colspan="5">Total Remaining Excluding Retained Items:</th>
        <th class="text-right">
          {{
            (totalRemainingAllocations -
              totalCreditorValue +
              totalCreditorRetained) /
              100 | toAccountancy
          }}
        </th>
      </tr>
    </tfoot>
  </table>
</div>

<div class="row">
  <div class="col-md-12 text-right">
    <a
      *ngIf="authService.hasPermission('reports.customer-soa-report')"
      class="btn btn-xs btn-danger"
      href="{{ apiUrl }}reports/detailed-customer-soa?caseId={{
        caseId
      }}&download=true&auth={{ authService?.getToken() }}"
      style="margin-right: 10px"
      target="_blank"
      >SOA Report</a
    >

    <button
      (click)="createFeeModal.confirmDialog(caseId)"
      *ngIf="authService.hasPermission('user.add-fees')"
      class="btn btn-xs btn-danger"
    >
      Add Fee
    </button>
  </div>
</div>

<app-create-modal
  #createFeeModal
  (confirm)="createFee($event)"
  [itemCreated]="feeCreated"
  type="fee"
>
  <div *ngIf="caseId" class="form-group clearfix">
    <label class="col-sm-4 control-label" for="entry">Account Entry</label>
    <div class="col-sm-8">
      <app-chart-of-accounts-select
        [model]="newFee"
        id="entry"
      ></app-chart-of-accounts-select>
    </div>
  </div>

  <div *ngIf="caseId" class="form-group clearfix">
    <label class="col-sm-4 control-label" for="value">Value</label>
    <div class="col-sm-8">
      <app-shared-currency
        [(ngModel)]="newFee.value"
        id="value"
        [min]="minFeeValue"
        [allowNegative]="true"
      ></app-shared-currency>
    </div>
  </div>
</app-create-modal>
