import AddressTypeList, {
  AddressTypeListProps,
} from '../../../chief-react/src/App/Pages/AddressTypes/AddressTypeList';
import {
  ElementRefWrapper,
  LinkProps,
  ReactWrapper,
  RouterWrapper,
} from 'react-inside-angular';

import { Component, ElementRef } from '@angular/core';
import { Router } from '@angular/router';
import { ComponentClass, FunctionComponent } from 'react';
import { AuthService } from '../auth/auth.service';

@Component({
  selector: 'app-address-types-list',
  template: '<div [id]="rootId"></div>',
})
export class AddressTypesListComponent extends ReactWrapper<AddressTypeListProps> {
  protected router: RouterWrapper;
  protected elemRef: ElementRefWrapper;

  constructor(router: Router, elemRef: ElementRef, protected readonly authService: AuthService) {
    super();
    this.router = (router as unknown) as RouterWrapper;
    this.elemRef = (elemRef as unknown) as ElementRefWrapper;
  }

  // Transfer any props from Angular to the React component
  public setProps(): AddressTypeListProps {
    return {
      authService: this.authService
    };
  }

  // Define the React component being wrapped
  public setComponent():
    | string
    | FunctionComponent<LinkProps<AddressTypeListProps>>
    | ComponentClass<LinkProps<AddressTypeListProps>, any> {
    return AddressTypeList;
  }
}
