<input
  #searchPopover="bs-popover"
  (keyup)="performSearch($event.target.value)"
  (onHidden)="clearSearch()"
  [(ngModel)]="searchValue"
  [outsideClick]="true"
  [popover]="searchPopoverTemplate"
  autocomplete="off"
  class="form-control search"
  placeholder="Search"
  placement="bottom"
  popoverTitle="Search Results"
  triggers=""
  type="search"
/>

<ng-template #searchPopoverTemplate>
  <ul class="search-result">
    <li *ngIf="searchSubscription">
      Searching - Please Wait...
    </li>

    <li
      *ngIf="!searchSubscription && (!foundCases || foundCases.length <= 0)"
      class="text-center text-muted"
    >
      No Cases could be found with these search details.
    </li>

    <ng-container
      *ngIf="!searchSubscription && foundCases && foundCases.length > 0"
    >
      <li
        *ngFor="let singleCase of foundCases"
        (click)="goToCase(singleCase.id)"
      >
        <strong (click)="goToCase(singleCase.id)">
          {{ singleCase?.case_name }}
          <span class="text-muted">({{ singleCase?.unique_reference }})</span>
        </strong>
        <small class="text-muted">
          <b>{{ singleCase?.introducer }}</b> ({{
            singleCase?.introducer_reference
          }}) -
          <strong>{{ singleCase?.status }}</strong>
        </small>
        <small class="text-muted" (click)="goToCase(singleCase.id)">
          {{ singleCase?.address }}
        </small>
      </li>
      <li (click)="goToCase(singleCase.id)">
        <a href="javascript:;" (click)="goToFullSearch()">View More...</a>
      </li>
    </ng-container>
  </ul>
</ng-template>
