import { Component, Input, OnInit } from '@angular/core';
import { NotificationService } from '../../../shared/notification.service';
import { SelectComparisons } from '../../../shared/pipes/select.comparisons';
import { FinancialAccount } from '../accounts';
import { FinancialAccountsService } from '../accounts.service';

@Component({
  selector: 'app-financial-accounts-select',
  templateUrl: 'select.component.html',
})
export class FinancialAccountsSelectComponent extends SelectComparisons
  implements OnInit {
  /**
   * The model that we will be adding the financialAccounts to.
   */
  @Input() model: any;

  /**
   * Do we require this field in the main form it is
   * included in?
   */
  @Input() required: boolean;

  /**
   * A list of all the delivery-methods currently loaded.
   *
   * @type {FinancialAccount[]}
   */
  public financialAccounts: FinancialAccount[] = [];

  /**
   * Are we currently performing a loading action?
   *
   * @type {boolean}
   */
  public isLoading: boolean = true;

  /**
   * Load up all our required services and subscribe to every observable we need.
   *
   * @param {FinancialAccountsService} financialAccountsService
   * @param {NotificationService} notificationService
   */
  constructor(
    private financialAccountsService: FinancialAccountsService,
    private notificationService: NotificationService,
  ) {
    super();
    this.financialAccountsService.loading.subscribe(isLoading => {
      this.isLoading = isLoading;
    });
  }

  /**
   * When the component is initialised we set it to load
   * page one of the financialAccounts list.
   */
  public ngOnInit(): void {
    this.loadFinancialAccounts(1, 10000);
  }

  /**
   * Loads a list of delivery-methods using the given per page limit.
   *
   * @param {number} page The current page we require.
   * @param {number} limit The number of results per page.
   */
  public loadFinancialAccounts(page?: number, limit?: number): void {
    const listFinancialAccounts = this.financialAccountsService
      .listFinancialAccounts(page, limit)
      .subscribe((financialAccounts: FinancialAccount[]) => {
        this.financialAccounts = financialAccounts;
        listFinancialAccounts.unsubscribe();
      });
  }
}
