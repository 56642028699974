import { Component, OnInit } from '@angular/core';
import { SupplierService } from '../supplier.service';
import { Supplier } from '../supplier';
import { Router } from '@angular/router';
import { NotificationService } from '../../../shared/notification.service';

@Component({
  selector: 'app-supplier-create',
  templateUrl: 'create.component.html',
})
export class SupplierCreateComponent implements OnInit {
  /**
   * Details of the supplier being created.
   */
  public supplier: Supplier;

  /**
   * Are we currently performing a loading action?
   *
   * @type {boolean}
   */
  public isLoading: boolean = false;

  /**
   * Load up all our required services and subscribe to every observable we need.
   *
   * @param {SupplierService} supplierService
   * @param {NotificationService} notificationService
   * @param {Router} router
   */
  constructor(
    private supplierService: SupplierService,
    private notificationService: NotificationService,
    private router: Router,
  ) {
    this.supplierService.loading.subscribe(result => (this.isLoading = result));
  }

  /**
   * Initialise the component, creating a new blank supplier if required.
   */
  public ngOnInit(): void {
    if (!this.supplier) {
      this.supplier = new Supplier();
    }
  }

  /**
   * Perform the create action which adds the new supplier into the backend.
   */
  public create() {
    const createSupplier = this.supplierService
      .createSupplier(this.supplier)
      .subscribe(
        (result: Supplier) => {
          this.notificationService.success(
            `Supplier Created`,
            `New supplier ${result.name} has been created with ID of ${
              result.id
            }.`,
            5,
          );
          this.supplier = new Supplier();
          createSupplier.unsubscribe();
          this.router.navigate(['/suppliers']);
        },
        err => {
          this.notificationService.error(
            `Could not create Supplier`,
            err.error.message,
            10,
          );
        },
      );
  }
}
