import 'rxjs/Rx';

import { Component, EventEmitter, Input, Output } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { AuthService } from '../../../../auth/auth.service';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { CasePaymentScheduleItem } from '../../../../../../chief-types/src/CasePaymentSchedule';
import { HttpRequestResponse } from '../../../../shared/http/http-request-response';
import { NotificationService } from '../../../../shared/notification.service';
import { Router } from '@angular/router';
import { environment } from '../../../../../environments/environment';

@Component({
  selector: 'app-case-log-manual-payment',
  templateUrl: 'manual.component.html',
})
export class CaseLogManualPaymentComponent {
  private readonly httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + this.authService.getToken(),
    }),
  };

  @Input() public caseId: string;

  @Input() modalRef: BsModalRef;

  @Input() paymentSchedule: CasePaymentScheduleItem[];

  @Output() completed = new EventEmitter();

  description: string;

  expectedDate: string;

  paidDate: string;

  method: any;

  value: number;

  constructor(
    private http: HttpClient,
    private readonly authService: AuthService,
    protected readonly notification: NotificationService,
    private router: Router,
  ) {
    this.method = {
      paymentType: {},
    };
  }

  public logPayment(): void {
    const scheduleItemId = this.paymentSchedule.find(
      (item) => item.expectedDate === this.expectedDate,
    )?.id;
    this.http
      .post<HttpRequestResponse>(
        `${environment.endpoint}payment-logs`,
        {
          caseId: this.caseId,
          description: this.description,
          expectedDate: this.expectedDate,
          paidDate: this.paidDate,
          method: this.method.paymentType,
          value: this.value,
          scheduleItemId,
        },
        this.httpOptions,
      )
      .map((data: HttpRequestResponse) => {
        return data.data;
      })
      .subscribe((item) => {
        this.notification.success('Success', 'Payment has been logged.', 5);
        this.completed.next(true);
        this.modalRef.hide();
      });
  }
}
