import { Component, OnInit } from '@angular/core';
import { TaskService } from '../task.service';
import { Task } from '../task';
import { Router } from '@angular/router';
import { NotificationService } from '../../shared/notification.service';

@Component({
  selector: 'app-task-create',
  templateUrl: 'create.component.html',
})
export class TaskCreateComponent implements OnInit {
  /**
   * Details of the task being created.
   */
  public task: Task;

  /**
   * Are we currently performing a loading action?
   *
   * @type {boolean}
   */
  public isLoading: boolean = false;

  /**
   * Load up all our required services and subscribe to every observable we need.
   *
   * @param {TaskService} taskService
   * @param {NotificationService} notificationService
   * @param {Router} router
   */
  constructor(
    private taskService: TaskService,
    private notificationService: NotificationService,
    private router: Router,
  ) {
    this.taskService.loading.subscribe(result => (this.isLoading = result));
  }

  /**
   * Initialise the component, creating a new blank task if required.
   */
  public ngOnInit(): void {
    if (!this.task) {
      this.task = new Task();
    }
  }

  /**
   * Perform the create action which adds the new task into the backend.
   */
  public create() {
    const createTask = this.taskService.createTask(this.task).subscribe(
      (result: Task) => {
        this.notificationService.success(
          `Task Created`,
          `New task ${result.name} has been created with ID of ${result.id}.`,
          5,
        );
        this.task = new Task();
        createTask.unsubscribe();
        this.router.navigate(['/tasks']);
      },
      err => {
        this.notificationService.error(
          `Could not create Task`,
          err.error.message,
          10,
        );
      },
    );
  }
}
