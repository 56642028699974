import * as moment from 'moment';

import { Component, OnInit } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { AuthService } from '../../auth/auth.service';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-annuals-due-report',
  templateUrl: 'annuals-due.component.html',
})
export class AnnualsDueComponent implements OnInit {
  public loading: boolean = false;
  public filter: any = {};

  public detailedReport: any[];

  public sort = {
    field: 'count',
    direction: 'DESC',
  };

  constructor(
    private readonly http: HttpClient,
    private readonly authService: AuthService,
  ) {}

  ngOnInit(): void {
    this.loadReport(1);
  }

  sortBy(field: string) {
    if (field !== this.sort.field) {
      this.sort = {
        field,
        direction: field === 'count' ? 'DESC' : 'ASC',
      };
    } else {
      this.sort.direction = this.sort.direction === 'ASC' ? 'DESC' : 'ASC';
    }

    this.loadReport(1);
  }

  get detailedLink(): string {
    return `${environment.apiUrl}reports/annuals-due?domainId=${
      this.authService.domain.id
    }&limit=100000&auth=${this.authService.getToken()}`;
  }

  get downloadReportUrl(): string {
    return `${this.detailedLink}&download=true`;
  }

  public isInPast(date) {
    return date <= moment().format('YYYY-MM-DD');
  }

  protected loadReport(page: number) {
    this.detailedReport = [];
    this.loading = true;
    this.http.get<any>(this.detailedLink).subscribe((data) => {
      this.loading = false;
      this.detailedReport = data.data;
    });
  }
}
