import {NgRedux} from '@angular-redux/store';
import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {AngularFirestore} from '@angular/fire/firestore';
import {Router} from '@angular/router';
import firebase from 'firebase';
import * as jwt from 'jwt-decode';
import * as moment from 'moment';
import {BehaviorSubject} from 'rxjs';

import {environment} from '../../environments/environment';
import {ReduxStore} from '../common/redux.store';
import {UserActions} from '../common/user/user.actions';
import {Domain} from '../domains/domain';
import {Department} from '../settings/departments/department';
import {HttpRequestResponse} from '../shared/http/http-request-response';
import {UserProfile} from '../users/user-profile';
import {AuthenticationResponse} from './authentication-response';

@Injectable()
export class AuthService {
  public loggedIn: boolean = false;
  public loggedIn$ = new BehaviorSubject<boolean>(this.loggedIn);

  public domain: Domain;
  public userId: string = '';
  public department: Department;
  public permissions: string[] = [];

  constructor(
      private readonly http: HttpClient,
      private readonly router: Router,
      private readonly angularFirestore: AngularFirestore,
      private ngRedux: NgRedux<ReduxStore>,
      private userActions: UserActions,
  ) {
    if (this.authenticated) {
      this.setLoggedIn(true);
    } else {
      this.setLoggedIn(false);
      // this.logout();
    }
  }

  /**
   * Check if the local storage key has expired or not.
   * @returns {boolean}
   */
  public get authenticated(): boolean {
    const expiresAt = localStorage.getItem('expires_at');
    const isAuthenticated =
        expiresAt && moment(expiresAt).diff(moment(), 'seconds', true) > 0;

    if (this.loggedIn && !isAuthenticated) {
      this.logout();
    }

    return isAuthenticated;
  }

  /**
   * Performs a login
   */
  public login(username: string, password: string): Promise<true | string> {
    return new Promise<true | string>(((resolve, reject) => {
      this.http
          .post<HttpRequestResponse>(environment.endpoint + 'auth/login', {
            username: username,
            password: password,
          })
          .subscribe(data => {
            this.handleAuthentication(data.data, {username, password});
            resolve(true);
          }, (e) => {
            reject(e.error.message);
          });
    }));
  }

  public getToken(): string {
    return localStorage.getItem('token');
  }

  /**
   * Clear out all local storage and ensure the user is no longer
   * marked as being logged in.
   */
  public logout() {
    firebase.auth().signOut()
        .then(() => console.log('Firebase Signed Out'));
    localStorage.removeItem('token');
    localStorage.removeItem('id_token');
    localStorage.removeItem('profile');
    localStorage.removeItem('expires_at');

    this.ngRedux.dispatch(this.userActions.removeJwtToken());
    this.ngRedux.dispatch(this.userActions.removeProfile());

    this.setLoggedIn(false);
  }

  public hasPermission(permission: string): boolean {
    let hasPermission = false;

    this.permissions.forEach(foundPermission => {
      const foundPermissionSplit = foundPermission.split('.');
      if (
          permission === foundPermission ||
          permission === `${foundPermissionSplit[0]}.*`
      ) {
        hasPermission = true;
      }
    });

    return hasPermission;
  }

  public hasRole(role: string): boolean {
    return true;
  }

  private handleAuthentication(authenticationData, {username, password}): AuthenticationResponse {
    console.log(authenticationData);
    if (authenticationData.successful && authenticationData.token) {
      localStorage.setItem('token', authenticationData.token);
      this.ngRedux.dispatch(this.userActions.setJwtToken(authenticationData.token));

      this.ngRedux.dispatch(this.userActions.setProfile(authenticationData.profile as UserProfile));

      localStorage.setItem('expires_at', authenticationData.expiresAt);

      const jwtParsed = jwt(authenticationData.token);

      this.http.post('/functions/auth/convert', {
        username,
        password,
        profile: authenticationData.profile,
      }).subscribe((result: any) => {
        console.log(result);

        if (result.data) {
          this.angularFirestore.doc(`/users/${result.data.uid}`).set(jwtParsed);
        }

        // Lets login the user with firebase now
        firebase.auth().signInWithEmailAndPassword(username, password)
            .then(() => console.log('Firebase Logged In'))
            .catch((err) => console.log('FB Login Error', err));
      });

      this.setLoggedIn(true);
    } else {
      this.setLoggedIn(false);
    }
    return authenticationData;
  }

  /**
   * Sets if the user is logged in or not. Also broadcasts the
   * appropriate status to any watchers.6
   * @param {boolean} value
   */
  private setLoggedIn(value: boolean): void {
    if (value === true) {
      const jwtParsed = jwt(this.getToken());

      this.domain = jwtParsed.domain;
      this.userId = jwtParsed.id;
      this.department = jwtParsed.department;
      this.permissions = jwtParsed.permissions;
    } else {
      this.domain = undefined;
      this.userId = '';
      this.permissions = [];
    }

    this.loggedIn$.next(value);
    this.loggedIn = value;
  }
}
