import { Component, EventEmitter, OnInit } from '@angular/core';
import { ProductService } from '../product.service';
import { Product } from '../product';
import { NotificationService } from '../../shared/notification.service';
import { AuthService } from '../../auth/auth.service';

@Component({
  selector   : 'app-product-list',
  templateUrl: 'list.component.html',
  styleUrls  : [],
})
export class ProductListComponent implements OnInit {
  public product: Product;

  /**
   * A list of all the products currently loaded.
   *
   * @type {Product[]}
   */
  public products: Product[] = [];

  /**
   * Are we currently performing a loading action?
   *
   * @type {boolean}
   */
  public isLoading: boolean = true;

  /**
   * The total number of pages in this list of products.
   *
   * @type {number}
   */
  public totalPages: number = 1;

  /**
   * The current page of products that we are viewing.
   *
   * @type {number}
   */
  public currentPage: number = 1;

  /**
   * The total number of products that can be viewed in the system.
   *
   * @type {number}
   */
  public totalResults: number = 0;

  /**
   * An Emitter to alert other components when a product has been deleted.
   *
   * @type {EventEmitter<boolean>}
   */
  public productDeleted: EventEmitter<boolean> = new EventEmitter<boolean>();

  /**
   * Load up all our required services and subscribe to every observable we need.
   *
   * @param {ProductService} productService
   * @param {NotificationService} notificationService
   */
  constructor(
    private productService: ProductService,
    private notificationService: NotificationService,
    private authService: AuthService,
  )
  {
    this.productService.loading.subscribe(isLoading => {
      this.isLoading = isLoading;
    });
    this.productService.totalPages.subscribe(totalPages => {
      this.totalPages = totalPages;
    });
    this.productService.currentPage.subscribe(currentPage => {
      this.currentPage = currentPage;
    });
    this.productService.totalResults.subscribe(totalResults => {
      this.totalResults = totalResults;
    });
  }

  /**
   * When the component is initialised we set it to load
   * page one of the product list.
   */
  public ngOnInit(): void {
    this.loadProducts(1);
  }

  /**
   * When we receive a page change from the pagination
   * component we load the appropriate page for the
   * product list.
   *
   * @param {number} pageNumber
   */
  public onPageChanged(pageNumber: number): void {
    this.loadProducts(pageNumber);
  }

  /**
   * Loads a list of products using the given per page limit.
   *
   * @param {number} page The current page we require.
   * @param {number} limit The number of results per page.
   */
  public loadProducts(page?: number, limit?: number): void {
    const listProducts = this.productService
      .listProducts(page, limit)
      .subscribe((products: Product[]) => {
        this.products = products;
        listProducts.unsubscribe();
      });
  }

  /**
   * When receiving confirmation of an item being deleted
   * we call the back end with the appropriate product ID
   * to delete the product.
   *
   * @param {string} id
   */
  public delete(id: string) {
    this.productService.deleteProduct(id).subscribe(
      deleted => {
        this.notificationService.success(
          `Product Deleted`,
          `Product with ID ${id} was deleted.`,
          5,
        );
        this.productDeleted.next(true);
        this.onPageChanged(this.currentPage);
      },
      err => {
        this.notificationService.error(
          `Product Not Deleted`,
          err.error.message,
          10,
        );
        this.productDeleted.next(false);
        this.onPageChanged(this.currentPage);
      },
    );
  }
}
