import { Component } from '@angular/core';
import { CreditorService } from '../creditor.service';
import { Creditor } from '../creditor';
import { ActivatedRoute, Router } from '@angular/router';
import { NotificationService } from '../../shared/notification.service';

@Component({
  selector: 'app-creditor-edit',
  templateUrl: 'edit.component.html',
})
export class CreditorEditComponent {
  /**
   * Details of the creditor being edited.
   */
  public creditor: Creditor;

  /**
   * Are we currently performing a loading action?
   *
   * @type {boolean}
   */
  public isLoading: boolean = false;

  /**
   * Load up all our required services and subscribe to every observable we need.
   *
   * @param {CreditorService} creditorService
   * @param {NotificationService} notificationService
   * @param {Router} router
   * @param {ActivatedRoute} route
   */
  constructor(
    private creditorService: CreditorService,
    private notificationService: NotificationService,
    private router: Router,
    private route: ActivatedRoute,
  ) {
    this.creditorService.loading.subscribe(result => (this.isLoading = result));
    this.route.params.subscribe(params => this.loadCreditor(params.id));
  }

  /**
   * Perform the edit action which send the edited creditor to the backend.
   */
  public edit() {
    const editCreditor = this.creditorService
      .updateCreditor(this.creditor.id, this.creditor)
      .subscribe(
        (result: Creditor) => {
          this.notificationService.success(
            `Creditor Updated`,
            `The creditor ${
              result.name
            } been updated with the specified changes.`,
            5,
          );
          this.creditor = new Creditor();
          editCreditor.unsubscribe();
          this.router.navigate(['/creditors']);
        },
        err => {
          this.notificationService.error(
            `Creditor Not Found`,
            err.error.message,
            10,
          );
          this.router.navigate(['/creditors']);
        },
      );
  }

  /**
   * Loads the requested creditor so we can display it and let the user edit the fields.
   *
   * @param {string} id
   */
  private loadCreditor(id: string) {
    this.creditorService.findCreditor(id).subscribe(
      result => {
        this.creditor = result;
      },
      err => {
        this.notificationService.error(
          `Creditor Not Found`,
          err.error.message,
          10,
        );
        this.router.navigate(['/creditors']);
      },
    );
  }
}
