import { Component, OnInit } from '@angular/core';
import { UserService } from '../user.service';
import { User } from '../user';
import { Router } from '@angular/router';
import { NotificationService } from '../../shared/notification.service';

@Component({
  selector: 'app-user-create',
  templateUrl: 'create.component.html',
})
export class UserCreateComponent implements OnInit {
  /**
   * Details of the user being created.
   */
  public user: User;

  /**
   * Are we currently performing a loading action?
   *
   * @type {boolean}
   */
  public isLoading: boolean = false;

  /**
   * Load up all our required services and subscribe to every observable we need.
   *
   * @param {UserService} userService
   * @param {NotificationService} notificationService
   * @param {Router} router
   */
  constructor(
    private userService: UserService,
    private notificationService: NotificationService,
    private router: Router,
  ) {
    this.userService.loading.subscribe(result => (this.isLoading = result));
  }

  /**
   * Initialise the component, creating a new blank user if required.
   */
  public ngOnInit(): void {
    if (!this.user) {
      this.user = new User();
    }
  }

  /**
   * Perform the create action which adds the new user into the backend.
   */
  public create() {
    const createUser = this.userService.createUser(this.user).subscribe(
      (result: User) => {
        this.notificationService.success(
          `User Created`,
          `New user ${result.name} has been created with ID of ${result.id}.`,
          5,
        );
        this.user = new User();
        createUser.unsubscribe();
        this.router.navigate(['/users']);
      },
      err => {
        this.notificationService.error(
          `Could not create User`,
          err.error.message,
          10,
        );
      },
    );
  }
}
