<div class="row" *ngIf="allocationsInProgress">
  <div class="col-md-12">
    <div class="alert alert-danger text-bold text-center">
      Allocations are currently Read Only whilst automatic allocations are
      performed.
    </div>
  </div>
</div>

<div class="row">
  <div class="col-sm-12">
    <section class="panel">
      <header class="panel-heading tab-bg-light">
        <div class="row">
          <div class="col-md-12">
            <ul class="nav nav-tabs">
              <li [class.active]="tabSelected('summary')">
                <a
                  (click)="setTab('summary')"
                  href="javascript:"
                  style="line-height: 32px"
                  >Summary</a
                >
              </li>
              <li [class.active]="tabSelected('pending-cat1s')">
                <a
                  (click)="setTab('pending-cat1s')"
                  href="javascript:"
                  style="line-height: 32px"
                  >Pending Fees</a
                >
              </li>
              <li [class.active]="tabSelected('pending-creditors')">
                <a
                  (click)="setTab('pending-creditors')"
                  href="javascript:"
                  style="line-height: 32px"
                  >Pending Distributions</a
                >
              </li>
            </ul>
          </div>
        </div>
      </header>

      <div class="tab-content">
        <div class="row" style="padding: 10px">
          <div class="col-md-12 text-right">
            <a
              [routerLink]="['/financials/distributions']"
              class="btn btn-default btn-md"
              tooltip="Back To Allocations"
              container="body"
              ><i class="fa fa-chevron-left"></i
            ></a>

            <button
              [disabled]="!readyToAllocate()"
              [class.btn-success]="readyToAllocate()"
              [class.btn-danger]="!readyToAllocate()"
              class="btn btn-md"
              style="margin-left: 10px"
              tooltip="Accept Allocations"
              (click)="startAllocating()"
              container="body"
              *ngIf="
                savingChecks.length === 0 &&
                !acceptingAllocations &&
                !allocationsInProgress &&
                authService.hasPermission('financials.accept-distributions')
              "
            >
              <i class="fa fa-check"></i>
            </button>
          </div>
        </div>

        <!-- Summary Table -->
        <div
          [class.active]="tabSelected('summary')"
          class="tab-pane"
          id="summary"
          role="tabpanel"
        >
          <div class="table-responsive">
            <table class="table table-striped">
              <thead>
                <tr>
                  <th>Name</th>
                  <th>Value</th>
                  <th>Payment Method</th>
                  <th>Payment Details</th>
                  <th>&nbsp;</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let item of loadedCat1s">
                  <td>
                    <div [ngSwitch]="item.will_distribute">
                      <div *ngSwitchCase="false">
                        <strong
                          style="color: #970100; cursor: help"
                          container="body"
                          tooltip="Will not distribute due to {{
                            item?.retained_reason
                          }}."
                        >
                          {{ item?.item }}
                          <sup>
                            <i class="fa fa-info-circle"></i>
                          </sup>
                        </strong>
                      </div>
                      <div *ngSwitchDefault>{{ item?.item }}</div>
                    </div>
                  </td>
                  <td>{{ item?.value / 100 | toAccountancy }}</td>
                  <td>
                    <select
                      class="form-control"
                      [(ngModel)]="item.method"
                      (change)="saveItemCache(item)"
                    >
                      <option value="bank-transfer">Bank Transfer</option>
                      <option value="cheque">Cheque</option>
                    </select>
                  </td>
                  <td>
                    <div [ngSwitch]="item.method">
                      <div *ngSwitchCase="'cheque'">
                        <input
                          class="form-control"
                          type="text"
                          [(ngModel)]="item.cheque_number"
                          (change)="saveItemCache(item)"
                        />
                      </div>
                      <div *ngSwitchDefault>
                        <input
                          class="form-control"
                          type="text"
                          readonly
                          [value]="item.sort_code + ' / ' + item.account_number"
                        />
                      </div>
                    </div>
                  </td>
                  <td>
                    <i
                      *ngIf="isSaveItemLoading(item.id)"
                      style="font-size: medium; line-height: 32px"
                      class="fa fa-spinner fa-spin"
                    ></i>
                  </td>
                </tr>

                <tr *ngFor="let item of loadedCreditors">
                  <td>
                    <div [ngSwitch]="item.will_distribute">
                      <div *ngSwitchCase="false">
                        <strong
                          style="color: #970100; cursor: help"
                          container="body"
                          tooltip="Will not distribute due to {{
                            item?.retained_reason
                          }}."
                        >
                          {{ item?.item }}
                          <sup>
                            <i class="fa fa-info-circle"></i>
                          </sup>
                        </strong>
                      </div>
                      <div *ngSwitchDefault>{{ item?.item }}</div>
                    </div>
                  </td>
                  <td>{{ item?.value / 100 | toAccountancy }}</td>
                  <td>
                    <select
                      class="form-control"
                      [(ngModel)]="item.method"
                      (change)="saveItemCache(item)"
                    >
                      <option value="bank-transfer">Bank Transfer</option>
                      <option value="cheque">Cheque</option>
                    </select>
                  </td>
                  <td>
                    <div [ngSwitch]="item.method">
                      <div *ngSwitchCase="'cheque'">
                        <input
                          class="form-control"
                          type="text"
                          [(ngModel)]="item.cheque_number"
                          (change)="saveItemCache(item)"
                        />
                      </div>
                      <div *ngSwitchDefault>
                        <input
                          class="form-control"
                          type="text"
                          readonly
                          [value]="item.sort_code + ' / ' + item.account_number"
                        />
                      </div>
                    </div>
                  </td>
                  <td>
                    <i
                      *ngIf="isSaveItemLoading(item.id)"
                      style="font-size: medium; line-height: 32px"
                      class="fa fa-spinner fa-spin"
                    ></i>
                  </td>
                </tr>
              </tbody>
              <tfoot *ngIf="!allocationsInProgress">
                <tr>
                  <td></td>
                  <td colspan="2">
                    <app-pagination
                      (pageChanged)="onPageChanged('summary', $event)"
                      [currentPage]="summaryCurrentPage"
                      [totalPages]="summaryTotalPages"
                    ></app-pagination>
                  </td>
                  <td class="text-right">
                    <a
                      [href]="distributionService.summaryUrl + '&download=true'"
                      target="_blank"
                      class="btn btn-xs btn-primary"
                    >
                      <i class="fa fa-download"></i>
                    </a>
                  </td>
                </tr>
              </tfoot>
            </table>
          </div>
        </div>

        <!-- Pending Cat1's Table -->
        <div
          [class.active]="tabSelected('pending-cat1s')"
          class="tab-pane"
          id="pending-cat1s"
          role="tabpanel"
        >
          <div
            class="table-responsive"
            style="display: block; border-bottom: #f1f2f7 solid 5px"
          >
            <table class="table-striped" width="100%" style="margin-top: 30px">
              <div style="display: block; border-bottom: #f1f2f7 solid 5px">
                <thead>
                  <tr>
                    <th
                      style="
                        min-width: 50px;
                        max-width: 50px;
                        line-height: 30px;
                      "
                    >
                      &nbsp;
                    </th>
                    <th
                      style="
                        min-width: 300px;
                        max-width: 300px;
                        line-height: 30px;
                      "
                      *ngFor="let header of cat1Headers; let i = index"
                    >
                      <input
                        *ngIf="i === 0"
                        class="form-control"
                        style="
                          width: 200px;
                          margin-right: 20px;
                          margin-bottom: 5px;
                        "
                        [(ngModel)]="catReferenceSearch"
                        (ngModelChange)="loadPendingCat1s(1)"
                        placeholder="Reference"
                      />

                      <span *ngIf="i > 0">{{
                        header.replace('Receivable', '')
                      }}</span>
                    </th>
                  </tr>
                </thead>
              </div>
              <div style="overflow-y: scroll; height: 50vh">
                <tbody>
                  <tr *ngFor="let cat1 of cat1s">
                    <td
                      style="
                        min-width: 50px;
                        max-width: 50px;
                        text-align: center;
                      "
                    >
                      <button
                        class="btn btn-xs btn-danger"
                        (click)="toggleAndDelete(cat1[0])"
                      >
                        <i class="fa fa-trash"></i>
                      </button>
                    </td>
                    <td
                      *ngFor="let value of cat1; let i = index"
                      style="
                        line-height: 24px;
                        min-width: 300px;
                        max-width: 300px;
                      "
                    >
                      <span *ngIf="i === 0">{{ value }}</span>
                      <app-editable-number
                        style="max-width: 100px"
                        [value]="value"
                        [isCurrency]="true"
                        [isSaving]="isSaving(cat1[0], i)"
                        (hasChanged)="
                          updateSummaryValue(
                            'Cat1',
                            cat1[0],
                            cat1Headers[i],
                            $event,
                            cat1
                          )
                        "
                        *ngIf="i !== 0"
                      ></app-editable-number>
                    </td>
                  </tr>
                </tbody>
              </div>
            </table>
          </div>

          <table width="100%">
            <tfoot *ngIf="!allocationsInProgress">
              <tr>
                <td width="10%"></td>
                <td class="text-center">
                  <app-pagination
                    (pageChanged)="onPageChanged('cats', $event)"
                    [currentPage]="catsCurrentPage"
                    [totalPages]="catsTotalPages"
                  ></app-pagination>
                </td>
                <td width="10%" class="text-right">
                  <a
                    [href]="
                      distributionService.pendingCat1sUrl + '&download=true'
                    "
                    target="_blank"
                    class="btn btn-sm btn-primary"
                  >
                    <i class="fa fa-download"></i>
                  </a>
                </td>
              </tr>
            </tfoot>
          </table>
        </div>

        <!-- Pending Creditors Table -->
        <div
          [class.active]="tabSelected('pending-creditors')"
          class="tab-pane"
          id="pending-creditors"
          role="tabpanel"
        >
          <div class="table-responsive">
            <table class="table table-striped">
              <thead>
                <tr>
                  <th *ngFor="let header of creditorHeaders; let i = index">
                    <input
                      *ngIf="i === 0"
                      class="form-control"
                      style="
                        width: 200px;
                        margin-right: 20px;
                        margin-bottom: 5px;
                      "
                      [(ngModel)]="creditorReferenceSearch"
                      (ngModelChange)="loadPendingCreditors(1)"
                      placeholder="Reference"
                    />

                    <span *ngIf="i > 0">{{
                      header.replace('Receivable', '')
                    }}</span>
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let creditor of creditors">
                  <td *ngFor="let value of creditor; let i = index">
                    <div [ngSwitch]="i">
                      <div *ngSwitchCase="0">{{ value }}</div>
                      <div *ngSwitchDefault>
                        <app-editable-number
                          style="max-width: 100px"
                          [value]="value.value"
                          [isCurrency]="true"
                          [isSaving]="isSaving(creditor[0], value.id)"
                          (hasChanged)="
                            updateSummaryValue(
                              'Creditor',
                              creditor[0],
                              value.id,
                              $event
                            )
                          "
                          *ngIf="i !== 0 && value"
                        ></app-editable-number>
                      </div>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

          <table width="100%">
            <tfoot *ngIf="!allocationsInProgress">
              <tr>
                <td width="10%"></td>
                <td class="text-center">
                  <app-pagination
                    (pageChanged)="onPageChanged('creditors', $event)"
                    [currentPage]="creditorsCurrentPage"
                    [totalPages]="creditorsTotalPages"
                  ></app-pagination>
                </td>
                <td width="10%" class="text-right">
                  <a
                    [href]="
                      distributionService.pendingCreditorsUrl + '&download=true'
                    "
                    target="_blank"
                    class="btn btn-xs btn-primary"
                  >
                    <i class="fa fa-download"></i>
                  </a>
                </td>
              </tr>
            </tfoot>
          </table>
        </div>
      </div>
    </section>
  </div>
</div>
