import { Component } from '@angular/core';
import { ModificationService } from '../modification.service';
import { Modification } from '../modification';
import { ActivatedRoute, Router } from '@angular/router';
import { NotificationService } from '../../../shared/notification.service';

@Component({
  selector: 'app-modification-edit',
  templateUrl: 'edit.component.html',
})
export class ModificationEditComponent {
  /**
   * Details of the modification being edited.
   */
  public modification: Modification;

  /**
   * Are we currently performing a loading action?
   *
   * @type {boolean}
   */
  public isLoading: boolean = false;

  /**
   * Load up all our required services and subscribe to every observable we need.
   *
   * @param {ModificationService} modificationService
   * @param {NotificationService} notificationService
   * @param {Router} router
   * @param {ActivatedRoute} route
   */
  constructor(
    private modificationService: ModificationService,
    private notificationService: NotificationService,
    private router: Router,
    private route: ActivatedRoute,
  ) {
    this.modificationService.loading.subscribe(
      result => (this.isLoading = result),
    );
    this.route.params.subscribe(params => this.loadModification(params.id));
  }

  /**
   * Perform the edit action which send the edited modification to the backend.
   */
  public edit() {
    const editModification = this.modificationService
      .updateModification(this.modification.id, this.modification)
      .subscribe(
        (result: Modification) => {
          this.notificationService.success(
            `Modification Updated`,
            `The modification ${
              result.name
            } been updated with the specified changes.`,
            5,
          );
          this.modification = new Modification();
          editModification.unsubscribe();
          this.router.navigate(['/modifications']);
        },
        err => {
          this.notificationService.error(
            `Modification Not Found`,
            err.error.message,
            10,
          );
          this.router.navigate(['/modifications']);
        },
      );
  }

  /**
   * Loads the requested modification so we can display it and let the user edit the fields.
   *
   * @param {string} id
   */
  private loadModification(id: string) {
    this.modificationService.findModification(id).subscribe(
      result => {
        this.modification = result;
      },
      err => {
        this.notificationService.error(
          `Modification Not Found`,
          err.error.message,
          10,
        );
        this.router.navigate(['/modifications']);
      },
    );
  }
}
