import { Component, EventEmitter, OnInit } from '@angular/core';
import { GenderService } from '../gender.service';
import { Gender } from '../gender';
import { NotificationService } from '../../shared/notification.service';

@Component({
  selector: 'app-gender-list',
  templateUrl: 'list.component.html',
  styleUrls: [],
})
export class GenderListComponent implements OnInit {
  public gender: Gender;

  /**
   * A list of all the genders currently loaded.
   *
   * @type {Gender[]}
   */
  public genders: Gender[] = [];

  /**
   * Are we currently performing a loading action?
   *
   * @type {boolean}
   */
  public isLoading: boolean = true;

  /**
   * The total number of pages in this list of genders.
   *
   * @type {number}
   */
  public totalPages: number = 1;

  /**
   * The current page of genders that we are viewing.
   *
   * @type {number}
   */
  public currentPage: number = 1;

  /**
   * The total number of genders that can be viewed in the system.
   *
   * @type {number}
   */
  public totalResults: number = 0;

  /**
   * An Emitter to alert other components when a gender has been deleted.
   *
   * @type {EventEmitter<boolean>}
   */
  public genderDeleted: EventEmitter<boolean> = new EventEmitter<boolean>();

  /**
   * Load up all our required services and subscribe to every observable we need.
   *
   * @param {GenderService} genderService
   * @param {NotificationService} notificationService
   */
  constructor(
    private genderService: GenderService,
    private notificationService: NotificationService,
  ) {
    this.genderService.loading.subscribe(isLoading => {
      this.isLoading = isLoading;
    });
    this.genderService.totalPages.subscribe(totalPages => {
      this.totalPages = totalPages;
    });
    this.genderService.currentPage.subscribe(currentPage => {
      this.currentPage = currentPage;
    });
    this.genderService.totalResults.subscribe(totalResults => {
      this.totalResults = totalResults;
    });
  }

  /**
   * When the component is initialised we set it to load
   * page one of the gender list.
   */
  public ngOnInit(): void {
    this.loadGenders(1);
  }

  /**
   * When we receive a page change from the pagination
   * component we load the appropriate page for the
   * gender list.
   *
   * @param {number} pageNumber
   */
  public onPageChanged(pageNumber: number): void {
    this.loadGenders(pageNumber);
  }

  /**
   * Loads a list of genders using the given per page limit.
   *
   * @param {number} page The current page we require.
   * @param {number} limit The number of results per page.
   */
  public loadGenders(page?: number, limit?: number): void {
    const listGenders = this.genderService
      .listGenders(page, limit)
      .subscribe((genders: Gender[]) => {
        this.genders = genders;
        listGenders.unsubscribe();
      });
  }

  /**
   * When receiving confirmation of an item being deleted
   * we call the back end with the appropriate gender ID
   * to delete the gender.
   *
   * @param {string} id
   */
  public delete(id: string) {
    this.genderService.deleteGender(id).subscribe(
      deleted => {
        this.notificationService.success(
          `Gender Deleted`,
          `Gender with ID ${id} was deleted.`,
          5,
        );
        this.genderDeleted.next(true);
        this.onPageChanged(this.currentPage);
      },
      err => {
        this.notificationService.error(
          `Gender Not Deleted`,
          err.error.message,
          10,
        );
        this.genderDeleted.next(false);
        this.onPageChanged(this.currentPage);
      },
    );
  }
}
