import {Component, Input, OnInit} from '@angular/core';

import {CaseAnnual} from '../case-annual';

@Component({
  selector: 'app-case-annual-form',
  templateUrl: 'form.component.html',
})
export class CaseAnnualFormComponent implements OnInit {
  @Input() public caseId: string;

  @Input() public type: string;

  @Input() model: CaseAnnual;
    public editorOptions = {
        placeholder: 'Enter your note...',
        modules: {
            toolbar: ['bold', 'link'],
        },
    };

    ngOnInit(): void {
        if (!this.model.approve) {
            this.model.approve = 'save';
        }
    }

  public addObligation() {
    this.model.additionalClientObligations.push({
      entry: '',
      result: '',
    });
  }

  public removeObligation(i) {
    this.model.additionalClientObligations.splice(i, 1);
  }
}
