<div *ngIf="matchInProgress">
  <div class="allocation-status-header">Allocations In Progress</div>
  <div class="progress" style="height: 10px; margin-top: 4px">
    <div
      [style.width]="percentage + '%'"
      aria-valuemax="100"
      aria-valuemin="0"
      class="progress-bar progress-bar-success progress-bar-striped active"
      role="progressbar"
    >
      <span class="sr-only">{{ percentage }}%</span>
    </div>
  </div>
</div>
