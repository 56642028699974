import { Component } from '@angular/core';
import { BankingAccountItemService } from '../banking-account-item.service';
import { BankingAccountItem } from '../banking-account-item';
import { ActivatedRoute, Router } from '@angular/router';
import { NotificationService } from '../../../../shared/notification.service';

@Component({
  selector: 'app-banking-account-item-edit',
  templateUrl: 'edit.component.html',
})
export class BankingAccountItemEditComponent {
  /**
   * Details of the accountItem being edited.
   */
  public accountItem: BankingAccountItem;

  /**
   * Are we currently performing a loading action?
   *
   * @item {boolean}
   */
  public isLoading: boolean = false;

  /**
   * Load up all our required services and subscribe to every observable we need.
   *
   * @param {BankingAccountItemService} accountItemService
   * @param {NotificationService} notificationService
   * @param {Router} router
   * @param {ActivatedRoute} route
   */
  constructor(
    private accountItemService: BankingAccountItemService,
    private notificationService: NotificationService,
    private router: Router,
    private route: ActivatedRoute,
  ) {
    this.accountItemService.loading.subscribe(
      result => (this.isLoading = result),
    );
    this.route.params.subscribe(params => this.loadBankingAccountItem(params.id));
  }

  /**
   * Perform the edit action which send the edited accountItem to the backend.
   */
  public edit() {
    const editBankingAccountItem = this.accountItemService
      .updateBankingAccountItem(this.accountItem.id, this.accountItem)
      .subscribe(
        (result: BankingAccountItem) => {
          this.notificationService.success(
            `Account Item Updated`,
            `The item been updated with the specified changes.`,
            5,
          );
          this.accountItem = new BankingAccountItem();
          editBankingAccountItem.unsubscribe();
          this.router.navigate(['/banking/account-items']);
        },
        err => {
          this.notificationService.error(
            `Account Item Not Found`,
            err.error.message,
            10,
          );
          this.router.navigate(['/banking/account-items']);
        },
      );
  }

  /**
   * Loads the requested accountItem so we can display it and let the user edit the fields.
   *
   * @param {string} id
   */
  private loadBankingAccountItem(id: string) {
    this.accountItemService.findBankingAccountItem(id).subscribe(
      result => {
        this.accountItem = result;
      },
      err => {
        this.notificationService.error(
          `Account Item Not Found`,
          err.error.message,
          10,
        );
        this.router.navigate(['/banking/account-items']);
      },
    );
  }
}
