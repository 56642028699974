import Axios from 'axios';
import { environment } from '../../../environments/environment';
import { ReduxAction } from './redux-action';
import { ReduxApiActionTypes } from './redux-api.action-types';

export abstract class ReduxApiActions<T = any> {
  public abstract endpoint: string;
  public abstract reduxActionValue: string;

  public create(item: T) {
    return (dispatch, currentState) => {
      Axios.post(`${environment.endpoint}${this.endpoint}`, item, {
        headers: {
          'Authorization': `Bearer ${currentState().user.jwtToken}`,
        },
      })
        .then(({ data }) => dispatch(this.createComplete()));
    };
  }

  public selectAll() {
    return (dispatch, currentState) => {
      Axios.get(`${environment.endpoint}${this.endpoint}`, {
        headers: {
          'Authorization': `Bearer ${currentState().user.jwtToken}`,
        },
      })
        .then(({ data }) => {
          dispatch(this.selectListComplete(
            data.data,
          ));
        });
    };
  }

  public list(page: number = 1, limit: number = 10) {
    return (dispatch, currentState) => {
      Axios.get(`${environment.endpoint}${this.endpoint}`, {
        headers: {
          'Authorization': `Bearer ${currentState().user.jwtToken}`,
        },
      })
        .then(({ data }) => {
          dispatch(this.listComplete(
            data.data,
          ));
        });
    };
  }

  public get(id: string) {
    return (dispatch, currentState) => {
      Axios.get(`${environment.endpoint}${this.endpoint}/${id}`, {
        headers: {
          'Authorization': `Bearer ${currentState().user.jwtToken}`,
        },
      })
        .then(({ data }) => {
          dispatch(this.itemComplete(
            data.data,
          ));
        });
    };
  }

  public update(id: string, item: Partial<T>) {
    return (dispatch, currentState) => {
      Axios.put(`${environment.endpoint}${this.endpoint}/${id}`, item, {
        headers: {
          'Authorization': `Bearer ${currentState().user.jwtToken}`,
        },
      })
        .then(({ data }) => {
          dispatch(this.updateComplete());
          dispatch(this.list(1));
        });
    };
  }

  public delete(id: string) {
    return (dispatch, currentState) => {
      Axios.delete(`${environment.endpoint}${this.endpoint}/${id}`, {
        headers: {
          'Authorization': `Bearer ${currentState().user.jwtToken}`,
        },
      })
        .then(({ data }) => {
          dispatch(this.createComplete());
          dispatch(this.list(1));
        });
    };
  }

  public clearAll(): ReduxAction {
    return { type: this.createActionValue(ReduxApiActionTypes.CLEAR_ALL) };
  }

  public clearOne(): ReduxAction {
    return { type: this.createActionValue(ReduxApiActionTypes.CLEAR_ONE) };
  }

  protected deleteComplete(): ReduxAction<T[]> {
    return { type: this.createActionValue(ReduxApiActionTypes.DELETE) };
  }

  protected createComplete(): ReduxAction<T[]> {
    return { type: this.createActionValue(ReduxApiActionTypes.CREATE) };
  }

  protected updateComplete(): ReduxAction<T[]> {
    return { type: this.createActionValue(ReduxApiActionTypes.UPDATE) };
  }

  protected selectListComplete(payload: T[]): ReduxAction<T[]> {
    return {
      type: this.createActionValue(ReduxApiActionTypes.SELECT_ALL),
      payload,
    };
  }

  protected listComplete(payload: T[]): ReduxAction<T[]> {
    return {
      type: this.createActionValue(ReduxApiActionTypes.GET_ALL),
      payload,
    };
  }

  protected itemComplete(payload: T): ReduxAction<T> {
    return {
      type: this.createActionValue(ReduxApiActionTypes.GET_ONE),
      payload,
    };
  }

  protected createActionValue(initial: string): string {
    return ReduxApiActionTypes.getReducerAction(initial, this.reduxActionValue);
  }
}
