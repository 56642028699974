<div class="form-group">
  <label class="col-sm-{{ labelWidth }} control-label" for="emailAddress-search"
    >E-Mail</label
  >
  <div class="col-sm-{{ lbWidth(12) }}">
    <input
      [(ngModel)]="model.emailAddress"
      [required]="isRequired('emailAddress')"
      autocomplete="nope"
      class="form-control"
      id="emailAddress-search"
      name="emailAddress-search"
      placeholder="E-Mail Address"
      type="email"
    />
  </div>
</div>

<!-- Start Creditor Only E-Mail Addresses -->
<ng-container *ngIf="creditorEmails === true">
  <div class="form-group">
    <label
      class="col-sm-{{ labelWidth }} control-label"
      for="supervisoryEmailAddress-search"
      >Supervisory E-Mail</label
    >
    <div class="col-sm-{{ lbWidth(12) }}">
      <input
        [(ngModel)]="model.supervisoryEmailAddress"
        [required]="isRequired('supervisoryEmailAddress')"
        autocomplete="nope"
        class="form-control"
        id="supervisoryEmailAddress-search"
        name="supervisoryEmailAddress-search"
        placeholder="Supervisory E-Mail Address"
        type="email"
      />
    </div>
  </div>

  <div class="form-group">
    <label
      class="col-sm-{{ labelWidth }} control-label"
      for="remittanceEmailAddress-search"
      >Remittance E-Mail</label
    >
    <div class="col-sm-{{ lbWidth(12) }}">
      <input
        [(ngModel)]="model.remittanceEmailAddress"
        [required]="isRequired('remittanceEmailAddress')"
        autocomplete="nope"
        class="form-control"
        id="remittanceEmailAddress-search"
        name="remittanceEmailAddress-search"
        placeholder="Remittance E-Mail Address"
        type="email"
      />
    </div>
  </div>
</ng-container>
<!-- End Creditor Only E-Mail Addresses -->

<div class="form-group">
  <label
    class="col-sm-{{ labelWidth }} control-label"
    for="landlineNumber-search"
    >Landline</label
  >
  <div class="col-sm-{{ lbWidth(12) }}">
    <input
      [(ngModel)]="model.landlineNumber"
      [required]="isRequired('landlineNumber')"
      autocomplete="nope"
      class="form-control"
      id="landlineNumber-search"
      name="landlineNumber-search"
      placeholder="Landline Number"
      type="text"
    />
  </div>
</div>

<div class="form-group">
  <label class="col-sm-{{ labelWidth }} control-label" for="mobileNumber-search"
    >Mobile</label
  >
  <div class="col-sm-{{ lbWidth(12) }}">
    <input
      [(ngModel)]="model.mobileNumber"
      [required]="isRequired('mobileNumber')"
      autocomplete="nope"
      class="form-control"
      id="mobileNumber-search"
      name="mobileNumber-search"
      placeholder="Mobile Number"
      type="text"
    />
  </div>
</div>

<div class="form-group">
  <label class="col-sm-{{ labelWidth }} control-label" for="faxNumber-search"
    >Fax</label
  >
  <div class="col-sm-{{ lbWidth(12) }}">
    <input
      [(ngModel)]="model.faxNumber"
      [required]="isRequired('faxNumber')"
      autocomplete="nope"
      class="form-control"
      id="faxNumber-search"
      name="faxNumber-search"
      placeholder="Fax Number"
      type="text"
    />
  </div>
</div>

<div class="form-group">
  <label class="col-sm-{{ labelWidth }} control-label" for="webAddress-search"
    >Web</label
  >
  <div class="col-sm-{{ lbWidth(12) }}">
    <input
      [(ngModel)]="model.webAddress"
      [required]="isRequired('webAddress')"
      autocomplete="nope"
      class="form-control"
      id="webAddress-search"
      name="webAddress-search"
      placeholder="Web Address"
      type="text"
    />
  </div>
</div>
