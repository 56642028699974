import { Injectable } from '@angular/core';
import { CaseExpenditure } from './case-expenditure';
import { Observable } from 'rxjs';

import { HttpRequestService } from '../../shared/http/http-request.service';
import { HttpOptions } from '../../shared/http/http-options';

@Injectable()
export class CaseExpenditureService extends HttpRequestService {
  /**
   * The endpoint that we call to get the case information.
   *
   * @type {string}
   */
  private readonly endpoint: string = 'cases/:id/expenditures';

  /**
   * Brings back a list of all accessible cases.
   *
   * @param {number} page The page number we are currently on.
   * @param {number} limit The number of cases to display per page.
   * @returns {Observable<CaseExpenditure[]>}
   */
  public listCaseExpenditures(
    page?: number,
    limit?: number,
    options?: HttpOptions,
  ): Observable<CaseExpenditure[]> {
    return this.list(this.endpoint, page, undefined, limit, options);
  }

  /**
   * Finds details of the case from the given ID.
   *
   * @param {string} caseId The ID of the case we require details for.
   * @returns {Observable<CaseExpenditure>}
   */
  public findCaseExpenditure(
    caseId: string,
    options?: HttpOptions,
  ): Observable<CaseExpenditure> {
    return this.findById(this.endpoint, caseId, options);
  }

  /**
   * Creates a new case from the given Case object.
   *
   * @param {CaseExpenditure} newCase The details of the case to be created.
   * @returns {Observable<CaseExpenditure | boolean>}
   */
  public createCaseExpenditure(
    newCase: CaseExpenditure,
    options?: HttpOptions,
  ): Observable<CaseExpenditure | boolean> {
    return this.create(this.endpoint, newCase, options);
  }

  /**
   * Updates a case with the given case object for the given case ID.
   *
   * @param {string} caseId The ID of the case needing updating.
   * @param {CaseExpenditure} newDetails The new case object to update with.
   * @returns {Observable<Case | boolean>}
   */
  public updateCaseExpenditure(
    caseId: string,
    newDetails: CaseExpenditure,
    options?: HttpOptions,
  ): Observable<CaseExpenditure | boolean> {
    return this.update(this.endpoint, caseId, newDetails, options);
  }

  /**
   * Deletes a case based on the given case ID.
   *
   * @param {string} caseId The ID of the case needing deleting.
   * @returns {Observable<boolean>}
   */
  public deleteCaseExpenditure(
    caseId: string,
    options?: HttpOptions,
  ): Observable<boolean> {
    return this.delete(this.endpoint, caseId, options);
  }
}
