import { Component, Input, OnInit } from '@angular/core';

import { CaseCreditor } from '../case-creditor';
import { CaseCreditorService } from '../case-creditor.service';
import { SelectComparisons } from '../../../shared/pipes/select.comparisons';

@Component({
  selector: 'app-shared-case-creditor-select',
  templateUrl: 'select.component.html',
})
export class SharedCaseCreditorSelectComponent
  extends SelectComparisons
  implements OnInit {
  @Input() caseId: string;

  @Input() model: any;

  @Input() required: boolean = false;

  @Input() singleOnly: boolean;

  public isLoading: boolean = true;

  public caseCreditors: CaseCreditor[];

  constructor(private readonly caseCaseCreditorService: CaseCreditorService) {
    super();
    this.caseCaseCreditorService.loading.subscribe((isLoading) => {
      this.isLoading = isLoading;
    });
  }

  public ngOnInit(): void {
    this.caseCaseCreditorService
      .listCaseCreditors(1, 10000, { parameters: { id: this.caseId } })
      .subscribe((caseCreditors: CaseCreditor[]) => {
        this.caseCreditors = caseCreditors;

        // if (caseCreditors.length === 1) {
        //   this.model.caseCreditor = caseCreditors[0];
        // } else {
        //   this.model.caseCreditor = null;
        // }
      });
  }
}
