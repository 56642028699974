<div class="form-group clearfix">
  <label for="dateOfProxy" class="col-sm-5 control-label">Creditor</label>
  <div class="col-sm-7">
    {{ creditorVote.creditor }}
  </div>
</div>

<div class="form-group clearfix">
  <label for="dateOfProxy" class="col-sm-5 control-label">Reference</label>
  <div class="col-sm-7">
    {{ creditorVote.reference }}
  </div>
</div>

<div class="form-group clearfix">
  <label for="dateOfProxy" class="col-sm-5 control-label">Applicant</label>
  <div class="col-sm-7">
    {{ creditorVote.applicant }}
  </div>
</div>

<div class="form-group clearfix">
  <label for="dateOfProxy" class="col-sm-5 control-label">Date Of Proxy</label>
  <div class="col-sm-7">
    <app-shared-date [(ngModel)]="creditorVote.dateOfProxy"></app-shared-date>
  </div>
</div>

<div class="form-group clearfix">
  <label for="proxyHolder" class="col-sm-5 control-label">Proxy Holder</label>
  <div class="col-sm-7">
    <input
      type="text"
      class="form-control"
      id="proxyHolder"
      name="proxyHolder"
      [(ngModel)]="creditorVote.proxyHolder"
    />
  </div>
</div>

<div class="form-group clearfix">
  <label for="proxyHolder" class="col-sm-5 control-label"
    >Discretion Given</label
  >
  <div class="col-sm-7">
    <select
      class="form-control"
      id="discretionGiven"
      name="discretionGiven"
      [(ngModel)]="creditorVote.discretionGiven"
    >
      <option [ngValue]="true">Yes</option>
      <option [ngValue]="false">No</option>
    </select>
  </div>
</div>

<div class="form-group clearfix">
  <label for="voteAcceptedNoMods" class="col-sm-5 control-label"
    >Vote for Acceptance No Mods</label
  >
  <div class="col-sm-7">
    <app-shared-currency
      [required]="false"
      [(ngModel)]="creditorVote.voteAcceptedNoMods"
    ></app-shared-currency>
  </div>
</div>

<div class="form-group clearfix">
  <label for="voteAcceptedWithMods" class="col-sm-5 control-label"
    >Vote for Acceptance With Mods</label
  >
  <div class="col-sm-7">
    <app-shared-currency
      [required]="false"
      [(ngModel)]="creditorVote.voteAcceptedWithMods"
    ></app-shared-currency>
  </div>
</div>

<div class="form-group clearfix">
  <label for="voteAcceptedWithModsRejected" class="col-sm-5 control-label"
    >Vote for Acceptance With Mods (Rejected)</label
  >
  <div class="col-sm-7">
    <app-shared-currency
      [required]="false"
      [(ngModel)]="creditorVote.voteAcceptedWithModsRejected"
    ></app-shared-currency>
  </div>
</div>

<div class="form-group clearfix">
  <label for="voteRejected" class="col-sm-5 control-label">Vote Rejected</label>
  <div class="col-sm-7">
    <app-shared-currency
      [required]="false"
      [(ngModel)]="creditorVote.voteRejected"
    ></app-shared-currency>
  </div>
</div>

<div class="form-group clearfix">
  <label for="invalidProxy" class="col-sm-5 control-label">Invalid Proxy</label>
  <div class="col-sm-7">
    <app-shared-currency
      [required]="false"
      [(ngModel)]="creditorVote.invalidProxy"
    ></app-shared-currency>
  </div>
</div>
