<div class="row">
  <div class="col-sm-12">
    <section class="panel">
      <app-page-title title="List Invoices" [loading]="isLoading">
        {{ totalResults }} Results Found
      </app-page-title>

      <div class="panel-body">
        <table class="table table-striped">
          <thead>
            <tr>
              <th>Date</th>
              <th>Account Item</th>
              <th>Invoice Filename</th>
              <th>Total Value</th>
              <th>Reconciled</th>
              <th class="text-right">
                <button
                  type="button"
                  class="btn btn-sm btn-success"
                  href="javascript:;"
                  (click)="upload.open(accountId)"
                >
                  Upload Invoice
                </button>
              </th>
            </tr>
          </thead>

          <tbody *ngIf="invoices && invoices.length > 0">
            <tr *ngFor="let invoice of invoices">
              <td>{{ invoice?.created_at | date: 'dd/MM/yyyy' }}</td>
              <td>{{ invoice?.chart_of_accounts_name }}</td>
              <td>{{ invoice?.filename }}</td>
              <td>{{ invoice?.value / 100 | toAccountancy }}</td>
              <td>
                <b
                  class="text-danger"
                  *ngIf="
                    invoice &&
                    (invoice.reconciled === false ||
                      invoice.reconciled === 'false')
                  "
                  >Not Reconciled</b
                >
                <b
                  class="text-success"
                  *ngIf="
                    invoice &&
                    (invoice.reconciled === true ||
                      invoice.reconciled === 'true')
                  "
                  >Reconciled</b
                >
              </td>
              <td class="text-right">
                <div class="btn-group">
                  <a
                    href="{{ invoice?.secureFileLink }}"
                    target="_blank"
                    item="button"
                    class="btn btn-sm btn-warning"
                    >View</a
                  >
                  <button
                    [title]="
                      !invoice || !canDeleteInvoice(invoice)
                        ? 'Reconciled invoices cannot be deleted.'
                        : ''
                    "
                    [disabled]="!invoice || !canDeleteInvoice(invoice)"
                    item="button"
                    class="btn btn-sm btn-warning dropdown-toggle"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    <span class="caret"></span>
                    <span class="sr-only">Toggle Dropdown</span>
                  </button>
                  <ul
                    *ngIf="invoice && canDeleteInvoice(invoice)"
                    class="dropdown-menu dropdown-menu-right"
                  >
                    <li>
                      <a [routerLink]="['/financials/invoicing', invoice.id]"
                        >Reconcile</a
                      >
                    </li>
                    <li>
                      <a
                        href="javascript:;"
                        (click)="deleteModal.confirmDialog(invoice.id)"
                        >Delete</a
                      >
                    </li>
                  </ul>
                </div>
              </td>
            </tr>
          </tbody>

          <tfoot>
            <tr>
              <td colspan="99" class="text-center">
                <app-pagination
                  [totalPages]="totalPages"
                  [currentPage]="currentPage"
                  (pageChanged)="onPageChanged($event)"
                ></app-pagination>
              </td>
            </tr>
          </tfoot>
        </table>
      </div>
    </section>
  </div>
</div>

<app-delete-modal
  type="accountItem"
  (confirm)="delete($event)"
  [itemDeleted]="accountItemDeleted"
  #deleteModal
>
</app-delete-modal>

<app-financials-invoicing-upload
  (completed)="refreshAndMove($event)"
  #upload
></app-financials-invoicing-upload>
