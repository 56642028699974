import { Alert, Button } from 'react-bootstrap';
import { LinkProps, LinkWrapper } from 'react-inside-angular';
import { Link as RRDLink, useHistory as RRDUseHistory } from 'react-router-dom';

import AddressType from '../../../../../chief-types/src/AddressType';
import AddressTypeForm from './AddressTypeForm';
import Panel from '../../Components/Panel';
import useAddressTypes from './UseAddressTypes';
import useForm from '../../Hooks/UseForm';
import useLoggedInUser from '../../Hooks/UseLoggedInUser';

export type CreateAddressTypeProps = {
  authService: any;
};

const CreateAddressType = ({
  Navigate,
  authService,
}: LinkProps<CreateAddressTypeProps>) => {
  const Link = Navigate ? LinkWrapper(Navigate).Component : RRDLink;
  const history = Navigate ? LinkWrapper(Navigate).useHistory : RRDUseHistory();

  const [addressType, setAddressTypeValue] = useForm<AddressType>({ name: '' });
  const { error, loading, createItem } = useAddressTypes(false);
  const { userLoading, userError, hasAllDomainsPermission, userDomains } = useLoggedInUser(
    authService,
  );

  return (
    <Panel title="Create Address Type" loading={loading || userLoading}>
      {error && (
        <Alert>
          <h4>Error</h4>
          <p>{error}</p>
        </Alert>
      )}
      {userError && (
        <Alert>
          <h4>Error</h4>
          <p>{userError}</p>
        </Alert>
      )}

      <AddressTypeForm
        addressType={addressType}
        setAddressTypeValue={setAddressTypeValue}
        hasAllDomainsPermission={hasAllDomainsPermission}
        userDomains={userDomains}
      />

      <Button
        className="pull-right"
        onClick={() =>
          createItem(addressType).then(() => history.push('address-types'))
        }
      >
        Save
      </Button>
    </Panel>
  );
};

export default CreateAddressType;
