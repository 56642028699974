import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { AngularFireFunctions } from '@angular/fire/functions';

import { AuthService } from '../../../auth/auth.service';
import { FinancialsDistributionService } from '../financials-distribution.service';

@Component({
  selector: 'app-financials-distribution-summary',
  templateUrl: 'summary.component.html',
})
export class FinancialsDistributionSummaryComponent implements OnInit {
  public summaryCurrentPage: number = 1;
  public summaryTotalPages: number = 1;
  public catsCurrentPage: number = 1;
  public catsTotalPages: number = 1;
  public creditorsCurrentPage: number = 1;
  public creditorsTotalPages: number = 1;

  public catReferenceSearch: string = '';
  public creditorReferenceSearch: string = '';

  public summary: any[] = [];
  public cat1s: any[] = [];
  public cat1Headers: any[] = [];
  public creditors: any[] = [];
  public creditorHeaders: any[] = [];
  public allocationsInProgress = false;

  public selectedTab = 'summary';

  /**
   * Are we currently performing a loading action?
   *
   * @item {boolean}
   */
  public isLoading: boolean = true;

  /**
   * The total number of pages in this list of banking-account-items.
   *
   * @item {number}
   */
  public totalPages: number = 1;

  /**
   * The current page of banking-account-items that we are viewing.
   *
   * @item {number}
   */
  public currentPage: number = 1;

  /**
   * The total number of banking-account-items that can be viewed in the system.
   *
   * @item {number}
   */
  public totalResults: number = 0;

  public acceptingAllocations: boolean = false;

  public savingChecks: string[] = [];

  constructor(
    public readonly distributionService: FinancialsDistributionService,
    public readonly authService: AuthService,
    protected readonly httpService: HttpClient,
    protected functions: AngularFireFunctions,
  ) {
    this.distributionService.automaticAllocationsInProgress().subscribe((r) => {
      if (this.allocationsInProgress === true && r === false) {
        this.loadItems();
      }
      this.allocationsInProgress = r;
    });
    this.distributionService.loading.subscribe((isLoading) => {
      this.isLoading = isLoading;
    });
    this.distributionService.totalPages.subscribe((totalPages) => {
      this.totalPages = totalPages;
    });
    this.distributionService.currentPage.subscribe((currentPage) => {
      this.currentPage = currentPage;
    });
    this.distributionService.totalResults.subscribe((totalResults) => {
      this.totalResults = totalResults;
    });
  }

  ngOnInit(): void {
    this.loadItems();
  }

  public saveItemLoading: boolean[] = [];

  public isSaveItemLoading = (id: string): boolean =>
    this.saveItemLoading && this.saveItemLoading[id] === true;

  public readonly updateAllocationSummary = this.functions.httpsCallable(
    `updateAllocationSummary`,
  );

  public readonly saveItemCache = (item: any) => {
    this.saveItemLoading[item.id] = true;

    this.updateAllocationSummary({
      creditorId: item.id,
      method: item.method,
      chequeNumber: item.cheque_number,
    }).subscribe((r) => {
      this.saveItemLoading[item.id] = false;
    });
  };

  public readyToAllocate(): boolean {
    if (!this.loadedCreditors || !this.loadedCreditors[0]) {
      return false;
    }

    return this.loadedCreditors[0]['ready_to_allocate'] ?? false;
  }

  public get loadedCreditors() {
    return !this.summary
      ? []
      : this.summary.filter((i) => i.type === 'creditor');
  }

  public get loadedCat1s() {
    return !this.summary ? [] : this.summary.filter((i) => i.type === 'cat1');
  }

  public loadItems() {
    this.loadDistributionSummary(this.summaryCurrentPage);
    this.loadPendingCat1s(this.catsCurrentPage);
    this.loadPendingCreditors(this.creditorsCurrentPage);
  }

  public updateSummaryValue(
    type: 'Cat1' | 'Creditor',
    reference: string,
    id: number,
    newValue: number,
    cat1Check: any,
  ) {
    console.log(cat1Check);
    const indexCheck = `${reference}:${id}`;
    this.savingChecks.push(indexCheck);

    console.log(`Saving: ${reference}:${id} - ${newValue}`);

    this.distributionService
      .updateFigure(type, reference, id, newValue)
      .subscribe((r) => {
        this.savingChecks.splice(this.savingChecks.indexOf(indexCheck), 1);
        this.loadDistributionSummary(1);
      });
  }

  public isSaving(reference: string, id: number) {
    return this.savingChecks.includes(`${reference}:${id}`);
  }

  public toggleAndDelete(id) {
    if (
      window.confirm(
        `Are you sure you want to remove all CAT1 allocations and set ${id} to manual mode?`,
      )
    ) {
      this.distributionService.switchCaseToManual(id).subscribe((r) => {
        this.loadItems();
      });
    }
  }

  public onPageChanged(pageType: string, pageNumber: number): void {
    switch (pageType) {
      case 'cats':
        this.catsCurrentPage = pageNumber;
        break;
      case 'creditors':
        this.creditorsCurrentPage = pageNumber;
        break;
      default:
        this.summaryCurrentPage = pageNumber;
        break;
    }
    this.loadItems();
  }

  public startAllocating() {
    this.distributionService.acceptAllocations().subscribe((r) => {
      this.acceptingAllocations = r;
    });
  }

  protected loadDistributionSummary(page?: number, limit?: number) {
    let distributionSummary = this.distributionService
      .showDistributionSummary(page, limit)
      .subscribe(
        (result: [any[], number]) => {
          this.summaryTotalPages = result[1];
          this.summary = result[0].map((i) => {
            return i;
          });
          distributionSummary.unsubscribe();
        },
        (e) => {
          this.summary = [];
          distributionSummary.unsubscribe();
        },
      );
  }

  protected loadPendingCat1s(page?: number, limit?: number) {
    let pendingCat1s = this.distributionService
      .showPendingCat1s(page, limit, undefined, {
        search: this.catReferenceSearch,
      })
      .subscribe((result: [any[], number]) => {
        this.cat1Headers = result[0].shift();
        this.catsTotalPages = result[1];
        this.cat1s = result[0];
        pendingCat1s.unsubscribe();
      });
  }

  protected loadPendingCreditors(page?: number, limit?: number) {
    let pendingCreditors = this.distributionService
      .showPendingCreditors(page, limit, undefined, {
        search: this.creditorReferenceSearch,
      })
      .subscribe((result: [any[], number]) => {
        this.creditorHeaders = result[0].shift();
        this.creditorsTotalPages = result[1];
        this.creditors = result[0];
        pendingCreditors.unsubscribe();
      });
  }

  public setTab(tab: string) {
    this.selectedTab = tab;
  }

  public tabSelected(tab: string) {
    return this.selectedTab === tab;
  }
}
