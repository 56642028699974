import { RouterModule, Routes } from '@angular/router';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule } from '@angular/common/http';
import { BankingAccountImportService } from './banking-account-import.service';
import {BankingAccountImportsListComponent} from './list/list.component';
import {AuthGuard} from '../../../../auth/auth.guard';
import {SharedModule} from '../../../../shared/shared.module';


const routes: Routes = [
  {
    path: 'banking/accounts/:accountId/imports',
    component: BankingAccountImportsListComponent,
    canActivate: [AuthGuard],
  },
];

@NgModule({
  declarations: [
    BankingAccountImportsListComponent,
  ],
  imports: [
    BrowserModule,
    RouterModule,
    FormsModule,
    HttpClientModule,
    SharedModule,
    RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' }),
  ],
  exports: [RouterModule],
  providers: [AuthGuard, BankingAccountImportService],
})
export class BankingAccountImportRoutingModule {}
