import { Injectable } from '@angular/core';
import { Task } from './task';
import { Observable } from 'rxjs';

import { HttpRequestService } from '../shared/http/http-request.service';

@Injectable()
export class TaskService extends HttpRequestService {
  /**
   * The endpoint that we call to get the task information.
   *
   * @type {string}
   */
  private readonly endpoint: string = 'tasks';

  /**
   * Brings back a list of all accessible tasks.
   *
   * @param {number} page The page number we are currently on.
   * @param {number} limit The number of tasks to display per page.
   * @returns {Observable<Task[]>}
   */
  public listTasks(page?: number, limit?: number): Observable<Task[]> {
    return this.list(this.endpoint, page, undefined, limit);
  }

  /**
   * Finds details of the task from the given ID.
   *
   * @param {string} taskId The ID of the task we require details for.
   * @returns {Observable<Task>}
   */
  public findTask(taskId: string): Observable<Task> {
    return this.findById(this.endpoint, taskId);
  }

  /**
   * Creates a new task from the given Task object.
   *
   * @param {Task} newTask The details of the task to be created.
   * @returns {Observable<Task | boolean>}
   */
  public createTask(newTask: Task): Observable<Task | boolean> {
    return this.create(this.endpoint, newTask);
  }

  /**
   * Updates a task with the given task object for the given task ID.
   *
   * @param {string} taskId The ID of the task needing updating.
   * @param {Task} newDetails The new task object to update with.
   * @returns {Observable<Task | boolean>}
   */
  public updateTask(
    taskId: string,
    newDetails: Task,
  ): Observable<Task | boolean> {
    return this.update(this.endpoint, taskId, newDetails);
  }

  /**
   * Deletes a task based on the given task ID.
   *
   * @param {string} taskId The ID of the task needing deleting.
   * @returns {Observable<boolean>}
   */
  public deleteTask(taskId: string): Observable<boolean> {
    return this.delete(this.endpoint, taskId);
  }
}
