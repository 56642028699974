<section class="panel">
  <header class="panel-heading tab-bg-light">
    Clients By Creditor Report

    <a [href]="downloadReportUrl" target="_blank" class="btn btn-danger btn-xs pull-right">
      <i class="fa fa-download"></i>
    </a>

  </header>

  <div class="panel-body">
    <table class="table table-striped">
      <thead>
      <tr>
        <th>Creditor</th>
        <th>Unique Reference</th>
        <th>Client Name</th>
        <th>Address</th>
        <th>Postal Code</th>
        <th>Date Of Birth</th>
        <th>Creditor Reference</th>
        <th>Value</th>
        <th>POD Value</th>
        <th>Current Status</th>
        <th>&nbsp;</th>
      </tr>
      </thead>
      <tbody>
      <tr *ngFor="let item of detailedReport">
        <td>{{ item.creditor_name }}</td>
        <td>{{ item.unique_reference }}</td>
        <td>{{ item.first_name }} {{ item.last_name }}</td>
        <td>{{ item.address }}</td>
        <td>{{ item.postal_code }}</td>
        <td>{{ item.date_of_birth }}</td>
        <td>{{ item.creditor_reference }}</td>
        <td>{{ item.value_of_debt / 100 | toCurrency }}</td>
        <td>{{ item.proof_of_debt_value / 100 | toCurrency }}</td>
        <td>{{ item.current_status }}</td>
        <td class="text-right">
          <a [routerLink]="['/cases', item.client_id]" target="_blank" class="btn btn-success btn-sm"><i class="fa fa-user"></i></a>
        </td>
      </tr>
      </tbody>
    </table>
  </div>

</section>
