import { NgModule } from '@angular/core';
import { CaseUserService } from './case-user.service';
import { HttpClientModule } from '@angular/common/http';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule } from '@angular/forms';
import { SharedModule } from '../../shared/shared.module';

@NgModule({
  imports: [BrowserModule, FormsModule, HttpClientModule, SharedModule],
  providers: [CaseUserService],
})
export class CaseUserRoutingModule {}
