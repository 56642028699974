import { Component, EventEmitter, Input, OnInit } from '@angular/core';

import { AuthService } from '../../../auth/auth.service';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../../environments/environment';

@Component({
  selector: 'app-case-detailed-transactions-view',
  templateUrl: 'view.component.html',
})
export class CaseDetailedTransactionsViewComponent implements OnInit {
  @Input() caseRefreshed: EventEmitter<string>;
  @Input() caseId: string;
  public loading: boolean = false;
  public detailedReport: any[];

  constructor(
    private readonly http: HttpClient,
    public readonly authService: AuthService,
  ) {}

  ngOnInit(): void {
    this.loadReport(1);
    this.caseRefreshed.subscribe((id) => {
      this.caseId = id;
      this.loadReport(1);
    });
  }

  get detailedLink(): string {
    return `${
      environment.apiUrl
    }reports/detailed-customer-transactions?caseId=${
      this.caseId
    }&limit=100000&auth=${this.authService.getToken()}`;
  }

  get downloadReportUrl(): string {
    return `${this.detailedLink}&download=true`;
  }

  protected loadReport(page: number) {
    this.loading = true;
    this.http.get<any>(this.detailedLink).subscribe((data) => {
      this.loading = false;
      this.detailedReport = data.data.reverse();
    });
  }
}
