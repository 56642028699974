import { Injectable } from '@angular/core';
import { AccountGroup } from './account-group';
import { Observable } from 'rxjs';

import { HttpRequestService } from '../../shared/http/http-request.service';

@Injectable()
export class AccountGroupService extends HttpRequestService {
  /**
   * The endpoint that we call to get the accountGroup information.
   *
   * @type {string}
   */
  private readonly endpoint: string = 'account-groups';

  /**
   * Brings back a list of all accessible account-groups.
   *
   * @param {number} page The page number we are currently on.
   * @param {number} limit The number of account-groups to display per page.
   * @returns {Observable<AccountGroup[]>}
   */
  public listAccountGroups(
    page?: number,
    limit?: number,
  ): Observable<AccountGroup[]> {
    return this.list(this.endpoint, page, undefined, limit);
  }

  /**
   * Finds details of the accountGroup from the given ID.
   *
   * @param {string} accountGroupId The ID of the accountGroup we require details for.
   * @returns {Observable<AccountGroup>}
   */
  public findAccountGroup(accountGroupId: string): Observable<AccountGroup> {
    return this.findById(this.endpoint, accountGroupId);
  }

  /**
   * Creates a new accountGroup from the given AccountGroup object.
   *
   * @param {AccountGroup} newAccountGroup The details of the accountGroup to be created.
   * @returns {Observable<AccountGroup | boolean>}
   */
  public createAccountGroup(
    newAccountGroup: AccountGroup,
  ): Observable<AccountGroup | boolean> {
    return this.create(this.endpoint, newAccountGroup);
  }

  /**
   * Updates a accountGroup with the given accountGroup object for the given accountGroup ID.
   *
   * @param {string} accountGroupId The ID of the accountGroup needing updating.
   * @param {AccountGroup} newDetails The new accountGroup object to update with.
   * @returns {Observable<AccountGroup | boolean>}
   */
  public updateAccountGroup(
    accountGroupId: string,
    newDetails: AccountGroup,
  ): Observable<AccountGroup | boolean> {
    return this.update(this.endpoint, accountGroupId, newDetails);
  }

  /**
   * Deletes a accountGroup based on the given accountGroup ID.
   *
   * @param {string} accountGroupId The ID of the accountGroup needing deleting.
   * @returns {Observable<boolean>}
   */
  public deleteAccountGroup(accountGroupId: string): Observable<boolean> {
    return this.delete(this.endpoint, accountGroupId);
  }
}
