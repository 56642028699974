import { Component, Input } from '@angular/core';
import { Expenditure } from '../expenditure';

@Component({
  selector: 'app-expenditure-form',
  templateUrl: 'form.component.html',
})
export class ExpenditureFormComponent {
  @Input() expenditure: Expenditure;
}
