<div class="row">
  <div class="col-md-6"><h4>Distributions</h4></div>
  <div class="col-md-6 text-right">
    <select
      class="form-control"
      [(ngModel)]="selectedFilterId"
      (ngModelChange)="filterCreditors()"
    >
      <option [value]="null">No Filter</option>
      <option *ngFor="let creditor of uniqueCreditors" [value]="creditor.id">
        {{ creditor?.creditor?.name }} ({{ creditor?.caseCreditor?.reference }})
      </option>
    </select>
  </div>
</div>

<div class="responsive">
  <table class="table table-striped">
    <thead>
      <tr>
        <th>Creditor</th>
        <th>Value</th>
        <th>Method</th>
        <th>Details</th>
        <th>Allocated</th>
        <th>Distributed</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let item of results">
        <td>
          {{ item?.creditor?.name }} ({{ item?.caseCreditor?.reference }})
        </td>
        <td>{{ item?.value / 100 | toAccountancy }}</td>
        <td>{{ item?.method }}</td>
        <td>{{ item?.details }}</td>
        <td>
          {{
            item?.allocatedAt ? (item?.allocatedAt | date: 'dd/MM/yyyy') : '-'
          }}
        </td>
        <td>
          {{
            item?.distributedAt
              ? (item?.distributedAt | date: 'dd/MM/yyyy')
              : '-'
          }}
        </td>
      </tr>
    </tbody>
  </table>
</div>
