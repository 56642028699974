<div class="form-group">
  <label for="name" class="col-sm-2 control-label">Name</label>
  <div class="col-sm-10">
    <input type="text" class="form-control" name="name" id="name" placeholder="Document Name" [(ngModel)]="document.name" required>
  </div>
</div>

<div class="form-group">
  <label for="domain" class="col-sm-2 control-label">Domain</label>
  <div class="col-sm-10">
    <app-domain-select [model]="document" [required]="false"></app-domain-select>
  </div>
</div>

<div class="form-group">
  <label for="folder" class="col-sm-2 control-label">Folder</label>
  <div class="col-sm-10">
    <app-folder-select [model]="document" [required]="false"></app-folder-select>
  </div>
</div>


<div class="form-group">
  <div class="col-sm-offset-2 col-sm-10 text-right">
    <button type="submit" class="btn btn-success">Save</button>
  </div>
</div>
