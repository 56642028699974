import Title from '../../../../../chief-types/src/Title';
import { FormGroup, FormControl } from 'react-bootstrap';

type TitleFormProps = {
  title: Title;
  setTitleValue: (key: keyof Title, value: any) => void;
};

const TitleForm = ({ title, setTitleValue }: TitleFormProps) => {
  return (
    <>
      <FormGroup controlId="formControlsTextarea">
        <label>Title Name</label>
        <FormControl
          type="text"
          defaultValue={title.name}
          placeholder="Title Name"
          onChange={(e: any) => setTitleValue('name', e.target.value)}
        />
      </FormGroup>
    </>
  );
};

export default TitleForm;
