import { Component, OnInit } from '@angular/core';
import { CountryService } from '../country.service';
import { Country } from '../country';
import { Router } from '@angular/router';
import { NotificationService } from '../../shared/notification.service';

@Component({
  selector: 'app-country-create',
  templateUrl: 'create.component.html',
})
export class CountryCreateComponent implements OnInit {
  /**
   * Details of the country being created.
   */
  public country: Country;

  /**
   * Are we currently performing a loading action?
   *
   * @type {boolean}
   */
  public isLoading: boolean = false;

  /**
   * Load up all our required services and subscribe to every observable we need.
   *
   * @param {CountryService} countryService
   * @param {NotificationService} notificationService
   * @param {Router} router
   */
  constructor(
    private countryService: CountryService,
    private notificationService: NotificationService,
    private router: Router,
  ) {
    this.countryService.loading.subscribe(result => (this.isLoading = result));
  }

  /**
   * Initialise the component, creating a new blank country if required.
   */
  public ngOnInit(): void {
    if (!this.country) {
      this.country = new Country();
    }
  }

  /**
   * Perform the create action which adds the new country into the backend.
   */
  public create() {
    const createCountry = this.countryService
      .createCountry(this.country)
      .subscribe(
        (result: Country) => {
          this.notificationService.success(
            `Country Created`,
            `New country ${result.name} has been created with ID of ${
              result.id
            }.`,
            5,
          );
          this.country = new Country();
          createCountry.unsubscribe();
          this.router.navigate(['/countries']);
        },
        err => {
          this.notificationService.error(
            `Could not create Country`,
            err.error.message,
            10,
          );
        },
      );
  }
}
