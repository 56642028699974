import { LinkProps, LinkWrapper } from 'react-inside-angular';
import Panel from '../../Components/Panel';
import useTitles from './UseTitles';
import { Alert, Button } from 'react-bootstrap';
import TitleForm from './TitleForm';
import useForm from '../../Hooks/UseForm';
import Title from '../../../../../chief-types/src/Title';
import { Link as RRDLink, useHistory as RRDUseHistory } from 'react-router-dom';

export type CreateTitleProps = {};

const CreateTitle = ({ Navigate }: LinkProps<CreateTitleProps>) => {
  const Link = Navigate ? LinkWrapper(Navigate).Component : RRDLink;
  const history = Navigate ? LinkWrapper(Navigate).useHistory : RRDUseHistory();

  const [title, setTitleValue] = useForm<Title>({ name: '' });
  const { error, loading, createItem } = useTitles(false);

  return (
    <Panel title="Create Title" loading={loading}>
      {error && (
        <Alert>
          <h4>Error</h4>
          <p>{error}</p>
        </Alert>
      )}

      <TitleForm title={title} setTitleValue={setTitleValue} />

      <Button
        className="pull-right"
        onClick={() => createItem(title).then(() => history.push('titles'))}
      >
        Save
      </Button>
    </Panel>
  );
};

export default CreateTitle;
