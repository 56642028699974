import { Component, Input, forwardRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'app-boolean-answer',
  templateUrl: 'boolean-answer.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => BooleanAnswerComponent),
      multi: true,
    },
  ],
})
export class BooleanAnswerComponent implements ControlValueAccessor {
  result: boolean | undefined = undefined;
  onChange: (_: any) => {};
  onTouched: () => void = () => {};

  writeValue(response: boolean | undefined): void {
    this.result = response;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched() {}

  public setAnswer(answer: boolean): void {
    this.result = answer;
    this.onChange(answer);
  }
}
