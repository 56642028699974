import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';

import {NotificationService} from '../../shared/notification.service';
import {Domain} from '../domain';
import {DomainService} from '../domain.service';

@Component({
  selector: 'app-domain-create',
  templateUrl: 'create.component.html',
})
export class DomainCreateComponent implements OnInit {
  /**
   * Details of the domain being created.
   */
  public domain: Domain;

  /**
   * Are we currently performing a loading action?
   *
   * @type {boolean}
   */
  public isLoading: boolean = false;

  /**
   * Load up all our required services and subscribe to every observable we need.
   *
   * @param {DomainService} domainService
   * @param {NotificationService} notificationService
   * @param {Router} router
   */
  constructor(
    private domainService: DomainService,
    private notificationService: NotificationService,
    private router: Router,
  ) {
    this.domainService.loading.subscribe(result => (this.isLoading = result));
  }

  /**
   * Initialise the component, creating a new blank domain if required.
   */
  public ngOnInit(): void {
    if (!this.domain) {
      this.domain = new Domain();
    }
  }

  /**
   * Perform the create action which adds the new domain into the backend.
   */
  public create() {
    const createDomain = this.domainService.createDomain(this.domain).subscribe(
      (result: Domain) => {
        this.notificationService.success(
          `Domain Created`,
          `New domain ${result.name} has been created with ID of ${result.id}.`,
          5,
        );
        this.domain = new Domain();
        createDomain.unsubscribe();
        this.router.navigate(['/domains']);
      },
      err => {
        this.notificationService.error(
          `Could not create Domain`,
          err.error.message,
          10,
        );
      },
    );
  }
}
