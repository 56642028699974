<div class="form-group">
  <label for="name" class="col-sm-2 control-label">Name</label>
  <div class="col-sm-10">
    <input type="text" class="form-control" name="name" id="name" placeholder="Domain Name" [(ngModel)]="domain.name"
           required>
  </div>
</div>
<div class="form-group">
  <label for="name" class="col-sm-2 control-label">Code</label>
  <div class="col-sm-10">
    <input type="text" class="form-control" name="code" id="code" placeholder="Domain Code" [(ngModel)]="domain.code"
           required>
  </div>
</div>

<div class="form-group">
  <label for="multiplier" class="col-sm-2 control-label">Commission Multiplier</label>
  <div class="col-sm-10">
    <input type="number" step="0.01" class="form-control" name="multiplier" id="multiplier"
           placeholder="Commission Multiplier"
           [(ngModel)]="domain.commissionMultiplier"
           required>
  </div>
</div>

<app-shared-address [model]="domain"></app-shared-address>

<app-shared-contact-details [model]="domain"></app-shared-contact-details>

<div class="form-group">
  <div class="col-sm-offset-2 col-sm-10 text-right">
    <button type="submit" class="btn btn-success">Save</button>
  </div>
</div>
