import * as moment from 'moment';

import { Component, EventEmitter, Input, OnInit } from '@angular/core';

import { AuthService } from '../../../auth/auth.service';
import { NotificationService } from '../../../shared/notification.service';
import { CaseAnnual } from '../case-annual';
import { CaseAnnualService } from '../case-annual.service';

@Component({
  selector: 'app-case-annual-list',
  templateUrl: 'list.component.html',
})
export class CaseAnnualListComponent implements OnInit {
  @Input() caseRefreshed: EventEmitter<string>;

  @Input() caseId: string;

  public caseAnnuals: CaseAnnual[] = [];

  public isLoading: boolean = true;

  public totalPages: number = 1;

  public currentPage: number = 1;

  public totalResults: number = 0;

  public itemDeleted: EventEmitter<boolean> = new EventEmitter<boolean>();

  public annualCreated: EventEmitter<boolean> = new EventEmitter<boolean>();

  public newAnnual: CaseAnnual = new CaseAnnual();

  public editorOptions = {
    placeholder: 'Enter your note...',
    modules: {
      toolbar: ['bold', 'link'],
    },
  };

  public isInTheFuture(dueDate) {
    return moment(dueDate).format('YYYY-MM-DD') > moment().format('YYYY-MM-DD');
  }

  constructor(
    private caseAnnualService: CaseAnnualService,
    private notificationService: NotificationService,
    public authService: AuthService,
  ) {
    this.caseAnnualService.loading.subscribe(isLoading => {
      this.isLoading = isLoading;
    });
    this.caseAnnualService.totalPages.subscribe(totalPages => {
      this.totalPages = totalPages;
    });
    this.caseAnnualService.currentPage.subscribe(currentPage => {
      this.currentPage = currentPage;
    });
    this.caseAnnualService.totalResults.subscribe(totalResults => {
      this.totalResults = totalResults;
    });
  }

  public ngOnInit(): void {
    this.loadCaseAnnuals(1);
    this.caseRefreshed.subscribe(id => {
      this.caseId = id;
      this.onPageChanged(1);
    });
  }

  public onPageChanged(pageNumber: number): void {
    this.loadCaseAnnuals(pageNumber);
  }

  public loadCaseAnnuals(page?: number, limit?: number): void {
    const listCountries = this.caseAnnualService
      .listCaseAnnuals(page, limit, { parameters: { id: this.caseId } })
      .subscribe((caseAnnuals: CaseAnnual[]) => {
        this.caseAnnuals = caseAnnuals.map((ca: any) => {
          try {
            ca.additionalClientObligations = JSON.parse(
              ca.additionalClientObligations,
            );
          } catch (e) {
            ca.additionalClientObligations = [];
          }
          return ca;
        });
        listCountries.unsubscribe();
      });
  }

  public create(formData: CaseAnnual) {
    this.caseAnnualService
      .createCaseAnnual(this.newAnnual, { parameters: { id: this.caseId } })
      .subscribe(results => {
        this.annualCreated.next(true);
        this.onPageChanged(1);
      });
    console.log('creating', this.newAnnual);
  }

  public delete(id: string) {
    this.caseAnnualService.deleteCaseAnnual(id).subscribe(
      deleted => {
        this.notificationService.success(
          `Annual Deleted`,
          `Vehicle with ID ${id} was deleted.`,
          5,
        );
        this.itemDeleted.next(true);
        this.onPageChanged(this.currentPage);
      },
      err => {
        this.notificationService.error(
          `Annual Not Deleted`,
          err.error.message,
          10,
        );
        this.itemDeleted.next(false);
        this.onPageChanged(this.currentPage);
      },
    );
  }
}
