<div class="row">
  <div class="col-sm-12">
    <section class="panel">
      <app-page-title title="Edit Case" [loading]="isLoading"></app-page-title>

      <div class="panel-body">
        <form class="form-horizontal" (ngSubmit)="edit()" ngNativeValidate>
          <app-case-form
            [case]="case"
            *ngIf="case"
            [editing]="true"
          ></app-case-form>
        </form>
      </div>
    </section>
  </div>
</div>
