import { Component } from '@angular/core';
import { CountryService } from '../country.service';
import { Country } from '../country';
import { ActivatedRoute, Router } from '@angular/router';
import { NotificationService } from '../../shared/notification.service';

@Component({
  selector: 'app-country-edit',
  templateUrl: 'edit.component.html',
})
export class CountryEditComponent {
  /**
   * Details of the country being edited.
   */
  public country: Country;

  /**
   * Are we currently performing a loading action?
   *
   * @type {boolean}
   */
  public isLoading: boolean = false;

  /**
   * Load up all our required services and subscribe to every observable we need.
   *
   * @param {CountryService} countryService
   * @param {NotificationService} notificationService
   * @param {Router} router
   * @param {ActivatedRoute} route
   */
  constructor(
    private countryService: CountryService,
    private notificationService: NotificationService,
    private router: Router,
    private route: ActivatedRoute,
  ) {
    this.countryService.loading.subscribe(result => (this.isLoading = result));
    this.route.params.subscribe(params => this.loadCountry(params.id));
  }

  /**
   * Perform the edit action which send the edited country to the backend.
   */
  public edit() {
    const editCountry = this.countryService
      .updateCountry(this.country.id, this.country)
      .subscribe(
        (result: Country) => {
          this.notificationService.success(
            `Country Updated`,
            `The country ${
              result.name
            } been updated with the specified changes.`,
            5,
          );
          this.country = new Country();
          editCountry.unsubscribe();
          this.router.navigate(['/countries']);
        },
        err => {
          this.notificationService.error(
            `Country Not Found`,
            err.error.message,
            10,
          );
          this.router.navigate(['/countries']);
        },
      );
  }

  /**
   * Loads the requested country so we can display it and let the user edit the fields.
   *
   * @param {string} id
   */
  private loadCountry(id: string) {
    this.countryService.findCountry(id).subscribe(
      result => {
        this.country = result;
      },
      err => {
        this.notificationService.error(
          `Country Not Found`,
          err.error.message,
          10,
        );
        this.router.navigate(['/countries']);
      },
    );
  }
}
