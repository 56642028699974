import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { DomSanitizer } from '@angular/platform-browser';
import moment from 'moment';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Observable } from 'rxjs/Observable';
import { map } from 'rxjs/operators';
import 'rxjs/Rx';
import { AuthService } from '../../auth/auth.service';
import { Case } from '../../cases/case';
import { CaseService } from '../../cases/case.service';
import { CaseCreditor } from '../../cases/creditors/case-creditor';
import { CaseCreditorService } from '../../cases/creditors/case-creditor.service';
import { CaseDocumentService } from '../../cases/documents/case-document.service';
import { SelectComparisons } from '../../shared/pipes/select.comparisons';
import { PaperlessOfficeIn } from './paperless-office-in';

@Component({
  selector: 'app-paperless-office-in-report',
  templateUrl: 'paperless-office-in.component.html',
})
export class PaperlessOfficeInComponent
  extends SelectComparisons
  implements OnInit
{
  public items: Observable<PaperlessOfficeIn[]>;
  public countAddedToday = 0;
  public itemToConfirm: PaperlessOfficeIn;
  public confirmDocumentModalRef: BsModalRef;
  public embedPdfFileModalRef: BsModalRef;
  @ViewChild('confirmDocumentModalTemplate')
  confirmDocumentModalView: ElementRef;
  @ViewChild('embedPdfFileModalTemplate') embedPdfFileModalView: ElementRef;
  public saveButtonDisabled: boolean = false;
  public foundCases: Case[] = [];
  public searchTerm: string = '';
  public foundCreditors: CaseCreditor[] = [];
  public embedPdfFileUrlFull = undefined;
  protected readonly path: string = '/paperless-office/mail/inbound';

  constructor(
    protected readonly db: AngularFirestore,
    protected modalService: BsModalService,
    protected caseService: CaseService,
    protected caseCreditorService: CaseCreditorService,
    protected caseDocumentService: CaseDocumentService,
    public sanitizer: DomSanitizer,
    public authService: AuthService,
  ) {
    super();
  }

  public get embedPdfFileUrl() {
    if (!this.embedPdfFileUrlFull) {
      return undefined;
    }
    return this.sanitizer.bypassSecurityTrustResourceUrl(
      `https://drive.google.com/viewerng/viewer?embedded=true&url=${encodeURIComponent(
        this.embedPdfFileUrlFull,
      )}`,
    );
  }

  public get pageTitle() {
    return `Paperless Office - Inbound Mail ${
      this.countAddedToday > 0 &&
      this.authService.hasPermission('reports.paperless-office.approve')
        ? `(${this.countAddedToday} today)`
        : ''
    }`;
  }

  ngOnInit(): void {
    this.items = this.db
      .collection<PaperlessOfficeIn>(this.path, (q) =>
        q.where('completed', '==', false).orderBy('created', 'desc'),
      )
      .snapshotChanges()
      .pipe(
        map((actions) =>
          actions.map((a) => {
            const data = a.payload.doc.data() as PaperlessOfficeIn;
            const id = a.payload.doc.id;
            return { id, ...data };
          }),
        ),
      );
    this.items.subscribe((items) => {
      this.countAddedToday = items?.filter(
        (item) =>
          item.created &&
          moment(item.created).format('DD/MM/YYYY') ===
            moment(new Date(Date.now())).format('DD/MM/YYYY'),
      ).length;
    });
  }

  public clickyPdfFileUrl(originalUrl) {
    return this.sanitizer.bypassSecurityTrustResourceUrl(
      `https://drive.google.com/viewerng/viewer?embedded=true&url=${encodeURIComponent(
        originalUrl,
      )}`,
    );
  }

  viewPdf(file: string): void {
    this.embedPdfFileUrlFull = file;
    this.embedPdfFileModalRef = this.modalService.show(
      this.embedPdfFileModalView as any,
      {
        backdrop: true,
        keyboard: false,
        ignoreBackdropClick: true,
        class: 'modal-lg',
      },
    );
  }

  embedPdfFileModalHide() {
    this.embedPdfFileUrlFull = undefined;
    this.embedPdfFileModalRef.hide();
  }

  update(item: PaperlessOfficeIn): Promise<void> {
    const itemId = item.id;
    delete item.id;

    return this.db.doc(`${this.path}/${itemId}`).set(item);
  }

  approveItem(item: PaperlessOfficeIn) {
    item.approvedAt = new Date().toISOString();
    this.db.doc(`${this.path}/${item.id}`).update(item);
  }

  confirmAndSend(item: PaperlessOfficeIn) {
    this.caseDocumentService
      .paperlessCreateCaseDocument(item, { parameters: { id: item.case.id } })
      .subscribe((result) => {
        item.completed = true;
        this.update(item);
      });
  }

  deleteItem(item: PaperlessOfficeIn) {
    if (window.confirm('Are you sure you want to delete this item?')) {
      item.completed = true;
      this.update(item);
    }
  }

  confirmWithItem(item: PaperlessOfficeIn) {
    this.itemToConfirm = JSON.parse(JSON.stringify(item));
    if (this.itemToConfirm.case) {
      this.loadCreditors(this.itemToConfirm.case.id);
    }
    this.confirmDocumentModalRef = this.modalService.show(
      this.confirmDocumentModalView as any,
      {
        backdrop: true,
        class: 'modal-lg',
        keyboard: false,
        ignoreBackdropClick: true,
      },
    );
  }

  saveDocumentSettings(): void {
    this.saveButtonDisabled = true;
    this.update(this.itemToConfirm)
      .then(() => {
        this.confirmDocumentModalHide();
      })
      .catch(() => {
        this.saveButtonDisabled = false;
      });
  }

  confirmDocumentModalHide() {
    this.itemToConfirm = undefined;
    this.saveButtonDisabled = false;
    this.foundCases = [];
    this.searchTerm = '';
    this.foundCreditors = [];
    this.confirmDocumentModalRef.hide();
  }

  // Perform the search
  public performSearch(value): void {
    this.caseService.search(value).subscribe((cases: any) => {
      this.foundCases = cases;
    });
  }

  public selectCase(selectedCase: Case): void {
    this.itemToConfirm.case = selectedCase;
    this.loadCreditors(this.itemToConfirm.case.id);
  }

  public removeSelectedCase(): void {
    delete this.itemToConfirm.case;
    delete this.itemToConfirm.caseCreditor;
    this.foundCreditors = [];
  }

  // Load creditors for the case
  public loadCreditors(caseId): void {
    this.caseCreditorService
      .listCaseCreditors(1, 1000, { parameters: { id: caseId } })
      .subscribe((results) => {
        this.foundCreditors = results;
      });
  }

  public insertIntoCaseNote(value: string) {
    this.itemToConfirm.caseNote = this.itemToConfirm.caseNote
      ? this.itemToConfirm.caseNote + value
      : value;
  }
}
