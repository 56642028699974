import { Observable } from 'rxjs';

import { Injectable } from '@angular/core';

import { HttpOptions } from '../../shared/http/http-options';
import { HttpRequestService } from '../../shared/http/http-request.service';
import { CaseComplaint } from './case-complaint';

@Injectable()
export class CaseComplaintService extends HttpRequestService {
  /**
   * The endpoint that we call to get the case information.
   *
   * @type {string}
   */
  private readonly endpoint: string = 'cases/:id/complaints';

  /**
   * Brings back a list of all accessible cases.
   *
   * @param {number} page The page number we are currently on.
   * @param {number} limit The number of cases to display per page.
   * @returns {Observable<CaseComplaint[]>}
   */
  public listCaseComplaints(
    page?: number,
    limit?: number,
    options?: HttpOptions,
  ): Observable<CaseComplaint[]> {
    return this.list(this.endpoint, page, undefined, limit, options);
  }

  /**
   * Finds details of the case from the given ID.
   *
   * @param {string} caseId The ID of the case we require details for.
   * @returns {Observable<CaseComplaint>}
   */
  public findCaseComplaint(
    caseId: string,
    options?: HttpOptions,
  ): Observable<CaseComplaint> {
    return this.findById(this.endpoint, caseId, options);
  }

  /**
   * Creates a new case from the given Case object.
   *
   * @param {CaseComplaint} newCase The details of the case to be created.
   * @returns {Observable<CaseComplaint | boolean>}
   */
  public createCaseComplaint(
    newCase: CaseComplaint,
    options?: HttpOptions,
  ): Observable<CaseComplaint | boolean> {
    return this.create(this.endpoint, newCase, options);
  }

  public updateCaseComplaintStatus(
    caseId: string,
    statusId: string,
    note: string,
    options?: HttpOptions,
  ): Observable<CaseComplaint | boolean> {
    return this.create(
      `${this.endpoint}/${caseId}/change-status`,
      { statusId, note },
      options,
    );
  }

  public assignCaseComplaint(
    caseId: string,
    userId: string,
    options?: HttpOptions,
  ): Observable<CaseComplaint | boolean> {
    console.log(caseId, userId);
    return this.create(
      `${this.endpoint}/${caseId}/assign`,
      { userId },
      options,
    );
  }

  /**
   * Updates a case with the given case object for the given case ID.
   *
   * @param {string} caseId The ID of the case needing updating.
   * @param {CaseComplaint} newDetails The new case object to update with.
   * @returns {Observable<Case | boolean>}
   */
  public updateCaseComplaint(
    caseId: string,
    newDetails: CaseComplaint,
    options?: HttpOptions,
  ): Observable<CaseComplaint | boolean> {
    return this.update(this.endpoint, caseId, newDetails, options);
  }

  /**
   * Deletes a case based on the given case ID.
   *
   * @param {string} caseId The ID of the case needing deleting.
   * @returns {Observable<boolean>}
   */
  public deleteCaseComplaint(
    caseId: string,
    options?: HttpOptions,
  ): Observable<boolean> {
    return this.delete(this.endpoint, caseId, options);
  }
}
