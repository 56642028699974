import { Case } from '../case';
import { Address } from '../../shared/address/address';

export class CaseBreach extends Address {
  public id?: string;
  public case?: Case;
  public breachType?: any;
  public issued?: string;
  public expired?: string;
  public outcome?: string;
}
