import { Injectable } from '@angular/core';
import { Country } from './country';
import { Observable } from 'rxjs';

import { HttpRequestService } from '../shared/http/http-request.service';

@Injectable()
export class CountryService extends HttpRequestService {
  /**
   * The endpoint that we call to get the country information.
   *
   * @type {string}
   */
  private readonly endpoint: string = 'countries';

  /**
   * Brings back a list of all accessible countries.
   *
   * @param {number} page The page number we are currently on.
   * @param {number} limit The number of countries to display per page.
   * @returns {Observable<Country[]>}
   */
  public listCountries(page?: number, limit?: number): Observable<Country[]> {
    return this.list(this.endpoint, page, undefined, limit);
  }

  /**
   * Finds details of the country from the given ID.
   *
   * @param {string} countryId The ID of the country we require details for.
   * @returns {Observable<Country>}
   */
  public findCountry(countryId: string): Observable<Country> {
    return this.findById(this.endpoint, countryId);
  }

  /**
   * Creates a new country from the given Country object.
   *
   * @param {Country} newCountry The details of the country to be created.
   * @returns {Observable<Country | boolean>}
   */
  public createCountry(newCountry: Country): Observable<Country | boolean> {
    return this.create(this.endpoint, newCountry);
  }

  /**
   * Updates a country with the given country object for the given country ID.
   *
   * @param {string} countryId The ID of the country needing updating.
   * @param {Country} newDetails The new country object to update with.
   * @returns {Observable<Country | boolean>}
   */
  public updateCountry(
    countryId: string,
    newDetails: Country,
  ): Observable<Country | boolean> {
    return this.update(this.endpoint, countryId, newDetails);
  }

  /**
   * Deletes a country based on the given country ID.
   *
   * @param {string} countryId The ID of the country needing deleting.
   * @returns {Observable<boolean>}
   */
  public deleteCountry(countryId: string): Observable<boolean> {
    return this.delete(this.endpoint, countryId);
  }
}
