import { Component } from '@angular/core';
import { ResidentialStatusService } from '../residential-status.service';
import { ResidentialStatus } from '../residential-status';
import { ActivatedRoute, Router } from '@angular/router';
import { NotificationService } from '../../shared/notification.service';

@Component({
  selector: 'app-residential-status-edit',
  templateUrl: 'edit.component.html',
})
export class ResidentialStatusEditComponent {
  /**
   * Details of the residentialStatus being edited.
   */
  public residentialStatus: ResidentialStatus;

  /**
   * Are we currently performing a loading action?
   *
   * @type {boolean}
   */
  public isLoading: boolean = false;

  /**
   * Load up all our required services and subscribe to every observable we need.
   *
   * @param {ResidentialStatusService} residentialStatusService
   * @param {NotificationService} notificationService
   * @param {Router} router
   * @param {ActivatedRoute} route
   */
  constructor(
    private residentialStatusService: ResidentialStatusService,
    private notificationService: NotificationService,
    private router: Router,
    private route: ActivatedRoute,
  ) {
    this.residentialStatusService.loading.subscribe(
      result => (this.isLoading = result),
    );
    this.route.params.subscribe(params =>
      this.loadResidentialStatus(params.id),
    );
  }

  /**
   * Perform the edit action which send the edited residentialStatus to the backend.
   */
  public edit() {
    const editResidentialStatus = this.residentialStatusService
      .updateResidentialStatus(
        this.residentialStatus.id,
        this.residentialStatus,
      )
      .subscribe(
        (result: ResidentialStatus) => {
          this.notificationService.success(
            `Residential Status Updated`,
            `The residential Status ${
              result.name
            } been updated with the specified changes.`,
            5,
          );
          this.residentialStatus = new ResidentialStatus();
          editResidentialStatus.unsubscribe();
          this.router.navigate(['/residential-statuses']);
        },
        err => {
          this.notificationService.error(
            `Residential Status Not Found`,
            err.error.message,
            10,
          );
          this.router.navigate(['/residential-statuses']);
        },
      );
  }

  /**
   * Loads the requested residentialStatus so we can display it and let the user edit the fields.
   *
   * @param {string} id
   */
  private loadResidentialStatus(id: string) {
    this.residentialStatusService.findResidentialStatus(id).subscribe(
      result => {
        this.residentialStatus = result;
      },
      err => {
        this.notificationService.error(
          `Residential Status Not Found`,
          err.error.message,
          10,
        );
        this.router.navigate(['/residential-statuses']);
      },
    );
  }
}
