import { Injectable } from '@angular/core';
import { Modification } from './modification';
import { Observable } from 'rxjs';

import { HttpRequestService } from '../../shared/http/http-request.service';

@Injectable()
export class ModificationService extends HttpRequestService {
  /**
   * The endpoint that we call to get the modification information.
   *
   * @type {string}
   */
  private readonly endpoint: string = 'modifications';

  /**
   * Brings back a list of all accessible modifications.
   *
   * @param {number} page The page number we are currently on.
   * @param {number} limit The number of modifications to display per page.
   * @returns {Observable<Modification[]>}
   */
  public listModifications(
    page?: number,
    limit?: number,
    filter?: string,
  ): Observable<Modification[]> {
    return this.list(this.endpoint, page, { filter: filter }, limit);
  }

  /**
   * Finds details of the modification from the given ID.
   *
   * @param {string} modificationId The ID of the modification we require details for.
   * @returns {Observable<Modification>}
   */
  public findModification(modificationId: string): Observable<Modification> {
    return this.findById(this.endpoint, modificationId);
  }

  /**
   * Creates a new modification from the given Modification object.
   *
   * @param {Modification} newModification The details of the modification to be created.
   * @returns {Observable<Modification | boolean>}
   */
  public createModification(
    newModification: Modification,
  ): Observable<Modification | boolean> {
    return this.create(this.endpoint, newModification);
  }

  /**
   * Updates a modification with the given modification object for the given modification ID.
   *
   * @param {string} modificationId The ID of the modification needing updating.
   * @param {Modification} newDetails The new modification object to update with.
   * @returns {Observable<Modification | boolean>}
   */
  public updateModification(
    modificationId: string,
    newDetails: Modification,
  ): Observable<Modification | boolean> {
    return this.update(this.endpoint, modificationId, newDetails);
  }

  /**
   * Deletes a modification based on the given modification ID.
   *
   * @param {string} modificationId The ID of the modification needing deleting.
   * @returns {Observable<boolean>}
   */
  public deleteModification(modificationId: string): Observable<boolean> {
    return this.delete(this.endpoint, modificationId);
  }
}
