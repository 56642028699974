import {HttpClientModule} from '@angular/common/http';
import {NgModule} from '@angular/core';
import {FormsModule} from '@angular/forms';
import {BrowserModule} from '@angular/platform-browser';
import {ModalModule} from 'ngx-bootstrap/modal';
import {TooltipModule} from 'ngx-bootstrap/tooltip';
import {QuillModule} from 'ngx-quill';

import {SharedModule} from '../../shared/shared.module';
import {CaseBreachService} from './case-breach.service';
import {CaseBreachCreateComponent} from './create/create.component';
import {CaseBreachEditComponent} from './edit/edit.component';
import {CaseBreachFormComponent} from './form/form.component';
import {CaseBreachListComponent} from './list/list.component';

@NgModule({
  declarations: [
    CaseBreachListComponent,
    CaseBreachCreateComponent,
    CaseBreachEditComponent,
    CaseBreachFormComponent,
  ],
  imports: [
    ModalModule.forRoot(),
    TooltipModule.forRoot(),
    QuillModule,
    BrowserModule,
    FormsModule,
    HttpClientModule,
    SharedModule,
  ],
  exports: [
    CaseBreachListComponent,
    CaseBreachCreateComponent,
    CaseBreachEditComponent,
    CaseBreachFormComponent,
  ],
  providers: [CaseBreachService],
})
export class CaseBreachRoutingModule {}
