import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

import {AuthService} from '../../../auth/auth.service';
import {NotificationService} from '../../../shared/notification.service';
import {CaseCreditor} from '../case-creditor';
import {CaseCreditorService} from '../case-creditor.service';

@Component({
  selector: 'app-case-creditor-list',
  templateUrl: 'list.component.html',
  styleUrls: ['list.component.css'],
})
export class CaseCreditorListComponent implements OnInit {
  @Input() caseRefreshed: EventEmitter<string>;

  @Input() caseId: string;

  @Output()
  creditors: EventEmitter<CaseCreditor[]> = new EventEmitter<CaseCreditor[]>();
  public caseCreditors: CaseCreditor[] = [];
  public isLoading: boolean = true;
  public totalPages: number = 1;
  public currentPage: number = 1;
  public totalResults: number = 0;
  public itemDeleted: EventEmitter<boolean> = new EventEmitter<boolean>();
  public creditorCreated: EventEmitter<boolean> = new EventEmitter<boolean>();
  public newCreditor: CaseCreditor = new CaseCreditor();
  @Output()
  public securedCreditorsUpdated: EventEmitter<number> = new EventEmitter<number>();
  @Output()
  public unsecuredCreditorsUpdated: EventEmitter<number> = new EventEmitter<number>();
  public editorOptions = {
    placeholder: 'Enter your note...',
    modules: {
      toolbar: ['bold', 'link'],
    },
  };
  public votingSplit = {
    accepted: 0,
    declined: 0,
    pending: 0,
  };
  private limit: number = 1000;

  constructor(
    private caseCreditorService: CaseCreditorService,
    private notificationService: NotificationService,
    public readonly authService: AuthService,
  ) {
    this.caseCreditorService.loading.subscribe((isLoading) => {
      this.isLoading = isLoading;
    });
    this.caseCreditorService.totalPages.subscribe((totalPages) => {
      this.totalPages = totalPages;
    });
    this.caseCreditorService.currentPage.subscribe((currentPage) => {
      this.currentPage = currentPage;
    });
    this.caseCreditorService.totalResults.subscribe((totalResults) => {
      this.totalResults = totalResults;
    });
  }

  public getCurrentValue(item: CaseCreditor): number {
    if (item.admittedValue > 0) {
      return item.admittedValue;
    }

    if (item.actualValue > 0) {
      return item.actualValue;
    }

    return item.value;
  }

  public get totalVotes(): number {
    return (
      this.votingSplit.accepted +
      this.votingSplit.declined +
      this.votingSplit.pending
    );
  }

  public sumPaidValueColumn(
    type?: 'secured' | 'unsecured' | 'excluded',
  ): number {
    return this.caseCreditors
      .map((i) => {
        if (type === undefined) {
          return i;
        }

        if (type === 'secured' && i.secured === true) {
          return i;
        }

        if (type === 'excluded' && i.excluded === true) {
          return i;
        }

        return type === 'unsecured' &&
          i.excluded === false &&
          i.secured === false
          ? i
          : { paidValue: 0 };
      })
      .map((r) => r.paidValue)
      .reduce((p, c) => p + c, 0);
  }

  public sumSOAColumn(type?: 'secured' | 'unsecured' | 'excluded'): number {
    return this.caseCreditors
      .map((i) => {
        if (type === undefined) {
          return i;
        }

        if (type === 'secured' && i.secured === true) {
          return i;
        }

        if (type === 'excluded' && i.excluded === true) {
          return i;
        }

        return type === 'unsecured' &&
          i.excluded === false &&
          i.secured === false
          ? i
          : { value: 0 };
      })
      .map((r) => r.value)
      .reduce((p, c) => p + c, 0);
  }

  public sumValueColumn(type?: 'secured' | 'unsecured' | 'excluded'): number {
    return this.caseCreditors
      .map((i) => {
        if (type === undefined) {
          return i;
        }

        if (type === 'secured' && i.secured === true) {
          return i;
        }

        if (type === 'excluded' && i.excluded === true) {
          return i;
        }

        return type === 'unsecured' &&
          i.excluded === false &&
          i.secured === false
          ? i
          : { ...i, value: 0, admittedValue: 0, actualValue: 0 };
      })
      .map((r) => {
        if (r.admittedValue > 0) {
          return r.admittedValue;
        } else if (r.actualValue > 0 || r.debtPaid === true) {
          return r.actualValue;
        } else {
          return r.value;
        }
      })
      .reduce((p, c) => p + c, 0);

    // return (this.totalSecuredCreditors + this.totalUnsecuredCreditors) * 100;
  }

  private get totalSecuredCreditors(): number {
    let totalCreditors = 0;

    this.caseCreditors.forEach((creditorItem) => {
      let thisValue = 0;

      if (creditorItem.admittedValue > 0) {
        thisValue = creditorItem.admittedValue;
      } else if (
        creditorItem.actualValue > 0 ||
        creditorItem.debtPaid === true
      ) {
        thisValue = creditorItem.actualValue;
      } else {
        thisValue = creditorItem.value;
      }

      if (creditorItem.secured) {
        totalCreditors = totalCreditors + thisValue;
      }
    });

    return totalCreditors / 100;
  }

  private get totalUnsecuredCreditors(): number {
    let totalCreditors = 0;

    this.caseCreditors.forEach((creditorItem) => {
      let thisValue = 0;

      if (creditorItem.admittedValue > 0) {
        thisValue = creditorItem.admittedValue;
      } else if (
        creditorItem.actualValue > 0 ||
        creditorItem.debtPaid === true
      ) {
        thisValue = creditorItem.actualValue;
      } else {
        thisValue = creditorItem.value;
      }

      if (!creditorItem.secured) {
        totalCreditors = totalCreditors + thisValue;
      }
    });

    return totalCreditors / 100;
  }

  public ngOnInit(): void {
    this.loadCaseCreditors(1, this.limit);
    this.caseRefreshed.subscribe((id) => {
      this.caseId = id;
      this.onPageChanged(1);
    });
  }

  public onPageChanged(pageNumber: number): void {
    this.loadCaseCreditors(pageNumber, this.limit);
  }

  public loadCaseCreditors(page?: number, limit?: number): void {
    this.votingSplit = {
      accepted: 0,
      declined: 0,
      pending: 0,
    };

    const listCountries = this.caseCreditorService
      .listCaseCreditors(page, limit, { parameters: { id: this.caseId } })
      .subscribe((caseCreditors: CaseCreditor[]) => {
        this.caseCreditors = caseCreditors;

        this.caseCreditors.forEach((r) => {
          if (r.excluded === false) {
            if (r.votingStatus === 'Approved') {
              this.votingSplit.accepted += r.value;
            } else if (r.votingStatus === 'Rejected') {
              this.votingSplit.declined += r.value;
            } else {
              this.votingSplit.pending += r.value;
            }
          }
        });

        this.creditors.next(caseCreditors);
        this.securedCreditorsUpdated.next(this.totalSecuredCreditors);
        this.unsecuredCreditorsUpdated.next(this.totalUnsecuredCreditors);
        listCountries.unsubscribe();
      });
  }

  public capsString(value: string): string {
    return value[0].toUpperCase() + value.substr(1, value.length);
  }

  public create(formData: CaseCreditor) {
    this.caseCreditorService
      .createCaseCreditor(this.newCreditor, { parameters: { id: this.caseId } })
      .subscribe((results) => {
        this.creditorCreated.next(true);
        this.onPageChanged(1);
      });
    console.log('creating', this.newCreditor);
  }

  public delete(id: string) {
    this.caseCreditorService.deleteCaseCreditor(id).subscribe(
      (deleted) => {
        this.notificationService.success(
          `Creditor Deleted`,
          `Vehicle with ID ${id} was deleted.`,
          5,
        );
        this.itemDeleted.next(true);
        this.onPageChanged(this.currentPage);
      },
      (err) => {
        this.notificationService.error(
          `Creditor Not Deleted`,
          err.error.message,
          10,
        );
        this.itemDeleted.next(false);
        this.onPageChanged(this.currentPage);
      },
    );
  }
}
