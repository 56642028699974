import { Component, EventEmitter, OnDestroy, OnInit } from '@angular/core';
import { EmploymentStatus } from '../employment-status';
import { NgRedux, select } from '@angular-redux/store';
import { Observable } from 'rxjs/Observable';
import { ReduxStore } from '../../common/redux.store';
import { EmploymentStatusActions } from '../../common/settings/employment-status/employment-status.actions';

@Component({
  selector: 'app-employment-status-list',
  templateUrl: 'list.component.html',
  styleUrls: [],
})
export class EmploymentStatusListComponent implements OnInit, OnDestroy {
  @select(['employmentStatuses', 'list']) public employmentStatuses$: Observable<EmploymentStatus[]>;

  public employmentStatusDeleted: EventEmitter<boolean> = new EventEmitter<boolean>();

  constructor(private readonly ngRedux: NgRedux<ReduxStore>, private readonly actions: EmploymentStatusActions) {
  }

  public ngOnInit(): void {
    this.onPageChanged(1);
  }

  public onPageChanged(pageNumber: number): void {
    this.ngRedux.dispatch<any>(this.actions.list(pageNumber));
  }

  ngOnDestroy(): void {
    this.ngRedux.dispatch(this.actions.clearAll());
  }

  public delete(id) {
    this.ngRedux.dispatch<any>(this.actions.delete(id));
    this.employmentStatusDeleted.next(true);
  }

}
