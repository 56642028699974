import {Component, EventEmitter, Input, OnInit} from '@angular/core';

import {CaseCreditorDistributionsService} from './distributions.service';

@Component({
  selector: 'app-case-creditor-distributions',
  templateUrl: 'distributions.component.html',
  styleUrls: ['distributions.component.css'],
})
export class CaseCreditorDistributionsComponent implements OnInit {
  @Input() caseId: string;
  @Input() caseRefreshed: EventEmitter<string>;

  public loading: boolean = false;
  public results: any[] = [];
  public uniqueCreditors = [];
  public selectedFilterId: string = null;

  constructor(private readonly service: CaseCreditorDistributionsService) {}

  public ngOnInit() {
    this.caseRefreshed.subscribe((r) => {
      this.loadDistributions(true);
    });
    this.loadDistributions(true);
  }

  private readonly filterUniqueCreditors = () =>
    this.results
      .map((i) => ({
        id: i.caseCreditor.id,
        creditor: i.creditor,
        caseCreditor: i.caseCreditor,
      }))
      .filter((v, i, a) => a.findIndex((t) => t.id === v.id) === i)
      .sort((c, p) =>
        c.creditor.name > p.creditor.name
          ? 1
          : c.creditor.name < p.creditor.name
          ? -1
          : 0,
      );

  private readonly loadDistributions = (filterUniques: boolean = false) => {
    this.loading = !this.loading;
    this.service
      .getDistributions(this.caseId, this.selectedFilterId)
      .subscribe((results) => {
        this.results = results;
        if (filterUniques) this.uniqueCreditors = this.filterUniqueCreditors();
        this.loading = !this.loading;
      });
  };

  public readonly filterCreditors = this.loadDistributions;
}
