import { Component, Input } from '@angular/core';
import { BankingAccount } from '../banking-account';

@Component({
  selector: 'app-banking-account-form',
  templateUrl: 'form.component.html',
})
export class BankingAccountFormComponent {
  @Input() account: BankingAccount;
}
