<div class="row">
  <div class="col-sm-12">
    <section class="panel">
      <app-page-title title="List Cases" [loading]="isLoading">
        Showing search results for {{ term }}
      </app-page-title>

      <div class="panel-body">
        <table class="table table-striped">
          <thead>
          <tr>
            <th>Name</th>
            <th>Introducer</th>
            <th>Disposable Income</th>
            <th>Status</th>
            <th>Created</th>
            <th>&nbsp;</th>
          </tr>
          </thead>

          <tbody>
          <tr *ngFor="let case of cases">
            <td>
              {{ case?.case_name }} - ({{ case?.unique_reference }})
            </td>
            <td>{{ case?.introducer }} - ({{ case?.introducer_reference }})</td>
            <td>&pound;{{ case?.di }}</td>
            <td>{{ case?.status }}</td>
            <td><span tooltip="{{ case?.created_at | date:'HH:mm dd/MM/yyyy' }}">{{ case?.created_at | timeAgo }}</span>
            </td>
            <td class="text-right">
              <div class="btn-group">
                <a [routerLink]="['/cases', case?.id]" type="button" class="btn btn-sm btn-warning">View</a>
                <button type="button" class="btn btn-sm btn-warning dropdown-toggle" data-toggle="dropdown"
                        aria-haspopup="true" aria-expanded="false">
                  <span class="caret"></span>
                  <span class="sr-only">Toggle Dropdown</span>
                </button>
                <ul class="dropdown-menu">
                  <li><a [routerLink]="['/cases', case?.id, 'edit']">Edit</a></li>
                  <li *ngIf="authService.hasPermission('user.delete-cases')"><a href="javascript:;"
                                                                                (click)="deleteModal.confirmDialog(case.id)">Delete</a>
                  </li>
                </ul>
              </div>
            </td>
          </tr>
          </tbody>

          <tfoot>
          <tr>
            <td colspan="99" class="text-center">
              <div class="row">
                <div class="col-md-2" style="margin: 20px 0"></div>
                <div class="col-md-8">
                  <app-pagination [totalPages]="totalPages" [currentPage]="currentPage" (pageChanged)="onPageChanged($event)"></app-pagination>
                </div>
                <div class="col-md-2 text-right" style="margin: 20px 0">
                  <button class="btn btn-sm" (click)="downloadCases()" *ngIf="authService.hasPermission('user.download-cases')"><i class="fa fa-download"></i></button>
                </div>
              </div>

            </td>
          </tr>
          </tfoot>
        </table>
      </div>
    </section>
  </div>
</div>

<app-delete-modal type="case" (confirm)="delete($event)" [itemDeleted]="caseDeleted" #deleteModal></app-delete-modal>
