<table class="table table-hover">

  <thead>
  <tr>
    <th>&nbsp;</th>
    <th>Type</th>
    <th>Preview</th>
    <th>Date</th>
    <th>&nbsp;</th>
  </tr>
  </thead>

  <tbody>
  <tr *ngFor="let communication of caseCommunications">
    <td>
      <i class="fa"
         tooltip="{{ communication.direction }}"
         [class.fa-arrow-circle-left]="communication.direction === 'inbound'"
         [class.fa-arrow-circle-right]="communication.direction === 'outbound'"
         aria-hidden="true"></i>
    </td>
    <td>{{ communication?.type }}</td>
    <td>{{ communication?.subject }} {{ communication.subject && communication.subject.length > 0 ? ' - ' : '' }} {{ communication?.content.substr(0,160) }}</td>
    <td>{{ communication?.createdAt | date:'dd/MM/yyyy HH:mm' }}</td>
    <td class="text-right">
      <div class="btn-group">
        <button type="button" class="btn btn-xs btn-warning">View</button>
        <button type="button" class="btn btn-xs btn-warning dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
          <span class="caret"></span>
          <span class="sr-only">Toggle Dropdown</span>
        </button>
        <ul class="dropdown-menu">
          <li>
            <a href="javascript:;">Reply</a>
          </li>
        </ul>
      </div>
    </td>
  </tr>
  </tbody>

  <tfoot>
  <tr>
    <td colspan="99" class="text-center">
      <app-pagination [totalPages]="totalPages" [currentPage]="currentPage" (pageChanged)="onPageChanged($event)"></app-pagination>
    </td>
  </tr>
  </tfoot>

</table>
