<div class="domain-icon">
  <ng-template #domainPopTemplate>
    <table class="table table-striped">
      <tr><td colspan="2"><img [src]="getDomainAvatar()" width="100%"></td></tr>
      <tr><th>Name: </th><td>{{ domain.name }}</td></tr>
    </table>
  </ng-template>
  <img
    *ngIf="!loading"
    [src]="getDomainAvatar()"
    [style.maxHeight]="size + 'px'"
    [style.maxWidth]="size + 'px'"
    [popover]="domainPopTemplate"
    placement="auto"
    triggers="mouseenter:mouseleave">

  <img
    *ngIf="loading"
    src="https://i0.wp.com/cdnjs.cloudflare.com/ajax/libs/semantic-ui/0.16.1/images/loader-large.gif"
    [style.maxHeight]="size + 'px'"
    [style.maxWidth]="size + 'px'"
    title="loading">
</div>
