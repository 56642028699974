import useApi, { PaginationOptions } from '../../Hooks/UseApi';

import Domain from '../../../../../chief-types/src/Domain';

const useDomains = (autoload: boolean = false) =>
  useApi<Domain, PaginationOptions>(
    'domains',
    undefined,
    autoload,
  );

export default useDomains;
