import { Component, EventEmitter, OnInit } from '@angular/core';
import { MaritalStatusService } from '../marital-status.service';
import { MaritalStatus } from '../marital-status';
import { NotificationService } from '../../shared/notification.service';

@Component({
  selector: 'app-marital-status-list',
  templateUrl: 'list.component.html',
  styleUrls: [],
})
export class MaritalStatusListComponent implements OnInit {
  public maritalStatus: MaritalStatus;

  /**
   * A list of all the marital-statuses currently loaded.
   *
   * @type {MaritalStatus[]}
   */
  public maritalStatuses: MaritalStatus[] = [];

  /**
   * Are we currently performing a loading action?
   *
   * @type {boolean}
   */
  public isLoading: boolean = true;

  /**
   * The total number of pages in this list of marital-statuses.
   *
   * @type {number}
   */
  public totalPages: number = 1;

  /**
   * The current page of marital-statuses that we are viewing.
   *
   * @type {number}
   */
  public currentPage: number = 1;

  /**
   * The total number of marital-statuses that can be viewed in the system.
   *
   * @type {number}
   */
  public totalResults: number = 0;

  /**
   * An Emitter to alert other components when a maritalStatus has been deleted.
   *
   * @type {EventEmitter<boolean>}
   */
  public maritalStatusDeleted: EventEmitter<boolean> = new EventEmitter<
    boolean
  >();

  /**
   * Load up all our required services and subscribe to every observable we need.
   *
   * @param {MaritalStatusService} maritalStatusService
   * @param {NotificationService} notificationService
   */
  constructor(
    private maritalStatusService: MaritalStatusService,
    private notificationService: NotificationService,
  ) {
    this.maritalStatusService.loading.subscribe(isLoading => {
      this.isLoading = isLoading;
    });
    this.maritalStatusService.totalPages.subscribe(totalPages => {
      this.totalPages = totalPages;
    });
    this.maritalStatusService.currentPage.subscribe(currentPage => {
      this.currentPage = currentPage;
    });
    this.maritalStatusService.totalResults.subscribe(totalResults => {
      this.totalResults = totalResults;
    });
  }

  /**
   * When the component is initialised we set it to load
   * page one of the maritalStatus list.
   */
  public ngOnInit(): void {
    this.loadMaritalStatuses(1);
  }

  /**
   * When we receive a page change from the pagination
   * component we load the appropriate page for the
   * maritalStatus list.
   *
   * @param {number} pageNumber
   */
  public onPageChanged(pageNumber: number): void {
    this.loadMaritalStatuses(pageNumber);
  }

  /**
   * Loads a list of marital-statuses using the given per page limit.
   *
   * @param {number} page The current page we require.
   * @param {number} limit The number of results per page.
   */
  public loadMaritalStatuses(page?: number, limit?: number): void {
    const listMaritalStatuses = this.maritalStatusService
      .listMaritalStatuses(page, limit)
      .subscribe((maritalStatuses: MaritalStatus[]) => {
        this.maritalStatuses = maritalStatuses;
        listMaritalStatuses.unsubscribe();
      });
  }

  /**
   * When receiving confirmation of an item being deleted
   * we call the back end with the appropriate maritalStatus ID
   * to delete the maritalStatus.
   *
   * @param {string} id
   */
  public delete(id: string) {
    this.maritalStatusService.deleteMaritalStatus(id).subscribe(
      deleted => {
        this.notificationService.success(
          `MaritalStatus Deleted`,
          `MaritalStatus with ID ${id} was deleted.`,
          5,
        );
        this.maritalStatusDeleted.next(true);
        this.onPageChanged(this.currentPage);
      },
      err => {
        this.notificationService.error(
          `MaritalStatus Not Deleted`,
          err.error.message,
          10,
        );
        this.maritalStatusDeleted.next(false);
        this.onPageChanged(this.currentPage);
      },
    );
  }
}
