import { RouterModule, Routes, CanActivate } from '@angular/router';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule } from '@angular/common/http';
import { PermissionListComponent } from './list/list.component';
import { AuthGuard } from '../auth/auth.guard';
import { PermissionService } from './permission.service';
import { SharedModule } from '../shared/shared.module';
import { PermissionCreateComponent } from './create/create.component';
import { PermissionFormComponent } from './form/form.component';
import { PermissionEditComponent } from './edit/edit.component';

const routes: Routes = [
  {
    path: 'permissions',
    component: PermissionListComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'permissions/create',
    component: PermissionCreateComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'permissions/:id/edit',
    component: PermissionEditComponent,
    canActivate: [AuthGuard],
  },
];

@NgModule({
  declarations: [
    PermissionListComponent,
    PermissionCreateComponent,
    PermissionFormComponent,
    PermissionEditComponent,
  ],
  imports: [
    BrowserModule,
    RouterModule,
    FormsModule,
    HttpClientModule,
    SharedModule,
    RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' }),
  ],
  exports: [RouterModule],
  providers: [AuthGuard, PermissionService],
})
export class PermissionRoutingModule {}
