import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { map } from 'rxjs/operators';

import { environment } from '../../../environments/environment';
import { AuthService } from '../../auth/auth.service';
import { FinancialsDistributionService } from '../../financials/distributions/financials-distribution.service';
import { Portfolio } from '../../portfolios/portfolio';
import { PortfolioService } from '../../portfolios/portfolio.service';

@Component({
  selector: 'wth-dep-statements-report',
  templateUrl: 'wth-dep-statements.component.html',
})
export class WthDepStatementsComponent implements OnInit {
  public statements: any[] = [];
  public allocationsInProgress: boolean = false;

  private customRemittance: string[] = [
    'a9176f06-b2ce-4590-a06b-391c03fb827d',
    '57b93d55-430d-4cd4-b72d-22a052e0c2b7',
    '992db77b-8786-4ee4-bab4-88f68fcbf0f4',
    '26dd1bf7-b76f-4f99-b6be-a6d9bf5345ca',
    '1bbb23e6-31b5-4843-8d9e-7cfcc7ff36e6',
  ];

  public portfolios: Portfolio[] = [];

  constructor(
    private readonly http: HttpClient,
    private readonly authService: AuthService,
    private readonly db: AngularFirestore,
    private readonly distributionService: FinancialsDistributionService,
    private readonly portfolioService: PortfolioService,
  ) {}

  ngOnInit(): void {
    this.distributionService
      .automaticAllocationsInProgress()
      .subscribe((r) => (this.allocationsInProgress = r));

    this.portfolioService.listPortfolios(1, 10000).subscribe((results) => {
      this.portfolios = results.filter(
        (r: Portfolio) => !this.customRemittance.includes(r.id),
      );
    });

    this.db
      .collection('bankExportStatements', (q) =>
        q
          .where('domainId', '==', this.authService.domain.id)
          .orderBy('created', 'desc'),
      )
      .snapshotChanges()
      .pipe(
        map((actions) =>
          actions.map((a) => {
            const data = a.payload.doc.data() as any;
            const id = a.payload.doc.id;
            return { id, ...data };
          }),
        ),
      )
      .subscribe((r) => (this.statements = r));
  }

  generateDep() {
    this.distributionService
      .generateDep()
      .subscribe((r) => console.log('Done'));
  }

  generateDownloadStatementUrl(item: any) {
    return `${environment.euFunctionEndpoint}wthStatementDownload?id=${
      item.id
    }&setStatus=${item.status === 'Pending'}`;
  }

  generateDownloadSummaryStatementUrl(item: any) {
    return `${environment.microservices.reports}reports/statement-summary?statementId=${item.id}&download=true`;
  }

  generateDownloadBacsStatementUrl(item: any) {
    return `${environment.microservices.reports}reports/bacs-statement?statementId=${item.id}&download=true`;
  }

  generateDownloadRemittanceStatementUrl(
    item: any,
    type: string,
    portfolioId?: string,
  ) {
    return (
      `${environment.microservices.reports}remittance/${type}?statementId=${item.id}&download=true` +
      (portfolioId ? `&portfolioId=${portfolioId}` : '')
    );
  }

  setStatus(id: string, status: string) {
    this.db.doc(`bankExportStatements/${id}`).update({
      status,
    });
  }
}
