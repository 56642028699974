import { Component, EventEmitter, Input, OnInit } from '@angular/core';
import { CaseCommunicateService } from '../case-communicate.service';
import { CaseDocument } from '../../documents/case-document';
import { CaseCommunication } from '../case-communication';

@Component({
  selector: 'app-case-communication-list',
  templateUrl: 'list.component.html',
})
export class CaseCommunicationListComponent implements OnInit {
  @Input() caseRefreshed: EventEmitter<string>;

  @Input() caseId: string;

  public caseCommunications: CaseCommunication[] = [];

  public isLoading: boolean = true;

  public totalPages: number = 1;

  public currentPage: number = 1;

  public totalResults: number = 0;

  constructor(
    private readonly caseCommunicationService: CaseCommunicateService,
  ) {
    this.caseCommunicationService.loading.subscribe(isLoading => {
      this.isLoading = isLoading;
    });
    this.caseCommunicationService.totalPages.subscribe(totalPages => {
      this.totalPages = totalPages;
    });
    this.caseCommunicationService.currentPage.subscribe(currentPage => {
      this.currentPage = currentPage;
    });
    this.caseCommunicationService.totalResults.subscribe(totalResults => {
      this.totalResults = totalResults;
    });
  }

  public ngOnInit(): void {
    this.onPageChanged(1);
    this.caseRefreshed.subscribe(id => {
      this.caseId = id;
      this.onPageChanged(1);
    });
  }

  public onPageChanged(pageNumber: number): void {
    this.loadCaseCommunications(pageNumber);
  }

  private loadCaseCommunications(page?: number, limit: number = 10): void {
    const listCommunications = this.caseCommunicationService
      .listCaseDocuments(page, limit, { parameters: { id: this.caseId } })
      .subscribe((caseCommunications: CaseCommunication[]) => {
        this.caseCommunications = caseCommunications;
        listCommunications.unsubscribe();
      });
  }
}
