import {Component, EventEmitter, Output, ViewChild} from '@angular/core';

import {CaseBreach} from '../case-breach';
import {CaseBreachService} from '../case-breach.service';

@Component({
  selector: 'app-case-breach-edit',
  templateUrl: 'edit.component.html',
})
export class CaseBreachEditComponent {
  @ViewChild('staticModal') staticModal;

  @Output() completed = new EventEmitter<boolean>();

  public disabled: boolean = false;

  public model: CaseBreach;

  private givenCaseId: string;

  constructor(private readonly caseBreachService: CaseBreachService) {}

  public get caseId(): string {
    return this.givenCaseId;
  }

  public open(caseId: string, model: CaseBreach): void {
    this.givenCaseId = caseId;
    this.model = model;
    this.staticModal.show();
  }

  public close() {
    this.workCompleted();
  }

  public confirmEdit($event) {
    $event.preventDefault();
    this.disabled = true;
    this.caseBreachService
      .updateCaseBreach(this.model.id, this.model, {
        parameters: { id: this.caseId },
      })
      .subscribe(() => {
        this.workCompleted();
      });
  }

  private workCompleted() {
    this.model = undefined;
    this.disabled = false;
    this.staticModal.hide();
    this.completed.next(true);
  }
}
