import { Component, Input } from '@angular/core';
import { Credit } from '../credit';

@Component({
  selector: 'app-credit-form',
  templateUrl: 'form.component.html',
})
export class CreditFormComponent {
  @Input() credit: Credit;
}
