import { Component, Input } from '@angular/core';
import { Product } from '../product';

@Component({
  selector: 'app-product-form',
  templateUrl: 'form.component.html',
})
export class ProductFormComponent {
  @Input() product: Product;
}
