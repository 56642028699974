<div class="row">
  <div class="col-sm-12">
    <section class="panel">
      <app-page-title
        title="Create Introducer"
        [loading]="isLoading"
      ></app-page-title>

      <div class="panel-body">
        <form class="form-horizontal" (ngSubmit)="create()" ngNativeValidate>
          <app-introducer-form
            [introducer]="introducer"
            *ngIf="introducer"
            [creating]="true"
          ></app-introducer-form>
        </form>
      </div>
    </section>
  </div>
</div>
