import { Component, Input } from '@angular/core';
import { Status } from '../status';

@Component({
  selector: 'app-status-form',
  templateUrl: 'form.component.html',
})
export class StatusFormComponent {
  @Input() status: Status;
}
