<section class="panel">
  <header class="panel-heading tab-bg-light">
    Creditors Report

    <a [href]="downloadReportUrl" target="_blank" class="btn btn-danger btn-xs pull-right">
      <i class="fa fa-download"></i>
    </a>

  </header>

  <div class="panel-body">
    <table class="table table-striped">
      <thead>
      <tr>
        <th>
          <a href="javascript:;" (click)="sortBy('name')">Name</a>
          <i class="fa"
             style="margin-left: 5px;"
             *ngIf="sort.field === 'name'"
             [class.fa-sort-alpha-desc]="sort.direction === 'DESC'"
             [class.fa-sort-alpha-asc]="sort.direction === 'ASC'"></i>
        </th>
        <th>
          <a href="javascript:;" (click)="sortBy('count')">Client Count</a>
          <i class="fa"
             style="margin-left: 5px;"
             *ngIf="sort.field === 'count'"
             [class.fa-sort-amount-desc]="sort.direction === 'DESC'"
             [class.fa-sort-amount-asc]="sort.direction === 'ASC'"></i>
        </th>
        <th>&nbsp;</th>
      </tr>
      </thead>
      <tbody>
      <tr *ngFor="let item of detailedReport">
        <td>{{ item.name }}</td>
        <td>{{ item.count }}</td>
        <td class="text-right">
          <div class="btn-group btn-group-sm" role="group" aria-label="...">
            <a [routerLink]="['/reports/clients-by-creditor', item.id]" target="_blank" class="btn btn-success btn-sm"><i class="fa fa-users"></i></a>
            <a [routerLink]="['/creditors', item.id, 'edit']" target="_blank" class="btn btn-warning btn-sm"><i class="fa fa-cog"></i></a>
          </div>
        </td>
      </tr>
      </tbody>
    </table>
  </div>

</section>
