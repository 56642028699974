<div class="form-group">
  <label for="name" class="col-sm-2 control-label">Domain</label>
  <div class="col-sm-10">
    <app-domain-select [model]="account"></app-domain-select>
  </div>
</div>

<div class="form-group">
  <label for="name" class="col-sm-2 control-label">Type</label>
  <div class="col-sm-10">
    <app-banking-account-type-select [model]="account"></app-banking-account-type-select>
  </div>
</div>

<div class="form-group">
  <label for="name" class="col-sm-2 control-label">Name</label>
  <div class="col-sm-10">
    <input type="text" class="form-control" name="name" id="name" placeholder="Account Name"
           [(ngModel)]="account.name" required>
  </div>
</div>

<div class="form-group">
  <label for="description" class="col-sm-2 control-label">Description</label>
  <div class="col-sm-10">
    <textarea class="form-control" name="description" id="description" [(ngModel)]="account.description" placeholder="Description"></textarea>
  </div>
</div>

<div class="form-group">
  <label for="sortCode" class="col-sm-2 control-label">Sort Code</label>
  <div class="col-sm-10">
    <input type="text" class="form-control" name="sortCode" id="sortCode" placeholder="Sort Code" maxlength="6"
           [(ngModel)]="account.sortCode">
  </div>
</div>


<div class="form-group">
  <label for="accountNumber" class="col-sm-2 control-label">Account Number</label>
  <div class="col-sm-10">
    <input type="text" class="form-control" name="accountNumber" id="accountNumber" placeholder="Account Number"
           [(ngModel)]="account.accountNumber">
  </div>
</div>

<div class="form-group">
  <div class="col-sm-offset-2 col-sm-10 text-right">
    <button type="submit" class="btn btn-success">Save</button>
  </div>
</div>
