
    <span *ngIf="isCurrency" [class.hidden]="!isEditing" class="currencyType"
      >£
      <input
        type="number"
        step="0.01"
        [value]="visibleValue"
        (focusout)="visibleChange($event)"
        class="inPlaceEditor"
        [disabled]="isSaving"
        #editableFieldInputBox
      />
    </span>

    <span [class.hidden]="isEditing" (click)="setIsEditing(true)">{{
      visibleValue | toAccountancy
    }}</span>

    <span class="spinner" *ngIf="isSaving">
      <i class="fa fa-spinner fa-spin"></i>
    </span>
  