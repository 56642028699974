<div *ngIf="auditResults" class="alert alert-info">
  <i class="fa fa-check"></i>
  {{ auditResults?.length }} Modifications have been made to this Fee Comparison
  -
  <a (click)="openModal(auditPaymentProfileModal)" href="javascript:"
    >View Modifications</a
  >
</div>

<ng-template #auditPaymentProfileModal>
  <div class="modal-header">
    <h4 class="modal-title pull-left">Audit Fee Comparison</h4>
    <button
      (click)="modalRef.hide()"
      aria-label="Close"
      class="close pull-right"
      type="button"
    >
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="container-fluid">
      <div class="row">
        <div class="col-md-3">
          <label class="form-label">Select Comparison:</label>
        </div>
        <div class="col-md-6">
          <select [(ngModel)]="viewingProfile">
            <option
              *ngFor="let profile of auditResults"
              [ngValue]="profile"
              class="form-control"
            >
              Active from {{ profile.createdAt }} to {{ profile.deletedAt }}
              {{ profile.original === true ? ' : Original Fees!' : '' }}
            </option>
          </select>
        </div>
        <div class="col-md-3">
          <button
            *ngIf="viewingProfile && viewingProfile.original === false"
            class="btn btn-primary"
            type="button"
            (click)="markAsOriginal()"
          >
            Mark as Original Fees
          </button>
          <div
            class="alert alert-success text-center"
            *ngIf="viewingProfile && viewingProfile.original === true"
          >
            Original fees!
          </div>
        </div>
      </div>

      <div
        class="row"
        style="max-height: 600px; overflow-x: auto; margin-top: 20px"
      >
        <table *ngIf="viewingProfile" class="table">
          <tr>
            <th>Fee Type</th>
            <th>Bankruptcy Value</th>
            <th>IVA Value</th>
          </tr>

          <tr *ngFor="let item of viewingProfile.items">
            <th>{{ item.name }}</th>
            <td>{{ item.bankruptcyValue / 100 | currency: 'GBP' }}</td>
            <td>{{ item.ivaValue / 100 | currency: 'GBP' }}</td>
          </tr>
        </table>
      </div>
    </div>
  </div>
</ng-template>
