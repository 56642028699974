<div class="row">
  <div class="col-sm-12">
    <section class="panel">
      <app-page-title title="List Accounts" [loading]="isLoading">
        {{ totalResults }} Results Found
      </app-page-title>

      <div class="panel-body">
        <table class="table table-striped">
          <thead>
          <tr>
            <th>Uploaded</th>
            <th>Total Rows</th>
            <th>Imported Rows</th>
            <th>Completed</th>
            <th>Expected Value</th>
            <th>Imported Value</th>
            <th>Accepted</th>
            <th>Note</th>
          </tr>
          </thead>

          <tbody>
          <tr *ngFor="let account of accounts">
            <td>{{ account?.createdAt | date:'dd/MM/yyyy HH:mm' }}</td>
            <td>{{ account?.totalRows }}</td>
            <td>{{ account?.importedRows }}</td>
            <td>
              <span
                class="fa"
                [class.fa-check]="account?.complete === true"
                [class.text-success]="account?.complete === true"
                [class.fa-times]="account?.complete === false"
                [class.text-danger]="account?.complete === false"></span>
            </td>
            <td>{{ account?.expectedValue / 100 | toAccountancy}}</td>
            <td>{{ account?.importedValue / 100 | toAccountancy}}</td>
            <td>
              <span
                class="fa"
                [class.fa-check]="account?.importAccepted === true"
                [class.text-success]="account?.importAccepted === true"
                [class.fa-times]="account?.importAccepted === false"
                [class.text-danger]="account?.importAccepted === false"></span>
            </td>
            <td>{{ account?.importNote }}</td>
            <td class="text-right"></td>
          </tr>
          </tbody>

          <tfoot>
          <tr>
            <td colspan="99" class="text-center">
              <app-pagination [totalPages]="totalPages" [currentPage]="currentPage"
                              (pageChanged)="onPageChanged($event)"></app-pagination>
            </td>
          </tr>
          </tfoot>
        </table>
      </div>
    </section>
  </div>
</div>

<app-delete-modal type="account" (confirm)="delete($event)" [itemDeleted]="accountDeleted"
                  #deleteModal></app-delete-modal>
