import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NotificationService } from '../../../shared/notification.service';
import { CaseExpenditure } from '../case-expenditure';
import { CaseExpenditureService } from '../case-expenditure.service';
import { AuthService } from '../../../auth/auth.service';

@Component({
  selector: 'app-case-expenditure-list',
  templateUrl: 'list.component.html',
  styleUrls: ['list.component.css'],
})
export class CaseExpenditureListComponent implements OnInit {
  @Input() caseRefreshed: EventEmitter<string>;

  @Input() caseId: string;
  public caseExpenditures: CaseExpenditure[] = [];
  public isLoading: boolean = true;
  public totalPages: number = 1;
  public currentPage: number = 1;
  public totalResults: number = 0;
  public expenditureCreated: EventEmitter<boolean> = new EventEmitter<
    boolean
  >();
  public itemDeleted: EventEmitter<boolean> = new EventEmitter<boolean>();
  public newExpenditure: CaseExpenditure = new CaseExpenditure();
  @Output()
  public expenditureUpdated: EventEmitter<number> = new EventEmitter<number>();
  @Output()
  public protectionValueUpdated: EventEmitter<number> = new EventEmitter<
    number
  >();
  public editorOptions = {
    placeholder: 'Enter your note...',
    modules: {
      toolbar: ['bold', 'link'],
    },
  };
  private limit: number = 1000;
  private insuranceId: string = '004e6c42-3522-4511-ab4d-32ff4135adeb';

  constructor(
    private caseExpenditureService: CaseExpenditureService,
    private notificationService: NotificationService,
    public readonly authService: AuthService,
  ) {
    this.caseExpenditureService.loading.subscribe(isLoading => {
      this.isLoading = isLoading;
    });
    this.caseExpenditureService.totalPages.subscribe(totalPages => {
      this.totalPages = totalPages;
    });
    this.caseExpenditureService.currentPage.subscribe(currentPage => {
      this.currentPage = currentPage;
    });
    this.caseExpenditureService.totalResults.subscribe(totalResults => {
      this.totalResults = totalResults;
    });
  }

  private get protectionValue(): number {
    let protectionFoundValue = 0;

    this.caseExpenditures.forEach(expenditureItem => {
      if (expenditureItem.expenditure.id === this.insuranceId) {
        protectionFoundValue = expenditureItem.value;
      }
    });

    return protectionFoundValue;
  }

  private get totalExpenditure(): number {
    let totalExpenditure = 0;

    this.caseExpenditures.forEach(expenditureItem => {
      totalExpenditure = totalExpenditure + expenditureItem.value;
    });

    return totalExpenditure / 100;
  }

  public ngOnInit(): void {
    this.loadCaseExpenditures(1, this.limit);
    this.caseRefreshed.subscribe(id => {
      this.caseId = id;
      this.onPageChanged(1);
    });
  }

  public onPageChanged(pageNumber: number): void {
    this.loadCaseExpenditures(pageNumber, this.limit);
  }

  public loadCaseExpenditures(page?: number, limit?: number): void {
    const listCountries = this.caseExpenditureService
      .listCaseExpenditures(page, limit, { parameters: { id: this.caseId } })
      .subscribe((caseExpenditures: CaseExpenditure[]) => {
        this.caseExpenditures = caseExpenditures;
        this.expenditureUpdated.next(this.totalExpenditure);
        this.protectionValueUpdated.next(this.protectionValue);
        listCountries.unsubscribe();
      });
  }

  public create(formData: CaseExpenditure) {
    this.caseExpenditureService
      .createCaseExpenditure(this.newExpenditure, {
        parameters: { id: this.caseId },
      })
      .subscribe(results => {
        this.expenditureCreated.next(true);
        this.onPageChanged(1);
      });
    console.log('creating', this.newExpenditure);
  }

  public delete(id: string) {
    this.caseExpenditureService.deleteCaseExpenditure(id).subscribe(
      deleted => {
        this.notificationService.success(
          `Expenditure Deleted`,
          `Expenditure with ID ${id} was deleted.`,
          5,
        );
        this.itemDeleted.next(true);
        this.onPageChanged(this.currentPage);
      },
      err => {
        this.notificationService.error(
          `Expenditure Not Deleted`,
          err.error.message,
          10,
        );
        this.itemDeleted.next(false);
        this.onPageChanged(this.currentPage);
      },
    );
  }
}
