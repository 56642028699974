import { Component, OnInit } from '@angular/core';
import { ProductService } from '../product.service';
import { Product } from '../product';
import { Router } from '@angular/router';
import { NotificationService } from '../../shared/notification.service';

@Component({
  selector: 'app-product-create',
  templateUrl: 'create.component.html',
})
export class ProductCreateComponent implements OnInit {
  /**
   * Details of the product being created.
   */
  public product: Product;

  /**
   * Are we currently performing a loading action?
   *
   * @type {boolean}
   */
  public isLoading: boolean = false;

  /**
   * Load up all our required services and subscribe to every observable we need.
   *
   * @param {ProductService} productService
   * @param {NotificationService} notificationService
   * @param {Router} router
   */
  constructor(
    private productService: ProductService,
    private notificationService: NotificationService,
    private router: Router,
  ) {
    this.productService.loading.subscribe(result => (this.isLoading = result));
  }

  /**
   * Initialise the component, creating a new blank product if required.
   */
  public ngOnInit(): void {
    if (!this.product) {
      this.product = new Product();
    }
  }

  /**
   * Perform the create action which adds the new product into the backend.
   */
  public create() {
    const createProduct = this.productService
      .createProduct(this.product)
      .subscribe(
        (result: Product) => {
          this.notificationService.success(
            `Product Created`,
            `New product ${result.name} has been created with ID of ${
              result.id
            }.`,
            5,
          );
          this.product = new Product();
          createProduct.unsubscribe();
          this.router.navigate(['/products']);
        },
        err => {
          this.notificationService.error(
            `Could not create Product`,
            err.error.message,
            10,
          );
        },
      );
  }
}
