import {Component, EventEmitter, Output, ViewChild} from '@angular/core';

import {CaseAnnual} from '../case-annual';
import {CaseAnnualService} from '../case-annual.service';

@Component({
  selector: 'app-case-annual-create',
  templateUrl: 'create.component.html',
})
export class CaseAnnualCreateComponent {
  @ViewChild('staticModal') staticModal;

  @Output() completed = new EventEmitter<boolean>();

  public disabled: boolean = false;

  public model: CaseAnnual = new CaseAnnual();

  private givenCaseId: string;

  constructor(private readonly caseAnnualService: CaseAnnualService) {}

  public get caseId(): string {
    return this.givenCaseId;
  }

  public open(caseId: string): void {
    this.givenCaseId = caseId;
    this.staticModal.show();
  }

  public close() {
    this.workCompleted();
  }

  public confirmCreate($event) {
    $event.preventDefault();
    this.disabled = true;
    this.caseAnnualService
      .createCaseAnnual(this.model, { parameters: { id: this.caseId } })
      .subscribe(() => {
        this.workCompleted();
      });
  }

  private workCompleted() {
    this.model = new CaseAnnual();
    this.disabled = false;
    this.staticModal.hide();
    this.completed.next(true);
  }
}
