import { Component, EventEmitter, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, map } from 'rxjs/operators';
import { CreditorService } from '../creditor.service';
import { Creditor } from '../creditor';
import { NotificationService } from '../../shared/notification.service';

@Component({
  selector: 'app-creditor-list',
  templateUrl: 'list.component.html',
  styleUrls: [],
})
export class CreditorListComponent implements OnInit {
  public creditor: Creditor;

  /**
   * A list of all the creditors currently loaded.
   *
   * @type {Creditor[]}
   */
  public creditors: Creditor[] = [];

  /**
   * Are we currently performing a loading action?
   *
   * @type {boolean}
   */
  public isLoading: boolean = true;

  /**
   * The total number of pages in this list of creditors.
   *
   * @type {number}
   */
  public totalPages: number = 1;

  /**
   * The current page of creditors that we are viewing.
   *
   * @type {number}
   */
  public currentPage: number = 1;

  /**
   * The total number of creditors that can be viewed in the system.
   *
   * @type {number}
   */
  public totalResults: number = 0;

  /**
   * The subject that will be used for debouncing search filter.
   *
   * @type {Creditor[]}
   */
  filterSubject = new Subject<string>();

  /**
   * The search keyword for filtering creditors.
   *
   * @type {string}
   */
  public searchTerm: string = '';

  /**
   * An Emitter to alert other components when a creditor has been deleted.
   *
   * @type {EventEmitter<boolean>}
   */
  public creditorDeleted: EventEmitter<boolean> = new EventEmitter<boolean>();

  /**
   * Load up all our required services and subscribe to every observable we need.
   *
   * @param {CreditorService} creditorService
   * @param {NotificationService} notificationService
   */
  constructor(
    private creditorService: CreditorService,
    private notificationService: NotificationService,
  ) {
    this.creditorService.loading.subscribe((isLoading) => {
      this.isLoading = isLoading;
    });
    this.creditorService.totalPages.subscribe((totalPages) => {
      this.totalPages = totalPages;
    });
    this.creditorService.currentPage.subscribe((currentPage) => {
      this.currentPage = currentPage;
    });
    this.creditorService.totalResults.subscribe((totalResults) => {
      this.totalResults = totalResults;
    });

    /**
     * @debounce when the user stops typing in the search input
     * we load the filtered creditor list.
     */
    this.filterSubject.pipe(debounceTime(600)).subscribe(() => {
      this.loadCreditors(this.currentPage, null, this.searchTerm);
    });
  }

  /**
   * When the component is initialised we set it to load
   * page one of the creditor list.
   */
  public ngOnInit(): void {
    this.loadCreditors(1, null, this.searchTerm);
  }

  /**
   * When we receive a page change from the pagination
   * component we load the appropriate page for the
   * creditor list.
   *
   * @param {number} pageNumber
   */
  public onPageChanged(pageNumber: number): void {
    this.loadCreditors(pageNumber, null, this.searchTerm);
  }

  /**
   * Loads a list of creditors using the given per page limit.
   *
   * @param {number} page The current page we require.
   * @param {number} limit The number of results per page.
   */
  public loadCreditors(page?: number, limit?: number, filter?: string): void {
    const listCreditors = this.creditorService
      .listCreditors(page, limit, filter)
      .subscribe((creditors: Creditor[]) => {
        this.creditors = creditors;
        listCreditors.unsubscribe();
      });
  }

  /**
   * When receiving confirmation of an item being deleted
   * we call the back end with the appropriate creditor ID
   * to delete the creditor.
   *
   * @param {string} id
   */
  public delete(id: string) {
    this.creditorService.deleteCreditor(id).subscribe(
      (deleted) => {
        this.notificationService.success(
          `Creditor Deleted`,
          `Creditor with ID ${id} was deleted.`,
          5,
        );
        this.creditorDeleted.next(true);
        this.onPageChanged(this.currentPage);
      },
      (err) => {
        this.notificationService.error(
          `Creditor Not Deleted`,
          err.error.message,
          10,
        );
        this.creditorDeleted.next(false);
        this.onPageChanged(this.currentPage);
      },
    );
  }

  /**
   * When the user writes something in the search input it will trigger.
   */
  public searchFilter() {
    this.filterSubject.next();
  }

  /**
   * When the clear filter icon is click we are clearing the search filter.
   */
  public clearSearch() {
    if (this.searchTerm !== '') {
      this.searchTerm = '';
      this.loadCreditors(1);
    }
  }
}
