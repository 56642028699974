import { Component, ElementRef } from '@angular/core';
import { Router } from '@angular/router';
import { ComponentClass, FunctionComponent } from 'react';
import {
  LinkProps,
  ReactWrapper,
  ElementRefWrapper,
  RouterWrapper,
} from 'react-inside-angular';
import CreateTitle, {
  CreateTitleProps,
} from '../../../chief-react/src/App/Pages/Titles/TitleCreate';

@Component({
  selector: 'app-titles-create',
  template: '<div [id]="rootId"></div>',
})
export class TitleCreateComponent extends ReactWrapper<CreateTitleProps> {
  protected router: RouterWrapper;
  protected elemRef: ElementRefWrapper;

  constructor(router: Router, elemRef: ElementRef) {
    super();
    this.router = (router as unknown) as RouterWrapper;
    this.elemRef = (elemRef as unknown) as ElementRefWrapper;
  }

  // Transfer any props from Angular to the React component
  public setProps(): CreateTitleProps {
    return {};
  }

  // Define the React component being wrapped
  public setComponent():
    | string
    | FunctionComponent<LinkProps<CreateTitleProps>>
    | ComponentClass<LinkProps<CreateTitleProps>, any> {
    return CreateTitle;
  }
}
