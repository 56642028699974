import { Component, EventEmitter, OnInit } from '@angular/core';
import { PortfolioService } from '../portfolio.service';
import { Portfolio } from '../portfolio';
import { NotificationService } from '../../shared/notification.service';

@Component({
  selector: 'app-portfolio-list',
  templateUrl: 'list.component.html',
  styleUrls: [],
})
export class PortfolioListComponent implements OnInit {
  public portfolio: Portfolio;

  /**
   * A list of all the portfolios currently loaded.
   *
   * @type {Portfolio[]}
   */
  public portfolios: Portfolio[] = [];

  /**
   * Are we currently performing a loading action?
   *
   * @type {boolean}
   */
  public isLoading: boolean = true;

  /**
   * The total number of pages in this list of portfolios.
   *
   * @type {number}
   */
  public totalPages: number = 1;

  /**
   * The current page of portfolios that we are viewing.
   *
   * @type {number}
   */
  public currentPage: number = 1;

  /**
   * The total number of portfolios that can be viewed in the system.
   *
   * @type {number}
   */
  public totalResults: number = 0;

  /**
   * An Emitter to alert other components when a portfolio has been deleted.
   *
   * @type {EventEmitter<boolean>}
   */
  public portfolioDeleted: EventEmitter<boolean> = new EventEmitter<boolean>();

  /**
   * Load up all our required services and subscribe to every observable we need.
   *
   * @param {PortfolioService} portfolioService
   * @param {NotificationService} notificationService
   */
  constructor(
    private portfolioService: PortfolioService,
    private notificationService: NotificationService,
  ) {
    this.portfolioService.loading.subscribe(isLoading => {
      this.isLoading = isLoading;
    });
    this.portfolioService.totalPages.subscribe(totalPages => {
      this.totalPages = totalPages;
    });
    this.portfolioService.currentPage.subscribe(currentPage => {
      this.currentPage = currentPage;
    });
    this.portfolioService.totalResults.subscribe(totalResults => {
      this.totalResults = totalResults;
    });
  }

  /**
   * When the component is initialised we set it to load
   * page one of the portfolio list.
   */
  public ngOnInit(): void {
    this.loadPortfolios(1);
  }

  /**
   * When we receive a page change from the pagination
   * component we load the appropriate page for the
   * portfolio list.
   *
   * @param {number} pageNumber
   */
  public onPageChanged(pageNumber: number): void {
    this.loadPortfolios(pageNumber);
  }

  /**
   * Loads a list of portfolios using the given per page limit.
   *
   * @param {number} page The current page we require.
   * @param {number} limit The number of results per page.
   */
  public loadPortfolios(page?: number, limit?: number): void {
    const listPortfolios = this.portfolioService
      .listPortfolios(page, limit)
      .subscribe((portfolios: Portfolio[]) => {
        this.portfolios = portfolios;
        listPortfolios.unsubscribe();
      });
  }

  /**
   * When receiving confirmation of an item being deleted
   * we call the back end with the appropriate portfolio ID
   * to delete the portfolio.
   *
   * @param {string} id
   */
  public delete(id: string) {
    this.portfolioService.deletePortfolio(id).subscribe(
      deleted => {
        this.notificationService.success(
          `Portfolio Deleted`,
          `Portfolio with ID ${id} was deleted.`,
          5,
        );
        this.portfolioDeleted.next(true);
        this.onPageChanged(this.currentPage);
      },
      err => {
        this.notificationService.error(
          `Portfolio Not Deleted`,
          err.error.message,
          10,
        );
        this.portfolioDeleted.next(false);
        this.onPageChanged(this.currentPage);
      },
    );
  }
}
