import { Component, EventEmitter, OnInit } from '@angular/core';
import { CaseService } from '../case.service';
import { Case } from '../case';
import { NotificationService } from '../../shared/notification.service';
import { AuthService } from '../../auth/auth.service';
import { HttpOptions } from '../../shared/http/http-options';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-case-list',
  templateUrl: 'list.component.html',
})
export class CaseListComponent implements OnInit {
  public filters = {};

  public case: Case;

  /**
   * A list of all the cases currently loaded.
   *
   * @type {Case[]}
   */
  public cases: Case[] = [];

  /**
   * Are we currently performing a loading action?
   *
   * @type {boolean}
   */
  public isLoading: boolean = true;

  /**
   * The total number of pages in this list of cases.
   *
   * @type {number}
   */
  public totalPages: number = 1;

  /**
   * The current page of cases that we are viewing.
   *
   * @type {number}
   */
  public currentPage: number = 1;

  /**
   * The total number of cases that can be viewed in the system.
   *
   * @type {number}
   */
  public totalResults: number = 0;

  /**
   * An Emitter to alert other components when a case has been deleted.
   *
   * @type {EventEmitter<boolean>}
   */
  public caseDeleted: EventEmitter<boolean> = new EventEmitter<boolean>();

  constructor(
    private caseService: CaseService,
    private notificationService: NotificationService,
    public readonly authService: AuthService,
  ) {
    this.caseService.loading.subscribe((isLoading) => {
      this.isLoading = isLoading;
    });
    this.caseService.totalPages.subscribe((totalPages) => {
      this.totalPages = totalPages;
    });
    this.caseService.currentPage.subscribe((currentPage) => {
      this.currentPage = currentPage;
    });
    this.caseService.totalResults.subscribe((totalResults) => {
      this.totalResults = totalResults;
    });
  }

  /**
   * When the component is initialised we set it to load
   * page one of the case list.
   */
  public ngOnInit(): void {
    this.loadCases(1);
  }

  /**
   * When we receive a page change from the pagination
   * component we load the appropriate page for the
   * case list.
   *
   * @param {number} pageNumber
   */
  public onPageChanged(pageNumber: number): void {
    this.loadCases(pageNumber);
  }

  /**
   * Loads a list of cases using the given per page limit.
   *
   * @param {number} page The current page we require.
   * @param {number} limit The number of results per page.
   */
  public loadCases(page?: number, limit?: number): void {
    let filters = {};

    Object.keys(this.filters).forEach((item) => {
      filters[item] = this.filters[item].id;
    });

    const listCases = this.caseService
      .listCases(page, limit, filters)
      .subscribe((cases: Case[]) => {
        this.cases = cases;
        listCases.unsubscribe();
      });
  }

  public downloadCases(): void {
    let filters = {};

    Object.keys(this.filters).forEach((item) => {
      filters[item] = this.filters[item].id;
    });

    filters['download'] = 'true';

    const urlToRequest = `${environment.endpoint}${this.parseEndpoint(
      'cases',
    )}?token=${localStorage.getItem('token')}${this.parseQueryObject(filters)}`;
    window.open(urlToRequest, '_blank');
  }

  /**
   * When receiving confirmation of an item being deleted
   * we call the back end with the appropriate case ID
   * to delete the case.
   *
   * @param {string} id
   */
  public delete(id: string) {
    this.caseService.deleteCase(id).subscribe(
      (deleted) => {
        this.notificationService.success(
          `Case Deleted`,
          `Case with ID ${id} was deleted.`,
          5,
        );
        this.caseDeleted.next(true);
        this.onPageChanged(this.currentPage);
      },
      (err) => {
        this.notificationService.error(
          `Case Not Deleted`,
          err.error.message,
          10,
        );
        this.caseDeleted.next(false);
        this.onPageChanged(this.currentPage);
      },
    );
  }

  public resetFilters() {
    this.filters = {};
    this.loadCases(1);
  }

  private parseQueryObject(query: any): string {
    if (!query || Object.keys(query).length === 0) {
      return undefined;
    }

    return (
      '&' +
      Object.keys(query)
        .map((queryKey) => `${queryKey}=${query[queryKey]}`)
        .join('&')
    );
  }

  private parseEndpoint(endpoint: string, options?: HttpOptions): string {
    if (!options || !options.parameters) {
      return endpoint;
    }

    Object.keys(options.parameters).forEach((key) => {
      endpoint = endpoint.replace(':' + key, options.parameters[key]);
    });

    return endpoint;
  }
}
