import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  TemplateRef,
} from '@angular/core';

import { CaseCreditor } from '../../creditors/case-creditor';
import { CasePaymentScheduleItem } from '../../../../../chief-types/src/CasePaymentSchedule';
import { ChartOfAccountsService } from '../../../financials/chart-of-accounts/chart-of-accounts.service';
import { FeeComparison } from '../fee-comparison';
import { FeeComparisonService } from '../fee-comparison.service';
import { NotificationService } from '../../../shared/notification.service';
import { calculateContributions } from '@tech3k/debt-financials/dist/modules/iva/calculate-contributions.function';
import { calculateCreditorAvailableAmount } from '@tech3k/debt-financials/dist/modules/iva/calculate-creditor-available-amount.function';
import { calculateCreditorTotals } from '@tech3k/debt-financials/dist/modules/iva/calculate-creditor-totals.function';
import { calculateDividend } from '@tech3k/debt-financials/dist/modules/iva/calculate-dividend.function';
import { calculateFees } from '@tech3k/debt-financials/dist/modules/iva/calculate-fees.function';

@Component({
  selector: 'app-case-fee-comparison-form',
  templateUrl: 'form.component.html',
})
export class CaseFeeComparisonFormComponent implements OnInit {
  @Input() caseId: string;
  @Input() paymentScheduleCreated: boolean;
  @Input() paymentScheduleItems?: CasePaymentScheduleItem[];
  @Input() caseRefreshed: EventEmitter<string>;
  @Input() creditorList: CaseCreditor[];
  @Input() disposableIncome: number;
  @Input() public ivaDuration: number;
  @Input() public supervisorPercentage: number;
  @Output() totalSoaValue: EventEmitter<number> = new EventEmitter<number>();

  isSaving: boolean = false;

  @Output()
  paymentScheduleCreatedAction: EventEmitter<boolean> = new EventEmitter<boolean>();

  public feeComparison: FeeComparison[] = [];

  @Input() public paymentFrequency: string = 'month';

  @Input() public steppedPayments: any[] = [];

  public subNomsFromSups = false;

  public totals: any = {
    totalBankruptcyFeesMain: 0,
    totalBankruptcyFeesPartner: 0,
    totalIvaFeesMain: 0,
    totalBankruptcyMain: 0,
    totalBankruptcyPartner: 0,
    totalVoluntaryArrangement: 0,
    mainCreditorValue: 0,
    partnerCreditorValue: 0,
    mainExcludedCreditorValue: 0,
    partnerExcludedCreditorValue: 0,
    jointCreditorValue: 0,
  };
  modalRef: BsModalRef;
  private creditors: CaseCreditor[] = [];

  constructor(
    private readonly feeComparisonService: FeeComparisonService,
    private readonly chartOfAccountsService: ChartOfAccountsService,
    private modalService: BsModalService,
    protected readonly notification: NotificationService,
  ) {}

  public get totalIvaDividend(): number {
    return calculateDividend(
      this.totalAvailableToIvaCreditors,
      calculateCreditorTotals(
        (this.creditorList || [])
          .filter((c) => c.excluded === false)
          .map((c) => {
            let creditorValue = c.value;
            if (c.admittedValue && c.admittedValue > 0) {
              creditorValue = c.admittedValue;
            } else if (c.actualValue && c.actualValue > 0) {
              creditorValue = c.actualValue;
            }

            return {
              value: creditorValue,
              secured: c.secured,
            };
          }),
      ).unsecured,
    );
  }

  public get totalAvailableToIvaCreditors(): number {
    return calculateCreditorAvailableAmount(
      calculateContributions([{ value: this.ivaPayment }]),
      this.totalPayableIvaFees,
    );
  }

  public get totalPayableIvaFees(): number {
    return calculateFees(
      this.feeComparison.map((fc) => {
        let thisFee = fc.voluntaryArrangementValue;

        if (fc.chartOfAccounts.name === 'IP Bond Fee') {
          thisFee =
            (fc.voluntaryArrangementValue * (this.ivaDuration + 12)) /
            this.ivaDuration;
        }

        return {
          value: thisFee,
          isAppreciating: fc.chartOfAccounts.appreciating,
          appreciatingTime: fc.chartOfAccounts.appreciationType,
        };
      }),
      this.ivaDuration,
    );
  }

  public get totalBankruptcyDividend(): number {
    return calculateDividend(
      this.totalAvailableToBankruptcyCreditors,
      calculateCreditorTotals(
        (this.creditorList || []).map((c: CaseCreditor) => {
          let creditorValue = c.value;
          if (c.admittedValue && c.admittedValue > 0) {
            creditorValue = c.admittedValue;
          } else if (c.actualValue && c.actualValue > 0) {
            creditorValue = c.actualValue;
          }

          return {
            value: creditorValue,
            secured: c.secured,
          };
        }),
      ).unsecured,
    );
  }

  public get totalAvailableToBankruptcyCreditors(): number {
    return calculateCreditorAvailableAmount(
      calculateContributions([{ value: this.bankruptcyPayment }]),
      this.totalPayableBankruptcyFees,
    );
  }

  public get totalPayableBankruptcyFees(): number {
    return calculateFees(
      this.feeComparison.map((fc) => {
        let thisFee = fc.bankruptcyMainValue;

        if (fc.chartOfAccounts.name === 'IP Bond Fee') {
          thisFee =
            (fc.bankruptcyMainValue * (this.ivaDuration + 12)) /
            this.ivaDuration;
        }

        return {
          value: thisFee,
          isAppreciating: fc.chartOfAccounts.appreciating,
          appreciatingTime: fc.chartOfAccounts.appreciationType,
        };
      }),
      this.ivaDuration,
    );
  }

  public get bankruptcyPayment(): number {
    return (this.disposableIncome - 4000) * 36;
  }

  public get ivaPayment(): number {
    // If a payment schedule exists the voluntary contributions should = total expected value
    if (this.paymentScheduleCreated && this.paymentScheduleItems) {
      let expectedValue = 0;
      this.paymentScheduleItems.forEach(
        (item) => (expectedValue += item.expectedValue),
      );
      return expectedValue;
    }

    // Otherwise an estimate is made using the disposable income
    let steppedTotals = 0;
    const extraPayments = this.steppedPayments.length * this.disposableIncome;
    this.steppedPayments.forEach((i) => (steppedTotals += Number(i.value)));
    return (
      this.disposableIncome * this.ivaDuration + (steppedTotals - extraPayments)
    );
  }

  public get totalBankruptcyMain(): number {
    return this.totals.totalBankruptcyMain * -1;
  }

  public get totalBankruptcyPartner(): number {
    return this.totals.totalBankruptcyPartner * -1;
  }

  public get totalVoluntaryArrangement(): number {
    return this.totals.totalVoluntaryArrangement * -1;
  }

  public get mainBankruptcyDividend(): number {
    return (
      Math.round(
        (this.totalBankruptcyMain /
          (this.mainCreditorValue() +
            this.mainCreditorValue(true) +
            this.jointCreditorValue())) *
          10000,
      ) / 100
    );
  }

  public get ivaDividend(): number {
    return (
      Math.round(
        (this.totalVoluntaryArrangement /
          (this.mainCreditorValue() +
            this.partnerCreditorValue() +
            this.jointCreditorValue())) *
          10000,
      ) / 100
    );
  }

  // public get partnerBankruptcyDividend(): number {
  //   return (
  //       Math.round(
  //           this.totalBankruptcyPartner /
  //           (this.calculateCreditors()) *
  //           10000,
  //       ) / 100
  //   );
  // }

  public get distributionsYearOne(): number {
    return Math.round(this.ivaDividend * 0.07 * 100) / 100;
  }

  public get distributionsYearTwoToFive(): number {
    return Math.round(this.ivaDividend * 0.2325 * 100) / 100;
  }

  public get distributionsYearFive(): number {
    return Math.round(this.ivaDividend * 100) / 100;
  }

  private get nomsFee(): number {
    let nomsFee = 0;

    this.feeComparison.forEach((item) => {
      if (item.chartOfAccounts.name === "Nominee's Fee") {
        nomsFee = item.voluntaryArrangementValue;
      }
    });

    return nomsFee;
  }

  public capitalizeFirstLetter(string: string): string {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  public frequencyText(short: string, number: number): string {
    let multiplier = 1;
    switch (short) {
      case 'bi-week':
        multiplier = 2;
        break;
      case 'four-week':
        multiplier = 4;
        break;
      default:
        multiplier = 1;
    }

    short = this.capitalizeFirstLetter(
      short.replace('-', '').replace('bi', '').replace('four', ''),
    );

    return `${short} ${
      number === 1 || multiplier === 1 ? number : (number - 1) * multiplier + 1
    }`;
  }

  public ngOnInit(): void {
    this.caseRefreshed.subscribe((id) => {
      this.caseId = id;
      this.loadFeeComparisonForCase();
    });
    this.loadFeeComparisonForCase();
  }

  addSteppedPayment() {
    this.steppedPayments.push({
      value:
        this.steppedPayments.length > 0
          ? this.steppedPayments[this.steppedPayments.length - 1].value
          : this.disposableIncome,
    });
  }

  generatePaymentSchedule(template: TemplateRef<any>) {
    this.saveFeeComparison();
    this.openModal(template);
  }

  openModal(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(template);
  }

  public saveUpdatedTotals() {}

  public calculateUpdatedTotals(id?: string, event?) {
    this.totals = {
      totalBankruptcyFeesMain: 0,
      totalBankruptcyFeesPartner: 0,
      totalIvaFeesMain: 0,
      totalBankruptcyMain: 0,
      totalBankruptcyPartner: 0,
      totalVoluntaryArrangement: 0,
      mainCreditorValue: 0,
      partnerCreditorValue: 0,
      mainExcludedCreditorValue: 0,
      partnerExcludedCreditorValue: 0,
      jointCreditorValue: 0,
    };
    this.calculateTotals();
    this.calculateCreditors();

    // Removing the auto calculation of sups percentage 08/10/18
    // if (id === 'ccbccf67-0924-480f-8a62-4f6a549445a8') {
    //   this.supervisorPercentage =
    //     event.target.value /
    //     (this.disposableIncome * this.ivaDuration) *
    //     100 *
    //     100;
    // }
  }

  public mainCreditorValue(excluded: boolean = false): number {
    return excluded
      ? this.totals.mainExcludedCreditorValue * -1
      : this.totals.mainCreditorValue * -1;
  }

  public partnerCreditorValue(excluded: boolean = false): number {
    return excluded
      ? this.totals.partnerExcludedCreditorValue * -1
      : this.totals.partnerCreditorValue * -1;
  }

  public jointCreditorValue(): number {
    return this.totals.jointCreditorValue * -1;
  }

  public changeSupervisoryPercentage(overrideFee: boolean = false) {
    this.feeComparison.forEach((item) => {
      if (
        item.chartOfAccounts.id === 'ccbccf67-0924-480f-8a62-4f6a549445a8' &&
        (item.voluntaryArrangementValue === 0 || overrideFee)
      ) {
        item.voluntaryArrangementValue =
          Math.round(
            (this.ivaPayment -
              (this.subNomsFromSups === true ? this.nomsFee : 0)) *
              (this.supervisorPercentage / 100) *
              100,
          ) / 100;
      }
    });
    this.calculateUpdatedTotals();
  }

  public saveFeeComparison(): void {
    this.isSaving = true;
    this.feeComparisonService
      .saveFeeComparison(this.caseId, {
        duration: this.ivaDuration,
        supervisoryPercentage: this.supervisorPercentage,
        items: this.feeComparison,
        steppedPayments: this.steppedPayments.map((item) => item.value),
        paymentFrequency: this.paymentFrequency,
      })
      .subscribe((response: boolean) => {
        this.notification.success(
          'Success',
          'Fee Comparison has been Saved',
          5,
        );
        this.isSaving = false;
      });
  }

  public removeStep(i) {
    this.steppedPayments.splice(i);
  }

  appreciatingValue(value, item) {
    if (item.chartOfAccounts.appreciating === false) {
      return value;
    }

    let numberOfPayments =
      this.ivaDuration / item.chartOfAccounts.appreciationType;

    if (item.chartOfAccounts.appreciationType === 12) {
      numberOfPayments = numberOfPayments + 1;
    }

    return value * numberOfPayments;
  }

  private getItemValue(item) {
    if (item.admittedValue > 0) {
      return item.admittedValue;
    } else if (item.actualValue > 0 || item.debtPaid === true) {
      return item.actualValue;
    } else {
      return item.value;
    }
  }

  private calculateCreditors() {
    this.creditorList.forEach((item) => {
      if (item.secured === true) {
        return;
      }

      if (
        item.excluded === false &&
        (!item.person || item.person === undefined)
      ) {
        this.totals.jointCreditorValue =
          this.totals.jointCreditorValue + this.getItemValue(item);
      } else if (item.excluded === true) {
        this.totals.mainExcludedCreditorValue =
          this.totals.mainExcludedCreditorValue + this.getItemValue(item);
      } else {
        this.totals.mainCreditorValue =
          this.totals.mainCreditorValue + this.getItemValue(item);
      }
    });
  }

  private calculateTotals() {
    this.feeComparison.forEach((item) => {
      this.totals.totalBankruptcyMain =
        this.totals.totalBankruptcyMain +
        this.appreciatingValue(item.bankruptcyMainValue, item);

      this.totals.totalBankruptcyPartner =
        this.totals.totalBankruptcyPartner +
        this.appreciatingValue(item.bankruptcyPartnerValue, item);

      this.totals.totalVoluntaryArrangement =
        this.totals.totalVoluntaryArrangement +
        this.appreciatingValue(item.voluntaryArrangementValue, item);
    });

    this.totals.totalBankruptcyFeesMain = this.totals.totalBankruptcyMain;
    this.totals.totalBankruptcyFeesPartner = this.totals.totalBankruptcyPartner;
    this.totals.totalIvaFeesMain = this.totals.totalVoluntaryArrangement;

    this.totalSoaValue.next(this.totals.totalVoluntaryArrangement);

    this.totals.totalBankruptcyMain =
      this.totals.totalBankruptcyMain - this.bankruptcyPayment;
    this.totals.totalBankruptcyPartner =
      this.totals.totalBankruptcyPartner - this.bankruptcyPayment;
    this.totals.totalVoluntaryArrangement =
      this.totals.totalVoluntaryArrangement - this.ivaPayment;
  }

  private loadFeeComparisonForCase(): void {
    this.feeComparisonService
      .listFeeComparison(1, 1000, {
        parameters: {
          id: this.caseId,
        },
      })
      .subscribe((items) => {
        this.feeComparison = items.map((item) => {
          if (item.chartOfAccounts.name === 'Trustees Fee') {
            item.bankruptcyMainValue = this.bankruptcyPayment * 0.25 * 1.2;
          }

          return item;
        });
        this.changeSupervisoryPercentage();
        this.calculateUpdatedTotals();
      });
  }
}
