import { useEffect, useState } from 'react';
import useToken from './UseToken';

const apiUrl = ' https://chief-backend-master.herokuapp.com/v1';

type MetaInformation = {
  totalPages: number;
  totalResults: number;
};

type ApiResponse<T> = {
  data: T;
  meta: MetaInformation;
};

export type PaginationOptions = {
  page?: number;
  limit?: number;
};

export type UseApiState<T, O> = {
  loading: boolean;
  error: string | undefined;
  item: T | undefined;
  items: T[] | undefined;
  metaData: MetaInformation | undefined;
  tokenStorageChecked: boolean,
  loadItems: (extraOptions?: O) => void;
  loadItem: (id: string) => Promise<void>;
  createItem: (item: T) => Promise<boolean>;
  updateItem: (id: string, item: T) => Promise<boolean>;
  deleteItem: (id: string) => Promise<boolean>;
};

const useApi = <T, O = any>(
  path: string,
  options?: O,
  autoload: boolean = false,
): UseApiState<T, O> => {
  const { token, tokenStorageChecked } = useToken();
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | undefined>(undefined);
  const [response, setResponse] = useState<T[] | undefined>(undefined);
  const [item, setItem] = useState<T | undefined>(undefined);
  const [metaData, setMetaData] = useState<MetaInformation | undefined>(
    undefined,
  );

  const loadItem = async (id: string) => {
    setLoading(true);
    return fetch(`${apiUrl}/${path}/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then((res) => {
        if (res.status !== 200) {
          throw new Error(res.statusText);
        }

        res.json().then(({ data }: ApiResponse<T>) => {
          setItem(data);
        });
      })
      .catch((e) => {
        setError(e.message);
      })
      .finally(() => setLoading(false));
  };

  const createItem = async (item: T): Promise<boolean> => {
    setLoading(true);
    return fetch(`${apiUrl}/${path}`, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(item),
    })
      .then((res) => {
        if (res.status !== 201) {
          throw new Error(res.statusText);
        }

        return true;
      })
      .catch((e) => {
        setError(e.message);
        return false;
      })
      .finally(() => setLoading(false));
  };

  const updateItem = async (id: string, item: T): Promise<boolean> => {
    setLoading(true);
    return fetch(`${apiUrl}/${path}/${id}`, {
      method: 'PUT',
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(item),
    })
      .then((res) => {
        if (res.status !== 200) {
          throw new Error(res.statusText);
        }

        return true;
      })
      .catch((e) => {
        setError(e.message);
        return false;
      })
      .finally(() => setLoading(false));
  };

  const deleteItem = async (id: string): Promise<boolean> => {
    if (window.confirm('Are you sure you want to delete this item?') === true) {
      setLoading(true);
      return fetch(`${apiUrl}/${path}/${id}`, {
        method: 'DELETE',
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
        .then((res) => {
          if (res.status !== 200) {
            throw new Error(res.statusText);
          }

          return true;
        })
        .catch((e) => {
          setError(e.message);
          return false;
        })
        .finally(() => setLoading(false));
    } else {
      return false;
    }
  };

  const loadData = (extraOptions?: Partial<O>): void => {
    const allOptions: O = { ...options, ...extraOptions } as O;
    setLoading(true);
    setError(undefined);
    fetch(
      `${apiUrl}/${path}${
        allOptions
          ? `?${Object.keys(allOptions)
              .map((key: string) => `${key}=${allOptions[key as keyof O]}`)
              .join('&')}`
          : ''
      }`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    )
      .then((res) => {
        if (res.status === 200) {
          res.json().then(({ data, meta }: ApiResponse<T[]>) => {
            setResponse(data);
            setMetaData(meta);
          });
        } else {
          throw new Error(res.statusText);
        }
      })
      .catch((e) => setError(e.message))
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    if (tokenStorageChecked === true && autoload === true) {
      loadData();
    }
  }, [tokenStorageChecked]);

  return {
    loading,
    error,
    item: item,
    items: response,
    metaData,
    tokenStorageChecked,
    loadItem,
    loadItems: loadData,
    createItem,
    updateItem,
    deleteItem,
  };
};

export default useApi;
