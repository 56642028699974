import { Component } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { Observable } from 'rxjs';
import { AuthService } from '../../auth/auth.service';
import * as moment from 'moment';
import { UserProfile } from '../../users/user-profile';
import { select } from '@angular-redux/store';

@Component({
  selector: 'app-team-chat',
  templateUrl: './team-chat.component.html',
  styleUrls: ['./team-chat.component.css'],
})
export class TeamChatComponent {
  public isPopover: boolean = false;
  public currentChat: Observable<any[]>;
  public messageToSend: string = '';
  @select() private userProfile$: Observable<UserProfile | undefined>;

  constructor(
    private db: AngularFirestore,
    public authService: AuthService,
  ) {
    this.currentChat = db
      .collection('/team-chat', ref => ref
        .where('domainId', '==', this.authService.domain.id)
        .orderBy('created', 'desc')
        .limit(25),
      )
      .valueChanges();
  }

  public async sendMessage(): Promise<void> {
    this.db.collection('/team-chat').add(
      {
        created: moment().toISOString(),
        text: this.messageToSend,
        user: await this.userProfile$,
        domainId: this.authService.domain.id,
      },
    );

    this.messageToSend = '';
  }

}
