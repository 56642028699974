<div class="row" *ngIf="allocationsInProgress">
  <div class="col-md-12">
    <div class="alert alert-danger text-bold text-center">
      Allocations are currently Read Only whilst automatic allocations are
      performed.
    </div>
  </div>
</div>

<div class="row">
  <div class="col-sm-12">
    <section class="panel">
      <app-page-title [loading]="isLoading" title="Allocate Client Funds">
        <input [(ngModel)]="hideInvoiceless" type="checkbox" /> Hide Cat 1's
        without invoices
      </app-page-title>

      <div *ngIf="clientDetails" class="panel-body">
        <div class="row" style="margin-bottom: 20px">
          <div class="col-md-6">
            <strong>Clients: </strong>
            <span *ngFor="let person of clientDetails?.client?.people"
              >{{ person.firstName }} {{ person.lastName }}
            </span>
          </div>
          <div class="col-md-3">
            <strong>Reference: </strong
            >{{ clientDetails?.client?.uniqueReference }}
          </div>
          <div class="col-md-3 text-right">
            <a
              [routerLink]="['/financials/distributions']"
              class="btn btn-default btn-xs"
              >Go Back</a
            >
          </div>
        </div>

        <div class="row">
          <div class="col-md-3">
            <div>
              <strong>Maximum Available to Allocate: </strong
              >{{ maximumAvailable / 100 || 0 | toAccountancy }}
            </div>

            <div>
              <strong>Retained: </strong
              >{{ retainedMonies / 100 | toAccountancy }}
            </div>

            <div>
              <strong>Pending Allocations: </strong
              >{{
                clientDetails?.financialValues?.pending_cash_out / 100 || 0
                  | toAccountancy
              }}
            </div>

            <select
              *ngIf="!allocationsInProgress"
              (ngModelChange)="setDistributionValueAndDate($event)"
              [(ngModel)]="pickedPayment"
              [compareWith]="byId"
              class="form-control"
              style="margin-top: 10px"
            >
              <option [value]="{ id: undefined }">
                Please choose a payment to match to
              </option>
              <option
                *ngFor="let single of possiblePayments"
                [ngValue]="single"
              >
                {{ single?.date | date: 'dd/MM/yyyy' }} -
                {{ single?.value / 100 | toAccountancy }}
              </option>
            </select>

            <!--<app-shared-currency [max]="maximumAvailable / 100" [(ngModel)]="clientDetails.financialValues.cash_remaining" (change)="allocateFundsAutomatically()"></app-shared-currency>-->
          </div>

          <div
            [class.text-danger]="totalDistributed > maximumAvailable / 100"
            [class.text-success]="totalDistributed <= maximumAvailable / 100"
            class="col-md-3"
          >
            <strong>Allocated: </strong>{{ totalDistributed | toAccountancy }}
          </div>
          <div class="col-md-3">
            <div>
              <strong>Remaining: </strong
              >{{ maximumAvailable / 100 - totalDistributed | toAccountancy }}
            </div>
          </div>
          <div *ngIf="displayLedger" class="col-md-3">
            <strong>Ledger Balance: </strong
            >{{
              clientDetails?.financialValues?.ledger_balance / 100
                | toAccountancy
            }}
          </div>
        </div>

        <hr />

        <div class="row">
          <div class="col-md-12 text-right">
            <button
              (click)="calculateCreditorDistributions()"
              *ngIf="
                !allocationsInProgress &&
                (clientDetails.financialValues.cash_remaining / 100 -
                  totalDistributed >=
                  clientDetails.creditors.length * 0.01 ||
                  clientDetails.financialValues.cash_remaining / 100 -
                    totalDistributed <
                    0)
              "
              class="btn btn-xs btn-danger"
            >
              Re-distribute Creditors
            </button>

            <button
              *ngIf="!allocationsInProgress"
              (click)="allocateFundsAutomatically()"
              class="btn btn-xs btn-warning"
              style="margin-left: 5px; margin-right: 5px"
            >
              Automatically Allocate
            </button>

            <button
              *ngIf="!allocationsInProgress"
              (click)="createTask.open(clientId)"
              class="btn btn-xs btn-success"
            >
              Create Case Task
            </button>
          </div>
        </div>

        <div *ngIf="clientDetails" class="row">
          <div class="col-md-6">
            <h4>Cat 1's</h4>

            <table class="table table-hover">
              <thead>
                <tr>
                  <th>Item</th>
                  <th>Distribution</th>
                </tr>
              </thead>

              <tbody>
                <tr
                  *ngFor="let catOne of clientDetails.catOnes"
                  [class.hidden]="
                    hideInvoiceless &&
                    catOne?.invoice_required &&
                    catOne?.invoice_count === 0
                  "
                >
                  <td>
                    <strong>{{ catOne?.item }}</strong
                    ><br /><br />
                    <small>
                      <strong>Expected: </strong
                      >{{ catOne?.total_over_life / 100 | toAccountancy }}
                      <br />
                      <strong>Required: </strong
                      >{{
                        (catOne.estimated
                          ? catOne?.invoice_value
                          : catOne?.required) / 100 | toAccountancy
                      }}
                      <br />
                      <strong>Paid: </strong
                      >{{ (catOne?.paid * -1) / 100 | toAccountancy }}<br />
                      <strong>Pending: </strong
                      >{{ catOne?.pending_paid / 100 | toAccountancy }}<br />
                      <strong>Remaining: </strong
                      >{{
                        ((catOne.estimated
                          ? catOne?.invoice_value
                          : catOne?.required) +
                          catOne?.paid -
                          catOne?.pending_paid) /
                          100 | toAccountancy
                      }}<br />
                    </small>
                  </td>
                  <td>
                    <div class="input-group">
                      <span class="input-group-addon">&pound;</span>
                      <input
                        (change)="ensureCreatedDate(catOne)"
                        [(ngModel)]="catOne.toDistribute"
                        [disabled]="
                          allocationsInProgress ||
                          catOne.pending_paid > 0 ||
                          !canChangeDistributions ||
                          (catOne?.invoice_required &&
                            catOne?.invoice_count === 0)
                        "
                        [max]="catOne.remaining / 100"
                        class="form-control"
                        id="cat_one_value_{{ catOne?.id }}"
                        name="Value"
                        step="0.01"
                        type="number"
                        value="0"
                      />
                    </div>
                    <div style="margin-top: 10px">
                      <input
                        [(ngModel)]="catOne.createdAt"
                        class="form-control"
                        type="date"
                        [disabled]="
                          allocationsInProgress ||
                          catOne.pending_paid > 0 ||
                          !canChangeDistributions ||
                          (catOne?.invoice_required &&
                            catOne?.invoice_count === 0)
                        "
                      />
                    </div>

                    <small *ngIf="catOne.pending_paid > 0" class="text-muted">
                      Cannot allocate when there are pending allocations.
                    </small>

                    <small
                      *ngIf="
                        catOne?.invoice_required && catOne?.invoice_count === 0
                      "
                      class="text-muted"
                    >
                      An invoice is required for this Cat 1 and hasn't yet been
                      supplied.
                    </small>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="col-md-6">
            <h4>Creditors</h4>

            <table class="table table-hover">
              <thead>
                <tr>
                  <th>Creditor</th>
                  <th>Distribution</th>
                </tr>
              </thead>

              <tbody>
                <tr *ngFor="let creditor of clientDetails.creditors">
                  <td>
                    <strong>{{ creditor?.creditor }}</strong
                    ><br />
                    <small>{{ creditor?.status }}</small
                    ><br /><br />
                    <small>
                      <strong>Required: </strong
                      >{{
                        (creditor.actual_value === 0
                          ? creditor.value
                          : creditor.actual_value) / 100 | toAccountancy
                      }}
                      <br />
                      <strong
                        >{{
                          creditor?.status === 'Admitted' ? 'Paid' : 'Retained'
                        }}: </strong
                      >{{ creditor?.paid / 100 | toAccountancy }}<br />
                      <strong>Pending: </strong
                      >{{ creditor?.pending_paid / 100 | toAccountancy }}<br />
                      <strong>Remaining: </strong
                      >{{
                        (creditor?.remaining - creditor?.pending_paid) / 100
                          | toAccountancy
                      }}<br />
                    </small>
                  </td>
                  <td>
                    <div class="input-group">
                      <span class="input-group-addon">&pound;</span>
                      <input
                        (change)="ensureCreatedDate(creditor)"
                        [(ngModel)]="creditor.toDistribute"
                        [disabled]="
                          allocationsInProgress ||
                          creditor.pending_paid > 0 ||
                          !canChangeDistributions
                        "
                        class="form-control"
                        id="creditor_value_{{ creditor?.id }}"
                        name="Value"
                        step="0.01"
                        type="number"
                        value="0"
                      />
                    </div>
                    <div *ngIf="creditor.pending_paid > 0">
                      <small class="text-muted">
                        Cannot distribute when there are pending distributions.
                      </small>
                    </div>
                    <div style="margin-top: 10px">
                      <input
                        [disabled]="
                          allocationsInProgress || creditor.pending_paid > 0
                        "
                        [(ngModel)]="creditor.createdAt"
                        class="form-control"
                        type="date"
                      />
                    </div>
                    <div style="margin-top: 10px">
                      <div class="checkbox">
                        <label>
                          <input
                            type="checkbox"
                            [(ngModel)]="creditor.willRetain"
                          />
                          Retain distribution
                        </label>
                      </div>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>

      <div *ngIf="!allocationsInProgress && clientDetails" class="panel-footer">
        <div class="text-right">
          <button
            (click)="confirmDistributions()"
            [disabled]="totalDistributed > maximumAvailable / 100"
            class="btn btn-default"
            style="margin-right: 10px"
          >
            Confirm
          </button>
          <button
            (click)="confirmDistributions(true)"
            [disabled]="totalDistributed > maximumAvailable / 100"
            class="btn btn-warning"
            style="margin-right: 10px"
          >
            Confirm and Refresh
          </button>
          <button
            (click)="confirmDistributionsAndNext()"
            [disabled]="totalDistributed > maximumAvailable / 100"
            class="btn btn-success"
          >
            Confirm and Next
          </button>
        </div>
      </div>
    </section>
  </div>
</div>

<app-case-calendar-create #createTask></app-case-calendar-create>
