import {Component, EventEmitter, Output, ViewChild} from '@angular/core';

import {CaseExpenditure} from '../case-expenditure';
import {CaseExpenditureService} from '../case-expenditure.service';

@Component({
  selector: 'app-case-expenditure-create',
  templateUrl: 'create.component.html',
})
export class CaseExpenditureCreateComponent {
  @ViewChild('staticModal') staticModal;

  @Output() completed = new EventEmitter<boolean>();

  public disabled: boolean = false;

  public model: CaseExpenditure = new CaseExpenditure();

  private givenCaseId: string;

  constructor(
    private readonly caseExpenditureService: CaseExpenditureService,
  ) {}

  public get caseId(): string {
    return this.givenCaseId;
  }

  public open(caseId: string): void {
    this.givenCaseId = caseId;
    this.staticModal.show();
  }

  public close() {
    this.workCompleted();
  }

  public confirmCreate($event) {
    $event.preventDefault();
    this.disabled = true;
    this.caseExpenditureService
      .createCaseExpenditure(this.model, { parameters: { id: this.caseId } })
      .subscribe(() => {
        this.workCompleted();
      });
  }

  private workCompleted() {
    this.model = new CaseExpenditure();
    this.disabled = false;
    this.staticModal.hide();
    this.completed.next(true);
  }
}
