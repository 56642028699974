import { Component, EventEmitter, OnInit } from '@angular/core';
import { UserService } from '../user.service';
import { User } from '../user';
import { NotificationService } from '../../shared/notification.service';
import { AuthService } from '../../auth/auth.service';

@Component({
  selector: 'app-user-list',
  templateUrl: 'list.component.html',
  styleUrls: [],
})
export class UserListComponent implements OnInit {
  public user: User;

  /**
   * A list of all the users currently loaded.
   *
   * @type {User[]}
   */
  public users: User[] = [];

  /**
   * Are we currently performing a loading action?
   *
   * @type {boolean}
   */
  public isLoading: boolean = true;

  /**
   * The total number of pages in this list of users.
   *
   * @type {number}
   */
  public totalPages: number = 1;

  /**
   * The current page of users that we are viewing.
   *
   * @type {number}
   */
  public currentPage: number = 1;

  /**
   * The total number of users that can be viewed in the system.
   *
   * @type {number}
   */
  public totalResults: number = 0;

  /**
   * An Emitter to alert other components when a user has been deleted.
   *
   * @type {EventEmitter<boolean>}
   */
  public userDeleted: EventEmitter<boolean> = new EventEmitter<boolean>();

  constructor(
    private userService: UserService,
    private notificationService: NotificationService,
    public readonly authService: AuthService,
  ) {
    this.userService.loading.subscribe(isLoading => {
      this.isLoading = isLoading;
    });
    this.userService.totalPages.subscribe(totalPages => {
      this.totalPages = totalPages;
    });
    this.userService.currentPage.subscribe(currentPage => {
      this.currentPage = currentPage;
    });
    this.userService.totalResults.subscribe(totalResults => {
      this.totalResults = totalResults;
    });
  }

  /**
   * When the component is initialised we set it to load
   * page one of the user list.
   */
  public ngOnInit(): void {
    this.loadUsers(1);
  }

  /**
   * When we receive a page change from the pagination
   * component we load the appropriate page for the
   * user list.
   *
   * @param {number} pageNumber
   */
  public onPageChanged(pageNumber: number): void {
    this.loadUsers(pageNumber);
  }

  /**
   * Loads a list of users using the given per page limit.
   *
   * @param {number} page The current page we require.
   * @param {number} limit The number of results per page.
   */
  public loadUsers(page?: number, limit?: number): void {
    const listUsers = this.userService
      .listUsers(page, limit)
      .subscribe((users: User[]) => {
        this.users = users;
        listUsers.unsubscribe();
      });
  }

  /**
   * When receiving confirmation of an item being deleted
   * we call the back end with the appropriate user ID
   * to delete the user.
   *
   * @param {string} id
   */
  public delete(id: string) {
    this.userService.deleteUser(id).subscribe(
      deleted => {
        this.notificationService.success(
          `User Deleted`,
          `User with ID ${id} was deleted.`,
          5,
        );
        this.userDeleted.next(true);
        this.onPageChanged(this.currentPage);
      },
      err => {
        this.notificationService.error(
          `User Not Deleted`,
          err.error.message,
          10,
        );
        this.userDeleted.next(false);
        this.onPageChanged(this.currentPage);
      },
    );
  }
}
