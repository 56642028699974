<div class="row">
    <div class="col-sm-12">
        <section class="panel">
            <app-page-title [loading]="isLoading" title="Create Department"></app-page-title>

            <div class="panel-body">
                <form (ngSubmit)="create()" class="form-horizontal" ngNativeValidate>
                    <app-department-form *ngIf="department" [department]="department"></app-department-form>
                </form>
            </div>
        </section>
    </div>
</div>


