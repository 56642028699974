import { Injectable } from '@angular/core';
import { Document } from './document';
import { Observable } from 'rxjs';

import { HttpRequestService } from '../shared/http/http-request.service';

@Injectable()
export class DocumentService extends HttpRequestService {
  /**
   * The endpoint that we call to get the document information.
   *
   * @type {string}
   */
  private readonly endpoint: string = 'documents';

  /**
   * Brings back a list of all accessible documents.
   *
   * @param {number} page The page number we are currently on.
   * @param {number} limit The number of documents to display per page.
   * @returns {Observable<Document[]>}
   */
  public listDocuments(page?: number, limit?: number): Observable<Document[]> {
    return this.list(this.endpoint, page, undefined, limit);
  }

  /**
   * Finds details of the document from the given ID.
   *
   * @param {string} documentId The ID of the document we require details for.
   * @returns {Observable<Document>}
   */
  public findDocument(documentId: string): Observable<Document> {
    return this.findById(this.endpoint, documentId);
  }

  /**
   * Creates a new document from the given Document object.
   *
   * @param {Document} newDocument The details of the document to be created.
   * @returns {Observable<Document | boolean>}
   */
  public createDocument(newDocument: Document): Observable<Document | boolean> {
    return this.create(this.endpoint, newDocument);
  }

  /**
   * Updates a document with the given document object for the given document ID.
   *
   * @param {string} documentId The ID of the document needing updating.
   * @param {Document} newDetails The new document object to update with.
   * @returns {Observable<Document | boolean>}
   */
  public updateDocument(
    documentId: string,
    newDetails: Document,
  ): Observable<Document | boolean> {
    return this.update(this.endpoint, documentId, newDetails);
  }

  /**
   * Deletes a document based on the given document ID.
   *
   * @param {string} documentId The ID of the document needing deleting.
   * @returns {Observable<boolean>}
   */
  public deleteDocument(documentId: string): Observable<boolean> {
    return this.delete(this.endpoint, documentId);
  }
}
