import { Component, Input, OnInit } from '@angular/core';

import { Address } from './address';
import { PostcodeService } from './postcode.service';

@Component({
  selector: 'app-shared-address',
  templateUrl: 'address.component.html',
})
export class SharedAddressComponent {
  constructor(private readonly postcodeService: PostcodeService) {}

  /**
   * The model that we will be adding the portfolio to.
   */
  @Input() model: any = undefined;

  @Input() requiredFields: string[] = [];

  @Input() allowLookup: boolean = true;

  @Input() labelWidth: Number = 4;

  public postcodeResults: Address[] = undefined;

  public chosenAddress: Address = undefined;

  public isRequired(item): boolean {
    return this.requiredFields.indexOf(item) >= 0;
  }

  public lbWidth(subtract = 0) {
    return subtract - Number(this.labelWidth);
  }

  public setAddress(address: Address): void {
    this.model.address1 = address.address1;
    this.model.address2 = address.address2;
    this.model.address3 = address.address3;
    this.model.town = address.town;
    this.model.county = address.county;
    this.model.postalCode = address.postalCode;
    this.model.country = address.country;
    this.model.addressType = address.addressType;
    this.model.from = address.from;
    this.model.till = address.till;
  }

  public searchForPostcode(): void {
    this.postcodeResults = undefined;
    const foundPostcodes = this.postcodeService
      .listAddresses(this.model.postalCode)
      .subscribe((addresses: Address[]) => {
        this.chosenAddress = undefined;
        this.postcodeResults = addresses;
        foundPostcodes.unsubscribe();
      });
  }

  /**
   * Formats the date from server in correct format for bsDatePicker
   * @param date date from the server in format YYYY-MM-DD
   * @returns
   * @todo Fix this hacky method
   * @body Date picker sometimes returns the wrong time so we set it manually to avoid dates changing
   */
  public formatDate(date?: string) {
    if (date) {
      const _date = new Date(date);
      // Date picker sometimes returns the wrong time so we set it here to avoid dates changing.
      _date.setHours(10);
      return _date;
    }
    return null;
  }
}
