import { Case } from './case';
import { HttpOptions } from '../shared/http/http-options';
import { HttpRequestService } from '../shared/http/http-request.service';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';

@Injectable()
export class CaseService extends HttpRequestService {
  /**
   * The endpoint that we call to get the case information.
   *
   * @type {string}
   */
  private readonly endpoint: string = 'cases';
  private readonly searchFunction = this.functions.httpsCallable('searchTerm');

  public referCase(to: string, caseId: string, item: any): Observable<any> {
    return this.create(`cases/:id/transfer/${to}`, item, {
      parameters: { id: caseId },
    } as HttpOptions);
  }

  public search(searchFor: string, page = 1, limit = 5): Observable<any> {
    return this.get(
      `${environment.microservices.search}term?term=${searchFor}`,
      1,
      { domainId: this.authService.domain.id },
      5,
    );
  }

  public listCases(
    page?: number,
    limit?: number,
    filters?: any,
    download: boolean = false,
  ): Observable<Case[]> {
    return this.list(this.endpoint, page, filters, limit);
  }

  /**
   * Finds details of the case from the given ID.
   *
   * @param {string} caseId The ID of the case we require details for.
   * @returns {Observable<Case>}
   */
  public findCase(caseId: string): Observable<Case> {
    return this.findById(this.endpoint, caseId);
  }

  /**
   * Creates a new case from the given Case object.
   *
   * @param {Case} newCase The details of the case to be created.
   * @returns {Observable<Case | boolean>}
   */
  public createCase(newCase: Case): Observable<Case | boolean> {
    return this.create(this.endpoint, newCase);
  }

  /**
   * Updates a case with the given case object for the given case ID.
   *
   * @param {string} caseId The ID of the case needing updating.
   * @param {Case} newDetails The new case object to update with.
   * @returns {Observable<Case | boolean>}
   */
  public updateCase(
    caseId: string,
    newDetails: Case,
  ): Observable<Case | boolean> {
    return this.update(this.endpoint, caseId, newDetails);
  }

  /**
   * Deletes a case based on the given case ID.
   *
   * @param {string} caseId The ID of the case needing deleting.
   * @returns {Observable<boolean>}
   */
  public deleteCase(caseId: string): Observable<boolean> {
    return this.delete(this.endpoint, caseId);
  }
}
