import { useEffect, useState } from 'react';
import { TokenState } from '../Contexts/TokenContext';
import useStoredValue from './UseStoredValue';

const useToken = (): TokenState => {
  const [token, setToken, tokenStorageChecked] = useStoredValue<
    string | undefined
  >('token', undefined);
  const [isValid, setIsValid] = useState<boolean>(true);

  useEffect(() => {
    if (tokenStorageChecked) {
      if (token !== undefined) {
        setIsValid(true);
      } else {
        setIsValid(false);
      }
    }
  }, [token]);

  return {
    token,
    isValid,
    setToken,
    tokenStorageChecked,
  };
};

export default useToken;
