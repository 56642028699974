<div class="container">

  <form class="form-signin ng-untouched ng-pristine ng-valid" novalidate="">
    <h2 class="form-signin-heading">
      <img alt="" src="/assets/images/logo.png">
    </h2>

    <div class="login-wrap">

      <div class="user-login-info">

        <div class="text-muted" style="font-size: 0.9em; margin-bottom: 20px;">
          If you have forgotten your password, please enter your email address below. If we find an account in our
          system we will e-mail you a link you can use to reset your password.
        </div>

        <input autofocus="" class="form-control ng-untouched ng-pristine ng-valid" placeholder="E-Mail Address" type="text" [(ngModel)]="username" [ngModelOptions]="{standalone: true}">
      </div>

      <button class="btn btn-lg btn-login btn-block" type="submit">Reset Password</button>

      <div class="forgotten-password">
        <a [routerLink]="['/auth/login']" style="cursor: pointer;">Back to Login</a>
      </div>

    </div>

  </form>

</div>
