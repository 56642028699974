import {Component, OnInit} from '@angular/core';
import {AngularFirestore} from '@angular/fire/firestore';

@Component({
  selector: 'app-bank-match-status',
  templateUrl: 'bank-match-status.component.html',
  styleUrls: ['./bank-match-status.component.css'],
})
export class BankMatchStatusComponent implements OnInit {
  public matchInProgress: boolean = false;
  public percentage: number = 0;

  constructor(
      private db: AngularFirestore,
  ) {

  }

  ngOnInit(): void {
    this.db.doc(`/logs/bank-matching`).valueChanges().subscribe((r: any) => {
      if (r && !r.endDate) {
        this.matchInProgress = true;
        this.percentage = Math.round((r.currentEntry / r.totalEntries) * 100);
      } else {
        this.matchInProgress = false;
      }
    });
  }

}
