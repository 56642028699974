import { BrowserModule } from '@angular/platform-browser';
import { CaseLogManualPaymentComponent } from './view/manual/manual.component';
import { CaseModificationRoutingModule } from '../modifications/modification-routing.module';
import { CasePaymentScheduleViewComponent } from './view/view.component';
import { EditPaymentScheduleItemComponent } from './view/edit/edit.component';
import { ExtendDurationComponent } from './view/extend-duration/extend-duration.component';
import { FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { ModalModule } from 'ngx-bootstrap/modal';
import { NgModule } from '@angular/core';
import { SharedModule } from '../../shared/shared.module';
import { TooltipModule } from 'ngx-bootstrap/tooltip';

@NgModule({
  declarations: [
    CasePaymentScheduleViewComponent,
    CaseLogManualPaymentComponent,
    EditPaymentScheduleItemComponent,
    ExtendDurationComponent,
  ],
  imports: [
    TooltipModule,
    BrowserModule,
    FormsModule,
    HttpClientModule,
    SharedModule,
    CaseModificationRoutingModule,
    ModalModule,
  ],
  exports: [CasePaymentScheduleViewComponent, CaseLogManualPaymentComponent],
})
export class CasePaymentScheduleRoutingModule {}
