<div class="row">
  <div class="col-sm-12">
    <section class="panel">
        <app-page-title [loading]="false" title="Edit Employment Status"></app-page-title>

      <div class="panel-body">
          <form (ngSubmit)="edit(employmentStatus$)" class="form-horizontal" ngNativeValidate>
              <app-employment-status-form
                      *ngIf="employmentStatus$ | async"
                      [employmentStatus]="employmentStatus$ | async"></app-employment-status-form>
        </form>
      </div>
    </section>
  </div>
</div>
