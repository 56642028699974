<a href="javascript:;" (click)="open()"
  ><i class="fa fa-pencil"></i>&nbsp;&nbsp;&nbsp;{{ creditorVote.creditor }}</a
>

<div
  class="modal fade"
  bsModal
  #staticModal="bs-modal"
  [config]="{ backdrop: 'static' }"
  tabindex="-1"
  role="dialog"
  aria-labelledby="mySmallModalLabel"
  aria-hidden="true"
>
  <div class="modal-dialog modal-md">
    <form (submit)="save($event)" #completedForm="ngForm" ngNativeValidate>
      <div class="modal-content panel-warning">
        <div class="modal-header panel-heading">
          <h4 class="modal-title pull-left">Edit Creditor</h4>
          <button
            type="button"
            class="close pull-right"
            aria-label="Close"
            (click)="close()"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <app-case-creditor-votes-form
            [creditorVote]="creditorVote"
          ></app-case-creditor-votes-form>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-default" (click)="close()">
            Cancel
          </button>
          <button type="submit" class="btn btn-success" [disabled]="disabled">
            Save
          </button>
        </div>
      </div>
    </form>
  </div>
</div>
