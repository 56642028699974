import { Component, EventEmitter, OnInit } from '@angular/core';
import { DeliveryMethodService } from '../delivery-method.service';
import { DeliveryMethod } from '../delivery-method';
import { NotificationService } from '../../shared/notification.service';

@Component({
  selector: 'app-delivery-method-list',
  templateUrl: 'list.component.html',
  styleUrls: [],
})
export class DeliveryMethodListComponent implements OnInit {
  public deliveryMethod: DeliveryMethod;

  /**
   * A list of all the delivery-methods currently loaded.
   *
   * @type {DeliveryMethod[]}
   */
  public deliveryMethods: DeliveryMethod[] = [];

  /**
   * Are we currently performing a loading action?
   *
   * @type {boolean}
   */
  public isLoading: boolean = true;

  /**
   * The total number of pages in this list of delivery-methods.
   *
   * @type {number}
   */
  public totalPages: number = 1;

  /**
   * The current page of delivery-methods that we are viewing.
   *
   * @type {number}
   */
  public currentPage: number = 1;

  /**
   * The total number of delivery-methods that can be viewed in the system.
   *
   * @type {number}
   */
  public totalResults: number = 0;

  /**
   * An Emitter to alert other components when a deliveryMethod has been deleted.
   *
   * @type {EventEmitter<boolean>}
   */
  public deliveryMethodDeleted: EventEmitter<boolean> = new EventEmitter<
    boolean
  >();

  /**
   * Load up all our required services and subscribe to every observable we need.
   *
   * @param {DeliveryMethodService} deliveryMethodService
   * @param {NotificationService} notificationService
   */
  constructor(
    private deliveryMethodService: DeliveryMethodService,
    private notificationService: NotificationService,
  ) {
    this.deliveryMethodService.loading.subscribe(isLoading => {
      this.isLoading = isLoading;
    });
    this.deliveryMethodService.totalPages.subscribe(totalPages => {
      this.totalPages = totalPages;
    });
    this.deliveryMethodService.currentPage.subscribe(currentPage => {
      this.currentPage = currentPage;
    });
    this.deliveryMethodService.totalResults.subscribe(totalResults => {
      this.totalResults = totalResults;
    });
  }

  /**
   * When the component is initialised we set it to load
   * page one of the deliveryMethod list.
   */
  public ngOnInit(): void {
    this.loadDeliveryMethods(1);
  }

  /**
   * When we receive a page change from the pagination
   * component we load the appropriate page for the
   * deliveryMethod list.
   *
   * @param {number} pageNumber
   */
  public onPageChanged(pageNumber: number): void {
    this.loadDeliveryMethods(pageNumber);
  }

  /**
   * Loads a list of delivery-methods using the given per page limit.
   *
   * @param {number} page The current page we require.
   * @param {number} limit The number of results per page.
   */
  public loadDeliveryMethods(page?: number, limit?: number): void {
    const listDeliveryMethods = this.deliveryMethodService
      .listDeliveryMethods(page, limit)
      .subscribe((deliveryMethods: DeliveryMethod[]) => {
        this.deliveryMethods = deliveryMethods;
        listDeliveryMethods.unsubscribe();
      });
  }

  /**
   * When receiving confirmation of an item being deleted
   * we call the back end with the appropriate deliveryMethod ID
   * to delete the deliveryMethod.
   *
   * @param {string} id
   */
  public delete(id: string) {
    this.deliveryMethodService.deleteDeliveryMethod(id).subscribe(
      deleted => {
        this.notificationService.success(
          `DeliveryMethod Deleted`,
          `DeliveryMethod with ID ${id} was deleted.`,
          5,
        );
        this.deliveryMethodDeleted.next(true);
        this.onPageChanged(this.currentPage);
      },
      err => {
        this.notificationService.error(
          `DeliveryMethod Not Deleted`,
          err.error.message,
          10,
        );
        this.deliveryMethodDeleted.next(false);
        this.onPageChanged(this.currentPage);
      },
    );
  }
}
