import { RouterModule, Routes } from '@angular/router';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule } from '@angular/common/http';
import { ModificationListComponent } from './list/list.component';
import { AuthGuard } from '../../auth/auth.guard';
import { ModificationService } from './modification.service';
import { SharedModule } from '../../shared/shared.module';
import { ModificationCreateComponent } from './create/create.component';
import { ModificationFormComponent } from './form/form.component';
import { ModificationEditComponent } from './edit/edit.component';

const routes: Routes = [
  {
    path: 'modifications',
    component: ModificationListComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'modifications/create',
    component: ModificationCreateComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'modifications/:id/edit',
    component: ModificationEditComponent,
    canActivate: [AuthGuard],
  },
];

@NgModule({
  declarations: [
    ModificationListComponent,
    ModificationCreateComponent,
    ModificationFormComponent,
    ModificationEditComponent,
  ],
  imports: [
    BrowserModule,
    RouterModule,
    FormsModule,
    HttpClientModule,
    SharedModule,
    RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' }),
  ],
  exports: [RouterModule],
  providers: [AuthGuard, ModificationService],
})
export class ModificationRoutingModule {}
