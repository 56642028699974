<div class="row">
  <div class="col-md-12">
    <div style="overflow-y: scroll">
      <table
        class="table table-striped table-hover"
        style="min-width: 2400px !important"
      >
        <thead>
          <tr>
            <th class="text-center">Creditor Name</th>
            <th class="text-center">Reference</th>
            <th class="text-center">Applicant</th>
            <th class="text-center">Date Proxy Recieved</th>
            <th class="text-center">Proxy holder</th>
            <th class="text-center">Discretion Given</th>
            <th class="text-center">Voting for Acceptance without Mods</th>
            <th class="text-center">Voting for Acceptance with Mods</th>
            <th class="text-center">Voting for Acceptance with Mods</th>
            <th class="text-center">Voting for Rejection</th>
            <th class="text-center">Invalid Proxies</th>
            <th class="text-center">Total</th>
          </tr>
          <tr>
            <th colspan="8"></th>
            <th colspan="2" class="text-center">Treated as Rejections</th>
            <th colspan="2"></th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let vote of creditorVotes">
            <td>
              <app-case-creditor-votes-edit
                (completed)="votesSaved()"
                [creditorVote]="vote"
              ></app-case-creditor-votes-edit>
            </td>
            <td>{{ vote.reference }}</td>
            <td>{{ vote.applicant }}</td>
            <td class="text-center">
              {{ vote.dateOfProxy | date: 'dd/MM/yyyy' }}
            </td>
            <td class="text-center">{{ vote.proxyHolder }}</td>
            <td class="text-center">
              {{
                vote.discretionGiven === true
                  ? 'Yes'
                  : vote.discretionGiven === false
                  ? 'No'
                  : ''
              }}
            </td>
            <td class="text-center">
              {{ this.getColumnValue(vote, 'voteAcceptedNoMods', '', true) }}
            </td>
            <td class="text-center">
              {{ this.getColumnValue(vote, 'voteAcceptedWithMods', '', true) }}
            </td>
            <td class="text-center">
              {{
                this.getColumnValue(
                  vote,
                  'voteAcceptedWithModsRejected',
                  '',
                  true
                )
              }}
            </td>
            <td class="text-center">
              {{ this.getColumnValue(vote, 'voteRejected', '', true) }}
            </td>
            <td class="text-center">
              {{ this.getColumnValue(vote, 'invalidProxy', '', true) }}
            </td>
            <td class="text-center">{{ sumSingleRow(vote) }}</td>
          </tr>
        </tbody>
        <tfoot>
          <tr>
            <th>Totals</th>
            <th colspan="5"></th>
            <th class="text-center">{{ sumCol('voteAcceptedNoMods') }}</th>
            <th class="text-center">{{ sumCol('voteAcceptedWithMods') }}</th>
            <th class="text-center">
              {{ sumCol('voteAcceptedWithModsRejected') }}
            </th>
            <th class="text-center">{{ sumCol('voteRejected') }}</th>
            <th class="text-center">{{ sumCol('invalidProxy') }}</th>
            <th class="text-center">{{ sumTotal() }}</th>
          </tr>
          <tr>
            <th>Percentage of Voting</th>
            <th colspan="5"></th>
            <th class="text-center">{{ percentCol('voteAcceptedNoMods') }}%</th>
            <th class="text-center">
              {{ percentCol('voteAcceptedWithMods') }}%
            </th>
            <th class="text-center">
              {{ percentCol('voteAcceptedWithModsRejected') }}%
            </th>
            <th class="text-center">{{ percentCol('voteRejected') }}%</th>
            <th class="text-center">{{ percentCol('invalidProxy') }}%</th>
            <th class="text-center">{{ percentTotalCol() }}%</th>
          </tr>
        </tfoot>
      </table>
    </div>
  </div>
</div>
<div class="row">
  <div class="col-md-12">
    <a [href]="downloadUrl" target="_blank" class="btn btn-success">Download</a>
  </div>
</div>
