import { Component, EventEmitter, OnInit } from '@angular/core';
import { IncomeTypeService } from '../income-type.service';
import { IncomeType } from '../income-type';
import { NotificationService } from '../../../shared/notification.service';

@Component({
  selector: 'app-income-type-list',
  templateUrl: 'list.component.html',
  styleUrls: [],
})
export class IncomeTypeListComponent implements OnInit {
  public incomeType: IncomeType;

  /**
   * A list of all the income-types currently loaded.
   *
   * @type {IncomeType[]}
   */
  public incomeTypes: IncomeType[] = [];

  /**
   * Are we currently performing a loading action?
   *
   * @type {boolean}
   */
  public isLoading: boolean = true;

  /**
   * The total number of pages in this list of income-types.
   *
   * @type {number}
   */
  public totalPages: number = 1;

  /**
   * The current page of income-types that we are viewing.
   *
   * @type {number}
   */
  public currentPage: number = 1;

  /**
   * The total number of income-types that can be viewed in the system.
   *
   * @type {number}
   */
  public totalResults: number = 0;

  /**
   * An Emitter to alert other components when a incomeType has been deleted.
   *
   * @type {EventEmitter<boolean>}
   */
  public incomeTypeDeleted: EventEmitter<boolean> = new EventEmitter<
    boolean
  >();

  /**
   * Load up all our required services and subscribe to every observable we need.
   *
   * @param {IncomeTypeService} incomeTypeService
   * @param {NotificationService} notificationService
   */
  constructor(
    private incomeTypeService: IncomeTypeService,
    private notificationService: NotificationService,
  ) {
    this.incomeTypeService.loading.subscribe(isLoading => {
      this.isLoading = isLoading;
    });
    this.incomeTypeService.totalPages.subscribe(totalPages => {
      this.totalPages = totalPages;
    });
    this.incomeTypeService.currentPage.subscribe(currentPage => {
      this.currentPage = currentPage;
    });
    this.incomeTypeService.totalResults.subscribe(totalResults => {
      this.totalResults = totalResults;
    });
  }

  /**
   * When the component is initialised we set it to load
   * page one of the incomeType list.
   */
  public ngOnInit(): void {
    this.loadIncomeTypes(1);
  }

  /**
   * When we receive a page change from the pagination
   * component we load the appropriate page for the
   * incomeType list.
   *
   * @param {number} pageNumber
   */
  public onPageChanged(pageNumber: number): void {
    this.loadIncomeTypes(pageNumber);
  }

  /**
   * Loads a list of income-types using the given per page limit.
   *
   * @param {number} page The current page we require.
   * @param {number} limit The number of results per page.
   */
  public loadIncomeTypes(page?: number, limit?: number): void {
    const listIncomeTypes = this.incomeTypeService
      .listIncomeTypes(page, limit)
      .subscribe((incomeTypes: IncomeType[]) => {
        this.incomeTypes = incomeTypes;
        listIncomeTypes.unsubscribe();
      });
  }

  /**
   * When receiving confirmation of an item being deleted
   * we call the back end with the appropriate incomeType ID
   * to delete the incomeType.
   *
   * @param {string} id
   */
  public delete(id: string) {
    this.incomeTypeService.deleteIncomeType(id).subscribe(
      deleted => {
        this.notificationService.success(
          `IncomeType Deleted`,
          `IncomeType with ID ${id} was deleted.`,
          5,
        );
        this.incomeTypeDeleted.next(true);
        this.onPageChanged(this.currentPage);
      },
      err => {
        this.notificationService.error(
          `IncomeType Not Deleted`,
          err.error.message,
          10,
        );
        this.incomeTypeDeleted.next(false);
        this.onPageChanged(this.currentPage);
      },
    );
  }
}
