import { Component, OnInit } from '@angular/core';
import { DeliveryMethodService } from '../delivery-method.service';
import { DeliveryMethod } from '../delivery-method';
import { Router } from '@angular/router';
import { NotificationService } from '../../shared/notification.service';

@Component({
  selector: 'app-delivery-method-create',
  templateUrl: 'create.component.html',
})
export class DeliveryMethodCreateComponent implements OnInit {
  /**
   * Details of the deliveryMethod being created.
   */
  public deliveryMethod: DeliveryMethod;

  /**
   * Are we currently performing a loading action?
   *
   * @type {boolean}
   */
  public isLoading: boolean = false;

  /**
   * Load up all our required services and subscribe to every observable we need.
   *
   * @param {DeliveryMethodService} deliveryMethodService
   * @param {NotificationService} notificationService
   * @param {Router} router
   */
  constructor(
    private deliveryMethodService: DeliveryMethodService,
    private notificationService: NotificationService,
    private router: Router,
  ) {
    this.deliveryMethodService.loading.subscribe(
      result => (this.isLoading = result),
    );
  }

  /**
   * Initialise the component, creating a new blank deliveryMethod if required.
   */
  public ngOnInit(): void {
    if (!this.deliveryMethod) {
      this.deliveryMethod = new DeliveryMethod();
    }
  }

  /**
   * Perform the create action which adds the new deliveryMethod into the backend.
   */
  public create() {
    const createDeliveryMethod = this.deliveryMethodService
      .createDeliveryMethod(this.deliveryMethod)
      .subscribe(
        (result: DeliveryMethod) => {
          this.notificationService.success(
            `DeliveryMethod Created`,
            `New deliveryMethod ${result.name} has been created with ID of ${
              result.id
            }.`,
            5,
          );
          this.deliveryMethod = new DeliveryMethod();
          createDeliveryMethod.unsubscribe();
          this.router.navigate(['/delivery-methods']);
        },
        err => {
          this.notificationService.error(
            `Could not create DeliveryMethod`,
            err.error.message,
            10,
          );
        },
      );
  }
}
