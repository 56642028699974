import { Component, Input } from '@angular/core';
import { Letterhead } from '../letterhead';

@Component({
  selector: 'app-letterhead-form',
  templateUrl: 'form.component.html',
})
export class LetterheadFormComponent {
  @Input() letterhead: Letterhead;
}
