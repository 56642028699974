import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-page-title',
  templateUrl: './page-title.component.html',
})
export class PageTitleComponent {
  @Input() title: string;

  @Input() loading: boolean = false;

  @Input() orientation: string = 'left';

  public get pullOrientation(): string {
    return `pull-${this.orientation}`;
  }

  public get textOrientation(): string {
    return `text-${this.orientation === 'left' ? 'right' : 'left'}`;
  }
}
