import { Component, Input, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'app-chief-updates',
  templateUrl: './chief-updates.component.html',
  styleUrls: ['./chief-updates.component.css'],
  encapsulation: ViewEncapsulation.None,
})
export class ChiefUpdatesComponent {
  @Input()
  public currentUpdates: any[];
}
