import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { HttpRequestService } from '../../shared/http/http-request.service';
import { FinancialLedger } from './ledger';

@Injectable()
export class FinancialLedgerService extends HttpRequestService {
  /**
   * The endpoint that we call to get the financialLedger information.
   *
   * @type {string}
   */
  private readonly endpoint: string = 'financial-ledger';

  /**
   * Brings back a list of all accessible financial-ledger.
   *
   * @param {number} page The page number we are currently on.
   * @param {number} limit The number of financial-ledger to display per page.
   * @returns {Observable<FinancialLedger[]>}
   */
  public listFinancialLedger(
    page?: number,
    limit?: number,
  ): Observable<FinancialLedger[]> {
    return this.list(this.endpoint, page, undefined, limit);
  }

  /**
   * Finds details of the financialLedger from the given ID.
   *
   * @param {string} financialLedgerId The ID of the financialLedger we require details for.
   * @returns {Observable<FinancialLedger>}
   */
  public findFinancialLedger(
    financialLedgerId: string,
  ): Observable<FinancialLedger> {
    return this.findById(this.endpoint, financialLedgerId);
  }

  /**
   * Creates a new financialLedger from the given FinancialLedger object.
   *
   * @param {FinancialLedger} newFinancialLedger The details of the financialLedger to be created.
   * @returns {Observable<FinancialLedger | boolean>}
   */
  public createFinancialLedger(
    newFinancialLedger: FinancialLedger,
  ): Observable<FinancialLedger | boolean> {
    return this.create(this.endpoint, newFinancialLedger);
  }

  /**
   * Updates a financialLedger with the given financialLedger object for the given financialLedger ID.
   *
   * @param {string} financialLedgerId The ID of the financialLedger needing updating.
   * @param {FinancialLedger} newDetails The new financialLedger object to update with.
   * @returns {Observable<FinancialLedger | boolean>}
   */
  public updateFinancialLedger(
    financialLedgerId: string,
    newDetails: FinancialLedger,
  ): Observable<FinancialLedger | boolean> {
    return this.update(this.endpoint, financialLedgerId, newDetails);
  }

  /**
   * Deletes a financialLedger based on the given financialLedger ID.
   *
   * @param {string} financialLedgerId The ID of the financialLedger needing deleting.
   * @returns {Observable<boolean>}
   */
  public deleteFinancialLedger(financialLedgerId: string): Observable<boolean> {
    return this.delete(this.endpoint, financialLedgerId);
  }
}
