import { INITIAL_USER_STATE, UserState } from './user/user.state';
import { EmploymentStatusState, INITIAL_EMPLOYMENT_STATUS_STATE } from './settings/employment-status/employment-status.state';

export interface ReduxStore {
  user: UserState;
  employmentStatuses: EmploymentStatusState;
}

export const INITIAL_STATE: ReduxStore = {
  user: INITIAL_USER_STATE,
  employmentStatuses: INITIAL_EMPLOYMENT_STATUS_STATE,
};
