import {HttpClientModule} from '@angular/common/http';
import {NgModule} from '@angular/core';
import {FormsModule} from '@angular/forms';
import {BrowserModule} from '@angular/platform-browser';
import {ModalModule} from 'ngx-bootstrap/modal';
import {TooltipModule} from 'ngx-bootstrap/tooltip';

import {SharedModule} from '../../shared/shared.module';
import {CaseInvoiceListComponent} from './list/list.component';

@NgModule({
  declarations: [CaseInvoiceListComponent],
  imports: [
    ModalModule.forRoot(),
    TooltipModule.forRoot(),
    BrowserModule,
    FormsModule,
    HttpClientModule,
    SharedModule,
  ],
  exports: [CaseInvoiceListComponent],
  providers: [],
})
export class CaseInvoiceRoutingModule {}
