<div class="row">
  <div class="col-sm-12">
    <section class="panel">
      <app-page-title
        title="List Creditors"
        [loading]="isLoading"
        class="creditors--list"
      >
        <span class="result--count"> {{ totalResults }} Results Found </span>
        <span class="search--input">
          <input
            name="filter"
            class="form-control"
            placeholder="Search..."
            [(ngModel)]="searchTerm"
            (input)="searchFilter()"
          />
          <i
            [className]="searchTerm ? 'fa fa-close search--icon reset-icon':'fa fa-search search--icon'"
            (click)="clearSearch()"
          ></i>
        </span>
      </app-page-title>

      <div class="panel-body">
        <table class="table table-striped">
          <thead>
            <tr>
              <th>Creditor Name</th>
              <th>Portfolio</th>
              <th class="text-right">
                <a [routerLink]="['/creditors/create']" class="btn btn-success"
                  >Create</a
                >
              </th>
            </tr>
          </thead>

          <tbody>
            <tr *ngFor="let creditor of creditors">
              <td>{{ creditor?.name }}</td>
              <td>{{ creditor?.portfolio?.name || 'None' }}</td>
              <td class="text-right">
                <div class="btn-group">
                  <a
                    [routerLink]="['/creditors', creditor?.id, 'edit']"
                    type="button"
                    class="btn btn-sm btn-warning"
                    >Edit</a
                  >
                  <button
                    type="button"
                    class="btn btn-sm btn-warning dropdown-toggle"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    <span class="caret"></span>
                    <span class="sr-only">Toggle Dropdown</span>
                  </button>
                  <ul class="dropdown-menu">
                    <li>
                      <a
                        href="javascript:;"
                        (click)="deleteModal.confirmDialog(creditor.id)"
                        >Delete</a
                      >
                    </li>
                  </ul>
                </div>
              </td>
            </tr>
          </tbody>

          <tfoot>
            <tr>
              <td colspan="99" class="text-center">
                <app-pagination
                  [totalPages]="totalPages"
                  [currentPage]="currentPage"
                  (pageChanged)="onPageChanged($event)"
                ></app-pagination>
              </td>
            </tr>
          </tfoot>
        </table>
      </div>
    </section>
  </div>
</div>

<app-delete-modal
  type="creditor"
  (confirm)="delete($event)"
  [itemDeleted]="creditorDeleted"
  #deleteModal
></app-delete-modal>
