import { RouterModule, Routes } from '@angular/router';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule } from '@angular/common/http';
import { AuthGuard } from '../../auth/auth.guard';
import { SharedModule } from '../../shared/shared.module';
import { FinancialLedgerService } from './ledger.service';
import { FinancialLedgerListComponent } from './list/list.component';

const routes: Routes = [
  {
    path: 'financial-ledger',
    component: FinancialLedgerListComponent,
    canActivate: [AuthGuard],
  },
];

@NgModule({
  declarations: [FinancialLedgerListComponent],
  imports: [
    BrowserModule,
    RouterModule,
    FormsModule,
    HttpClientModule,
    SharedModule,
    RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' }),
  ],
  exports: [RouterModule],
  providers: [AuthGuard, FinancialLedgerService],
})
export class FinancialLedgerRoutingModule {}
