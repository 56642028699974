import { Component, Input, OnInit } from '@angular/core';
import { GenderService } from '../gender.service';
import { Gender } from '../gender';
import { NotificationService } from '../../shared/notification.service';
import { SelectComparisons } from '../../shared/pipes/select.comparisons';

@Component({
  selector: 'app-gender-select',
  templateUrl: 'select.component.html',
})
export class GenderSelectComponent extends SelectComparisons implements OnInit {
  /**
   * The model that we will be adding the gender to.
   */
  @Input() model: any;

  /**
   * Do we require this field in the main form it is
   * included in?
   */
  @Input() required: boolean;

  /**
   * A list of all the genders currently loaded.
   *
   * @type {Gender[]}
   */
  public genders: Gender[] = [];

  /**
   * Are we currently performing a loading action?
   *
   * @type {boolean}
   */
  public isLoading: boolean = true;

  /**
   * Load up all our required services and subscribe to every observable we need.
   *
   * @param {GenderService} genderService
   * @param {NotificationService} notificationService
   */
  constructor(
    private genderService: GenderService,
    private notificationService: NotificationService,
  ) {
    super();
    this.genderService.loading.subscribe(isLoading => {
      this.isLoading = isLoading;
    });
  }

  /**
   * When the component is initialised we set it to load
   * page one of the gender list.
   */
  public ngOnInit(): void {
    this.loadGenders(1, 10000);
  }

  /**
   * Loads a list of genders using the given per page limit.
   *
   * @param {number} page The current page we require.
   * @param {number} limit The number of results per page.
   */
  public loadGenders(page?: number, limit?: number): void {
    const listGenders = this.genderService
      .listGenders(page, limit)
      .subscribe((genders: Gender[]) => {
        this.genders = genders;
        listGenders.unsubscribe();
      });
  }
}
