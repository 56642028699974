import { Component, OnInit } from '@angular/core';
import { StatusService } from '../status.service';
import { Status } from '../status';
import { Router } from '@angular/router';
import { NotificationService } from '../../shared/notification.service';

@Component({
  selector: 'app-status-create',
  templateUrl: 'create.component.html',
})
export class StatusCreateComponent implements OnInit {
  /**
   * Details of the status being created.
   */
  public status: Status;

  /**
   * Are we currently performing a loading action?
   *
   * @type {boolean}
   */
  public isLoading: boolean = false;

  /**
   * Load up all our required services and subscribe to every observable we need.
   *
   * @param {StatusService} statusService
   * @param {NotificationService} notificationService
   * @param {Router} router
   */
  constructor(
    private statusService: StatusService,
    private notificationService: NotificationService,
    private router: Router,
  ) {
    this.statusService.loading.subscribe(result => (this.isLoading = result));
  }

  /**
   * Initialise the component, creating a new blank status if required.
   */
  public ngOnInit(): void {
    if (!this.status) {
      this.status = new Status();
    }
  }

  /**
   * Perform the create action which adds the new status into the backend.
   */
  public create() {
    const createStatus = this.statusService.createStatus(this.status).subscribe(
      (result: Status) => {
        this.notificationService.success(
          `Status Created`,
          `New status ${result.name} has been created with ID of ${result.id}.`,
          5,
        );
        this.status = new Status();
        createStatus.unsubscribe();
        this.router.navigate(['/statuses']);
      },
      err => {
        this.notificationService.error(
          `Could not create Status`,
          err.error.message,
          10,
        );
      },
    );
  }
}
