import {Component, Input, OnInit} from '@angular/core';
import * as urlencode from 'urlencode';

import {User} from '../../users/user';
import {UserService} from '../../users/user.service';

@Component({
  selector: 'app-user-icon',
  templateUrl: './user-icon.component.html',
  styleUrls: ['./user-icon.component.scss'],
})
export class UserIconComponent implements OnInit {
  @Input() userId: string;

  @Input() size: number;

  @Input() passedUser: User;

  @Input() clickable: boolean = false;

  public user: User;

  public loading: boolean = true;

  public constructor(private readonly userService: UserService) {}

  public ngOnInit(): void {
    if (this.passedUser) {
      this.user = this.passedUser;
      this.loading = false;
    } else {
      this.userService.findUser(this.userId).subscribe((user: User) => {
        this.loading = false;
        this.user = user;
      });
    }
  }

  public getUserAvatar(): string {
    return (
      `https://ui-avatars.com/api/?name=${urlencode(
        this.user.name,
      )}&background=${this.intToRGB(
        this.hashCode(
          urlencode(
            this.user.domain && this.user.domain.code
              ? this.user.domain.code
              : 'UKN',
          ),
        ),
      )}&color=fff&size=200&rounded=false`
    );
  }

  private hashCode(str) {
    // java String#hashCode
    let hash = 0;
    for (let i = 0; i < str.length; i++) {
      hash = str.charCodeAt(i) + ((hash << 5) - hash);
    }
    return hash;
  }

  private intToRGB(i) {
    let c = (i & 0x00ffffff).toString(16).toUpperCase();

    return '00000'.substring(0, 6 - c.length) + c;
  }
}
