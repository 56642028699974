import { Component, Input } from '@angular/core';
import { Occupation } from '../occupation';

@Component({
  selector: 'app-occupation-form',
  templateUrl: 'form.component.html',
})
export class OccupationFormComponent {
  @Input() occupation: Occupation;
}
