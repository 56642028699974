import { Component, Input, OnInit } from '@angular/core';

import { ContactDetails } from './contact-details';

@Component({
  selector: 'app-shared-contact-details',
  templateUrl: 'contact-details.component.html',
})
export class SharedContactDetailsComponent {
  /**
   * The model that we will be adding the portfolio to.
   */
  @Input() model: any = undefined;

  @Input() requiredFields: string[] = [];

  @Input() labelWidth: Number = 4;

  @Input() creditorEmails: boolean = false;

  public lbWidth(subtract = 0) {
    return subtract - Number(this.labelWidth);
  }

  public isRequired(item): boolean {
    return this.requiredFields.indexOf(item) >= 0;
  }
}
