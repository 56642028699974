<div *ngIf="warningOnly && creditControlSummary.overdue > 0" class="row clearfix">
    <div class="col-md-12">
        <div class="alert alert-danger">
            <i class="fa fa-warning"></i>
            This case currently has arrears of {{ creditControlSummary.overdue / 100 | currency:"GBP" }}
        </div>
    </div>
</div>

<table *ngIf="!warningOnly" class="table">
    <tr>
        <th class="text-center" width="33%">Expected To Date</th>
        <th class="text-center" width="33%">Paid To Date</th>
        <th class="text-center" width="34%">Overdue</th>
    </tr>
    <tr>
        <td class="text-center">{{ creditControlSummary.expected / 100 | currency:"GBP" }}</td>
        <td class="text-center">{{ creditControlSummary.paid / 100 | currency:"GBP" }}</td>
        <td class="text-center">{{ creditControlSummary.overdue / 100 | currency:"GBP" }}</td>
    </tr>
</table>
