<input
  [(ngModel)]="selectedValue"
  [required]="required"
  [typeahead]="dataSource"
  (typeaheadLoading)="changeTypeaheadLoading($event)"
  (typeaheadNoResults)="changeTypeaheadNoResults($event)"
  (typeaheadOnSelect)="typeaheadOnSelect($event)"
  (blur)="typeaheadOnBlur($event)"
  [typeaheadMinLength]="2"
  typeaheadOptionField="name"
  [typeaheadItemTemplate]="customItemTemplate"
  placeholder="Please start typing your creditor name"
  class="form-control"
/>

<div
  class="alert alert-danger"
  role="alert"
  style="margin-top: 10px;"
  *ngIf="pleaseClickAlertVisible"
>
  Please make sure you click a creditor from the list
</div>

<ng-template #customItemTemplate let-model="item" let-index="index">
  <h5 class="typeahead-small">
    {{ model.name }}
    <small style="font-size: 0.7em;"
      >({{ addressStringFromAddress(model) }})</small
    >
  </h5>
</ng-template>
