<div class="row">
  <div class="col-sm-12">
    <section class="panel">
      <app-page-title [loading]="isLoading" title="List Cases">
        <button
          [outsideClick]="true"
          [popover]="filterTemplate"
          class="btn btn-xs btn-default btn-secondary"
          placement="bottom"
          popoverTitle="Filter Case List"
          type="button"
        >
          <span class="fa fa-filter"></span>
        </button>

        <ng-template #filterTemplate>
          <ul class="filter-list">
            <li class="row filter-row clearfix" style="min-width: 270px">
              <div class="form-group">
                <label class="col-sm-3 text-muted">Domain</label>
                <div class="col-sm-9">
                  <app-domain-select
                    (optionChanged)="loadCases(1)"
                    [model]="filters"
                  ></app-domain-select>
                </div>
              </div>
            </li>

            <li class="row filter-row clearfix" style="min-width: 270px">
              <div class="form-group">
                <label class="col-sm-3 text-muted">User</label>
                <div class="col-sm-9">
                  <app-user-select
                    (optionChanged)="loadCases(1)"
                    [model]="filters"
                  ></app-user-select>
                </div>
              </div>
            </li>

            <li class="row filter-row clearfix" style="min-width: 270px">
              <div class="form-group">
                <label class="col-sm-3 text-muted">Introducer</label>
                <div class="col-sm-9">
                  <app-introducer-select
                    (optionChanged)="loadCases(1)"
                    [model]="filters"
                  ></app-introducer-select>
                </div>
              </div>
            </li>

            <li class="row filter-row clearfix" style="min-width: 270px">
              <div class="form-group">
                <label class="col-sm-3 text-muted">Status</label>
                <div class="col-sm-9">
                  <app-status-select
                    (optionChanged)="loadCases(1)"
                    [model]="filters"
                  ></app-status-select>
                </div>
              </div>
            </li>

            <li class="row filter-row clearfix" style="min-width: 270px">
              <div class="form-group">
                <label class="col-sm-3 text-muted">Product</label>
                <div class="col-sm-9">
                  <app-product-select
                    (optionChanged)="loadCases(1)"
                    [model]="filters"
                  ></app-product-select>
                </div>
              </div>
            </li>
          </ul>

          <div class="clearfix text-right">
            <button (click)="resetFilters()" class="btn btn-warning btn-xs">
              Reset Filters
            </button>
          </div>
        </ng-template>

        {{ totalResults }} Results Found
      </app-page-title>

      <div class="panel-body">
        <table class="table table-striped">
          <thead>
            <tr>
              <th width="30"></th>
              <th width="30"></th>
              <th>Name</th>
              <th>Introducer</th>
              <th>Status</th>
              <th>Product</th>
              <th class="text-center">
                <span
                  class="fa fa-share-square"
                  container="body"
                  tooltip="Case has been transferred for PPI / PBA"
                ></span>
              </th>
              <th class="text-center">
                <span
                  class="fa fa-lock"
                  container="body"
                  tooltip="Case has been transferred for protection"
                ></span>
              </th>
              <th class="text-center">
                <span
                  class="fa fa-calendar"
                  container="body"
                  tooltip="Payment Schedule has been created for this case"
                ></span>
              </th>
              <th>Created</th>

              <th class="text-right">
                <a [routerLink]="['/cases/create']" class="btn btn-success"
                  >Create</a
                >
              </th>
            </tr>
          </thead>

          <tbody>
            <tr *ngFor="let case of cases">
              <td>
                <app-domain-icon
                  *ngIf="case?.domain?.id"
                  [domainId]="case?.domain?.id"
                  [passedDomain]="case?.domain"
                  [size]="24"
                ></app-domain-icon>
              </td>
              <td>
                <app-user-icon
                  *ngIf="case?.user?.id"
                  [passedUser]="case?.user"
                  [size]="24"
                  [userId]="case?.user?.id"
                ></app-user-icon>
              </td>
              <td>
                <div *ngFor="let singlePerson of case.people">
                  {{ singlePerson.firstName }} {{ singlePerson.lastName }}
                </div>
              </td>
              <td>{{ case?.introducer?.name }}</td>
              <td>
                {{ case?.status?.name }}
                <div class="text-muted" *ngIf="case?.substatus">
                  {{ case?.substatus?.name }}
                </div>
              </td>
              <td>{{ case?.product?.name }}</td>
              <td class="text-center">
                <span
                  [class.fa-check]="case.ppiId && case.ppiId.length > 0"
                  [class.fa-times]="!case.ppiId || case.ppiId.length <= 0"
                  [class.text-danger]="!case.ppiId || case.ppiId.length <= 0"
                  [class.text-success]="case.ppiId && case.ppiId.length > 0"
                  class="fa"
                ></span>
              </td>
              <td class="text-center">
                <span
                  [class.fa-check]="case.lifeId && case.lifeId.length > 0"
                  [class.fa-times]="!case.lifeId || case.lifeId.length <= 0"
                  [class.text-danger]="!case.lifeId || case.lifeId.length <= 0"
                  [class.text-success]="case.lifeId && case.lifeId.length > 0"
                  class="fa"
                ></span>
              </td>
              <td class="text-center">
                <span
                  [class.fa-check]="case.paymentScheduleCreated === true"
                  [class.fa-times]="case.paymentScheduleCreated === false"
                  [class.text-danger]="case.paymentScheduleCreated === false"
                  [class.text-success]="case.paymentScheduleCreated === true"
                  class="fa"
                ></span>
              </td>
              <td>
                <span
                  tooltip="{{ case.createdAt | date: 'HH:mm dd/MM/yyyy' }}"
                  >{{ case.createdAt | timeAgo }}</span
                >
              </td>
              <td class="text-right">
                <div class="btn-group">
                  <a
                    [routerLink]="['/cases', case?.id]"
                    class="btn btn-sm btn-warning"
                    type="button"
                    >View</a
                  >
                  <button
                    aria-expanded="false"
                    aria-haspopup="true"
                    class="btn btn-sm btn-warning dropdown-toggle"
                    data-toggle="dropdown"
                    type="button"
                  >
                    <span class="caret"></span>
                    <span class="sr-only">Toggle Dropdown</span>
                  </button>
                  <ul class="dropdown-menu">
                    <li>
                      <a [routerLink]="['/cases', case?.id, 'edit']">Edit</a>
                    </li>
                    <li *ngIf="authService.hasPermission('user.delete-cases')">
                      <a
                        (click)="deleteModal.confirmDialog(case.id)"
                        href="javascript:;"
                        >Delete</a
                      >
                    </li>
                  </ul>
                </div>
              </td>
            </tr>
          </tbody>

          <tfoot>
            <tr>
              <td class="text-center" colspan="99">
                <div class="row">
                  <div class="col-md-2" style="margin: 20px 0"></div>
                  <div class="col-md-8">
                    <app-pagination
                      (pageChanged)="onPageChanged($event)"
                      [currentPage]="currentPage"
                      [totalPages]="totalPages"
                    ></app-pagination>
                  </div>
                  <div class="col-md-2 text-right" style="margin: 20px 0">
                    <button
                      (click)="downloadCases()"
                      *ngIf="authService.hasPermission('user.download-cases')"
                      class="btn btn-sm"
                    >
                      <i class="fa fa-download"></i>
                    </button>
                  </div>
                </div>
              </td>
            </tr>
          </tfoot>
        </table>
      </div>
    </section>
  </div>
</div>

<app-delete-modal
  type="case"
  (confirm)="delete($event)"
  [itemDeleted]="caseDeleted"
  #deleteModal
></app-delete-modal>
