import {Component, EventEmitter, Output, ViewChild} from '@angular/core';

import {CaseBreach} from '../case-breach';
import {CaseBreachService} from '../case-breach.service';

@Component({
  selector: 'app-case-breach-create',
  templateUrl: 'create.component.html',
})
export class CaseBreachCreateComponent {
  @ViewChild('staticModal') staticModal;

  @Output() completed = new EventEmitter<boolean>();

  public disabled: boolean = false;

  public model: CaseBreach = new CaseBreach();

  private givenCaseId: string;

  constructor(private readonly caseBreachService: CaseBreachService) {}

  public get caseId(): string {
    return this.givenCaseId;
  }

  public open(caseId: string): void {
    this.givenCaseId = caseId;
    this.staticModal.show();
  }

  public close() {
    this.workCompleted();
  }

  public confirmCreate($event) {
    $event.preventDefault();
    this.disabled = true;
    this.caseBreachService
      .createCaseBreach(this.model, { parameters: { id: this.caseId } })
      .subscribe(() => {
        this.workCompleted();
      });
  }

  private workCompleted() {
    this.model = new CaseBreach();
    this.disabled = false;
    this.staticModal.hide();
    this.completed.next(true);
  }
}
