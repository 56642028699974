import { Injectable } from '@angular/core';
import { CaseBreach } from './case-breach';
import { Observable } from 'rxjs';

import { HttpRequestService } from '../../shared/http/http-request.service';
import { HttpOptions } from '../../shared/http/http-options';

@Injectable()
export class CaseBreachService extends HttpRequestService {
  /**
   * The endpoint that we call to get the case information.
   *
   * @type {string}
   */
  private readonly endpoint: string = 'cases/:id/breaches';

  /**
   * Brings back a list of all accessible cases.
   *
   * @param {number} page The page number we are currently on.
   * @param {number} limit The number of cases to display per page.
   * @returns {Observable<CaseBreach[]>}
   */
  public listCaseBreaches(
    page?: number,
    limit?: number,
    options?: HttpOptions,
  ): Observable<CaseBreach[]> {
    return this.list(this.endpoint, page, undefined, limit, options);
  }

  /**
   * Finds details of the case from the given ID.
   *
   * @param {string} caseId The ID of the case we require details for.
   * @returns {Observable<CaseBreach>}
   */
  public findCaseBreach(
    caseId: string,
    options?: HttpOptions,
  ): Observable<CaseBreach> {
    return this.findById(this.endpoint, caseId, options);
  }

  /**
   * Creates a new case from the given Case object.
   *
   * @param {CaseBreach} newCase The details of the case to be created.
   * @returns {Observable<CaseBreach | boolean>}
   */
  public createCaseBreach(
    newCase: CaseBreach,
    options?: HttpOptions,
  ): Observable<CaseBreach | boolean> {
    return this.create(this.endpoint, newCase, options);
  }

  /**
   * Updates a case with the given case object for the given case ID.
   *
   * @param {string} caseId The ID of the case needing updating.
   * @param {CaseBreach} newDetails The new case object to update with.
   * @returns {Observable<Case | boolean>}
   */
  public updateCaseBreach(
    caseId: string,
    newDetails: CaseBreach,
    options?: HttpOptions,
  ): Observable<CaseBreach | boolean> {
    return this.update(this.endpoint, caseId, newDetails, options);
  }

  /**
   * Deletes a case based on the given case ID.
   *
   * @param {string} caseId The ID of the case needing deleting.
   * @returns {Observable<boolean>}
   */
  public deleteCaseBreach(
    caseId: string,
    options?: HttpOptions,
  ): Observable<boolean> {
    return this.delete(this.endpoint, caseId, options);
  }
}
