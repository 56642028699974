import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import {HttpRequestService} from '../../../../shared/http/http-request.service';
import {BankingAccountImport} from './banking-account-import';

@Injectable()
export class BankingAccountImportService extends HttpRequestService {
  /**
   * The endpoint that we call to get the accountType information.
   *
   * @type {string}
   */
  private readonly endpoint: string = 'banking/accounts/:accountId/imports';

  public listBankingAccountImports(
    page?: number,
    limit?: number,
    options?: any,
    query?: any,
  ): Observable<BankingAccountImport[]> {
    return this.list(this.endpoint, page, query, limit, options);
  }

  /**
   * Finds details of the accountType from the given ID.
   *
   * @param {string} accountTypeId The ID of the accountType we require details for.
   * @returns {Observable<BankingAccountImport>}
   */
  public findBankingAccountImport(accountTypeId: string): Observable<BankingAccountImport> {
    return this.findById(this.endpoint, accountTypeId);
  }

  /**
   * Creates a new accountType from the given BankingAccountImport object.
   *
   * @param {BankingAccountImport} newBankingAccountImport The details of the accountType to be created.
   * @returns {Observable<BankingAccountImport | boolean>}
   */
  public createBankingAccountImport(
    newBankingAccountImport: BankingAccountImport,
  ): Observable<BankingAccountImport | boolean> {
    return this.create(this.endpoint, newBankingAccountImport);
  }

  /**
   * Updates a accountType with the given accountType object for the given accountType ID.
   *
   * @param {string} accountTypeId The ID of the accountType needing updating.
   * @param {BankingAccountImport} newDetails The new accountType object to update with.
   * @returns {Observable<BankingAccountImport | boolean>}
   */
  public updateBankingAccountImport(
    accountTypeId: string,
    newDetails: BankingAccountImport,
  ): Observable<BankingAccountImport | boolean> {
    return this.update(this.endpoint, accountTypeId, newDetails);
  }

  /**
   * Deletes a accountType based on the given accountType ID.
   *
   * @param {string} accountTypeId The ID of the accountType needing deleting.
   * @returns {Observable<boolean>}
   */
  public deleteBankingAccountImport(accountTypeId: string): Observable<boolean> {
    return this.delete(this.endpoint, accountTypeId);
  }
}
