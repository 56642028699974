import { useCallback, useEffect, useMemo } from 'react';
import useApi, { PaginationOptions } from './UseApi';
import User from '../../../../chief-types/src/User';

const useUsers = (autoload: boolean = false) =>
  useApi<User, PaginationOptions>(
    'users',
    undefined,
    autoload,
  );

const useLoggedInUser = (authService: {
  userId: string;
  hasPermission: (permission: string) => boolean;
}) => {
  const {
    item: user,
    loadItem: loadUser,
    loading: userLoading,
    error: userError,
    tokenStorageChecked,
  } = useUsers();

  useEffect(() => {
    if (tokenStorageChecked) {
      loadUser(authService.userId);
    }
  }, [authService, authService.userId, tokenStorageChecked]);

  const checkPermission = useCallback((permission: string) => {
      return authService.hasPermission(permission);
  }, [authService]);

  const hasAllDomainsPermission = useMemo(
    () => checkPermission('config.all-domains'),
    [checkPermission],
  );
  const userDomains = useMemo(() => {
    return user ? [user.domain, ...user.additionalDomains] : [];
  }, [user]);

  return { user, userError, userLoading, hasAllDomainsPermission, userDomains, checkPermission };
};

export default useLoggedInUser;
