import { RouterModule, Routes, CanActivate } from '@angular/router';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule } from '@angular/common/http';
import { DomainListComponent } from './list/list.component';
import { AuthGuard } from '../auth/auth.guard';
import { DomainService } from './domain.service';
import { SharedModule } from '../shared/shared.module';
import { DomainCreateComponent } from './create/create.component';
import { DomainFormComponent } from './form/form.component';
import { DomainEditComponent } from './edit/edit.component';
import { DomainCreditRoutingModule } from './credits/domain-credit-routing.module';

const routes: Routes = [
  { path: 'domains', component: DomainListComponent, canActivate: [AuthGuard] },
  {
    path: 'domains/create',
    component: DomainCreateComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'domains/:id/edit',
    component: DomainEditComponent,
    canActivate: [AuthGuard],
  },
];

@NgModule({
  declarations: [
    DomainListComponent,
    DomainCreateComponent,
    DomainFormComponent,
    DomainEditComponent,
  ],
  imports: [
    RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' }),
    BrowserModule,
    RouterModule,
    FormsModule,
    HttpClientModule,
    SharedModule,
    DomainCreditRoutingModule,
  ],
  exports: [RouterModule],
  providers: [AuthGuard, DomainService],
})
export class DomainRoutingModule {}
