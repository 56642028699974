import {Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';

@Component({
  selector: 'app-editable-number',
  template: `
    <span *ngIf="isCurrency" [class.hidden]="!isEditing" class="currencyType"
      >£
      <input
        type="number"
        step="0.01"
        [value]="visibleValue"
        (focusout)="visibleChange($event)"
        class="inPlaceEditor"
        [disabled]="isSaving"
        #editableFieldInputBox
      />
    </span>

    <span [class.hidden]="isEditing" (click)="setIsEditing(true)">{{
      visibleValue | toAccountancy
    }}</span>

    <span class="spinner" *ngIf="isSaving">
      <i class="fa fa-spinner fa-spin"></i>
    </span>
  `,
  styles: [
    `
      .spinner {
        padding-left: 5px;
      }

      .currencyType {
        padding-right: 5px;
        font-weight: bold;
      }

      .inPlaceEditor:not(:focus) {
        border: 0;
        text-decoration: underline;
        background: none;
        padding: 7px;
      }

      .inPlaceEditor:not(:focus)::-webkit-outer-spin-button,
      .inPlaceEditor:not(:focus)::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }

      .inPlaceEditor:not(:focus)[type='number'] {
        -moz-appearance: textfield;
      }

      .inPlaceEditor:focus {
        border: 1px solid #dddddd;
        text-decoration: none;
        background: #ffffff;
        padding: 5px;
      }
    `,
  ],
})
export class EditableNumberComponent implements OnInit {
  @Input()
  public value: number = 0;
  @Input()
  public isCurrency: boolean = false;
  @Input()
  public isSaving: boolean = false;

  public isEditing: boolean = false;
  @ViewChild('editableFieldInputBox') inputEl: ElementRef;

  @Output()
  public hasChanged: EventEmitter<number> = new EventEmitter<number>();

  public visibleValue: number = 0;

  ngOnInit(): void {
    this.visibleValue = this.isCurrency ? this.value / 100 : this.value;
  }

  public setIsEditing(value: boolean): void {
    this.isEditing = value;

    if (value === true) {
      setTimeout(() => this.inputEl.nativeElement.focus(), 10);
    }
  }

  visibleChange(event) {
    const eventValue: string = event.target.value;
    const eventNumberValue: number = +eventValue;
    this.setIsEditing(false);

    if (eventNumberValue === this.visibleValue) {
      return;
    }

    const updatedValue = this.isCurrency
      ? Math.round(eventNumberValue * 100)
      : eventNumberValue;

    this.visibleValue = eventNumberValue;

    this.hasChanged.emit(updatedValue);
  }
}
