export class ReduxApiActionTypes {
  static CREATE: string = 'CREATE';
  static GET_ALL: string = 'GET_ALL';
  static SELECT_ALL: string = 'SELECT_ALL';
  static GET_ONE: string = 'GET_SINGLE';
  static UPDATE: string = 'UPDATE';
  static DELETE: string = 'DELETE';
  static CLEAR_ALL: string = 'CLEAR_ALL';
  static CLEAR_ONE: string = 'CLEAR_SINGLE';
  static getReducerAction = (type: string, reducer: string): string => (`${type.toUpperCase()}_${reducer.toUpperCase()}`);
}
