import {
  Component,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';

import { AuthService } from '../../../../auth/auth.service';
import { CasePaymentScheduleItem } from '../../../../../../chief-types/src/CasePaymentSchedule';
import { HttpRequestResponse } from '../../../../shared/http/http-request-response';
import { NotificationService } from '../../../../shared/notification.service';
import { environment } from '../../../../../environments/environment';

@Component({
  selector: 'app-case-edit',
  templateUrl: 'edit.component.html',
})
export class EditPaymentScheduleItemComponent {
  @ViewChild('staticModal') staticModal: any;

  @Input() caseId?: string;

  @Output() completed = new EventEmitter<boolean>();

  public scheduleItem?: CasePaymentScheduleItem;

  /**
   * The ordered payment schedule used to validate expectedDate field.
   */
  public schedule?: CasePaymentScheduleItem[];

  public expectedValue?: number;

  public expectedDate?: string;

  public minDate?: string;

  public maxDate?: string;

  public setAllFuturePayments = false;

  public disabled = false;

  private readonly httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + this.authService.getToken(),
    }),
  };

  constructor(
    protected readonly http: HttpClient,
    protected readonly authService: AuthService,
    protected readonly notificationService: NotificationService,
  ) {}

  public open(
    scheduleItem: CasePaymentScheduleItem,
    schedule?: CasePaymentScheduleItem[],
  ) {
    this.schedule = schedule;
    this.scheduleItem = scheduleItem;
    this.expectedValue = scheduleItem.expectedValue;
    this.expectedDate = scheduleItem.expectedDate.substr(0, 10);

    // Calculate min and max dates.
    const indexOfItem = schedule?.findIndex(
      (item) => item.id === scheduleItem.id,
    );

    // Set the min possible date.
    const currentDate = new Date().toISOString().split('T')[0];

    // If schedule item is not first we take into account of the previous item.
    if (schedule && indexOfItem > 0) {
      const previousItemExpectedDate = schedule[indexOfItem - 1].expectedDate;
      const minDate = new Date(previousItemExpectedDate);
      minDate.setDate(minDate.getDate() + 1);
      const minDateString = minDate.toISOString().split('T')[0];
      this.minDate = minDateString > currentDate ? minDateString : currentDate;
      // Otherwise we just set minimum to todays date
    } else {
      this.minDate = currentDate;
    }
    // When editing payment schedule items in the past we should allow the min date to be the initial date.
    if (this.minDate > this.expectedDate) {
      this.minDate = this.expectedDate;
    }

    // Set the max possible date.
    if (schedule && indexOfItem < schedule.length - 1) {
      const maxDate = new Date(schedule[indexOfItem + 1].expectedDate);
      maxDate.setDate(maxDate.getDate() - 1);
      this.maxDate = maxDate.toISOString().split('T')[0];
    }

    this.staticModal.show();
  }

  public close() {
    this.workCompleted();
  }

  public toggleSetAllFuturePayments() {
    this.setAllFuturePayments = !this.setAllFuturePayments;
  }

  public submit() {
    this.disabled = true;
    this.editExpectedValue();
  }

  private workCompleted() {
    this.disabled = false;
    this.scheduleItem = undefined;
    this.expectedValue = undefined;
    this.setAllFuturePayments = false;
    this.staticModal.hide();
  }

  private editExpectedValue() {
    if (this.scheduleItem?.id) {
      this.http
        .put<HttpRequestResponse>(
          `${environment.endpoint}cases/${this.caseId}/payment-schedule/${this.scheduleItem.id}/edit?setAllFuturePayments=${this.setAllFuturePayments}`,
          {
            expectedValue: this.expectedValue,
            expectedDate: this.expectedDate,
          },
          this.httpOptions,
        )
        .map((data: HttpRequestResponse) => data.data)
        .subscribe(
          (item) => {
            this.notificationService.success(
              'Updated Payment Schedule',
              'Successfully updated the payment schedule with the changes specified.',
              5,
            );
            this.completed.emit(true);
            this.workCompleted();
          },
          (err) => {
            this.notificationService.error(
              'Error Updating payment schedule.',
              err.message,
              5,
            );
            this.completed.emit(false);
            this.disabled = false;
          },
        );
    } else {
      this.notificationService.error('Error Updating payment schedule.', '', 5);
      this.completed.emit(false);
    }
  }
}
