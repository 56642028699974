<div class="row">
  <div class="col-sm-12">
    <section class="panel">
      <app-page-title title="Bank Matching"></app-page-title>

      <div class="panel-body">
        <table class="table table-striped">
          <thead>
          <tr>
            <th>ID</th>
            <th>Reference</th>
            <th>Status</th>
            <th>Message</th>
            <th>Case</th>
            <th>Payment</th>
          </tr>
          </thead>

          <tbody>
          <tr *ngFor="let match of matches | async">
            <td>{{ match?.payment?.id }}</td>
            <td>{{ match?.payment?.reference }}</td>
            <td>{{ match?.status }}</td>
            <td>{{ match?.message }}</td>
            <td><a href="/cases/{{ match?.clientId }}" target="_blank">{{ match?.clientId }}</a></td>
            <td>{{ match?.paymentProfile?.id }}</td>
          </tr>
          </tbody>
        </table>
      </div>
    </section>
  </div>
</div>
