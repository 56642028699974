import {RouterModule, Routes} from '@angular/router';
import {NgModule} from '@angular/core';
import {FormsModule} from '@angular/forms';
import {BrowserModule} from '@angular/platform-browser';
import {HttpClientModule} from '@angular/common/http';
import {AuthGuard} from '../../../auth/auth.guard';
import {SharedModule} from '../../../shared/shared.module';
import {BankingMatchingListComponent} from './list/list.component';

const routes: Routes = [
  {
    path: 'banking/matching',
    component: BankingMatchingListComponent,
    canActivate: [AuthGuard],
  }
];

@NgModule({
  declarations: [
    BankingMatchingListComponent,
  ],
  imports: [
    BrowserModule,
    RouterModule,
    FormsModule,
    HttpClientModule,
    SharedModule,
    RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' }),
  ],
  exports: [RouterModule],
  providers: [AuthGuard],
})
export class BankingMatchingRoutingModule {
}
