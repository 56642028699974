import { Component, OnInit } from '@angular/core';
import { CreditService } from '../credit.service';
import { Credit } from '../credit';
import { Router } from '@angular/router';
import { NotificationService } from '../../shared/notification.service';

@Component({
  selector: 'app-credit-create',
  templateUrl: 'create.component.html',
})
export class CreditCreateComponent implements OnInit {
  /**
   * Details of the credit being created.
   */
  public credit: Credit;

  /**
   * Are we currently performing a loading action?
   *
   * @type {boolean}
   */
  public isLoading: boolean = false;

  /**
   * Load up all our required services and subscribe to every observable we need.
   *
   * @param {CreditService} creditService
   * @param {NotificationService} notificationService
   * @param {Router} router
   */
  constructor(
    private creditService: CreditService,
    private notificationService: NotificationService,
    private router: Router,
  ) {
    this.creditService.loading.subscribe(result => (this.isLoading = result));
  }

  /**
   * Initialise the component, creating a new blank credit if required.
   */
  public ngOnInit(): void {
    if (!this.credit) {
      this.credit = new Credit();
    }
  }

  /**
   * Perform the create action which adds the new credit into the backend.
   */
  public create() {
    const createCredit = this.creditService.createCredit(this.credit).subscribe(
      (result: Credit) => {
        this.notificationService.success(
          `Credit Created`,
          `New credit ${result.name} has been created with ID of ${result.id}.`,
          5,
        );
        this.credit = new Credit();
        createCredit.unsubscribe();
        this.router.navigate(['/credits']);
      },
      err => {
        this.notificationService.error(
          `Could not create Credit`,
          err.error.message,
          10,
        );
      },
    );
  }
}
