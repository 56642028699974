<app-case-modification-alert
  [caseId]="caseId"
  [caseRefreshed]="caseRefreshed"
></app-case-modification-alert>

<app-audit-payment-profile [clientId]="caseId"></app-audit-payment-profile>

<div class="row">
  <div class="col-md-12">
    <app-case-credit-summary
      [clientId]="caseId"
      [warningOnly]="false"
      [paymentScheduleUpdated]="paymentScheduleUpdated"
    ></app-case-credit-summary>
  </div>
</div>

<div class="row" style="margin-bottom: 16px">
  <div class="col-md-4">
    <strong>Payment Method: </strong>
    {{ paymentMethod }}
  </div>
  <div class="col-md-8 text-right">
    <button
      (click)="openModal(logManualPayment)"
      class="btn btn-xs btn-success"
    >
      Log Manual Payment
    </button>
    <button
      (click)="extendDuration.open()"
      class="btn btn-xs btn-info"
      style="margin-left: 10px"
    >
      Extend Duration
    </button>
    <button
      (click)="deleteModal.confirmDialog(paymentSchedule?.id)"
      *ngIf="
        authService.hasPermission('user.delete-payment-schedules') &&
        receiptsAndPaymentsTotal === 0 &&
        paymentSchedule?.id
      "
      class="btn btn-xs btn-danger"
      style="margin-left: 10px"
    >
      Delete Payment Schedule
    </button>
  </div>
</div>

<table class="table">
  <tr>
    <th>Expected Date</th>
    <th>Value</th>
    <th>Status</th>
    <th>Received</th>
    <th class="text-center">Payment Log</th>
    <th></th>
  </tr>

  <tr
    *ngFor="let item of paymentSchedule?.schedule"
    [ngClass]="{
      'bg-success': item.status === 'paid',
      'bg-warning': item.status === 'partial',
      'bg-info': item.status === 'holiday'
    }"
  >
    <th>{{ item.expectedDate | date: 'dd/MM/yyyy' }}</th>
    <td>{{ item.expectedValue / 100 | toCurrency }}</td>
    <td>{{ item.status }}</td>
    <td>
      {{ (item.totalReceived ? item.totalReceived : 0) / 100 | toCurrency }}
    </td>
    <td class="payment-log-cell">
      <div *ngFor="let payment of item.paymentLog" class="payment-row">
        <div class="col-sm-3">
          {{ payment.textDate?.split('T')[0] | date: 'dd/MM/yyyy' }}
        </div>
        <div class="col-sm-3">{{ payment.value / 100 | toCurrency }}</div>
        <div class="col-sm-4">{{ payment.prettyTypes[payment.type] }}</div>
        <div class="text-right col-sm-2">
          <button
            (click)="deleteSinglePayment(paymentSchedule?.id, item.id, payment)"
            *ngIf="
              authService.hasPermission('user.delete-payment-schedules') &&
              paymentSchedule?.id
            "
            class="btn btn-xs btn-danger"
          >
            <i class="fa fa-trash"></i>
          </button>
        </div>
      </div>
    </td>
    <td class="text-right">
      <button
        (click)="addPaymentHoliday(item.id)"
        *ngIf="
          !item.relatedScheduleItem &&
          item.status === 'unpaid' &&
          authService.hasPermission('user.authorise-payment-holiday')
        "
        class="btn btn-xs btn-warning"
      >
        <i class="fa fa-gift"></i>
      </button>

      <button
        (click)="deletePaymentHoliday(item.id)"
        *ngIf="
          item.relatedScheduleItem &&
          item.status === 'unpaid' &&
          authService.hasPermission('user.authorise-payment-holiday')
        "
        class="btn btn-xs btn-danger"
      >
        <i class="fa fa-undo"></i>
      </button>

      <button
        *ngIf="item.note"
        [tooltip]="item.note"
        class="btn btn-info btn-xs"
        container="body"
        placement="left"
        style="margin-left: 5px !important"
        type="button"
      >
        <i class="fa fa-info-circle"></i>
      </button>
      <button
        *ngIf="canEditExpectedValue(item)"
        class="btn btn-warning btn-xs"
        container="body"
        placement="left"
        style="margin-left: 5px !important"
        type="button"
        (click)="edit.open(item, paymentSchedule?.schedule)"
      >
        <i class="fa fa-pencil"></i>
      </button>
    </td>
  </tr>
</table>

<ng-template #logManualPayment>
  <app-case-log-manual-payment
    [caseId]="caseId"
    [modalRef]="modalRef"
    [paymentSchedule]="paymentSchedule?.schedule"
    (completed)="reload()"
  ></app-case-log-manual-payment>
</ng-template>

<app-case-edit #edit [caseId]="caseId" (completed)="reload()"></app-case-edit>

<app-case-extend-duration
  #extendDuration
  [caseId]="caseId"
  (completed)="reload()"
></app-case-extend-duration>

<app-delete-modal
  #deleteModal
  (confirm)="delete($event)"
  [itemDeleted]="scheduleDeleted"
  type="payment schedule"
></app-delete-modal>
