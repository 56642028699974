import { Component } from '@angular/core';
import { PackService } from '../pack.service';
import { Pack } from '../pack';
import { ActivatedRoute, Router } from '@angular/router';
import { NotificationService } from '../../shared/notification.service';

@Component({
  selector: 'app-pack-edit',
  templateUrl: 'edit.component.html',
})
export class PackEditComponent {
  /**
   * Details of the pack being edited.
   */
  public pack: Pack;

  /**
   * Are we currently performing a loading action?
   *
   * @type {boolean}
   */
  public isLoading: boolean = false;

  /**
   * Load up all our required services and subscribe to every observable we need.
   *
   * @param {PackService} packService
   * @param {NotificationService} notificationService
   * @param {Router} router
   * @param {ActivatedRoute} route
   */
  constructor(
    private packService: PackService,
    private notificationService: NotificationService,
    private router: Router,
    private route: ActivatedRoute,
  ) {
    this.packService.loading.subscribe(result => (this.isLoading = result));
    this.route.params.subscribe(params => this.loadPack(params.id));
  }

  /**
   * Perform the edit action which send the edited pack to the backend.
   */
  public edit() {
    const editPack = this.packService
      .updatePack(this.pack.id, this.pack)
      .subscribe(
        (result: Pack) => {
          this.notificationService.success(
            `Pack Updated`,
            `The pack ${result.name} been updated with the specified changes.`,
            5,
          );
          this.pack = new Pack();
          editPack.unsubscribe();
          this.router.navigate(['/packs']);
        },
        err => {
          this.notificationService.error(
            `Pack Not Found`,
            err.error.message,
            10,
          );
          this.router.navigate(['/packs']);
        },
      );
  }

  /**
   * Loads the requested pack so we can display it and let the user edit the fields.
   *
   * @param {string} id
   */
  private loadPack(id: string) {
    this.packService.findPack(id).subscribe(
      result => {
        this.pack = result;
      },
      err => {
        this.notificationService.error(`Pack Not Found`, err.error.message, 10);
        this.router.navigate(['/packs']);
      },
    );
  }
}
