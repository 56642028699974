import { Component, Input } from '@angular/core';
import { Folder } from '../folder';

@Component({
  selector: 'app-folder-form',
  templateUrl: 'form.component.html',
})
export class FolderFormComponent {
  @Input() folder: Folder;
}
