import { Component, ElementRef } from '@angular/core';

import TitlesList, {
  TitlesListProps,
} from '../../../chief-react/src/App/Pages/Titles/TitleList';

import {
  LinkProps,
  ReactWrapper,
  ElementRefWrapper,
  RouterWrapper,
} from 'react-inside-angular';
import { Router } from '@angular/router';
import { ComponentClass, FunctionComponent } from 'react';

@Component({
  selector: 'app-titles-list',
  template: '<div [id]="rootId"></div>',
})
export class TitleListComponent extends ReactWrapper<TitlesListProps> {
  protected router: RouterWrapper;
  protected elemRef: ElementRefWrapper;

  constructor(router: Router, elemRef: ElementRef) {
    super();
    this.router = (router as unknown) as RouterWrapper;
    this.elemRef = (elemRef as unknown) as ElementRefWrapper;
  }

  // Transfer any props from Angular to the React component
  public setProps(): TitlesListProps {
    return {};
  }

  // Define the React component being wrapped
  public setComponent():
    | string
    | FunctionComponent<LinkProps<TitlesListProps>>
    | ComponentClass<LinkProps<TitlesListProps>, any> {
    return TitlesList;
  }
}
