import { NgModule } from '@angular/core';
import { CaseDocumentGeneratorService } from './case-document-generator.service';
import { FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { BrowserModule } from '@angular/platform-browser';
import { SharedModule } from '../../../shared/shared.module';

@NgModule({
  imports: [BrowserModule, FormsModule, HttpClientModule, SharedModule],
  providers: [CaseDocumentGeneratorService],
})
export class CaseDocumentGeneratorRoutingModule {}
