import { Component, Input } from '@angular/core';

import { ComplaintStatus } from '../complaint-status';

@Component({
  selector: 'app-complaint-status-form',
  templateUrl: 'form.component.html',
})
export class ComplaintStatusFormComponent {
  @Input() complaintStatus: ComplaintStatus;
}
