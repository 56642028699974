import { Component, OnDestroy, OnInit } from '@angular/core';

import { AuthService } from '../../auth/auth.service';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-report-viewer',
  templateUrl: 'report-viewer.component.html',
})
export class ReportViewerComponent implements OnInit, OnDestroy {
  public loading = false;
  public selectedReport: any = undefined;
  public startDate: string = new Date().toISOString().substr(0, 10);
  public endDate: string = new Date().toISOString().substr(0, 10);
  public loadedReport: any[] | undefined;
  public currentPage = 1;
  public totalPages = 1;
  protected reportOptions: any[] = [
    {
      name: 'Customer Payments',
      url: environment.microservices.reports + 'reports/monthly-payments',
    },
    {
      name: 'Receipts and Payments',
      url: environment.microservices.reports + 'reports/receipts-and-payments',
    },
    {
      name: 'Completion Status',
      url: environment.microservices.reports + 'reports/completion-status',
    },
    {
      name: 'Missing CAT 1 Invoices',
      url: environment.microservices.reports + 'reports/missing-cat1-invoices',
    },
    {
      name: 'Payment Deficit',
      url: environment.microservices.reports + 'reports/payment-deficit',
    },
    {
      name: 'Monthly Payments',
      url: environment.microservices.reports + 'reports/monthly-payments',
    },
    {
      name: 'CMS - Accounts',
      url: environment.microservices.reports + 'reports/cms-accounts',
    },
    {
      name: 'CMS - Withdrawals and Deposits',
      url:
        environment.microservices.reports +
        'reports/cms-withdrawals-and-deposits',
    },
    {
      name: 'CMS - Summary',
      url: environment.microservices.reports + 'reports/cms-summary',
    },
    {
      name: 'Detailed Transaction Report',
      url:
        environment.microservices.reports +
        'reports/global-customer-transactions',
    },
    {
      name: 'Modifications',
      url: environment.microservices.reports + 'reports/modifications',
    },
    {
      name: 'All Tasks',
      url: environment.microservices.reports + 'reports/task-report',
    },
    {
      name: 'Overdue Tasks',
      url: environment.microservices.reports + 'reports/task-report',
    },
    {
      name: 'Complaint Register',
      url: environment.microservices.reports + 'reports/complaint-register',
    },
    {
      name: 'Pending Distributions',
      url: environment.microservices.reports + 'reports/pending-distributions',
    },
    {
      name: 'Retained Allocations',
      url: environment.microservices.reports + 'reports/retained-allocations',
    },
    {
      name: 'Bonding Report',
      url: environment.microservices.reports + 'reports/bonding',
    },
  ];

  constructor(
    private readonly http: HttpClient,
    private readonly authService: AuthService,
  ) {}

  public get allPossibleReports() {
    return this.reportOptions.sort((a, b) => (a.name > b.name ? 1 : -1));
  }

  public get reportHeaders(): string[] {
    return !this.loadedReport || this.loadedReport.length < 1
      ? []
      : Object.keys(this.loadedReport[0]);
  }

  ngOnInit(): void {}

  public reportUrl(download: boolean = false): string {
    return !this.selectedReport || !this.selectedReport.url
      ? ``
      : `${this.selectedReport.url}?domainId=${
          this.authService.domain.id
        }&auth=${this.authService.getToken()}&date=${this.startDate}&endDate=${
          this.endDate
        }&download=${download}`;
  }

  public refreshReport(pageNumber: number = 1) {
    this.loading = true;
    this.http
      .get(`${this.reportUrl()}&page=${pageNumber}`)
      .subscribe((result: any) => {
        this.loadedReport = result.data;
        this.currentPage = result.meta.currentPage;
        this.totalPages = result.meta.totalPages;
      });
  }

  ngOnDestroy(): void {}
}
