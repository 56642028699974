<div class="form-group clearfix">
  <label class="col-sm-4 control-label" for="model">Creditor</label>
  <div class="col-sm-8">
    <app-creditor-select
      [model]="model"
      [required]="true"
    ></app-creditor-select>
  </div>
</div>

<div class="form-group clearfix">
  <label class="col-sm-4 control-label" for="reference">Reference</label>
  <div class="col-sm-8">
    <input
      [(ngModel)]="model.reference"
      [required]="true"
      class="form-control"
      id="reference"
      name="reference"
      type="text"
    />
  </div>
</div>

<div class="form-group clearfix">
  <label class="col-sm-4 control-label" for="credit">Credit Type</label>
  <div class="col-sm-8">
    <app-credit-select [model]="model" [required]="true"></app-credit-select>
  </div>
</div>

<div class="form-group clearfix">
  <label class="col-sm-4 control-label" for="reference">Applicant</label>
  <div class="col-sm-8">
    <app-shared-person-select
      *ngIf="caseId"
      [caseId]="caseId"
      [model]="model"
      [required]="true"
    ></app-shared-person-select>
  </div>
</div>

<div class="form-group clearfix">
  <label class="col-sm-4 control-label" for="debt-status">Debt Status</label>
  <div class="col-sm-8">
    <app-creditor-status-select
      [model]="model"
      [required]="false"
    ></app-creditor-status-select>
  </div>
</div>

<div class="form-group clearfix">
  <label class="col-sm-4 control-label" for="reference">SOA Value</label>
  <div class="col-sm-8">
    <app-shared-currency
      [(ngModel)]="model.value"
      [required]="false"
    ></app-shared-currency>
  </div>
</div>

<div class="form-group clearfix">
  <label class="col-sm-4 control-label" for="reference"
    >Proof of Debt Value</label
  >
  <div class="col-sm-7">
    <app-shared-currency
      [(ngModel)]="model.actualValue"
      [required]="false"
    ></app-shared-currency>
  </div>
  <div class="col-sm-1">
    <input
      [(ngModel)]="model.debtPaid"
      class="form-control"
      tooltip="Has the Debt Been completely paid off?"
      type="checkbox"
    />
  </div>
</div>

<div class="form-group clearfix">
  <label class="col-sm-4 control-label" for="reference">Admitted Value</label>
  <div class="col-sm-8">
    <app-shared-currency
      [(ngModel)]="model.admittedValue"
      [required]="false"
    ></app-shared-currency>
  </div>
</div>

<div class="form-group clearfix">
  <label class="col-sm-4 control-label" for="reference">Secured?</label>
  <div class="col-sm-8">
    <input [(ngModel)]="model.secured" class="form-control" type="checkbox" />
  </div>
</div>

<div *ngIf="model.secured === true" class="form-group clearfix">
  <label class="col-sm-4 control-label" for="reference">Secured On</label>
  <div class="col-sm-8">
    <app-shared-date
      [(ngModel)]="model.securedOn"
      [allowFuture]="false"
      [allowPast]="true"
      [hasDate]="true"
      [hasTime]="false"
      [required]="model.secured === true"
    ></app-shared-date>
  </div>
</div>

<div class="form-group clearfix">
  <label class="col-sm-4 control-label" for="reference">Excluded?</label>
  <div class="col-sm-8">
    <input [(ngModel)]="model.excluded" class="form-control" type="checkbox" />
  </div>
</div>

<div class="form-group clearfix">
  <label class="col-sm-4 control-label" for="reference">Status</label>
  <div class="col-sm-8">
    <select [(ngModel)]="model.status" [required]="false" class="form-control">
      <option>Active</option>
      <option>Defaulted</option>
      <option>Closed</option>
    </select>
  </div>
</div>

<div class="form-group clearfix">
  <label class="col-sm-4 control-label" for="reference">Note</label>
  <div class="col-sm-8">
    <quill-editor [(ngModel)]="model.note" name="note"></quill-editor>
  </div>
</div>
