import {HttpClientModule} from '@angular/common/http';
import {NgModule} from '@angular/core';
import {FormsModule} from '@angular/forms';
import {BrowserModule} from '@angular/platform-browser';
import {RouterModule, Routes} from '@angular/router';
import {FileUploadModule} from 'ng2-file-upload';
import {ModalModule} from 'ngx-bootstrap/modal';
import {TooltipModule} from 'ngx-bootstrap/tooltip';

import {AuthGuard} from '../../../auth/auth.guard';
import {SharedModule} from '../../../shared/shared.module';
import {BankingAccountItemSelectService} from './banking-account-item-select.service';
import {BankingAccountItemService} from './banking-account-item.service';
import {BankingAccountItemCreateComponent} from './create/create.component';
import {BankingAccountItemEditComponent} from './edit/edit.component';
import {BankingAccountItemFormComponent} from './form/form.component';
import {BankingAccountItemListComponent} from './list/list.component';
import {BankingAccountItemUploadComponent} from './upload/upload.component';

const routes: Routes = [
  {
    path: 'banking/accounts/:accountId/items',
    component: BankingAccountItemListComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'banking/accounts/:accountId/items/create',
    component: BankingAccountItemCreateComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'banking/accounts/:accountId/items/:id/edit',
    component: BankingAccountItemEditComponent,
    canActivate: [AuthGuard],
  },
];

@NgModule({
  declarations: [
    BankingAccountItemListComponent,
    BankingAccountItemCreateComponent,
    BankingAccountItemFormComponent,
    BankingAccountItemEditComponent,
    BankingAccountItemUploadComponent,
  ],
  imports: [
    ModalModule.forRoot(),
    FileUploadModule,
    BrowserModule,
    RouterModule,
    FormsModule,
    HttpClientModule,
    SharedModule,
    RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' }),
    TooltipModule.forRoot(),
  ],
  exports: [RouterModule],
  providers: [
    AuthGuard,
    BankingAccountItemService,
    BankingAccountItemSelectService,
  ],
})
export class BankingAccountItemRoutingModule {}
