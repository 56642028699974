import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

import {CreditorVote} from '../case-creditor-vote';
import {CaseCreditorVotesService} from '../case-creditor-vote.service';

@Component({
  selector: 'app-case-creditor-votes-list',
  templateUrl: 'list.component.html',
})
export class CaseCreditorVotesListComponent implements OnInit {
  @Input() caseRefreshed: EventEmitter<string>;
  @Input()
  private caseId: string;
  @Output()
  public votesUpdated: EventEmitter<string> = new EventEmitter<string>();

  public creditorVotes: CreditorVote[] = [];

  constructor(private service: CaseCreditorVotesService) {}

  ngOnInit(): void {
    this.loadCreditorVotes();
    this.caseRefreshed.subscribe((id) => {
      this.caseId = id;
      this.loadCreditorVotes();
    });
  }

  public votesSaved() {
    this.loadCreditorVotes();
    this.votesUpdated.emit(this.caseId);
  }

  public get downloadUrl() {
    return `${this.service.voteListUrl(this.caseId)}?download=true`;
  }

  public loadCreditorVotes() {
    this.service
      .getCreditorVotes(this.caseId)
      .subscribe((r) => (this.creditorVotes = r));
  }

  public sumTotalNumber(): number {
    return this.creditorVotes.reduce((p, c) => p + this.sumRow(c), 0);
  }

  public sumTotal(): string {
    return this.formatCurrencyAsString(this.sumTotalNumber() / 100);
  }

  public sumColNumber(name: string): number {
    return +this.creditorVotes
      .map((item) => this.getColumnValue(item, name, 0))
      .reduce((p, c) => +p + +c, 0);
  }

  public sumCol(name: string): string {
    return this.formatCurrencyAsString(this.sumColNumber(name) / 100);
  }

  public percentColNumber(name: string): number {
    const columnValue = this.sumColNumber(name);

    if (this.sumTotalNumber() === 0) {
      return 0;
    }

    return (columnValue / this.sumTotalNumber()) * 100;
  }

  public percentCol(name: string): string {
    return this.percentColNumber(name).toFixed(2);
  }

  public getColumnValue(
    creditor: CreditorVote,
    columnName: string,
    defaultValue: number | string = 0,
    asString: boolean = false,
  ): number | string {
    if (!creditor[columnName] || creditor[columnName] === null) {
      return defaultValue;
    }

    return asString
      ? this.formatCurrencyAsString(creditor[columnName] / 100)
      : +creditor[columnName];
  }

  public sumSingleRow(creditor: CreditorVote): string {
    return this.formatCurrencyAsString(this.sumRow(creditor) / 100);
  }

  public sumRow(creditor: CreditorVote): number {
    return (
      +this.getColumnValue(creditor, 'voteAcceptedNoMods') +
      +this.getColumnValue(creditor, 'voteAcceptedWithMods') +
      +this.getColumnValue(creditor, 'voteAcceptedWithModsRejected') +
      +this.getColumnValue(creditor, 'voteRejected') +
      +this.getColumnValue(creditor, 'invalidProxy')
    );
  }

  public percentTotalCol(): string {
    return (
      +this.percentColNumber('voteAcceptedNoMods') +
      +this.percentColNumber('voteAcceptedWithMods') +
      +this.percentColNumber('voteAcceptedWithModsRejected') +
      +this.percentColNumber('voteRejected') +
      +this.percentColNumber('invalidProxy')
    ).toFixed(2);
  }

  protected formatCurrencyAsString(x: number): string {
    return new Intl.NumberFormat('en-GB', {
      style: 'currency',
      currency: 'GBP',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    }).format(x);
  }
}
