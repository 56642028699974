<div class="row">
  <div class="col-sm-12">
    <section class="panel">
      <app-page-title [loading]="isLoading" title="List Chart of Accounts">
        {{ totalResults }} Results Found
      </app-page-title>

      <div class="panel-body">
        <table class="table table-striped">
          <thead>
            <tr>
              <th></th>
              <th>Name</th>
              <th>Code</th>
              <th>Description</th>
              <th>Supplier</th>
              <th>Account Code</th>
              <th>Invoice Required</th>
              <th>Estimated</th>
              <th class="text-right">
                <a
                  [routerLink]="['/chart-of-accounts/create']"
                  class="btn btn-success"
                  >Create</a
                >
              </th>
            </tr>
          </thead>

          <tbody>
            <tr *ngFor="let singleChartOfAccounts of chartOfAccounts">
              <td></td>
              <td>{{ singleChartOfAccounts?.name }}</td>
              <td>{{ singleChartOfAccounts?.code }}</td>
              <td>{{ singleChartOfAccounts?.description }}</td>
              <td>{{ singleChartOfAccounts?.supplier?.name }}</td>
              <td>{{ singleChartOfAccounts?.financialAccount?.code }}</td>
              <td>
                <i
                  *ngIf="
                    singleChartOfAccounts.invoiceRequired === 'true' ||
                    singleChartOfAccounts.invoiceRequired === true
                  "
                  class="fa fa-check text-success"
                ></i>
                <i
                  *ngIf="
                    singleChartOfAccounts.invoiceRequired === 'false' ||
                    singleChartOfAccounts.invoiceRequired === false
                  "
                  class="fa fa-times text-danger"
                ></i>
              </td>
              <td>
                <i
                  *ngIf="
                    singleChartOfAccounts.estimated === 'true' ||
                    singleChartOfAccounts.estimated === true
                  "
                  class="fa fa-check text-success"
                ></i>
                <i
                  *ngIf="
                    singleChartOfAccounts.estimated === 'false' ||
                    singleChartOfAccounts.estimated === false
                  "
                  class="fa fa-times text-danger"
                ></i>
              </td>
              <td class="text-right">
                <div class="btn-group">
                  <a
                    [routerLink]="[
                      '/chart-of-accounts',
                      singleChartOfAccounts?.id,
                      'edit'
                    ]"
                    class="btn btn-sm btn-warning"
                    type="button"
                    >Edit</a
                  >
                  <button
                    aria-expanded="false"
                    aria-haspopup="true"
                    class="btn btn-sm btn-warning dropdown-toggle"
                    data-toggle="dropdown"
                    type="button"
                  >
                    <span class="caret"></span>
                    <span class="sr-only">Toggle Dropdown</span>
                  </button>
                  <ul class="dropdown-menu">
                    <li>
                      <a
                        (click)="
                          deleteModal.confirmDialog(singleChartOfAccounts.id)
                        "
                        href="javascript:"
                        >Delete</a
                      >
                    </li>
                  </ul>
                </div>
              </td>
            </tr>
          </tbody>

          <tfoot>
            <tr>
              <td class="text-center" colspan="99">
                <app-pagination
                  (pageChanged)="onPageChanged($event)"
                  [currentPage]="currentPage"
                  [totalPages]="totalPages"
                ></app-pagination>
              </td>
            </tr>
          </tfoot>
        </table>
      </div>
    </section>
  </div>
</div>

<app-delete-modal
  #deleteModal
  (confirm)="delete($event)"
  [itemDeleted]="chartOfAccountsDeleted"
  type="chartOfAccounts"
></app-delete-modal>
