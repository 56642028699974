import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-pagination',
  templateUrl: './pagination.component.html',
})
export class PaginationComponent {
  public maximumPages = 5;

  @Input() currentPage: number = 1;

  @Input() totalPages: number;

  @Output() pageChanged = new EventEmitter<number>();

  public skipToPage: number = 1;

  createPageNumberArray(): number[] {
    var items: number[] = [];

    let pageOffsets = this.calculatePageOffsets();

    for (var i = pageOffsets.bottom; i <= pageOffsets.top; i++) {
      items.push(i);
    }
    return items;
  }

  changePage(page: number): void {
    this.currentPage = page;
    this.skipToPage = page;
    this.pageChanged.emit(page);
  }

  previousPage(): void {
    this.changePage(this.currentPage - 1);
  }

  nextPage(): void {
    this.changePage(this.currentPage + 1);
  }

  private calculatePageOffsets() {
    let topMaximumPage = this.currentPage + Math.floor(this.maximumPages / 2);
    let bottomMaximumPage =
      this.currentPage - Math.floor(this.maximumPages / 2);

    if (bottomMaximumPage < 1) {
      topMaximumPage += 1 - bottomMaximumPage;
      bottomMaximumPage = 1;
    }
    if (topMaximumPage > this.totalPages) {
      bottomMaximumPage -= topMaximumPage - this.totalPages;
      topMaximumPage = this.totalPages;
    }

    return {
      bottom: bottomMaximumPage < 1 ? 1 : bottomMaximumPage,
      top: topMaximumPage > this.totalPages ? this.totalPages : topMaximumPage,
    };
  }
}
