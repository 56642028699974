import { Component, OnInit } from '@angular/core';

import { ActivatedRoute } from '@angular/router';
import { AuthService } from '../../../auth/auth.service';
import { FinancialsDistributionService } from '../financials-distribution.service';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-financials-distribution-list',
  templateUrl: 'list.component.html',
  styleUrls: [],
})
export class FinancialsDistributionListComponent implements OnInit {
  distributionItems: any[];
  filter: string = '';
  public displayLedger: boolean = false;
  public allocationType = 'all';
  public allocationsInProgress = false;

  public allocationAboutToRun = false;

  /**
   * Are we currently performing a loading action?
   *
   * @item {boolean}
   */
  public isLoading: boolean = true;

  /**
   * The total number of pages in this list of banking-account-items.
   *
   * @item {number}
   */
  public totalPages: number = 1;

  /**
   * The current page of banking-account-items that we are viewing.
   *
   * @item {number}
   */
  public currentPage: number = 1;

  /**
   * The total number of banking-account-items that can be viewed in the system.
   *
   * @item {number}
   */
  public totalResults: number = 0;

  constructor(
    private readonly http: HttpClient,
    public readonly authService: AuthService,
    private route: ActivatedRoute,
    private readonly distributionService: FinancialsDistributionService,
  ) {
    this.distributionService.automaticAllocationsInProgress().subscribe((r) => {
      if (this.allocationsInProgress === true && r === false) {
        this.loadDistributionList(1);
      }

      this.allocationsInProgress = r;
    });

    this.distributionService.loading.subscribe((isLoading) => {
      this.isLoading = isLoading;
    });
    this.distributionService.totalPages.subscribe((totalPages) => {
      this.totalPages = totalPages;
    });
    this.distributionService.currentPage.subscribe((currentPage) => {
      this.currentPage = currentPage;
    });
    this.distributionService.totalResults.subscribe((totalResults) => {
      this.totalResults = totalResults;
    });
  }

  ngOnInit(): void {
    this.loadDistributionList(1);
  }

  public startAllocating(includeDistributions: boolean = false) {
    this.distributionService
      .startAutomaticAllocations(includeDistributions)
      .subscribe((r) => {
        this.allocationAboutToRun = r;
      });
  }

  public onPageChanged(pageNumber: number): void {
    this.loadDistributionList(pageNumber);
  }

  public totalCashIn(a, b) {
    return Number(a || 0) + Number(b || 0);
  }

  public loadDistributionList(page?: number, limit?: number): void {
    const listDistributionItems = this.distributionService
      .listDistributionCases(page, limit, undefined, {
        filter: this.filter,
        type: this.allocationType,
      })
      .subscribe((items: any[]) => {
        this.distributionItems = items;
        listDistributionItems.unsubscribe();
      });
  }

  public allocationtypeUpdated(event) {
    this.loadDistributionList(1);
  }
}
