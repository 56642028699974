<ng-container *ngIf="direction === 'horizontal'">
  <div class="row person-item">
    <div class="col-xs-4 person-item-title">Name</div>
    <div class="col-xs-8">
      {{ person?.title?.name }} {{ person?.firstName }}
      {{ person?.middleNames }} {{ person?.lastName }}
      <span class="text-muted" *ngIf="person?.maidenName"
        >({{ person?.maidenName }})</span
      >
    </div>
  </div>
  <div class="row person-item">
    <div class="col-xs-4 person-item-title">Date of Birth</div>
    <div class="col-xs-8">
      {{ person?.dateOfBirth | date: 'dd/MM/yyyy' }} ({{
        person?.dateOfBirth | age
      }})
    </div>
  </div>

  <div class="row person-item">
    <div class="col-xs-4 person-item-title">Mobile</div>
    <div class="col-xs-8">{{ person?.mobileNumber }}</div>
  </div>
  <div class="row person-item">
    <div class="col-xs-4 person-item-title">Landline</div>
    <div class="col-xs-8">{{ person?.landlineNumber }}</div>
  </div>
  <div class="row person-item">
    <div class="col-xs-4 person-item-title">E-Mail</div>
    <div class="col-xs-8">{{ person?.emailAddress }}</div>
  </div>

  <hr />

  <div class="row person-item">
    <div class="col-xs-4 person-item-title">Marital</div>
    <div class="col-xs-8">{{ person?.maritalStatus?.name }}</div>
  </div>
  <div class="row person-item">
    <div class="col-xs-4 person-item-title">Residential</div>
    <div class="col-xs-8">{{ person?.residentialStatus?.name }}</div>
  </div>
  <div class="row person-item">
    <div class="col-xs-4 person-item-title">Employment</div>
    <div class="col-xs-8">{{ person?.employmentStatus?.name }}</div>
  </div>
  <div class="row person-item">
    <div class="col-xs-4 person-item-title">Occupation</div>
    <div class="col-xs-8">{{ person?.occupation?.name }}</div>
  </div>
  <div class="row person-item">
    <div class="col-xs-4 person-item-title">Employer</div>
    <div class="col-xs-8">{{ person?.employer }}</div>
  </div>

  <ng-container *ngIf="person?.addresses && person?.addresses.length > 0">
    <hr />

    <div class="row person-item">
      <div class="col-xs-6 person-item-title">Applicant Addresses</div>
      <div class="col-xs-6 text-right">
        <button (click)="create.open()" class="btn btn-success btn-xs">
          Add Address
        </button>
      </div>
    </div>
    <div class="person-addresses">
      <div *ngFor="let address of person.addresses; let i = index">
        <hr *ngIf="i !== 0" />

        <div class="row person-item">
          <div class="col-xs-4 person-item-title">Address 1</div>
          <div class="col-xs-6">{{ address.address1 }}</div>
          <div class="col-xs-2">
            <button (click)="edit.open(address)" class="btn btn-warning btn-xs">
              Edit
            </button>
          </div>
        </div>

        <div class="row person-item">
          <div class="col-xs-4 person-item-title">Address 2</div>
          <div class="col-xs-8">{{ address.address2 }}</div>
        </div>

        <div class="row person-item">
          <div class="col-xs-4 person-item-title">Town</div>
          <div class="col-xs-8">{{ address.town }}</div>
        </div>

        <div class="row person-item">
          <div class="col-xs-4 person-item-title">County</div>
          <div class="col-xs-8">{{ address.county }}</div>
        </div>

        <div class="row person-item">
          <div class="col-xs-4 person-item-title">Postcode</div>
          <div class="col-xs-8">{{ address.postalCode }}</div>
        </div>

        <div *ngIf="address?.from || address?.till" class="row person-item">
          <div class="col-xs-4 person-item-title">Time at Address</div>
          <div class="col-xs-8">
            From:
            {{ address?.from ? (address?.from | date: 'dd/MM/yyyy') : '-' }} To:
            {{ address?.till ? (address?.till | date: 'dd/MM/yyyy') : '-' }}
          </div>
        </div>

        <div *ngIf="address.addressType" class="row person-item">
          <div class="col-xs-4 person-item-title">Address Type</div>
          <div class="col-xs-8">{{ address.addressType.name }}</div>
        </div>
      </div>
    </div>
  </ng-container>
</ng-container>

<app-case-person-addresses-edit
  #edit
  [personId]="person && person.id"
  [caseId]="caseId"
  (completed)="reloadCase.next(true)"
></app-case-person-addresses-edit>
<app-case-person-addresses-create
  #create
  [personId]="person && person.id"
  [caseId]="caseId"
  (completed)="reloadCase.next(true)"
></app-case-person-addresses-create>
