<div
  class="modal fade"
  bsModal
  #staticModal="bs-modal"
  [config]="{ backdrop: 'static' }"
  tabindex="-1"
  role="dialog"
  aria-labelledby="mySmallModalLabel"
  aria-hidden="true"
>
  <div class="modal-dialog modal-md">
    <form (submit)="upload()" #completedForm="ngForm" ngNativeValidate>
      <div class="modal-content panel-success">
        <div class="modal-header panel-heading">
          <h4 class="modal-title pull-left">Create Document</h4>
          <button
            type="button"
            class="close pull-right"
            aria-label="Close"
            (click)="close()"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <div class="form-group clearfix">
            <label for="document" class="col-sm-4 control-label"
              >Document Type</label
            >
            <div class="col-sm-8">
              <app-document-select
                [model]="newDocument"
                [required]="false"
              ></app-document-select>
            </div>
          </div>

          <div class="form-group clearfix">
            <label for="creditor" class="col-sm-4 control-label"
              >Related Creditor</label
            >
            <div class="col-sm-8">
              <app-creditor-select
                [model]="newDocument"
                [required]="false"
              ></app-creditor-select>
            </div>
          </div>

          <div class="form-group clearfix">
            <label class="col-sm-4 control-label" for="reference"
              >Delivery Method</label
            >
            <div class="col-sm-8">
              <app-delivery-method-select
                [model]="newDocument"
                [required]="true"
              ></app-delivery-method-select>
            </div>
          </div>

          <div class="form-group clearfix">
            <label for="file" class="col-sm-4 control-label">File</label>

            <label class="col-sm-8" for="fileButton">
              <div
                class="well my-drop-zone"
                ng2FileDrop
                [uploader]="uploader"
                (fileOver)="fileOverBase($event)"
                [ngClass]="{ 'nv-file-over': hasBaseDropZoneOver }"
              >
                <div *ngFor="let item of uploader.queue">
                  <strong>{{ item?.file?.name }}</strong>
                </div>

                <div *ngIf="uploader?.queue?.length < 1">
                  Drag a file (or click here) to choose a file to upload.
                </div>

                <input
                  type="file"
                  id="fileButton"
                  style="display: none"
                  ng2FileSelect
                  [uploader]="uploader"
                />
              </div>
            </label>
          </div>

          <div class="progress" style="margin-bottom: 0">
            <div
              class="progress-bar"
              role="progressbar"
              [ngStyle]="{ width: uploader.progress + '%' }"
            ></div>
          </div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-default" (click)="close()">
            Cancel
          </button>
          <button type="submit" class="btn btn-success" [disabled]="disabled">
            Create
          </button>
        </div>
      </div>
    </form>
  </div>
</div>
