import { Injectable } from '@angular/core';
import { Credit } from './credit';
import { Observable } from 'rxjs';

import { HttpRequestService } from '../shared/http/http-request.service';

@Injectable()
export class CreditService extends HttpRequestService {
  /**
   * The endpoint that we call to get the credit information.
   *
   * @type {string}
   */
  private readonly endpoint: string = 'credits';

  /**
   * Brings back a list of all accessible credits.
   *
   * @param {number} page The page number we are currently on.
   * @param {number} limit The number of credits to display per page.
   * @returns {Observable<Credit[]>}
   */
  public listCredits(page?: number, limit?: number): Observable<Credit[]> {
    return this.list(this.endpoint, page, undefined, limit);
  }

  /**
   * Finds details of the credit from the given ID.
   *
   * @param {string} creditId The ID of the credit we require details for.
   * @returns {Observable<Credit>}
   */
  public findCredit(creditId: string): Observable<Credit> {
    return this.findById(this.endpoint, creditId);
  }

  /**
   * Creates a new credit from the given Credit object.
   *
   * @param {Credit} newCredit The details of the credit to be created.
   * @returns {Observable<Credit | boolean>}
   */
  public createCredit(newCredit: Credit): Observable<Credit | boolean> {
    return this.create(this.endpoint, newCredit);
  }

  /**
   * Updates a credit with the given credit object for the given credit ID.
   *
   * @param {string} creditId The ID of the credit needing updating.
   * @param {Credit} newDetails The new credit object to update with.
   * @returns {Observable<Credit | boolean>}
   */
  public updateCredit(
    creditId: string,
    newDetails: Credit,
  ): Observable<Credit | boolean> {
    return this.update(this.endpoint, creditId, newDetails);
  }

  /**
   * Deletes a credit based on the given credit ID.
   *
   * @param {string} creditId The ID of the credit needing deleting.
   * @returns {Observable<boolean>}
   */
  public deleteCredit(creditId: string): Observable<boolean> {
    return this.delete(this.endpoint, creditId);
  }
}
