import { Component, EventEmitter, Output, ViewChild } from '@angular/core';

import { CaseCalendar } from '../case-calendar';
import { CaseCalendarService } from '../case-calendar.service';
import { NotificationService } from '../../../shared/notification.service';

@Component({
  selector: 'app-case-calendar-edit',
  templateUrl: 'edit.component.html',
})
export class CaseCalendarEditComponent {
  @ViewChild('staticModal') staticModal;

  @Output() completed = new EventEmitter<boolean>();

  public disabled: boolean = false;

  public model: CaseCalendar;

  private givenCaseId: string;

  constructor(
    private readonly caseCalendarService: CaseCalendarService,
    private readonly notificationService: NotificationService,
  ) {}

  public get caseId(): string {
    return this.givenCaseId;
  }

  public open(caseId: string, model: CaseCalendar): void {
    this.givenCaseId = caseId;
    this.model = model;
    this.staticModal.show();
  }

  public close() {
    this.workCompleted();
  }

  public confirmEdit($event) {
    $event.preventDefault();
    if (!this.model.user && !this.model.department) {
      this.notificationService.error(
        'Incorrect Selection',
        'Please select one of User or Department to edit Task.',
        10,
      );
      return;
    }
    this.disabled = true;
    this.caseCalendarService
      .updateCaseCalendar(this.model.id, this.model, {
        parameters: { id: this.caseId },
      })
      .subscribe(() => {
        this.workCompleted();
      });
  }

  private workCompleted() {
    this.model = undefined;
    this.disabled = false;
    this.staticModal.hide();
    this.completed.next(true);
  }
}
