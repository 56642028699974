import { FormControl, FormGroup } from 'react-bootstrap';

import Alert from '../../Components/Alert';
import AddressType from '../../../../../chief-types/src/AddressType';
import useDomains from './UseDomains';
import Domain from '../../../../../chief-types/src/Domain';
import { useEffect } from 'react';

type AddressTypeFormProps = {
  addressType: AddressType;
  hasAllDomainsPermission: boolean;
  userDomains: Domain[];
  setAddressTypeValue: (key: keyof AddressType, value: any) => void;
};

const AddressTypeForm = ({
  addressType,
  hasAllDomainsPermission,
  userDomains,
  setAddressTypeValue,
}: AddressTypeFormProps) => {
  const { items: domains, error: domainsError } = useDomains(true);

  // Ensure that there is an initial selection for domain if the user does not have global permissions
  useEffect(() => {
    if (!addressType.domain && !hasAllDomainsPermission && userDomains.length > 0) {
      setAddressTypeValue('domain', {...userDomains[0]});
    }
  }, [userDomains]);

  const domainDropdownIsVisible =
    domains &&
    (hasAllDomainsPermission || (userDomains && userDomains.length > 1));
  const filteredDomains = hasAllDomainsPermission ? domains : userDomains;

  return (
    <>
      {domainsError && (
        <Alert
          color='red'
          title='Error'
          message={domainsError}
          className='tw-m-6'
        />
      )}
      <FormGroup controlId='formControlsTextarea'>
        <label>Address Type Name</label>
        <FormControl
          type='text'
          defaultValue={addressType.name || undefined}
          placeholder='Address Type Name'
          onChange={(e: any) => setAddressTypeValue('name', e.target.value)}
        />
        {domainDropdownIsVisible && (
          <>
            <label>Domain</label>
            <select
              className="tw-block tw-w-full tw-border tw-border-gray-200 tw-py-3 tw-px-4 tw-pr-8 tw-rounded tw-leading-tight tw-focus:outline-none tw-focus:bg-white tw-focus:border-gray-500"
              value={addressType?.domain?.name}
              onChange={(e: any) =>
                setAddressTypeValue(
                  'domain',
                  domains?.find((domain) => domain.name === e.target.value) ||
                    null,
                )
              }
            >
              {hasAllDomainsPermission && <option>No Domain</option>}
              {filteredDomains &&
                filteredDomains.map((domain) => {
                  return (
                    <option key={domain.id} value={domain.name}>
                      {domain.name}
                    </option>
                  );
                })}
            </select>
          </>
        )}
      </FormGroup>
    </>
  );
};

export default AddressTypeForm;
