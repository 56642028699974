import { RouterModule, Routes } from '@angular/router';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule } from '@angular/common/http';
import { BreachTypeListComponent } from './list/list.component';
import { AuthGuard } from '../../auth/auth.guard';
import { BreachTypeService } from './breach-type.service';
import { BreachTypeCreateComponent } from './create/create.component';
import { BreachTypeFormComponent } from './form/form.component';
import { BreachTypeEditComponent } from './edit/edit.component';
import { SharedModule } from '../../shared/shared.module';

const routes: Routes = [
  {
    path: 'breach-types',
    component: BreachTypeListComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'breach-types/create',
    component: BreachTypeCreateComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'breach-types/:id/edit',
    component: BreachTypeEditComponent,
    canActivate: [AuthGuard],
  },
];

@NgModule({
  declarations: [
    BreachTypeListComponent,
    BreachTypeCreateComponent,
    BreachTypeFormComponent,
    BreachTypeEditComponent,
  ],
  imports: [
    BrowserModule,
    RouterModule,
    FormsModule,
    HttpClientModule,
    SharedModule,
    RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' }),
  ],
  exports: [RouterModule],
  providers: [AuthGuard, BreachTypeService],
})
export class BreachTypeRoutingModule {}
