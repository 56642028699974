import { Component, OnInit } from '@angular/core';
import { CaseService } from '../case.service';
import { Case } from '../case';
import { ActivatedRoute, Router } from '@angular/router';
import { NotificationService } from '../../shared/notification.service';
import { Person } from '../people/person';

@Component({
  selector: 'app-case-edit',
  templateUrl: 'edit.component.html',
})
export class CaseEditComponent {
  /**
   * Details of the case being edited.
   */
  public case: Case;

  /**
   * Are we currently performing a loading action?
   *
   * @type {boolean}
   */
  public isLoading: boolean = false;

  /**
   * Load up all our required services and subscribe to every observable we need.
   *
   * @param {CaseService} caseService
   * @param {NotificationService} notificationService
   * @param {Router} router
   * @param {ActivatedRoute} route
   */
  constructor(
    private caseService: CaseService,
    private notificationService: NotificationService,
    private router: Router,
    private route: ActivatedRoute,
  ) {
    this.caseService.loading.subscribe(result => (this.isLoading = result));
    this.route.params.subscribe(params => this.loadCase(params.id));
  }

  /**
   * Perform the edit action which send the edited case to the backend.
   */
  public edit() {
    const editCase = this.caseService
      .updateCase(this.case.id, this.case)
      .subscribe(
        (result: Case) => {
          this.notificationService.success(
            `Case Updated`,
            `The case ${result.people
              .map(person => `${person.firstName} ${person.lastName}`)
              .join(', ')} been updated with the specified changes.`,
            5,
          );
          editCase.unsubscribe();
          this.router.navigate([`/cases/${this.case.id}`]);
        },
        err => {
          this.notificationService.error(
            `Case Not Found`,
            err.error.message,
            10,
          );
          this.router.navigate(['/cases']);
        },
      );
  }

  /**
   * Loads the requested case so we can display it and let the user edit the fields.
   *
   * @param {string} id
   */
  private loadCase(id: string) {
    this.caseService.findCase(id).subscribe(
      result => {
        this.case = result;
      },
      err => {
        this.notificationService.error(`Case Not Found`, err.error.message, 10);
        this.router.navigate(['/cases']);
      },
    );
  }
}
