import * as moment from 'moment';

import { Component, OnInit } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { AuthService } from '../../auth/auth.service';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-wip-report',
  templateUrl: 'wip.component.html',
})
export class WipComponent implements OnInit {
  protected startDate = moment('2017-12-01');

  protected selectedTab: string = 'details';
  public loading: boolean = false;
  public filter: any = {
    approvedDate: null,
  };

  public downloadInMonth = moment().format('YYYY-MM');

  public detailedReport: any[];

  public monthsSinceStart: string[] = [];

  constructor(
    private readonly http: HttpClient,
    private readonly authService: AuthService,
  ) {
    const months: string[] = [];
    for (
      const m = moment(this.startDate);
      m.diff(moment().endOf('month'), 'months') <= 0;
      m.add(1, 'months')
    ) {
      this.monthsSinceStart.push(m.format('YYYY-MM'));
    }
    this.monthsSinceStart = this.monthsSinceStart.reverse();
  }

  dateswitch(date: string): string {
    const flipMonth = moment(`${date}-01`);

    return flipMonth.format('MMMM YYYY');
  }

  ngOnInit(): void {
    this.loadDetailedReport(1);
  }

  public reloadAllReports() {
    this.loadDetailedReport(1);
  }

  get detailedLink(): string {
    let url: string = `${environment.apiUrl}reports/wip?domainId=${
      this.authService.domain.id
    }&auth=${this.authService.getToken()}`;

    if (this.filter.introducer) {
      url += `&introducerId=${this.filter.introducer.id}`;
    }

    if (
      this.filter.approvedDate &&
      this.filter.approvedDate !== null &&
      this.filter.approvedDate !== 'null'
    ) {
      url += `&approvedDate=${this.filter.approvedDate}`;
    }

    return url;
  }

  protected loadDetailedReport(page: number) {
    this.loading = true;
    this.http.get<any>(this.detailedLink).subscribe((data) => {
      this.loading = false;
      this.detailedReport = data.data;
    });
  }

  public setTab(tabName: string): void {
    this.selectedTab = tabName;
  }

  public tabSelected(tabName: string): boolean {
    return this.selectedTab === tabName;
  }
}
