import { Injectable } from '@angular/core';

import { HttpOptions } from '../../shared/http/http-options';
import { Observable } from 'rxjs';
import { HttpRequestService } from '../../shared/http/http-request.service';
import { FeeComparison } from './fee-comparison';
import { CaseCreditor } from '../creditors/case-creditor';

@Injectable()
export class FeeComparisonService extends HttpRequestService {
  private readonly endpoint: string = 'cases/:id/fee-comparison';

  public listPreviousFeeComparisons(
    page?: number,
    limit?: number,
    options?: HttpOptions,
  ): Observable<FeeComparison[]> {
    return this.list(this.endpoint + '/all', 1, undefined, limit, options);
  }

  public listFeeComparison(
    page?: number,
    limit?: number,
    options?: HttpOptions,
  ): Observable<FeeComparison[]> {
    return this.list(this.endpoint, page, undefined, limit, options);
  }

  public updateCaseCreditor(
    caseId: string,
    newDetails: CaseCreditor,
    options?: HttpOptions,
  ): Observable<CaseCreditor | boolean> {
    return this.update(this.endpoint, caseId, newDetails, options);
  }

  public saveFeeComparison(caseId: string, items: any): Observable<boolean> {
    return this.create(this.endpoint, items, { parameters: { id: caseId } });
  }
}
