import { Injectable } from '@angular/core';
import { Title } from './title';
import { Observable } from 'rxjs';

import { HttpRequestService } from '../shared/http/http-request.service';

@Injectable()
export class TitleService extends HttpRequestService {
  /**
   * The endpoint that we call to get the title information.
   *
   * @type {string}
   */
  private readonly endpoint: string = 'titles';

  /**
   * Brings back a list of all accessible titles.
   *
   * @param {number} page The page number we are currently on.
   * @param {number} limit The number of titles to display per page.
   * @returns {Observable<Title[]>}
   */
  public listTitles(page?: number, limit?: number): Observable<Title[]> {
    return this.list(this.endpoint, page, undefined, limit);
  }

  /**
   * Finds details of the title from the given ID.
   *
   * @param {string} titleId The ID of the title we require details for.
   * @returns {Observable<Title>}
   */
  public findTitle(titleId: string): Observable<Title> {
    return this.findById(this.endpoint, titleId);
  }

  /**
   * Creates a new title from the given Title object.
   *
   * @param {Title} newTitle The details of the title to be created.
   * @returns {Observable<Title | boolean>}
   */
  public createTitle(newTitle: Title): Observable<Title | boolean> {
    return this.create(this.endpoint, newTitle);
  }

  /**
   * Updates a title with the given title object for the given title ID.
   *
   * @param {string} titleId The ID of the title needing updating.
   * @param {Title} newDetails The new title object to update with.
   * @returns {Observable<Title | boolean>}
   */
  public updateTitle(
    titleId: string,
    newDetails: Title,
  ): Observable<Title | boolean> {
    return this.update(this.endpoint, titleId, newDetails);
  }

  /**
   * Deletes a title based on the given title ID.
   *
   * @param {string} titleId The ID of the title needing deleting.
   * @returns {Observable<boolean>}
   */
  public deleteTitle(titleId: string): Observable<boolean> {
    return this.delete(this.endpoint, titleId);
  }
}
