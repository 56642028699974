<div class="form-group">
  <label for="title" class="col-sm-4 control-label">Title</label>
  <div class="col-sm-8">
    <app-title-select [model]="model" [required]="true"></app-title-select>
  </div>
</div>

<div class="form-group">
  <label for="firstName" class="col-sm-4 control-label">First Name</label>
  <div class="col-sm-8">
    <input type="text" class="form-control" name="firstName" id="firstName" placeholder="First Name" [(ngModel)]="model.firstName" [required]="isRequired('firstName')">
  </div>
</div>

<div class="form-group">
  <label for="middleNames" class="col-sm-4 control-label">Middle Names</label>
  <div class="col-sm-8">
    <input type="text" class="form-control" name="middleNames" id="middleNames" placeholder="Middle Names" [(ngModel)]="model.middleNames" [required]="isRequired('middleNames')">
  </div>
</div>

<div class="form-group">
  <label for="lastName" class="col-sm-4 control-label">Last Name</label>
  <div class="col-sm-8">
    <input type="text" class="form-control" name="lastName" id="lastName" placeholder="Last Name" [(ngModel)]="model.lastName" [required]="isRequired('lastName')">
  </div>
</div>

<div class="form-group">
  <label for="maidenName" class="col-sm-4 control-label">Maiden Name</label>
  <div class="col-sm-8">
    <input type="text" class="form-control" name="maidenName" id="maidenName" placeholder="Maiden Name" [(ngModel)]="model.maidenName" [required]="isRequired('maidenName')">
  </div>
</div>

<div class="form-group">
  <label for="dateOfBirth" class="col-sm-4 control-label">Date of Birth</label>
  <div class="col-sm-8">
    <app-shared-date [(ngModel)]="model.dateOfBirth" [allowFuture]="false" [allowPast]="true" [required]="true"></app-shared-date>
  </div>
</div>

<div class="form-group">
  <label for="gender" class="col-sm-4 control-label">Gender</label>
  <div class="col-sm-8">
    <app-gender-select [model]="model"></app-gender-select>
  </div>
</div>

<div class="form-group">
  <label for="marital-status" class="col-sm-4 control-label">Marital Status</label>
  <div class="col-sm-8">
    <app-marital-status-select [model]="model"></app-marital-status-select>
  </div>
</div>
