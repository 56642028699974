export class CaseAnnual {
  public id?: string;
  public dueOn?: string;
  public createdAt?: string;
  public contributionNotes?: string;
  public termNotes?: string;
  public iandeNotes?: string;
  public assetNotes?: string;
  public dividendApprovedNotes?: string;
  public dividendPaidNotes?: string;
  public feeNotes?: string;
  public additionalMoniesNotes?: string;
  public completedOn?: string;
    public approve?: string;
  public iandeOutcome?: string;
  public ppiOrPba?: string;

  public additionalClientObligations?: any;
}
