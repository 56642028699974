<div class="text-right button-menu">
  <button type="button" class="btn btn-sm btn-success" href="javascript:;" (click)="create.open(caseId)">
    Add Breach
  </button>
</div>

<table class="breaches table table-striped">
  <thead>
  <tr>
    <th>Type</th>
    <th>Issued</th>
    <th>Expires</th>
    <th>Outcome</th>
    <th></th>
  </tr>
  </thead>
  <tbody>
  <tr *ngFor="let caseBreach of caseBreaches">
    <td>{{ caseBreach?.breachType?.name }}</td>
    <td>{{ caseBreach?.issued | date:"dd/MM/yyyy" }}</td>
    <td>{{ caseBreach.expired | date:"dd/MM/yyyy" }}</td>
    <td>{{ caseBreach.outcome }}</td>
    <td class="text-right">
      <div class="btn-group">
        <a type="button" class="btn btn-xs btn-warning" (click)="edit.open(caseId, caseBreach)">Edit</a>
        <button type="button" class="btn btn-xs btn-warning dropdown-toggle" data-toggle="dropdown"
                aria-haspopup="true" aria-expanded="false" *ngIf="authService.hasPermission('user.delete-cases')">
          <span class="caret" *ngIf="authService.hasPermission('user.delete-cases')"></span>
          <span class="sr-only" *ngIf="authService.hasPermission('user.delete-cases')">Toggle Dropdown</span>
        </button>
        <ul class="dropdown-menu" *ngIf="authService.hasPermission('user.delete-cases')">
          <li *ngIf="authService.hasPermission('user.delete-cases')"><a href="javascript:;"
                                                                        (click)="deleteModal.confirmDialog(caseBreach.id)">Delete</a>
          </li>
        </ul>
      </div>
    </td>
  </tr>
  </tbody>
  <tfoot>
  <tr>
    <td colspan="99" class="text-center">
      <app-pagination [totalPages]="totalPages" [currentPage]="currentPage"
                      (pageChanged)="onPageChanged($event)"></app-pagination>
    </td>
  </tr>
  </tfoot>
</table>


<app-case-breach-create (completed)="onPageChanged(1)" #create></app-case-breach-create>

<app-case-breach-edit (completed)="onPageChanged(1)" #edit></app-case-breach-edit>

<app-delete-modal type="breach" (confirm)="delete($event)" [itemDeleted]="itemDeleted"
                  #deleteModal></app-delete-modal>
