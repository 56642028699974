import { environment } from '../../environments/environment';

export class LogService {
  public static error(...data: any[]): void {
    if (
      environment.logOutput.some(x => {
        return x === 'error';
      })
    ) {
      console.error(...data);
    }
  }

  public static warning(...data: any[]): void {
    if (
      environment.logOutput.some(x => {
        return x === 'warning';
      })
    ) {
      console.warn(...data);
    }
  }

  public static info(...data: any[]): void {
    if (
      environment.logOutput.some(x => {
        return x === 'info';
      })
    ) {
      console.log(...data);
    }
  }
}
