<div class="row">
  <div class="col-sm-12">
    <section class="panel">
      <app-page-title title="Create Chart of Accounts" [loading]="isLoading"></app-page-title>

      <div class="panel-body">
        <form class="form-horizontal" (ngSubmit)="create()" ngNativeValidate>
          <app-chart-of-accounts-form [chartOfAccounts]="chartOfAccounts"
                                      *ngIf="chartOfAccounts"></app-chart-of-accounts-form>
        </form>
      </div>
    </section>
  </div>
</div>


