import { Component, Input, OnInit } from '@angular/core';
import { AccountGroupService } from '../account-group.service';
import { AccountGroup } from '../account-group';
import { SelectComparisons } from '../../../shared/pipes/select.comparisons';
import { NotificationService } from '../../../shared/notification.service';

@Component({
  selector: 'app-account-group-select',
  templateUrl: 'select.component.html',
})
export class AccountGroupSelectComponent extends SelectComparisons
  implements OnInit {
  /**
   * The model that we will be adding the accountGroup to.
   */
  @Input() model: any;

  /**
   * Do we require this field in the main form it is
   * included in?
   */
  @Input() required: boolean;

  /**
   * A list of all the account-groups currently loaded.
   *
   * @type {AccountGroup[]}
   */
  public accountGroups: AccountGroup[] = [];

  /**
   * Are we currently performing a loading action?
   *
   * @type {boolean}
   */
  public isLoading: boolean = true;

  /**
   * Load up all our required services and subscribe to every observable we need.
   *
   * @param {AccountGroupService} accountGroupService
   * @param {NotificationService} notificationService
   */
  constructor(
    private accountGroupService: AccountGroupService,
    private notificationService: NotificationService,
  ) {
    super();
    this.accountGroupService.loading.subscribe(isLoading => {
      this.isLoading = isLoading;
    });
  }

  /**
   * When the component is initialised we set it to load
   * page one of the accountGroup list.
   */
  public ngOnInit(): void {
    this.loadAccountGroups(1, 10000);
  }

  /**
   * Loads a list of account-groups using the given per page limit.
   *
   * @param {number} page The current page we require.
   * @param {number} limit The number of results per page.
   */
  public loadAccountGroups(page?: number, limit?: number): void {
    const listAccountGroups = this.accountGroupService
      .listAccountGroups(page, limit)
      .subscribe((accountGroups: AccountGroup[]) => {
        this.accountGroups = accountGroups;
        listAccountGroups.unsubscribe();
      });
  }
}
