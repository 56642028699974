import { UserActionTypes } from './user.actions';
import { INITIAL_USER_STATE } from './user.state';

export const userReducer = (lastState = INITIAL_USER_STATE, { type, payload }) => {
  switch (type) {
    case UserActionTypes.TOGGLE_MENU_BAR:
      return { ...lastState, menubar: !lastState.menubar };
    case UserActionTypes.SELECT_MENU_ITEM:
      return {
        ...lastState,
        menuItem: lastState.menuItem === payload ? undefined : payload,
      };
    case UserActionTypes.SET_PROFILE:
      return { ...lastState, profile: payload };
    case UserActionTypes.REMOVE_PROFILE:
      return { ...lastState, profile: undefined };
    case UserActionTypes.SET_JWT_TOKEN:
      return { ...lastState, jwtToken: payload };
    case UserActionTypes.REMOVE_JWT_TOKEN:
      return { ...lastState, jwtToken: undefined };
  }

  return lastState;
};
