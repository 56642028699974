import { Injectable } from '@angular/core';
import { Creditor } from './creditor';
import { Observable } from 'rxjs';

import { HttpRequestService } from '../shared/http/http-request.service';

@Injectable()
export class CreditorService extends HttpRequestService {
  /**
   * The endpoint that we call to get the creditor information.
   *
   * @type {string}
   */
  private readonly endpoint: string = 'creditors';

  /**
   * Brings back a list of all accessible creditors.
   *
   * @param {number} page The page number we are currently on.
   * @param {number} limit The number of creditors to display per page.
   * @returns {Observable<Creditor[]>}
   */
  public listCreditors(
    page?: number,
    limit?: number,
    filter?: string,
  ): Observable<Creditor[]> {
    return this.list(this.endpoint, page, filter ? { filter } : {}, limit);
  }

  /**
   * Finds details of the creditor from the given ID.
   *
   * @param {string} creditorId The ID of the creditor we require details for.
   * @returns {Observable<Creditor>}
   */
  public findCreditor(creditorId: string): Observable<Creditor> {
    return this.findById(this.endpoint, creditorId);
  }

  /**
   * Creates a new creditor from the given Creditor object.
   *
   * @param {Creditor} newCreditor The details of the creditor to be created.
   * @returns {Observable<Creditor | boolean>}
   */
  public createCreditor(newCreditor: Creditor): Observable<Creditor | boolean> {
    return this.create(this.endpoint, newCreditor);
  }

  /**
   * Updates a creditor with the given creditor object for the given creditor ID.
   *
   * @param {string} creditorId The ID of the creditor needing updating.
   * @param {Creditor} newDetails The new creditor object to update with.
   * @returns {Observable<Creditor | boolean>}
   */
  public updateCreditor(
    creditorId: string,
    newDetails: Creditor,
  ): Observable<Creditor | boolean> {
    return this.update(this.endpoint, creditorId, newDetails);
  }

  /**
   * Deletes a creditor based on the given creditor ID.
   *
   * @param {string} creditorId The ID of the creditor needing deleting.
   * @returns {Observable<boolean>}
   */
  public deleteCreditor(creditorId: string): Observable<boolean> {
    return this.delete(this.endpoint, creditorId);
  }
}
