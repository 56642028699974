import {Domain} from '../domains/domain';
import {Letterhead} from '../letterheads/letterhead';

export class Template {
  public id?: string;
  public name: string;
  public domain?: Domain;
  public letterhead?: Letterhead;
  public document: Document;
  public content: string;
  public quantity: number;
  public type: string;
  public templateUrl: string;

  public templateType?: string;
}
