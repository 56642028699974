import { HttpRequestService } from '../../shared/http/http-request.service';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { CaseCommunication } from '../communicate/case-communication';
import { ChartOfAccounts } from '../../financials/chart-of-accounts/chart-of-accounts';

@Injectable()
export class CaseChartOfAccountsService extends HttpRequestService {
  private readonly endpoint: string = 'cases/:id/chart-of-accounts/:chartId';

  public getChartOfAccountEntryForCase(caseId: string, chartOfAccountsId: string): Observable<ChartOfAccounts> {
    return this.list(this.endpoint, 1, undefined, 10000, {
      parameters: {id: caseId, chartId: chartOfAccountsId}
    });
  }

}
