import { Component, Input } from '@angular/core';
import { Gender } from '../gender';

@Component({
  selector: 'app-gender-form',
  templateUrl: 'form.component.html',
})
export class GenderFormComponent {
  @Input() gender: Gender;
}
