<form class="form-horizontal" (submit)="logPayment()" ngNativeValidate>
  <div class="modal-header">
    <h4 class="modal-title pull-left">Log Manual Payment</h4>
    <button
      type="button"
      class="close pull-right"
      aria-label="Close"
      (click)="modalRef.hide()"
    >
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="form-group">
      <label for="payment" class="col-sm-4 control-label">Value</label>
      <div class="col-sm-8">
        <app-shared-currency
          id="payment"
          name="payment"
          [(ngModel)]="value"
          [required]="true"
        ></app-shared-currency>
      </div>
    </div>

    <div class="form-group">
      <label for="payment" class="col-sm-4 control-label">Method</label>
      <div class="col-sm-8">
        <app-payment-type-select
          name="method"
          [model]="method"
        ></app-payment-type-select>
      </div>
    </div>

    <div class="form-group">
      <label for="paidDate" class="col-sm-4 control-label">Paid Date</label>
      <div class="col-sm-8">
        <app-shared-date
          name="paidDate"
          [(ngModel)]="paidDate"
          [required]="false"
          [allowFuture]="true"
          [allowPast]="true"
        ></app-shared-date>
      </div>
    </div>

    <div class="form-group">
      <label for="expectedDate" class="col-sm-4 control-label"
        >Related Schedule</label
      >
      <div class="col-sm-8">
        <select
          class="form-control"
          id="expectedDate"
          name="expectedDate"
          [(ngModel)]="expectedDate"
        >
          <option
            *ngFor="let item of paymentSchedule"
            value="{{ item.expectedDate }}"
          >
            {{ item.expectedDate | date: 'dd/MM/yyyy' }} -
            {{ item.expectedValue / 100 | toCurrency }} - {{ item.status }}
          </option>
        </select>
      </div>
    </div>

    <div class="form-group">
      <label for="description" class="col-sm-4 control-label"
        >Description</label
      >
      <div class="col-sm-8">
        <input
          type="text"
          class="form-control"
          name="description"
          id="description"
          placeholder="Description"
          [(ngModel)]="description"
        />
      </div>
    </div>
  </div>
  <div class="modal-footer text-right">
    <button type="submit" class="btn btn-success">Save</button>
    <button type="button" class="btn btn-default" (click)="modalRef.hide()">
      Cancel
    </button>
  </div>
</form>
