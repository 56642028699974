import { Component } from '@angular/core';
import { AssetService } from '../asset.service';
import { Asset } from '../asset';
import { ActivatedRoute, Router } from '@angular/router';
import { NotificationService } from '../../shared/notification.service';

@Component({
  selector: 'app-asset-edit',
  templateUrl: 'edit.component.html',
})
export class AssetEditComponent {
  /**
   * Details of the asset being edited.
   */
  public asset: Asset;

  /**
   * Are we currently performing a loading action?
   *
   * @type {boolean}
   */
  public isLoading: boolean = false;

  /**
   * Load up all our required services and subscribe to every observable we need.
   *
   * @param {AssetService} assetService
   * @param {NotificationService} notificationService
   * @param {Router} router
   * @param {ActivatedRoute} route
   */
  constructor(
    private assetService: AssetService,
    private notificationService: NotificationService,
    private router: Router,
    private route: ActivatedRoute,
  ) {
    this.assetService.loading.subscribe(result => (this.isLoading = result));
    this.route.params.subscribe(params => this.loadAsset(params.id));
  }

  /**
   * Perform the edit action which send the edited asset to the backend.
   */
  public edit() {
    const editAsset = this.assetService
      .updateAsset(this.asset.id, this.asset)
      .subscribe(
        (result: Asset) => {
          this.notificationService.success(
            `Asset Updated`,
            `The asset ${result.name} been updated with the specified changes.`,
            5,
          );
          this.asset = new Asset();
          editAsset.unsubscribe();
          this.router.navigate(['/assets']);
        },
        err => {
          this.notificationService.error(
            `Asset Not Found`,
            err.error.message,
            10,
          );
          this.router.navigate(['/assets']);
        },
      );
  }

  /**
   * Loads the requested asset so we can display it and let the user edit the fields.
   *
   * @param {string} id
   */
  private loadAsset(id: string) {
    this.assetService.findAsset(id).subscribe(
      result => {
        this.asset = result;
      },
      err => {
        this.notificationService.error(
          `Asset Not Found`,
          err.error.message,
          10,
        );
        this.router.navigate(['/assets']);
      },
    );
  }
}
