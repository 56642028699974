import { Vehicle } from '../../shared/vehicle/vehicle';
import { Case } from '../case';
import { CaseCreditor } from '../creditors/case-creditor';
import { Person } from '../people/person';

export class CaseVehicle extends Vehicle {
  public id?: string;
  public case?: Case;
  public owner?: string;
  public person?: Person;
  public value: number = 0;
  public note?: string;
  public createdAt?: string;
  public onHp?: boolean;
  public remainingHp?: number = 0;
  public excluded: boolean;
  public caseCreditor: CaseCreditor;
  public receivedValue?: number = 0;
}
