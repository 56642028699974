<div *ngIf="allowLookup" class="form-group clearfix">
  <label class="col-sm-{{ labelWidth }} control-label" for="postalCode"
    >Postcode</label
  >
  <div class="col-sm-{{ lbWidth(9) }}">
    <input
      [(ngModel)]="model.postalCode"
      [required]="isRequired('postalCode')"
      autocomplete="nope"
      class="form-control"
      id="postalCode"
      name="postalCode"
      placeholder="Postcode"
      type="text"
    />
  </div>
  <div class="col-sm-3">
    <button
      (click)="searchForPostcode()"
      class="btn btn-warning btn-block"
      type="button"
    >
      <span class="fa fa-search"></span>
    </button>
  </div>
</div>

<div *ngIf="postcodeResults && !chosenAddress" class="form-group clearfix">
  <label class="col-sm-{{ labelWidth }} control-label" for="address1"
    >Select</label
  >
  <div class="col-sm-{{ lbWidth(12) }}">
    <select
      (change)="setAddress(chosenAddress)"
      [(ngModel)]="chosenAddress"
      autocomplete="nope"
      class="form-control"
    >
      <option value="">-- Please select an address from this list</option>
      <option
        *ngFor="let singleResult of postcodeResults"
        [ngValue]="singleResult"
      >
        {{ singleResult.address1 }} , {{ singleResult.town }},
        {{ singleResult.county }}
      </option>
    </select>
  </div>
</div>

<div class="form-group clearfix">
  <label class="col-sm-{{ labelWidth }} control-label" for="address1"
    >Address 1</label
  >
  <div class="col-sm-{{ lbWidth(12) }}">
    <input
      [(ngModel)]="model.address1"
      [required]="isRequired('address1')"
      autocomplete="nope"
      class="form-control"
      id="address1"
      name="address1"
      placeholder="Address Line 1"
      type="text"
    />
  </div>
</div>

<div class="form-group clearfix">
  <label class="col-sm-{{ labelWidth }} control-label" for="address2"
    >Address 2</label
  >
  <div class="col-sm-{{ lbWidth(12) }}">
    <input
      [(ngModel)]="model.address2"
      [required]="isRequired('address2')"
      autocomplete="nope"
      class="form-control"
      id="address2"
      name="address2"
      placeholder="Address Line 2"
      type="text"
    />
  </div>
</div>

<div class="form-group clearfix">
  <label class="col-sm-{{ labelWidth }} control-label" for="address3"
    >Address 3</label
  >
  <div class="col-sm-{{ lbWidth(12) }}">
    <input
      [(ngModel)]="model.address3"
      [required]="isRequired('address3')"
      autocomplete="nope"
      class="form-control"
      id="address3"
      name="address3"
      placeholder="Address Line 3"
      type="text"
    />
  </div>
</div>

<div class="form-group clearfix">
  <label class="col-sm-{{ labelWidth }} control-label" for="town">Town</label>
  <div class="col-sm-{{ lbWidth(12) }}">
    <input
      [(ngModel)]="model.town"
      [required]="isRequired('town')"
      autocomplete="nope"
      class="form-control"
      id="town"
      name="town"
      placeholder="Town"
      type="text"
    />
  </div>
</div>

<div class="form-group clearfix">
  <label class="col-sm-{{ labelWidth }} control-label" for="county"
    >County</label
  >
  <div class="col-sm-{{ lbWidth(12) }}">
    <input
      [(ngModel)]="model.county"
      [required]="isRequired('county')"
      autocomplete="nope"
      class="form-control"
      id="county"
      name="county"
      placeholder="County"
      type="text"
    />
  </div>
</div>

<div *ngIf="!allowLookup" class="form-group clearfix">
  <label class="col-sm-{{ labelWidth }} control-label" for="postalCode"
    >Postcode</label
  >
  <div class="col-sm-{{ lbWidth(12) }}">
    <input
      [(ngModel)]="model.postalCode"
      [required]="isRequired('postalCode')"
      autocomplete="nope"
      class="form-control"
      id="postalCode"
      name="postalCode"
      placeholder="Postcode"
      type="text"
    />
  </div>
</div>

<div class="form-group clearfix">
  <label class="col-sm-{{ labelWidth }} control-label" for="country"
    >Country</label
  >
  <div class="col-sm-{{ lbWidth(12) }}">
    <app-country-select
      [model]="model"
      [required]="isRequired('country')"
    ></app-country-select>
  </div>
</div>

<div class="form-group clearfix">
  <label for="from" class="col-sm-4 control-label">From</label>
  <div class="col-sm-8">
    <input
      [(ngModel)]="model.from"
      [bsConfig]="{ adaptivePosition: true, dateInputFormat: 'DD/MM/YYYY' }"
      type="text"
      placeholder="Enter date moved in..."
      class="form-control"
      bsDatepicker
      [bsValue]="formatDate(model.from)"
    />
  </div>
</div>

<div class="form-group clearfix">
  <label for="till" class="col-sm-4 control-label">Till</label>
  <div class="col-sm-8">
    <input
      [(ngModel)]="model.till"
      [bsConfig]="{ adaptivePosition: true, dateInputFormat: 'DD/MM/YYYY' }"
      type="text"
      placeholder="Enter date moved out..."
      class="form-control"
      bsDatepicker
      [bsValue]="formatDate(model.till)"
    />
  </div>
</div>

<div class="form-group clearfix">
  <label class="col-sm-{{ labelWidth }} control-label" for="country"
    >Address Type</label
  >
  <div class="col-sm-{{ lbWidth(12) }}">
    <app-address-type-select
      [model]="model"
      [required]="isRequired('addressType')"
    ></app-address-type-select>
  </div>
</div>
