import { FileItem, FileUploader, ParsedResponseHeaders } from 'ng2-file-upload';
import { environment } from '../../../environments/environment';
import { EventEmitter, Output, Directive } from '@angular/core';
import { AuthService } from '../../auth/auth.service';

@Directive()
export abstract class FileUploaderAbstract {
  @Output() completed = new EventEmitter<boolean>();

  public uploader: FileUploader;
  public hasBaseDropZoneOver: boolean = false;

  public disabled: boolean = false;

  protected uploadOptions: any = {};

  constructor(
    protected readonly authService: AuthService
  ) {
    this.createUploader();
  }

  public fileOverBase(e: any): void {
    this.hasBaseDropZoneOver = e;
  }

  protected setUploadOptions(options: any) {
    this.uploadOptions = options;
    return this;
  }

  upload() {
    this.disabled = true;
    this.uploader.options.additionalParameter = this.uploadOptions;
    this.uploader.uploadAll();
  }

  public confirmCreate($event) {
    $event.preventDefault();
    this.disabled = true;
  }

  private createUploader() {
    this.uploader = new FileUploader({
      url: `${environment.endpoint}${this.postUrl}`,
      authToken: `Bearer ${this.authService.getToken()}`,
    });

    this.uploader.onBeforeUploadItem = (item) => {
      item.withCredentials = false;
    };

    this.uploader.onSuccessItem = (
      item: FileItem,
      response: string,
      status: number,
      headers: ParsedResponseHeaders,
    ) => {
      if (response) {
        this.completed.next(true);
        this.disabled = false;
        this.workCompleted(response);
      }
    };
  }

  public abstract workCompleted(response: any);

  public abstract get postUrl();

}
