import { Component } from '@angular/core';
import { DeliveryMethodService } from '../delivery-method.service';
import { DeliveryMethod } from '../delivery-method';
import { ActivatedRoute, Router } from '@angular/router';
import { NotificationService } from '../../shared/notification.service';

@Component({
  selector: 'app-delivery-method-edit',
  templateUrl: 'edit.component.html',
})
export class DeliveryMethodEditComponent {
  /**
   * Details of the deliveryMethod being edited.
   */
  public deliveryMethod: DeliveryMethod;

  /**
   * Are we currently performing a loading action?
   *
   * @type {boolean}
   */
  public isLoading: boolean = false;

  /**
   * Load up all our required services and subscribe to every observable we need.
   *
   * @param {DeliveryMethodService} deliveryMethodService
   * @param {NotificationService} notificationService
   * @param {Router} router
   * @param {ActivatedRoute} route
   */
  constructor(
    private deliveryMethodService: DeliveryMethodService,
    private notificationService: NotificationService,
    private router: Router,
    private route: ActivatedRoute,
  ) {
    this.deliveryMethodService.loading.subscribe(
      result => (this.isLoading = result),
    );
    this.route.params.subscribe(params => this.loadDeliveryMethod(params.id));
  }

  /**
   * Perform the edit action which send the edited deliveryMethod to the backend.
   */
  public edit() {
    const editDeliveryMethod = this.deliveryMethodService
      .updateDeliveryMethod(this.deliveryMethod.id, this.deliveryMethod)
      .subscribe(
        (result: DeliveryMethod) => {
          this.notificationService.success(
            `Delivery Method Updated`,
            `The delivery Method ${
              result.name
            } been updated with the specified changes.`,
            5,
          );
          this.deliveryMethod = new DeliveryMethod();
          editDeliveryMethod.unsubscribe();
          this.router.navigate(['/delivery-methods']);
        },
        err => {
          this.notificationService.error(
            `Delivery Method Not Found`,
            err.error.message,
            10,
          );
          this.router.navigate(['/delivery-methods']);
        },
      );
  }

  /**
   * Loads the requested deliveryMethod so we can display it and let the user edit the fields.
   *
   * @param {string} id
   */
  private loadDeliveryMethod(id: string) {
    this.deliveryMethodService.findDeliveryMethod(id).subscribe(
      result => {
        this.deliveryMethod = result;
      },
      err => {
        this.notificationService.error(
          `Delivery Method Not Found`,
          err.error.message,
          10,
        );
        this.router.navigate(['/delivery-methods']);
      },
    );
  }
}
