import { Injectable } from '@angular/core';
import { Introducer } from './introducer';
import { Observable } from 'rxjs';

import { HttpRequestService } from '../shared/http/http-request.service';

@Injectable()
export class IntroducerService extends HttpRequestService {
  /**
   * The endpoint that we call to get the introducer information.
   *
   * @type {string}
   */
  private readonly endpoint: string = 'introducers';

  /**
   * Brings back a list of all accessible introducers.
   *
   * @param {number} page The page number we are currently on.
   * @param {number} limit The number of introducers to display per page.
   * @returns {Observable<Introducer[]>}
   */
  public listIntroducers(
    page?: number,
    limit?: number,
  ): Observable<Introducer[]> {
    return this.list(this.endpoint, page, undefined, limit);
  }

  /**
   * Finds details of the introducer from the given ID.
   *
   * @param {string} introducerId The ID of the introducer we require details for.
   * @returns {Observable<Introducer>}
   */
  public findIntroducer(introducerId: string): Observable<Introducer> {
    return this.findById(this.endpoint, introducerId);
  }

  /**
   * Creates a new introducer from the given Introducer object.
   *
   * @param {Introducer} newIntroducer The details of the introducer to be created.
   * @returns {Observable<Introducer | boolean>}
   */
  public createIntroducer(
    newIntroducer: Introducer,
  ): Observable<Introducer | boolean> {
    return this.create(this.endpoint, newIntroducer);
  }

  /**
   * Updates a introducer with the given introducer object for the given introducer ID.
   *
   * @param {string} introducerId The ID of the introducer needing updating.
   * @param {Introducer} newDetails The new introducer object to update with.
   * @returns {Observable<Introducer | boolean>}
   */
  public updateIntroducer(
    introducerId: string,
    newDetails: Introducer,
  ): Observable<Introducer | boolean> {
    return this.update(this.endpoint, introducerId, newDetails);
  }

  /**
   * Deletes a introducer based on the given introducer ID.
   *
   * @param {string} introducerId The ID of the introducer needing deleting.
   * @returns {Observable<boolean>}
   */
  public deleteIntroducer(introducerId: string): Observable<boolean> {
    return this.delete(this.endpoint, introducerId);
  }
}
