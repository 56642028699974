import { Injectable } from '@angular/core';
import { Portfolio } from './portfolio';
import { Observable } from 'rxjs';

import { HttpRequestService } from '../shared/http/http-request.service';

@Injectable()
export class PortfolioService extends HttpRequestService {
  /**
   * The endpoint that we call to get the portfolio information.
   *
   * @type {string}
   */
  private readonly endpoint: string = 'portfolios';

  /**
   * Brings back a list of all accessible portfolios.
   *
   * @param {number} page The page number we are currently on.
   * @param {number} limit The number of portfolios to display per page.
   * @returns {Observable<Portfolio[]>}
   */
  public listPortfolios(
    page?: number,
    limit?: number,
  ): Observable<Portfolio[]> {
    return this.list(this.endpoint, page, undefined, limit);
  }

  /**
   * Finds details of the portfolio from the given ID.
   *
   * @param {string} portfolioId The ID of the portfolio we require details for.
   * @returns {Observable<Portfolio>}
   */
  public findPortfolio(portfolioId: string): Observable<Portfolio> {
    return this.findById(this.endpoint, portfolioId);
  }

  /**
   * Creates a new portfolio from the given Portfolio object.
   *
   * @param {Portfolio} newPortfolio The details of the portfolio to be created.
   * @returns {Observable<Portfolio | boolean>}
   */
  public createPortfolio(
    newPortfolio: Portfolio,
  ): Observable<Portfolio | boolean> {
    return this.create(this.endpoint, newPortfolio);
  }

  /**
   * Updates a portfolio with the given portfolio object for the given portfolio ID.
   *
   * @param {string} portfolioId The ID of the portfolio needing updating.
   * @param {Portfolio} newDetails The new portfolio object to update with.
   * @returns {Observable<Portfolio | boolean>}
   */
  public updatePortfolio(
    portfolioId: string,
    newDetails: Portfolio,
  ): Observable<Portfolio | boolean> {
    return this.update(this.endpoint, portfolioId, newDetails);
  }

  /**
   * Deletes a portfolio based on the given portfolio ID.
   *
   * @param {string} portfolioId The ID of the portfolio needing deleting.
   * @returns {Observable<boolean>}
   */
  public deletePortfolio(portfolioId: string): Observable<boolean> {
    return this.delete(this.endpoint, portfolioId);
  }
}
