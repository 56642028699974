<div class="row button-menu">
  <div class="col-md-6">
    <select
      (change)="onPageChanged(1)"
      [(ngModel)]="departmentFilter"
      [compareWith]="byId"
      class="form-control"
      name="department"
    >
      <option [ngValue]="{ id: 0 }">-- View All notes --</option>
      <option
        *ngFor="let singleDepartment of departments"
        [ngValue]="singleDepartment"
      >
        {{ singleDepartment.name }}
      </option>
    </select>
  </div>
  <div class="col-md-6 text-right">
    <button
      (click)="createModal.confirmDialog(caseId)"
      class="btn btn-success"
      href="javascript:;"
      type="button"
    >
      Add Note
    </button>
  </div>
</div>

<ul class="notes">
  <li
    *ngFor="let caseNote of caseNotes"
    [class.danger]="caseNote.danger"
    [class.pinned]="caseNote.pinned"
  >
    <app-user-icon
      *ngIf="caseNote?.user"
      [passedUser]="caseNote?.user"
      [size]="40"
      [userId]="caseNote?.user?.id"
      style="float: left"
    ></app-user-icon>

    <div [innerHTML]="caseNote?.content"></div>

    <small *ngIf="caseNote && caseNote.department">
      <strong>Department: </strong> {{ caseNote?.department?.name }}
    </small>

    <small>
      By {{ caseNote?.user?.name }},&nbsp;
      {{ caseNote?.createdAt | date: 'dd/MM/yyyy' }} at
      {{ caseNote?.createdAt | date: 'HH:mm' }},&nbsp;
      <span class="text-muted">({{ caseNote?.createdAt | timeAgo }})</span>
    </small>

    <div class="buttons text-right">
      <span *ngIf="!caseNote.pinned" class="pin">
        <a (click)="togglePin(caseNote)" href="javascript:"
          ><span class="fa fa-thumb-tack"></span> Pin Note</a
        >
      </span>

      <span *ngIf="caseNote.pinned" class="pin">
        <a (click)="togglePin(caseNote)" href="javascript:"
          ><span class="fa fa-thumb-tack"></span> Unpin Note</a
        >
      </span>

      <span
        *ngIf="authService.hasPermission('user.delete-notes')"
        class="pin"
        style="margin-left: 10px"
      >
        <a (click)="deleteModal.confirmDialog(caseNote.id)" href="javascript:"
          ><span class="fa fa-trash"></span> Delete Note</a
        >
      </span>
    </div>
  </li>
</ul>

<div class="row">
  <div class="col-md-1">&nbsp;</div>
  <div class="col-md-10 text-center">
    <app-pagination
      (pageChanged)="onPageChanged($event)"
      [currentPage]="currentPage"
      [totalPages]="totalPages"
    ></app-pagination>
  </div>
  <div class="col-md-1 text-right">
    <a [href]="downloadUrl" class="btn btn-success">
      <i class="fa fa-download"></i>
    </a>
  </div>
</div>

<app-create-modal
  #createModal
  (confirm)="create($event)"
  [itemCreated]="noteCreated"
  type="note"
>
  <quill-editor [(ngModel)]="newNote.content" name="content"></quill-editor>

  <select
    [(ngModel)]="newNote.department"
    [compareWith]="byId"
    class="form-control"
    name="department"
    style="margin-top: 10px"
  >
    <option [ngValue]="{ id: 0 }">
      -- Please select a department for this note --
    </option>
    <option
      *ngFor="let singleDepartment of departments"
      [ngValue]="singleDepartment"
    >
      {{ singleDepartment.name }}
    </option>
  </select>
</app-create-modal>

<app-delete-modal
  #deleteModal
  (confirm)="delete($event)"
  [itemDeleted]="noteDeleted"
  type="note"
></app-delete-modal>
