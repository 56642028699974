import {HttpClientModule} from '@angular/common/http';
import {NgModule} from '@angular/core';
import {FormsModule} from '@angular/forms';
import {BrowserModule} from '@angular/platform-browser';
import {ModalModule} from 'ngx-bootstrap/modal';
import {TooltipModule} from 'ngx-bootstrap/tooltip';
import {QuillModule} from 'ngx-quill';

import {SharedModule} from '../../shared/shared.module';
import {CasePersonService} from '../people/case-person.service';
import {CaseExpenditureService} from './case-expenditure.service';
import {CaseExpenditureCreateComponent} from './create/create.component';
import {CaseExpenditureEditComponent} from './edit/edit.component';
import {CaseExpenditureStraightFormComponent} from './form/form.component';
import {CaseExpenditureListComponent} from './list/list.component';

@NgModule({
  declarations: [
    CaseExpenditureStraightFormComponent,
    CaseExpenditureCreateComponent,
    CaseExpenditureEditComponent,
    CaseExpenditureListComponent,
  ],
  imports: [
    ModalModule.forRoot(),
    TooltipModule.forRoot(),
    QuillModule,
    BrowserModule,
    FormsModule,
    HttpClientModule,
    SharedModule,
  ],
  exports: [CaseExpenditureListComponent],
  providers: [CaseExpenditureService, CasePersonService],
})
export class CaseExpenditureRoutingModule {}
