import { Component, EventEmitter, Input, OnInit } from '@angular/core';
import { NotificationService } from '../../../shared/notification.service';
import { CaseVehicle } from '../case-vehicle';
import { CaseVehicleService } from '../case-vehicle.service';
import { AuthService } from '../../../auth/auth.service';

@Component({
  selector: 'app-case-vehicle-list',
  templateUrl: 'list.component.html',
  styleUrls: ['list.component.css'],
})
export class CaseVehicleListComponent implements OnInit {
  @Input() caseRefreshed: EventEmitter<string>;

  @Input() caseId: string;

  public caseVehicles: CaseVehicle[] = [];

  public isLoading: boolean = true;

  public totalPages: number = 1;

  public currentPage: number = 1;

  public totalResults: number = 0;

  public itemDeleted: EventEmitter<boolean> = new EventEmitter<boolean>();

  public vehicleCreated: EventEmitter<boolean> = new EventEmitter<boolean>();

  public newVehicle: CaseVehicle = new CaseVehicle();

  public editorOptions = {
    placeholder: 'Enter your note...',
    modules: {
      toolbar: ['bold', 'link'],
    },
  };

  constructor(
    private caseVehicleService: CaseVehicleService,
    private notificationService: NotificationService,
    public readonly authService: AuthService,
  ) {
    this.caseVehicleService.loading.subscribe(isLoading => {
      this.isLoading = isLoading;
    });
    this.caseVehicleService.totalPages.subscribe(totalPages => {
      this.totalPages = totalPages;
    });
    this.caseVehicleService.currentPage.subscribe(currentPage => {
      this.currentPage = currentPage;
    });
    this.caseVehicleService.totalResults.subscribe(totalResults => {
      this.totalResults = totalResults;
    });
  }

  public ngOnInit(): void {
    this.loadCaseVehicles(1);
    this.caseRefreshed.subscribe(id => {
      this.caseId = id;
      this.onPageChanged(1);
    });
  }

  public onPageChanged(pageNumber: number): void {
    this.loadCaseVehicles(pageNumber);
  }

  public loadCaseVehicles(page?: number, limit?: number): void {
    const listCountries = this.caseVehicleService
      .listCaseVehicles(page, limit, { parameters: { id: this.caseId } })
      .subscribe((caseVehicles: CaseVehicle[]) => {
        this.caseVehicles = caseVehicles;
        listCountries.unsubscribe();
      });
  }

  public create(formData: CaseVehicle) {
    this.caseVehicleService
      .createCaseVehicle(this.newVehicle, { parameters: { id: this.caseId } })
      .subscribe(results => {
        this.vehicleCreated.next(true);
        this.onPageChanged(1);
      });
    console.log('creating', this.newVehicle);
  }

  public delete(id: string) {
    this.caseVehicleService.deleteCaseVehicle(id).subscribe(
      deleted => {
        this.notificationService.success(
          `Vehicle Deleted`,
          `Vehicle with ID ${id} was deleted.`,
          5,
        );
        this.itemDeleted.next(true);
        this.onPageChanged(this.currentPage);
      },
      err => {
        this.notificationService.error(
          `Vehicle Not Deleted`,
          err.error.message,
          10,
        );
        this.itemDeleted.next(false);
        this.onPageChanged(this.currentPage);
      },
    );
  }
}
