import {HttpClientModule} from '@angular/common/http';
import {NgModule} from '@angular/core';
import {FormsModule} from '@angular/forms';
import {BrowserModule} from '@angular/platform-browser';
import {FileUploadModule} from 'ng2-file-upload';
import {ModalModule} from 'ngx-bootstrap/modal';
import {PopoverModule} from 'ngx-bootstrap/popover';
import {TooltipModule} from 'ngx-bootstrap/tooltip';
import {QuillModule} from 'ngx-quill';

import {SharedModule} from '../../shared/shared.module';
import {CaseDocumentApproveComponent} from './approve/approve.component';
import {CaseDocumentService} from './case-document.service';
import {CaseDocumentGeneratorRoutingModule} from './generator/case-document-generator-routing.module';
import {CaseDocumentListComponent} from './list/list.component';
import {CaseDocumentUploadComponent} from './upload/upload.component';

@NgModule({
  declarations: [
    CaseDocumentApproveComponent,
    CaseDocumentListComponent,
    CaseDocumentUploadComponent,
  ],
  imports: [
    PopoverModule.forRoot(),
    ModalModule.forRoot(),
    FileUploadModule,
    TooltipModule.forRoot(),
    QuillModule,
    BrowserModule,
    FormsModule,
    HttpClientModule,
    SharedModule,
    CaseDocumentGeneratorRoutingModule,
  ],
  exports: [CaseDocumentListComponent],
  providers: [CaseDocumentService],
})
export class CaseDocumentRoutingModule {}
