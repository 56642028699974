import { Injectable } from '@angular/core';
import { BankingAccount } from './banking-account';
import { Observable } from 'rxjs';

import { HttpRequestService } from '../../../shared/http/http-request.service';

@Injectable()
export class BankingAccountService extends HttpRequestService {
  /**
   * The endpoint that we call to get the accountType information.
   *
   * @type {string}
   */
  private readonly endpoint: string = 'banking/accounts';

  /**
   * Brings back a list of all accessible account-types.
   *
   * @param {number} page The page number we are currently on.
   * @param {number} limit The number of account-types to display per page.
   * @returns {Observable<BankingAccount[]>}
   */
  public listBankingAccounts(
    page?: number,
    limit?: number,
  ): Observable<BankingAccount[]> {
    return this.list(this.endpoint, page, undefined, limit);
  }

  /**
   * Finds details of the accountType from the given ID.
   *
   * @param {string} accountTypeId The ID of the accountType we require details for.
   * @returns {Observable<BankingAccount>}
   */
  public findBankingAccount(accountTypeId: string): Observable<BankingAccount> {
    return this.findById(this.endpoint, accountTypeId);
  }

  /**
   * Creates a new accountType from the given BankingAccount object.
   *
   * @param {BankingAccount} newBankingAccount The details of the accountType to be created.
   * @returns {Observable<BankingAccount | boolean>}
   */
  public createBankingAccount(
    newBankingAccount: BankingAccount,
  ): Observable<BankingAccount | boolean> {
    return this.create(this.endpoint, newBankingAccount);
  }

  /**
   * Updates a accountType with the given accountType object for the given accountType ID.
   *
   * @param {string} accountTypeId The ID of the accountType needing updating.
   * @param {BankingAccount} newDetails The new accountType object to update with.
   * @returns {Observable<BankingAccount | boolean>}
   */
  public updateBankingAccount(
    accountTypeId: string,
    newDetails: BankingAccount,
  ): Observable<BankingAccount | boolean> {
    return this.update(this.endpoint, accountTypeId, newDetails);
  }

  /**
   * Deletes a accountType based on the given accountType ID.
   *
   * @param {string} accountTypeId The ID of the accountType needing deleting.
   * @returns {Observable<boolean>}
   */
  public deleteBankingAccount(accountTypeId: string): Observable<boolean> {
    return this.delete(this.endpoint, accountTypeId);
  }
}
