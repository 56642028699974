import { RouterModule, Routes } from '@angular/router';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule } from '@angular/common/http';
import { StatusCategoryListComponent } from './list/list.component';
import { AuthGuard } from '../auth/auth.guard';
import { StatusCategoryService } from './status-category.service';
import { SharedModule } from '../shared/shared.module';
import { StatusCategoryCreateComponent } from './create/create.component';
import { StatusCategoryFormComponent } from './form/form.component';
import { StatusCategoryEditComponent } from './edit/edit.component';

const routes: Routes = [
  {
    path: 'status-categories',
    component: StatusCategoryListComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'status-categories/create',
    component: StatusCategoryCreateComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'status-categories/:id/edit',
    component: StatusCategoryEditComponent,
    canActivate: [AuthGuard],
  },
];

@NgModule({
  declarations: [
    StatusCategoryListComponent,
    StatusCategoryCreateComponent,
    StatusCategoryFormComponent,
    StatusCategoryEditComponent,
  ],
  imports: [
    BrowserModule,
    RouterModule,
    FormsModule,
    HttpClientModule,
    SharedModule,
    RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' }),
  ],
  exports: [RouterModule],
  providers: [AuthGuard, StatusCategoryService],
})
export class StatusCategoryRoutingModule {}
