import { Component, EventEmitter, OnInit } from '@angular/core';

import { NotificationService } from '../../shared/notification.service';
import { ComplaintStatus } from '../complaint-status';
import { ComplaintStatusService } from '../complaint-status.service';
import { AuthService } from '../../auth/auth.service';

@Component({
  selector   : 'app-complaint-status-list',
  templateUrl: 'list.component.html',
  styleUrls  : [],
})
export class ComplaintStatusListComponent implements OnInit {
  public complaintStatus: ComplaintStatus;

  /**
   * A list of all the complaint-statuses currently loaded.
   *
   * @type {ComplaintStatus[]}
   */
  public complaintStatuses: ComplaintStatus[] = [];

  /**
   * Are we currently performing a loading action?
   *
   * @type {boolean}
   */
  public isLoading: boolean = true;

  /**
   * The total number of pages in this list of complaint-statuses.
   *
   * @type {number}
   */
  public totalPages: number = 1;

  /**
   * The current page of complaint-statuses that we are viewing.
   *
   * @type {number}
   */
  public currentPage: number = 1;

  /**
   * The total number of complaint-statuses that can be viewed in the system.
   *
   * @type {number}
   */
  public totalResults: number = 0;

  /**
   * An Emitter to alert other components when a complaintStatus has been deleted.
   *
   * @type {EventEmitter<boolean>}
   */
  public complaintStatusDeleted: EventEmitter<boolean> = new EventEmitter<boolean>();

  /**
   * Load up all our required services and subscribe to every observable we need.
   *
   * @param {ComplaintStatusService} complaintStatusService
   * @param {NotificationService} notificationService
   */
  constructor(
    private complaintStatusService: ComplaintStatusService,
    private notificationService: NotificationService,
    private authService: AuthService,
  )
  {
    this.complaintStatusService.loading.subscribe(isLoading => {
      this.isLoading = isLoading;
    });
    this.complaintStatusService.totalPages.subscribe(totalPages => {
      this.totalPages = totalPages;
    });
    this.complaintStatusService.currentPage.subscribe(currentPage => {
      this.currentPage = currentPage;
    });
    this.complaintStatusService.totalResults.subscribe(totalResults => {
      this.totalResults = totalResults;
    });
  }

  /**
   * When the component is initialised we set it to load
   * page one of the complaintStatus list.
   */
  public ngOnInit(): void {
    this.loadComplaintStatuses(1);
  }

  /**
   * When we receive a page change from the pagination
   * component we load the appropriate page for the
   * complaintStatus list.
   *
   * @param {number} pageNumber
   */
  public onPageChanged(pageNumber: number): void {
    this.loadComplaintStatuses(pageNumber);
  }

  /**
   * Loads a list of complaint-statuses using the given per page limit.
   *
   * @param {number} page The current page we require.
   * @param {number} limit The number of results per page.
   */
  public loadComplaintStatuses(page?: number, limit?: number): void {
    const listComplaintStatuses = this.complaintStatusService
      .listComplaintStatuses(page, limit)
      .subscribe((complaintStatuses: ComplaintStatus[]) => {
        this.complaintStatuses = complaintStatuses;
        listComplaintStatuses.unsubscribe();
      });
  }

  /**
   * When receiving confirmation of an item being deleted
   * we call the back end with the appropriate complaintStatus ID
   * to delete the complaintStatus.
   *
   * @param {string} id
   */
  public delete(id: string) {
    this.complaintStatusService.deleteComplaintStatus(id).subscribe(
      deleted => {
        this.notificationService.success(
          `ComplaintStatus Deleted`,
          `ComplaintStatus with ID ${id} was deleted.`,
          5,
        );
        this.complaintStatusDeleted.next(true);
        this.onPageChanged(this.currentPage);
      },
      err => {
        this.notificationService.error(
          `ComplaintStatus Not Deleted`,
          err.error.message,
          10,
        );
        this.complaintStatusDeleted.next(false);
        this.onPageChanged(this.currentPage);
      },
    );
  }
}
