import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { HttpRequestService } from '../shared/http/http-request.service';
import { Case } from '../cases/case';

@Injectable()
export class ApprovalService extends HttpRequestService {
  /**
   * The endpoint that we call to get the approval information.
   *
   * @type {string}
   */
  private readonly endpoint: string = 'approvals';

  /**
   * Brings back a list of all accessible approvals.
   *
   * @param {number} page The page number we are currently on.
   * @param {number} limit The number of approvals to display per page.
   * @returns {Observable<Case[]>}
   */
  public listApprovals(page?: number, limit?: number): Observable<Case[]> {
    return this.list(this.endpoint, page, undefined, limit);
  }

  public approvePayment(caseId: string): Observable<boolean> {
    return this.update(this.endpoint, caseId, {});
  }
}
