import { Component, Input } from '@angular/core';
import { Document } from '../document';

@Component({
  selector: 'app-document-form',
  templateUrl: 'form.component.html',
})
export class DocumentFormComponent {
  @Input() document: Document;
}
