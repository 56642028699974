<div class="row button-menu">
  <div class="col-md-9">
    <button
      [outsideClick]="true"
      [popover]="filterTemplate"
      class="btn btn-xs btn-default btn-secondary"
      placement="bottom"
      popoverTitle="Filter Tasks"
      type="button"
    >
      <span class="fa fa-filter"></span>
    </button>
  </div>
  <ng-template #filterTemplate>
    <ul class="filter-list">
      <li class="row filter-row clearfix">
        <div class="form-group">
          <label class="text-capitalize col-sm-4 text-muted">
            Department
          </label>
          <div class="col-sm-8">
            <app-department-select
              [model]="filter"
              [required]="false"
              (optionChanged)="loadCaseCalendars(1)"
            ></app-department-select>
          </div>
        </div>
      </li>
      <li class="row filter-row clearfix">
        <div class="form-group">
          <label class="text-capitalize col-sm-4 text-muted">
            Actionable Only?
          </label>
          <div class="col-sm-8">
            <input
              (change)="toggleActionable()"
              [checked]="actionableOnly"
              type="checkbox"
            />
          </div>
        </div>
      </li>
      <li class="row filter-row clearfix">
        <div class="form-group">
          <label class="text-capitalize col-sm-4 text-muted">
            Statuses Only?
          </label>
          <div class="col-sm-8">
            <input
              (change)="toggleStatuses()"
              [checked]="statusesOnly"
              type="checkbox"
            />
          </div>
        </div>
      </li>
    </ul>
    <div class="clearfix text-right">
      <button (click)="resetFilters()" class="btn btn-warning btn-xs">
        Reset Filters
      </button>
    </div>
  </ng-template>

  <div class="col-md-3">
    <div class="text-right">
      <button
        (click)="create.open(caseId)"
        class="btn btn-sm btn-success"
        href="javascript:;"
        type="button"
      >
        Add Task
      </button>
    </div>
  </div>
</div>

<table class="calendars table table-striped">
  <thead>
    <tr>
      <th class="text-center" width="30"></th>
      <th class="text-center" width="40"></th>
      <th>Task</th>
      <th>Department</th>
      <th>Note</th>
      <th>Date</th>
      <th>Completed</th>
      <th width="100"></th>
    </tr>
  </thead>
  <tbody>
    <tr *ngFor="let caseCalendar of caseCalendars">
      <td
        [class.overdue]="
          caseCalendar.toAction &&
          !caseCalendar.completedAt &&
          taskOverdue(caseCalendar.actionAt)
        "
        [class.text-bold]="caseCalendar.toAction && !caseCalendar.completedAt"
        [class.text-muted]="!caseCalendar.toAction"
      >
        <span class="fa fa-{{ caseCalendar.icon }}"></span>
      </td>
      <td>
        <app-user-icon
          *ngIf="caseCalendar.user"
          [passedUser]="caseCalendar.user"
          [size]="20"
          [userId]="caseCalendar.user.id"
        ></app-user-icon>
      </td>
      <td
        [class.overdue]="
          caseCalendar.toAction &&
          !caseCalendar.completedAt &&
          taskOverdue(caseCalendar.actionAt)
        "
        [class.text-bold]="caseCalendar.toAction && !caseCalendar.completedAt"
        [class.text-muted]="!caseCalendar.toAction"
      >
        {{ caseCalendar.task.name }}
      </td>
      <td
        [class.overdue]="
          caseCalendar.toAction &&
          !caseCalendar.completedAt &&
          taskOverdue(caseCalendar.actionAt)
        "
        [class.text-bold]="caseCalendar.toAction && !caseCalendar.completedAt"
        [class.text-muted]="!caseCalendar.toAction"
      >
        {{ caseCalendar.department?.name }}
      </td>
      <td
        [class.overdue]="
          caseCalendar.toAction &&
          !caseCalendar.completedAt &&
          taskOverdue(caseCalendar.actionAt)
        "
        [class.text-bold]="caseCalendar.toAction && !caseCalendar.completedAt"
        [class.text-muted]="!caseCalendar.toAction"
      >
        {{ caseCalendar.content | stripHTML }} <br />
        <span
          class="text-muted"
          style="font-size: 0.75em"
          tooltip="{{ caseCalendar.createdAt | timeAgo }}"
          >{{ caseCalendar.createdAt | date: "dd/MM/yyyy 'at' HH:mm" }}
          {{
            caseCalendar.createdBy ? 'by ' + caseCalendar.createdBy.name : ''
          }}</span
        >
      </td>
      <td
        [class.overdue]="
          caseCalendar.toAction &&
          !caseCalendar.completedAt &&
          taskOverdue(caseCalendar.actionAt)
        "
        [class.text-bold]="caseCalendar.toAction && !caseCalendar.completedAt"
        [class.text-muted]="!caseCalendar.toAction"
        class="text-center"
      >
        <span tooltip="{{ caseCalendar.actionAt | timeAgo }}">{{
          caseCalendar.actionAt | date: "dd/MM/yyyy 'at' HH:mm"
        }}</span>
      </td>
      <td
        [class.overdue]="
          caseCalendar.toAction &&
          !caseCalendar.completedAt &&
          taskOverdue(caseCalendar.actionAt)
        "
        [class.text-bold]="caseCalendar.toAction && !caseCalendar.completedAt"
        [class.text-muted]="!caseCalendar.toAction"
        class="text-center"
      >
        <span
          *ngIf="
            caseCalendar.toAction &&
            !caseCalendar.completedAt &&
            taskOverdue(caseCalendar.actionAt)
          "
          class="label label-danger"
          >OVERDUE</span
        >
        <span
          *ngIf="caseCalendar.completedAt"
          tooltip="{{ caseCalendar.completedAt | timeAgo }}"
          >{{ caseCalendar.completedAt | date: "dd/MM/yyyy 'at' HH:mm" }}</span
        >
      </td>
      <td class="text-right">
        <div
          *ngIf="
            (caseCalendar.toAction === true ||
              authService.hasPermission('user.delete-calendar')) &&
            !caseCalendar.completedAt
          "
          class="btn-group"
        >
          <a
            (click)="edit.open(caseId, caseCalendar)"
            class="btn btn-xs btn-warning"
            type="button"
            >Edit</a
          >
          <button
            *ngIf="
              caseCalendar.toAction === true ||
              authService.hasPermission('user.delete-calendar')
            "
            aria-expanded="false"
            aria-haspopup="true"
            class="btn btn-xs btn-warning dropdown-toggle"
            data-toggle="dropdown"
            type="button"
          >
            <span
              *ngIf="
                caseCalendar.toAction === true ||
                authService.hasPermission('user.delete-calendar')
              "
              class="caret"
            ></span>
            <span
              *ngIf="
                caseCalendar.toAction === true ||
                authService.hasPermission('user.delete-calendar')
              "
              class="sr-only"
              >Toggle Dropdown</span
            >
          </button>
          <ul
            *ngIf="
              caseCalendar.toAction === true ||
              authService.hasPermission('user.delete-calendar')
            "
            class="dropdown-menu"
          >
            <li>
              <a (click)="markCompleted(caseCalendar.id)" href="javascript:;"
                >Mark Completed</a
              >
            </li>
            <li *ngIf="authService.hasPermission('user.delete-calendar')">
              <a
                (click)="deleteModal.confirmDialog(caseCalendar.id)"
                href="javascript:;"
                >Delete</a
              >
            </li>
          </ul>
        </div>
      </td>
    </tr>
  </tbody>
  <tfoot>
    <tr>
      <td class="text-center" colspan="99">
        <div class="col-md-1">&nbsp;</div>
        <div class="col-md-10">
          <app-pagination
            (pageChanged)="onPageChanged($event)"
            [currentPage]="currentPage"
            [totalPages]="totalPages"
          ></app-pagination>
        </div>
        <div class="col-md-1 text-right">
          <a [href]="downloadUrl" class="btn btn-success">
            <i class="fa fa-download"></i>
          </a>
        </div>
      </td>
    </tr>
  </tfoot>
</table>

<app-case-calendar-create
  #create
  (completed)="onPageChanged(1)"
></app-case-calendar-create>

<app-case-calendar-edit
  #edit
  (completed)="onPageChanged(1)"
></app-case-calendar-edit>

<app-delete-modal
  #deleteModal
  (confirm)="delete($event)"
  [itemDeleted]="itemDeleted"
  type="task"
></app-delete-modal>
