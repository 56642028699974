<div *ngIf="isLoading">Loading Address Types List...</div>

<select
  *ngIf="!isLoading && addressTypes"
  [compareWith]="byId"
  class="form-control"
  name="address-types"
  [(ngModel)]="model.addressType"
  [required]="required"
>
  <option [ngValue]="null">No Address Type</option>
  <option *ngFor="let addressType of addressTypes" [ngValue]="addressType">
    {{ addressType.name }}
  </option>
</select>
