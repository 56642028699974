import {Component, EventEmitter, Output, ViewChild} from '@angular/core';

import {CaseAnnual} from '../case-annual';
import {CaseAnnualService} from '../case-annual.service';

@Component({
  selector: 'app-case-annual-edit',
  templateUrl: 'edit.component.html',
})
export class CaseAnnualEditComponent {
  @ViewChild('staticModal') staticModal;

  @Output() completed = new EventEmitter<boolean>();

  public disabled: boolean = false;

  public model: CaseAnnual;

  private givenCaseId: string;

  constructor(private readonly caseAnnualService: CaseAnnualService) {}

  public get caseId(): string {
    return this.givenCaseId;
  }

  public open(caseId: string, model: CaseAnnual): void {
    this.givenCaseId = caseId;
    this.model = model;
    this.staticModal.show();
  }

  public close() {
    this.workCompleted();
  }

  public confirmEdit($event) {
    $event.preventDefault();
    this.disabled = true;
    this.model.additionalClientObligations = JSON.stringify(
      this.model.additionalClientObligations,
    );
    this.caseAnnualService
      .updateCaseAnnual(this.model.id, this.model, {
        parameters: { id: this.caseId },
      })
      .subscribe(() => {
        this.workCompleted();
      });
  }

  private workCompleted() {
    this.model = undefined;
    this.disabled = false;
    this.staticModal.hide();
    this.completed.next(true);
  }
}
