<div class="row">
  <div class="col-sm-12">
    <section class="panel">
        <app-page-title [loading]="false" title="Create Employment Status"></app-page-title>

      <div class="panel-body">
          <form (ngSubmit)="create()" class="form-horizontal" ngNativeValidate>
              <app-employment-status-form *ngIf="employmentStatus"
                                          [employmentStatus]="employmentStatus"></app-employment-status-form>
        </form>
      </div>
    </section>
  </div>
</div>


