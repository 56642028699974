import { EventEmitter, Input, Output, Directive } from '@angular/core';

@Directive()
export class SelectComparisons {
  @Input() public noStyle: boolean = false;

  @Output() public optionChanged: EventEmitter<any> = new EventEmitter<any>();

  public byId(item1, item2): boolean {
    if (item1 === null && item2 === null) {
      return true;
    }

    return item1 && item2 && item1.id === item2.id;
  }

  public byCaseCreditorId(item1, item2): boolean {
    if (item1 === null && item2 === null) {
      return true;
    }

    return item1 && item2 && item1.id === item2.id;
  }
}
