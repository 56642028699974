import EditAddressType, {
  EditAddressTypeProps,
} from '../../../chief-react/src/App/Pages/AddressTypes/AddressTypeEdit';
import {
  ElementRefWrapper,
  LinkProps,
  ReactWrapper,
  RouterWrapper,
} from 'react-inside-angular';

import { Component, ElementRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ComponentClass, FunctionComponent } from 'react';
import { AuthService } from '../auth/auth.service';

@Component({
  selector: 'app-address-types-edit',
  template: '<div [id]="rootId"></div>',
})
export class AddressTypeEditComponent extends ReactWrapper<EditAddressTypeProps> {
  protected router: RouterWrapper;
  protected elemRef: ElementRefWrapper;

  constructor(
    router: Router,
    elemRef: ElementRef,
    protected route: ActivatedRoute,
    protected readonly authService: AuthService,
  ) {
    super();
    this.router = (router as unknown) as RouterWrapper;
    this.elemRef = (elemRef as unknown) as ElementRefWrapper;

    this.route.params.subscribe((params) => {
      this.id = params.id;
    });
  }

  private id: string;

  // Transfer any props from Angular to the React component
  public setProps(): EditAddressTypeProps {
    return {
      id: this.id,
      authService: this.authService
    };
  }

  // Define the React component being wrapped
  public setComponent():
    | string
    | FunctionComponent<LinkProps<EditAddressTypeProps>>
    | ComponentClass<LinkProps<EditAddressTypeProps>, any> {
    return EditAddressType;
  }
}
