import {HttpClientModule} from '@angular/common/http';
import {NgModule} from '@angular/core';
import {FormsModule} from '@angular/forms';
import {BrowserModule} from '@angular/platform-browser';
import {ModalModule} from 'ngx-bootstrap/modal';

import {SharedModule} from '../../shared/shared.module';
import {CaseModificationRoutingModule} from '../modifications/modification-routing.module';
import {FeeComparisonService} from './fee-comparison.service';
import {CaseFeeComparisonFormComponent} from './form/form.component';
import {CaseFeeComparisonScheduleComponent} from './form/schedule/schedule.component';

@NgModule({
  declarations: [
    CaseFeeComparisonFormComponent,
    CaseFeeComparisonScheduleComponent,
  ],
  imports: [
    ModalModule.forRoot(),
    BrowserModule,
    FormsModule,
    HttpClientModule,
    SharedModule,
    CaseModificationRoutingModule,
  ],
  exports: [CaseFeeComparisonFormComponent, CaseFeeComparisonFormComponent],
  providers: [FeeComparisonService],
})
export class CaseFeeComparisonRoutingModule {}
