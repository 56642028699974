<div
  class="modal fade"
  bsModal
  #staticModal="bs-modal"
  [config]="{ backdrop: 'static' }"
  tabindex="-1"
  role="dialog"
  aria-labelledby="mySmallModalLabel"
  aria-hidden="true"
>
  <div class="modal-dialog modal-lg">
    <form
      (submit)="confirmApprove($event)"
      #completedForm="ngForm"
      ngNativeValidate
    >
      <div class="modal-content panel-warning">
        <div class="modal-header panel-heading">
          <h4 class="modal-title pull-left">Approve Document</h4>
          <button
            type="button"
            class="close pull-right"
            aria-label="Close"
            (click)="close()"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          Please view the following document. Once you are happy with the
          content click the approve button.

          <div
            class="document-holder"
            *ngIf="model?.url"
            style="margin-top: 20px"
          >
            <iframe
              [src]="url | safe"
              width="100%"
              height="475"
              type="application/pdf"
              frameborder="0"
            ></iframe>
          </div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-default" (click)="close()">
            Cancel
          </button>
          <button type="submit" class="btn btn-success" [disabled]="disabled">
            Approve
          </button>
        </div>
      </div>
    </form>
  </div>
</div>
