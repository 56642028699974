import {Component, Input, OnInit} from '@angular/core';
import * as moment from 'moment';

import {Case} from '../../cases/case';
import {HttpRequestService} from '../http/http-request.service';
import {Dependant} from './dependant';

@Component({
  selector: 'app-shared-dependant-list',
  templateUrl: 'dependant-list.component.html',
})
export class DependantListComponent extends HttpRequestService
  implements OnInit {
  @Input() case: Case;
  @Input() direction: string = 'horizontal';

  public newDependant: any;

  public ngOnInit(): void {
    this.orderByAges();

    this.newDependant = {
    case: this.case,
    dateOfBirth: '',
  };
  }

  public calculateAge(dateOfBirth: string): number {
    return moment().diff(dateOfBirth, 'years', false);
  }

  public orderByAges() {
    this.case.dependants.sort((a: Dependant, b: Dependant): number => {
      const ageA = this.calculateAge(a.dateOfBirth);
      const ageB = this.calculateAge(b.dateOfBirth);

      if (ageA < ageB) {
        return -1;
      }
      if (ageA > ageB) {
        return 1;
      }
      return 0;
    });
  }

  public deleteDependant(item: Dependant) {
    const deleteDependant = this.delete(
      `cases/${this.case.id}/dependants`,
      item.id,
    ).subscribe(result => {
      let foundDependant: number = undefined;

      this.case.dependants.forEach((singleItem, i) => {
        if (singleItem.id === item.id) {
          foundDependant = i;
        }
      });

      if (foundDependant !== undefined) {
        this.case.dependants.splice(foundDependant, 1);
      }

      this.orderByAges();
      deleteDependant.unsubscribe();
    });
  }

  public addDependant() {
    const createDependant = this.create(
      `cases/${this.case.id}/dependants`,
      this.newDependant,
    ).subscribe((result: Dependant) => {
      this.case.dependants.push(result);
      this.orderByAges();
      createDependant.unsubscribe();
    });
  }
}
