<div class="row">
    <div class="col-sm-12">
        <section class="panel">
            <app-page-title [loading]="isLoading" title="Edit Status"></app-page-title>

            <div class="panel-body">
                <form (ngSubmit)="edit()" class="form-horizontal" ngNativeValidate>
                    <app-creditor-status-form *ngIf="status" [status]="status"></app-creditor-status-form>
                </form>
            </div>
        </section>
    </div>
</div>
