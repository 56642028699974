import { Component, OnDestroy, OnInit } from '@angular/core';
import { EmploymentStatus } from '../employment-status';
import { ActivatedRoute, Router } from '@angular/router';
import { NgRedux, select } from '@angular-redux/store';
import { Observable } from 'rxjs/Observable';
import { ReduxStore } from '../../common/redux.store';
import { EmploymentStatusActions } from '../../common/settings/employment-status/employment-status.actions';

@Component({
  selector: 'app-employment-status-edit',
  templateUrl: 'edit.component.html',
})
export class EmploymentStatusEditComponent implements OnInit, OnDestroy {
  @select(['employmentStatuses', 'selected']) public employmentStatus$: Observable<EmploymentStatus>;

  constructor(
    private readonly route: ActivatedRoute,
    private readonly router: Router,
    private readonly ngRedux: NgRedux<ReduxStore>,
    private readonly actions: EmploymentStatusActions,
  ) {
  }

  ngOnInit(): void {
    this.route.paramMap.subscribe(params => {
      this.ngRedux.dispatch<any>(this.actions.get(params.get('id')));
    });
  }

  public async edit(observableItem: Observable<EmploymentStatus>) {
    observableItem.subscribe(item => {
      this.ngRedux.dispatch<any>(this.actions.update(item.id, item));
      this.router.navigate(['/employment-statuses']);
    });
  }

  ngOnDestroy(): void {
    this.ngRedux.dispatch(this.actions.clearOne());
  }

}
