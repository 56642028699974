import {HttpClientModule} from '@angular/common/http';
import {NgModule} from '@angular/core';
import {FormsModule} from '@angular/forms';
import {BrowserModule} from '@angular/platform-browser';
import {ModalModule} from 'ngx-bootstrap/modal';
import {PopoverModule} from 'ngx-bootstrap/popover';
import {TooltipModule} from 'ngx-bootstrap/tooltip';

import {SharedModule} from '../../shared/shared.module';
import {CaseCommunicateService} from './case-communicate.service';
import {CaseCommunicationListComponent} from './list/list.component';

@NgModule({
  declarations: [CaseCommunicationListComponent],
  imports: [
    PopoverModule.forRoot(),
    ModalModule.forRoot(),
    TooltipModule.forRoot(),
    BrowserModule,
    FormsModule,
    HttpClientModule,
    SharedModule,
  ],
  exports: [CaseCommunicationListComponent],
  providers: [CaseCommunicateService],
})
export class CaseCommunicateRoutingModule {}
