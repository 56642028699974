import {Component, Input, OnInit} from '@angular/core';
import {TypeaheadMatch} from 'ngx-bootstrap/typeahead';
import {Observable} from 'rxjs';

import {NotificationService} from '../../shared/notification.service';
import {SelectComparisons} from '../../shared/pipes/select.comparisons';
import {Occupation} from '../occupation';
import {OccupationService} from '../occupation.service';

@Component({
  selector: 'app-occupation-select',
  templateUrl: 'select.component.html',
})
export class OccupationSelectComponent extends SelectComparisons
  implements OnInit {
  /**
   * The model that we will be adding the occupation to.
   */
  @Input() model: any;

  /**
   * Do we require this field in the main form it is
   * included in?
   */
  @Input() required: boolean;

  /**
   * A list of all the occupations currently loaded.
   *
   * @type {Occupation[]}
   */
  public occupations: Occupation[] = [];

  /**
   * Are we currently performing a loading action?
   *
   * @type {boolean}
   */
  public isLoading: boolean = true;

  selectedValue: string;
  dataSource: Observable<any>;
  asyncSelected: string;
  typeaheadNoResults: boolean;
  typeaheadLoading: boolean;

  /**
   * Load up all our required services and subscribe to every observable we need.
   *
   * @param {OccupationService} occupationService
   * @param {NotificationService} notificationService
   */
  constructor(
    private occupationService: OccupationService,
    private notificationService: NotificationService,
  ) {
    super();

    this.dataSource = Observable.create((observer: any) => {
      observer.next(this.selectedValue);
    }).mergeMap((token: string) => this.searchOccupations(token));

    this.occupationService.loading.subscribe(isLoading => {
      this.isLoading = isLoading;
    });
  }

  /**
   * When the component is initialised we set it to load
   * page one of the occupation list.
   */
  public ngOnInit(): void {
    if (this.model && this.model.occupation && this.model.occupation.name) {
      this.selectedValue = this.model.occupation.name;
    }
  }

  /**
   * Loads a list of occupations using the given per page limit.
   *
   * @param {number} page The current page we require.
   * @param {number} limit The number of results per page.
   */
  public searchOccupations(filter: string): Observable<Occupation[]> {
    return this.occupationService.listOccupations(1, 10, filter);
  }

  changeTypeaheadLoading(e: boolean): void {
    this.typeaheadLoading = e;
  }

  changeTypeaheadNoResults(e: boolean): void {
    this.model.occupation = {
      name: this.selectedValue,
    };
  }

  typeaheadOnSelect(e: TypeaheadMatch): void {
    this.model.occupation = e.item;
    this.selectedValue = this.model.occupation.name;
  }

  typeaheadOnBlur(e: TypeaheadMatch): void {
    this.model.occupation = e.item;
    this.selectedValue = this.model.occupation.name;
  }
}
