<div class="row">
  <div class="col-sm-12">
    <section class="panel">
      <app-page-title title="Edit ExpenditureCategory" [loading]="isLoading"></app-page-title>

      <div class="panel-body">
        <form class="form-horizontal" (ngSubmit)="edit()" ngNativeValidate>
          <app-expenditure-category-form [expenditureCategory]="expenditureCategory" *ngIf="expenditureCategory"></app-expenditure-category-form>
        </form>
      </div>
    </section>
  </div>
</div>
