import { Component, EventEmitter, Output, ViewChild } from '@angular/core';
import { FileItem, FileUploader, ParsedResponseHeaders } from 'ng2-file-upload';

import { AuthService } from '../../../../auth/auth.service';
import { BankingAccountService } from '../banking-account.service';
import { HttpClient } from '@angular/common/http';
import { NotificationService } from '../../../../shared/notification.service';
import { environment } from '../../../../../environments/environment';

@Component({
  selector: 'app-client-interest-upload',
  templateUrl: 'upload.component.html',
})
export class ClientInterestUploadComponent {
  @ViewChild('staticModal') staticModal;

  @Output() completed = new EventEmitter<boolean>();

  public uploader: FileUploader;
  public hasBaseDropZoneOver = false;
  public hasBaseDropZoneOverCsv = false;

  public disabled = false;
  public loading = false;

  constructor(
    private readonly authService: AuthService,
    private readonly notificationService: NotificationService,
  ) {
    this.createUploader();
  }

  public fileOverBase(e: any): void {
    this.hasBaseDropZoneOver = e;
  }

  public fileOverBaseCsv(e: any): void {
    this.hasBaseDropZoneOverCsv = e;
  }

  upload() {
    this.disabled = true;
    this.loading = true;
    this.uploader.uploadAll();
  }

  public open(): void {
    this.staticModal.show();
  }

  public close() {
    this.workCompleted();
  }

  public confirmCreate($event) {
    $event.preventDefault();
    this.disabled = true;
  }

  private createUploader() {
    this.uploader = new FileUploader({
      url: `${environment.endpoint}banking/client-interest/upload`,
      authToken: `Bearer ${this.authService.getToken()}`,
    });

    this.uploader.onBeforeUploadItem = (item) => {
      item.withCredentials = false;
    };

    this.uploader.onSuccessItem = (
      item: FileItem,
      response: string,
      status: number,
      headers: ParsedResponseHeaders,
    ) => {
      if (response) {
        this.notificationService.success(
          'Success.',
          'Interest file has been successfully uploaded and processed.',
          10,
        );
      }
      this.loading = false;
      this.workCompleted();
    };

    this.uploader.onErrorItem = (
      item: FileItem,
      response: string,
      status: number,
      headers: ParsedResponseHeaders,
    ) => {
      this.loading = false;
      this.notificationService.error(
        'Error.',
        'An error occurred while processing the file.',
        10,
      );
    };
  }

  private workCompleted() {
    this.createUploader();
    this.disabled = false;
    this.staticModal.hide();
    this.completed.next(true);
  }
}
