import { AccountGroup } from '../../settings/account-groups/account-group';
import { AccountType } from '../../settings/account-types/account-type';
import { Domain } from '../../domains/domain';
import { FinancialAccount } from '../accounts/accounts';
import { Supplier } from '../../settings/suppliers/supplier';

export class ChartOfAccounts {
  public id?: string;
  public domain?: Domain;
  public accountGroup?: AccountGroup;
  public accountType?: AccountType;
  public supplier?: Supplier;
  public name: string;
  public code: string;
  public description?: string;
  public includedInComparison: boolean;
  public vatPayable: number;
  public vatReconcilable: number;
  public financialAccount: FinancialAccount;
  public appreciating: boolean;
  public appreciationType: number;
  public estimated: boolean;
  public invoiceRequired: boolean;
}
