<div>
  <header *ngIf="authService.authenticated" class="header fixed-top clearfix">
    <div class="brand">
      <a class="logo" href="index.html">
        <img alt="" src="/assets/images/logo.png" />
      </a>
      <div (click)="toggleSidebar()" class="sidebar-toggle-box">
        <div class="fa fa-bars"></div>
      </div>
    </div>

    <div class="nav notify-row" id="top_menu">
      <ul class="nav top-menu">
        <li class="dropdown">
          <a
            [outsideClick]="true"
            [popover]="chiefUpdatesTemplate"
            class="dropdown-toggle"
            container="body"
            data-toggle="dropdown"
            href="#"
            placement="bottom"
            popoverTitle="Chief Updates"
          >
            <i class="fa fa-info-circle"></i>
          </a>

          <ng-template #chiefUpdatesTemplate>
            <app-chief-updates
              [currentUpdates]="chiefUpdates"
            ></app-chief-updates>
          </ng-template>
        </li>

        <li class="dropdown">
          <a
            [outsideClick]="true"
            [popover]="taskListTemplate"
            class="dropdown-toggle"
            container="body"
            data-toggle="dropdown"
            href="#"
            placement="bottom"
            popoverTitle="Task List"
          >
            <i class="fa fa-calendar"></i>
          </a>

          <ng-template #taskListTemplate>
            <app-user-tasks
              [isPopover]="true"
              [showUser]="false"
            ></app-user-tasks>
          </ng-template>
        </li>

        <li>
          <app-bank-match-status></app-bank-match-status>
        </li>

        <li>
          <app-allocation-status></app-allocation-status>
        </li>
      </ul>
    </div>

    <div class="top-nav clearfix">
      <ul class="nav pull-right top-menu">
        <li>
          <app-search></app-search>
        </li>
        <li class="dropdown">
          <a class="dropdown-toggle icon-user" data-toggle="dropdown" href="#">
            <div class="tw-flex tw-items-center">
              <img alt="" src="{{ (userProfile$ | async)?.avatar }}" />
              <div class="tw-pt-3">
                <span class="username">{{ (userProfile$ | async)?.name }}</span>
                <b class="caret"></b>
              </div>
            </div>
          </a>
          <ul class="dropdown-menu extended logout">
            <li>
              <a href="#"><i class="fa fa-suitcase"></i>Profile</a>
            </li>
            <li>
              <a href="#"><i class="fa fa-cog"></i> Settings</a>
            </li>
            <li>
              <a [routerLink]="['/auth/logout']"
                ><i class="fa fa-key"></i> Log Out</a
              >
            </li>
          </ul>
        </li>
      </ul>
    </div>
  </header>

  <aside *ngIf="authService.authenticated">
    <div
      [class.hide-left-bar]="!(menubar$ | async)"
      class="nav-collapse"
      id="sidebar"
      style="height: 100%; overflow-y: auto"
    >
      <div class="leftside-navigation">
        <ul class="sidebar-menu" id="nav-accordion">
          <li>
            <a [routerLink]="['/dashboard']">
              <i class="fa fa-dashboard"></i>
              <span>Dashboard</span>
            </a>
          </li>

          <li>
            <a [routerLink]="['/cases']">
              <i class="fa fa-briefcase"></i>
              <span>Cases</span>
            </a>
          </li>

          <li
            *ngIf="authService.hasPermission('reports.*')"
            [class.active]="(menuItem$ | async) === 'reports'"
            class="sub-menu"
            style="cursor: pointer"
          >
            <a (click)="selectedMenu = 'reports'">
              <i class="fa fa-bars"></i>
              <span>Reports</span>
            </a>
            <ul [class.hidden]="(menuItem$ | async) != 'reports'" class="sub">
              <li [routerLinkActive]="['active']">
                <a [routerLink]="['/reports/viewer']">Report Viewer</a>
              </li>

              <li
                *ngIf="authService.hasPermission('reports.annuals-due')"
                [routerLinkActive]="['active']"
              >
                <a [routerLink]="['/reports/wth-dep-statements']"
                  >WTH / DEP Statements</a
                >
              </li>

              <li
                *ngIf="authService.hasPermission('reports.annuals-due')"
                [routerLinkActive]="['active']"
              >
                <a [routerLink]="['/reports/annuals-due']">Annuals Due</a>
              </li>
              <li
                *ngIf="authService.hasPermission('reports.customer-payments')"
                [routerLinkActive]="['active']"
              >
                <a
                  href="{{ apiUrl }}reports/monthly-payments?domainId={{
                    authService?.domain?.id
                  }}&download=true&auth={{ authService?.getToken() }}"
                  target="_blank"
                  >Customer Payments</a
                >
              </li>
              <li
                *ngIf="authService.hasPermission('reports.wip')"
                [routerLinkActive]="['active']"
              >
                <a [routerLink]="['/reports/wip']">WIP</a>
              </li>
              <li
                *ngIf="
                  authService.hasPermission('reports.unapproved-creditors')
                "
                [routerLinkActive]="['active']"
              >
                <a [routerLink]="['/reports/unapproved-creditors']"
                  >Unapproved Creditors</a
                >
              </li>
              <li
                *ngIf="authService.hasPermission('reports.client-by-creditor')"
                [routerLinkActive]="['active']"
              >
                <a [routerLink]="['/reports/clients-by-creditor']"
                  >Clients By Creditor</a
                >
              </li>

              <li
                *ngIf="
                  authService.hasPermission('reports.receipts-and-payments')
                "
                [routerLinkActive]="['active']"
              >
                <a
                  href="{{ apiUrl }}reports/receipts-and-payments?domainId={{
                    authService?.domain?.id
                  }}&download=true&auth={{ authService?.getToken() }}"
                  target="_blank"
                  >Receipts and Payments</a
                >
              </li>

              <li
                *ngIf="authService.hasPermission('reports.completion-status')"
                [routerLinkActive]="['active']"
              >
                <a
                  href="{{ apiUrl }}reports/completion-status?domainId={{
                    authService?.domain?.id
                  }}&download=true&auth={{ authService?.getToken() }}"
                  target="_blank"
                  >Completion Status</a
                >
              </li>

              <li
                *ngIf="
                  authService.hasPermission('reports.missing-cat1-invoices')
                "
                [routerLinkActive]="['active']"
              >
                <a
                  href="{{ apiUrl }}reports/missing-cat1-invoices?domainId={{
                    authService?.domain?.id
                  }}&download=true&auth={{ authService?.getToken() }}"
                  target="_blank"
                  >Missing CAT1 Invoices</a
                >
              </li>

              <li
                *ngIf="authService.hasPermission('reports.payment-deficit')"
                [routerLinkActive]="['active']"
              >
                <a
                  href="{{ apiUrl }}reports/payment-deficit?domainId={{
                    authService?.domain?.id
                  }}&download=true&auth={{ authService?.getToken() }}"
                  target="_blank"
                  >Payment Deficit</a
                >
              </li>

              <li
                *ngIf="authService.hasPermission('reports.monthly-payments')"
                [routerLinkActive]="['active']"
              >
                <a
                  href="{{ apiUrl }}reports/monthly-payments?domainId={{
                    authService?.domain?.id
                  }}&download=true&auth={{ authService?.getToken() }}"
                  target="_blank"
                  >Monthly Payments</a
                >
              </li>

              <li
                *ngIf="authService.hasPermission('reports.cms-accounts')"
                [routerLinkActive]="['active']"
              >
                <a
                  href="{{ apiUrl }}reports/cms-accounts?domainId={{
                    authService?.domain?.id
                  }}&download=true&auth={{ authService?.getToken() }}"
                  target="_blank"
                  >CMS Accounts</a
                >
              </li>

              <li
                *ngIf="
                  authService.hasPermission(
                    'reports.cms-withdrawals-and-deposits'
                  )
                "
                [routerLinkActive]="['active']"
              >
                <a
                  href="{{
                    apiUrl
                  }}reports/cms-withdrawals-and-deposits?domainId={{
                    authService?.domain?.id
                  }}&download=true&auth={{ authService?.getToken() }}"
                  target="_blank"
                  >CMS WTH / DEP</a
                >
              </li>

              <li
                *ngIf="
                  authService.hasPermission(
                    'reports.cms-withdrawals-and-deposits'
                  )
                "
                [routerLinkActive]="['active']"
              >
                <a
                  href="{{ apiUrl }}reports/cms-summary?domainId={{
                    authService?.domain?.id
                  }}&download=true&auth={{ authService?.getToken() }}"
                  target="_blank"
                  >CMS Summary</a
                >
              </li>

              <li
                *ngIf="authService.hasPermission('reports.global-soa-report')"
                [routerLinkActive]="['active']"
              >
                <a
                  href="{{
                    apiUrl
                  }}reports/global-customer-transactions?domainId={{
                    authService?.domain?.id
                  }}&csv=true&download=true&auth={{ authService?.getToken() }}"
                  target="_blank"
                  >Detailed Transaction Report</a
                >
              </li>

              <li
                *ngIf="authService.hasPermission('reports.modifications')"
                [routerLinkActive]="['active']"
              >
                <a
                  href="{{ apiUrl }}reports/modifications?domainId={{
                    authService?.domain?.id
                  }}&download=true&auth={{ authService?.getToken() }}"
                  target="_blank"
                  >Modifications</a
                >
              </li>

              <li
                *ngIf="authService.hasPermission('reports.tasks')"
                [routerLinkActive]="['active']"
              >
                <a
                  href="{{ apiUrl }}reports/task-report?domainId={{
                    authService?.domain?.id
                  }}&download=true&auth={{ authService?.getToken() }}"
                  target="_blank"
                  >All Tasks</a
                >
              </li>

              <li
                *ngIf="authService.hasPermission('reports.tasks')"
                [routerLinkActive]="['active']"
              >
                <a
                  href="{{ apiUrl }}reports/task-report?overdue=true&domainId={{
                    authService?.domain?.id
                  }}&download=true&auth={{ authService?.getToken() }}"
                  target="_blank"
                  >Overdue Tasks</a
                >
              </li>

              <li
                *ngIf="authService.hasPermission('reports.complaint-register')"
                [routerLinkActive]="['active']"
              >
                <a
                  href="{{ apiUrl }}reports/complaint-register?domainId={{
                    authService?.domain?.id
                  }}&download=true&auth={{ authService?.getToken() }}"
                  target="_blank"
                  >Complaint Register</a
                >
              </li>

              <li [routerLinkActive]="['active']">
                <a
                  href="{{ apiUrl }}reports/case-creditor-list?domainId={{
                    authService?.domain?.id
                  }}&download=true&auth={{ authService?.getToken() }}"
                  target="_blank"
                  >Case Creditor List</a
                >
              </li>

              <li [routerLinkActive]="['active']">
                <a
                  href="{{ apiUrl }}reports/retained-allocations?domainId={{
                    authService?.domain?.id
                  }}&download=true&auth={{ authService?.getToken() }}"
                  target="_blank"
                  >Retained Allocations</a
                >
              </li>

              <li [routerLinkActive]="['active']">
                <a
                  href="{{ apiUrl }}reports/bonding?domainId={{
                    authService?.domain?.id
                  }}&download=true&auth={{ authService?.getToken() }}"
                  target="_blank"
                  >Bonding Report (Month)</a
                >
              </li>
            </ul>
          </li>

          <li
            *ngIf="authService.hasPermission('financials.*')"
            [class.active]="(menuItem$ | async) === 'financials'"
            class="sub-menu"
            style="cursor: pointer"
          >
            <a (click)="selectedMenu = 'financials'">
              <i class="fa fa-gbp"></i>
              <span>Financials</span>
            </a>
            <ul
              [class.hidden]="(menuItem$ | async) != 'financials'"
              class="sub"
            >
              <li
                *ngIf="authService.hasPermission('financials.distributions')"
                [routerLinkActive]="['active']"
              >
                <a [routerLink]="['/financials/distributions']"
                  >Allocate Client Funds</a
                >
              </li>
              <li
                *ngIf="authService.hasPermission('financials.approve-payments')"
                [routerLinkActive]="['active']"
              >
                <a [routerLink]="['/approvals']">Approve Payments</a>
              </li>
              <li
                *ngIf="authService.hasPermission('financials.banking')"
                [routerLinkActive]="['active']"
              >
                <a [routerLink]="['/banking/accounts']">Bank Accounts</a>
              </li>
              <li
                *ngIf="authService.hasPermission('financials.banking')"
                [routerLinkActive]="['active']"
              >
                <a [routerLink]="['/financials/invoicing']">Invoicing</a>
              </li>
              <li
                *ngIf="
                  authService.hasPermission('financials.chart-of-accounts')
                "
                [routerLinkActive]="['active']"
              >
                <a [routerLink]="['/chart-of-accounts']">Chart of Accounts</a>
              </li>
            </ul>
          </li>

          <li
            *ngIf="authService.hasPermission('reports.paperless-office')"
            [class.active]="(menuItem$ | async) === 'paperless-office'"
            class="sub-menu"
            style="cursor: pointer"
          >
            <a (click)="selectedMenu = 'paperless-office'">
              <i class="fa fa-paperclip"></i>
              <span>Paperless Office</span>
            </a>
            <ul
              [class.hidden]="(menuItem$ | async) != 'paperless-office'"
              class="sub"
            >
              <li [routerLinkActive]="['active']">
                <a [routerLink]="['/paperless-office/outbound']"
                  >Outbound Mail</a
                >
              </li>
              <li [routerLinkActive]="['active']">
                <a [routerLink]="['/paperless-office/inbound']">Inbound Mail</a>
              </li>
            </ul>
          </li>

          <li
            *ngIf="authService.hasPermission('settings.*')"
            [class.active]="(menuItem$ | async) === 'settings'"
            class="sub-menu"
            style="cursor: pointer"
          >
            <a (click)="selectedMenu = 'settings'">
              <i class="fa fa-sliders"></i>
              <span>Settings</span>
            </a>
            <ul [class.hidden]="(menuItem$ | async) != 'settings'" class="sub">
              <li
                *ngIf="authService.hasPermission('settings.users')"
                [routerLinkActive]="['active']"
              >
                <a [routerLink]="['/users']">Users</a>
              </li>
              <li
                *ngIf="authService.hasPermission('settings.templates')"
                [routerLinkActive]="['active']"
              >
                <a [routerLink]="['/templates']">Templates</a>
              </li>
              <li
                *ngIf="authService.hasPermission('settings.templates')"
                [routerLinkActive]="['active']"
              >
                <a [routerLink]="['/packs']">Packs</a>
              </li>
              <li
                *ngIf="authService.hasPermission('settings.letterheads')"
                [routerLinkActive]="['active']"
              >
                <a [routerLink]="['/letterheads']">Letterheads</a>
              </li>
              <li
                *ngIf="authService.hasPermission('settings.introducers')"
                [routerLinkActive]="['active']"
              >
                <a [routerLink]="['/introducers']">Introducers</a>
              </li>
              <li
                *ngIf="authService.hasPermission('settings.statuses')"
                [routerLinkActive]="['active']"
              >
                <a [routerLink]="['/statuses']">Statuses</a>
              </li>
              <li
                *ngIf="authService.hasPermission('settings.substatuses')"
                [routerLinkActive]="['active']"
              >
                <a [routerLink]="['/substatuses']">Sub-Statuses</a>
              </li>
              <li
                *ngIf="authService.hasPermission('settings.statuses')"
                [routerLinkActive]="['active']"
              >
                <a [routerLink]="['/creditor-statuses']">Debt Statuses</a>
              </li>
              <li
                *ngIf="authService.hasPermission('settings.departments')"
                [routerLinkActive]="['active']"
              >
                <a [routerLink]="['/departments']">Departments</a>
              </li>
              <li
                *ngIf="authService.hasPermission('settings.complaint-statuses')"
                [routerLinkActive]="['active']"
              >
                <a [routerLink]="['/complaint-statuses']">Complaint Statuses</a>
              </li>
              <li
                *ngIf="authService.hasPermission('settings.documents')"
                [routerLinkActive]="['active']"
              >
                <a [routerLink]="['/documents']">Documents</a>
              </li>
              <li
                *ngIf="authService.hasPermission('settings.folders')"
                [routerLinkActive]="['active']"
              >
                <a [routerLink]="['/folders']">Folders</a>
              </li>
              <li
                *ngIf="authService.hasPermission('settings.tasks')"
                [routerLinkActive]="['active']"
              >
                <a [routerLink]="['/tasks']">Tasks</a>
              </li>
              <li
                *ngIf="authService.hasPermission('settings.products')"
                [routerLinkActive]="['active']"
              >
                <a [routerLink]="['/products']">Products</a>
              </li>
              <li
                *ngIf="authService.hasPermission('settings.suppliers')"
                [routerLinkActive]="['active']"
              >
                <a [routerLink]="['/suppliers']">Suppliers</a>
              </li>
              <li
                *ngIf="authService.hasPermission('settings.address-types')"
                [routerLinkActive]="['active']"
              >
                <a [routerLink]="['/address-types']">Address Types</a>
              </li>
            </ul>
          </li>

          <li
            *ngIf="authService.hasPermission('config.*')"
            [class.active]="(menuItem$ | async) === 'config'"
            class="sub-menu"
            style="cursor: pointer"
          >
            <a (click)="selectedMenu = 'config'">
              <i class="fa fa-cogs"></i>
              <span>Chief Config</span>
            </a>
            <ul [class.hidden]="(menuItem$ | async) != 'config'" class="sub">
              <li
                *ngIf="authService.hasPermission('config.breach-types')"
                [routerLinkActive]="['active']"
              >
                <a [routerLink]="['/breach-types']">Breach Types</a>
              </li>
              <li
                *ngIf="authService.hasPermission('config.modifications')"
                [routerLinkActive]="['active']"
              >
                <a [routerLink]="['/modifications']">Modifications</a>
              </li>
              <li
                *ngIf="authService.hasPermission('config.income-types')"
                [routerLinkActive]="['active']"
              >
                <a [routerLink]="['/income-types']">Income Types</a>
              </li>
              <li
                *ngIf="authService.hasPermission('config.payment-types')"
                [routerLinkActive]="['active']"
              >
                <a [routerLink]="['/payment-types']">Payment Types</a>
              </li>
              <li
                *ngIf="authService.hasPermission('config.account-groups')"
                [routerLinkActive]="['active']"
              >
                <a [routerLink]="['/account-groups']">Account Groups</a>
              </li>
              <li
                *ngIf="authService.hasPermission('config.account-types')"
                [routerLinkActive]="['active']"
              >
                <a [routerLink]="['/account-types']">Account Types</a>
              </li>
              <li
                *ngIf="authService.hasPermission('config.domains')"
                [routerLinkActive]="['active']"
              >
                <a [routerLink]="['/domains']">Domains</a>
              </li>
              <li
                *ngIf="authService.hasPermission('config.permissions')"
                [routerLinkActive]="['active']"
              >
                <a [routerLink]="['/permissions']">Permissions</a>
              </li>
              <li
                *ngIf="authService.hasPermission('config.status-categories')"
                [routerLinkActive]="['active']"
              >
                <a [routerLink]="['/status-categories']">Status Categories</a>
              </li>
              <li
                *ngIf="authService.hasPermission('config.portfolios')"
                [routerLinkActive]="['active']"
              >
                <a [routerLink]="['/portfolios']">Portfolios</a>
              </li>
              <li
                *ngIf="authService.hasPermission('config.creditors')"
                [routerLinkActive]="['active']"
              >
                <a [routerLink]="['/creditors']">Creditors</a>
              </li>
              <li
                *ngIf="authService.hasPermission('config.delivery-methods')"
                [routerLinkActive]="['active']"
              >
                <a [routerLink]="['/delivery-methods']">Delivery Methods</a>
              </li>
              <li
                *ngIf="authService.hasPermission('config.titles')"
                [routerLinkActive]="['active']"
              >
                <a [routerLink]="['/titles']">Titles</a>
              </li>
              <li
                *ngIf="authService.hasPermission('config.countries')"
                [routerLinkActive]="['active']"
              >
                <a [routerLink]="['/countries']">Countries</a>
              </li>
              <li
                *ngIf="authService.hasPermission('config.occupations')"
                [routerLinkActive]="['active']"
              >
                <a [routerLink]="['/occupations']">Occupations</a>
              </li>
              <li
                *ngIf="authService.hasPermission('config.assets')"
                [routerLinkActive]="['active']"
              >
                <a [routerLink]="['/assets']">Assets</a>
              </li>
              <li
                *ngIf="authService.hasPermission('config.income-categories')"
                [routerLinkActive]="['active']"
              >
                <a [routerLink]="['/income-categories']">Income Categories</a>
              </li>
              <li
                *ngIf="authService.hasPermission('config.incomes')"
                [routerLinkActive]="['active']"
              >
                <a [routerLink]="['/incomes']">Income Types</a>
              </li>
              <li
                *ngIf="
                  authService.hasPermission('config.expenditure-categories')
                "
                [routerLinkActive]="['active']"
              >
                <a [routerLink]="['/expenditure-categories']"
                  >Expenditure Categories</a
                >
              </li>
              <li
                *ngIf="authService.hasPermission('config.expenditures')"
                [routerLinkActive]="['active']"
              >
                <a [routerLink]="['/expenditures']">Expenditure Types</a>
              </li>
              <li
                *ngIf="authService.hasPermission('config.credits')"
                [routerLinkActive]="['active']"
              >
                <a [routerLink]="['/credits']">Credit Types</a>
              </li>
              <li
                *ngIf="authService.hasPermission('config.genders')"
                [routerLinkActive]="['active']"
              >
                <a [routerLink]="['/genders']">Genders</a>
              </li>
              <li
                *ngIf="authService.hasPermission('config.marital-statuses')"
                [routerLinkActive]="['active']"
              >
                <a [routerLink]="['/marital-statuses']">Marital Statuses</a>
              </li>
              <li
                *ngIf="authService.hasPermission('config.residential-statuses')"
                [routerLinkActive]="['active']"
              >
                <a [routerLink]="['/residential-statuses']"
                  >Residential Statuses</a
                >
              </li>
              <li
                *ngIf="authService.hasPermission('config.employment-statuses')"
                [routerLinkActive]="['active']"
              >
                <a [routerLink]="['/employment-statuses']"
                  >Employment Statuses</a
                >
              </li>
            </ul>
          </li>

          <li
            [class.active]="(menuItem$ | async) === 'roadmaps'"
            class="sub-menu"
            style="cursor: pointer"
          >
            <a (click)="selectedMenu = 'roadmaps'">
              <i class="fa fa-dashboard"></i>
              <span>Chief Roadmaps</span>
            </a>
            <ul [class.hidden]="(menuItem$ | async) != 'roadmaps'" class="sub">
              <li>
                <a
                  href="https://github.com/tech3k/ChiefSupportSystems/projects/1?fullscreen=true"
                  target="_blank"
                >
                  <span>Feature Roadmap</span>
                </a>
              </li>
              <li>
                <a
                  href="https://github.com/tech3k/ChiefSupportSystems/projects/2?fullscreen=true"
                  target="_blank"
                >
                  <span>Bug Reports</span>
                </a>
              </li>
            </ul>
          </li>
        </ul>
      </div>
    </div>
  </aside>

  <section
    [class.merge-left]="!authService.authenticated || !(menubar$ | async)"
    id="main-content"
  >
    <section class="wrapper">
      <router-outlet></router-outlet>
    </section>
  </section>
</div>
