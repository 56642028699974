<div class="row">
  <div class="col-sm-12">
    <section class="panel">
      <app-page-title title="List Accounts" [loading]="isLoading">
        {{ totalResults }} Results Found
      </app-page-title>

      <div class="panel-body">
        <table class="table table-striped">
          <thead>
            <tr>
              <th>Domain</th>
              <th>Account Name</th>
              <th>Description</th>
              <th>Sort Code</th>
              <th>Account Number</th>
              <th>Balance</th>
              <th class="text-right">
                <a
                  [routerLink]="['/banking/accounts/create']"
                  class="btn btn-success"
                  >Create</a
                >
              </th>
            </tr>
          </thead>

          <tbody>
            <tr *ngFor="let account of accounts">
              <td>{{ account?.domain?.name }}</td>
              <td>{{ account?.name }}</td>
              <td>{{ account?.description }}</td>
              <td>{{ account?.sortCode }}</td>
              <td>{{ account?.accountNumber }}</td>
              <td>
                <b>Balance:</b> {{ account?.balance / 100 | toAccountancy }}
                <div *ngIf="account.reconciledBalance !== account.balance">
                  <b>Reconciled Balance:</b>
                  {{ account?.reconciledBalance / 100 | toAccountancy }}
                </div>
                <div *ngIf="account.reconciledBalance !== account.balance">
                  <b>Unreconciled Balance:</b>
                  {{ account?.unreconciledBalance / 100 | toAccountancy }}
                </div>
              </td>
              <td class="text-right">
                <div class="btn-group">
                  <a
                    [routerLink]="['/banking/accounts', account?.id, 'items']"
                    type="button"
                    class="btn btn-sm btn-warning"
                    >View</a
                  >
                  <button
                    type="button"
                    class="btn btn-sm btn-warning dropdown-toggle"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    <span class="caret"></span>
                    <span class="sr-only">Toggle Dropdown</span>
                  </button>
                  <ul class="dropdown-menu">
                    <li>
                      <a
                        [routerLink]="[
                          '/banking/accounts',
                          account?.id,
                          'edit'
                        ]"
                        >Edit</a
                      >
                    </li>
                    <li *ngIf="account.type?.name === 'Client Money Services'">
                      <a (click)="interestModal.open()" href="javascript:;">
                        Import Interest
                      </a>
                    </li>
                    <li>
                      <a
                        [routerLink]="[
                          '/banking/accounts',
                          account?.id,
                          'imports'
                        ]"
                        >View Imports</a
                      >
                    </li>
                    <li>
                      <a
                        href="javascript:;"
                        (click)="deleteModal.confirmDialog(account.id)"
                        >Delete</a
                      >
                    </li>
                  </ul>
                </div>
              </td>
            </tr>
          </tbody>

          <tfoot>
            <tr>
              <td colspan="99" class="text-center">
                <app-pagination
                  [totalPages]="totalPages"
                  [currentPage]="currentPage"
                  (pageChanged)="onPageChanged($event)"
                ></app-pagination>
              </td>
            </tr>
          </tfoot>
        </table>
      </div>
    </section>
  </div>
</div>

<app-client-interest-upload #interestModal> </app-client-interest-upload>

<app-delete-modal
  type="account"
  (confirm)="delete($event)"
  [itemDeleted]="accountDeleted"
  #deleteModal
></app-delete-modal>
