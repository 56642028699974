<div
  class="row"
  style="
    margin-bottom: 10px;
    padding-bottom: 15px;
    border-bottom: 1px solid #eee;
  "
>
  <div class="col-md-1" style="line-height: 36px">
    <strong>Filter: </strong>
  </div>
  <div class="col-md-8">
    <app-chart-of-accounts-select
      [model]="filter"
    ></app-chart-of-accounts-select>
  </div>
  <div class="col-md-3 text-right">
    <div class="btn-group">
      <button class="btn btn-success" (click)="runFilters()">Filter</button>
      <button class="btn btn-warning" (click)="clearFilters()">Clear</button>
    </div>
  </div>
</div>

<div class="table-responsive">
  <table class="table table-striped">
    <thead>
      <tr>
        <th>Invoice Type</th>
        <th>Value</th>
        <th>Creation Date</th>
        <th>&nbsp;</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let invoice of invoices">
        <td>{{ invoice?.chartOfAccounts?.name }}</td>
        <td>{{ invoice?.value / 100 | toCurrency: 'GBP' }}</td>
        <td>{{ invoice?.createdAt | date: 'dd/MM/yyyy hh:mm' }}</td>
        <td class="text-right">
          <a
            class="btn btn-xs btn-warning"
            [href]="generateInvoiceDownloadUrl(invoice.url)"
            >Download</a
          >
        </td>
      </tr>
    </tbody>
  </table>
</div>
