import { RouterModule, Routes } from '@angular/router';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule } from '@angular/common/http';
import { ApprovalListComponent } from './list/list.component';
import { AuthGuard } from '../auth/auth.guard';
import { ApprovalService } from './approval.service';
import { SharedModule } from '../shared/shared.module';

const routes: Routes = [
  {
    path: 'approvals',
    component: ApprovalListComponent,
    canActivate: [AuthGuard],
  },
];

@NgModule({
  declarations: [ApprovalListComponent],
  imports: [
    BrowserModule,
    RouterModule,
    FormsModule,
    HttpClientModule,
    SharedModule,
    RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' }),
  ],
  exports: [RouterModule],
  providers: [AuthGuard, ApprovalService],
})
export class ApprovalRoutingModule {}
