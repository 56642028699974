import { RouterModule, Routes } from '@angular/router';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule } from '@angular/common/http';
import { ChartOfAccountsListComponent } from './list/list.component';
import { AuthGuard } from '../../auth/auth.guard';
import { ChartOfAccountsService } from './chart-of-accounts.service';
import { SharedModule } from '../../shared/shared.module';
import { ChartOfAccountsCreateComponent } from './create/create.component';
import { ChartOfAccountsFormComponent } from './form/form.component';
import { ChartOfAccountsEditComponent } from './edit/edit.component';

const routes: Routes = [
  {
    path: 'chart-of-accounts',
    component: ChartOfAccountsListComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'chart-of-accounts/create',
    component: ChartOfAccountsCreateComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'chart-of-accounts/:id/edit',
    component: ChartOfAccountsEditComponent,
    canActivate: [AuthGuard],
  },
];

@NgModule({
  declarations: [
    ChartOfAccountsListComponent,
    ChartOfAccountsCreateComponent,
    ChartOfAccountsFormComponent,
    ChartOfAccountsEditComponent,
  ],
  imports: [
    BrowserModule,
    RouterModule,
    FormsModule,
    HttpClientModule,
    SharedModule,
    RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' }),
  ],
  exports: [RouterModule],
  providers: [AuthGuard, ChartOfAccountsService],
})
export class ChartOfAccountsRoutingModule {}
