import { Component, Input } from '@angular/core';

import { Substatus } from '../substatus';

@Component({
  selector: 'app-substatus-form',
  templateUrl: 'form.component.html',
})
export class SubstatusFormComponent {
  @Input() substatus: Substatus;
}
