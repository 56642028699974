import { RouterModule, Routes } from '@angular/router';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule } from '@angular/common/http';
import { CountryListComponent } from './list/list.component';
import { AuthGuard } from '../auth/auth.guard';
import { CountryService } from './country.service';
import { SharedModule } from '../shared/shared.module';
import { CountryCreateComponent } from './create/create.component';
import { CountryFormComponent } from './form/form.component';
import { CountryEditComponent } from './edit/edit.component';

const routes: Routes = [
  {
    path: 'countries',
    component: CountryListComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'countries/create',
    component: CountryCreateComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'countries/:id/edit',
    component: CountryEditComponent,
    canActivate: [AuthGuard],
  },
];

@NgModule({
  declarations: [
    CountryListComponent,
    CountryCreateComponent,
    CountryFormComponent,
    CountryEditComponent,
  ],
  imports: [
    BrowserModule,
    RouterModule,
    FormsModule,
    HttpClientModule,
    SharedModule,
    RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' }),
  ],
  exports: [RouterModule],
  providers: [AuthGuard, CountryService],
})
export class CountryRoutingModule {}
