import { Component, EventEmitter, Input, OnInit } from '@angular/core';

import { AuthService } from '../../../auth/auth.service';
import { CaseNote } from '../case-note';
import { CaseNoteService } from '../case-note.service';
import { Department } from '../../../settings/departments/department';
import { DepartmentService } from '../../../settings/departments/department.service';
import { NotificationService } from '../../../shared/notification.service';
import { SelectComparisons } from '../../../shared/pipes/select.comparisons';
import { environment } from '../../../../environments/environment';

@Component({
  selector: 'app-case-note-list',
  templateUrl: 'list.component.html',
  styleUrls: ['list.component.scss'],
})
export class CaseNoteListComponent extends SelectComparisons implements OnInit {
  @Input() caseRefreshed: EventEmitter<string>;

  @Input() caseId: string;

  public newNote: any;

  /**
   * A list of all the departments currently loaded.
   *
   * @type {Department[]}
   */
  public departments: Department[] = [];

  public caseNotes: CaseNote[] = [];

  public isLoading: boolean = true;

  public totalPages: number = 1;

  public currentPage: number = 1;

  public departmentFilter: any = { id: 0 };

  public totalResults: number = 0;

  public noteCreated: EventEmitter<boolean> = new EventEmitter<boolean>();

  public noteDeleted: EventEmitter<boolean> = new EventEmitter<boolean>();

  public editorOptions = {
    placeholder: 'Enter your note...',
    modules: {
      toolbar: ['bold', 'italic', 'underline', 'strike', 'link'],
    },
  };

  constructor(
    private caseNoteService: CaseNoteService,
    private notificationService: NotificationService,
    public authService: AuthService,
    private departmentService: DepartmentService,
  ) {
    super();
    this.newNote = {
      content: '',
      department: authService.department || { id: 0 },
    };

    this.loadDepartments(1, 10000);

    this.caseNoteService.loading.subscribe((isLoading) => {
      this.isLoading = isLoading;
    });
    this.caseNoteService.totalPages.subscribe((totalPages) => {
      this.totalPages = totalPages;
    });
    this.caseNoteService.currentPage.subscribe((currentPage) => {
      this.currentPage = currentPage;
    });
    this.caseNoteService.totalResults.subscribe((totalResults) => {
      this.totalResults = totalResults;
    });
  }

  public ngOnInit(): void {
    this.loadCaseNotes(1);
    this.caseRefreshed.subscribe((id) => {
      this.caseId = id;
      this.onPageChanged(1);
    });
  }

  public get downloadUrl() {
    return `${environment.apiUrl}cases/${this.caseId}/notes?download=true`;
  }

  public loadDepartments(page?: number, limit?: number): void {
    const listDepartments = this.departmentService
      .listDepartments(page, limit)
      .subscribe((departments: Department[]) => {
        this.departments = departments;
        listDepartments.unsubscribe();
      });
  }

  public onPageChanged(pageNumber: number): void {
    this.loadCaseNotes(pageNumber);
  }

  public loadCaseNotes(page?: number, limit?: number): void {
    const options = { parameters: { id: this.caseId } };

    const listCountries = this.caseNoteService
      .listCaseNotes(
        page,
        limit,
        options,
        this.departmentFilter.id !== 0
          ? { department: this.departmentFilter.id }
          : undefined,
      )
      .subscribe((caseNotes: CaseNote[]) => {
        this.caseNotes = caseNotes;
        listCountries.unsubscribe();
      });
  }

  public create(formData: CaseNote) {
    const subscription = this.caseNoteService
      .createCaseNote(this.newNote, { parameters: { id: this.caseId } })
      .subscribe((results) => {
        this.noteCreated.next(true);
        this.newNote = {
          content: '',
          department: this.authService.department || { id: 0 },
        };
        this.onPageChanged(1);
        subscription.unsubscribe();
      });
  }

  delete(id: string) {
    this.caseNoteService.deleteCaseNote(id).subscribe(
      (deleted) => {
        this.notificationService.success(
          `Case Note Deleted`,
          `Case Note with ID ${id} was deleted.`,
          5,
        );
        this.noteDeleted.next(true);
        this.onPageChanged(this.currentPage);
      },
      (err) => {
        this.notificationService.error(
          `Case Not Deleted`,
          err.error.message,
          10,
        );
        this.noteDeleted.next(false);
        this.onPageChanged(this.currentPage);
      },
    );
  }

  public togglePin(note: CaseNote): void {
    note.pinned = !note.pinned;
    const subscription = this.caseNoteService
      .updateCaseNote(note.id, note)
      .subscribe((result) => {
        this.onPageChanged(1);
        subscription.unsubscribe();
      });
  }
}
