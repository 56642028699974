import { RouterModule, Routes } from '@angular/router';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule } from '@angular/common/http';
import { LetterheadListComponent } from './list/list.component';
import { AuthGuard } from '../auth/auth.guard';
import { LetterheadService } from './letterhead.service';
import { SharedModule } from '../shared/shared.module';
import { LetterheadCreateComponent } from './create/create.component';
import { LetterheadFormComponent } from './form/form.component';
import { LetterheadEditComponent } from './edit/edit.component';

const routes: Routes = [
  {
    path: 'letterheads',
    component: LetterheadListComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'letterheads/create',
    component: LetterheadCreateComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'letterheads/:id/edit',
    component: LetterheadEditComponent,
    canActivate: [AuthGuard],
  },
];

@NgModule({
  declarations: [
    LetterheadListComponent,
    LetterheadCreateComponent,
    LetterheadFormComponent,
    LetterheadEditComponent,
  ],
  imports: [
    BrowserModule,
    RouterModule,
    FormsModule,
    HttpClientModule,
    SharedModule,
    RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' }),
  ],
  exports: [RouterModule],
  providers: [AuthGuard, LetterheadService],
})
export class LetterheadRoutingModule {}
