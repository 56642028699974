import { useEffect } from "react";

const useOutsideClick = (ref: any, action: () => void): void => {

  useEffect(() => {
    // add when mounted
    document.addEventListener('mousedown', handleClick);

    return () => {
      document.removeEventListener('mousedown', handleClick);
    };
  }, []);

  const handleClick = (e: any) => {
    if (!ref || !ref.current) {
      return;
    }
    if (ref.current.contains(e.target)) {
      return;
    }
    // outside click
    action();
  };

}

export default useOutsideClick;