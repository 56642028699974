import { Component, Input } from '@angular/core';
import { Modification } from '../modification';

@Component({
  selector: 'app-modification-form',
  templateUrl: 'form.component.html',
})
export class ModificationFormComponent {
  @Input() modification: Modification;
}
