import { Injectable } from '@angular/core';
import { Occupation } from './occupation';
import { Observable } from 'rxjs';

import { HttpRequestService } from '../shared/http/http-request.service';

@Injectable()
export class OccupationService extends HttpRequestService {
  /**
   * The endpoint that we call to get the occupation information.
   *
   * @type {string}
   */
  private readonly endpoint: string = 'occupations';

  /**
   * Brings back a list of all accessible occupations.
   *
   * @param {number} page The page number we are currently on.
   * @param {number} limit The number of occupations to display per page.
   * @returns {Observable<Occupation[]>}
   */
  public listOccupations(
    page?: number,
    limit?: number,
    filter?: string,
  ): Observable<Occupation[]> {
    return this.list(this.endpoint, page, { filter: filter }, limit);
  }

  /**
   * Finds details of the occupation from the given ID.
   *
   * @param {string} occupationId The ID of the occupation we require details for.
   * @returns {Observable<Occupation>}
   */
  public findOccupation(occupationId: string): Observable<Occupation> {
    return this.findById(this.endpoint, occupationId);
  }

  /**
   * Creates a new occupation from the given Occupation object.
   *
   * @param {Occupation} newOccupation The details of the occupation to be created.
   * @returns {Observable<Occupation | boolean>}
   */
  public createOccupation(
    newOccupation: Occupation,
  ): Observable<Occupation | boolean> {
    return this.create(this.endpoint, newOccupation);
  }

  /**
   * Updates a occupation with the given occupation object for the given occupation ID.
   *
   * @param {string} occupationId The ID of the occupation needing updating.
   * @param {Occupation} newDetails The new occupation object to update with.
   * @returns {Observable<Occupation | boolean>}
   */
  public updateOccupation(
    occupationId: string,
    newDetails: Occupation,
  ): Observable<Occupation | boolean> {
    return this.update(this.endpoint, occupationId, newDetails);
  }

  /**
   * Deletes a occupation based on the given occupation ID.
   *
   * @param {string} occupationId The ID of the occupation needing deleting.
   * @returns {Observable<boolean>}
   */
  public deleteOccupation(occupationId: string): Observable<boolean> {
    return this.delete(this.endpoint, occupationId);
  }
}
