import { Component, Input, OnInit } from '@angular/core';
import { IncomeService } from '../income.service';
import { Income } from '../income';
import { NotificationService } from '../../shared/notification.service';
import { SelectComparisons } from '../../shared/pipes/select.comparisons';

@Component({
  selector: 'app-income-select',
  templateUrl: 'select.component.html',
})
export class IncomeSelectComponent extends SelectComparisons implements OnInit {
  /**
   * The model that we will be adding the income to.
   */
  @Input() model: any;

  /**
   * Do we require this field in the main form it is
   * included in?
   */
  @Input() required: boolean;

  /**
   * A list of all the incomes currently loaded.
   *
   * @type {Income[]}
   */
  public incomes: Income[] = [];

  /**
   * Are we currently performing a loading action?
   *
   * @type {boolean}
   */
  public isLoading: boolean = true;

  /**
   * Load up all our required services and subscribe to every observable we need.
   *
   * @param {IncomeService} incomeService
   * @param {NotificationService} notificationService
   */
  constructor(
    private incomeService: IncomeService,
    private notificationService: NotificationService,
  ) {
    super();
    this.incomeService.loading.subscribe(isLoading => {
      this.isLoading = isLoading;
    });
  }

  /**
   * When the component is initialised we set it to load
   * page one of the income list.
   */
  public ngOnInit(): void {
    this.loadIncomes(1, 10000);
  }

  /**
   * Loads a list of incomes using the given per page limit.
   *
   * @param {number} page The current page we require.
   * @param {number} limit The number of results per page.
   */
  public loadIncomes(page?: number, limit?: number): void {
    const listIncomes = this.incomeService
      .listIncomes(page, limit)
      .subscribe((incomes: Income[]) => {
        this.incomes = incomes;
        listIncomes.unsubscribe();
      });
  }
}
