<div
  class="modal fade"
  bsModal
  #staticModal="bs-modal"
  [config]="{ backdrop: 'static' }"
  tabindex="-1"
  role="dialog"
  aria-labelledby="mySmallModalLabel"
  aria-hidden="true"
>
  <div *ngIf="scheduleItem" class="modal-dialog modal-md">
    <form class="form-horizontal" (submit)="submit()" ngNativeValidate>
      <div class="modal-content panel-success">
        <div class="modal-header">
          <h4 class="modal-title pull-left">
            Edit Payment Schedule Item Value
          </h4>
          <button
            type="button"
            class="close pull-right"
            aria-label="Close"
            (click)="close()"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <div class="form-group">
            <label for="payment" class="col-sm-4 control-label"
              >Selected Schedule Item</label
            >
            <div class="col-sm-8">
              {{ scheduleItem.expectedDate | date: 'dd/MM/yyyy' }} -
              {{ scheduleItem.expectedValue / 100 | toCurrency }} -
              {{ scheduleItem.status }}
            </div>
          </div>

          <div class="form-group">
            <label for="payment" class="col-sm-4 control-label"
              >Expected Value</label
            >
            <div class="col-sm-8">
              <app-shared-currency
                id="payment"
                name="payment"
                [(ngModel)]="expectedValue"
                [required]="true"
                [min]="scheduleItem.totalReceived / 100"
              ></app-shared-currency>
            </div>
          </div>

          <div class="form-group">
            <label class="col-sm-4 control-label">Expected Date</label>
            <div class="col-sm-8">
              <input
                [(ngModel)]="expectedDate"
                class="form-control"
                placeholder="Start Date"
                type="date"
                min="{{ minDate }}"
                max="{{ maxDate ? maxDate : undefined }}"
              />
            </div>
          </div>

          <div class="form-group">
            <label for="expectedDate" class="col-sm-4 control-label"
              >Set All Future Expected Values</label
            >
            <div class="col-sm-8">
              <input
                type="checkbox"
                (value)="(setAllFuturePayments)"
                (change)="toggleSetAllFuturePayments()"
              />
            </div>
          </div>
        </div>
        <div class="modal-footer text-right">
          <button type="button" class="btn btn-default" (click)="close()">
            Cancel
          </button>
          <button type="submit" class="btn btn-success" [disabled]="disabled">
            Save
          </button>
        </div>
      </div>
    </form>
  </div>
</div>
