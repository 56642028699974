<div class="col-md-9">
  <section class="panel">
    <header class="panel-heading tab-bg-light">
      WIP Report
    </header>

    <div class="panel-body">

      <div class="tab-content">
          <div class="table-responsive">
            <table class="table table-striped table-hover table-bordered">
              <thead>
              <tr>
                <th style="width: 80px !important;">Ref</th>
                <th width="120">First Name</th>
                <th width="120">Surname</th>
                <th width="200">Introducer</th>
                <th>Product</th>
                <th>Status</th>
                <th>Latest Note</th>
                <th width="200">Drafter</th>
                <th>Date Received</th>
                <th>Cons Date</th>
                <th>Cons Time</th>
                <th>Cons Completed</th>
                <th>Draft Completed</th>
                <th>IP Sign Off</th>
                <th>Sent to Client</th>
                <th>Received from Client</th>
                <th>MOC set	Initial MOC date</th>
                <th>Initial MOC Time</th>
                <th>Adjourned Date</th>
                <th>Approved Date</th>
                <th>Closed</th>
                <th>DI</th>
                <th>CIC included</th>
                <th>1st Payment Due</th>
                <th>1st Payment Received</th>
                <th>Nominee Fee</th>
                <th>Variation MOC Date</th>
                <th>PPI Pack Sent</th>
              </tr>
              </thead>
              <tbody>
              <tr *ngFor="let item of detailedReport">
                <td>{{ item['Ref'] }}</td>
                <td>{{ item['First Name'] }}</td>
                <td>{{ item['Surname'] }}</td>
                <td>{{ item['Introducer'] }}</td>
                <td>{{ item['Product'] }}</td>
                <td>{{ item['Status'] }}</td>
                <td>hover</td>
                <td>{{ item['Drafter'] }}</td>
                <td>{{ item['Date Received'] }}</td>
                <td>{{ item['Cons Date'] }}</td>
                <td>{{ item['Cons Time'] }}</td>
                <td>{{ item['Cons Completed'] }}</td>

                <td>{{ item['Draft Completed'] }}</td>
                <td>{{ item['IP Sign Off'] }}</td>
                <td>{{ item['Sent to Client'] }}</td>
                <td>{{ item['Received from Client'] }}</td>
                <td>{{ item['MOC set	Initial MOC date'] }}</td>
                <td>{{ item['Initial MOC Time'] }}</td>
                <td>{{ item['Adjourned Date'] }}</td>
                <td>{{ item['Approved Date'] }}</td>
                <td>{{ item['Closed'] }}</td>
                <td>{{ (item['DI'] ? item['DI'] : 0) | toCurrency }}</td>
                <td>{{ (item['CIC included'] ? item['CIC included'] : 0) | toCurrency }}</td>
                <td>{{ item['1st Payment Due'] }}</td>
                <td>{{ item['1st Payment Received'] }}</td>
                <td>{{ (item['Nominee Fee'] ? item['Nominee Fee'] : 0) | toCurrency }}</td>
                <td>{{ item['Variation MOC Date'] }}</td>
                <td>{{ item['PPI Pack Sent'] }}</td>
              </tr>
              </tbody>
            </table>
          </div>
      </div>
    </div>

  </section>
</div>

<div class="col-md-3">
    <section class="panel panel-warning">
      <header class="panel-heading tab-bg-light">
        WIP Options
      </header>

      <div class="panel-body">

        <div class="row clearfix">
          <div class="col-md-4">
            Introducer
          </div>
          <div class="col-md-8">
            <app-introducer-select [model]="filter" (change)="reloadAllReports()"></app-introducer-select>
          </div>
        </div>

        <div class="row" style="margin-top: 20px;">
          <div class="col-md-4">
            Live Month
          </div>
          <div class="col-md-8">
            <select class="form-control" [(ngModel)]="filter.approvedDate" (change)="reloadAllReports()">
              <option [value]="null">Choose a Month to View</option>
              <option *ngFor="let month of monthsSinceStart" [value]="month">{{ dateswitch(month) }}</option>
            </select>
          </div>
        </div>


        <div class="row" style="margin-top: 20px;">
          <div class="col-md-12">
            <a href="{{ detailedLink }}&download=true" class="btn btn-block btn-primary" target="_blank">
              Download Current View
            </a>
          </div>
        </div>

      </div>
    </section>
</div>
