import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FileItem, FileUploader, ParsedResponseHeaders } from 'ng2-file-upload';

import { AuthService } from '../../auth/auth.service';
import { Template } from '../template';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-template-form',
  templateUrl: 'form.component.html',
})
export class TemplateFormComponent {
  @Input() template: Template;
  @Input() disabled: boolean;
  @Output() public onSubmit: EventEmitter<
    string | undefined
  > = new EventEmitter<string | undefined>();

  public uploader: FileUploader;
  public hasBaseDropZoneOver: boolean = false;

  public editorOptions = {
    placeholder: 'Enter your template...',
  };

  constructor(private readonly authService: AuthService) {
    this.createUploader();
  }

  public fileOverBase(e: any): void {
    this.hasBaseDropZoneOver = e;
  }

  handleSubmit() {
    if (this.uploader.queue.length > 0) {
      this.disabled = true;
      this.uploader.uploadAll();
    } else {
      this.onSubmit.next(undefined);
    }
  }

  private createUploader() {
    this.uploader = new FileUploader({
      url: `${environment.endpoint}templates/upload`,
      authToken: `Bearer ${this.authService.getToken()}`,
      // allowedFileType: ['.docx', '.doc'],
    });

    this.uploader.onBeforeUploadItem = (item) => {
      item.withCredentials = false;
    };

    this.uploader.onSuccessItem = (
      item: FileItem,
      response: string,
      status: number,
      headers: ParsedResponseHeaders,
    ) => {
      if (response) {
        this.workCompleted(JSON.parse(response).data.url);
      }
    };
  }

  private workCompleted(documentUrl) {
    this.createUploader();
    this.disabled = false;
    this.onSubmit.next(documentUrl);
  }
}
