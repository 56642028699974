import { HttpClient } from '@angular/common/http';
import { Component, Input, OnInit } from '@angular/core';

import { environment } from '../../../../environments/environment';
import { ChairmansReportData } from '../chairmansReportData';

@Component({
  selector: 'app-case-chairmans-report-view',
  templateUrl: 'view.component.html',
})
export class CaseChairmansReportViewComponent implements OnInit {
  @Input()
  private caseId: string;

  public data: ChairmansReportData;

  private get watchUrl(): string {
    return `${environment.microservices.creditorCommunication}creditor-communication/chairmans-report/${this.caseId}`;
  }

  constructor(private readonly http: HttpClient) {}

  ngOnInit(): void {
    this.http
      .get(`${this.watchUrl}?preview=true`)
      .subscribe((result: any) => (this.data = result));
  }

  public ableToSend(): boolean {
    if (!this || !this.data || typeof this.data !== 'object') {
      return false;
    }
    let able = true;

    Object.keys(this.data ?? {}).forEach((key) => {
      if (
        [
          'partnerFirstName',
          'partnerLastName',
          'partnerDateOfBirth',
          'adjournedMocDate',
        ].includes(key)
      ) {
        return;
      }

      if (this.data[key] === undefined || this.data[key] === null) {
        able = false;
      }
    });

    return able;
  }

  public download(company: 'watch'): string {
    return this.watchUrl;
  }
}
