import { Injectable } from '@angular/core';
import { Income } from './income';
import { Observable } from 'rxjs';

import { HttpRequestService } from '../shared/http/http-request.service';

@Injectable()
export class IncomeService extends HttpRequestService {
  /**
   * The endpoint that we call to get the income information.
   *
   * @type {string}
   */
  private readonly endpoint: string = 'incomes';

  /**
   * Brings back a list of all accessible incomes.
   *
   * @param {number} page The page number we are currently on.
   * @param {number} limit The number of incomes to display per page.
   * @returns {Observable<Income[]>}
   */
  public listIncomes(page?: number, limit?: number): Observable<Income[]> {
    return this.list(this.endpoint, page, undefined, limit);
  }

  /**
   * Finds details of the income from the given ID.
   *
   * @param {string} incomeId The ID of the income we require details for.
   * @returns {Observable<Income>}
   */
  public findIncome(incomeId: string): Observable<Income> {
    return this.findById(this.endpoint, incomeId);
  }

  /**
   * Creates a new income from the given Income object.
   *
   * @param {Income} newIncome The details of the income to be created.
   * @returns {Observable<Income | boolean>}
   */
  public createIncome(newIncome: Income): Observable<Income | boolean> {
    return this.create(this.endpoint, newIncome);
  }

  /**
   * Updates a income with the given income object for the given income ID.
   *
   * @param {string} incomeId The ID of the income needing updating.
   * @param {Income} newDetails The new income object to update with.
   * @returns {Observable<Income | boolean>}
   */
  public updateIncome(
    incomeId: string,
    newDetails: Income,
  ): Observable<Income | boolean> {
    return this.update(this.endpoint, incomeId, newDetails);
  }

  /**
   * Deletes a income based on the given income ID.
   *
   * @param {string} incomeId The ID of the income needing deleting.
   * @returns {Observable<boolean>}
   */
  public deleteIncome(incomeId: string): Observable<boolean> {
    return this.delete(this.endpoint, incomeId);
  }
}
