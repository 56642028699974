<div class="panel-heading {{ textOrientation }}">
  {{ title }}

  <span class="tools {{ pullOrientation }}" [class.hidden]="!loading">
    <div class="tw-flex">
      <div>Loading</div>
      <div>
        <img
          src="https://i0.wp.com/cdnjs.cloudflare.com/ajax/libs/semantic-ui/0.16.1/images/loader-large.gif"
          class="tw-h-8 tw-w-8"
          height="20"
          style="margin-left: 20px"
        />
      </div>
    </div>
  </span>

  <span class="tools {{ pullOrientation }}" [class.hidden]="loading">
    <ng-content></ng-content>
  </span>
</div>
