<div class="row">
  <div class="col-md-12 text-right">
    <button class="btn btn-success btn-xs" (click)="saveAll()">Save All</button>
  </div>
</div>

<ng-container *ngFor="let category of categories" class="category clearfix">
  <div class="row clearfix">
    <div class="col-md-12 clearfix">
      <h3 class="category-title">{{ category.name }}</h3>
    </div>
  </div>

  <div class="row clearfix">

    <app-case-income-form-item *ngFor="let item of category.incomes" [caseId]="caseId"
                               [item]="item" [saveAllClicked]="saveAllClicked"></app-case-income-form-item>

  </div>
</ng-container>
