import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';

import {environment} from '../../../environments/environment';
import {HttpRequestResponse} from '../../shared/http/http-request-response';
import {HttpRequestService} from '../../shared/http/http-request.service';

@Injectable()
export class FinancialsDistributionService extends HttpRequestService {
  private readonly endpoint: string = 'financials/distributions';

  public generateDep() {
    const generateDep = this.functions.httpsCallable('acceptDepositsRequest');

    return generateDep({
      domainId: this.authService.domain.id,
    });
  }

  public startAutomaticAllocations(
    includeDistributions: boolean = false,
  ): Observable<boolean> {
    return new Observable((subscriber) => {
      subscriber.next(true);

      const automaticAllocationsRequest = this.functions.httpsCallable(
        'automaticAllocationsRequest',
      );

      automaticAllocationsRequest({
        domainId: this.authService.domain.id,
        shouldDistributeToCreditors: includeDistributions,
      }).subscribe(
        (r) => subscriber.next(false),
        (error) => subscriber.next(false),
      );
    });
  }

  public updateFigure(type, reference, financialType, newValue) {
    const updateFunction = this.functions.httpsCallable(`update${type}Figure`);

  console.log({
      reference,
      financialType,
      newValue,
    });

    return updateFunction({
      reference,
      financialType,
      newValue,
    });
  }

  public switchCaseToManual(id) {
    const switchToManual = this.functions.httpsCallable('switchToManual');

    return switchToManual({
      caseReference: id,
    });
  }

  public acceptAllocations(): Observable<boolean> {
    return new Observable((subscriber) => {
      subscriber.next(true);

      const acceptAllocationsRequest = this.functions.httpsCallable(
        'acceptAllocationsRequest',
      );

      acceptAllocationsRequest({
        domainId: this.authService.domain.id,
      }).subscribe(
        (r) => subscriber.next(false),
        (error) => subscriber.next(false),
      );
    });
  }

  public automaticAllocationsInProgress(): Observable<boolean> {
    return new Observable((subscribe) => {
      this.db
        .doc(`logs/automatic-allocation/logs/${this.authService.domain.id}`)
        .valueChanges()
        .subscribe((r: any) => subscribe.next(r.inProgress));
    });
  }

  public listDistributionCases(
    page?: number,
    limit?: number,
    options?: any,
    query?: any,
  ): Observable<any[]> {
    return this.get(
      `${environment.endpoint}${this.endpoint}?domainId=${this.authService.domain.id}`,
      page,
      query,
      limit,
      options,
    );
  }

  public showDistributionSummary(
    page?: number,
    limit?: number,
    options?: any,
    query?: any,
  ): Observable<[any[], number]> {
    return this.http.get(this.summaryUrl +
          `&page=${page || environment.pagination.startPage}&limit=${
            limit || environment.pagination.perPage
          }${this.parseQueryObject(query) || ''}`)
          .map((data: HttpRequestResponse) => {
            return [data.data, data.meta.totalPages] as [any[], number];
          })
  }

  public get summaryUrl() {
    return `${environment.endpoint}${this.endpoint}/summary?domainId=${this.authService.domain.id}`;
  }

  public showPendingCat1s(
    page?: number,
    limit?: number,
    options?: any,
    query?: any,
  ): Observable<[any[], number]> {
    return this.http.get(this.pendingCat1sUrl +
          `&page=${page || environment.pagination.startPage}&limit=${
            limit || environment.pagination.perPage
          }${this.parseQueryObject(query) || ''}`)
          .map((data: HttpRequestResponse) => {
            return [data.data, data.meta.totalPages] as [any[], number];
          })
  }

  public get pendingCat1sUrl() {
    return `${environment.endpoint}${this.endpoint}/detailed-cat1s?domainId=${this.authService.domain.id}`;
  }

  public showPendingCreditors(
    page?: number,
    limit?: number,
    options?: any,
    query?: any,
  ): Observable<[any[], number]> {
     return this.http.get(this.pendingCreditorsUrl +
          `&page=${page || environment.pagination.startPage}&limit=${
            limit || environment.pagination.perPage
          }${this.parseQueryObject(query) || ''}`)
          .map((data: HttpRequestResponse) => {
            return [data.data, data.meta.totalPages] as [any[], number];
          })
          
  }

  public get pendingCreditorsUrl() {
    return `${environment.endpoint}${this.endpoint}/detailed-creditors?domainId=${this.authService.domain.id}`;
  }

  public showClientDistributions(id, options?: any) {
    return this.findById(this.endpoint, id, options);
  }

  public sendDistributions(id, items, options?: any) {
    return this.update(this.endpoint, id, items, options);
  }
}
