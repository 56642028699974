<div class="form-group clearfix">
  <label for="breach-type" class="col-sm-4 control-label">Type</label>
  <div class="col-sm-8">
    <app-breach-type-select id="breach-type" *ngIf="caseId" [model]="model" [required]="true"></app-breach-type-select>
  </div>
</div>

<div class="form-group clearfix">
  <label for="issued" class="col-sm-4 control-label">Issued</label>
  <div class="col-sm-8">
    <app-shared-date id="issued" *ngIf="caseId" [(ngModel)]="model.issued" [required]="true" [allowPast]="true" [allowFuture]="true" [hasTime]="false"></app-shared-date>
  </div>
</div>

<div class="form-group clearfix">
  <label for="expired" class="col-sm-4 control-label">Expired</label>
  <div class="col-sm-8">
    <app-shared-date id="expired" *ngIf="caseId" [(ngModel)]="model.expired" [required]="true" [allowPast]="true" [allowFuture]="true" [hasTime]="false"></app-shared-date>
  </div>
</div>

<div class="form-group clearfix">
  <label for="outcome" class="col-sm-4 control-label">Outcome</label>
  <div class="col-sm-8">
    <textarea rows="5" name="outcome" id="outcome" [(ngModel)]="model.outcome" class="form-control"></textarea>
  </div>
</div>
