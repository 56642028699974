<div class="row">
  <div class="col-sm-12">
    <section class="panel">
      <app-page-title
        title="Create Template"
        [loading]="isLoading"
      ></app-page-title>

      <div class="panel-body">
        <app-template-form
          [template]="template"
          (onSubmit)="create($event)"
          [disabled]="disabled"
          *ngIf="template"
        ></app-template-form>
      </div>
    </section>
  </div>
</div>
