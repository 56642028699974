import { ActivatedRoute, Router } from '@angular/router';

import { Component } from '@angular/core';
import { NotificationService } from '../../shared/notification.service';
import { Template } from '../template';
import { TemplateService } from '../template.service';

@Component({
  selector: 'app-template-edit',
  templateUrl: 'edit.component.html',
})
export class TemplateEditComponent {
  /**
   * Details of the template being edited.
   */
  public template: Template;

  /**
   * Are we currently performing a loading action?
   *
   * @type {boolean}
   */
  public isLoading: boolean = false;

  /**
   * Load up all our required services and subscribe to every observable we need.
   *
   * @param {TemplateService} templateService
   * @param {NotificationService} notificationService
   * @param {Router} router
   * @param {ActivatedRoute} route
   */
  constructor(
    private templateService: TemplateService,
    private notificationService: NotificationService,
    private router: Router,
    private route: ActivatedRoute,
  ) {
    this.templateService.loading.subscribe(
      (result) => (this.isLoading = result),
    );
    this.route.params.subscribe((params) => this.loadTemplate(params.id));
  }

  /**
   * Perform the edit action which send the edited template to the backend.
   */
  public edit(filename: string | undefined) {
    console.log(this.template.id, this.template);
    if (filename !== undefined) {
      this.template.templateUrl = filename;
    }
    const editTemplate = this.templateService
      .updateTemplate(this.template.id, this.template)
      .subscribe(
        (result: Template) => {
          this.notificationService.success(
            `Template Updated`,
            `The template ${result.name} been updated with the specified changes.`,
            5,
          );
          this.template = new Template();
          editTemplate.unsubscribe();
          this.router.navigate(['/templates']);
        },
        (err) => {
          this.notificationService.error(
            `Template Not Found`,
            err.error.message,
            10,
          );
          this.router.navigate(['/templates']);
        },
      );
  }

  /**
   * Loads the requested template so we can display it and let the user edit the fields.
   *
   * @param {string} id
   */
  private loadTemplate(id: string) {
    this.templateService.findTemplate(id).subscribe(
      (result) => {
        this.template = result;
        this.template.templateType =
          this.template.templateUrl && this.template.templateUrl.length > 2
            ? 'doc'
            : 'html';
      },
      (err) => {
        this.notificationService.error(
          `Template Not Found`,
          err.error.message,
          10,
        );
        this.router.navigate(['/templates']);
      },
    );
  }
}
