<div *ngIf="isLoading">Loading Template List...</div>

<select *ngIf="!isLoading && model && model !== undefined" [compareWith]="byId" class="form-control" name="template"
        [(ngModel)]="model.template" [required]="required">
  <option *ngFor="let singleTemplate of templates" [ngValue]="singleTemplate">{{ singleTemplate.document.name }} - {{ singleTemplate.name }}</option>
</select>

<select *ngIf="!isLoading && (!model || model === undefined)" [compareWith]="byId" class="form-control" name="template"
        ngModel [required]="required">
  <option *ngFor="let singleTemplate of templates" [ngValue]="singleTemplate">{{ singleTemplate.document.name }} - {{ singleTemplate.name }}</option>
</select>
