import { Component, Input } from '@angular/core';
import { Domain } from '../domain';

@Component({
  selector: 'app-domain-form',
  templateUrl: 'form.component.html',
})
export class DomainFormComponent {
  @Input() domain: Domain;
}
