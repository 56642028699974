import { Injectable } from '@angular/core';
import { Product } from './product';
import { Observable } from 'rxjs';

import { HttpRequestService } from '../shared/http/http-request.service';

@Injectable()
export class ProductService extends HttpRequestService {
  /**
   * The endpoint that we call to get the product information.
   *
   * @type {string}
   */
  private readonly endpoint: string = 'products';

  /**
   * Brings back a list of all accessible products.
   *
   * @param {number} page The page number we are currently on.
   * @param {number} limit The number of products to display per page.
   * @returns {Observable<Product[]>}
   */
  public listProducts(page?: number, limit?: number): Observable<Product[]> {
    return this.list(this.endpoint, page, undefined, limit);
  }

  /**
   * Finds details of the product from the given ID.
   *
   * @param {string} productId The ID of the product we require details for.
   * @returns {Observable<Product>}
   */
  public findProduct(productId: string): Observable<Product> {
    return this.findById(this.endpoint, productId);
  }

  /**
   * Creates a new product from the given Product object.
   *
   * @param {Product} newProduct The details of the product to be created.
   * @returns {Observable<Product | boolean>}
   */
  public createProduct(newProduct: Product): Observable<Product | boolean> {
    return this.create(this.endpoint, newProduct);
  }

  /**
   * Updates a product with the given product object for the given product ID.
   *
   * @param {string} productId The ID of the product needing updating.
   * @param {Product} newDetails The new product object to update with.
   * @returns {Observable<Product | boolean>}
   */
  public updateProduct(
    productId: string,
    newDetails: Product,
  ): Observable<Product | boolean> {
    return this.update(this.endpoint, productId, newDetails);
  }

  /**
   * Deletes a product based on the given product ID.
   *
   * @param {string} productId The ID of the product needing deleting.
   * @returns {Observable<boolean>}
   */
  public deleteProduct(productId: string): Observable<boolean> {
    return this.delete(this.endpoint, productId);
  }
}
