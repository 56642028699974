import { Component, OnInit } from '@angular/core';

import { Introducer } from '../introducer';
import { IntroducerService } from '../introducer.service';
import { NotificationService } from '../../shared/notification.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-introducer-create',
  templateUrl: 'create.component.html',
})
export class IntroducerCreateComponent implements OnInit {
  /**
   * Details of the introducer being created.
   */
  public introducer: Introducer;

  /**
   * Are we currently performing a loading action?
   *
   * @type {boolean}
   */
  public isLoading: boolean = false;

  /**
   * Load up all our required services and subscribe to every observable we need.
   *
   * @param {IntroducerService} introducerService
   * @param {NotificationService} notificationService
   * @param {Router} router
   */
  constructor(
    private introducerService: IntroducerService,
    private notificationService: NotificationService,
    private router: Router,
  ) {
    this.introducerService.loading.subscribe(
      (result) => (this.isLoading = result),
    );
  }

  /**
   * Initialise the component, creating a new blank introducer if required.
   */
  public ngOnInit(): void {
    if (!this.introducer) {
      this.introducer = new Introducer();
      this.introducer.callbackUrl = 'https://proxy.debtpanel.co.uk/feedback';
    }
  }

  /**
   * Perform the create action which adds the new introducer into the backend.
   */
  public create() {
    const createIntroducer = this.introducerService
      .createIntroducer(this.introducer)
      .subscribe(
        (result: Introducer) => {
          this.notificationService.success(
            `Introducer Created`,
            `New introducer ${result.name} has been created with ID of ${result.id}.`,
            5,
          );
          this.introducer = new Introducer();
          createIntroducer.unsubscribe();
          this.router.navigate(['/introducers']);
        },
        (err) => {
          this.notificationService.error(
            `Could not create Introducer`,
            err.error.message,
            10,
          );
        },
      );
  }
}
