import { RouterModule, Routes } from '@angular/router';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule } from '@angular/common/http';
import { TaskListComponent } from './list/list.component';
import { AuthGuard } from '../auth/auth.guard';
import { TaskService } from './task.service';
import { SharedModule } from '../shared/shared.module';
import { TaskCreateComponent } from './create/create.component';
import { TaskFormComponent } from './form/form.component';
import { TaskEditComponent } from './edit/edit.component';

const routes: Routes = [
  { path: 'tasks', component: TaskListComponent, canActivate: [AuthGuard] },
  {
    path: 'tasks/create',
    component: TaskCreateComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'tasks/:id/edit',
    component: TaskEditComponent,
    canActivate: [AuthGuard],
  },
];

@NgModule({
  declarations: [
    TaskListComponent,
    TaskCreateComponent,
    TaskFormComponent,
    TaskEditComponent,
  ],
  imports: [
    BrowserModule,
    RouterModule,
    FormsModule,
    HttpClientModule,
    SharedModule,
    RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' }),
  ],
  exports: [RouterModule],
  providers: [AuthGuard, TaskService],
})
export class TaskRoutingModule {}
