import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule } from '@angular/common/http';
import { CaseNoteListComponent } from './list/list.component';
import { SharedModule } from '../../shared/shared.module';
import { CaseNoteService } from './case-note.service';
import { QuillModule } from 'ngx-quill';
import { PopoverModule } from 'ngx-bootstrap/popover';
import { TooltipModule } from 'ngx-bootstrap/tooltip';

@NgModule({
  declarations: [CaseNoteListComponent],
  imports: [
    TooltipModule.forRoot(),
    QuillModule,
    BrowserModule,
    FormsModule,
    HttpClientModule,
    SharedModule,
  ],
  exports: [CaseNoteListComponent],
  providers: [CaseNoteService],
})
export class CaseNoteRoutingModule {}
