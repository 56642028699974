import {HttpClientModule} from '@angular/common/http';
import {NgModule} from '@angular/core';
import {FormsModule} from '@angular/forms';
import {BrowserModule} from '@angular/platform-browser';

import {SharedModule} from '../../shared/shared.module';
import {CaseChairmansReportViewComponent} from './view/view.component';

@NgModule({
  declarations: [CaseChairmansReportViewComponent],
  imports: [BrowserModule, FormsModule, HttpClientModule, SharedModule],
  exports: [CaseChairmansReportViewComponent],
  providers: [],
})
export class CaseChairmansReportRoutingModule {}
