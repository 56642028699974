<div class="chief-updates">
  <ul>
    <li *ngFor="let update of currentUpdates">
      <b>{{ update?.title }}
        <span class="label label-{{ update.tagColor ? update.tagColor : 'primary' }} pull-right">{{ update?.tag }}</span>
      </b>
      <div class="content" *ngIf="update.content">
        <div [innerHTML]="update.content.replace('\n', '\r\n') | markdown"></div>
      </div>
      <i class="text-muted">
        {{ update?.created | timeAgo }}
        <span class="pull-right" *ngIf="update.url">
          <a href="{{ update.url }}" target="_blank" class="text-primary" style="font-style: normal;">Project Link</a>
        </span>
      </i>
    </li>
  </ul>
</div>


