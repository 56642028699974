import { RouterModule, Routes } from '@angular/router';

import { AddressTypeRoutingModule } from './address-types/address-type-routing.module';
import { ApprovalRoutingModule } from './approvals/approval-routing.module';
import { AssetRoutingModule } from './assets/asset-routing.module';
import { AuthGuard } from './auth/auth.guard';
import { AuthRoutingModule } from './auth/auth-routing.module';
import { BrowserModule } from '@angular/platform-browser';
import { CaseRoutingModule } from './cases/case-routing.module';
import { ChiefTimelineModule } from './chief-timeline/chief-timeline.module';
import { ComplaintStatusRoutingModule } from './complaint-statuses/complaint-status-routing.module';
import { CountryRoutingModule } from './countries/country-routing.module';
import { CreditRoutingModule } from './credits/credit-routing.module';
import { CreditorRoutingModule } from './creditors/creditor-routing.module';
import { CreditorStatusRoutingModule } from './creditor-statuses/creditor-status-routing.module';
import { DashboardComponent } from './dashboard/dashboard.component';
import { DeliveryMethodRoutingModule } from './delivery-methods/delivery-method-routing.module';
import { DocumentRoutingModule } from './documents/document-routing.module';
import { DomainRoutingModule } from './domains/domain-routing.module';
import { EmploymentStatusRoutingModule } from './employment-statuses/employment-status-routing.module';
import { ExpenditureCategoryRoutingModule } from './expenditure-categories/expenditure-category-routing.module';
import { ExpenditureRoutingModule } from './expenditures/expenditure-routing.module';
import { FinancialsModule } from './financials/financials.module';
import { FormsModule } from '@angular/forms';
import { GenderRoutingModule } from './genders/gender-routing.module';
import { HttpClientModule } from '@angular/common/http';
import { IncomeCategoryRoutingModule } from './income-categories/income-category-routing.module';
import { IncomeRoutingModule } from './incomes/income-routing.module';
import { IntroducerRoutingModule } from './introducers/introducer-routing.module';
import { LetterheadRoutingModule } from './letterheads/letterhead-routing.module';
import { MaritalStatusRoutingModule } from './marital-statuses/marital-status-routing.module';
import { NgModule } from '@angular/core';
import { OccupationRoutingModule } from './occupations/occupation-routing.module';
import { PackRoutingModule } from './packs/pack-routing.module';
import { PermissionRoutingModule } from './permissions/permission-routing.module';
import { PortfolioRoutingModule } from './portfolios/portfolio-routing.module';
import { ProductRoutingModule } from './products/product-routing.module';
import { ReportsModule } from './reports/reports.module';
import { ResidentialStatusRoutingModule } from './residential-statuses/residential-status-routing.module';
import { SettingsModule } from './settings/settings.module';
import { SharedModule } from './shared/shared.module';
import { StatusCategoryRoutingModule } from './status-categories/status-category-routing.module';
import { StatusRoutingModule } from './statuses/status-routing.module';
import { SubstatusRoutingModule } from './substatuses/substatus-routing.module';
import { SupportModule } from './support/support.module';
import { TaskRoutingModule } from './tasks/task-routing.module';
import { TemplateRoutingModule } from './templates/template-routing.module';
import { TitleRoutingModule } from './titles/title-routing.module';
import { UserRoutingModule } from './users/user-routing.module';

const routes: Routes = [
  {
    path: 'dashboard',
    component: DashboardComponent,
    canActivate: [AuthGuard],
  },
  { path: '**', redirectTo: '/dashboard' },
];

@NgModule({
  declarations: [DashboardComponent],
  imports: [
    ApprovalRoutingModule,
    SupportModule,
    ChiefTimelineModule,
    FinancialsModule,
    SettingsModule,
    ReportsModule,
    SharedModule,
    BrowserModule,
    RouterModule,
    FormsModule,
    HttpClientModule,
    RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' }),
    AuthRoutingModule,
    DomainRoutingModule,
    PermissionRoutingModule,
    UserRoutingModule,
    IntroducerRoutingModule,
    PortfolioRoutingModule,
    CreditorRoutingModule,
    TitleRoutingModule,
    DocumentRoutingModule,
    TaskRoutingModule,
    StatusRoutingModule,
    SubstatusRoutingModule,
    CountryRoutingModule,
    ProductRoutingModule,
    OccupationRoutingModule,
    AssetRoutingModule,
    IncomeRoutingModule,
    ExpenditureRoutingModule,
    CreditRoutingModule,
    CaseRoutingModule,
    GenderRoutingModule,
    MaritalStatusRoutingModule,
    ResidentialStatusRoutingModule,
    EmploymentStatusRoutingModule,
    LetterheadRoutingModule,
    TemplateRoutingModule,
    IncomeCategoryRoutingModule,
    ExpenditureCategoryRoutingModule,
    DeliveryMethodRoutingModule,
    PackRoutingModule,
    StatusCategoryRoutingModule,
    ComplaintStatusRoutingModule,
    CreditorStatusRoutingModule,
    AddressTypeRoutingModule,
  ],
  exports: [RouterModule],
  providers: [AuthGuard],
})
export class AppRoutingModule {}
