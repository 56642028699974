import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-case-calendar-form',
  templateUrl: 'form.component.html',
})
export class CaseCalendarFormComponent {
  @Input() public caseId: string;

  @Input() public type: string;

  @Input() model: any;

  public editorOptions = {
    placeholder: 'Enter your note...',
    modules: {
      toolbar: ['bold', 'link'],
    },
  };
}
