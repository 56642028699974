<div class="row">
  <div class="col-sm-12">
    <section class="panel">
      <app-page-title [loading]="isLoading" title="List Users">
        {{ totalResults }} Results Found
      </app-page-title>

      <div class="panel-body">
        <table class="table table-striped">
          <thead>
          <tr>
            <th *ngIf="authService.hasPermission('config.multiple-domains') || authService.hasPermission('config.all-domains')"
                width="30"></th>
            <th width="30"></th>
            <th>Name</th>
            <th>E-Mail Address</th>
            <th>Department</th>
            <th class="text-right">
              <a [routerLink]="['/users/create']" class="btn btn-success">Create</a>
            </th>
          </tr>
          </thead>

          <tbody>
          <tr *ngFor="let user of users">
            <td
                    *ngIf="authService.hasPermission('config.multiple-domains') || authService.hasPermission('config.all-domains')">
              <app-domain-icon *ngIf="user?.domain.id" [domainId]="user?.domain?.id"
                               [passedDomain]="user?.domain"
                               [size]="24"></app-domain-icon>
            </td>
            <td>
              <app-user-icon [passedUser]="user" [size]="24" [userId]="user?.id"></app-user-icon>
            </td>
            <td>{{ user?.name }}</td>
            <td>{{ user?.emailAddress }}</td>
            <td>{{ user?.department?.name }}</td>
            <td class="text-right">
              <div class="btn-group">
                <a [routerLink]="['/users', user?.id, 'edit']" class="btn btn-sm btn-warning"
                   type="button">Edit</a>
                <button aria-expanded="false" aria-haspopup="true"
                        class="btn btn-sm btn-warning dropdown-toggle"
                        data-toggle="dropdown" type="button">
                  <span class="caret"></span>
                  <span class="sr-only">Toggle Dropdown</span>
                </button>
                <ul class="dropdown-menu">
                  <li><a (click)="deleteModal.confirmDialog(user.id)" href="javascript:">Delete</a>
                  </li>
                </ul>
              </div>
            </td>
          </tr>
          </tbody>

          <tfoot>
          <tr>
            <td class="text-center" colspan="99">
              <app-pagination (pageChanged)="onPageChanged($event)" [currentPage]="currentPage"
                              [totalPages]="totalPages"></app-pagination>
            </td>
          </tr>
          </tfoot>
        </table>
      </div>
    </section>
  </div>
</div>

<app-delete-modal #deleteModal (confirm)="delete($event)" [itemDeleted]="userDeleted" type="user"></app-delete-modal>
