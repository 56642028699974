<div class="form-group">
  <label for="domain" class="col-sm-2 control-label">Domain</label>
  <div class="col-sm-10">
    <app-domain-select
      [model]="introducer"
      [required]="true"
    ></app-domain-select>
  </div>
</div>

<div class="form-group" *ngIf="shouldLoadDebtpanelDomains">
  <label for="domain" class="col-sm-2 control-label">Debtpanel Domain</label>
  <div class="col-sm-10">
    <select [(ngModel)]="introducer.debtpanelDomainId" class="form-control">
      <option [value]="undefined">Do not link to DebtPanel</option>
      <option [value]="domain.id" *ngFor="let domain of debtpanelDomains">
        {{ domain.name }}
      </option>
    </select>
  </div>
</div>

<div class="form-group">
  <label for="name" class="col-sm-2 control-label">Name</label>
  <div class="col-sm-10">
    <input
      type="text"
      class="form-control"
      name="name"
      id="name"
      placeholder="Introducer Name"
      [(ngModel)]="introducer.name"
      required
    />
  </div>
</div>

<div class="form-group">
  <label for="product" class="col-sm-4 control-label">Product</label>
  <div class="col-sm-8">
    <app-product-select
      [model]="introducer"
      [required]="false"
    ></app-product-select>
  </div>
</div>

<app-shared-address [model]="introducer"></app-shared-address>

<app-shared-contact-details [model]="introducer"></app-shared-contact-details>

<div class="form-group">
  <label for="callbackUrl" class="col-sm-2 control-label">Callback Url</label>
  <div class="col-sm-10">
    <input
      type="text"
      class="form-control"
      name="callbackUrl"
      id="callbackUrl"
      placeholder="Callback Url"
      [(ngModel)]="introducer.callbackUrl"
    />
  </div>
</div>

<div class="form-group">
  <div class="col-sm-offset-2 col-sm-10 text-right">
    <button type="submit" class="btn btn-success">Save</button>
  </div>
</div>
