import { useEffect, useState } from 'react';

type UseStoredValueState<T> = [T, (value: T) => void, boolean];

const useStoredValue = <T = string>(
  key: string,
  defaultValue: T,
  isJson: boolean = false,
): UseStoredValueState<T> => {
  const [storageChecked, setStorageChecked] = useState<boolean>(false);
  const [value, setValue] = useState<T>(defaultValue);

  // If the value has changed then we store the updated state in localstorage
  useEffect(() => {
    if (storageChecked === true) {
      if (value === undefined) {
        localStorage.removeItem(key);
      } else {
        localStorage.setItem(
          key,
          (isJson ? JSON.stringify(value) : value) as string,
        );
      }
    }
  }, [value]);

  // On load lets see if there's a stored value in localstorage
  useEffect(() => {
    const storedValue: string | null = localStorage.getItem(key);

    if (
      storedValue !== null &&
      storedValue !== 'undefined' &&
      storedValue !== undefined
    ) {
      setValue(isJson ? JSON.parse(storedValue) : storedValue);
    }

    setStorageChecked(true);
  }, []);

  return [value, setValue, storageChecked];
};

export default useStoredValue;
