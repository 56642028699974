import { Component, OnInit } from '@angular/core';
import { PackService } from '../pack.service';
import { Pack } from '../pack';
import { Router } from '@angular/router';
import { NotificationService } from '../../shared/notification.service';

@Component({
  selector: 'app-pack-create',
  templateUrl: 'create.component.html',
})
export class PackCreateComponent implements OnInit {
  /**
   * Details of the pack being created.
   */
  public pack: Pack;

  /**
   * Are we currently performing a loading action?
   *
   * @type {boolean}
   */
  public isLoading: boolean = false;

  /**
   * Load up all our required services and subscribe to every observable we need.
   *
   * @param {PackService} packService
   * @param {NotificationService} notificationService
   * @param {Router} router
   */
  constructor(
    private packService: PackService,
    private notificationService: NotificationService,
    private router: Router,
  ) {
    this.packService.loading.subscribe(result => (this.isLoading = result));
  }

  /**
   * Initialise the component, creating a new blank pack if required.
   */
  public ngOnInit(): void {
    if (!this.pack) {
      this.pack = new Pack();
      this.pack.templates = [];
    }
  }

  /**
   * Perform the create action which adds the new pack into the backend.
   */
  public create() {
    const createPack = this.packService.createPack(this.pack).subscribe(
      (result: Pack) => {
        this.notificationService.success(
          `Pack Created`,
          `New pack ${result.name} has been created with ID of ${result.id}.`,
          5,
        );
        this.pack = new Pack();
        createPack.unsubscribe();
        this.router.navigate(['/packs']);
      },
      err => {
        this.notificationService.error(
          `Could not create Pack`,
          err.error.message,
          10,
        );
      },
    );
  }
}
