import {NgModule} from '@angular/core';
import {ChartOfAccountsRoutingModule} from './chart-of-accounts/chart-of-accounts-routing.module';
import {FinancialAccountsRoutingModule} from './accounts/accounts-routing.module';
import {FinancialLedgerRoutingModule} from './ledger/ledger-routing.module';
import {BankingModule} from './banking/banking.module';
import {FinancialsInvoicingRoutingModule} from './invoicing/financials-invoicing-routing.module';
import {FinancialsDistributionRoutingModule} from './distributions/financials-distribution-routing.module';
import {BankingMatchingRoutingModule} from './banking/matching/banking-matching-routing.module';

@NgModule({
  imports: [
    BankingModule,
    FinancialsDistributionRoutingModule,
    FinancialsInvoicingRoutingModule,
    ChartOfAccountsRoutingModule,
    FinancialAccountsRoutingModule,
    FinancialLedgerRoutingModule,
    BankingMatchingRoutingModule,
  ],
})
export class FinancialsModule {
}
