<div class="text-right button-menu">
  <button type="button" class="btn btn-sm btn-success" href="javascript:;" (click)="create.open(caseId)">
    Add Asset
  </button>
</div>

<table class="assets table table-striped">
  <thead>
  <tr>
    <th width="40" class="text-center"></th>
    <th width="40" class="text-center"></th>
    <th>Type</th>
    <th>Value</th>
    <th>Applicant</th>
    <th>Creditor</th>
    <th></th>
  </tr>
  </thead>
  <tbody>
  <tr *ngFor="let caseAsset of caseAssets">
    <td>
      <app-user-icon *ngIf="caseAsset.user" [userId]="caseAsset.user.id" [size]="20"
                     [passedUser]="caseAsset.user"></app-user-icon>
    </td>
    <td><i class="fa" [class.fa-check-circle]="!caseAsset.excluded"  [class.fa-times-circle]="caseAsset.excluded"></i></td>
    <td>{{ caseAsset.asset ? caseAsset.asset.name : 'Unknown' }}</td>
    <td>{{ caseAsset.value / 100 | toCurrency }}</td>
    <td *ngIf="caseAsset.person">{{ caseAsset?.person?.firstName }} {{ caseAsset?.person?.lastName }}</td>
    <td *ngIf="!caseAsset.person">Joint</td>
    <td>{{ caseAsset?.caseCreditor?.creditor?.name }}</td>
    <td class="text-right">
      <div class="btn-group">
        <a type="button" class="btn btn-xs btn-warning" (click)="edit.open(caseId, caseAsset)">Edit</a>
        <button type="button" class="btn btn-xs btn-warning dropdown-toggle" data-toggle="dropdown"
                aria-haspopup="true" aria-expanded="false" *ngIf="authService.hasPermission('user.delete-cases')">
          <span class="caret" *ngIf="authService.hasPermission('user.delete-cases')"></span>
          <span class="sr-only" *ngIf="authService.hasPermission('user.delete-cases')">Toggle Dropdown</span>
        </button>
        <ul class="dropdown-menu" *ngIf="authService.hasPermission('user.delete-cases')">
          <li *ngIf="authService.hasPermission('user.delete-cases')"><a href="javascript:;"
                                                                        (click)="deleteModal.confirmDialog(caseAsset.id)">Delete</a>
          </li>
        </ul>
      </div>
    </td>
  </tr>
  </tbody>
  <tfoot>
  <tr>
    <td colspan="99" class="text-center">
      <app-pagination [totalPages]="totalPages" [currentPage]="currentPage"
                      (pageChanged)="onPageChanged($event)"></app-pagination>
    </td>
  </tr>
  </tfoot>
</table>

<app-case-asset-create (completed)="onPageChanged(1)" #create></app-case-asset-create>

<app-case-asset-edit (completed)="onPageChanged(1)" #edit></app-case-asset-edit>

<app-delete-modal type="asset" (confirm)="delete($event)" [itemDeleted]="itemDeleted" #deleteModal></app-delete-modal>
