import {HttpClientModule} from '@angular/common/http';
import {NgModule} from '@angular/core';
import {FormsModule} from '@angular/forms';
import {BrowserModule} from '@angular/platform-browser';
import {ModalModule} from 'ngx-bootstrap/modal';

import {SharedModule} from '../../shared/shared.module';
import {CaseCreditorVotesService} from './case-creditor-vote.service';
import {CaseCreditorVotesEditComponent} from './edit/edit.component';
import {CaseCreditorVotesFormComponent} from './form/form.component';
import {CaseCreditorVotesListComponent} from './list/list.component';

@NgModule({
  declarations: [
    CaseCreditorVotesListComponent,
    CaseCreditorVotesEditComponent,
    CaseCreditorVotesFormComponent,
  ],
  imports: [
    ModalModule.forRoot(),
    BrowserModule,
    FormsModule,
    HttpClientModule,
    SharedModule,
  ],
  exports: [CaseCreditorVotesListComponent],
  providers: [CaseCreditorVotesService],
})
export class CaseCreditorVoteRoutingModule {}
