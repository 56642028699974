<div *ngIf="isLoading">Loading Permission List...</div>

<div *ngIf="!isLoading">

  <div class="checkbox" *ngFor="let singlePermission of permissions">
    <label>
      <input type="checkbox" [checked]="isValidPermission(singlePermission) !== false"
             (change)="updatePermission(singlePermission)"> {{ singlePermission.description }}
    </label>
  </div>

</div>

