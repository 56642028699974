import { Component, Input, OnInit } from '@angular/core';
import { AssetService } from '../asset.service';
import { Asset } from '../asset';
import { NotificationService } from '../../shared/notification.service';
import { SelectComparisons } from '../../shared/pipes/select.comparisons';

@Component({
  selector: 'app-asset-select',
  templateUrl: 'select.component.html',
})
export class AssetSelectComponent extends SelectComparisons implements OnInit {
  /**
   * The model that we will be adding the asset to.
   */
  @Input() model: any;

  /**
   * Do we require this field in the main form it is
   * included in?
   */
  @Input() required: boolean;

  /**
   * A list of all the assets currently loaded.
   *
   * @type {Asset[]}
   */
  public assets: Asset[] = [];

  /**
   * Are we currently performing a loading action?
   *
   * @type {boolean}
   */
  public isLoading: boolean = true;

  /**
   * Load up all our required services and subscribe to every observable we need.
   *
   * @param {AssetService} assetService
   * @param {NotificationService} notificationService
   */
  constructor(
    private assetService: AssetService,
    private notificationService: NotificationService,
  ) {
    super();
    this.assetService.loading.subscribe(isLoading => {
      this.isLoading = isLoading;
    });
  }

  /**
   * When the component is initialised we set it to load
   * page one of the asset list.
   */
  public ngOnInit(): void {
    this.loadAssets(1, 10000);
  }

  /**
   * Loads a list of assets using the given per page limit.
   *
   * @param {number} page The current page we require.
   * @param {number} limit The number of results per page.
   */
  public loadAssets(page?: number, limit?: number): void {
    const listAssets = this.assetService
      .listAssets(page, limit)
      .subscribe((assets: Asset[]) => {
        this.assets = assets;
        listAssets.unsubscribe();
      });
  }
}
