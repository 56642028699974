<div class="row">
  <div class="col-sm-12">
    <section class="panel">
      <app-page-title
        title="Reconcile Invoice"
        [loading]="isLoading"
      ></app-page-title>

      <div class="panel-body">
        <div class="col-md-8">
          <table class="table">
            <thead>
              <tr>
                <th>Client</th>
                <th>Expected Value</th>
                <th>Assigned Value</th>
                <th>Write Off?</th>
                <th class="text-right">
                  <button
                    class="btn btn-success"
                    (click)="createClientReconciliationModal.confirmDialog()"
                  >
                    <i class="fa fa-plus-circle"></i>
                  </button>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let client of reconciledClients; let i = index">
                <td>
                  {{ client?.client?.unique_reference }} -
                  {{ client?.client?.case_name }}
                </td>
                <td>
                  {{ client?.expectedValue / 100 | toAccountancy }}
                </td>
                <td>
                  <app-shared-currency
                    [(ngModel)]="client.value"
                    (change)="saveCurrentLines(client)"
                  ></app-shared-currency>
                </td>
                <td>
                  <div class="form-inline">
                    <input
                      type="checkbox"
                      class="form-control"
                      [(ngModel)]="client.writeOff"
                      (change)="saveCurrentLines(client)"
                    />
                    <span>Write off value?</span>
                  </div>

                  <app-shared-currency
                    *ngIf="client.writeOff"
                    [(ngModel)]="client.writeOffValue"
                    (change)="saveCurrentLines(client)"
                  ></app-shared-currency>
                </td>
                <td class="text-right">
                  <button
                    class="btn btn-danger"
                    (click)="deleteLine(client.id)"
                  >
                    <i class="fa fa-trash"></i>
                  </button>
                </td>
              </tr>
            </tbody>
            <tfoot>
              <tr>
                <th
                  [class.text-success]="
                    invoice?.value === currentTotal + writeOffTotal
                  "
                  [class.text-danger]="
                    invoice?.value !== currentTotal + writeOffTotal
                  "
                >
                  Invoice Total: {{ invoice?.value / 100 | toAccountancy
                  }}<br />
                </th>
                <th
                  [class.text-success]="
                    invoice?.value === currentTotal + writeOffTotal
                  "
                  [class.text-danger]="
                    invoice?.value !== currentTotal + writeOffTotal
                  "
                >
                  Assigned Total: {{ currentTotal / 100 | toAccountancy }}
                </th>
                <th
                  [class.text-success]="
                    invoice?.value === currentTotal + writeOffTotal
                  "
                  [class.text-danger]="
                    invoice?.value !== currentTotal + writeOffTotal
                  "
                >
                  Write Off Total: {{ writeOffTotal / 100 | toAccountancy
                  }}<br />
                </th>
                <th
                  [class.text-success]="
                    invoice?.value === currentTotal + writeOffTotal
                  "
                  [class.text-danger]="
                    invoice?.value !== currentTotal + writeOffTotal
                  "
                >
                  Reconciled Total:
                  {{ (currentTotal + writeOffTotal) / 100 | toAccountancy }}
                </th>
                <th class="text-right">
                  <button
                    class="btn btn-success"
                    *ngIf="invoice?.value === currentTotal + writeOffTotal"
                    (click)="reconcile()"
                  >
                    Reconcile
                  </button>
                </th>
              </tr>
            </tfoot>
          </table>
        </div>
        <div class="col-md-4">
          <div class="document-holder" *ngIf="documentUrl">
            <iframe
              [src]="documentUrl"
              width="100%"
              height="600"
              type="application/pdf"
              frameborder="0"
            ></iframe>
          </div>
        </div>
      </div>
    </section>
  </div>
</div>

<div class="row">
  <div class="col-sm-12">
    <section class="panel panel-danger">
      <app-page-title
        title="Reconcile Errors"
        [loading]="isLoading"
      ></app-page-title>

      <div class="panel-body">
        <div class="col-md-12">
          <table class="table">
            <thead>
              <tr>
                <th>Client</th>
                <th>Value</th>
                <th>Expected Value</th>
                <th>Error</th>
                <th>&nbsp;</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let client of reconciledErrors; let i = index">
                <td>
                  <div *ngIf="!client.client">
                    <a [href]="['/cases/' + client?.case_id]" target="_blank">{{
                      client?.case_id
                    }}</a>
                  </div>
                  <div *ngIf="client.client">
                    <a [href]="['/cases/' + client?.case_id]" target="_blank">
                      {{ client?.client?.unique_reference }} -
                      {{ client?.client?.case_name }}
                    </a>
                  </div>
                </td>
                <td>
                  {{ client.value / 100 | toAccountancy }}
                </td>
                <td>
                  <div *ngIf="!client.expectedValue">-</div>
                  <div *ngIf="client.expectedValue">
                    {{ client.expectedValue / 100 | toAccountancy }}
                  </div>
                </td>
                <td>
                  {{ client?.message }}
                </td>
                <td class="text-right">
                  <button
                    *ngIf="client.expectedValue"
                    class="btn btn-danger btn-sm"
                    (click)="writeOffDifference(client)"
                  >
                    Write Off Difference
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </section>
  </div>
</div>

<app-create-modal
  type="client reconciliation"
  (confirm)="addLine($event)"
  (cancel)="clearFoundOptions()"
  [itemCreated]="reconciliationCreated"
  #createClientReconciliationModal
>
  <div class="form-group clearfix">
    <label for="reference" class="col-sm-3 control-label">Search</label>
    <div class="col-sm-6">
      <input
        name="search-filter"
        class="form-control"
        type="text"
        placeholder="Search filter..."
        [(ngModel)]="accountSelect.filter"
      />
    </div>
    <button
      class="btn btn-success col-sm-3"
      type="button"
      (click)="performSearch(accountSelect.filter)"
    >
      Filter
    </button>
  </div>

  <div *ngIf="foundCases.length > 0">
    <table class="table table-striped">
      <thead>
        <tr>
          <th>Client</th>
          <th>Reference</th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let case of foundCases">
          <td>{{ case.case_name }}</td>
          <td>{{ case.unique_reference }}</td>
          <td>
            <button
              class="btn btn-xs btn-success"
              type="button"
              (click)="
                getDuePaymentsForCase(case, invoice.chart_of_accounts_id)
              "
            >
              <i class="fa fa-check"></i>
            </button>
          </td>
        </tr>
      </tbody>
    </table>
  </div>

  <div *ngIf="matchedCoA">
    <select
      class="form-control"
      [(ngModel)]="accountSelect.chartOfAccountsItem"
    >
      <option>Please confirm your choice</option>
      <option [ngValue]="matchedCoA">
        {{ matchedCoA.chartOfAccount?.name }} -
        {{ matchedCoA.ivaValue / 100 | toCurrency }}
      </option>
    </select>
  </div>
</app-create-modal>
