import * as moment from 'moment';

import { ActivatedRoute, Router } from '@angular/router';
import { Component, EventEmitter, Input, OnInit } from '@angular/core';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { AngularFirestore } from '@angular/fire/firestore';
import { AuthService } from '../../../auth/auth.service';
import { Case } from '../../../cases/case';
import { CaseChartOfAccountsService } from '../../../cases/chart-of-accounts/case-chart-of-accounts.service';
import { CaseService } from '../../../cases/case.service';
import { FinancialsInvoicingService } from '../financials-invoicing.service';
import { NotificationService } from '../../../shared/notification.service';
import { map } from 'rxjs/operators';

@Component({
  selector: 'app-financials-invoice-reconcile',
  templateUrl: 'reconcile.component.html',
  styleUrls: [],
})
export class FinancialsInvoiceReconcileComponent implements OnInit {
  public invoice: any;
  public isLoading: boolean = false;

  accountSelect: any = {
    account: undefined,
    reconcileOptions: 'all',
    filter: '',
    chosenCase: undefined,
    ivaValue: 0,
    chartOfAccountsItem: undefined,
  };

  public matchedCoA: any;
  public invoiceId: string = undefined;

  public reconciledClients: any[] = [];
  public reconciledErrors: any[] = [];
  public reconciliationCreated: EventEmitter<boolean> = new EventEmitter<
    boolean
  >();
  public documentUrl: SafeUrl;

  private readonly httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + this.authService.getToken(),
    }),
  };

  constructor(
    private readonly sanitizer: DomSanitizer,
    private notificationService: NotificationService,
    private router: Router,
    private route: ActivatedRoute,
    private readonly authService: AuthService,
    private readonly invoicingService: FinancialsInvoicingService,
    private readonly caseService: CaseService,
    private readonly caseChartOfAccountsService: CaseChartOfAccountsService,
    private readonly db: AngularFirestore,
  ) {}

  ngOnInit(): void {
    this.route.params.subscribe((params) => this.loadInvoice(params.id));
  }

  public foundCases: Case[] = [];

  public performSearch(value): void {
    this.caseService.search(value).subscribe((cases: any) => {
      this.foundCases = cases;
    });
  }

  public clearFoundOptions() {
    this.foundCases = [];
    this.matchedCoA = undefined;

    this.accountSelect = {
      account: undefined,
      reconcileOptions: 'all',
      filter: '',
      chosenCase: undefined,
      ivaValue: 0,
      chartOfAccountsItem: undefined,
    };
  }

  public addLine(event) {
    const newDetails = {
      client: this.accountSelect.chosenCase,
      chartOfAccountsItem: this.accountSelect.chartOfAccountsItem,
      expectedValue: this.accountSelect.chartOfAccountsItem.ivaValue,
      value: this.accountSelect.chartOfAccountsItem.ivaValue,
      writeOff: false,
      writeOffValue: 0,
      added: new Date().toUTCString(),
    };

    // this.reconciledClients.push(newDetails);
    // this.saveCurrentLines();

    this.db
      .collection(`/financials-invoice-reconciliations/${this.invoiceId}/items`)
      .add(newDetails)
      .then(() => {
        this.clearFoundOptions();
        this.reconciliationCreated.next(true);
      });
  }

  public deleteLine(item) {
    this.db
      .doc(
        `/financials-invoice-reconciliations/${this.invoiceId}/items/${item}`,
      )
      .delete();
  }

  public get currentTotal(): number {
    let totalValue = 0;

    this.reconciledClients.forEach((item) => (totalValue += item.value));

    return totalValue;
  }

  public get writeOffTotal(): number {
    let totalValue = 0;

    this.reconciledClients.forEach(
      (item) => (totalValue += item.writeOffValue),
    );

    return totalValue;
  }

  protected loadInvoice(id) {
    this.db
      .collection(`/financials-invoice-reconciliations/${id}/items`)
      .snapshotChanges()
      .pipe(
        map((actions) =>
          actions.map((a) => {
            const data = a.payload.doc.data() as any;
            const id = a.payload.doc.id;
            return { id, ...data };
          }),
        ),
      )
      .subscribe((data) => {
        this.reconciledClients = data.sort((a, b) => {
          if (a.added > b.added) {
            return 1;
          }
          if (b.added > a.added) {
            return -1;
          }
          return 0;
        });
        this.documentUrl = this.url;
      });

    this.db
      .collection(`/financials-invoice-reconciliations/${id}/errors`)
      .snapshotChanges()
      .pipe(
        map((actions) =>
          actions.map((a) => {
            const data = a.payload.doc.data() as any;
            const id = a.payload.doc.id;
            return { id, ...data };
          }),
        ),
      )
      .subscribe((data) => {
        this.reconciledErrors = data;
      });

    this.invoiceId = id;
    this.isLoading = true;
    this.invoicingService.findInvoice(id).subscribe(
      (result) => {
        this.isLoading = false;
        this.invoice = result;
      },
      (err) => {
        this.isLoading = false;
        this.notificationService.error(
          `Invoice Not Found`,
          err.error.message,
          10,
        );
        this.router.navigate(['/financials/invoicing']);
      },
    );
  }

  public get url() {
    if (!this.invoice || !this.invoice.secureFileLink) {
      return undefined;
    }
    return this.sanitizer.bypassSecurityTrustResourceUrl(
      `https://drive.google.com/viewerng/viewer?embedded=true&url=${encodeURIComponent(
        this.invoice.secureFileLink,
      )}`,
    );
  }

  public getDuePaymentsForCase(chosenCase, chartOfAccountsId) {
    this.accountSelect.chosenCase = chosenCase;
    this.caseChartOfAccountsService
      .getChartOfAccountEntryForCase(chosenCase.id, chartOfAccountsId)
      .subscribe(
        (result) => {
          this.matchedCoA = result;
        },
        (err) => {
          console.error(err);
        },
      );
    console.log('Doing it now!');
  }

  public reconcile() {
    this.invoicingService
      .reconcileInvoice(this.invoice.id, this.reconciledClients)
      .subscribe((res) => {
        this.notificationService.success(
          `Invoice Reconciled`,
          `Invoice Has been Reconciled to ${
            this.reconciledClients.length
          } clients totalling £${this.currentTotal / 100}.`,
          10,
        );
        this.router.navigate(['/financials/invoicing']);
      });
  }

  public saveCurrentLines(client): void {
    this.db
      .doc(
        `/financials-invoice-reconciliations/${this.invoice.id}/items/${client.id}`,
      )
      .update(client);
  }

  public writeOffDifference(line: any) {
    const deleteId = line.id;
    const invoiceBalance = line.value;
    const remainingBalance = line.expectedValue;

    const writeOffBalance = invoiceBalance - remainingBalance;

    line.writeOff = true;
    line.value = remainingBalance;
    line.writeOffValue = writeOffBalance;
    delete line.id;

    line.added = new Date().toUTCString();

    this.db
      .collection(
        `/financials-invoice-reconciliations/${this.invoice.id}/items`,
      )
      .add(line);
    this.db
      .doc(
        `/financials-invoice-reconciliations/${this.invoice.id}/errors/${deleteId}`,
      )
      .delete();
  }
}
