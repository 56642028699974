<div class="form-group clearfix">
  <label for="reference" class="col-sm-4 control-label">Asset Type</label>
  <div class="col-sm-8">
    <app-asset-select [model]="model" [required]="true"></app-asset-select>
  </div>
</div>

<div class="form-group clearfix">
  <label for="reference" class="col-sm-4 control-label">Applicant</label>
  <div class="col-sm-8">
    <app-shared-person-select
      *ngIf="caseId"
      [caseId]="caseId"
      [model]="model"
      [required]="true"
    ></app-shared-person-select>
  </div>
</div>

<div class="form-group clearfix">
  <label for="reference" class="col-sm-4 control-label">Linked Creditor</label>
  <div class="col-sm-8">
    <app-shared-case-creditor-select
      *ngIf="caseId"
      [caseId]="caseId"
      [model]="model"
      [required]="false"
    ></app-shared-case-creditor-select>
  </div>
</div>

<div class="form-group clearfix">
  <label for="reference" class="col-sm-4 control-label">Value</label>
  <div class="col-sm-8">
    <app-shared-currency
      [required]="true"
      [(ngModel)]="model.value"
    ></app-shared-currency>
  </div>
</div>

<div class="form-group clearfix">
  <label for="reference" class="col-sm-4 control-label">Excluded</label>
  <div class="col-sm-8">
    <app-boolean-answer [(ngModel)]="model.excluded"></app-boolean-answer>
  </div>
</div>

<div class="form-group clearfix" *ngIf="!model.excluded">
  <label for="receivedValue" class="col-sm-4 control-label"
    >Received Value</label
  >
  <div class="col-sm-8">
    <app-shared-currency
      [required]="false"
      [(ngModel)]="model.receivedValue"
    ></app-shared-currency>
  </div>
</div>

<div class="form-group clearfix">
  <label for="reference" class="col-sm-4 control-label">Note</label>
  <div class="col-sm-8">
    <quill-editor name="note" [(ngModel)]="model.note"></quill-editor>
  </div>
</div>
