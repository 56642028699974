import {MouseEventHandler, useEffect, useRef, useState} from 'react';
import {LinkWrapper} from 'react-inside-angular';
import {Link as RRDLink, useHistory as RRDUseHistory} from 'react-router-dom';
import useOutsideClick from '../Hooks/UseOutsideClick';

import useToggle from '../Hooks/UseToggle';
import {buttonStyle} from './LinkButton';

export type LinkDropdownProps = {
  Navigate?: any;
  color: string;
  title: string;
  to: string;
  className?: string;
  children: any;
};

const LinkDropdown = ({
  children,
  color,
  title,
  Navigate,
  to,
}: LinkDropdownProps) => {
  const history = Navigate ? LinkWrapper(Navigate).useHistory : RRDUseHistory();
  const [dropdownVisible, toggledropdown, setDropdownVisibility] = useToggle();

  const dropdownNode: any = useRef();

  useOutsideClick(dropdownNode, () => {
    setDropdownVisibility(false);
  });

  return (
    <div className="tw-relative tw-inline-block">
      <div className="tw-flex tw-flex-row tw-w-max tw-z-10 ">
        <div
          className={buttonStyle(
            color,
            `tw-flex-1 tw-cursor-pointer tw-rounded-r-none tw-border-r-0`,
            !!Navigate,
          )}
          onClick={(e) => {
            e.preventDefault();
            history.push(to);
          }}
        >
          {title}
        </div>
        <div
          className={buttonStyle(
            color,
            `tw-cursor-pointer tw-rounded-l-none tw-border-l-0 tw-items-center tw-flex`,
            !!Navigate,
            1,
            0,
          )}
          onClick={(e) => {
            e.preventDefault();
            toggledropdown();
          }}
        >
          <div className="tw-place-self-center">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="tw-h-4 tw-w-4"
              viewBox="0 0 20 20"
              fill="currentColor"
            >
              <path
                fill-rule="evenodd"
                d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                clip-rule="evenodd"
              />
            </svg>
          </div>
        </div>
      </div>
      {dropdownVisible === true && (
        <div
          ref={dropdownNode}
          className="tw-text-left tw-w-40 tw-flex tw-flex-col tw-gap-1 tw-px-2 tw-py-2 tw-z-50 tw-origin-top-right tw-absolute tw-right-0 tw-bg-white tw-rounded-sm tw-ring-2 tw-ring-gray-100 tw-shadow-sm tw-mt-2"
        >
          {children}
        </div>
      )}
    </div>
  );
};

export type LinkDropdownLinkProps = {
  Navigate?: any;
  children: any;
  to?: string;
  onClick?: MouseEventHandler<HTMLDivElement>;
  className?: string;
};

const LinkDropdownLink = ({
  to,
  Navigate,
  className,
  children,
  onClick,
}: LinkDropdownLinkProps) => {
  const Link: any = Navigate ? LinkWrapper(Navigate).Component : RRDLink;

  return to ? (
    <Link
      to={to}
      className={`tw-px-2 tw-py-1 tw-flex-1 tw-flex tw-flex-row hover:tw-bg-gray-200 tw-rounded-md ${className}`}
    >
      {children}
    </Link>
  ) : (
    <div
      onClick={onClick}
      className={`tw-cursor-pointer tw-px-2 tw-py-1 tw-flex-1 tw-flex tw-flex-row hover:tw-bg-gray-200 tw-rounded-md ${className}`}
    >
      {children}
    </div>
  );
};

LinkDropdown.Link = LinkDropdownLink;

export default LinkDropdown;
