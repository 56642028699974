import { Component, Input, OnInit } from '@angular/core';
import { Person } from '../person';

@Component({
  selector: 'app-shared-person-edit',
  templateUrl: 'edit.component.html',
})
export class SharedPersonEditComponent {
  /**
   * The model that we will be adding the portfolio to.
   */
  @Input() model: any;

  @Input() requiredFields: string[] = [];

  public isRequired(item): boolean {
    return this.requiredFields.indexOf(item) >= 0;
  }
}
