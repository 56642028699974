import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { TypeaheadModule } from 'ngx-bootstrap/typeahead';
import { QuillModule } from 'ngx-quill';
import { ModalModule } from 'ngx-bootstrap/modal';

import { AssetSelectComponent } from '../assets/select/select.component';
import { CasePersonAddressEditComponent } from '../cases/people/addresses/edit/edit.component';
import { SharedPersonEditComponent } from '../cases/people/edit/edit.component';
import { SharedPersonSelectComponent } from '../cases/people/select/select.component';
import { SharedPersonViewComponent } from '../cases/people/view/view.component';
import { CaseSelectComponent } from '../cases/select/select.component';
import { CountrySelectComponent } from '../countries/select/select.component';
import { CreditorStatusSelectComponent } from '../creditor-statuses/select/select.component';
import { CreditorSelectComponent } from '../creditors/select/select.component';
import { CreditSelectComponent } from '../credits/select/select.component';
import { DeliveryMethodSelectComponent } from '../delivery-methods/select/select.component';
import { DocumentSelectComponent } from '../documents/select/select.component';
import { DomainSelectComponent } from '../domains/select/select.component';
import { EmploymentStatusSelectComponent } from '../employment-statuses/select/select.component';
import { ExpenditureCategorySelectComponent } from '../expenditure-categories/select/select.component';
import { ExpenditureSelectComponent } from '../expenditures/select/select.component';
import { FinancialAccountsSelectComponent } from '../financials/accounts/select/select.component';
import { ChartOfAccountsSelectComponent } from '../financials/chart-of-accounts/select/select.component';
import { FinancialLedgerSelectComponent } from '../financials/ledger/select/select.component';
import { GenderSelectComponent } from '../genders/select/select.component';
import { IncomeCategorySelectComponent } from '../income-categories/select/select.component';
import { IncomeSelectComponent } from '../incomes/select/select.component';
import { IntroducerSelectComponent } from '../introducers/select/select.component';
import { LetterheadSelectComponent } from '../letterheads/select/select.component';
import { MaritalStatusSelectComponent } from '../marital-statuses/select/select.component';
import { OccupationSelectComponent } from '../occupations/select/select.component';
import { PackSelectComponent } from '../packs/select/select.component';
import { PermissionSelectComponent } from '../permissions/select/select.component';
import { PortfolioSelectComponent } from '../portfolios/select/select.component';
import { ProductSelectComponent } from '../products/select/select.component';
import { ResidentialStatusSelectComponent } from '../residential-statuses/select/select.component';
import { AccountGroupSelectComponent } from '../settings/account-groups/select/select.component';
import { AccountTypeSelectComponent } from '../settings/account-types/select/select.component';
import { BreachTypeSelectComponent } from '../settings/breach-types/select/select.component';
import { DepartmentSelectComponent } from '../settings/departments/select/select.component';
import { FolderSelectComponent } from '../settings/folders/select/select.component';
import { IncomeTypeSelectComponent } from '../settings/income-types/select/select.component';
import { SupplierSelectComponent } from '../settings/suppliers/select/select.component';
import { StatusCategorySelectComponent } from '../status-categories/select/select.component';
import { StatusSelectComponent } from '../statuses/select/select.component';
import { SubstatusSelectComponent } from '../substatuses/select/select.component';
import { TaskSelectComponent } from '../tasks/select/select.component';
import { TemplateSelectComponent } from '../templates/select/select.component';
import { TitleSelectComponent } from '../titles/select/select.component';
import { UserSelectComponent } from '../users/select/select.component';
import { SharedAddressComponent } from './address/address.component';
import { PostcodeService } from './address/postcode.service';
import { SharedContactDetailsComponent } from './contact-details/contact-details.component';
import { SharedCurrencyComponent } from './currency/currency.component';
import { SharedDateComponent } from './date/date.component';
import { SharedFinancialSummaryComponent } from './financial-summary/financial-summary.component';
import {
  AgePipe,
  ToAccountancyPipe,
  ToCurrencyPipe,
} from './pipes/formatting.pipe';
import { SharedVehicleComponent } from './vehicle/vehicle.component';
import { CasePersonAddressService } from '../cases/people/addresses/case-person-address-service';
import { CasePersonAddressCreateComponent } from '../cases/people/addresses/create/create.component';
import { AddressTypeSelectComponent } from '../address-types/select/select.component';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';

@NgModule({
  imports: [
    TypeaheadModule.forRoot(),
    QuillModule,
    BrowserModule,
    FormsModule,
    ModalModule,
    ReactiveFormsModule,
    BsDatepickerModule,
  ],
  declarations: [
    AccountGroupSelectComponent,
    AddressTypeSelectComponent,
    AccountTypeSelectComponent,
    ChartOfAccountsSelectComponent,
    SupplierSelectComponent,
    ToCurrencyPipe,
    ToAccountancyPipe,
    AgePipe,
    FinancialAccountsSelectComponent,
    FinancialLedgerSelectComponent,
    SharedVehicleComponent,
    SharedDateComponent,
    SharedAddressComponent,
    SharedContactDetailsComponent,
    SharedPersonEditComponent,
    SharedPersonViewComponent,
    CasePersonAddressEditComponent,
    CasePersonAddressCreateComponent,
    SharedPersonSelectComponent,
    SharedFinancialSummaryComponent,
    DomainSelectComponent,
    UserSelectComponent,
    PermissionSelectComponent,
    IntroducerSelectComponent,
    PortfolioSelectComponent,
    CreditorSelectComponent,
    TitleSelectComponent,
    DocumentSelectComponent,
    TaskSelectComponent,
    StatusSelectComponent,
    SubstatusSelectComponent,
    CountrySelectComponent,
    ProductSelectComponent,
    OccupationSelectComponent,
    AssetSelectComponent,
    IncomeSelectComponent,
    ExpenditureSelectComponent,
    CreditSelectComponent,
    CaseSelectComponent,
    GenderSelectComponent,
    MaritalStatusSelectComponent,
    ResidentialStatusSelectComponent,
    EmploymentStatusSelectComponent,
    SharedCurrencyComponent,
    LetterheadSelectComponent,
    TemplateSelectComponent,
    IncomeCategorySelectComponent,
    ExpenditureCategorySelectComponent,
    DeliveryMethodSelectComponent,
    PackSelectComponent,
    StatusCategorySelectComponent,
    IncomeTypeSelectComponent,
    FolderSelectComponent,
    BreachTypeSelectComponent,
    DepartmentSelectComponent,
    CreditorStatusSelectComponent,
  ],
  exports: [
    AccountGroupSelectComponent,
    AccountTypeSelectComponent,
    AddressTypeSelectComponent,
    ChartOfAccountsSelectComponent,
    SupplierSelectComponent,
    ToCurrencyPipe,
    ToAccountancyPipe,
    AgePipe,
    FinancialAccountsSelectComponent,
    FinancialLedgerSelectComponent,
    SharedVehicleComponent,
    SharedDateComponent,
    SharedAddressComponent,
    SharedContactDetailsComponent,
    SharedPersonEditComponent,
    SharedPersonViewComponent,
    SharedPersonSelectComponent,
    SharedFinancialSummaryComponent,
    DomainSelectComponent,
    UserSelectComponent,
    PermissionSelectComponent,
    IntroducerSelectComponent,
    PortfolioSelectComponent,
    CreditorSelectComponent,
    TitleSelectComponent,
    DocumentSelectComponent,
    TaskSelectComponent,
    StatusSelectComponent,
    SubstatusSelectComponent,
    CountrySelectComponent,
    ProductSelectComponent,
    OccupationSelectComponent,
    AssetSelectComponent,
    IncomeSelectComponent,
    ExpenditureSelectComponent,
    CreditSelectComponent,
    CaseSelectComponent,
    GenderSelectComponent,
    MaritalStatusSelectComponent,
    ResidentialStatusSelectComponent,
    EmploymentStatusSelectComponent,
    SharedCurrencyComponent,
    LetterheadSelectComponent,
    TemplateSelectComponent,
    IncomeCategorySelectComponent,
    ExpenditureCategorySelectComponent,
    DeliveryMethodSelectComponent,
    PackSelectComponent,
    StatusCategorySelectComponent,
    IncomeTypeSelectComponent,
    FolderSelectComponent,
    BreachTypeSelectComponent,
    DepartmentSelectComponent,
    CreditorStatusSelectComponent,
  ],
  providers: [PostcodeService, CasePersonAddressService],
})
export class SharedSelectModule {}
