import { RouterModule, Routes } from '@angular/router';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule } from '@angular/common/http';
import { TitleListComponent } from './list.component';
import { AuthGuard } from '../auth/auth.guard';
import { TitleService } from './title.service';
import { SharedModule } from '../shared/shared.module';
import { TitleCreateComponent } from './create.component';
import { TitleEditComponent } from './edit.component';

const routes: Routes = [
  { path: 'titles', component: TitleListComponent, canActivate: [AuthGuard] },
  {
    path: 'titles/create',
    component: TitleCreateComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'titles/:id/edit',
    component: TitleEditComponent,
    canActivate: [AuthGuard],
  },
];

@NgModule({
  declarations: [TitleListComponent, TitleCreateComponent, TitleEditComponent],
  imports: [
    BrowserModule,
    RouterModule,
    FormsModule,
    HttpClientModule,
    SharedModule,
    RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' }),
  ],
  exports: [RouterModule],
  providers: [AuthGuard, TitleService],
})
export class TitleRoutingModule {}
