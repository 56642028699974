import { Component, Input, OnInit } from '@angular/core';
import { CountryService } from '../country.service';
import { Country } from '../country';
import { NotificationService } from '../../shared/notification.service';
import { SelectComparisons } from '../../shared/pipes/select.comparisons';

@Component({
  selector: 'app-country-select',
  templateUrl: 'select.component.html',
})
export class CountrySelectComponent extends SelectComparisons
  implements OnInit {
  /**
   * The model that we will be adding the country to.
   */
  @Input() model: any;

  /**
   * Do we require this field in the main form it is
   * included in?
   */
  @Input() required: boolean;

  /**
   * A list of all the countries currently loaded.
   *
   * @type {Country[]}
   */
  public countries: Country[] = [];

  /**
   * Are we currently performing a loading action?
   *
   * @type {boolean}
   */
  public isLoading: boolean = true;

  /**
   * Load up all our required services and subscribe to every observable we need.
   *
   * @param {CountryService} countryService
   * @param {NotificationService} notificationService
   */
  constructor(
    private countryService: CountryService,
    private notificationService: NotificationService,
  ) {
    super();
    this.countryService.loading.subscribe(isLoading => {
      this.isLoading = isLoading;
    });
  }

  /**
   * When the component is initialised we set it to load
   * page one of the country list.
   */
  public ngOnInit(): void {
    this.loadCountries(1, 10000);
  }

  /**
   * Loads a list of countries using the given per page limit.
   *
   * @param {number} page The current page we require.
   * @param {number} limit The number of results per page.
   */
  public loadCountries(page?: number, limit?: number): void {
    const listCountries = this.countryService
      .listCountries(page, limit)
      .subscribe((countries: Country[]) => {
        this.countries = countries;
        listCountries.unsubscribe();
      });
  }
}
