import { RouterModule, Routes } from '@angular/router';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule } from '@angular/common/http';
import { OccupationListComponent } from './list/list.component';
import { AuthGuard } from '../auth/auth.guard';
import { OccupationService } from './occupation.service';
import { SharedModule } from '../shared/shared.module';
import { OccupationCreateComponent } from './create/create.component';
import { OccupationFormComponent } from './form/form.component';
import { OccupationEditComponent } from './edit/edit.component';

const routes: Routes = [
  {
    path: 'occupations',
    component: OccupationListComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'occupations/create',
    component: OccupationCreateComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'occupations/:id/edit',
    component: OccupationEditComponent,
    canActivate: [AuthGuard],
  },
];

@NgModule({
  declarations: [
    OccupationListComponent,
    OccupationCreateComponent,
    OccupationFormComponent,
    OccupationEditComponent,
  ],
  imports: [
    BrowserModule,
    RouterModule,
    FormsModule,
    HttpClientModule,
    SharedModule,
    RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' }),
  ],
  exports: [RouterModule],
  providers: [AuthGuard, OccupationService],
})
export class OccupationRoutingModule {}
