import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { NotificationService } from '../../shared/notification.service';
import { ComplaintStatus } from '../complaint-status';
import { ComplaintStatusService } from '../complaint-status.service';

@Component({
  selector: 'app-complaint-status-edit',
  templateUrl: 'edit.component.html',
})
export class ComplaintStatusEditComponent {
  /**
   * Details of the complaintStatus being edited.
   */
  public complaintStatus: ComplaintStatus;

  /**
   * Are we currently performing a loading action?
   *
   * @type {boolean}
   */
  public isLoading: boolean = false;

  /**
   * Load up all our required services and subscribe to every observable we need.
   *
   * @param {ComplaintStatusService} complaintStatusService
   * @param {NotificationService} notificationService
   * @param {Router} router
   * @param {ActivatedRoute} route
   */
  constructor(
    private complaintStatusService: ComplaintStatusService,
    private notificationService: NotificationService,
    private router: Router,
    private route: ActivatedRoute,
  ) {
    this.complaintStatusService.loading.subscribe(
      result => (this.isLoading = result),
    );
    this.route.params.subscribe(params => this.loadComplaintStatus(params.id));
  }

  /**
   * Perform the edit action which send the edited complaintStatus to the backend.
   */
  public edit() {
    const editComplaintStatus = this.complaintStatusService
      .updateComplaintStatus(this.complaintStatus.id, this.complaintStatus)
      .subscribe(
        (result: ComplaintStatus) => {
          this.notificationService.success(
            `Complaint Status Updated`,
            `The complaint Status ${
              result.name
            } been updated with the specified changes.`,
            5,
          );
          this.complaintStatus = new ComplaintStatus();
          editComplaintStatus.unsubscribe();
          this.router.navigate(['/complaint-statuses']);
        },
        err => {
          this.notificationService.error(
            `Complaint Status Not Found`,
            err.error.message,
            10,
          );
          this.router.navigate(['/complaint-statuses']);
        },
      );
  }

  /**
   * Loads the requested complaintStatus so we can display it and let the user edit the fields.
   *
   * @param {string} id
   */
  private loadComplaintStatus(id: string) {
    this.complaintStatusService.findComplaintStatus(id).subscribe(
      result => {
        this.complaintStatus = result;
      },
      err => {
        this.notificationService.error(
          `Complaint Status Not Found`,
          err.error.message,
          10,
        );
        this.router.navigate(['/complaint-statuses']);
      },
    );
  }
}
