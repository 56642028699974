import { Component, OnInit } from '@angular/core';
import { IncomeService } from '../income.service';
import { Income } from '../income';
import { Router } from '@angular/router';
import { NotificationService } from '../../shared/notification.service';

@Component({
  selector: 'app-income-create',
  templateUrl: 'create.component.html',
})
export class IncomeCreateComponent implements OnInit {
  /**
   * Details of the income being created.
   */
  public income: Income;

  /**
   * Are we currently performing a loading action?
   *
   * @type {boolean}
   */
  public isLoading: boolean = false;

  /**
   * Load up all our required services and subscribe to every observable we need.
   *
   * @param {IncomeService} incomeService
   * @param {NotificationService} notificationService
   * @param {Router} router
   */
  constructor(
    private incomeService: IncomeService,
    private notificationService: NotificationService,
    private router: Router,
  ) {
    this.incomeService.loading.subscribe(result => (this.isLoading = result));
  }

  /**
   * Initialise the component, creating a new blank income if required.
   */
  public ngOnInit(): void {
    if (!this.income) {
      this.income = new Income();
    }
  }

  /**
   * Perform the create action which adds the new income into the backend.
   */
  public create() {
    const createIncome = this.incomeService.createIncome(this.income).subscribe(
      (result: Income) => {
        this.notificationService.success(
          `Income Created`,
          `New income ${result.name} has been created with ID of ${result.id}.`,
          5,
        );
        this.income = new Income();
        createIncome.unsubscribe();
        this.router.navigate(['/incomes']);
      },
      err => {
        this.notificationService.error(
          `Could not create Income`,
          err.error.message,
          10,
        );
      },
    );
  }
}
