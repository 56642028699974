<div [class.panel]="!isPopover">
  <app-page-title
    *ngIf="!isPopover"
    orientation="right"
    title="Tasks"
    [loading]="isLoading"
  >
    <button
      [outsideClick]="true"
      [popover]="filterTemplate"
      class="btn btn-xs btn-default btn-secondary"
      placement="bottom"
      popoverTitle="Filter Tasks"
      type="button"
    >
      <span class="fa fa-filter"></span>
    </button>
    <ng-template #filterTemplate>
      <ul class="filter-list">
        <li
          class="row filter-row clearfix"
          [class.hidden]="filter.viewAll === true"
        >
          <div class="form-group">
            <label class="text-capitalize col-sm-3 text-muted"> List </label>
            <div class="col-sm-9">
              <select
                [class.form-control]="true"
                [ngModel]="filter.list"
                (change)="handleDisplayFilterChanged($event)"
              >
                <option value="all">All Tasks</option>
                <option value="my">My Tasks</option>
                <option value="department">My Department Tasks</option>
              </select>
            </div>
          </div>
        </li>
        <li
          *ngIf="authService.hasPermission('domain.all-tasks')"
          class="row filter-row clearfix"
          [class.hidden]="filter.viewAll === true"
        >
          <div class="form-group">
            <label class="text-capitalize col-sm-3 text-muted">
              Department
            </label>
            <div class="col-sm-9">
              <app-department-select
                [model]="filter"
                (optionChanged)="selectOption('department')"
              ></app-department-select>
            </div>
          </div>
        </li>
        <li
          *ngIf="authService.hasPermission('domain.all-tasks')"
          class="row filter-row clearfix"
          [class.hidden]="filter.viewAll === true"
        >
          <div class="form-group">
            <label class="text-capitalize col-sm-3 text-muted"> User </label>
            <div class="col-sm-9">
              <app-user-select
                [model]="filter"
                (optionChanged)="selectOption('user')"
              ></app-user-select>
            </div>
          </div>
        </li>
        <li
          *ngIf="authService.hasPermission('domain.all-tasks')"
          class="row filter-row clearfix"
        >
          <div class="form-group">
            <label class="text-capitalize col-sm-3 text-muted"> All </label>
            <div class="col-sm-9">
              <input
                type="checkbox"
                [checked]="filter.viewAll"
                (change)="toggleAllTasks()"
              />
            </div>
          </div>
        </li>
      </ul>
      <div class="clearfix text-right">
        <button (click)="resetFilters()" class="btn btn-warning btn-xs">
          Reset Filters
        </button>
      </div>
    </ng-template>
  </app-page-title>
  <div [class.panel-body]="!isPopover">
    <ul
      class="tasks"
      [class.is-popover]="isPopover"
      style="max-height: 500px; overflow-x: auto"
    >
      <li
        *ngFor="let task of tasks"
        class="task"
        [class.overdue]="taskOverdue(task.actionAt)"
        style="padding-right: 10px"
      >
        <app-user-icon
          *ngIf="task?.user && showUser"
          [userId]="task?.user?.id"
          [size]="40"
          style="float: left"
        ></app-user-icon>
        <div class="title" [class.padded]="showUser">
          {{ task.task.name }}
          <span
            class="label label-danger pull-right"
            *ngIf="!isPopover && taskOverdue(task.actionAt)"
            >OVERDUE</span
          >
        </div>
        <div class="person text-muted" [class.padded]="showUser">
          <b>Case: </b>
          <span *ngFor="let person of task?.case?.people"
            >{{ person.firstName }} {{ person.lastName }}</span
          >
        </div>
        <div class="person text-muted" [class.padded]="showUser">
          <b>Due: </b>
          <span tooltip="{{ task?.actionAt | timeAgo }}">{{
            task?.actionAt | date: "dd/MM/yyyy 'at' HH:mm"
          }}</span>
        </div>
        <div
          class="content text-muted"
          [class.padded]="showUser"
          [innerHTML]="task?.content"
        ></div>

        <div class="actions text-right" [class.padded]="showUser">
          <a [routerLink]="['/cases', task?.case?.id]"
            ><span class="fa fa-eye"></span> View Case</a
          >
          <a href="javascript:;" (click)="completeTask(task)"
            ><span class="fa fa-check"></span> Mark Completed</a
          >
        </div>
      </li>
    </ul>
    <div class="text-center" *ngIf="!isPopover">
      <app-pagination
        [currentPage]="page"
        [totalPages]="pages"
        (pageChanged)="page = $event"
      ></app-pagination>
    </div>
  </div>
</div>
