<div class="form-group">
    <label class="col-sm-2 control-label" for="name">Name</label>
    <div class="col-sm-10">
        <input [(ngModel)]="department.name" class="form-control" id="name" name="name" placeholder="Department Name"
               required type="text">
    </div>
</div>

<div class="form-group">
    <label class="col-sm-2 control-label" for="domain">Domain</label>
    <div class="col-sm-10">
        <app-domain-select [model]="department" [required]="false"></app-domain-select>
    </div>
</div>

<div class="form-group">
    <div class="col-sm-offset-2 col-sm-10 text-right">
        <button class="btn btn-success" type="submit">Save</button>
    </div>
</div>
