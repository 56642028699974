import { Injectable } from '@angular/core';
import { DomainCredit } from './domain-credit';
import { Observable } from 'rxjs';

import { HttpRequestService } from '../../shared/http/http-request.service';
import { HttpOptions } from '../../shared/http/http-options';

@Injectable()
export class DomainCreditService extends HttpRequestService {
  /**
   * The endpoint that we call to get the domain information.
   *
   * @type {string}
   */
  private readonly endpoint: string = 'domains/:id/credits';

  /**
   * Brings back a list of all accessible domains.
   *
   * @param {number} page The page number we are currently on.
   * @param {number} limit The number of domains to display per page.
   * @returns {Observable<DomainCredit[]>}
   */
  public listDomainCredits(
    page?: number,
    limit?: number,
    options?: HttpOptions,
  ): Observable<DomainCredit[]> {
    return this.list(this.endpoint, page, undefined, limit, options);
  }

  /**
   * Finds details of the domain from the given ID.
   *
   * @param {string} domainId The ID of the domain we require details for.
   * @returns {Observable<DomainCredit>}
   */
  public findDomainCredit(
    domainId: string,
    options?: HttpOptions,
  ): Observable<DomainCredit> {
    return this.findById(this.endpoint, domainId, options);
  }

  /**
   * Creates a new domain from the given Domain object.
   *
   * @param {DomainCredit} newDomain The details of the domain to be created.
   * @returns {Observable<DomainCredit | boolean>}
   */
  public createDomainCredit(
    newDomain: DomainCredit,
    options?: HttpOptions,
  ): Observable<DomainCredit | boolean> {
    return this.create(this.endpoint, newDomain, options);
  }

  /**
   * Updates a domain with the given domain object for the given domain ID.
   *
   * @param {string} domainId The ID of the domain needing updating.
   * @param {DomainCredit} newDetails The new domain object to update with.
   * @returns {Observable<Domain | boolean>}
   */
  public updateDomainCredit(
    domainId: string,
    newDetails: DomainCredit,
    options?: HttpOptions,
  ): Observable<DomainCredit | boolean> {
    return this.update(this.endpoint, domainId, newDetails, options);
  }

  /**
   * Deletes a domain based on the given domain ID.
   *
   * @param {string} domainId The ID of the domain needing deleting.
   * @returns {Observable<boolean>}
   */
  public deleteDomainCredit(
    domainId: string,
    options?: HttpOptions,
  ): Observable<boolean> {
    return this.delete(this.endpoint, domainId, options);
  }
}
