<div *ngIf="isLoading">Loading Creditor List...</div>

<select
  *ngIf="!isLoading"
  [compareWith]="byId"
  class="form-control"
  name="case"
  [(ngModel)]="model.caseCreditor"
  [required]="required"
>
  <option *ngFor="let creditor of caseCreditors" [ngValue]="creditor">
    {{ creditor.creditor.name }} - {{ creditor.value / 100 | toCurrency }}
  </option>
</select>
