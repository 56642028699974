import {HttpClientModule} from '@angular/common/http';
import {NgModule} from '@angular/core';
import {FormsModule} from '@angular/forms';
import {BrowserModule} from '@angular/platform-browser';
import {RouterModule, Routes} from '@angular/router';
import {ModalModule} from 'ngx-bootstrap/modal';
import {QuillModule} from 'ngx-quill';

import {AuthGuard} from '../auth/auth.guard';
import {SharedModule} from '../shared/shared.module';
import {PackCreateComponent} from './create/create.component';
import {PackEditComponent} from './edit/edit.component';
import {PackFormComponent} from './form/form.component';
import {PackListComponent} from './list/list.component';
import {PackService} from './pack.service';

const routes: Routes = [
  { path: 'packs', component: PackListComponent, canActivate: [AuthGuard] },
  {
    path: 'packs/create',
    component: PackCreateComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'packs/:id/edit',
    component: PackEditComponent,
    canActivate: [AuthGuard],
  },
];

@NgModule({
  declarations: [
    PackListComponent,
    PackCreateComponent,
    PackFormComponent,
    PackEditComponent,
  ],
  imports: [
    ModalModule.forRoot(),
    QuillModule,
    BrowserModule,
    RouterModule,
    FormsModule,
    HttpClientModule,
    SharedModule,
    RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' }),
  ],
  exports: [RouterModule],
  providers: [AuthGuard, PackService],
})
export class PackRoutingModule {}
