import { Injectable } from '@angular/core';
import { ChartOfAccounts } from './chart-of-accounts';
import { Observable } from 'rxjs';

import { HttpRequestService } from '../../shared/http/http-request.service';

@Injectable()
export class ChartOfAccountsService extends HttpRequestService {
  /**
   * The endpoint that we call to get the chartOfAccounts information.
   *
   * @type {string}
   */
  private readonly endpoint: string = 'chart-of-accounts';

  /**
   * Brings back a list of all accessible chart-of-accounts.
   *
   * @param {number} page The page number we are currently on.
   * @param {number} limit The number of chart-of-accounts to display per page.
   * @returns {Observable<ChartOfAccounts[]>}
   */
  public listChartOfAccounts(
    page?: number,
    limit?: number,
  ): Observable<ChartOfAccounts[]> {
    return this.list(this.endpoint, page, undefined, limit);
  }

  /**
   * Finds details of the chartOfAccounts from the given ID.
   *
   * @param {string} chartOfAccountsId The ID of the chartOfAccounts we require details for.
   * @returns {Observable<ChartOfAccounts>}
   */
  public findChartOfAccounts(
    chartOfAccountsId: string,
  ): Observable<ChartOfAccounts> {
    return this.findById(this.endpoint, chartOfAccountsId);
  }

  /**
   * Creates a new chartOfAccounts from the given ChartOfAccounts object.
   *
   * @param {ChartOfAccounts} newChartOfAccounts The details of the chartOfAccounts to be created.
   * @returns {Observable<ChartOfAccounts | boolean>}
   */
  public createChartOfAccounts(
    newChartOfAccounts: ChartOfAccounts,
  ): Observable<ChartOfAccounts | boolean> {
    return this.create(this.endpoint, newChartOfAccounts);
  }

  /**
   * Updates a chartOfAccounts with the given chartOfAccounts object for the given chartOfAccounts ID.
   *
   * @param {string} chartOfAccountsId The ID of the chartOfAccounts needing updating.
   * @param {ChartOfAccounts} newDetails The new chartOfAccounts object to update with.
   * @returns {Observable<ChartOfAccounts | boolean>}
   */
  public updateChartOfAccounts(
    chartOfAccountsId: string,
    newDetails: ChartOfAccounts,
  ): Observable<ChartOfAccounts | boolean> {
    return this.update(this.endpoint, chartOfAccountsId, newDetails);
  }

  /**
   * Deletes a chartOfAccounts based on the given chartOfAccounts ID.
   *
   * @param {string} chartOfAccountsId The ID of the chartOfAccounts needing deleting.
   * @returns {Observable<boolean>}
   */
  public deleteChartOfAccounts(chartOfAccountsId: string): Observable<boolean> {
    return this.delete(this.endpoint, chartOfAccountsId);
  }
}
