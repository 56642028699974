import * as moment from 'moment';

import { ActivatedRoute, Router } from '@angular/router';
import { Component, EventEmitter } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { AuthService } from '../../auth/auth.service';
import { Case } from '../case';
import { CaseCreditor } from '../creditors/case-creditor';
import { CaseDocumentGenerator } from '../documents/generator/case-document-generator';
import { CaseDocumentGeneratorService } from '../documents/generator/case-document-generator.service';
import { CasePackGenerator } from '../documents/generator/case-pack-generator';
import { CasePaymentSchedule } from '../../../../chief-types/src/CasePaymentSchedule';
import { CaseService } from '../case.service';
import { CaseStatusService } from '../statuses/case-status.service';
import { CaseUserService } from '../users/case-user.service';
import { FinancialSummary } from '../../shared/financial-summary/financial-summary';
import { HttpRequestResponse } from '../../shared/http/http-request-response';
import { NotificationService } from '../../shared/notification.service';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-case-view',
  templateUrl: 'view.component.html',
  styleUrls: ['view.component.css'],
})
export class CaseViewComponent {
  public caseRefreshed: EventEmitter<string> = new EventEmitter<string>();

  public totals: FinancialSummary;

  public invoiceFilter: any = {
    chartOfAccounts: {},
  };
  public invoicesFiltered: EventEmitter<string> = new EventEmitter<string>();

  /**
   * Details of the case being edited.
   */
  public case: Case;

  /**
   * Are we currently performing a loading action?
   *
   * @type {boolean}
   */
  public isLoading: boolean = false;

  public basicVersion: boolean = true;
  public currentApplicant: number = 0;
  public newDocumentCreated: boolean = true;
  public newPackCreated: boolean = true;
  public creditors: CaseCreditor[];
  public paymentSchedule?: CasePaymentSchedule;
  public protectionValue = 0;
  public statusUpdated: EventEmitter<boolean> = new EventEmitter<boolean>();
  public userUpdated: EventEmitter<boolean> = new EventEmitter<boolean>();
  public documentCreated: EventEmitter<boolean> = new EventEmitter<boolean>();
  public packCreated: EventEmitter<boolean> = new EventEmitter<boolean>();
  public introducerCommissionCreated: EventEmitter<boolean> =
    new EventEmitter<boolean>();
  public protectionReferralCreated: EventEmitter<boolean> =
    new EventEmitter<boolean>();
  public ppiReferralCreated: EventEmitter<boolean> =
    new EventEmitter<boolean>();
  public newProtectionReferral = { value: 0 };
  public createNewDocument: CaseDocumentGenerator = new CaseDocumentGenerator();
  public createNewPack: CasePackGenerator = new CasePackGenerator();
  private currentTab: string = 'calendar';
  public clawingBack: boolean = false;

  private readonly httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + this.auth.getToken(),
    }),
  };

  constructor(
    private caseService: CaseService,
    private caseDocumentGeneratorService: CaseDocumentGeneratorService,
    private caseUserService: CaseUserService,
    private caseStatusService: CaseStatusService,
    private notificationService: NotificationService,
    private router: Router,
    private route: ActivatedRoute,
    public auth: AuthService,
    private http: HttpClient,
  ) {
    this.totals = {
      secured: 0,
      unsecured: 0,
      income: 0,
      expenditure: 0,
      totalSoa: 0,
      totalPod: 0,
      totalAdmitted: 0,
      totalDividends: 0,
    };
    this.caseService.loading.subscribe((result) => {
      this.isLoading = result;
    });
    this.route.params.subscribe((params) => this.loadCase(params.id));
  }

  public clawbackCase(caseId: string) {
    this.clawingBack = true;
    this.http
      .get(`${environment.endpoint}cases/${caseId}/clawback`, this.httpOptions)
      .subscribe(
        (r) => {
          this.clawingBack = false;
          this.loadCase(caseId);
        },
        (e) => {
          this.clawingBack = false;
        },
      );
  }

  public filterInvoicesTo(chartOfAccountsId: string) {
    this.invoiceFilter.chartOfAccounts.id = chartOfAccountsId;
    this.setTab('invoices');
  }

  public get canReferForProtection(): boolean {
    if (this.case) {
      return (
        (!this.case.lifeId || this.case.lifeId.length <= 0) &&
        this.protectionValue > 0
      );
    } else {
      return false;
    }
  }

  public get canReferForPpi(): boolean {
    if (this.case) {
      return !this.case.ppiId || this.case.ppiId.length <= 0;
    } else {
      return false;
    }
  }

  public get introducerPaid(): boolean {
    return (
      this.case &&
      this.case.introducerPaidAt !== undefined &&
      this.case.introducerPaidAt !== null &&
      this.case.introducerPaidAt.length > 2
    );
  }

  setTotalSoa(value) {
    this.totals.totalSoa = value;
  }

  setTotalDividends(value) {
    this.totals.totalDividends = value;
  }

  public creditorsUpdated(event: CaseCreditor[]) {
    this.creditors = event;

    event.forEach((i: CaseCreditor) => (this.totals.totalPod += i.actualValue));
    event.forEach(
      (i: CaseCreditor) => (this.totals.totalAdmitted += i.admittedValue),
    );
  }

  public toggleIntroducerPaid() {
    if (!this.introducerPaid) {
      this.case.introducerPaidAt = moment().format('YYYY-MM-DD HH:mm:ss');
    } else {
      this.case.introducerPaidAt = undefined;
    }
  }

  public protectionFound(value: number): void {
    this.protectionValue = value / 100;
  }

  public valueChanged(type: string, value: number): void {
    this.totals[type] = value;
  }

  public changeApplicant(index: number): void {
    this.currentApplicant = index;
  }

  public tabSelected(name: string): boolean {
    return this.currentTab === name;
  }

  public setTab(name: string): void {
    this.currentTab = name;
  }

  setCommission() {
    let commissionValue =
      (this.totals.income - this.totals.expenditure) *
      this.case.domain.commissionMultiplier;

    this.case.introducerValue = commissionValue;
  }

  createIntroducerCommission(formData: any) {
    const subscription = this.caseService
      .updateCase(this.case.id, this.case)
      .subscribe(
        (result) => {
          this.introducerCommissionCreated.next(true);
          subscription.unsubscribe();
        },
        (err) => {
          this.introducerCommissionCreated.next(false);
          subscription.unsubscribe();
        },
      );
  }

  changeStatus(formData: any) {
    const subscription = this.caseStatusService
      .createCaseStatus(
        { status: this.case.status },
        { parameters: { id: this.case.id } },
      )
      .subscribe((results) => {
        this.statusUpdated.next(true);
        subscription.unsubscribe();
      });
  }

  changeUser(formData: any) {
    const subscription = this.caseUserService
      .createCaseUser(
        { user: this.case.user },
        { parameters: { id: this.case.id } },
      )
      .subscribe((results) => {
        this.userUpdated.next(true);
        subscription.unsubscribe();
      });
  }

  createProtectionReferral(formData: any) {
    const subscription = this.caseService
      .referCase('wlc', this.case.id, { value: this.protectionValue })
      .subscribe((result) => {
        if (result.successful) {
          this.case.lifeId = result.externalReference;
        }
        this.notificationService.success(
          'Referred for Protection',
          'Case has been successfully referred for protection',
          5,
        );
        this.protectionReferralCreated.next(true);
        subscription.unsubscribe();
      });
  }

  createPpiReferral(formData: any) {
    const subscription = this.caseService
      .referCase('ppi', this.case.id, {})
      .subscribe((result) => {
        if (result.successful) {
          this.case.ppiId = result.externalReference;
        }
        this.notificationService.success(
          'Referred for PPI / PBA',
          'Case has been successfully referred for ppi / pba',
          5,
        );
        this.ppiReferralCreated.next(true);
        subscription.unsubscribe();
      });
  }

  createDocument(formData: any) {
    this.newDocumentCreated = false;
    const subscription = this.caseDocumentGeneratorService
      .createDocument(this.createNewDocument, {
        parameters: {
          id: this.case.id,
          templateId: this.createNewDocument.template.id,
        },
      })
      .subscribe(
        (result) => {
          this.newDocumentCreated = true;
          this.documentCreated.next(true);
          subscription.unsubscribe();
        },
        (err) => {
          this.newDocumentCreated = true;
          this.notificationService.error(
            `Count not create document`,
            err.error.message,
            10,
          );
          subscription.unsubscribe();
        },
      );
  }

  createPack(formData: any) {
    this.newPackCreated = false;
    const subscription = this.caseDocumentGeneratorService
      .createPack(this.createNewPack, {
        parameters: {
          id: this.case.id,
          packId: this.createNewPack.pack.id,
        },
      })
      .subscribe(
        (result) => {
          this.newPackCreated = true;
          this.packCreated.next(true);
          subscription.unsubscribe();
        },
        (err) => {
          this.newPackCreated = true;
          this.notificationService.error(
            `Could not create pack`,
            err.error.message,
            10,
          );
          subscription.unsubscribe();
        },
      );
  }

  toggleDeceased() {
    const result = window.confirm(
      `Are you sure you want to mark this client as ${
        this.case.deceased === true ? 'alive' : 'deceased'
      }?`,
    );
    if (result) {
      this.caseService
        .updateCase(this.case.id, {
          ...this.case,
          deceased: !this.case.deceased,
        })
        .subscribe(() => this.reloadCase());
    }
  }

  public async reloadCase() {
    this.loadCase(this.case.id);
  }

  /**
   * Loads the requested case so we can display it and let the user edit the fields.
   *
   * @param {string} id
   */
  private loadCase(id: string) {
    this.caseRefreshed.next(id);
    this.invoicesFiltered.subscribe((filterId) =>
      this.filterInvoicesTo(filterId),
    );
    const loadedCase = this.caseService.findCase(id).subscribe(
      (result) => {
        this.case = result;

        this.case.steppedPayments =
          !this.case || !this.case.steppedPayments
            ? []
            : this.case.steppedPayments.map((item) => {
                return item.value ? item : { value: item };
              });
        if (
          this.tabSelected('payment-schedule') &&
          this.case.paymentScheduleCreated === false
        ) {
          this.currentTab = 'calendar';
        }

        this.sortPeopleAddresses();

        if (this.case.paymentScheduleCreated) {
          this.loadPaymentSchedule();
        }

        loadedCase.unsubscribe();
      },
      (err) => {
        this.notificationService.error(`Case Not Found`, err.error.message, 10);
        this.router.navigate(['/cases']);
        loadedCase.unsubscribe();
      },
    );
  }

  loadPaymentSchedule() {
    this.http
      .get<HttpRequestResponse>(
        `${environment.endpoint}cases/${this.case.id}/payment-schedule`,
        this.httpOptions,
      )
      .map((data: HttpRequestResponse) => data.data)
      .subscribe((item) => {
        this.paymentSchedule = item;
      });
  }

  /**
   * Sorts the addresses of each person by their till or from dates.
   * Till takes priority over from. Addresses with neither are sorted to the end.
   */
  private sortPeopleAddresses() {
    this.case?.people?.forEach((person) => {
      if (person.addresses && person.addresses.length > 1) {
        const sortedAddresses = [...person.addresses].sort((a, b) => {
          const aDate = a.till ?? a.from;
          const bDate = b.till ?? b.from;
          if (aDate && bDate) {
            return Date.parse(b.till ?? b.from) - Date.parse(a.till ?? a.from);
          }
          if (!aDate) {
            return 1;
          }
          return -1;
        });
        person.addresses = sortedAddresses;
      }
    });
  }
}
