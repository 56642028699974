import { Component, Input, OnInit } from '@angular/core';
import { Vehicle } from './vehicle';
import { LogService } from '../log.service';

@Component({
  selector: 'app-shared-vehicle',
  templateUrl: 'vehicle.component.html',
})
export class SharedVehicleComponent {
  /**
   * The model that we will be adding the portfolio to.
   */
  @Input() model: any = undefined;

  @Input() requiredFields: string[] = [];

  public isRequired(item): boolean {
    return this.requiredFields.indexOf(item) >= 0;
  }
}
