import { Component, Input } from '@angular/core';
import { Pack } from '../pack';

@Component({
  selector: 'app-pack-form',
  templateUrl: 'form.component.html',
})
export class PackFormComponent {
  @Input() pack: Pack;

  public addTemplateItem: any = { template: undefined };

  public addTemplate(event) {
    event.preventDefault();
    this.pack.templates.push(this.addTemplateItem.template);
    this.addTemplateItem = { template: undefined };
  }
}
