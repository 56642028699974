<app-case-modification-alert
  [caseId]="caseId"
  [caseRefreshed]="caseRefreshed"
></app-case-modification-alert>

<app-audit-chart-of-accounts [clientId]="caseId"></app-audit-chart-of-accounts>

<div class="row clearfix">
  <div class="col-md-12">
    <table class="table">
      <tr style="border-top: none !important">
        <td
          class="text-right"
          style="border-top: none !important; vertical-align: middle !important"
        >
          <strong>Supervisor %</strong>
        </td>
        <td
          style="border-top: none !important; vertical-align: middle !important"
        >
          <div class="input-group">
            <input
              (change)="changeSupervisoryPercentage(true)"
              [(ngModel)]="supervisorPercentage"
              [disabled]="paymentScheduleCreated === true"
              class="form-control"
              max="100"
              min="0"
              type="number"
            />
            <span class="input-group-addon">%</span>
          </div>
        </td>
        <td
          style="border-top: none !important; vertical-align: middle !important"
        >
          <input
            (change)="changeSupervisoryPercentage(true)"
            [(ngModel)]="subNomsFromSups"
            type="checkbox"
          />
        </td>
        <td
          class="text-right"
          style="border-top: none !important; vertical-align: middle !important"
        >
          <strong>IVA Duration</strong>
        </td>
        <td
          style="border-top: none !important; vertical-align: middle !important"
        >
          <div class="input-group">
            <input
              (change)="changeSupervisoryPercentage(true)"
              [(ngModel)]="ivaDuration"
              [disabled]="paymentScheduleCreated === true"
              class="form-control"
              max="72"
              min="60"
              type="number"
            />
            <span class="input-group-addon">months</span>
          </div>
        </td>

        <td
          class="text-right"
          style="border-top: none !important; vertical-align: middle !important"
        >
          <strong>Payment Frequency</strong>
        </td>
        <td
          style="border-top: none !important; vertical-align: middle !important"
        >
          <div class="input-group">
            <select
              [(ngModel)]="paymentFrequency"
              [required]="true"
              class="form-control"
              id="frequency"
              name="frequency"
            >
              <option value="month">Monthly</option>
              <option value="week">Weekly</option>
              <option value="bi-week">Bi-Weekly</option>
              <option value="four-week">Four-Weekly</option>
            </select>
          </div>
        </td>
      </tr>
    </table>
  </div>
</div>

<table class="table table-striped clearfix">
  <thead>
    <tr>
      <th></th>
      <th>Bankruptcy (Main Account)</th>
      <th
        *ngIf="
          partnerCreditorValue() +
            partnerCreditorValue(true) +
            jointCreditorValue() >
          0
        "
      >
        Bankruptcy (Partner)
      </th>
      <th>Voluntary Arrangement</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td>Voluntary Contributions</td>
      <td></td>
      <td
        *ngIf="
          partnerCreditorValue() +
            partnerCreditorValue(true) +
            jointCreditorValue() >
          0
        "
      ></td>
      <td>{{ ivaPayment / 100 | toCurrency }}</td>
    </tr>
    <tr>
      <td>IPA/IPO payment available in bankruptcy</td>
      <td>{{ bankruptcyPayment / 100 | toCurrency }}</td>
      <td
        *ngIf="
          partnerCreditorValue() +
            partnerCreditorValue(true) +
            jointCreditorValue() >
          0
        "
      >
        {{ bankruptcyPayment / 100 | toCurrency }}
      </td>
      <td></td>
    </tr>
    <tr>
      <th>Estimated amount available to Creditors</th>
      <th>{{ bankruptcyPayment / 100 | toCurrency }}</th>
      <th
        *ngIf="
          partnerCreditorValue() +
            partnerCreditorValue(true) +
            jointCreditorValue() >
          0
        "
      >
        {{ bankruptcyPayment / 100 | toCurrency }}
      </th>
      <th>{{ ivaPayment / 100 | toCurrency }}</th>
    </tr>

    <tr *ngFor="let singleComparison of feeComparison">
      <td>
        {{ singleComparison.chartOfAccounts.name }}
      </td>
      <td>
        <app-shared-currency
          (change)="calculateUpdatedTotals()"
          [(ngModel)]="singleComparison.bankruptcyMainValue"
          [disabled]="paymentScheduleCreated === true"
        ></app-shared-currency>
        <div
          *ngIf="singleComparison.chartOfAccounts.estimated === true"
          class="text-muted"
        >
          (Estimated Value Only)
        </div>
        <div
          *ngIf="singleComparison.chartOfAccounts.appreciating === true"
          class="text-muted"
        >
          (Appreciating
          {{
            appreciatingValue(
              singleComparison.bankruptcyMainValue,
              singleComparison
            ) / 100 | toCurrency
          }}
          )
        </div>
      </td>
      <td
        *ngIf="
          partnerCreditorValue() +
            partnerCreditorValue(true) +
            jointCreditorValue() >
          0
        "
      >
        <app-shared-currency
          (change)="calculateUpdatedTotals()"
          [(ngModel)]="singleComparison.bankruptcyPartnerValue"
          [disabled]="paymentScheduleCreated === true"
        ></app-shared-currency>
        <div
          *ngIf="singleComparison.chartOfAccounts.estimated === true"
          class="text-muted"
        >
          (Estimated Value Only)
        </div>
        <div
          *ngIf="singleComparison.chartOfAccounts.estimated === true"
          class="text-muted"
        >
          (Estimated Value Only)
        </div>
        <div
          *ngIf="singleComparison.chartOfAccounts.appreciating === true"
          class="text-muted"
        >
          (Appreciating
          {{
            appreciatingValue(
              singleComparison.bankruptcyPartnerValue,
              singleComparison
            ) / 100 | toCurrency
          }}
          )
        </div>
      </td>
      <td>
        <app-shared-currency
          (change)="
            calculateUpdatedTotals(singleComparison.chartOfAccounts.id, $event)
          "
          [(ngModel)]="singleComparison.voluntaryArrangementValue"
          [disabled]="paymentScheduleCreated === true"
        ></app-shared-currency>
        <div
          *ngIf="singleComparison.chartOfAccounts.estimated === true"
          class="text-muted"
        >
          (Estimated Value Only)
        </div>
        <div
          *ngIf="singleComparison.chartOfAccounts.appreciating === true"
          class="text-muted"
        >
          (Appreciating
          {{
            appreciatingValue(
              singleComparison.voluntaryArrangementValue,
              singleComparison
            ) / 100 | toCurrency
          }}
          )
        </div>
      </td>
    </tr>
    <tr>
      <th>Total Payable Fees</th>
      <th>{{ totalPayableBankruptcyFees / 100 | toCurrency }}</th>
      <th
        *ngIf="
          partnerCreditorValue() +
            partnerCreditorValue(true) +
            jointCreditorValue() >
          0
        "
      >
        {{ totals.totalBankruptcyFeesPartner / 100 | toCurrency }}
      </th>
      <th>{{ totalPayableIvaFees / 100 | toCurrency }}</th>
    </tr>
    <tr>
      <th>Estimated amount available to Creditors</th>
      <th>{{ totalAvailableToBankruptcyCreditors / 100 | toCurrency }}</th>
      <th
        *ngIf="
          partnerCreditorValue() +
            partnerCreditorValue(true) +
            jointCreditorValue() >
          0
        "
      >
        {{ totalBankruptcyPartner / 100 | toCurrency }}
      </th>
      <th>{{ totalAvailableToIvaCreditors / 100 | toCurrency }}</th>
    </tr>
    <tr>
      <td>Creditors - Personal</td>
      <td>{{ mainCreditorValue() / 100 | toCurrency }}</td>
      <td
        *ngIf="
          partnerCreditorValue() +
            partnerCreditorValue(true) +
            jointCreditorValue() >
          0
        "
      >
        {{ partnerCreditorValue() / 100 | toCurrency }}
      </td>
      <td>
        {{ (mainCreditorValue() + partnerCreditorValue()) / 100 | toCurrency }}
      </td>
    </tr>
    <tr>
      <td>Creditors - Joint</td>
      <td>{{ jointCreditorValue() / 100 | toCurrency }}</td>
      <td
        *ngIf="
          partnerCreditorValue() +
            partnerCreditorValue(true) +
            jointCreditorValue() >
          0
        "
      >
        {{ jointCreditorValue() / 100 | toCurrency }}
      </td>
      <td>{{ jointCreditorValue() / 100 | toCurrency }}</td>
    </tr>
    <tr>
      <td>Creditors - Excluded</td>
      <td>{{ mainCreditorValue(true) / 100 | toCurrency }}</td>
      <td
        *ngIf="
          partnerCreditorValue() +
            partnerCreditorValue(true) +
            jointCreditorValue() >
          0
        "
      >
        {{ partnerCreditorValue(true) / 100 | toCurrency }}
      </td>
      <td>
        {{
          (mainCreditorValue(true) + partnerCreditorValue(true)) / 100
            | toCurrency
        }}
      </td>
    </tr>
    <tr>
      <th>Estimated Total (Deficiency)</th>
      <th>
        {{
          (totalBankruptcyMain +
            mainCreditorValue() +
            jointCreditorValue() +
            mainCreditorValue(true)) /
            100 | toCurrency
        }}
      </th>
      <th
        *ngIf="
          partnerCreditorValue() +
            partnerCreditorValue(true) +
            jointCreditorValue() >
          0
        "
      >
        {{
          (totalBankruptcyPartner +
            partnerCreditorValue() +
            jointCreditorValue()) /
            100 | toCurrency
        }}
      </th>
      <th>
        {{
          (totalVoluntaryArrangement +
            mainCreditorValue() +
            jointCreditorValue()) /
            100 | toCurrency
        }}
      </th>
    </tr>
    <tr>
      <th>Estimated dividend to Unsecured Creditors (p in the £)</th>
      <th>
        {{ totalBankruptcyDividend < 0 ? 'Nil' : totalBankruptcyDividend }}
      </th>
      <th
        *ngIf="
          partnerCreditorValue() +
            partnerCreditorValue(true) +
            jointCreditorValue() >
          0
        "
      >
        {{ totalBankruptcyDividend }}
      </th>
      <th>
        {{ totalIvaDividend }}
      </th>
    </tr>
  </tbody>
</table>

<div class="row">
  <div class="col-md-6">
    <h3>
      <button
        (click)="addSteppedPayment()"
        [disabled]="paymentScheduleCreated === true"
        class="pull-right btn btn-xs"
        style="margin-left: 10px"
        type="button"
      >
        <i class="fa fa-plus"></i>
      </button>
      Step Payments
    </h3>
    <div class="form-group">
      <ng-container *ngFor="let step of steppedPayments; let i = index">
        <label class="col-sm-3 control-label">{{
          frequencyText(paymentFrequency, i + 1)
        }}</label>
        <div class="col-sm-8">
          <app-shared-currency
            [(ngModel)]="step.value"
            [disabled]="paymentScheduleCreated === true"
            [required]="true"
          ></app-shared-currency>
        </div>
        <div class="col-sm-1 text-right">
          <button (click)="removeStep(i)" class="btn btn-xs">
            <i class="fa fa-trash"></i>
          </button>
        </div>
      </ng-container>
    </div>
  </div>
  <div class="col-md-6">
    <h3>Distributions</h3>
    <table class="table table-striped">
      <tbody>
        <tr>
          <td>{{ distributionsYearOne * -1 }}</td>
          <td>p in &pound;</td>
          <td>in year 1</td>
        </tr>
        <tr>
          <td>{{ distributionsYearTwoToFive * -1 }}</td>
          <td>p in &pound;</td>
          <td>in years 2 to 5</td>
        </tr>
        <tr>
          <td>{{ totalIvaDividend }}</td>
          <td>p in &pound;</td>
          <td>in total in year 5</td>
        </tr>
      </tbody>
    </table>
  </div>
</div>

<div *ngIf="paymentScheduleCreated === false" class="row">
  <div class="col-md-12 text-right">
    <button
      (click)="generatePaymentSchedule(comparisonScheduleModal)"
      [disabled]="isSaving || paymentScheduleCreated === true"
      class="btn btn-warning"
    >
      Generate Payment Schedule
    </button>
    <button
      (click)="saveFeeComparison()"
      [disabled]="isSaving || paymentScheduleCreated === true"
      class="btn btn-success"
    >
      Save
    </button>
  </div>
</div>

<ng-template #comparisonScheduleModal>
  <app-case-fee-comparison-schedule
    (paymentScheduleCreatedAction)="paymentScheduleCreatedAction.next(true)"
    [(duration)]="ivaDuration"
    [caseId]="caseId"
    [disposableIncome]="disposableIncome"
    [initialDividend]="ivaDividend * -1"
    [modalRef]="modalRef"
    [paymentFrequency]="paymentFrequency"
  ></app-case-fee-comparison-schedule>
</ng-template>
