import {Component} from '@angular/core';
import {AuthService} from '../auth.service';
import {Router} from '@angular/router';

@Component({
  selector: 'app-auth-login',
  // We'll use an external file for both the CSS styles and HTML view
  templateUrl: 'login.component.html',
  styleUrls: [],
})
export class LoginComponent {
  public username: string;
  public password: string;

  public errorMessage: string;

  constructor(private authService: AuthService, private router: Router) {
    if (this.authService.authenticated) {
      this.router.navigate(['/dashboard']);
      return;
    }

    this.authService.loggedIn$.subscribe(loggedIn => {
      if (loggedIn) {
        this.router.navigate(['/dashboard']);
      }
    });
  }

  public login() {
    this.authService.login(this.username, this.password)
        .then(() => this.errorMessage = undefined)
        .catch((e) => this.errorMessage = e);
  }
}
