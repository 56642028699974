import {Component} from '@angular/core';
import {DepartmentService} from '../department.service';
import {Department} from '../department';
import {ActivatedRoute, Router} from '@angular/router';
import {NotificationService} from '../../../shared/notification.service';

@Component({
    selector: 'app-department-edit',
    templateUrl: 'edit.component.html',
})
export class DepartmentEditComponent {
    /**
     * Details of the department being edited.
     */
    public department: Department;

    /**
     * Are we currently performing a loading action?
     *
     * @type {boolean}
     */
    public isLoading: boolean = false;

    /**
     * Load up all our required services and subscribe to every observable we need.
     *
     * @param {DepartmentService} departmentService
     * @param {NotificationService} notificationService
     * @param {Router} router
     * @param {ActivatedRoute} route
     */
    constructor(
        private departmentService: DepartmentService,
        private notificationService: NotificationService,
        private router: Router,
        private route: ActivatedRoute,
    ) {
        this.departmentService.loading.subscribe(result => (this.isLoading = result));
        this.route.params.subscribe(params => this.loadDepartment(params.id));
    }

    /**
     * Perform the edit action which send the edited department to the backend.
     */
    public edit() {
        const editDepartment = this.departmentService
            .updateDepartment(this.department.id, this.department)
            .subscribe(
                (result: Department) => {
                    this.notificationService.success(
                        `Department Updated`,
                        `The department ${result.name} been updated with the specified changes.`,
                        5,
                    );
                    this.department = new Department();
                    editDepartment.unsubscribe();
                    this.router.navigate(['/departments']);
                },
                err => {
                    this.notificationService.error(
                        `Department Not Found`,
                        err.error.message,
                        10,
                    );
                    this.router.navigate(['/departments']);
                },
            );
    }

    /**
     * Loads the requested department so we can display it and let the user edit the fields.
     *
     * @param {string} id
     */
    private loadDepartment(id: string) {
        this.departmentService.findDepartment(id).subscribe(
            result => {
                this.department = result;
            },
            err => {
                this.notificationService.error(`Department Not Found`, err.error.message, 10);
                this.router.navigate(['/departments']);
            },
        );
    }
}
