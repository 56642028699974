import { Component, Input } from '@angular/core';
import { BankingAccountItem } from '../banking-account-item';

@Component({
  selector: 'app-banking-account-item-form',
  templateUrl: 'form.component.html',
})
export class BankingAccountItemFormComponent {
  @Input() accountItem: BankingAccountItem;
}
