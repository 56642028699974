<h4>
  <a [href]="downloadReportUrl" target="_blank" class="btn btn-xs btn-danger pull-right">
    <i class="fa fa-download"></i>
  </a>
  Detailed Customer Transactions
</h4>

<table class="table table-striped">
  <thead>
  <tr>
    <th>Date</th>
    <th>Item</th>
    <th>Out</th>
    <th>In</th>
    <th>Running Total</th>
  </tr>
  </thead>

  <tbody>
  <tr *ngFor="let item of detailedReport">
    <td>{{ item.date | date:'dd/MM/yyyy' }}</td>
    <td>{{ item.note }}</td>
    <td>{{ item.out | toAccountancy }}</td>
    <td>{{ item.in | toAccountancy }}</td>
    <td>{{ item.total | toAccountancy }}</td>
  </tr>
  </tbody>

</table>
