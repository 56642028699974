import {Component, EventEmitter, Input, OnDestroy, OnInit, Output, TemplateRef} from '@angular/core';
import {BsModalRef, BsModalService} from 'ngx-bootstrap/modal';

import {Modification} from '../../../settings/modifications/modification';
import {NotificationService} from '../../../shared/notification.service';
import {CaseModificationService} from '../modification.service';

@Component({
  selector: 'app-case-modification-modal',
  templateUrl: 'modal.component.html',
})
export class CaseModificationModalComponent implements OnInit, OnDestroy {
  @Input() modalRef: BsModalRef;

  @Input() caseId: string;

  private modificationSubscription: any;

  public modifications: Modification[] = [];

  public customModifications: any[] = [];

  public visibleModifications: 'all' | 'selected' | 'override' = 'all';

  @Output()
  public modificationsSaved: EventEmitter<boolean> = new EventEmitter<
    boolean
  >();

  constructor(
    private modalService: BsModalService,
    private readonly service: CaseModificationService,
    protected readonly notification: NotificationService,
  ) {}

  ngOnInit(): void {
    this.loadModifications();
  }

  updateActiveIfRequired(modification) {
    if (modification.override === true) {
      modification.active = true;
    }
  }

  setVisibleModifications(event) {
    this.visibleModifications = event;
  }

  openModal(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(template, { class: 'modal-lg' });
  }

  public updateModifications(): void {
    this.service
      .saveModifications(this.caseId, this.modifications, this.customModifications, {
        parameters: {
          id: this.caseId,
        },
      })
      .subscribe(response => {
        this.notification.success('Saved', 'Modifications have been saved.');
        this.modificationsSaved.next(true);
        this.modalRef.hide();
      });
  }

  loadModifications() {
    this.modificationSubscription = this.service
      .listModifications(1, 1000, {
        parameters: {
          id: this.caseId,
        },
      })
      .subscribe((result: any) => {
        this.modifications = result.modifications;
        this.customModifications = result.customModifications;
      });
  }

  ngOnDestroy(): void {
    this.modificationSubscription.unsubscribe();
  }

  addCustomModification() {
    this.customModifications.push({
      text: '',
    });
  }

  deleteCustomModification(i) {
    this.customModifications.splice(i, 1);
  }

}
