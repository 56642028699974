import { BrowserModule } from '@angular/platform-browser';
import { EditableNumberComponent } from './editable-number.component';
import { FormsModule } from '@angular/forms';
import { NgModule } from '@angular/core';
import { SharedSelectModule } from '../shared-select.module';
import { ToAccountancyPipe } from '../pipes/formatting.pipe';

@NgModule({
  imports: [FormsModule, BrowserModule, SharedSelectModule],
  declarations: [EditableNumberComponent],
  exports: [EditableNumberComponent],
})
export class EditableFieldsModule {}
