import 'rxjs/Rx';

import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Component, OnInit} from '@angular/core';

import {environment} from '../../../environments/environment';
import {AuthService} from '../../auth/auth.service';
import {HttpRequestResponse} from '../../shared/http/http-request-response';

@Component({
  selector: 'app-paperless-office-out-report',
  templateUrl: 'paperless-office-out.component.html',
})
export class PaperlessOfficeOutComponent implements OnInit {
  private readonly httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + this.authService.getToken(),
    }),
  };

  public isLoading: boolean = false;
  public totalResults: number = 0;

  public totalPages: number = 1;
  public currentPage: number = 1;

  public results: any[] = [];

  constructor(private readonly http: HttpClient, private readonly authService: AuthService) { }

  ngOnInit(): void {
    this.loadPage(1);
  }

  public markDocumentSent(id: string): void {
    this.isLoading = true;
    this.http.post(`${environment.endpoint}reports/paperless-office/${id}`, {}, this.httpOptions)
      .subscribe(result => {
        this.isLoading = false;
        this.loadPage(1);
      });
  }

  public loadPage(page: number = 1, limit: number = 10) {
    this.isLoading = true;
    this.http.get<any>(
      `${environment.endpoint}reports/paperless-office?page=${page || environment.pagination.startPage}&limit=${limit || environment.pagination.perPage}`,
      this.httpOptions
    )
      .map((data: HttpRequestResponse) => {
        this.totalPages =
          data.meta && data.meta.totalPages
            ? data.meta.totalPages
            : Math.ceil(
            (data.meta && data.meta.totalResults
              ? data.meta.totalResults
              : data.data.length) /
            (limit || environment.pagination.perPage),
            );

        this.totalResults = data.meta && data.meta.totalResults
          ? data.meta.totalResults
          : data.data.length;
        this.currentPage = data.meta && data.meta.currentPage ? data.meta.currentPage : page;

        return data.data;
      })
      .subscribe(data => {
        this.isLoading = false;
        this.results = data;
      });
  }

}
