import { Component, Input } from '@angular/core';
import { Creditor } from '../creditor';

@Component({
  selector: 'app-creditor-form',
  templateUrl: 'form.component.html',
})
export class CreditorFormComponent {
  @Input() creditor: Creditor;
}
