import { Injectable } from '@angular/core';
import { PaymentType } from './payment-type';
import { Observable } from 'rxjs';

import { HttpRequestService } from '../../shared/http/http-request.service';

@Injectable()
export class PaymentTypeService extends HttpRequestService {
  /**
   * The endpoint that we call to get the paymentType information.
   *
   * @type {string}
   */
  private readonly endpoint: string = 'payment-types';

  /**
   * Brings back a list of all accessible payment-types.
   *
   * @param {number} page The page number we are currently on.
   * @param {number} limit The number of payment-types to display per page.
   * @returns {Observable<PaymentType[]>}
   */
  public listPaymentTypes(
    page?: number,
    limit?: number,
  ): Observable<PaymentType[]> {
    return this.list(this.endpoint, page, undefined, limit);
  }

  /**
   * Finds details of the paymentType from the given ID.
   *
   * @param {string} paymentTypeId The ID of the paymentType we require details for.
   * @returns {Observable<PaymentType>}
   */
  public findPaymentType(paymentTypeId: string): Observable<PaymentType> {
    return this.findById(this.endpoint, paymentTypeId);
  }

  /**
   * Creates a new paymentType from the given PaymentType object.
   *
   * @param {PaymentType} newPaymentType The details of the paymentType to be created.
   * @returns {Observable<PaymentType | boolean>}
   */
  public createPaymentType(
    newPaymentType: PaymentType,
  ): Observable<PaymentType | boolean> {
    return this.create(this.endpoint, newPaymentType);
  }

  /**
   * Updates a paymentType with the given paymentType object for the given paymentType ID.
   *
   * @param {string} paymentTypeId The ID of the paymentType needing updating.
   * @param {PaymentType} newDetails The new paymentType object to update with.
   * @returns {Observable<PaymentType | boolean>}
   */
  public updatePaymentType(
    paymentTypeId: string,
    newDetails: PaymentType,
  ): Observable<PaymentType | boolean> {
    return this.update(this.endpoint, paymentTypeId, newDetails);
  }

  /**
   * Deletes a paymentType based on the given paymentType ID.
   *
   * @param {string} paymentTypeId The ID of the paymentType needing deleting.
   * @returns {Observable<boolean>}
   */
  public deletePaymentType(paymentTypeId: string): Observable<boolean> {
    return this.delete(this.endpoint, paymentTypeId);
  }
}
