import { Component, OnInit } from '@angular/core';
import { PaymentTypeService } from '../payment-type.service';
import { PaymentType } from '../payment-type';
import { Router } from '@angular/router';
import { NotificationService } from '../../../shared/notification.service';

@Component({
  selector: 'app-payment-type-create',
  templateUrl: 'create.component.html',
})
export class PaymentTypeCreateComponent implements OnInit {
  /**
   * Details of the paymentType being created.
   */
  public paymentType: PaymentType;

  /**
   * Are we currently performing a loading action?
   *
   * @type {boolean}
   */
  public isLoading: boolean = false;

  /**
   * Load up all our required services and subscribe to every observable we need.
   *
   * @param {PaymentTypeService} paymentTypeService
   * @param {NotificationService} notificationService
   * @param {Router} router
   */
  constructor(
    private paymentTypeService: PaymentTypeService,
    private notificationService: NotificationService,
    private router: Router,
  ) {
    this.paymentTypeService.loading.subscribe(
      result => (this.isLoading = result),
    );
  }

  /**
   * Initialise the component, creating a new blank paymentType if required.
   */
  public ngOnInit(): void {
    if (!this.paymentType) {
      this.paymentType = new PaymentType();
    }
  }

  /**
   * Perform the create action which adds the new paymentType into the backend.
   */
  public create() {
    const createPaymentType = this.paymentTypeService
      .createPaymentType(this.paymentType)
      .subscribe(
        (result: PaymentType) => {
          this.notificationService.success(
            `PaymentType Created`,
            `New paymentType ${result.name} has been created with ID of ${
              result.id
            }.`,
            5,
          );
          this.paymentType = new PaymentType();
          createPaymentType.unsubscribe();
          this.router.navigate(['/payment-types']);
        },
        err => {
          this.notificationService.error(
            `Could not create PaymentType`,
            err.error.message,
            10,
          );
        },
      );
  }
}
