import {HttpClientModule} from '@angular/common/http';
import {NgModule} from '@angular/core';
import {FormsModule} from '@angular/forms';
import {BrowserModule} from '@angular/platform-browser';
import {ModalModule} from 'ngx-bootstrap/modal';
import {TooltipModule} from 'ngx-bootstrap/tooltip';

import {SharedModule} from '../../shared/shared.module';
import {CaseModificationAlertComponent} from './alert/alert.component';
import {CaseModificationModalComponent} from './modal/modal.component';
import {CaseModificationService} from './modification.service';

@NgModule({
  declarations: [
    CaseModificationAlertComponent,
    CaseModificationModalComponent,
  ],
  imports: [
    ModalModule.forRoot(),
    TooltipModule.forRoot(),
    BrowserModule,
    FormsModule,
    HttpClientModule,
    SharedModule,
  ],
  exports: [CaseModificationAlertComponent, CaseModificationModalComponent],
  providers: [CaseModificationService],
})
export class CaseModificationRoutingModule {}
