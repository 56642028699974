import { RouterModule, Routes } from '@angular/router';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule } from '@angular/common/http';
import { MaritalStatusListComponent } from './list/list.component';
import { AuthGuard } from '../auth/auth.guard';
import { MaritalStatusService } from './marital-status.service';
import { SharedModule } from '../shared/shared.module';
import { MaritalStatusCreateComponent } from './create/create.component';
import { MaritalStatusFormComponent } from './form/form.component';
import { MaritalStatusEditComponent } from './edit/edit.component';

const routes: Routes = [
  {
    path: 'marital-statuses',
    component: MaritalStatusListComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'marital-statuses/create',
    component: MaritalStatusCreateComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'marital-statuses/:id/edit',
    component: MaritalStatusEditComponent,
    canActivate: [AuthGuard],
  },
];

@NgModule({
  declarations: [
    MaritalStatusListComponent,
    MaritalStatusCreateComponent,
    MaritalStatusFormComponent,
    MaritalStatusEditComponent,
  ],
  imports: [
    BrowserModule,
    RouterModule,
    FormsModule,
    HttpClientModule,
    SharedModule,
    RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' }),
  ],
  exports: [RouterModule],
  providers: [AuthGuard, MaritalStatusService],
})
export class MaritalStatusRoutingModule {}
