import { LinkProps, LinkWrapper } from 'react-inside-angular';
import Pagination, { useCurrentPage } from '../../Components/Pagination';

import Alert from '../../Components/Alert';
import LinkButton from '../../Components/LinkButton';
import LinkDropdown from '../../Components/LinkDropdown';
import Panel from '../../Components/Panel';
import { useHistory as RRDUseHistory } from 'react-router-dom';
import useAddressTypes from './UseAddressTypes';
import useLoggedInUser from '../../Hooks/UseLoggedInUser';

export type AddressTypeListProps = {
  authService: any;
};

const AddressTypeList = ({
  Navigate,
  authService,
}: LinkProps<AddressTypeListProps>) => {
  const Link =
    Navigate !== undefined ? LinkWrapper(Navigate).useHistory : RRDUseHistory();

  const {
    items,
    loading,
    metaData,
    error,
    loadItems,
    deleteItem,
  } = useAddressTypes(true);

  const [page, setPage] = useCurrentPage((n: number) => loadItems({ page: n }));

  const { hasAllDomainsPermission, userDomains } = useLoggedInUser(authService);

  const canEdit = (domainId: string) => {
    return (
      hasAllDomainsPermission ||
      (userDomains && userDomains.map((domain) => domain.id).includes(domainId))
    );
  };

  return (
    <Panel
      title='List Address Types'
      count={metaData?.totalResults}
      loading={loading}
      edgeToEdge
      footer={
        <Pagination
          totalPages={metaData?.totalPages ?? 1}
          currentPage={page}
          onPageChanged={setPage}
        />
      }
    >
      {error && (
        <Alert color='red' title='Error' message={error} className='tw-m-6' />
      )}

      {items && (
        <div className='tw-table tw-w-full tw-mt-2'>
          <div className='tw-table-header-group'>
            <div className='tw-table-row'>
              <div className='tw-font-bold tw-table-cell tw-p-2 tw-border-solid tw-border-0 tw-border-b-2 tw-border-gray-100 tw-pb-4 tw-pl-4'>
                Address Type Name
              </div>
              <div className='tw-font-bold tw-table-cell tw-p-2 tw-border-solid tw-border-0 tw-border-b-2 tw-border-gray-100 tw-pb-4 tw-pl-4'>
                Domain
              </div>
              <div className='tw-table-cell tw-p-2 tw-pb-2 tw-border-solid tw-border-0 tw-border-b-2 tw-border-gray-100 tw-text-right tw-pb-4 tw-pr-4'>
                <LinkButton
                  to={`/address-types/create`}
                  title='Create'
                  color='green'
                  Navigate={Navigate}
                />
              </div>
            </div>
          </div>

          {items &&
            items.map((addressType) => (
              <div key={addressType.id} className='tw-table-row-group'>
                <div className='tw-table-row tw-h-max hover:tw-bg-gray-100'>
                  <div className='tw-table-cell tw-p-2 tw-pl-4 tw-flex tw-items-center'>
                    {addressType.name}
                  </div>
                  <div className='tw-table-cell tw-p-2 tw-pl-4 tw-flex tw-items-center'>
                    {addressType.domain?.name}
                  </div>
                  <div className='tw-table-cell tw-p-2 tw-text-right tw-pr-4 tw-flex tw-items-center'>
                    {canEdit(addressType.domain?.id ?? '') && (
                      <LinkDropdown
                        to={`/address-types/${addressType.id}/edit`}
                        title='Edit'
                        color='yellow'
                        Navigate={Navigate}
                      >
                        <LinkDropdown.Link
                          onClick={(e) => {
                            e.preventDefault();
                            deleteItem(addressType.id ?? '').finally(() =>
                              loadItems({ page }),
                            );
                          }}
                        >
                          Delete
                        </LinkDropdown.Link>
                      </LinkDropdown>
                    )}
                  </div>
                </div>
              </div>
            ))}
        </div>
      )}
    </Panel>
  );
};

export default AddressTypeList;
