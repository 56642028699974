import { Component, Input } from '@angular/core';
import { DeliveryMethod } from '../delivery-method';

@Component({
  selector: 'app-delivery-method-form',
  templateUrl: 'form.component.html',
})
export class DeliveryMethodFormComponent {
  @Input() deliveryMethod: DeliveryMethod;
}
