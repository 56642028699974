import { Component, Input, forwardRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'app-shared-currency',
  templateUrl: 'currency.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SharedCurrencyComponent),
      multi: true,
    },
  ],
})
export class SharedCurrencyComponent implements ControlValueAccessor {
  @Input() disabled: boolean;

  @Input() required: boolean;
  @Input() name: string = 'value';

  @Input() max: number = 99999999999;

  @Input() min?: number;

  @Input() allowNegative?: boolean;

  get minValue() {
    if (this.min) {
      return this.min;
    }
    if (this.isRequired()) {
      return this.allowNegative ? -999999999999 : 0.01;
    }
    return this.allowNegative ? undefined : 0;
  }

  // Function to call when the rating changes.
  onChange = (value: number) => {};

  // Function to call when the input is touched (when a star is clicked).
  onTouched = () => {};

  public currentValue = 0;

  public setValue(value: number, decimal: boolean = true): void {
    this.currentValue = decimal ? Math.round(value * 100) : value;
    this.writeValue(this.currentValue);
  }

  public changeValue($event): void {
    this.currentValue = $event.target.value;
    this.writeValue(this.currentValue * 100);
  }

  public isRequired(): boolean {
    return this.required;
  }

  // Allows Angular to update the model (rating).
  // Update the model and changes needed for the view here.
  writeValue(value: number): void {
    // if (this.currentValue === 0) {
    this.currentValue = value / 100;
    // }
    this.onChange(Math.round(this.currentValue * 100));
  }

  // Allows Angular to register a function to call when the model (rating) changes.
  // Save the function as a property to call later here.
  registerOnChange(fn: (value: number) => void): void {
    this.onChange = fn;
  }

  // Allows Angular to register a function to call when the input has been touched.
  // Save the function as a property to call later here.
  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }
}
