<div class="text-right button-menu">
  <button type="button" class="btn btn-sm btn-success" href="javascript:;" (click)="create.open(caseId)">
    Add Property
  </button>
</div>

<table class="properties table table-striped">
  <thead>
  <tr>
    <th width="40" class="text-center"></th>
    <th width="40" class="text-center"></th>
    <th>Address</th>
    <th>Postcode</th>
    <th>Value</th>
    <th>Mortgage Balance</th>
    <th>Applicant</th>
    <th>Creditor</th>
    <th></th>
  </tr>
  </thead>
  <tbody>
  <tr *ngFor="let caseProperty of caseProperties">
    <td>
      <app-user-icon *ngIf="caseProperty.user" [userId]="caseProperty.user.id" [size]="20"></app-user-icon>
    </td>
    <td><i class="fa" [class.fa-check-circle]="!caseProperty.excluded"  [class.fa-times-circle]="caseProperty.excluded"></i></td>
    <td>{{ caseProperty.address1 }}</td>
    <td>{{ caseProperty.postalCode }}</td>
    <td>{{ caseProperty.value / 100 | toCurrency }}</td>
    <td>{{ caseProperty.mortgageBalance / 100 | toCurrency }}</td>
    <td *ngIf="caseProperty.person">{{ caseProperty?.person?.firstName }} {{ caseProperty?.person?.lastName }}</td>
    <td *ngIf="!caseProperty.person">Joint</td>
    <td>{{ caseProperty?.caseCreditor?.creditor?.name }}</td>
    <td class="text-right">
      <div class="btn-group">
        <a type="button" class="btn btn-xs btn-warning" (click)="edit.open(caseId, caseProperty)">Edit</a>
        <button type="button" class="btn btn-xs btn-warning dropdown-toggle" data-toggle="dropdown"
                aria-haspopup="true" aria-expanded="false" *ngIf="authService.hasPermission('user.delete-cases')">
          <span class="caret" *ngIf="authService.hasPermission('user.delete-cases')"></span>
          <span class="sr-only" *ngIf="authService.hasPermission('user.delete-cases')">Toggle Dropdown</span>
        </button>
        <ul class="dropdown-menu" *ngIf="authService.hasPermission('user.delete-cases')">
          <li *ngIf="authService.hasPermission('user.delete-cases')"><a href="javascript:;"
                                                                        (click)="deleteModal.confirmDialog(caseProperty.id)">Delete</a>
          </li>
        </ul>
      </div>
    </td>
  </tr>
  </tbody>
  <tfoot>
  <tr>
    <td colspan="99" class="text-center">
      <app-pagination [totalPages]="totalPages" [currentPage]="currentPage"
                      (pageChanged)="onPageChanged($event)"></app-pagination>
    </td>
  </tr>
  </tfoot>
</table>


<app-case-property-create (completed)="onPageChanged(1)" #create></app-case-property-create>

<app-case-property-edit (completed)="onPageChanged(1)" #edit></app-case-property-edit>

<app-delete-modal type="property" (confirm)="delete($event)" [itemDeleted]="itemDeleted"
                  #deleteModal></app-delete-modal>
