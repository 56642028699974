import {Component, Input, OnInit} from '@angular/core';
import {DepartmentService} from '../department.service';
import {Department} from '../department';
import {NotificationService} from '../../../shared/notification.service';
import {SelectComparisons} from '../../../shared/pipes/select.comparisons';

@Component({
    selector: 'app-department-select',
    templateUrl: 'select.component.html',
})
export class DepartmentSelectComponent extends SelectComparisons implements OnInit {
    /**
     * The model that we will be adding the department to.
     */
    @Input() model: any;

    /**
     * Do we require this field in the main form it is
     * included in?
     */
    @Input() required: boolean;

    /**
     * A list of all the departments currently loaded.
     *
     * @type {Department[]}
     */
    public departments: Department[] = [];

    /**
     * Are we currently performing a loading action?
     *
     * @type {boolean}
     */
    public isLoading: boolean = true;

    /**
     * Load up all our required services and subscribe to every observable we need.
     *
     * @param {DepartmentService} departmentService
     * @param {NotificationService} notificationService
     */
    constructor(
        private departmentService: DepartmentService,
        private notificationService: NotificationService,
    ) {
        super();
        this.departmentService.loading.subscribe(isLoading => {
            this.isLoading = isLoading;
        });
    }

    /**
     * When the component is initialised we set it to load
     * page one of the department list.
     */
    public ngOnInit(): void {
        this.loadDepartments(1, 10000);
    }

    /**
     * Loads a list of departments using the given per page limit.
     *
     * @param {number} page The current page we require.
     * @param {number} limit The number of results per page.
     */
    public loadDepartments(page?: number, limit?: number): void {
        const listDepartments = this.departmentService
            .listDepartments(page, limit)
            .subscribe((departments: Department[]) => {
                this.departments = departments;
                listDepartments.unsubscribe();
            });
    }
}
