import { Injectable } from '@angular/core';
import { Permission } from './permission';
import { Observable } from 'rxjs';

import { HttpRequestService } from '../shared/http/http-request.service';

@Injectable()
export class PermissionService extends HttpRequestService {
  /**
   * The endpoint that we call to get the permission information.
   *
   * @type {string}
   */
  private readonly endpoint: string = 'permissions';

  /**
   * Brings back a list of all accessible permissions.
   *
   * @param {number} page The page number we are currently on.
   * @param {number} limit The number of permissions to display per page.
   * @returns {Observable<Permission[]>}
   */
  public listPermissions(
    page?: number,
    limit?: number,
  ): Observable<Permission[]> {
    return this.list(this.endpoint, page, undefined, limit);
  }

  /**
   * Finds details of the permission from the given ID.
   *
   * @param {string} permissionId The ID of the permission we require details for.
   * @returns {Observable<Permission>}
   */
  public findPermission(permissionId: string): Observable<Permission> {
    return this.findById(this.endpoint, permissionId);
  }

  /**
   * Creates a new permission from the given Permission object.
   *
   * @param {Permission} newPermission The details of the permission to be created.
   * @returns {Observable<Permission | boolean>}
   */
  public createPermission(
    newPermission: Permission,
  ): Observable<Permission | boolean> {
    return this.create(this.endpoint, newPermission);
  }

  /**
   * Updates a permission with the given permission object for the given permission ID.
   *
   * @param {string} permissionId The ID of the permission needing updating.
   * @param {Permission} newDetails The new permission object to update with.
   * @returns {Observable<Permission | boolean>}
   */
  public updatePermission(
    permissionId: string,
    newDetails: Permission,
  ): Observable<Permission | boolean> {
    return this.update(this.endpoint, permissionId, newDetails);
  }

  /**
   * Deletes a permission based on the given permission ID.
   *
   * @param {string} permissionId The ID of the permission needing deleting.
   * @returns {Observable<boolean>}
   */
  public deletePermission(permissionId: string): Observable<boolean> {
    return this.delete(this.endpoint, permissionId);
  }
}
