import { RouterModule, Routes } from '@angular/router';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule } from '@angular/common/http';
import { AccountGroupListComponent } from './list/list.component';
import { AuthGuard } from '../../auth/auth.guard';
import { AccountGroupService } from './account-group.service';
import { AccountGroupCreateComponent } from './create/create.component';
import { AccountGroupFormComponent } from './form/form.component';
import { AccountGroupEditComponent } from './edit/edit.component';
import { SharedModule } from '../../shared/shared.module';

const routes: Routes = [
  {
    path: 'account-groups',
    component: AccountGroupListComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'account-groups/create',
    component: AccountGroupCreateComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'account-groups/:id/edit',
    component: AccountGroupEditComponent,
    canActivate: [AuthGuard],
  },
];

@NgModule({
  declarations: [
    AccountGroupListComponent,
    AccountGroupCreateComponent,
    AccountGroupFormComponent,
    AccountGroupEditComponent,
  ],
  imports: [
    BrowserModule,
    RouterModule,
    FormsModule,
    HttpClientModule,
    SharedModule,
    RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' }),
  ],
  exports: [RouterModule],
  providers: [AuthGuard, AccountGroupService],
})
export class AccountGroupRoutingModule {}
