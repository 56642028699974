import { INITIAL_EMPLOYMENT_STATUS_STATE } from './employment-status.state';
import { ReduxApiActionTypes } from '../../shared/redux-api.action-types';

export const employmentStatusReducer = (lastState = INITIAL_EMPLOYMENT_STATUS_STATE, { type, payload }) => {

  switch (type) {
    case ReduxApiActionTypes.getReducerAction(ReduxApiActionTypes.GET_ALL, 'EMPLOYMENT_STATUSES'):
      return { ...lastState, list: payload };
    case ReduxApiActionTypes.getReducerAction(ReduxApiActionTypes.GET_ONE, 'EMPLOYMENT_STATUSES'):
      return { ...lastState, selected: payload };
    case ReduxApiActionTypes.getReducerAction(ReduxApiActionTypes.UPDATE, 'EMPLOYMENT_STATUSES'):
      return { ...lastState, selected: undefined };
    case ReduxApiActionTypes.getReducerAction(ReduxApiActionTypes.CLEAR_ALL, 'EMPLOYMENT_STATUSES'):
      return { ...lastState, list: undefined };
    case ReduxApiActionTypes.getReducerAction(ReduxApiActionTypes.CLEAR_ONE, 'EMPLOYMENT_STATUSES'):
      return { ...lastState, selected: undefined };
    case ReduxApiActionTypes.getReducerAction(ReduxApiActionTypes.SELECT_ALL, 'EMPLOYMENT_STATUSES'):
      return { ...lastState, selectList: payload };
    default:
      return lastState;
  }
};
