import { Component, Input, OnInit } from '@angular/core';
import { DeliveryMethodService } from '../delivery-method.service';
import { DeliveryMethod } from '../delivery-method';
import { NotificationService } from '../../shared/notification.service';
import { SelectComparisons } from '../../shared/pipes/select.comparisons';

@Component({
  selector: 'app-delivery-method-select',
  templateUrl: 'select.component.html',
})
export class DeliveryMethodSelectComponent extends SelectComparisons
  implements OnInit {
  /**
   * The model that we will be adding the deliveryMethod to.
   */
  @Input() model: any;

  /**
   * Do we require this field in the main form it is
   * included in?
   */
  @Input() required: boolean;

  /**
   * A list of all the delivery-methods currently loaded.
   *
   * @type {DeliveryMethod[]}
   */
  public deliveryMethods: DeliveryMethod[] = [];

  /**
   * Are we currently performing a loading action?
   *
   * @type {boolean}
   */
  public isLoading: boolean = true;

  /**
   * Load up all our required services and subscribe to every observable we need.
   *
   * @param {DeliveryMethodService} deliveryMethodService
   * @param {NotificationService} notificationService
   */
  constructor(
    private deliveryMethodService: DeliveryMethodService,
    private notificationService: NotificationService,
  ) {
    super();
    this.deliveryMethodService.loading.subscribe(isLoading => {
      this.isLoading = isLoading;
    });
  }

  /**
   * When the component is initialised we set it to load
   * page one of the deliveryMethod list.
   */
  public ngOnInit(): void {
    this.loadDeliveryMethods(1, 10000);
  }

  /**
   * Loads a list of delivery-methods using the given per page limit.
   *
   * @param {number} page The current page we require.
   * @param {number} limit The number of results per page.
   */
  public loadDeliveryMethods(page?: number, limit?: number): void {
    const listDeliveryMethods = this.deliveryMethodService
      .listDeliveryMethods(page, limit)
      .subscribe((deliveryMethods: DeliveryMethod[]) => {
        this.deliveryMethods = deliveryMethods;
        listDeliveryMethods.unsubscribe();
      });
  }
}
