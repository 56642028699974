import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NotificationService } from '../../../shared/notification.service';
import { FinancialAccountsService } from '../accounts.service';
import { FinancialAccount } from '../accounts';

@Component({
  selector: 'app-financial-accounts-edit',
  templateUrl: 'edit.component.html',
})
export class FinancialAccountsEditComponent {
  /**
   * Details of the financialAccounts being edited.
   */
  public financialAccounts: FinancialAccount;

  /**
   * Are we currently performing a loading action?
   *
   * @type {boolean}
   */
  public isLoading: boolean = false;

  /**
   * Load up all our required services and subscribe to every observable we need.
   *
   * @param {AccountsService} financialAccountsService
   * @param {NotificationService} notificationService
   * @param {Router} router
   * @param {ActivatedRoute} route
   */
  constructor(
    private financialAccountsService: FinancialAccountsService,
    private notificationService: NotificationService,
    private router: Router,
    private route: ActivatedRoute,
  ) {
    this.financialAccountsService.loading.subscribe(
      result => (this.isLoading = result),
    );
    this.route.params.subscribe(params =>
      this.loadFinancialAccounts(params.id),
    );
  }

  /**
   * Perform the edit action which send the edited financialAccounts to the backend.
   */
  public edit() {
    const editFinancialAccounts = this.financialAccountsService
      .updateFinancialAccounts(
        this.financialAccounts.id,
        this.financialAccounts,
      )
      .subscribe(
        (result: FinancialAccount) => {
          this.notificationService.success(
            `Delivery Method Updated`,
            `The delivery Method ${
              result.name
            } been updated with the specified changes.`,
            5,
          );
          this.financialAccounts = new FinancialAccount();
          editFinancialAccounts.unsubscribe();
          this.router.navigate(['/financial-accounts']);
        },
        err => {
          this.notificationService.error(
            `Delivery Method Not Found`,
            err.error.message,
            10,
          );
          this.router.navigate(['/financial-accounts']);
        },
      );
  }

  /**
   * Loads the requested financialAccounts so we can display it and let the user edit the fields.
   *
   * @param {string} id
   */
  private loadFinancialAccounts(id: string) {
    this.financialAccountsService.findFinancialAccounts(id).subscribe(
      result => {
        this.financialAccounts = result;
      },
      err => {
        this.notificationService.error(
          `Delivery Method Not Found`,
          err.error.message,
          10,
        );
        this.router.navigate(['/financial-accounts']);
      },
    );
  }
}
