import { LinkProps, LinkWrapper } from 'react-inside-angular';
import { useHistory as RRDUseHistory } from 'react-router-dom';

import Alert from '../../Components/Alert';
import LinkButton from '../../Components/LinkButton';
import LinkDropdown from '../../Components/LinkDropdown';

import Pagination, { useCurrentPage } from '../../Components/Pagination';
import Panel from '../../Components/Panel';
import useTitles from './UseTitles';

export type TitlesListProps = {};

const ListTitles = (props: LinkProps<TitlesListProps>) => {
  const Link =
    props.Navigate !== undefined
      ? LinkWrapper(props.Navigate).useHistory
      : RRDUseHistory();

  const { items, error, loading, metaData, loadItems, deleteItem } = useTitles(
    true,
  );

  const [page, setPage] = useCurrentPage((n: number) => loadItems({ page: n }));

  const deleteWithId = (id: string) =>
    deleteItem(id).finally(() => loadItems({ page }));

  return (
    <Panel
      title="List Titles"
      count={metaData?.totalResults}
      loading={loading}
      edgeToEdge
      footer={
        <Pagination
          totalPages={metaData?.totalPages ?? 1}
          currentPage={page}
          onPageChanged={setPage}
        />
      }
    >
      {error && (
        <Alert color="red" title="Error" message={error} className="tw-m-6" />
      )}

      {items && (
        <div className="tw-table tw-w-full tw-mt-2">
          <div className="tw-table-header-group">
            <div className="tw-table-row">
              <div className="tw-font-bold tw-table-cell tw-p-2 tw-border-solid tw-border-0 tw-border-b-2 tw-border-gray-100 tw-pb-2 tw-pl-4">
                Title Name
              </div>
              <div className="tw-table-cell tw-p-2 tw-pb-2 tw-border-solid tw-border-0 tw-border-b-2 tw-border-gray-100 tw-text-right tw-pb-2 tw-pr-4">
                <LinkButton
                  to={`/titles/create`}
                  title="Create"
                  color="green"
                  Navigate={props.Navigate}
                  className=""
                />
              </div>
            </div>
          </div>

          {items &&
            items.map((title) => (
              <div className="tw-table-row-group">
                <div className="tw-table-row tw-h-max hover:tw-bg-gray-100">
                  <div className="tw-table-cell tw-p-1 tw-pl-4 tw-flex tw-items-center">
                    {title.name}
                  </div>
                  <div className="tw-table-cell tw-p-1 tw-text-right tw-pr-4 tw-flex tw-items-center">
                    <LinkDropdown
                      to={`/titles/${title.id}/edit`}
                      title="Edit"
                      color="yellow"
                      Navigate={props.Navigate}
                    >
                      <LinkDropdown.Link
                        onClick={(e) => {
                          e.preventDefault();
                          deleteWithId(title.id ?? '');
                        }}
                      >
                        Delete
                      </LinkDropdown.Link>
                    </LinkDropdown>
                  </div>
                </div>
              </div>
            ))}
        </div>
      )}
    </Panel>
  );
};

export default ListTitles;
