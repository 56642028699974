<div class="text-right button-menu">
  <button
    (click)="upload.open(caseId)"
    class="btn btn-sm btn-success"
    href="javascript:;"
    type="button"
  >
    Add Document
  </button>
</div>

<div *ngIf="loading" class="row">
  <div class="col-md-12 text-center">
    <h4>Loading Documents - Please Wait</h4>
  </div>
</div>

<table class="documents table table-striped">
  <ng-container *ngFor="let singleFolder of documentsInFolders">
    <thead>
      <tr (click)="toggleFolderOpen(singleFolder.name)" style="cursor: pointer">
        <th colspan="99">
          <i
            [class.fa-minus-square]="folderOpen(singleFolder.name)"
            [class.fa-plus-square]="!folderOpen(singleFolder.name)"
            class="fa"
            style="margin-right: 5px"
          ></i
          >{{ singleFolder.name }}
        </th>
      </tr>
    </thead>
    <tbody
      *ngIf="folderOpen(singleFolder.name)"
      style="width: 100%; display: block"
    >
      <tr
        *ngFor="let caseDocument of singleFolder.documents"
        style="display: table; width: 100%; table-layout: fixed"
      >
        <td>
          <app-user-icon
            *ngIf="caseDocument.user"
            [passedUser]="caseDocument.user"
            [size]="20"
            [userId]="caseDocument.user.id"
          ></app-user-icon>
        </td>
        <td>{{ caseDocument?.document?.folder?.name }}</td>
        <td>
          {{
            caseDocument.template && caseDocument.template.name
              ? caseDocument.template.name + ' - '
              : ''
          }}
          {{ caseDocument.document ? caseDocument.document.name : 'Unknown' }}
        </td>
        <td>
          {{ caseDocument.creditor ? caseDocument.creditor.name : 'None' }}
        </td>
        <td>
          {{ caseDocument?.deliveryMethod?.name }}

          <ng-container
            *ngIf="
              caseDocument?.deliveryMethod?.name === 'E-Sign' &&
              caseDocument.approvedAt
            "
          >
            <span
              *ngIf="!caseDocument?.esignComplete"
              class="label label-danger"
              style="margin: 0 10px 0 10px"
              >Not Signed</span
            >

            <i
              *ngIf="caseDocument?.esignComplete"
              [popover]="esignPopover"
              class="fa fa-pencil"
              placement="auto"
              style="margin: 0 10px 0 10px"
              triggers="mouseenter:mouseleave"
            ></i>

            <ng-template #esignPopover>
              <table class="table">
                <tr>
                  <td colspan="2">
                    <img src="{{ caseDocument?.esignSignature }}" />
                  </td>
                </tr>
                <tr>
                  <th>Signed At</th>
                  <td>
                    {{ caseDocument?.esignDate | date: 'HH:mm dd/MM/yyyy' }}
                  </td>
                </tr>
                <tr>
                  <th>IP Address</th>
                  <td>{{ caseDocument?.esignSecurity }}</td>
                </tr>
              </table>
            </ng-template>
          </ng-container>
        </td>
        <td>
          {{
            caseDocument.person
              ? caseDocument.person.firstName +
                ' ' +
                caseDocument.person.lastName
              : 'Not Specific'
          }}
        </td>
        <td>
          <span
            data-placement="top"
            data-toggle="tooltip"
            tooltip="{{ caseDocument.createdAt | date: 'HH:mm dd/MM/yyyy' }}"
            >{{ caseDocument.createdAt | timeAgo }}</span
          >
        </td>
        <td>
          <span
            *ngIf="caseDocument.approvedAt"
            data-placement="top"
            data-toggle="tooltip"
            tooltip="{{ caseDocument.approvedAt | date: 'HH:mm dd/MM/yyyy' }}"
            >{{ caseDocument.approvedAt | timeAgo }}</span
          >
          <span
            *ngIf="!caseDocument.approvedAt"
            class="text-danger fa fa-times"
          ></span>
        </td>
        <td class="text-right">
          <div class="btn-group">
            <a
              class="btn btn-xs btn-warning"
              href="{{ caseDocument?.url }}"
              target="_blank"
              type="button"
              >View</a
            >
            <button
              aria-expanded="false"
              aria-haspopup="true"
              class="btn btn-xs btn-warning dropdown-toggle"
              data-toggle="dropdown"
              type="button"
            >
              <span class="caret"></span>
              <span class="sr-only">Toggle Dropdown</span>
            </button>
            <ul class="dropdown-menu dropdown-menu-right" container="body">
              <li>
                <a href="{{ caseDocument?.url }}" target="_blank">Download</a>
              </li>
              <li
                *ngIf="
                  !caseDocument.approvedAt &&
                  authService.hasPermission('user.approve-documents')
                "
              >
                <a
                  (click)="approve.open(caseId, caseDocument)"
                  href="javascript:"
                  >Approve Document</a
                >
              </li>
              <li *ngIf="authService.hasPermission('user.delete-cases')">
                <a
                  (click)="deleteModal.confirmDialog(caseDocument.id)"
                  href="javascript:"
                  >Delete</a
                >
              </li>
            </ul>
          </div>
        </td>
      </tr>
    </tbody>
  </ng-container>
</table>

<!--<table class="documents table table-striped">-->
<!--  <thead>-->
<!--  <tr>-->
<!--      <th class="text-center" width="40"></th>-->
<!--    <th>Folder</th>-->
<!--    <th>Document Type</th>-->
<!--    <th>Creditor</th>-->
<!--    <th>Delivery Method</th>-->
<!--    <th>Client</th>-->
<!--    <th>Created</th>-->
<!--    <th>Approved</th>-->
<!--    <th></th>-->
<!--  </tr>-->
<!--  </thead>-->
<!--  <tbody>-->
<!--  <tr *ngFor="let caseDocument of caseDocuments">-->
<!--    <td>-->
<!--        <app-user-icon *ngIf="caseDocument.user" [passedUser]="caseDocument.user" [size]="20"-->
<!--                       [userId]="caseDocument.user.id"></app-user-icon>-->
<!--    </td>-->
<!--    <td>{{ caseDocument?.document?.folder?.name }}</td>-->
<!--    <td>{{ caseDocument.template && caseDocument.template.name ? caseDocument.template.name + ' - ' : '' }} {{-->
<!--      caseDocument.document ?-->
<!--          caseDocument.document.name : 'Unknown' }}-->
<!--    </td>-->
<!--    <td>{{ caseDocument.creditor ? caseDocument.creditor.name : 'None' }}</td>-->
<!--    <td>-->

<!--      {{ caseDocument?.deliveryMethod?.name }}-->

<!--      <ng-container *ngIf="caseDocument?.deliveryMethod?.name === 'E-Sign' && caseDocument.approvedAt">-->

<!--        <span *ngIf="!caseDocument?.esignComplete" class="label label-danger"-->
<!--              style="margin: 0 10px 0 10px">Not Signed</span>-->

<!--          <i *ngIf="caseDocument?.esignComplete" [popover]="esignPopover" class="fa fa-pencil"-->
<!--             placement="auto"-->
<!--             style="margin: 0 10px 0 10px"-->
<!--             triggers="mouseenter:mouseleave"></i>-->

<!--        <ng-template #esignPopover>-->
<!--          <table class="table">-->
<!--            <tr>-->
<!--              <td colspan="2">-->
<!--                <img src="{{ caseDocument?.esignSignature }}">-->
<!--              </td>-->
<!--            </tr>-->
<!--            <tr>-->
<!--              <th>Signed At</th>-->
<!--              <td>{{ caseDocument?.esignDate | date:'HH:mm dd/MM/yyyy' }}</td>-->
<!--            </tr>-->
<!--            <tr>-->
<!--              <th>IP Address</th>-->
<!--              <td>{{ caseDocument?.esignSecurity }}</td>-->
<!--            </tr>-->
<!--          </table>-->
<!--        </ng-template>-->

<!--      </ng-container>-->

<!--    </td>-->
<!--    <td>-->
<!--      {{ caseDocument.person ? caseDocument.person.firstName + " " + caseDocument.person.lastName : "Not Specific" }}-->
<!--    </td>-->
<!--      <td><span data-placement="top" data-toggle="tooltip"-->
<!--                tooltip="{{ caseDocument.createdAt | date:'HH:mm dd/MM/yyyy' }}">{{ caseDocument.createdAt | timeAgo }}</span>-->
<!--      </td>-->
<!--    <td>-->
<!--      <span *ngIf="caseDocument.approvedAt"-->
<!--            data-placement="top"-->
<!--            data-toggle="tooltip"-->
<!--            tooltip="{{ caseDocument.approvedAt | date:'HH:mm dd/MM/yyyy' }}">{{ caseDocument.approvedAt | timeAgo }}</span>-->
<!--      <span *ngIf="!caseDocument.approvedAt" class="text-danger fa fa-times"></span>-->
<!--    </td>-->
<!--    <td class="text-right">-->
<!--      <div class="btn-group">-->
<!--          <a class="btn btn-xs btn-warning" href="{{ caseDocument?.url }}" target="_blank" type="button">View</a>-->
<!--          <button aria-expanded="false" aria-haspopup="true" class="btn btn-xs btn-warning dropdown-toggle"-->
<!--                  data-toggle="dropdown" type="button">-->
<!--          <span class="caret"></span>-->
<!--          <span class="sr-only">Toggle Dropdown</span>-->
<!--        </button>-->
<!--        <ul class="dropdown-menu">-->
<!--          <li><a href="{{ caseDocument?.url }}" target="_blank">Download</a></li>-->
<!--          <li *ngIf="!caseDocument.approvedAt && authService.hasPermission('user.approve-documents')"><a-->
<!--                  (click)="approve.open(caseId, caseDocument)" href="javascript:">Approve-->
<!--            Document</a></li>-->
<!--            <li *ngIf="authService.hasPermission('user.delete-cases')"><a-->
<!--                    (click)="deleteModal.confirmDialog(caseDocument.id)"-->
<!--                    href="javascript:">Delete</a>-->
<!--          </li>-->
<!--        </ul>-->
<!--      </div>-->
<!--    </td>-->
<!--  </tr>-->
<!--  </tbody>-->
<!--  <tfoot>-->
<!--  <tr>-->
<!--      <td class="text-center" colspan="99">-->
<!--          <app-pagination (pageChanged)="onPageChanged($event)" [currentPage]="currentPage"-->
<!--                          [totalPages]="totalPages"></app-pagination>-->
<!--    </td>-->
<!--  </tr>-->
<!--  </tfoot>-->
<!--</table>-->

<app-delete-modal
  #deleteModal
  (confirm)="delete($event)"
  [itemDeleted]="itemDeleted"
  type="document"
></app-delete-modal>

<app-case-document-approve
  #approve
  (completed)="onPageChanged(1)"
></app-case-document-approve>

<app-case-document-upload
  #upload
  (completed)="onPageChanged(1)"
></app-case-document-upload>
