import { Component, Input, OnInit } from '@angular/core';
import { PaymentTypeService } from '../payment-type.service';
import { PaymentType } from '../payment-type';
import { SelectComparisons } from '../../../shared/pipes/select.comparisons';
import { NotificationService } from '../../../shared/notification.service';

@Component({
  selector: 'app-payment-type-select',
  templateUrl: 'select.component.html',
})
export class PaymentTypeSelectComponent extends SelectComparisons
  implements OnInit {
  /**
   * The model that we will be adding the paymentType to.
   */
  @Input() model: any;

  /**
   * Do we require this field in the main form it is
   * included in?
   */
  @Input() required: boolean;

  /**
   * A list of all the payment-types currently loaded.
   *
   * @type {PaymentType[]}
   */
  public paymentTypes: PaymentType[] = [];

  /**
   * Are we currently performing a loading action?
   *
   * @type {boolean}
   */
  public isLoading: boolean = true;

  /**
   * Load up all our required services and subscribe to every observable we need.
   *
   * @param {PaymentTypeService} paymentTypeService
   * @param {NotificationService} notificationService
   */
  constructor(
    private paymentTypeService: PaymentTypeService,
    private notificationService: NotificationService,
  ) {
    super();
    this.paymentTypeService.loading.subscribe(isLoading => {
      this.isLoading = isLoading;
    });
  }

  /**
   * When the component is initialised we set it to load
   * page one of the paymentType list.
   */
  public ngOnInit(): void {
    this.loadPaymentTypes(1, 10000);
  }

  /**
   * Loads a list of payment-types using the given per page limit.
   *
   * @param {number} page The current page we require.
   * @param {number} limit The number of results per page.
   */
  public loadPaymentTypes(page?: number, limit?: number): void {
    const listPaymentTypes = this.paymentTypeService
      .listPaymentTypes(page, limit)
      .subscribe((paymentTypes: PaymentType[]) => {
        this.paymentTypes = paymentTypes;
        listPaymentTypes.unsubscribe();
      });
  }
}
