import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { HttpRequestService } from '../../../shared/http/http-request.service';
import { HttpOptions } from '../../../shared/http/http-options';
import { CaseDocumentGenerator } from './case-document-generator';
import { CasePackGenerator } from './case-pack-generator';

@Injectable()
export class CaseDocumentGeneratorService extends HttpRequestService {
  private readonly endpoint: string = 'cases/:id/documents/:templateId/generate';
  private readonly packEndpoint: string = 'cases/:id/pack/:packId/generate';

  public createDocument(
    newCase: CaseDocumentGenerator,
    options?: HttpOptions,
  ): Observable<CaseDocumentGenerator | boolean> {
    return this.create(this.endpoint, newCase, options);
  }

  public createPack(
    newCase: CasePackGenerator,
    options?: HttpOptions,
  ): Observable<CasePackGenerator | boolean> {
    return this.create(this.packEndpoint, newCase, options);
  }
}
