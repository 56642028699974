import { Component, Input } from '@angular/core';
import { MaritalStatus } from '../marital-status';

@Component({
  selector: 'app-marital-status-form',
  templateUrl: 'form.component.html',
})
export class MaritalStatusFormComponent {
  @Input() maritalStatus: MaritalStatus;
}
