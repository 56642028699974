<div class="form-group">
  <label for="name" class="col-sm-2 control-label">Name</label>
  <div class="col-sm-10">
    <input
      type="text"
      class="form-control"
      name="name"
      id="name"
      placeholder="Creditor Name"
      [(ngModel)]="creditor.name"
      required
    />
  </div>
</div>

<div class="form-group">
  <label for="name" class="col-sm-2 control-label">Portfolio</label>
  <div class="col-sm-10">
    <app-portfolio-select
      [model]="creditor"
      [required]="false"
    ></app-portfolio-select>
  </div>
</div>

<div class="row">
  <div class="col-md-6">
    <div class="form-group">
      <label for="sortCode" class="col-sm-4 control-label">Sort Code</label>
      <div class="col-sm-8">
        <input
          type="text"
          class="form-control"
          name="sortCode"
          id="sortCode"
          placeholder="Sort Code"
          [(ngModel)]="creditor.sortCode"
        />
      </div>
    </div>
  </div>
  <div class="col-md-6">
    <div class="form-group">
      <label for="accountNumber" class="col-sm-4 control-label"
        >Account Number</label
      >
      <div class="col-sm-8">
        <input
          type="text"
          class="form-control"
          name="accountNumber"
          id="accountNumber"
          placeholder="Account Number"
          [(ngModel)]="creditor.accountNumber"
        />
      </div>
    </div>
  </div>
</div>

<div class="form-group">
  <label for="name" class="col-sm-2 control-label">Minimum Distribution</label>
  <div class="col-sm-10">
    <app-shared-currency
      [(ngModel)]="creditor.minimumDistribution"
    ></app-shared-currency>
  </div>
</div>

<div class="form-group">
  <label for="name" class="col-sm-2 control-label"
    >Preferred Contact Method</label
  >
  <div class="col-sm-10">
    <select [(ngModel)]="creditor.preferredContact" class="form-control">
      <option value="post">Postal</option>
      <option value="email">E-Mail</option>
    </select>
  </div>
</div>

<app-shared-address [model]="creditor" [labelWidth]="2"></app-shared-address>

<app-shared-contact-details
  [model]="creditor"
  [labelWidth]="2"
  [creditorEmails]="true"
></app-shared-contact-details>

<div class="form-group">
  <div class="col-sm-offset-2 col-sm-10 text-right">
    <button type="submit" class="btn btn-success">Save</button>
  </div>
</div>
