import { Component, OnInit } from '@angular/core';
import { BreachTypeService } from '../breach-type.service';
import { BreachType } from '../breach-type';
import { Router } from '@angular/router';
import { NotificationService } from '../../../shared/notification.service';

@Component({
  selector: 'app-breach-type-create',
  templateUrl: 'create.component.html',
})
export class BreachTypeCreateComponent implements OnInit {
  /**
   * Details of the breachType being created.
   */
  public breachType: BreachType;

  /**
   * Are we currently performing a loading action?
   *
   * @type {boolean}
   */
  public isLoading: boolean = false;

  /**
   * Load up all our required services and subscribe to every observable we need.
   *
   * @param {BreachTypeService} breachTypeService
   * @param {NotificationService} notificationService
   * @param {Router} router
   */
  constructor(
    private breachTypeService: BreachTypeService,
    private notificationService: NotificationService,
    private router: Router,
  ) {
    this.breachTypeService.loading.subscribe(
      result => (this.isLoading = result),
    );
  }

  /**
   * Initialise the component, creating a new blank breachType if required.
   */
  public ngOnInit(): void {
    if (!this.breachType) {
      this.breachType = new BreachType();
    }
  }

  /**
   * Perform the create action which adds the new breachType into the backend.
   */
  public create() {
    const createBreachType = this.breachTypeService
      .createBreachType(this.breachType)
      .subscribe(
        (result: BreachType) => {
          this.notificationService.success(
            `BreachType Created`,
            `New breachType ${result.name} has been created with ID of ${
              result.id
            }.`,
            5,
          );
          this.breachType = new BreachType();
          createBreachType.unsubscribe();
          this.router.navigate(['/breach-types']);
        },
        err => {
          this.notificationService.error(
            `Could not create BreachType`,
            err.error.message,
            10,
          );
        },
      );
  }
}
