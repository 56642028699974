import { Component, Input } from '@angular/core';
import AddressType from '../../../../chief-types/src/AddressType';
import { AuthService } from '../../auth/auth.service';
import { Address } from '../../shared/address/address';
import { SelectComparisons } from '../../shared/pipes/select.comparisons';
import { AddressTypeService } from '../address-type.service';

@Component({
  selector: 'app-address-type-select',
  templateUrl: 'select.component.html',
})
export class AddressTypeSelectComponent extends SelectComparisons {
  @Input() model: Address;

  @Input() required: boolean;

  public addressTypes: AddressType[] = [];

  public isLoading = false;

  constructor(protected readonly addressTypeService: AddressTypeService) {
    super();
    this.addressTypeService.loading.subscribe((isLoading) => {
      this.isLoading = isLoading;
    });
  }

  /**
   * When the component is initialised we set it to load
   * page one of the approval list.
   */
  public ngOnInit(): void {
    this.loadAddressTypes();
  }

  private async loadAddressTypes() {
    this.isLoading = true;
    const listAddressTypes = this.addressTypeService
      .listAddressTypes(1, 10000)
      .subscribe((addressType: AddressType[]) => {
        this.addressTypes = addressType;
        listAddressTypes.unsubscribe();
        this.isLoading = false;
      });
  }
}
