import {Component, ViewChild} from '@angular/core';
import {Router} from '@angular/router';
import {PopoverDirective} from 'ngx-bootstrap/popover';

import {Case} from '../../cases/case';
import {CaseService} from '../../cases/case.service';

@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.css'],
})
export class SearchComponent {
  @ViewChild('searchPopover') private searchPopover: PopoverDirective;

  private readonly minimumSearchLength: number = 2;

  public foundCases: Case[] = [];

  public searchValue = '';

  public searchSubscription = undefined;
  public searching = false;

  constructor(
    private readonly caseService: CaseService,
    private readonly router: Router,
  ) {}

  public goToFullSearch() {
    this.router
      .navigate([`/cases/search/${this.searchValue}`])
      .then((result) => {
        this.clearSearch();
      });
  }

  public goToCase(caseId: string): void {
    this.router.navigate([`/cases/${caseId}`]).then((result) => {
      this.clearSearch();
    });
  }

  public performSearch(value): void {
    if (this.searchSubscription) {
      this.searchSubscription.unsubscribe();
    }

    if (value.length > this.minimumSearchLength) {
      this.searchPopover.show();
      this.searching = true;
      this.searchSubscription = this.caseService
        .search(value)
        .subscribe((cases: any) => {
          this.foundCases = cases;
          this.searchSubscription = undefined;
          this.searching = false;
        });
    }
  }

  public clearSearch(): void {
    this.searchValue = '';
    this.foundCases = [];
    this.searchPopover.hide();
  }
}
