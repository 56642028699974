<div
  class="modal fade"
  bsModal
  #staticModal="bs-modal"
  [config]="{ backdrop: 'static' }"
  tabindex="-1"
  role="dialog"
  aria-labelledby="mySmallModalLabel"
  aria-hidden="true"
>
  <div class="modal-dialog modal-md">
    <form
      (submit)="confirmChange($event)"
      #completedForm="ngForm"
      ngNativeValidate
    >
      <div class="modal-content panel-warning">
        <div class="modal-header panel-heading">
          <h4 class="modal-title pull-left">Change Status</h4>
          <button
            type="button"
            class="close pull-right"
            aria-label="Close"
            (click)="close()"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <div class="row">
            <div class="col-md-3"><strong>Status</strong></div>
            <div class="col-md-9">
              <app-status-select
                *ngIf="model"
                [model]="model"
                [required]="true"
              ></app-status-select>
            </div>
          </div>

          <div class="row" style="margin-top: 20px">
            <div class="col-md-3"><strong>Sub-Status</strong></div>
            <div class="col-md-9">
              <app-substatus-select
                *ngIf="model"
                [model]="model"
                [required]="false"
              ></app-substatus-select>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-default" (click)="close()">
            Cancel
          </button>
          <button type="submit" class="btn btn-success" [disabled]="disabled">
            Save
          </button>
        </div>
      </div>
    </form>
  </div>
</div>
