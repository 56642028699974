import { Component, OnInit } from '@angular/core';
import { AssetService } from '../asset.service';
import { Asset } from '../asset';
import { Router } from '@angular/router';
import { NotificationService } from '../../shared/notification.service';

@Component({
  selector: 'app-asset-create',
  templateUrl: 'create.component.html',
})
export class AssetCreateComponent implements OnInit {
  /**
   * Details of the asset being created.
   */
  public asset: Asset;

  /**
   * Are we currently performing a loading action?
   *
   * @type {boolean}
   */
  public isLoading: boolean = false;

  /**
   * Load up all our required services and subscribe to every observable we need.
   *
   * @param {AssetService} assetService
   * @param {NotificationService} notificationService
   * @param {Router} router
   */
  constructor(
    private assetService: AssetService,
    private notificationService: NotificationService,
    private router: Router,
  ) {
    this.assetService.loading.subscribe(result => (this.isLoading = result));
  }

  /**
   * Initialise the component, creating a new blank asset if required.
   */
  public ngOnInit(): void {
    if (!this.asset) {
      this.asset = new Asset();
    }
  }

  /**
   * Perform the create action which adds the new asset into the backend.
   */
  public create() {
    const createAsset = this.assetService.createAsset(this.asset).subscribe(
      (result: Asset) => {
        this.notificationService.success(
          `Asset Created`,
          `New asset ${result.name} has been created with ID of ${result.id}.`,
          5,
        );
        this.asset = new Asset();
        createAsset.unsubscribe();
        this.router.navigate(['/assets']);
      },
      err => {
        this.notificationService.error(
          `Could not create Asset`,
          err.error.message,
          10,
        );
      },
    );
  }
}
