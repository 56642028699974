import { Injectable } from '@angular/core';
import { Letterhead } from './letterhead';
import { Observable } from 'rxjs';

import { HttpRequestService } from '../shared/http/http-request.service';

@Injectable()
export class LetterheadService extends HttpRequestService {
  /**
   * The endpoint that we call to get the letterhead information.
   *
   * @type {string}
   */
  private readonly endpoint: string = 'letterheads';

  /**
   * Brings back a list of all accessible letterheads.
   *
   * @param {number} page The page number we are currently on.
   * @param {number} limit The number of letterheads to display per page.
   * @returns {Observable<Letterhead[]>}
   */
  public listLetterheads(
    page?: number,
    limit?: number,
  ): Observable<Letterhead[]> {
    return this.list(this.endpoint, page, undefined, limit);
  }

  /**
   * Finds details of the letterhead from the given ID.
   *
   * @param {string} letterheadId The ID of the letterhead we require details for.
   * @returns {Observable<Letterhead>}
   */
  public findLetterhead(letterheadId: string): Observable<Letterhead> {
    return this.findById(this.endpoint, letterheadId);
  }

  /**
   * Creates a new letterhead from the given Letterhead object.
   *
   * @param {Letterhead} newLetterhead The details of the letterhead to be created.
   * @returns {Observable<Letterhead | boolean>}
   */
  public createLetterhead(
    newLetterhead: Letterhead,
  ): Observable<Letterhead | boolean> {
    return this.create(this.endpoint, newLetterhead);
  }

  /**
   * Updates a letterhead with the given letterhead object for the given letterhead ID.
   *
   * @param {string} letterheadId The ID of the letterhead needing updating.
   * @param {Letterhead} newDetails The new letterhead object to update with.
   * @returns {Observable<Letterhead | boolean>}
   */
  public updateLetterhead(
    letterheadId: string,
    newDetails: Letterhead,
  ): Observable<Letterhead | boolean> {
    return this.update(this.endpoint, letterheadId, newDetails);
  }

  /**
   * Deletes a letterhead based on the given letterhead ID.
   *
   * @param {string} letterheadId The ID of the letterhead needing deleting.
   * @returns {Observable<boolean>}
   */
  public deleteLetterhead(letterheadId: string): Observable<boolean> {
    return this.delete(this.endpoint, letterheadId);
  }
}
