import { Component, OnInit } from '@angular/core';
import { IncomeTypeService } from '../income-type.service';
import { IncomeType } from '../income-type';
import { Router } from '@angular/router';
import { NotificationService } from '../../../shared/notification.service';

@Component({
  selector: 'app-income-type-create',
  templateUrl: 'create.component.html',
})
export class IncomeTypeCreateComponent implements OnInit {
  /**
   * Details of the incomeType being created.
   */
  public incomeType: IncomeType;

  /**
   * Are we currently performing a loading action?
   *
   * @type {boolean}
   */
  public isLoading: boolean = false;

  /**
   * Load up all our required services and subscribe to every observable we need.
   *
   * @param {IncomeTypeService} incomeTypeService
   * @param {NotificationService} notificationService
   * @param {Router} router
   */
  constructor(
    private incomeTypeService: IncomeTypeService,
    private notificationService: NotificationService,
    private router: Router,
  ) {
    this.incomeTypeService.loading.subscribe(
      result => (this.isLoading = result),
    );
  }

  /**
   * Initialise the component, creating a new blank incomeType if required.
   */
  public ngOnInit(): void {
    if (!this.incomeType) {
      this.incomeType = new IncomeType();
    }
  }

  /**
   * Perform the create action which adds the new incomeType into the backend.
   */
  public create() {
    const createIncomeType = this.incomeTypeService
      .createIncomeType(this.incomeType)
      .subscribe(
        (result: IncomeType) => {
          this.notificationService.success(
            `IncomeType Created`,
            `New incomeType ${result.name} has been created with ID of ${
              result.id
            }.`,
            5,
          );
          this.incomeType = new IncomeType();
          createIncomeType.unsubscribe();
          this.router.navigate(['/income-types']);
        },
        err => {
          this.notificationService.error(
            `Could not create IncomeType`,
            err.error.message,
            10,
          );
        },
      );
  }
}
