<ng-container *ngIf="direction === 'horizontal'">


  <div class="row">
    <div class="col-xs-6"><strong>Date of Birth</strong></div>
    <div class="col-xs-3"><strong>Age</strong></div>
    <div class="col-xs-3">&nbsp;</div>
  </div>

  <div class="row" *ngFor="let dependant of case.dependants" style="line-height: 2em; margin-bottom: 5px;">
    <div class="col-xs-6">{{ dependant.dateOfBirth | date:'dd/MM/yyyy' }}</div>
    <div class="col-xs-3">{{ calculateAge(dependant.dateOfBirth) }}</div>
    <div class="col-xs-3 text-right">
      <button class="btn btn-xs btn-danger" type="button" (click)="deleteDependant(dependant)">
        <i class="fa fa-trash"></i>
      </button>
    </div>
  </div>

  <div class="row" style="margin-top: 20px; border-top: 4px solid #F1F2F7; padding-top: 26px;">
    <div class="col-xs-9">
      <app-shared-date [(ngModel)]="newDependant.dateOfBirth" [allowFuture]="false" [allowPast]="true"
                       [hasTime]="false"
                       required></app-shared-date>
    </div>
    <div class="col-xs-3">
      <button class="btn btn-block btn-success" type="button" (click)="addDependant()">Add</button>
    </div>
  </div>

</ng-container>
