<div class="form-group clearfix">
  <label for="registration" class="col-sm-4 control-label">Registration</label>
  <div class="col-sm-8">
    <input
      type="text"
      class="form-control"
      name="registration"
      id="registration"
      placeholder="Registration"
      [(ngModel)]="model.registration"
      [required]="isRequired('registration')">
  </div>
</div>

<div class="form-group clearfix">
  <label for="registration" class="col-sm-4 control-label">Make</label>
  <div class="col-sm-8">
    <input
      type="text"
      class="form-control"
      name="make"
      id="make"
      placeholder="Make"
      [(ngModel)]="model.make"
      [required]="isRequired('make')">
  </div>
</div>

<div class="form-group clearfix">
  <label for="registration" class="col-sm-4 control-label">Model</label>
  <div class="col-sm-8">
    <input
      type="text"
      class="form-control"
      name="model"
      id="model"
      placeholder="Model"
      [(ngModel)]="model.model"
      [required]="isRequired('model')">
  </div>
</div>

<div class="form-group clearfix">
  <label for="registration" class="col-sm-4 control-label">Year</label>
  <div class="col-sm-8">
    <input
      type="text"
      class="form-control"
      name="year"
      id="year"
      placeholder="Year"
      [(ngModel)]="model.year"
      [required]="isRequired('year')">
  </div>
</div>

