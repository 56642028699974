import { Component, OnInit } from '@angular/core';
import { MaritalStatusService } from '../marital-status.service';
import { MaritalStatus } from '../marital-status';
import { Router } from '@angular/router';
import { NotificationService } from '../../shared/notification.service';

@Component({
  selector: 'app-marital-status-create',
  templateUrl: 'create.component.html',
})
export class MaritalStatusCreateComponent implements OnInit {
  /**
   * Details of the maritalStatus being created.
   */
  public maritalStatus: MaritalStatus;

  /**
   * Are we currently performing a loading action?
   *
   * @type {boolean}
   */
  public isLoading: boolean = false;

  /**
   * Load up all our required services and subscribe to every observable we need.
   *
   * @param {MaritalStatusService} maritalStatusService
   * @param {NotificationService} notificationService
   * @param {Router} router
   */
  constructor(
    private maritalStatusService: MaritalStatusService,
    private notificationService: NotificationService,
    private router: Router,
  ) {
    this.maritalStatusService.loading.subscribe(
      result => (this.isLoading = result),
    );
  }

  /**
   * Initialise the component, creating a new blank maritalStatus if required.
   */
  public ngOnInit(): void {
    if (!this.maritalStatus) {
      this.maritalStatus = new MaritalStatus();
    }
  }

  /**
   * Perform the create action which adds the new maritalStatus into the backend.
   */
  public create() {
    const createMaritalStatus = this.maritalStatusService
      .createMaritalStatus(this.maritalStatus)
      .subscribe(
        (result: MaritalStatus) => {
          this.notificationService.success(
            `MaritalStatus Created`,
            `New maritalStatus ${result.name} has been created with ID of ${
              result.id
            }.`,
            5,
          );
          this.maritalStatus = new MaritalStatus();
          createMaritalStatus.unsubscribe();
          this.router.navigate(['/marital-statuses']);
        },
        err => {
          this.notificationService.error(
            `Could not create MaritalStatus`,
            err.error.message,
            10,
          );
        },
      );
  }
}
