import { Injectable } from '@angular/core';
import { Template } from './template';
import { Observable } from 'rxjs';

import { HttpRequestService } from '../shared/http/http-request.service';

@Injectable()
export class TemplateService extends HttpRequestService {
  /**
   * The endpoint that we call to get the template information.
   *
   * @type {string}
   */
  private readonly endpoint: string = 'templates';

  /**
   * Brings back a list of all accessible templates.
   *
   * @param {number} page The page number we are currently on.
   * @param {number} limit The number of templates to display per page.
   * @returns {Observable<Template[]>}
   */
  public listTemplates(page?: number, limit?: number): Observable<Template[]> {
    return this.list(this.endpoint, page, undefined, limit);
  }

  /**
   * Finds details of the template from the given ID.
   *
   * @param {string} templateId The ID of the template we require details for.
   * @returns {Observable<Template>}
   */
  public findTemplate(templateId: string): Observable<Template> {
    return this.findById(this.endpoint, templateId);
  }

  /**
   * Creates a new template from the given Template object.
   *
   * @param {Template} newTemplate The details of the template to be created.
   * @returns {Observable<Template | boolean>}
   */
  public createTemplate(newTemplate: Template): Observable<Template | boolean> {
    return this.create(this.endpoint, newTemplate);
  }

  /**
   * Updates a template with the given template object for the given template ID.
   *
   * @param {string} templateId The ID of the template needing updating.
   * @param {Template} newDetails The new template object to update with.
   * @returns {Observable<Template | boolean>}
   */
  public updateTemplate(
    templateId: string,
    newDetails: Template,
  ): Observable<Template | boolean> {
    return this.update(this.endpoint, templateId, newDetails);
  }

  /**
   * Deletes a template based on the given template ID.
   *
   * @param {string} templateId The ID of the template needing deleting.
   * @returns {Observable<boolean>}
   */
  public deleteTemplate(templateId: string): Observable<boolean> {
    return this.delete(this.endpoint, templateId);
  }
}
