import { NgModule } from '@angular/core';
import {BankingAccountTypeRoutingModule} from './account-types/banking-account-type-routing.module';
import {BankingAccountRoutingModule} from './accounts/banking-account-routing.module';
import {BankingAccountItemRoutingModule} from './account-items/banking-account-item-routing.module';
import {BankingAccountImportRoutingModule} from './accounts/imports/banking-account-import-routing.module';

@NgModule({
  imports: [
    BankingAccountImportRoutingModule,
    BankingAccountRoutingModule,
    BankingAccountItemRoutingModule,
    BankingAccountTypeRoutingModule,
  ],
})
export class BankingModule {}
