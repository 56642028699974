import { Injectable } from '@angular/core';
import { CaseDocument } from './case-document';
import { Observable } from 'rxjs';

import { HttpRequestService } from '../../shared/http/http-request.service';
import { HttpOptions } from '../../shared/http/http-options';
import { PaperlessOfficeIn } from '../../reports/paperless-office-in/paperless-office-in';

@Injectable()
export class CaseDocumentService extends HttpRequestService {
  /**
   * The endpoint that we call to get the case information.
   *
   * @type {string}
   */
  private readonly endpoint: string = 'cases/:id/documents';

  /**
   * Brings back a list of all accessible cases.
   *
   * @param {number} page The page number we are currently on.
   * @param {number} limit The number of cases to display per page.
   * @returns {Observable<CaseDocument[]>}
   */
  public listCaseDocuments(
    page?: number,
    limit?: number,
    options?: HttpOptions,
  ): Observable<CaseDocument[]> {
    return this.list(this.endpoint, page, undefined, limit, options);
  }

  /**
   * Finds details of the case from the given ID.
   *
   * @param {string} caseId The ID of the case we require details for.
   * @returns {Observable<CaseDocument>}
   */
  public findCaseDocument(
    caseId: string,
    options?: HttpOptions,
  ): Observable<CaseDocument> {
    return this.findById(this.endpoint, caseId, options);
  }

  /**
   * Creates a new case from the given Case object.
   *
   * @param {CaseDocument} newCase The details of the case to be created.
   * @returns {Observable<CaseDocument | boolean>}
   */
  public createCaseDocument(
    newCase: CaseDocument,
    options?: HttpOptions,
  ): Observable<CaseDocument | boolean> {
    return this.create(this.endpoint, newCase, options);
  }

  public paperlessCreateCaseDocument(
    newCase: PaperlessOfficeIn,
    options?: HttpOptions,
  ): Observable<CaseDocument | boolean> {
    return this.create(`${this.endpoint}/paperless`, newCase, options);
  }

  /**
   * Updates a case with the given case object for the given case ID.
   *
   * @param {string} caseId The ID of the case needing updating.
   * @param {CaseDocument} newDetails The new case object to update with.
   * @returns {Observable<Case | boolean>}
   */
  public updateCaseDocument(
    caseId: string,
    newDetails: CaseDocument,
    options?: HttpOptions,
  ): Observable<CaseDocument | boolean> {
    return this.update(this.endpoint, caseId, newDetails, options);
  }

  /**
   * Deletes a case based on the given case ID.
   *
   * @param {string} caseId The ID of the case needing deleting.
   * @returns {Observable<boolean>}
   */
  public deleteCaseDocument(
    caseId: string,
    options?: HttpOptions,
  ): Observable<boolean> {
    return this.delete(this.endpoint, caseId, options);
  }
}
