<div class="row">
  <div class="col-sm-12">
    <section class="panel">
      <app-page-title title="List Financial Ledger" [loading]="isLoading">
        {{ totalResults }} Results Found
      </app-page-title>

      <div class="panel-body">
        <table class="table table-striped">
          <thead>
          <tr>
            <th></th>
            <th>Account</th>
            <th>Case</th>
            <th>Value</th>
          </tr>
          </thead>

          <tbody>
          <tr *ngFor="let singleFinancialLedger of financialLedger">
            <td></td>
            <td>{{ singleFinancialLedger?.financialAccount?.code }} : {{ singleFinancialLedger?.financialAccount?.name }}</td>
            <td>
              <div *ngFor="let singlePerson of singleFinancialLedger?.case?.people">{{ singlePerson.firstName }} {{ singlePerson.lastName }}</div>
            </td>
            <td>{{ singleFinancialLedger?.value / 100 | toCurrency }}</td>
            <td class="text-right">
              <div class="btn-group">
                <a [routerLink]="['/financial-ledger', singleFinancialLedger?.id, 'edit']" type="button"
                   class="btn btn-sm btn-warning">Edit</a>
                <button type="button" class="btn btn-sm btn-warning dropdown-toggle" data-toggle="dropdown"
                        aria-haspopup="true" aria-expanded="false">
                  <span class="caret"></span>
                  <span class="sr-only">Toggle Dropdown</span>
                </button>
                <ul class="dropdown-menu">
                  <li><a href="javascript:;" (click)="deleteModal.confirmDialog(singleFinancialLedger.id)">Delete</a>
                  </li>
                </ul>
              </div>
            </td>
          </tr>
          </tbody>

          <tfoot>
          <tr>
            <td colspan="99" class="text-center">
              <app-pagination [totalPages]="totalPages" [currentPage]="currentPage"
                              (pageChanged)="onPageChanged($event)"></app-pagination>
            </td>
          </tr>
          </tfoot>
        </table>
      </div>
    </section>
  </div>
</div>

<app-delete-modal type="financialLedger" (confirm)="delete($event)" [itemDeleted]="financialLedgerDeleted"
                  #deleteModal></app-delete-modal>
