import * as moment from 'moment';

import { Component, Input, OnInit } from '@angular/core';

import { AuthService } from '../../auth/auth.service';
import { CaseCalendar } from '../../cases/calendars/case-calendar';
import { CaseCalendarService } from '../../cases/calendars/case-calendar.service';
import { Department } from '../../settings/departments/department';
import { Router } from '@angular/router';
import { User } from '../../users/user';

type OptionType = 'user' | 'department';

type FilterType = {
  list?: 'my' | 'department' | 'all';
  user?: User | { id: string };
  department?: Department | { id: string };
  viewAll?: boolean;
};

@Component({
  selector: 'app-user-tasks',
  templateUrl: 'user-task.component.html',
  styleUrls: ['user-task.component.scss'],
})
export class UserTaskComponent implements OnInit {
  @Input() public showUser: boolean = false;
  @Input() public isPopover: boolean = false;
  private defaultFilter: FilterType = {
    list: 'all',
    viewAll: false,
  };
  public filter: FilterType = this.defaultFilter;
  private pageNumber = 1;
  private loading = false;
  private tasksHolder: CaseCalendar[] = [];
  private totalPages = 100;

  constructor(
    private readonly caseCalendarService: CaseCalendarService,
    public readonly authService: AuthService,
    private readonly router: Router,
  ) {
    this.caseCalendarService.loading.subscribe((loading) => {
      this.loading = loading;
    });
    this.caseCalendarService.totalPages.subscribe((totalPages) => {
      this.totalPages = totalPages;
    });
  }

  public get isLoading(): boolean {
    return this.loading;
  }

  public get tasks(): CaseCalendar[] {
    return this.tasksHolder;
  }

  public get page(): number {
    return this.pageNumber;
  }

  public set page(page: number) {
    this.pageNumber = page;
    this.loadTasks(page);
  }

  public get pages(): number {
    return this.totalPages;
  }

  public toggleAllTasks() {
    this.filter.viewAll = !this.filter.viewAll;
    this.filter.department = undefined;
    if (this.filter.viewAll) {
      this.filter.user = { id: 'ALL' };
    } else {
      this.filter.user = undefined;
    }

    this.loadTasks(1);
  }

  public selectOption(optionType: OptionType) {
    this.filter = {
      [optionType]: this.filter[optionType],
    };
    if (optionType === 'department') {
      this.filter.user = { id: 'department' };
    }
    this.loadTasks(1);
  }

  public handleDisplayFilterChanged($event) {
    this.filter = {
      ['list']: $event.target.value,
    };
    this.loadTasks(1);
  }

  public resetFilters() {
    this.filter = this.defaultFilter;
    this.loadTasks(1);
  }

  public completeTask(task: CaseCalendar) {
    this.caseCalendarService
      .completeTaskForUser(this.authService.userId, task.id)
      .subscribe(() => {
        this.loadTasks(this.page);
      });
  }

  public taskOverdue(dueDate): boolean {
    const dueMoment = moment(dueDate);

    return dueMoment.isBefore(moment());
  }

  public ngOnInit(): void {
    this.loadTasks(this.pageNumber);
  }

  public loadCase(caseId: string): void {
    this.router.navigate([`/cases/${caseId}`]);
  }

  /**
   * Determines what the requestIds should be set to depending on the current state of filters.
   * @returns userId and departmentId
   */
  private getRequestIds(): { userId?: string; departmentId?: string } {
    let userId;
    let departmentId;

    if (this.filter.viewAll) {
      return { userId: 'ALL', departmentId: this.authService.department?.id };
    }

    if (this.filter.list) {
      if (this.filter.list === 'all') {
        userId = this.authService.userId;
        departmentId = this.authService.department?.id;
      }
      if (this.filter.list === 'my') {
        userId = this.authService.userId;
      }
      if (this.filter.list === 'department') {
        userId = 'department';
        departmentId = this.authService.department?.id;
      }
      return { userId, departmentId };
    }

    userId = this.filter.user.id;
    departmentId = this.filter.department?.id;
    return { userId, departmentId };
  }

  private loadTasks(page: number = 1): void {
    const { userId, departmentId } = this.getRequestIds();
    this.caseCalendarService
      .listCaseCalendarsForUser(userId, page, 10, departmentId)
      .subscribe((tasks: CaseCalendar[]) => {
        this.tasksHolder = tasks;
      });
  }
}
