import { Observable } from 'rxjs';

import { Injectable } from '@angular/core';

import { HttpRequestService } from '../shared/http/http-request.service';
import { ComplaintStatus } from './complaint-status';

@Injectable()
export class ComplaintStatusService extends HttpRequestService {
  /**
   * The endpoint that we call to get the complaintStatus information.
   *
   * @type {string}
   */
  private readonly endpoint: string = 'complaint-statuses';

  /**
   * Brings back a list of all accessible complaint-statuses.
   *
   * @param {number} page The page number we are currently on.
   * @param {number} limit The number of complaint-statuses to display per page.
   * @returns {Observable<ComplaintStatus[]>}
   */
  public listComplaintStatuses(
    page?: number,
    limit?: number,
  ): Observable<ComplaintStatus[]> {
    return this.list(this.endpoint, page, undefined, limit);
  }

  /**
   * Finds details of the complaintStatus from the given ID.
   *
   * @param {string} complaintStatusId The ID of the complaintStatus we require details for.
   * @returns {Observable<ComplaintStatus>}
   */
  public findComplaintStatus(
    complaintStatusId: string,
  ): Observable<ComplaintStatus> {
    return this.findById(this.endpoint, complaintStatusId);
  }

  /**
   * Creates a new complaintStatus from the given ComplaintStatus object.
   *
   * @param {ComplaintStatus} newComplaintStatus The details of the complaintStatus to be created.
   * @returns {Observable<ComplaintStatus | boolean>}
   */
  public createComplaintStatus(
    newComplaintStatus: ComplaintStatus,
  ): Observable<ComplaintStatus | boolean> {
    return this.create(this.endpoint, newComplaintStatus);
  }

  /**
   * Updates a complaintStatus with the given complaintStatus object for the given complaintStatus ID.
   *
   * @param {string} complaintStatusId The ID of the complaintStatus needing updating.
   * @param {ComplaintStatus} newDetails The new complaintStatus object to update with.
   * @returns {Observable<ComplaintStatus | boolean>}
   */
  public updateComplaintStatus(
    complaintStatusId: string,
    newDetails: ComplaintStatus,
  ): Observable<ComplaintStatus | boolean> {
    return this.update(this.endpoint, complaintStatusId, newDetails);
  }

  /**
   * Deletes a complaintStatus based on the given complaintStatus ID.
   *
   * @param {string} complaintStatusId The ID of the complaintStatus needing deleting.
   * @returns {Observable<boolean>}
   */
  public deleteComplaintStatus(complaintStatusId: string): Observable<boolean> {
    return this.delete(this.endpoint, complaintStatusId);
  }
}
