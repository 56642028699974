import {NgModule} from '@angular/core';
import {AccountGroupRoutingModule} from './account-groups/account-group-routing.module';
import {AccountTypeRoutingModule} from './account-types/account-type-routing.module';
import {SupplierRoutingModule} from './suppliers/supplier-routing.module';
import {PaymentTypeRoutingModule} from './payment-types/payment-type-routing.module';
import {ModificationRoutingModule} from './modifications/modification-routing.module';
import {IncomeTypeRoutingModule} from './income-types/income-type-routing.module';
import {FolderRoutingModule} from './folders/folder-routing.module';
import {BreachTypeRoutingModule} from './breach-types/breach-type-routing.module';
import {DepartmentRoutingModule} from "./departments/department-routing.module";

@NgModule({
  imports: [
    AccountGroupRoutingModule,
    AccountTypeRoutingModule,
    SupplierRoutingModule,
    PaymentTypeRoutingModule,
    ModificationRoutingModule,
    IncomeTypeRoutingModule,
    FolderRoutingModule,
    BreachTypeRoutingModule,
      DepartmentRoutingModule,
  ],
})
export class SettingsModule {
}
