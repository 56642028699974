<form (submit)="createSchedule()" class="form-horizontal" ngNativeValidate>
  <div class="modal-header">
    <h4 class="modal-title pull-left">Create Payment Schedule</h4>
    <button (click)="modalRef.hide()" aria-label="Close" class="close pull-right" type="button">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">

    <div class="form-group">
      <label class="col-sm-4 control-label" for="startDate">Start Date</label>
      <div class="col-sm-8">
        <input [(ngModel)]="startDate" class="form-control" id="startDate" name="startDate"
               placeholder="Start Date"
               required type="date">
      </div>
    </div>

    <div class="form-group">
      <label class="col-sm-4 control-label">Payment Frequency</label>
      <div class="col-sm-8 control-label text-left" style="text-align: left !important;">
        {{ paymentFrequencyFormatted }}
      </div>
    </div>

    <div class="form-group">
      <label class="col-sm-4 control-label" for="payment">Payment</label>
      <div class="col-sm-8">
        <app-shared-currency [(ngModel)]="payment" [required]="true" id="payment"
                             name="payment"></app-shared-currency>
      </div>
    </div>

    <div class="form-group">
      <label class="col-sm-4 control-label" for="method">Expected Method</label>
      <div class="col-sm-8">
        <select [(ngModel)]="method" [required]="true" class="form-control" id="method" name="frequency">
          <option value="recurring-card">Recurring Card</option>
          <option value="standing-order">Standing Order</option>
        </select>
      </div>
    </div>


  </div>
  <div class="modal-footer text-right">
    <button [disabled]="isSaving" class="btn btn-success" type="submit">Save</button>
    <button (click)="modalRef.hide()" class="btn btn-default" type="button">Cancel</button>
  </div>
</form>

