import { RouterModule, Routes } from '@angular/router';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule } from '@angular/common/http';
import { AuthGuard } from '../../auth/auth.guard';
import { SharedModule } from '../../shared/shared.module';
import { FinancialAccountsService } from './accounts.service';
import { FinancialAccountsListComponent } from './list/list.component';
import { FinancialAccountsCreateComponent } from './create/create.component';
import { FinancialAccountsEditComponent } from './edit/edit.component';
import { FinancialAccountsFormComponent } from './form/form.component';

const routes: Routes = [
  {
    path: 'financial-accounts',
    component: FinancialAccountsListComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'financial-accounts/create',
    component: FinancialAccountsCreateComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'financial-accounts/:id/edit',
    component: FinancialAccountsEditComponent,
    canActivate: [AuthGuard],
  },
];

@NgModule({
  declarations: [
    FinancialAccountsListComponent,
    FinancialAccountsCreateComponent,
    FinancialAccountsFormComponent,
    FinancialAccountsEditComponent,
  ],
  imports: [
    BrowserModule,
    RouterModule,
    FormsModule,
    HttpClientModule,
    SharedModule,
    RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' }),
  ],
  exports: [RouterModule],
  providers: [AuthGuard, FinancialAccountsService],
})
export class FinancialAccountsRoutingModule {}
