import { Component, Input, OnInit } from '@angular/core';
import { AccountTypeService } from '../account-type.service';
import { AccountType } from '../account-type';
import { SelectComparisons } from '../../../shared/pipes/select.comparisons';
import { NotificationService } from '../../../shared/notification.service';

@Component({
  selector: 'app-account-type-select',
  templateUrl: 'select.component.html',
})
export class AccountTypeSelectComponent extends SelectComparisons
  implements OnInit {
  /**
   * The model that we will be adding the accountType to.
   */
  @Input() model: any;

  /**
   * Do we require this field in the main form it is
   * included in?
   */
  @Input() required: boolean;

  /**
   * A list of all the account-types currently loaded.
   *
   * @type {AccountType[]}
   */
  public accountTypes: AccountType[] = [];

  /**
   * Are we currently performing a loading action?
   *
   * @type {boolean}
   */
  public isLoading: boolean = true;

  /**
   * Load up all our required services and subscribe to every observable we need.
   *
   * @param {AccountTypeService} accountTypeService
   * @param {NotificationService} notificationService
   */
  constructor(
    private accountTypeService: AccountTypeService,
    private notificationService: NotificationService,
  ) {
    super();
    this.accountTypeService.loading.subscribe(isLoading => {
      this.isLoading = isLoading;
    });
  }

  /**
   * When the component is initialised we set it to load
   * page one of the accountType list.
   */
  public ngOnInit(): void {
    this.loadAccountTypes(1, 10000);
  }

  /**
   * Loads a list of account-types using the given per page limit.
   *
   * @param {number} page The current page we require.
   * @param {number} limit The number of results per page.
   */
  public loadAccountTypes(page?: number, limit?: number): void {
    const listAccountTypes = this.accountTypeService
      .listAccountTypes(page, limit)
      .subscribe((accountTypes: AccountType[]) => {
        this.accountTypes = accountTypes;
        listAccountTypes.unsubscribe();
      });
  }
}
