import { CaseCalendar } from './case-calendar';
import { HttpOptions } from '../../shared/http/http-options';
import { HttpRequestService } from '../../shared/http/http-request.service';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable()
export class CaseCalendarService extends HttpRequestService {
  /**
   * The endpoint that we call to get the case information.
   *
   * @type {string}
   */
  private readonly endpoint: string = 'cases/:id/calendars';

  /**
   * Brings back a list of all accessible cases.
   *
   * @param {number} page The page number we are currently on.
   * @param {number} limit The number of cases to display per page.
   * @returns {Observable<CaseCalendar[]>}
   */
  public listCaseCalendars(
    page?: number,
    limit?: number,
    options?: HttpOptions,
    filters?: any,
  ): Observable<CaseCalendar[]> {
    return this.list(this.endpoint, page, filters, limit, options);
  }

  public completeTaskForUser(userId, taskId) {
    return this.create('users/:userId/tasks/:taskId', undefined, {
      parameters: {
        userId: userId,
        taskId: taskId,
      },
    });
  }

  public listCaseCalendarsForUser(
    userId: string,
    page?: number,
    limit?: number,
    departmentId?: string,
    options?: HttpOptions,
  ): Observable<CaseCalendar[]> {
    return this.list(
      'users/:userId/tasks',
      page,
      departmentId ? { departmentId } : undefined,
      limit,
      {
        parameters: {
          userId: userId,
        },
      },
    );
  }

  /**
   * Finds details of the case from the given ID.
   *
   * @param {string} caseId The ID of the case we require details for.
   * @returns {Observable<CaseCalendar>}
   */
  public findCaseCalendar(
    caseId: string,
    options?: HttpOptions,
  ): Observable<CaseCalendar> {
    return this.findById(this.endpoint, caseId, options);
  }

  /**
   * Creates a new case from the given Case object.
   *
   * @param {CaseCalendar} newCase The details of the case to be created.
   * @returns {Observable<CaseCalendar | boolean>}
   */
  public createCaseCalendar(
    newCase: CaseCalendar,
    options?: HttpOptions,
  ): Observable<CaseCalendar | boolean> {
    return this.create(this.endpoint, newCase, options);
  }

  /**
   * Updates a case with the given case object for the given case ID.
   *
   * @param {string} caseId The ID of the case needing updating.
   * @param {CaseCalendar} newDetails The new case object to update with.
   * @returns {Observable<Case | boolean>}
   */
  public updateCaseCalendar(
    caseId: string,
    newDetails: CaseCalendar,
    options?: HttpOptions,
  ): Observable<CaseCalendar | boolean> {
    return this.update(this.endpoint, caseId, newDetails, options);
  }

  /**
   * Deletes a case based on the given case ID.
   *
   * @param {string} caseId The ID of the case needing deleting.
   * @returns {Observable<boolean>}
   */
  public deleteCaseCalendar(
    caseId: string,
    options?: HttpOptions,
  ): Observable<boolean> {
    return this.delete(this.endpoint, caseId, options);
  }
}
