<div class="row" *ngIf="allocationsInProgress">
  <div class="col-md-12">
    <div class="alert alert-danger text-bold text-center">
      Allocations are currently Read Only whilst automatic allocations are
      performed.
    </div>
  </div>
</div>

<div class="row">
  <div class="col-sm-12">
    <section class="panel">
      <app-page-title [loading]="isLoading" title="Allocate Client Funds">
        {{ totalResults }} Results Found

        <select
          [(ngModel)]="allocationType"
          (ngModelChange)="allocationtypeUpdated($event)"
          style="margin-left: 30px"
        >
          <option value="all">All Wizard Items</option>
          <option value="auto">Automatic Wizard Items</option>
          <option value="manual">Manual Wizard Items</option>
        </select>

        <input
          [(ngModel)]="filter"
          placeholder="Search filter..."
          style="margin-left: 30px"
          type="text"
        />

        <button (click)="onPageChanged(1)">Filter</button>
      </app-page-title>

      <div class="panel-body">
        <table class="table table-striped">
          <thead>
            <tr>
              <th>Client Name</th>
              <th>Reference</th>
              <th>Fee Wizard</th>
              <th>In</th>
              <th>Out</th>
              <th>Pending</th>
              <th>Remaining</th>
              <th *ngIf="displayLedger">Ledger Balance</th>
              <th class="text-right">
                <a
                  [routerLink]="['/financials/distributions/summary']"
                  class="btn btn-md btn-primary"
                  tooltip="View Summary"
                  container="body"
                  ><i class="fa fa-file"></i
                ></a>

                <div
                  class="btn-group"
                  style="margin-left: 10px"
                  *ngIf="
                    authService.hasPermission(
                      'financials.start-distributions'
                    ) &&
                    !allocationsInProgress &&
                    !allocationAboutToRun
                  "
                >
                  <a
                    href="javascript:;"
                    class="btn btn-md btn-danger"
                    tooltip="Run Automatic Allocations"
                    (click)="startAllocating()"
                    ><i class="fa fa-play"></i
                  ></a>
                  <button
                    type="button"
                    class="btn btn-danger dropdown-toggle"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    <span class="caret"></span>
                    <span class="sr-only">Toggle Dropdown</span>
                  </button>
                  <ul class="dropdown-menu dropdown-menu-right">
                    <li>
                      <a href="javascript:;" (click)="startAllocating(false)"
                        >Allocations Only</a
                      >
                    </li>
                    <li>
                      <a href="javascript:;" (click)="startAllocating(true)"
                        >Allocations AND Distributions</a
                      >
                    </li>
                  </ul>
                </div>
              </th>
            </tr>
          </thead>

          <tbody *ngIf="distributionItems && distributionItems.length > 0">
            <tr *ngFor="let item of distributionItems">
              <td>{{ item?.client_name }}</td>
              <td>{{ item?.unique_reference }}</td>
              <td>
                <div [ngSwitch]="item.allocation_type">
                  <div *ngSwitchCase="'furtherRealisationsNoCap'">
                    Further Realisations No Cap
                  </div>
                  <div *ngSwitchCase="'furtherRealisationsWithCap'">
                    Further Realisations With Cap
                  </div>
                  <div *ngSwitchCase="'totalRealisationsNoCap'">
                    Total Realisations No Cap
                  </div>
                  <div *ngSwitchCase="'totalRealisationsWithCap'">
                    Total Realisations With Cap
                  </div>
                  <div *ngSwitchDefault>
                    {{ item?.allocation_type | capitalizeFirst }}
                  </div>
                </div>
              </td>
              <td>
                {{
                  totalCashIn(item.cash_in, item.extraordinary_in) / 100
                    | toAccountancy
                }}
              </td>
              <td>{{ item?.cash_out / 100 | toAccountancy }}</td>
              <td>{{ item?.cash_out_pending / 100 | toAccountancy }}</td>
              <td>
                {{ item?.cash_remaining / 100 | toAccountancy }}
              </td>
              <td *ngIf="displayLedger">
                {{ item?.ledger_balance / 100 | toAccountancy }}
              </td>
              <td class="text-right">
                <div class="btn-group">
                  <a
                    [routerLink]="['/financials/distributions', item.id]"
                    class="btn btn-sm btn-warning"
                    type="button"
                    >Distribute</a
                  >
                  <button
                    aria-expanded="false"
                    aria-haspopup="true"
                    class="btn btn-sm btn-warning dropdown-toggle"
                    data-toggle="dropdown"
                    type="button"
                  >
                    <span class="caret"></span>
                    <span class="sr-only">Toggle Dropdown</span>
                  </button>
                  <ul class="dropdown-menu">
                    <li>
                      <a [routerLink]="['/cases', item.id]" target="_blank"
                        >View Client</a
                      >
                    </li>
                  </ul>
                </div>
              </td>
            </tr>
          </tbody>

          <tfoot>
            <tr>
              <td class="text-center" colspan="99">
                <app-pagination
                  (pageChanged)="onPageChanged($event)"
                  [currentPage]="currentPage"
                  [totalPages]="totalPages"
                ></app-pagination>
              </td>
            </tr>
          </tfoot>
        </table>
      </div>
    </section>
  </div>
</div>
