import { Component, EventEmitter, OnInit } from '@angular/core';
import { CountryService } from '../country.service';
import { Country } from '../country';
import { NotificationService } from '../../shared/notification.service';

@Component({
  selector: 'app-country-list',
  templateUrl: 'list.component.html',
  styleUrls: [],
})
export class CountryListComponent implements OnInit {
  public country: Country;

  /**
   * A list of all the countries currently loaded.
   *
   * @type {Country[]}
   */
  public countries: Country[] = [];

  /**
   * Are we currently performing a loading action?
   *
   * @type {boolean}
   */
  public isLoading: boolean = true;

  /**
   * The total number of pages in this list of countries.
   *
   * @type {number}
   */
  public totalPages: number = 1;

  /**
   * The current page of countries that we are viewing.
   *
   * @type {number}
   */
  public currentPage: number = 1;

  /**
   * The total number of countries that can be viewed in the system.
   *
   * @type {number}
   */
  public totalResults: number = 0;

  /**
   * An Emitter to alert other components when a country has been deleted.
   *
   * @type {EventEmitter<boolean>}
   */
  public countryDeleted: EventEmitter<boolean> = new EventEmitter<boolean>();

  /**
   * Load up all our required services and subscribe to every observable we need.
   *
   * @param {CountryService} countryService
   * @param {NotificationService} notificationService
   */
  constructor(
    private countryService: CountryService,
    private notificationService: NotificationService,
  ) {
    this.countryService.loading.subscribe(isLoading => {
      this.isLoading = isLoading;
    });
    this.countryService.totalPages.subscribe(totalPages => {
      this.totalPages = totalPages;
    });
    this.countryService.currentPage.subscribe(currentPage => {
      this.currentPage = currentPage;
    });
    this.countryService.totalResults.subscribe(totalResults => {
      this.totalResults = totalResults;
    });
  }

  /**
   * When the component is initialised we set it to load
   * page one of the country list.
   */
  public ngOnInit(): void {
    this.loadCountries(1);
  }

  /**
   * When we receive a page change from the pagination
   * component we load the appropriate page for the
   * country list.
   *
   * @param {number} pageNumber
   */
  public onPageChanged(pageNumber: number): void {
    this.loadCountries(pageNumber);
  }

  /**
   * Loads a list of countries using the given per page limit.
   *
   * @param {number} page The current page we require.
   * @param {number} limit The number of results per page.
   */
  public loadCountries(page?: number, limit?: number): void {
    const listCountries = this.countryService
      .listCountries(page, limit)
      .subscribe((countries: Country[]) => {
        this.countries = countries;
        listCountries.unsubscribe();
      });
  }

  /**
   * When receiving confirmation of an item being deleted
   * we call the back end with the appropriate country ID
   * to delete the country.
   *
   * @param {string} id
   */
  public delete(id: string) {
    this.countryService.deleteCountry(id).subscribe(
      deleted => {
        this.notificationService.success(
          `Country Deleted`,
          `Country with ID ${id} was deleted.`,
          5,
        );
        this.countryDeleted.next(true);
        this.onPageChanged(this.currentPage);
      },
      err => {
        this.notificationService.error(
          `Country Not Deleted`,
          err.error.message,
          10,
        );
        this.countryDeleted.next(false);
        this.onPageChanged(this.currentPage);
      },
    );
  }
}
