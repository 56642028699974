<div class="row">

  <div class="col-md-7">
    <app-user-tasks [showUser]="true"></app-user-tasks>
  </div>

  <div class="col-md-5">
    <app-team-chat></app-team-chat>
  </div>

</div>
