import { Alert, Button } from 'react-bootstrap';
import { LinkProps, LinkWrapper } from 'react-inside-angular';
import { Link as rrdLink, useHistory as rrdUseHistory } from 'react-router-dom';

import AddressType from '../../../../../chief-types/src/AddressType';
import AddressTypeForm from './AddressTypeForm';
import Panel from '../../Components/Panel';
import useAddressTypes from './UseAddressTypes';
import { useEffect, useMemo } from 'react';
import useForm from '../../Hooks/UseForm';
import useLoggedInUser from '../../Hooks/UseLoggedInUser';

export type EditAddressTypeProps = {
  id: string;
  authService: any;
};

const EditAddressType = ({
  Navigate,
  id,
  authService,
}: LinkProps<EditAddressTypeProps>) => {
  const Link = Navigate ? LinkWrapper(Navigate).Component : rrdLink;
  const history = Navigate ? LinkWrapper(Navigate).useHistory : rrdUseHistory();

  const {
    error,
    item,
    loadItem,
    loading,
    updateItem,
    tokenStorageChecked,
  } = useAddressTypes(false);
  const [
    addressType,
    setAddressTypeValue,
    setAddressType,
  ] = useForm<AddressType>({
    name: '',
  });

  useEffect(() => {
    if (tokenStorageChecked) {
      loadItem(id);
    }
  }, [tokenStorageChecked]);

  useEffect(() => {
    if (item !== undefined) {
      setAddressType(item);
    }
  }, [item, setAddressType]);

  const {
    user,
    hasAllDomainsPermission,
    userDomains,
    userLoading,
    userError,
  } = useLoggedInUser(authService);

  return (
    <Panel title="Edit Address Type" loading={loading || userLoading}>
      {error && (
        <Alert>
          <h4>Error</h4>
          <p>{error}</p>
        </Alert>
      )}
      {userError && (
        <Alert>
          <h4>Error</h4>
          <p>{userError}</p>
        </Alert>
      )}

      {item && (
        <>
          <AddressTypeForm
            addressType={addressType}
            setAddressTypeValue={setAddressTypeValue}
            hasAllDomainsPermission={hasAllDomainsPermission}
            userDomains={userDomains}
          />

          <Button
            className="pull-right"
            onClick={() =>
              updateItem(id, addressType).then(() =>
                history.push('address-types'),
              )
            }
          >
            Save
          </Button>
        </>
      )}
    </Panel>
  );
};

export default EditAddressType;
