<div [class.panel]="!isPopover">
  <app-page-title *ngIf="!isPopover" orientation="right" title="Team Chat" [loading]="false"></app-page-title>
  <div [class.panel-body]="!isPopover">

    <div class="team-chat-window">
      <ul>
        <li *ngFor="let message of currentChat | async">
          <div class="avatar">
            <img [src]="message?.user?.avatar">
          </div>
          <div class="message">
            {{ message?.text }}<br />
            <i class="text-muted">Sent {{ message?.created | timeAgo }} by {{ message?.user?.name }}</i>
          </div>
        </li>
      </ul>
    </div>
  </div>
  <div [class.panel-footer]="!isPopover">
    <div class="row">
      <div class="col-md-10">
        <input type="text" class="form-control" placeholder="Type your message here..." [(ngModel)]="messageToSend" (keyup.enter)="sendMessage()">
      </div>
      <div class='col-md-2'>
        <button class="btn btn-success btn-block" (click)="sendMessage()">Send</button>
      </div>
    </div>
  </div>
</div>


