import { Component, OnInit } from '@angular/core';
import { LetterheadService } from '../letterhead.service';
import { Letterhead } from '../letterhead';
import { Router } from '@angular/router';
import { NotificationService } from '../../shared/notification.service';

@Component({
  selector: 'app-letterhead-create',
  templateUrl: 'create.component.html',
})
export class LetterheadCreateComponent implements OnInit {
  /**
   * Details of the letterhead being created.
   */
  public letterhead: Letterhead;

  /**
   * Are we currently performing a loading action?
   *
   * @type {boolean}
   */
  public isLoading: boolean = false;

  /**
   * Load up all our required services and subscribe to every observable we need.
   *
   * @param {LetterheadService} letterheadService
   * @param {NotificationService} notificationService
   * @param {Router} router
   */
  constructor(
    private letterheadService: LetterheadService,
    private notificationService: NotificationService,
    private router: Router,
  ) {
    this.letterheadService.loading.subscribe(
      result => (this.isLoading = result),
    );
  }

  /**
   * Initialise the component, creating a new blank letterhead if required.
   */
  public ngOnInit(): void {
    if (!this.letterhead) {
      this.letterhead = new Letterhead();
    }
  }

  /**
   * Perform the create action which adds the new letterhead into the backend.
   */
  public create() {
    const createLetterhead = this.letterheadService
      .createLetterhead(this.letterhead)
      .subscribe(
        (result: Letterhead) => {
          this.notificationService.success(
            `Letterhead Created`,
            `New letterhead ${result.name} has been created with ID of ${
              result.id
            }.`,
            5,
          );
          this.letterhead = new Letterhead();
          createLetterhead.unsubscribe();
          this.router.navigate(['/letterheads']);
        },
        err => {
          this.notificationService.error(
            `Could not create Letterhead`,
            err.error.message,
            10,
          );
        },
      );
  }
}
