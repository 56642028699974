<div class="row">
  <div class="col-sm-12">
    <section class="panel">
        <app-page-title [loading]="isLoading" title="Paperless Office - Outbound Mail">
        {{ totalResults }} Results Found
      </app-page-title>

      <div class="panel-body">
        <table class="table table-striped">
            <thead>
            <tr>
                <th>Domain</th>
                <th>Client</th>
                <th>Document Type</th>
                <th>Approved By</th>
                <th>Approved At</th>
                <th>&nbsp;</th>
            </tr>
            </thead>

            <tbody>
            <tr *ngFor="let item of results">
                <td>{{ item?.case?.domain?.name }}</td>
                <td>
                    <div *ngFor="let person of item?.case?.people">
                        {{ person.firstName }} {{ person.lastName }}
                    </div>
                </td>
                <td>{{ item?.document?.name }}</td>
                <td>{{ item?.approvedBy?.name }}</td>
                <td>{{ item?.approvedAt | date:'dd/MM/yyyy HH:mm' }}</td>
                <td class="text-right">
                    <div aria-label="..." class="btn-group btn-group-sm" role="group">
                        <a [routerLink]="['/cases', item?.case?.id]" class="btn btn-success btn-sm"><i
                                class="fa fa-eye"></i></a>
                        <a [href]="item?.url" class="btn btn-warning btn-sm" target="_blank"><i
                                class="fa fa-download"></i></a>
                        <button (click)="markDocumentSent(item.id)" class="btn btn-danger btn-sm" type="button">
                            <i class="fa fa-check"></i></button>
                    </div>
                </td>
            </tr>
            </tbody>

            <tfoot>
            <tr>
                <td class="text-center" colspan="99">
                    <app-pagination (pageChanged)="loadPage($event)" [currentPage]="currentPage"
                                    [totalPages]="totalPages"></app-pagination>
                </td>
            </tr>
            </tfoot>
        </table>
      </div>
    </section>
  </div>
</div>
