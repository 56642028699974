import { DeliveryMethod } from '../../../delivery-methods/delivery-method';
import { Pack } from '../../../packs/pack';
import { Person } from '../../people/person';

export class CasePackGenerator {
  public pack?: Pack;
  public deliveryMethod: DeliveryMethod;
  public person?: Person;
  public freeTypedItems?: FreeTypedItem[];
}

export class FreeTypedItem {
  id: string;
  value: string;
}
