import { Component } from '@angular/core';
import { DocumentService } from '../document.service';
import { Document } from '../document';
import { ActivatedRoute, Router } from '@angular/router';
import { NotificationService } from '../../shared/notification.service';

@Component({
  selector: 'app-document-edit',
  templateUrl: 'edit.component.html',
})
export class DocumentEditComponent {
  /**
   * Details of the document being edited.
   */
  public document: Document;

  /**
   * Are we currently performing a loading action?
   *
   * @type {boolean}
   */
  public isLoading: boolean = false;

  /**
   * Load up all our required services and subscribe to every observable we need.
   *
   * @param {DocumentService} documentService
   * @param {NotificationService} notificationService
   * @param {Router} router
   * @param {ActivatedRoute} route
   */
  constructor(
    private documentService: DocumentService,
    private notificationService: NotificationService,
    private router: Router,
    private route: ActivatedRoute,
  ) {
    this.documentService.loading.subscribe(result => (this.isLoading = result));
    this.route.params.subscribe(params => this.loadDocument(params.id));
  }

  /**
   * Perform the edit action which send the edited document to the backend.
   */
  public edit() {
    const editDocument = this.documentService
      .updateDocument(this.document.id, this.document)
      .subscribe(
        (result: Document) => {
          this.notificationService.success(
            `Document Updated`,
            `The document ${
              result.name
            } been updated with the specified changes.`,
            5,
          );
          this.document = new Document();
          editDocument.unsubscribe();
          this.router.navigate(['/documents']);
        },
        err => {
          this.notificationService.error(
            `Document Not Found`,
            err.error.message,
            10,
          );
          this.router.navigate(['/documents']);
        },
      );
  }

  /**
   * Loads the requested document so we can display it and let the user edit the fields.
   *
   * @param {string} id
   */
  private loadDocument(id: string) {
    this.documentService.findDocument(id).subscribe(
      result => {
        this.document = result;
      },
      err => {
        this.notificationService.error(
          `Document Not Found`,
          err.error.message,
          10,
        );
        this.router.navigate(['/documents']);
      },
    );
  }
}
