import 'rxjs/Rx';

import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Component, Input, OnInit, TemplateRef} from '@angular/core';
import {BsModalRef, BsModalService} from 'ngx-bootstrap/modal';

import {environment} from '../../../../environments/environment';
import {AuthService} from '../../../auth/auth.service';
import {HttpRequestResponse} from '../../../shared/http/http-request-response';
import {NotificationService} from '../../../shared/notification.service';

@Component({
  selector: 'app-communicate-button',
  templateUrl: 'send.component.html',
})
export class CaseCommunicateSendComponent implements OnInit {
  private readonly httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + this.authService.getToken(),
    }),
  };

  @Input() caseId: string;
  @Input() buttonSize: string = 'xs';
  @Input() buttonColor: string = 'warning';

  public contact: any;

  modalRef: BsModalRef;

  constructor(
    private http: HttpClient,
    private readonly authService: AuthService,
    protected readonly notification: NotificationService,
    private modalService: BsModalService,
  ) {}

  ngOnInit(): void {
    this.contact = {};
  }

  public openModal(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(template);
  }

  public sendMessage() {
    if (this.contact.template !== undefined) {
      this.sendFromTemplate();
    } else {
      this.sendBasicMessage();
    }
  }

  private sendFromTemplate() {
    this.http
      .post<HttpRequestResponse>(
        `${environment.endpoint}cases/${this.caseId}/communicate/template/${
          this.contact.template.id
        }`,
        this.contact,
        this.httpOptions,
      )
      .map((data: HttpRequestResponse) => {
        return data.data;
      })
      .subscribe(item => {
        this.notification.success(
          'Success',
          'Message has been sent, you can see any responses in the communications tab.',
          5,
        );
        this.modalRef.hide();
        this.contact = {};
      });
  }

  private sendBasicMessage() {
    this.http
      .post<HttpRequestResponse>(
        `${environment.endpoint}cases/${this.caseId}/communicate/body/${
          this.contact.type
        }`,
        this.contact,
        this.httpOptions,
      )
      .map((data: HttpRequestResponse) => {
        return data.data;
      })
      .subscribe(item => {
        this.notification.success(
          'Success',
          'Message has been sent, you can see any responses in the communications tab.',
          5,
        );
        this.modalRef.hide();
        this.contact = {};
      });
  }
}
