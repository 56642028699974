import { useEffect, useState } from 'react';
import { Pagination as BSPagination } from 'react-bootstrap';

type PaginationProps = {
  totalPages: number;
  currentPage: number;
  onPageChanged: (pageNumber: number) => void;
};

export const useCurrentPage = (
  onChange?: (page: number) => void,
  defaultPage: number = 1,
): [number, (page: number) => void] => {
  const [currentPage, setCurrentPage] = useState<number>(defaultPage);

  const changePage = (page: number) => {
    setCurrentPage(page);
    if (onChange) {
      onChange(page);
    }
  };

  return [currentPage, changePage];
};

const Pagination = ({
  totalPages,
  currentPage,
  onPageChanged,
}: PaginationProps) => {
  const [pageRange, setPageRange] = useState<number[]>([1]);

  const nextPage = () => {
    const nextPageNumber = currentPage + 1;
    choosePage(nextPageNumber > totalPages ? totalPages : nextPageNumber);
  };

  const previousPage = () => {
    const previousPageNumber = currentPage - 1;
    choosePage(previousPageNumber < 1 ? 1 : previousPageNumber);
  };

  const calculatePageRange = (neighbours: number = 2): number[] => {
    const neighborSlant = neighbours * 2 + 1;

    const numberList = [...Array(totalPages).keys()]
      .map((i) => {
        const nextNumber = i + 1;

        return nextNumber >= totalPages ||
          nextNumber <= 1 ||
          nextNumber > currentPage + neighbours ||
          nextNumber < currentPage - neighbours
          ? undefined
          : nextNumber;
      })
      .filter((r) => r);

    return numberList as number[];
  };

  const choosePage = (page: number) => {
    onPageChanged(page);
    setPageRange(calculatePageRange());
    onPageChanged(page);
  };

  useEffect(() => setPageRange(calculatePageRange()), []);

  return (
    <div className="tw-flex tw-flex-row tw-gap-1">
      {totalPages < 5 ? (
        ''
      ) : (
        <>
          <BSPagination.First onClick={() => choosePage(1)} />
          <BSPagination.Prev onClick={() => previousPage()} />
        </>
      )}
      <div
        className={`tw-rounded-sm tw-select-none tw-cursor-pointer tw-flex tw-items-center tw-justify-center tw-w-6 tw-h-6 tw-ring-1 tw-ring-gray-200 ${
          currentPage === 1
            ? 'tw-bg-blue-400 tw-text-white'
            : 'tw-bg-white hover:tw-bg-blue-100'
        }`}
        key={1}
        onClick={() => choosePage(1)}
      >
        {1}
      </div>
      {pageRange[0] > 2 ? <BSPagination.Ellipsis /> : ''}

      {pageRange.map((n) => (
        <div
          className={`tw-rounded-sm tw-select-none tw-cursor-pointer tw-flex tw-items-center tw-justify-center tw-w-6 tw-h-6 tw-ring-1 tw-ring-gray-200 ${
            currentPage === n
              ? 'tw-bg-blue-400 tw-text-white'
              : 'tw-bg-white hover:tw-bg-blue-100'
          }`}
          key={n}
          onClick={() => choosePage(n)}
        >
          {n}
        </div>
      ))}

      {pageRange[pageRange.length - 1] < totalPages - 1 ? (
        <BSPagination.Ellipsis />
      ) : (
        ''
      )}
      {totalPages === 1 ? (
        ''
      ) : (
        <div
          className={`tw-rounded-sm tw-select-none tw-cursor-pointer tw-flex tw-items-center tw-justify-center tw-w-6 tw-h-6 tw-ring-1 tw-ring-gray-200 ${
            currentPage === totalPages
              ? 'tw-bg-blue-400 tw-text-white'
              : 'tw-bg-white hover:tw-bg-blue-100'
          }`}
          onClick={() => choosePage(totalPages)}
        >
          {totalPages}
        </div>
      )}
      {totalPages < 5 ? (
        ''
      ) : (
        <>
          <BSPagination.Next onClick={() => nextPage()} />
          <BSPagination.Last onClick={() => choosePage(totalPages)} />
        </>
      )}
    </div>
  );
};

export default Pagination;
