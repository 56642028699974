import { RouterModule, Routes } from '@angular/router';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule } from '@angular/common/http';
import { PaymentTypeListComponent } from './list/list.component';
import { AuthGuard } from '../../auth/auth.guard';
import { PaymentTypeService } from './payment-type.service';
import { PaymentTypeCreateComponent } from './create/create.component';
import { PaymentTypeFormComponent } from './form/form.component';
import { PaymentTypeEditComponent } from './edit/edit.component';
import { SharedModule } from '../../shared/shared.module';

const routes: Routes = [
  {
    path: 'payment-types',
    component: PaymentTypeListComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'payment-types/create',
    component: PaymentTypeCreateComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'payment-types/:id/edit',
    component: PaymentTypeEditComponent,
    canActivate: [AuthGuard],
  },
];

@NgModule({
  declarations: [
    PaymentTypeListComponent,
    PaymentTypeCreateComponent,
    PaymentTypeFormComponent,
    PaymentTypeEditComponent,
  ],
  imports: [
    BrowserModule,
    RouterModule,
    FormsModule,
    HttpClientModule,
    SharedModule,
    RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' }),
  ],
  exports: [RouterModule],
  providers: [AuthGuard, PaymentTypeService],
})
export class PaymentTypeRoutingModule {}
