import { Component, Input } from '@angular/core';
import { IncomeType } from '../income-type';

@Component({
  selector: 'app-income-type-form',
  templateUrl: 'form.component.html',
})
export class IncomeTypeFormComponent {
  @Input() incomeType: IncomeType;
}
