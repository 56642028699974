import {RouterModule, Routes} from '@angular/router';
import {NgModule} from '@angular/core';
import {FormsModule} from '@angular/forms';
import {BrowserModule} from '@angular/platform-browser';
import {HttpClientModule} from '@angular/common/http';
import {DepartmentListComponent} from './list/list.component';
import {AuthGuard} from '../../auth/auth.guard';
import {DepartmentService} from './department.service';
import {SharedModule} from '../../shared/shared.module';
import {DepartmentCreateComponent} from './create/create.component';
import {DepartmentFormComponent} from './form/form.component';
import {DepartmentEditComponent} from './edit/edit.component';

const routes: Routes = [
    {path: 'departments', component: DepartmentListComponent, canActivate: [AuthGuard]},
    {
        path: 'departments/create',
        component: DepartmentCreateComponent,
        canActivate: [AuthGuard],
    },
    {
        path: 'departments/:id/edit',
        component: DepartmentEditComponent,
        canActivate: [AuthGuard],
    },
];

@NgModule({
    declarations: [
        DepartmentListComponent,
        DepartmentCreateComponent,
        DepartmentFormComponent,
        DepartmentEditComponent,
    ],
    imports: [
        BrowserModule,
        RouterModule,
        FormsModule,
        HttpClientModule,
        SharedModule,
        RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' }),
    ],
    exports: [RouterModule],
    providers: [AuthGuard, DepartmentService],
})
export class DepartmentRoutingModule {
}
