import { Component } from '@angular/core';
import { ProductService } from '../product.service';
import { Product } from '../product';
import { ActivatedRoute, Router } from '@angular/router';
import { NotificationService } from '../../shared/notification.service';

@Component({
  selector: 'app-product-edit',
  templateUrl: 'edit.component.html',
})
export class ProductEditComponent {
  /**
   * Details of the product being edited.
   */
  public product: Product;

  /**
   * Are we currently performing a loading action?
   *
   * @type {boolean}
   */
  public isLoading: boolean = false;

  /**
   * Load up all our required services and subscribe to every observable we need.
   *
   * @param {ProductService} productService
   * @param {NotificationService} notificationService
   * @param {Router} router
   * @param {ActivatedRoute} route
   */
  constructor(
    private productService: ProductService,
    private notificationService: NotificationService,
    private router: Router,
    private route: ActivatedRoute,
  ) {
    this.productService.loading.subscribe(result => (this.isLoading = result));
    this.route.params.subscribe(params => this.loadProduct(params.id));
  }

  /**
   * Perform the edit action which send the edited product to the backend.
   */
  public edit() {
    const editProduct = this.productService
      .updateProduct(this.product.id, this.product)
      .subscribe(
        (result: Product) => {
          this.notificationService.success(
            `Product Updated`,
            `The product ${
              result.name
            } been updated with the specified changes.`,
            5,
          );
          this.product = new Product();
          editProduct.unsubscribe();
          this.router.navigate(['/products']);
        },
        err => {
          this.notificationService.error(
            `Product Not Found`,
            err.error.message,
            10,
          );
          this.router.navigate(['/products']);
        },
      );
  }

  /**
   * Loads the requested product so we can display it and let the user edit the fields.
   *
   * @param {string} id
   */
  private loadProduct(id: string) {
    this.productService.findProduct(id).subscribe(
      result => {
        this.product = result;
      },
      err => {
        this.notificationService.error(
          `Product Not Found`,
          err.error.message,
          10,
        );
        this.router.navigate(['/products']);
      },
    );
  }
}
