import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Component, EventEmitter, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import * as moment from 'moment';

import {environment} from '../../../../../environments/environment';
import {AuthService} from '../../../../auth/auth.service';
import {Case} from '../../../../cases/case';
import {CaseService} from '../../../../cases/case.service';
import {FileUploaderAbstract} from '../../../../shared/abstracts/file-uploader.abstract';
import {HttpRequestResponse} from '../../../../shared/http/http-request-response';
import {NotificationService} from '../../../../shared/notification.service';
import {BankingAccountItem} from '../banking-account-item';
import {BankingAccountItemService} from '../banking-account-item.service';

@Component({
  selector: 'app-banking-account-item-list',
  templateUrl: 'list.component.html',
  styleUrls: [],
})
export class BankingAccountItemListComponent
  extends FileUploaderAbstract
  implements OnInit {
  public accountItem: BankingAccountItem;

  currentBankItem;
  public paymentDate: any = {};
  public note: string = '';
  public importTotal: number = 0;
  public chosenClientLink: string;
  public continueWithInvalidMatch: boolean = false;
  public eoSelected = {
    case: {
      supervisory_percentage: 15,
    },
    typeId: 'c85bc69d-f342-4d1a-93a3-2bc4d566873e',
    value: 0,
    feeValue: 0,
    supsPercentage: 15,
    incomeType: undefined,
  };
  paymentSchedule: any[];
  accountSelect: any = {
    account: undefined,
    reconcileOptions: 'all',
    filter: '',
  };
  /**
   * A list of all the banking-account-items currently loaded.
   *
   * @item {BankingAccountItem[]}
   */
  public accountItems: BankingAccountItem[] = [];
  /**
   * Are we currently performing a loading action?
   *
   * @item {boolean}
   */
  public isLoading: boolean = true;
  /**
   * The total number of pages in this list of banking-account-items.
   *
   * @item {number}
   */
  public totalPages: number = 1;
  /**
   * The current page of banking-account-items that we are viewing.
   *
   * @item {number}
   */
  public currentPage: number = 1;
  /**
   * The total number of banking-account-items that can be viewed in the system.
   *
   * @item {number}
   */
  public totalResults: number = 0;
  public currentMonetaryValue: number = 0;
  public reconcileItems: string[] = [];
  public matchAllowed: boolean = false;
  public checkingMatchAllowed: boolean = false;
  public matchAllowedMessage: string;
  public reconciliationCreated: EventEmitter<boolean> = new EventEmitter<boolean>();
  /**
   * An Emitter to alert other components when a accountItem has been deleted.
   *
   * @item {EventEmitter<boolean>}
   */
  public accountItemDeleted: EventEmitter<boolean> = new EventEmitter<boolean>();
  public reconcileReady: boolean = false;
  public reconcileOptions: string = 'all';
  public filter: string = undefined;
  public accountId: string;
  public foundCases: Case[] = [];
  public eoSelectedClients = [];
  protected bankDetails: any;
  private readonly httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + this.authService.getToken(),
    }),
  };

  constructor(
    protected readonly http: HttpClient,
    protected readonly authService: AuthService,
    protected readonly accountItemService: BankingAccountItemService,
    protected readonly notificationService: NotificationService,
    protected readonly route: ActivatedRoute,
    protected readonly caseService: CaseService,
  ) {
    super(authService);
    this.accountItemService.loading.subscribe((isLoading) => {
      this.isLoading = isLoading;
    });
    this.accountItemService.totalPages.subscribe((totalPages) => {
      this.totalPages = totalPages;
    });
    this.accountItemService.currentPage.subscribe((currentPage) => {
      this.currentPage = currentPage;
    });
    this.accountItemService.totalResults.subscribe((totalResults) => {
      this.totalResults = totalResults;
    });
  }

  public get invoiceIsRequired(): boolean {
    let totalFee = 0;
    this.eoSelectedClients.forEach((v) => (totalFee += v.feeValue));

    return totalFee > 0 && this.uploader.queue.length < 1;
  }

  get downloadLink(): string {
    return `${
      environment.apiUrl
    }reports/bank-statement?download=true&domainId=${
      this.authService.domain.id
    }&accountId=${this.accountId}&limit=99999999&reconciled=${
      this.reconcileOptions
    }${
      this.filter ? '&filter=' + this.filter : ''
    }&auth=${this.authService.getToken()}`;
  }

  public get totalReconciled() {
    let total = 0;

    this.eoSelectedClients.forEach((item) => {
      total += item.value;
    });

    return total;
  }

  get postUrl() {
    return 'financials/invoicing/upload';
  }

  public caseChanged() {
    this.eoSelected.supsPercentage =
      this.eoSelected.case.supervisory_percentage || 15;
  }

  public deleteSelectedItemAtIndex(index) {
    this.eoSelectedClients.splice(index, 1);
  }

  public performSearch(value): void {
    this.caseService.search(value).subscribe((cases: any) => {
      this.paymentSchedule = [];
      this.paymentDate = {};
      this.note = '';
      this.foundCases = cases;
      this.eoSelected.case = {
        supervisory_percentage: 15,
      };
      this.eoSelected.case.supervisory_percentage = 15;
    });
  }

  public createReconciliation(event) {
    this.accountItemService
      .reconcileItems(event.given_id.id, this.reconcileItems, {
        parameters: { accountId: this.accountId },
      })
      .subscribe(() => {
        this.reconciliationCreated.next(true);
        this.reconcileItems = [];
        this.reconcileReady = false;
        this.loadBankingAccountItems(this.currentPage);
      });
  }

  public createClientReconciliation(event) {
    console.log(event.given_id);

    this.accountItemService
      .reconcileClient(
        event.given_id.id,
        this.chosenClientLink,
        this.paymentDate['expectedDate'],
        Math.round(event.given_id.value * 100),
        event.given_id.paidDate,
        this.note,
        this.paymentDate['id'],
        { parameters: { accountId: this.accountId } },
      )
      .subscribe(() => {
        this.reconciliationCreated.next(true);
        this.clearReconcile();
        this.loadBankingAccountItems(this.currentPage);
      });
  }

  public createClientLoanReconciliation(event) {
    this.accountItemService
      .reconcileToLoan(
        event.given_id.id,
        this.chosenClientLink,
        Math.round(event.given_id.value * 100),
        event.given_id.paidDate,
      )
      .subscribe((r) => {
        this.reconciliationCreated.next(true);
        this.clearReconcile();
        this.loadBankingAccountItems(this.currentPage);
      });
  }

  public clearReconcile() {
    this.accountSelect = {
      account: undefined,
      reconcileOptions: 'all',
      filter: '',
    };
    this.foundCases = [];
    this.paymentSchedule = [];
    this.reconcileItems = [];
    this.reconcileReady = false;
  }

  public startReconcile() {
    this.reconcileReady = false;
    setTimeout(() => {
      this.reconcileReady = true;
    }, 50);
  }

  /**
   * When the component is initialised we set it to load
   * page one of the accountItem list.
   */
  public ngOnInit(): void {
    this.route.params.subscribe((params) => {
      this.accountId = params.accountId;
      this.loadBankingAccountItems(1);
    });
  }

  /**
   * When we receive a page change from the pagination
   * component we load the appropriate page for the
   * accountItem list.
   *
   * @param {number} pageNumber
   */
  public onPageChanged(pageNumber: number): void {
    this.loadBankingAccountItems(pageNumber);
  }

  /**
   * Loads a list of banking-account-items using the given per page limit.
   *
   * @param {number} page The current page we require.
   * @param {number} limit The number of results per page.
   */
  public loadBankingAccountItems(page?: number, limit?: number): void {
    const query: any = {};
    if (this.reconcileOptions !== 'all') {
      query['reconciled'] = this.reconcileOptions === 'reconciled';
    }
    if (this.filter !== undefined) {
      query['filter'] = this.filter;
    }

    const listBankingAccountItems = this.accountItemService
      .listBankingAccountItems(
        page,
        limit,
        { parameters: { accountId: this.accountId } },
        query,
      )
      .subscribe((accountItems: BankingAccountItem[]) => {
        this.accountItems = accountItems;
        listBankingAccountItems.unsubscribe();
      });
  }

  /**
   * When receiving confirmation of an item being deleted
   * we call the back end with the appropriate accountItem ID
   * to delete the accountItem.
   *
   * @param {string} id
   */
  public delete(id: string) {
    this.accountItemService.deleteBankingAccountItem(id).subscribe(
      (deleted) => {
        this.notificationService.success(
          `BankingAccountItem Deleted`,
          `BankingAccountItem with ID ${id} was deleted.`,
          5,
        );
        this.accountItemDeleted.next(true);
        this.onPageChanged(this.currentPage);
      },
      (err) => {
        this.notificationService.error(
          `BankingAccountItem Not Deleted`,
          err.error.message,
          10,
        );
        this.accountItemDeleted.next(false);
        this.onPageChanged(this.currentPage);
      },
    );
  }

  public makePositive(value: number) {
    return value < 0 ? value * -1 : value;
  }

  public getPaymentScheduleForCase(caseId: string) {
    this.chosenClientLink = caseId;
    this.http
      .get<HttpRequestResponse>(
        `${environment.endpoint}cases/${caseId}/payment-schedule`,
        this.httpOptions,
      )
      .map((data: HttpRequestResponse) => data.data)
      .subscribe((item) => {
        this.paymentSchedule = item.schedule;
      });
  }

  public canMatch(firstId: string, secondId: string) {
    this.checkingMatchAllowed = true;
    return this.accountItemService
      .canReconcile(firstId, secondId)
      .subscribe((r) => {
        this.matchAllowed = r.result;
        this.matchAllowedMessage = r.message;
        this.checkingMatchAllowed = false;
      });
  }

  public selectCase(caseId: string) {
    this.chosenClientLink = caseId;
  }

  public checkPaymentDate(event, transactionDate): void {
    if (!event.expectedDate) {
      this.paymentDate = {};
      return;
    }

    const dateDifference = moment(event.expectedDate).diff(
      moment(transactionDate),
      'd',
    );

    if (dateDifference > 30 || dateDifference > 30) {
      const confirmBox = confirm(
        `This payment date is ${dateDifference} away from the transaction date. Are you sure this is correct?`,
      );
      if (confirmBox === true) {
        this.paymentDate = event;
      } else {
        this.paymentDate = {};
      }
    } else {
      this.paymentDate = event;
    }
  }

  public addEoSelected() {
    this.eoSelectedClients.push({
      case: this.eoSelected.case,
      typeId: this.eoSelected.incomeType.id,
      value: Math.round(this.eoSelected.value * 100),
      feeValue: Math.round(this.eoSelected.feeValue * 100),
      supsPercentage: this.eoSelected.supsPercentage,
      incomeType: this.eoSelected.incomeType,
    });
    this.eoSelected = {
      case: {
        supervisory_percentage: 15,
      },
      typeId: 'c85bc69d-f342-4d1a-93a3-2bc4d566873e',
      value: 0,
      feeValue: 0,
      supsPercentage: 15,
      incomeType: undefined,
    };
    this.foundCases = [];
  }

  createExtraordinaryReconciliation(event) {
    this.currentBankItem = event.given_id.id;
    this.bankDetails = event.given_id;
    // Upload the invoice
    if (this.uploader.queue.length < 1) {
      this.workCompleted(JSON.stringify({}));
    } else {
      this.saveInvoice();
    }
  }

  saveInvoice() {
    let invoiceTotal = 0;
    this.eoSelectedClients.forEach((i) => {
      invoiceTotal += i.feeValue;
    });

    this.setUploadOptions({
      chartOfAccountsId: 'bff367a3-cfb7-4e0a-aa4a-16e2dc2ece3f',
      value: invoiceTotal,
    }).upload();
  }

  workCompleted(response: any) {
    console.log('Invoice Uploaded', response);

    this.http
      .post(
        `${environment.endpoint}financials/invoicing/reconcile-eo`,
        {
          response,
          bankItem: this.currentBankItem,
          clients: this.eoSelectedClients,
          createdAt: this.bankDetails.transaction.createdAt,
        },
        this.httpOptions,
      )
      .subscribe((v) => {
        this.reconciliationCreated.next(true);
        this.clearEoReconcile();
      });
  }

  public clearEoReconcile() {
    this.eoSelected = {
      case: {
        supervisory_percentage: 15,
      },
      typeId: 'c85bc69d-f342-4d1a-93a3-2bc4d566873e',
      value: 0,
      feeValue: 0,
      supsPercentage: 15,
      incomeType: undefined,
    };
    this.eoSelectedClients = [];
    this.uploader.clearQueue();
    this.loadBankingAccountItems(this.currentPage);
  }
}
