import { Injectable } from '@angular/core';
import { BankingAccountType } from './banking-account-type';
import { Observable } from 'rxjs';

import { HttpRequestService } from '../../../shared/http/http-request.service';

@Injectable()
export class BankingAccountTypeService extends HttpRequestService {
  /**
   * The endpoint that we call to get the accountType information.
   *
   * @type {string}
   */
  private readonly endpoint: string = 'banking/account-types';

  /**
   * Brings back a list of all accessible account-types.
   *
   * @param {number} page The page number we are currently on.
   * @param {number} limit The number of account-types to display per page.
   * @returns {Observable<BankingAccountType[]>}
   */
  public listBankingAccountTypes(
    page?: number,
    limit?: number,
  ): Observable<BankingAccountType[]> {
    return this.list(this.endpoint, page, undefined, limit);
  }

  /**
   * Finds details of the accountType from the given ID.
   *
   * @param {string} accountTypeId The ID of the accountType we require details for.
   * @returns {Observable<BankingAccountType>}
   */
  public findBankingAccountType(accountTypeId: string): Observable<BankingAccountType> {
    return this.findById(this.endpoint, accountTypeId);
  }

  /**
   * Creates a new accountType from the given BankingAccountType object.
   *
   * @param {BankingAccountType} newBankingAccountType The details of the accountType to be created.
   * @returns {Observable<BankingAccountType | boolean>}
   */
  public createBankingAccountType(
    newBankingAccountType: BankingAccountType,
  ): Observable<BankingAccountType | boolean> {
    return this.create(this.endpoint, newBankingAccountType);
  }

  /**
   * Updates a accountType with the given accountType object for the given accountType ID.
   *
   * @param {string} accountTypeId The ID of the accountType needing updating.
   * @param {BankingAccountType} newDetails The new accountType object to update with.
   * @returns {Observable<BankingAccountType | boolean>}
   */
  public updateBankingAccountType(
    accountTypeId: string,
    newDetails: BankingAccountType,
  ): Observable<BankingAccountType | boolean> {
    return this.update(this.endpoint, accountTypeId, newDetails);
  }

  /**
   * Deletes a accountType based on the given accountType ID.
   *
   * @param {string} accountTypeId The ID of the accountType needing deleting.
   * @returns {Observable<boolean>}
   */
  public deleteBankingAccountType(accountTypeId: string): Observable<boolean> {
    return this.delete(this.endpoint, accountTypeId);
  }
}
