import { Component, Input } from '@angular/core';
import { Asset } from '../asset';

@Component({
  selector: 'app-asset-form',
  templateUrl: 'form.component.html',
})
export class AssetFormComponent {
  @Input() asset: Asset;
}
