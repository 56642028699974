<div
  *ngIf="
    authService.hasPermission('config.multiple-domains') ||
    authService.hasPermission('config.all-domains')
  "
  class="form-group"
>
  <label class="col-sm-2 control-label" for="domain">Domain</label>
  <div class="col-sm-10">
    <app-domain-select [model]="case" [required]="true"></app-domain-select>
  </div>
</div>

<div class="form-group">
  <label class="col-sm-2 control-label" for="introducer">Introducer</label>
  <div class="col-sm-10">
    <app-introducer-select
      [model]="case"
      [required]="true"
    ></app-introducer-select>
  </div>
</div>

<div class="form-group">
  <label class="col-sm-2 control-label" for="product">Product</label>
  <div class="col-sm-10">
    <app-product-select [model]="case" [required]="true"></app-product-select>
  </div>
</div>

<div *ngIf="authService.hasPermission('config.all-domains')" class="form-group">
  <label class="col-sm-2 control-label" for="status">Status</label>
  <div class="col-sm-10">
    <app-status-select [model]="case" [required]="true"></app-status-select>
  </div>
</div>

<div class="form-group">
  <label class="col-sm-2 control-label" for="court-name"
    >Court Name (If Known)</label
  >
  <div class="col-sm-10">
    <input
      [(ngModel)]="case.courtName"
      class="form-control"
      name="courtName"
      placeholder="Court Name (If Known)"
      required="false"
      type="text"
    />
  </div>
</div>

<div class="row">
  <div class="col-md-6">
    <div class="form-group">
      <label class="col-sm-4 control-label" for="current-ip"
        >Current IP Name</label
      >
      <div class="col-sm-8">
        <input
          [(ngModel)]="case.currentIp"
          class="form-control"
          name="currentIp"
          placeholder="Current IP"
          required="false"
          type="text"
        />
      </div>
    </div>
    <div class="form-group">
      <label class="col-sm-4 control-label" for="case-locale">Case Area</label>
      <div class="col-sm-8">
        <select
          [(ngModel)]="case.caseLocale"
          class="form-control"
          name="case-locale"
          required="true"
        >
          <option value="england-wales">England / Wales</option>
          <option value="ireland">Northern Ireland</option>
        </select>
      </div>
    </div>
    <div class="form-group">
      <label class="col-sm-4 control-label" for="case-initial-dividend"
        >Initial Dividend</label
      >
      <div class="col-sm-8">
        <input
          [(ngModel)]="case.initialDividend"
          class="form-control"
          id="case-initial-dividend"
          step="0.0000001"
          type="number"
        />
      </div>
    </div>

    <div class="form-group">
      <label class="col-sm-4 control-label" for="case-initial-dividend"
        >Vulnerability</label
      >
      <div class="col-sm-8">
        <div class="form-check">
          <input
            type="checkbox"
            id="vulnerableClient"
            [(ngModel)]="case.vulnerableClient"
            class="form-check-input"
          />&nbsp;<label class="form-check-label" for="vulnerableClient"
            >Client is vulnerable</label
          >
        </div>

        <div class="form-check" *ngIf="case.vulnerableClient === true">
          <input
            type="checkbox"
            id="vulnerableClientDisclosed"
            [(ngModel)]="case.vulnerableClientDisclosed"
            class="form-check-input"
          />&nbsp;<label
            class="form-check-label"
            for="vulnerableClientDisclosed"
            >Permission to disclose?</label
          >
        </div>
      </div>
    </div>

    <div class="form-group">
      <label class="col-sm-4 control-label" for="case-initial-dividend"
        >Communication</label
      >
      <div class="col-sm-8">
        <div class="form-check">
          <input
            type="checkbox"
            id="allowPost"
            [(ngModel)]="case.allowPost"
            class="form-check-input"
          />&nbsp;<label class="form-check-label" for="allowPost"
            >Postal Mail Allowed</label
          >
        </div>
      </div>
    </div>
  </div>
  <div class="col-md-6">
    <div class="form-group">
      <label class="col-sm-4 control-label" for="allocation-type"
        >Fee Wizard</label
      >
      <div [class.col-sm-4]="editing" [class.col-sm-8]="!editing">
        <select
          [(ngModel)]="case.allocationType"
          class="form-control"
          name="allocation-type"
          (change)="resetAllocationCheckbox()"
        >
          <option value="furtherRealisationsNoCap">
            Further Realisations No Cap
          </option>
          <option value="furtherRealisationsWithCap">
            Further Realisations With Cap
          </option>
          <option value="totalRealisationsNoCap">
            Total Realisations No Cap
          </option>
          <option value="totalRealisationsWithCap">
            Total Realisations With Cap
          </option>
          <option value="manual">Manual</option>
        </select>
      </div>

      <div class="col-sm-4" *ngIf="editing">
        <div class="form-check">
          <input
            type="checkbox"
            id="allocation-type-confirmed"
            [(ngModel)]="case.allocationTypeConfirmed"
            class="form-check-input"
          />&nbsp;<label
            class="form-check-label"
            for="allocation-type-confirmed"
            >Confirmed Correct</label
          >
        </div>
        <div
          *ngIf="
            case.allocationTypeConfirmedBy && case.allocationTypeConfirmedAt
          "
        >
          <small
            ><b>By</b>&nbsp;<i>{{ case.allocationTypeConfirmedBy }}</i>
            &nbsp;<b>on</b>&nbsp;
            <i>{{
              case.allocationTypeConfirmedAt | date: 'dd/MM/yyyy'
            }}</i></small
          >
        </div>
      </div>
    </div>

    <div class="form-group">
      <label class="col-sm-4 control-label" for="case-allocation-savings"
        >Allocation Savings</label
      >
      <div class="col-sm-8">
        <app-shared-currency
          [(ngModel)]="case.allocationSavings"
        ></app-shared-currency>
      </div>
    </div>

    <div class="form-group">
      <label class="col-sm-4 control-label" for="administrationPeriod"
        >Administration Period (Months)</label
      >
      <div class="col-sm-8">
        <input
          [(ngModel)]="case.administrationPeriod"
          class="form-control"
          id="administrationPeriod"
          step="1"
          min="0"
          type="number"
        />
      </div>
    </div>

    <div class="form-group">
      <label class="col-sm-4 control-label" for="previous-ip"
        >Previous IP Name</label
      >
      <div class="col-sm-8">
        <input
          [(ngModel)]="case.previousIp"
          class="form-control"
          name="previousIp"
          placeholder="Previous IP"
          required="false"
          type="text"
        />
      </div>
    </div>

    <div class="form-group">
      <label class="col-sm-4 control-label" for="current-ip"
        >Previous IP Retirement Date</label
      >
      <div class="col-sm-8">
        <app-shared-date
          [(ngModel)]="case.previousIpRetired"
          [allowFuture]="false"
          [allowPast]="true"
          [required]="false"
        ></app-shared-date>
      </div>
    </div>
  </div>
</div>

<div *ngFor="let person of case.people; let i = index" class="well clearfix">
  <div class="row applicant-header">
    <div class="col-md-11">
      <h4>Applicant {{ i + 1 }}</h4>
    </div>
    <div class="col-md-1 text-right">
      <div class="btn-group" role="group">
        <button
          (click)="addApplicant()"
          *ngIf="case.people.length < 2"
          class="btn btn-xs btn-success"
          type="button"
        >
          <span class="fa fa-plus-circle"></span>
        </button>
        <button
          (click)="deleteApplicant(i)"
          *ngIf="case.people.length > 0 && i > 0"
          class="btn btn-xs btn-danger"
          type="button"
        >
          <span class="fa fa-trash"></span>
        </button>
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col-md-4">
      <app-shared-person-edit [model]="person"></app-shared-person-edit>
    </div>
    <div class="col-md-4">
      <app-shared-address
        [model]="person.addresses[0]"
        [requiredFields]="['address1', 'postalCode', 'country']"
      ></app-shared-address>

      <div class="form-group">
        <label class="col-sm-4 control-label" for="residential-status"
          >Residential Status</label
        >
        <div class="col-sm-8">
          <app-residential-status-select
            [model]="person"
          ></app-residential-status-select>
        </div>
      </div>

      <div class="form-group">
        <label class="col-sm-4 control-label" for="residential-restriction"
          >Residential Restriction</label
        >
        <div class="col-sm-8">
          <select
            [(ngModel)]="person.residentialRestriction"
            class="form-control"
            id="residential-restriction"
          >
            <option>N/A</option>
            <option>Awaiting RX1</option>
            <option>With IP for signing</option>
            <option>Sent to Land Reg</option>
            <option>Restriction in place</option>
            <option>Restriction removed</option>
            <option>Property sold</option>
          </select>
        </div>
      </div>
    </div>
    <div class="col-md-4">
      <div class="form-group">
        <label class="col-sm-4 control-label" for="employment-status"
          >Employment Status</label
        >
        <div class="col-sm-8">
          <app-employment-status-select
            [model]="person"
          ></app-employment-status-select>
        </div>
      </div>

      <div class="form-group">
        <label class="col-sm-4 control-label" for="occupation"
          >Occupation</label
        >
        <div class="col-sm-8">
          <app-occupation-select [model]="person"></app-occupation-select>
        </div>
      </div>

      <div class="form-group">
        <label class="col-sm-4 control-label" for="employer">Employer</label>
        <div class="col-sm-8">
          <input
            [(ngModel)]="person.employer"
            [required]="false"
            class="form-control"
            id="employer"
            name="employer"
            placeholder="Employer"
            type="text"
          />
        </div>
      </div>

      <app-shared-contact-details [model]="person"></app-shared-contact-details>
    </div>
  </div>
</div>

<div class="row">
  <div class="col-md-6">
    <label class="control-label" for="employer"
      >Reason for Financial Difficulty</label
    >
    <quill-editor
      [(ngModel)]="case.reasonForFinancialDifficulty"
      name="reasonForFinancialDifficulty"
    ></quill-editor>
  </div>
  <div class="col-md-6">
    <label class="control-label" for="employer"
      >Steps Taken to resolve financial difficulties</label
    >
    <quill-editor
      [(ngModel)]="case.stepsTakenToResolve"
      name="stepsTakenToResolve"
    ></quill-editor>
  </div>
</div>

<div class="row">
  <div class="col-md-4">
    <label class="control-label" for="employer"
      >Any Child Maintenance Agreement</label
    >
    <textarea
      [(ngModel)]="case.childMaintenanceAgreement"
      class="form-control"
      rows="5"
    ></textarea>
  </div>
  <div class="col-md-4">
    <label class="control-label" for="employer"
      >Financial Difficulty Summary</label
    >
    <textarea
      [(ngModel)]="case.reasonForFinancialDifficultySummary"
      class="form-control"
      name="reasonForFinancialDifficultySummary"
      rows="5"
    ></textarea>
  </div>
  <div class="col-md-4">
    <label class="control-label" for="employer"
      >Reason for Non-Standard Protocol</label
    >
    <textarea
      [(ngModel)]="case.reasonForNonStandardProtocol"
      class="form-control"
      rows="5"
    ></textarea>
  </div>
</div>

<div class="form-group" style="margin-top: 20px">
  <div class="col-sm-offset-10 col-sm-2 text-right">
    <button class="btn btn-block btn-success" type="submit">Save</button>
  </div>
</div>
