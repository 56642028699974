<div class="text-right button-menu">
  <button
    type="button"
    class="btn btn-sm btn-success"
    href="javascript:;"
    (click)="createModal.confirmDialog(caseId)"
  >
    Add Complaint
  </button>
</div>

<table class="table table-striped">
  <thead>
    <tr>
      <th>Complaint Date</th>
      <th>Title</th>
      <th>Current Status</th>
      <th>First Response Due</th>
      <th>Final Response Due</th>
      <th>Assigned To</th>
      <th>&nbsp;</th>
    </tr>
  </thead>
  <tbody>
    <tr *ngFor="let complaint of caseComplaints">
      <td>{{ complaint.createdAt | date: 'dd/MM/yyyy' }}</td>
      <td>{{ complaint.name }}</td>
      <td>{{ complaint.complaintStatus?.name }}</td>
      <td>{{ complaint.firstResponseAt | date: 'dd/MM/yyyy' }}</td>
      <td>{{ complaint.finalResponseAt | date: 'dd/MM/yyyy' }}</td>
      <td>{{ complaint.user?.name }}</td>
      <td class="text-right">
        <ul class="btn-group">
          <button
            type="button"
            class="btn btn-xs btn-warning"
            (click)="view.open(caseId, complaint.id)"
          >
            View
          </button>
          <button
            type="button"
            class="btn btn-xs btn-warning dropdown-toggle"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
          >
            <span class="caret"></span>
            <span class="sr-only">Toggle Dropdown</span>
          </button>
          <ul class="dropdown-menu dropdown-menu-right">
            <li>
              <a href="javascript:;" (click)="update.open(caseId, complaint.id)"
                >Update</a
              >
            </li>
            <li *ngIf="!complaint.user">
              <a href="javascript:;" (click)="assign.open(caseId, complaint.id)"
                >Assign To User</a
              >
            </li>
          </ul>
        </ul>
      </td>
    </tr>
  </tbody>
  <tfoot>
    <tr class="text-center">
      <td colspan="99">
        <app-pagination
          [totalPages]="totalPages"
          [currentPage]="currentPage"
          (pageChanged)="onPageChanged($event)"
        >
        </app-pagination>
      </td>
    </tr>
  </tfoot>
</table>

<app-case-complaint-view
  #view
  (completed)="onPageChanged(1)"
></app-case-complaint-view>

<app-case-complaint-assign
  #assign
  (completed)="onPageChanged(1)"
></app-case-complaint-assign>

<app-case-complaint-update
  #update
  (completed)="onPageChanged(1)"
></app-case-complaint-update>

<app-create-modal
  type="complaint"
  (confirm)="create($event)"
  [itemCreated]="complaintCreated"
  #createModal
>
  <div class="form-group">
    <label for="createdAt">Complaint Date</label>
    <input
      type="date"
      name="createdAt"
      class="form-control"
      [required]="true"
      ngModel
    />
  </div>

  <div class="row">
    <div class="col-md-6">
      <div class="form-group">
        <label for="firstResponseAt">First Response Date</label>
        <input
          type="date"
          name="firstResponseAt"
          class="form-control"
          [required]="true"
          ngModel
        />
      </div>
    </div>
    <div class="col-md-6">
      <div class="form-group">
        <label for="finalResponseAt">Final Response Date</label>
        <input
          type="date"
          name="finalResponseAt"
          class="form-control"
          [required]="true"
          ngModel
        />
      </div>
    </div>
  </div>

  <hr />

  <div class="form-group">
    <label for="name">Title</label>
    <input
      type="text"
      name="name"
      class="form-control"
      [required]="true"
      ngModel
    />
    <small
      >This should be some brief text to easily identify the complaint.</small
    >
  </div>

  <div class="form-group">
    <label for="content">Complaint Details</label>
    <textarea
      name="content"
      class="form-control"
      rows="7"
      [required]="true"
      ngModel
    ></textarea>
    <small>Full details of the complaint being submitted.</small>
  </div>
</app-create-modal>
