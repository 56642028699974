import { RouterModule, Routes } from '@angular/router';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule } from '@angular/common/http';
import { ResidentialStatusListComponent } from './list/list.component';
import { AuthGuard } from '../auth/auth.guard';
import { ResidentialStatusService } from './residential-status.service';
import { SharedModule } from '../shared/shared.module';
import { ResidentialStatusCreateComponent } from './create/create.component';
import { ResidentialStatusFormComponent } from './form/form.component';
import { ResidentialStatusEditComponent } from './edit/edit.component';

const routes: Routes = [
  {
    path: 'residential-statuses',
    component: ResidentialStatusListComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'residential-statuses/create',
    component: ResidentialStatusCreateComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'residential-statuses/:id/edit',
    component: ResidentialStatusEditComponent,
    canActivate: [AuthGuard],
  },
];

@NgModule({
  declarations: [
    ResidentialStatusListComponent,
    ResidentialStatusCreateComponent,
    ResidentialStatusFormComponent,
    ResidentialStatusEditComponent,
  ],
  imports: [
    BrowserModule,
    RouterModule,
    FormsModule,
    HttpClientModule,
    SharedModule,
    RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' }),
  ],
  exports: [RouterModule],
  providers: [AuthGuard, ResidentialStatusService],
})
export class ResidentialStatusRoutingModule {}
