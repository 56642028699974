import { Component, EventEmitter, OnInit } from '@angular/core';
import { StatusCategoryService } from '../status-category.service';
import { StatusCategory } from '../status-category';
import { NotificationService } from '../../shared/notification.service';

@Component({
  selector: 'app-status-category-list',
  templateUrl: 'list.component.html',
  styleUrls: [],
})
export class StatusCategoryListComponent implements OnInit {
  public statusCategory: StatusCategory;

  /**
   * A list of all the statusCategories currently loaded.
   *
   * @type {StatusCategory[]}
   */
  public statusCategories: StatusCategory[] = [];

  /**
   * Are we currently performing a loading action?
   *
   * @type {boolean}
   */
  public isLoading: boolean = true;

  /**
   * The total number of pages in this list of statusCategories.
   *
   * @type {number}
   */
  public totalPages: number = 1;

  /**
   * The current page of statusCategories that we are viewing.
   *
   * @type {number}
   */
  public currentPage: number = 1;

  /**
   * The total number of statusCategories that can be viewed in the system.
   *
   * @type {number}
   */
  public totalResults: number = 0;

  /**
   * An Emitter to alert other components when a statusCategory has been deleted.
   *
   * @type {EventEmitter<boolean>}
   */
  public statusCategoryDeleted: EventEmitter<boolean> = new EventEmitter<
    boolean
  >();

  /**
   * Load up all our required services and subscribe to every observable we need.
   *
   * @param {StatusCategoryService} statusCategoryService
   * @param {NotificationService} notificationService
   */
  constructor(
    private statusCategoryService: StatusCategoryService,
    private notificationService: NotificationService,
  ) {
    this.statusCategoryService.loading.subscribe(isLoading => {
      this.isLoading = isLoading;
    });
    this.statusCategoryService.totalPages.subscribe(totalPages => {
      this.totalPages = totalPages;
    });
    this.statusCategoryService.currentPage.subscribe(currentPage => {
      this.currentPage = currentPage;
    });
    this.statusCategoryService.totalResults.subscribe(totalResults => {
      this.totalResults = totalResults;
    });
  }

  /**
   * When the component is initialised we set it to load
   * page one of the statusCategory list.
   */
  public ngOnInit(): void {
    this.loadStatusCategories(1);
  }

  /**
   * When we receive a page change from the pagination
   * component we load the appropriate page for the
   * statusCategory list.
   *
   * @param {number} pageNumber
   */
  public onPageChanged(pageNumber: number): void {
    this.loadStatusCategories(pageNumber);
  }

  /**
   * Loads a list of statusCategories using the given per page limit.
   *
   * @param {number} page The current page we require.
   * @param {number} limit The number of results per page.
   */
  public loadStatusCategories(page?: number, limit?: number): void {
    const listStatusCategories = this.statusCategoryService
      .listStatusCategories(page, limit)
      .subscribe((statusCategories: StatusCategory[]) => {
        this.statusCategories = statusCategories;
        listStatusCategories.unsubscribe();
      });
  }

  /**
   * When receiving confirmation of an item being deleted
   * we call the back end with the appropriate statusCategory ID
   * to delete the statusCategory.
   *
   * @param {string} id
   */
  public delete(id: string) {
    this.statusCategoryService.deleteStatusCategory(id).subscribe(
      deleted => {
        this.notificationService.success(
          `StatusCategory Deleted`,
          `StatusCategory with ID ${id} was deleted.`,
          5,
        );
        this.statusCategoryDeleted.next(true);
        this.onPageChanged(this.currentPage);
      },
      err => {
        this.notificationService.error(
          `StatusCategory Not Deleted`,
          err.error.message,
          10,
        );
        this.statusCategoryDeleted.next(false);
        this.onPageChanged(this.currentPage);
      },
    );
  }
}
