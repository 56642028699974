import { Component, EventEmitter, Input, OnInit } from '@angular/core';

import { AngularFireFunctions } from '@angular/fire/functions';

@Component({
  selector: 'app-case-credit-summary',
  templateUrl: 'case-credit-summary.component.html',
})
export class CaseCreditSummaryComponent implements OnInit {
  @Input() warningOnly: boolean = false;
  @Input() clientId: string;
  @Input() paymentScheduleUpdated: EventEmitter<string>;

  creditControlSummary: CreditControlSummary = {
    expected: 0,
    paid: 0,
    overdue: 0,
    holiday: 0,
  };
  protected creditControlResults = this.fireFuncton.httpsCallable(
    'clientCreditControl',
  );

  constructor(protected fireFuncton: AngularFireFunctions) {}

  ngOnInit(): void {
    this.loadCreditControls();
    this.paymentScheduleUpdated.subscribe(() => this.loadCreditControls());
  }

  private loadCreditControls() {
    this.creditControlResults({
      clientID: this.clientId,
    }).subscribe((result) => {
      this.creditControlSummary = result;
    });
  }
}

class CreditControlSummary {
  expected: number;
  paid: number;
  overdue: number;
  holiday: number;
}
