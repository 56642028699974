import { BankingAccount } from '../accounts/banking-account';
import { Case } from '../../../cases/case';

export class BankingAccountItem {
  public id?: string;
  public account: BankingAccount;
  public description: string;
  public type: string;
  public value: number;
  public reconciledValue: number;
  public reconciled: boolean;
  public relatedTransaction?: BankingAccountItem;
  public relatedCase?: Case;
  public note?: string;
  public createdAt: string;
  public reconciliations: any[];
  public otherReconciliations: any[];
  public isChecking?: boolean;
  public isAllowed?: boolean;
}
