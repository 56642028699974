import { Component, Input, OnInit } from '@angular/core';

import { NotificationService } from '../../shared/notification.service';
import { SelectComparisons } from '../../shared/pipes/select.comparisons';
import { ComplaintStatus } from '../complaint-status';
import { ComplaintStatusService } from '../complaint-status.service';

@Component({
  selector: 'app-complaint-status-select',
  templateUrl: 'select.component.html',
})
export class ComplaintStatusSelectComponent extends SelectComparisons
  implements OnInit {
  /**
   * The model that we will be adding the complaintStatus to.
   */
  @Input() model: any;

  /**
   * Do we require this field in the main form it is
   * included in?
   */
  @Input() required: boolean;

  /**
   * A list of all the complaint-statuses currently loaded.
   *
   * @type {ComplaintStatus[]}
   */
  public complaintStatuses: ComplaintStatus[] = [];

  /**
   * Are we currently performing a loading action?
   *
   * @type {boolean}
   */
  public isLoading: boolean = true;

  /**
   * Load up all our required services and subscribe to every observable we need.
   *
   * @param {ComplaintStatusService} complaintStatusService
   * @param {NotificationService} notificationService
   */
  constructor(
    private complaintStatusService: ComplaintStatusService,
    private notificationService: NotificationService,
  ) {
    super();
    this.complaintStatusService.loading.subscribe(isLoading => {
      this.isLoading = isLoading;
    });
  }

  /**
   * When the component is initialised we set it to load
   * page one of the complaintStatus list.
   */
  public ngOnInit(): void {
    this.loadComplaintStatuses(1, 10000);
  }

  /**
   * Loads a list of complaint-statuses using the given per page limit.
   *
   * @param {number} page The current page we require.
   * @param {number} limit The number of results per page.
   */
  public loadComplaintStatuses(page?: number, limit?: number): void {
    const listComplaintStatuses = this.complaintStatusService
      .listComplaintStatuses(page, limit)
      .subscribe((complaintStatuses: ComplaintStatus[]) => {
        this.complaintStatuses = complaintStatuses;
        listComplaintStatuses.unsubscribe();
      });
  }
}
