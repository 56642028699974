import { Injectable } from '@angular/core';
import { ResidentialStatus } from './residential-status';
import { Observable } from 'rxjs';

import { HttpRequestService } from '../shared/http/http-request.service';

@Injectable()
export class ResidentialStatusService extends HttpRequestService {
  /**
   * The endpoint that we call to get the residentialStatus information.
   *
   * @type {string}
   */
  private readonly endpoint: string = 'residential-statuses';

  /**
   * Brings back a list of all accessible residential-statuses.
   *
   * @param {number} page The page number we are currently on.
   * @param {number} limit The number of residential-statuses to display per page.
   * @returns {Observable<ResidentialStatus[]>}
   */
  public listResidentialStatuses(
    page?: number,
    limit?: number,
  ): Observable<ResidentialStatus[]> {
    return this.list(this.endpoint, page, undefined, limit);
  }

  /**
   * Finds details of the residentialStatus from the given ID.
   *
   * @param {string} residentialStatusId The ID of the residentialStatus we require details for.
   * @returns {Observable<ResidentialStatus>}
   */
  public findResidentialStatus(
    residentialStatusId: string,
  ): Observable<ResidentialStatus> {
    return this.findById(this.endpoint, residentialStatusId);
  }

  /**
   * Creates a new residentialStatus from the given ResidentialStatus object.
   *
   * @param {ResidentialStatus} newResidentialStatus The details of the residentialStatus to be created.
   * @returns {Observable<ResidentialStatus | boolean>}
   */
  public createResidentialStatus(
    newResidentialStatus: ResidentialStatus,
  ): Observable<ResidentialStatus | boolean> {
    return this.create(this.endpoint, newResidentialStatus);
  }

  /**
   * Updates a residentialStatus with the given residentialStatus object for the given residentialStatus ID.
   *
   * @param {string} residentialStatusId The ID of the residentialStatus needing updating.
   * @param {ResidentialStatus} newDetails The new residentialStatus object to update with.
   * @returns {Observable<ResidentialStatus | boolean>}
   */
  public updateResidentialStatus(
    residentialStatusId: string,
    newDetails: ResidentialStatus,
  ): Observable<ResidentialStatus | boolean> {
    return this.update(this.endpoint, residentialStatusId, newDetails);
  }

  /**
   * Deletes a residentialStatus based on the given residentialStatus ID.
   *
   * @param {string} residentialStatusId The ID of the residentialStatus needing deleting.
   * @returns {Observable<boolean>}
   */
  public deleteResidentialStatus(
    residentialStatusId: string,
  ): Observable<boolean> {
    return this.delete(this.endpoint, residentialStatusId);
  }
}
