import {
  AfterViewInit,
  Component,
  ContentChildren,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  QueryList,
  ViewChild,
} from '@angular/core';
import {NgForm, NgModel} from '@angular/forms';

import {LogService} from '../log.service';

declare let $: any;

@Component({
  selector: 'app-create-modal',
  templateUrl: './create-modal.component.html',
})
export class CreateModalComponent implements OnInit, AfterViewInit {
  @ContentChildren(NgModel) public models: QueryList<NgModel>;
  @ViewChild(NgForm) public form: NgForm;

  @Output() confirm = new EventEmitter<any>();
  @Output() cancel = new EventEmitter();

  @Input() type: string = undefined;
  @Input() itemCreated: EventEmitter<boolean>;
  @Input() disabled: boolean = false;

  @ViewChild('createModalComponent') modal: ElementRef;

  public id: any = undefined;

  public ngOnInit(): void {
    this.itemCreated.subscribe(result => {
      this.close();
    });
  }

  public ngAfterViewInit(): void {
    const ngContentModels = this.models.toArray();
    ngContentModels.forEach(model => {
      this.form.addControl(model);
    });
  }

  public confirmDialog(id: any) {
    this.disabled = false;
    this.id = id;
    this.open();
  }

  public confirmCreation(event, formResults) {
    this.disabled = true;
    event.preventDefault();
    LogService.info(formResults);
    formResults.value.given_id = this.id;
    this.confirm.next(formResults.value);
  }

  public cancelCreation() {
    this.disabled = false;
    this.cancel.next();
    LogService.info(`Cancelling Creation of item with ID ${this.id}`);
    this.close();
  }

  public open() {
    $(this.modal.nativeElement).modal('show');
  }

  public close() {
    $(this.modal.nativeElement).modal('hide');
  }
}
