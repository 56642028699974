<div class="form-group">
  <label for="domain" class="col-sm-2 control-label">Domain</label>
  <div class="col-sm-10">
    <app-domain-select
      [model]="chartOfAccounts"
      [required]="false"
      id="domain"
    ></app-domain-select>
  </div>
</div>

<div class="form-group">
  <label for="account-group" class="col-sm-2 control-label"
    >Account Group</label
  >
  <div class="col-sm-10">
    <app-account-group-select
      [model]="chartOfAccounts"
      [required]="false"
      id="account-group"
    ></app-account-group-select>
  </div>
</div>

<div class="form-group">
  <label for="account-type" class="col-sm-2 control-label">Account Type</label>
  <div class="col-sm-10">
    <app-account-type-select
      [model]="chartOfAccounts"
      [required]="false"
      id="account-type"
    ></app-account-type-select>
  </div>
</div>

<div class="form-group">
  <label for="supplier" class="col-sm-2 control-label">Supplier</label>
  <div class="col-sm-10">
    <app-supplier-select
      [model]="chartOfAccounts"
      [required]="false"
      id="supplier"
    ></app-supplier-select>
  </div>
</div>

<div class="form-group">
  <label for="name" class="col-sm-2 control-label">Name</label>
  <div class="col-sm-10">
    <input
      type="text"
      class="form-control"
      name="name"
      id="name"
      placeholder="Chart of Accounts Name"
      [(ngModel)]="chartOfAccounts.name"
      required
    />
  </div>
</div>

<div class="form-group">
  <label for="code" class="col-sm-2 control-label">Code</label>
  <div class="col-sm-10">
    <input
      type="text"
      class="form-control"
      name="code"
      id="code"
      placeholder="Domain Code"
      [(ngModel)]="chartOfAccounts.code"
      required
    />
  </div>
</div>

<div class="form-group">
  <label for="description" class="col-sm-2 control-label">Description</label>
  <div class="col-sm-10">
    <textarea
      class="form-control"
      name="description"
      id="description"
      placeholder="Description"
      [(ngModel)]="chartOfAccounts.description"
    ></textarea>
  </div>
</div>

<div class="form-group">
  <label for="included-in-comparison" class="col-sm-2 control-label"
    >Included in Comparison</label
  >
  <div class="col-sm-10">
    <input
      type="checkbox"
      (click)="toggleItem('includedInComparison')"
      [checked]="chartOfAccounts.includedInComparison"
      class="form-control"
      name="included-in-comparison"
      id="included-in-comparison"
    />
  </div>
</div>

<div class="form-group">
  <label for="invoice-required" class="col-sm-2 control-label"
    >Invoice Required</label
  >
  <div class="col-sm-10">
    <input
      type="checkbox"
      (click)="toggleItem('invoiceRequired')"
      [checked]="chartOfAccounts.invoiceRequired"
      class="form-control"
      name="invoice-required"
      id="invoice-required"
    />
  </div>
</div>

<div class="form-group">
  <label for="estimated" class="col-sm-2 control-label">Estimated Value</label>
  <div class="col-sm-10">
    <input
      type="checkbox"
      (click)="toggleItem('estimated')"
      [checked]="chartOfAccounts.estimated"
      class="form-control"
      name="estimated"
      id="estimated"
    />
  </div>
</div>

<div class="form-group">
  <label for="vat-payable" class="col-sm-2 control-label"
    >VAT Payable (%)</label
  >
  <div class="col-sm-10">
    <input
      type="number"
      min="0"
      max="100"
      class="form-control"
      name="vat-payable"
      id="vat-payable"
      [(ngModel)]="chartOfAccounts.vatPayable"
    />
  </div>
</div>

<div class="form-group">
  <label for="vat-reconcilable" class="col-sm-2 control-label"
    >VAT Reconcilable (%)</label
  >
  <div class="col-sm-10">
    <input
      type="number"
      min="0"
      max="100"
      class="form-control"
      name="vat-reconcilable"
      id="vat-reconcilable"
      [(ngModel)]="chartOfAccounts.vatReconcilable"
    />
  </div>
</div>

<div class="form-group">
  <label for="account" class="col-sm-2 control-label">Account</label>
  <div class="col-sm-10">
    <app-financial-accounts-select
      [model]="chartOfAccounts"
      [required]="true"
      id="account"
    ></app-financial-accounts-select>
  </div>
</div>

<div class="form-group">
  <div class="col-sm-offset-2 col-sm-10 text-right">
    <button type="submit" class="btn btn-success">Save</button>
  </div>
</div>
