import {Component, EventEmitter, Output, ViewChild} from '@angular/core';

import {CaseProperty} from '../case-property';
import {CasePropertyService} from '../case-property.service';

@Component({
  selector: 'app-case-property-create',
  templateUrl: 'create.component.html',
})
export class CasePropertyCreateComponent {
  @ViewChild('staticModal') staticModal;

  @Output() completed = new EventEmitter<boolean>();

  public disabled: boolean = false;

  public model: CaseProperty = new CaseProperty();

  private givenCaseId: string;

  constructor(private readonly casePropertyService: CasePropertyService) {}

  public get caseId(): string {
    return this.givenCaseId;
  }

  public open(caseId: string): void {
    this.givenCaseId = caseId;
    this.staticModal.show();
  }

  public close() {
    this.workCompleted();
  }

  public confirmCreate($event) {
    $event.preventDefault();
    this.disabled = true;
    this.casePropertyService
      .createCaseProperty(this.model, { parameters: { id: this.caseId } })
      .subscribe(() => {
        this.workCompleted();
      });
  }

  private workCompleted() {
    this.model = new CaseProperty();
    this.disabled = false;
    this.staticModal.hide();
    this.completed.next(true);
  }
}
