import { Address } from '../shared/address/address';
import { ContactDetails } from '../shared/contact-details/contact-details';
import { Country } from '../countries/country';

export class Domain implements Address, ContactDetails {
  public id?: string;
  public name: string;
  public address1: string;
  public address2: string;
  public address3: string;
  public town: string;
  public county: string;
  public postalCode: string;
  public country: Country;
  public landlineNumber: string;
  public mobileNumber: string;
  public faxNumber: string;
  public webAddress: string;
  public emailAddress: string;
  public avatar?: string;
  public code: string;
  public commissionMultiplier: number;
}
