import { RouterModule, Routes } from '@angular/router';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AuthGuard } from '../auth/auth.guard';
import { ChiefTimelineEmbedComponent } from './embed/embed.component';
import { SharedModule } from '../shared/shared.module';

const routes: Routes = [
  {
    path: 'chief-timeline',
    component: ChiefTimelineEmbedComponent,
    canActivate: [AuthGuard],
  },
];

@NgModule({
  declarations: [ChiefTimelineEmbedComponent],
  imports: [
    BrowserModule,
    RouterModule,
    SharedModule,
    RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' }),
  ],
  exports: [RouterModule],
  providers: [AuthGuard],
})
export class ChiefTimelineModule {}
