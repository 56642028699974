<div *ngIf="auditResults" class="alert alert-info">
    <i class="fa fa-check"></i>
    {{ auditResults?.length }} Modifications have been made to this Payment Profile - <a
        (click)="openModal(auditPaymentProfileModal)"
        href="javascript:">View
    Modifications</a>
</div>

<ng-template #auditPaymentProfileModal>
    <div class="modal-header">
        <h4 class="modal-title pull-left">Audit Payment Schedule</h4>
        <button (click)="modalRef.hide()" aria-label="Close" class="close pull-right" type="button">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <div class="row">
            <div class="col-md-3">
                <label>Select Profile:</label>
            </div>
            <div class="col-md-9">
                <select [(ngModel)]="viewingProfile">
                    <option *ngFor="let profile of auditResults" [ngValue]="profile">Active
                        from {{ profile.created_at | date:'dd/MM/yyyy'}}
                        to {{ profile.deleted_at | date:'dd/MM/yyyy'}}</option>
                </select>
            </div>
        </div>

        <div class="row" style="max-height: 400px; overflow-x: auto; margin-top: 20px;">
            <table *ngIf="viewingProfile" class="table">
                <tr>
                    <th>Expected Date</th>
                    <th>Value</th>
                    <th>Status</th>
                    <th>Received</th>
                    <th class="text-center">Payment Log</th>
                </tr>

                <tr
                        *ngFor="let item of viewingProfile.schedule"
                        [ngClass]="{
      'bg-success': item.status === 'paid',
      'bg-warning': item.status === 'partial',
      'bg-info': item.status === 'holiday'
    }"
                >
                    <th>{{ item.expected_date | date: 'dd/MM/yyyy' }}</th>
                    <td>{{ item.expected_value / 100 | toCurrency }}</td>
                    <td>{{ item.status }}</td>
                    <td>
                        {{ (item.total_received ? item.total_received : 0) / 100 | toCurrency }}
                    </td>
                    <td>
                        <table class="table table-hover">
                            <tbody>
                            <tr *ngFor="let payment of item.payment_log">
                                <td>{{ payment.textDate | date: 'dd/MM/yyyy' }}</td>
                                <td>{{ payment.value / 100 | toCurrency }}</td>
                                <td>{{ payment.prettyTypes[payment.type] }}</td>
                            </tr>
                            </tbody>
                        </table>
                    </td>
                </tr>
            </table>
        </div>
    </div>
</ng-template>
