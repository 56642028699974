import { Component, Input, OnInit } from '@angular/core';
import { DomainService } from '../domain.service';
import { Domain } from '../domain';
import { NotificationService } from '../../shared/notification.service';
import { SelectComparisons } from '../../shared/pipes/select.comparisons';

@Component({
  selector: 'app-domain-select',
  templateUrl: 'select.component.html',
})
export class DomainSelectComponent extends SelectComparisons implements OnInit {
  /**
   * The model that we will be adding the domain to.
   */
  @Input() model: any;

  /**
   * Do we require this field in the main form it is
   * included in?
   */
  @Input() required: boolean;

  /**
   * A list of all the domains currently loaded.
   *
   * @type {Domain[]}
   */
  public domains: Domain[] = [];

  /**
   * Are we currently performing a loading action?
   *
   * @type {boolean}
   */
  public isLoading: boolean = true;

  /**
   * Load up all our required services and subscribe to every observable we need.
   *
   * @param {DomainService} domainService
   * @param {NotificationService} notificationService
   */
  constructor(
    private domainService: DomainService,
    private notificationService: NotificationService,
  ) {
    super();
    this.domainService.loading.subscribe(isLoading => {
      this.isLoading = isLoading;
    });
  }

  /**
   * When the component is initialised we set it to load
   * page one of the domain list.
   */
  public ngOnInit(): void {
    this.loadDomains(1, 10000);
  }

  /**
   * Loads a list of domains using the given per page limit.
   *
   * @param {number} page The current page we require.
   * @param {number} limit The number of results per page.
   */
  public loadDomains(page?: number, limit?: number): void {
    const listDomains = this.domainService
      .listDomains(page, limit)
      .subscribe((domains: Domain[]) => {
        this.domains = domains;
        listDomains.unsubscribe();
      });
  }
}
