import {
  Component,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import { Address } from '../../../../shared/address/address';
import { NotificationService } from '../../../../shared/notification.service';
import { CasePersonAddressService } from '../case-person-address-service';

@Component({
  selector: 'app-case-person-addresses-edit',
  templateUrl: 'edit.component.html',
})
export class CasePersonAddressEditComponent {
  @ViewChild('staticModal') staticModal;

  @Output() completed = new EventEmitter<boolean>();

  disabled: boolean = false;

  address: Address;

  @Input() personId: string;
  @Input() caseId: string;

  constructor(
    protected readonly casePersonAddressService: CasePersonAddressService,
    protected readonly notificationService: NotificationService,
  ) {}

  public open(address: Address) {
    this.address = { ...address };
    this.staticModal.show();
  }

  public close() {
    this.workCompleted();
  }

  public confirmEdit($event) {
    $event.preventDefault();
    this.disabled = true;
    const updateAddress = this.casePersonAddressService
      .updateAddress(this.address.id, this.address, this.caseId, this.personId)
      .subscribe(
        (result: Address) => {
          this.notificationService.success(
            `Address Updated`,
            `The address has been updated with the specified changes.`,
            5,
          );
          updateAddress.unsubscribe();
          this.workCompleted();
          this.completed.next(true);
        },
        (err) => {
          this.notificationService.error(
            `Address Not Found`,
            err.error.message,
            10,
          );
          updateAddress.unsubscribe();
          this.workCompleted();
          this.completed.next(true);
        },
      );
  }

  private workCompleted() {
    this.address = undefined;
    this.disabled = false;
    this.staticModal.hide();
  }
}
