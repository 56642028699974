import {HttpClientModule} from '@angular/common/http';
import {NgModule} from '@angular/core';
import {FormsModule} from '@angular/forms';
import {BrowserModule} from '@angular/platform-browser';
import {RouterModule, Routes} from '@angular/router';
import {BsDropdownModule} from 'ngx-bootstrap/dropdown';
import {ModalModule} from 'ngx-bootstrap/modal';
import {PopoverModule} from 'ngx-bootstrap/popover';
import {TooltipModule} from 'ngx-bootstrap/tooltip';
import {QuillModule} from 'ngx-quill';

import {AuthGuard} from '../auth/auth.guard';
import {SharedModule} from '../shared/shared.module';
import {CaseAnnualRoutingModule} from './annuals/case-annual-routing.module';
import {CaseAssetRoutingModule} from './assets/case-asset-routing.module';
import {CaseBreachRoutingModule} from './breaches/case-breach-routing.module';
import {CaseCalendarRoutingModule} from './calendars/case-calendar-routing.module';
import {CaseService} from './case.service';
import {CaseChairmansReportRoutingModule} from './chairmans-report/chairmans-report-routing.module';
import {CaseChartOfAccountsRoutingModule} from './chart-of-accounts/case-chart-of-accounts-routing.module';
import {CaseCommunicateRoutingModule} from './communicate/case-communicate-routing.module';
import {CaseCommunicateSendComponent} from './communicate/send/send.component';
import {CaseComplaintRoutingModule} from './complaints/case-complaint-routing.module';
import {CaseCreateComponent} from './create/create.component';
import {CaseCreditorVoteRoutingModule} from './creditor-votes/case-creditor-vote-routing.module';
import {CaseCreditorRoutingModule} from './creditors/case-creditor-routing.module';
import {CaseCreditorDistributionsComponent} from './creditors/distributions/distributions.component';
import {DetailedTransactionsModule} from './detailed-transactions/detailed-transactions.module';
import {CaseDocumentRoutingModule} from './documents/case-document-routing.module';
import {CaseEditComponent} from './edit/edit.component';
import {CaseExpenditureRoutingModule} from './expenditures/case-expenditure-routing.module';
import {CaseFeeComparisonRoutingModule} from './fee-comparison/fee-comparison-routing.module';
import {CaseFinancialSummaryRoutingModule} from './financial-summary/case-financial-summary-routing.module';
import {CaseFormComponent} from './form/form.component';
import {CaseIncomeRoutingModule} from './incomes/case-income-routing.module';
import {CaseInvoiceRoutingModule} from './invoices/case-invoice-routing.module';
import {CaseListComponent} from './list/list.component';
import {CaseNoteRoutingModule} from './notes/case-note-routing.module';
import {CaseNoteService} from './notes/case-note.service';
import {CasePaymentScheduleRoutingModule} from './payment-schedules/case-payment-schedule-routing.module';
import {CasePropertyRoutingModule} from './properties/case-property-routing.module';
import {CaseSearchListComponent} from './search-list/list.component';
import {CaseStatusRoutingModule} from './statuses/case-status-routing.module';
import {CaseStatusChangeComponent} from './statuses/change/change.component';
import {CaseUserRoutingModule} from './users/case-user-routing.module';
import {CaseVehicleRoutingModule} from './vehicles/case-vehicle-routing.module';
import {CaseViewComponent} from './view/view.component';

const routes: Routes = [
  { path: 'cases', component: CaseListComponent, canActivate: [AuthGuard] },
  {
    path: 'cases/search/:term',
    component: CaseSearchListComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'cases/create',
    component: CaseCreateComponent,
    canActivate: [AuthGuard],
  },
  { path: 'cases/:id', component: CaseViewComponent, canActivate: [AuthGuard] },
  {
    path: 'cases/:id/edit',
    component: CaseEditComponent,
    canActivate: [AuthGuard],
  },
];

@NgModule({
  declarations: [
    CaseListComponent,
    CaseCreateComponent,
    CaseFormComponent,
    CaseEditComponent,
    CaseViewComponent,
    CaseStatusChangeComponent,
    CaseSearchListComponent,
    CaseCommunicateSendComponent,
    CaseCreditorDistributionsComponent,
  ],
  imports: [
    ModalModule,
    QuillModule,
    PopoverModule.forRoot(),
    BrowserModule,
    RouterModule,
    FormsModule,
    HttpClientModule,
    SharedModule,
    DetailedTransactionsModule,
    CaseCommunicateRoutingModule,
    CaseNoteRoutingModule,
    CaseDocumentRoutingModule,
    CaseCreditorRoutingModule,
    CaseCreditorVoteRoutingModule,
    CaseVehicleRoutingModule,
    CasePropertyRoutingModule,
    CaseAssetRoutingModule,
    CaseCalendarRoutingModule,
    CaseStatusRoutingModule,
    CaseUserRoutingModule,
    CaseIncomeRoutingModule,
    CaseExpenditureRoutingModule,
    CaseFeeComparisonRoutingModule,
    CasePaymentScheduleRoutingModule,
    CaseChartOfAccountsRoutingModule,
    CaseFinancialSummaryRoutingModule,
    CaseComplaintRoutingModule,
    CaseBreachRoutingModule,
    CaseAnnualRoutingModule,
    CaseInvoiceRoutingModule,
    CaseChairmansReportRoutingModule,
    RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' }),
    TooltipModule.forRoot(),
    BsDropdownModule.forRoot(),
  ],
  exports: [RouterModule],
  providers: [AuthGuard, CaseService, CaseNoteService],
})
export class CaseRoutingModule {}
