import { Injectable } from '@angular/core';
import { MaritalStatus } from './marital-status';
import { Observable } from 'rxjs';

import { HttpRequestService } from '../shared/http/http-request.service';

@Injectable()
export class MaritalStatusService extends HttpRequestService {
  /**
   * The endpoint that we call to get the maritalStatus information.
   *
   * @type {string}
   */
  private readonly endpoint: string = 'marital-statuses';

  /**
   * Brings back a list of all accessible marital-statuses.
   *
   * @param {number} page The page number we are currently on.
   * @param {number} limit The number of marital-statuses to display per page.
   * @returns {Observable<MaritalStatus[]>}
   */
  public listMaritalStatuses(
    page?: number,
    limit?: number,
  ): Observable<MaritalStatus[]> {
    return this.list(this.endpoint, page, undefined, limit);
  }

  /**
   * Finds details of the maritalStatus from the given ID.
   *
   * @param {string} maritalStatusId The ID of the maritalStatus we require details for.
   * @returns {Observable<MaritalStatus>}
   */
  public findMaritalStatus(maritalStatusId: string): Observable<MaritalStatus> {
    return this.findById(this.endpoint, maritalStatusId);
  }

  /**
   * Creates a new maritalStatus from the given MaritalStatus object.
   *
   * @param {MaritalStatus} newMaritalStatus The details of the maritalStatus to be created.
   * @returns {Observable<MaritalStatus | boolean>}
   */
  public createMaritalStatus(
    newMaritalStatus: MaritalStatus,
  ): Observable<MaritalStatus | boolean> {
    return this.create(this.endpoint, newMaritalStatus);
  }

  /**
   * Updates a maritalStatus with the given maritalStatus object for the given maritalStatus ID.
   *
   * @param {string} maritalStatusId The ID of the maritalStatus needing updating.
   * @param {MaritalStatus} newDetails The new maritalStatus object to update with.
   * @returns {Observable<MaritalStatus | boolean>}
   */
  public updateMaritalStatus(
    maritalStatusId: string,
    newDetails: MaritalStatus,
  ): Observable<MaritalStatus | boolean> {
    return this.update(this.endpoint, maritalStatusId, newDetails);
  }

  /**
   * Deletes a maritalStatus based on the given maritalStatus ID.
   *
   * @param {string} maritalStatusId The ID of the maritalStatus needing deleting.
   * @returns {Observable<boolean>}
   */
  public deleteMaritalStatus(maritalStatusId: string): Observable<boolean> {
    return this.delete(this.endpoint, maritalStatusId);
  }
}
