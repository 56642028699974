import { RouterModule, Routes } from '@angular/router';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule } from '@angular/common/http';
import { IntroducerListComponent } from './list/list.component';
import { AuthGuard } from '../auth/auth.guard';
import { IntroducerService } from './introducer.service';
import { SharedModule } from '../shared/shared.module';
import { IntroducerCreateComponent } from './create/create.component';
import { IntroducerFormComponent } from './form/form.component';
import { IntroducerEditComponent } from './edit/edit.component';

const routes: Routes = [
  {
    path: 'introducers',
    component: IntroducerListComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'introducers/create',
    component: IntroducerCreateComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'introducers/:id/edit',
    component: IntroducerEditComponent,
    canActivate: [AuthGuard],
  },
];

@NgModule({
  declarations: [
    IntroducerListComponent,
    IntroducerCreateComponent,
    IntroducerFormComponent,
    IntroducerEditComponent,
  ],
  imports: [
    BrowserModule,
    RouterModule,
    FormsModule,
    HttpClientModule,
    SharedModule,
    RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' }),
  ],
  exports: [RouterModule],
  providers: [AuthGuard, IntroducerService],
})
export class IntroducerRoutingModule {}
