import {
  Component,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';

import { AuthService } from '../../../../auth/auth.service';
import { HttpRequestResponse } from '../../../../shared/http/http-request-response';
import { NotificationService } from '../../../../shared/notification.service';
import { environment } from '../../../../../environments/environment';

@Component({
  selector: 'app-case-extend-duration',
  templateUrl: 'extend-duration.component.html',
})
export class ExtendDurationComponent {
  @ViewChild('staticModal') staticModal: any;

  @Input() caseId?: string;

  @Output() completed = new EventEmitter<boolean>();

  /**
   * The number of months to increase the iva by.
   */
  public extensionDuration?: number;

  /**
   * The reason the iva is being extended.
   */
  public extensionReason?: string;

  public disabled = false;

  private readonly httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + this.authService.getToken(),
    }),
  };

  constructor(
    protected readonly http: HttpClient,
    protected readonly authService: AuthService,
    protected readonly notificationService: NotificationService,
  ) {}

  public open() {
    this.staticModal.show();
  }

  public close() {
    this.workCompleted();
  }

  public submit() {
    this.disabled = true;
    this.extendDuration();
  }

  private workCompleted() {
    this.disabled = false;
    this.staticModal.hide();
  }

  private extendDuration() {
    this.http
      .put<HttpRequestResponse>(
        `${environment.endpoint}cases/${this.caseId}/extend-duration`,
        {
          extensionDuration: this.extensionDuration,
          reasonForExtension: this.extensionReason,
        },
        this.httpOptions,
      )
      .map((data: HttpRequestResponse) => data.data)
      .subscribe(
        (item) => {
          this.notificationService.success(
            'Duration extended',
            `Duration of case with id: ${this.caseId} has been increased by ${this.extensionDuration} months.`,
            5,
          );
          this.completed.emit(true);
          this.workCompleted();
        },
        (err) => {
          this.notificationService.error(
            'Error Extending Duration.',
            err.message,
            5,
          );
          this.completed.emit(false);
          this.disabled = false;
        },
      );
  }
}
