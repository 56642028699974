<div class="row">
  <div class="col-sm-12">
    <section class="panel">
      <app-page-title [title]="pageTitle"></app-page-title>

      <table class="table">
        <thead>
          <tr>
            <th>File</th>
            <th>Case</th>
            <th>Case Status</th>
            <th>Document Type</th>
            <th>Creditor</th>
            <th width="400">Note</th>
            <th>&nbsp;</th>
          </tr>
        </thead>
        <tbody>
          <ng-container *ngFor="let item of items | async">
            <tr
              *ngIf="
                authService.hasPermission('reports.paperless-office.approve') ||
                item.approvedAt
              "
              [class.bg-danger]="!item.approvedAt"
            >
              <td>
                <a
                  *ngIf="item && item.filename"
                  [href]="
                    clickyPdfFileUrl(
                      'https://s3.eu-west-2.amazonaws.com/cdn.portal.chief.gi/' +
                        item.filename
                    )
                  "
                  target="_blank"
                >
                  <i class="fa fa-file"></i>
                </a>
                <strong *ngIf="item.approvedAt" style="margin-left: 10px">{{
                  item?.approvedAt | date: 'dd/MM/yyy HH:mm'
                }}</strong>
                <strong *ngIf="!item.approvedAt" style="margin-left: 10px">{{
                  item?.created | date: 'dd/MM/yyy HH:mm'
                }}</strong>
              </td>
              <td>
                <ng-container *ngIf="item.case">
                  {{ item?.case?.unique_reference }} -
                  {{ item?.case?.case_name }}
                </ng-container>
              </td>
              <td>
                {{ item?.case?.status }}
              </td>
              <td>{{ item?.document?.name }}</td>
              <td>{{ item?.caseCreditor?.creditor?.name }}</td>
              <td>{{ item?.internalNote }}</td>
              <td class="text-right">
                <button
                  (click)="approveItem(item)"
                  *ngIf="
                    authService.hasPermission(
                      'reports.paperless-office.approve'
                    ) && !item.approvedAt
                  "
                  class="btn btn-default btn-sm"
                  style="margin-right: 20px; color: black !important"
                >
                  <i class="fa fa-files-o"></i>
                </button>

                <div
                  aria-label="..."
                  class="btn-group btn-group-sm"
                  role="group"
                >
                  <a
                    (click)="confirmWithItem(item)"
                    class="btn btn-success btn-sm"
                    ><i class="fa fa-pencil"></i
                  ></a>
                  <a
                    *ngIf="item.case"
                    [routerLink]="['/cases', item.case.id]"
                    class="btn btn-primary btn-sm"
                    target="_blank"
                    ><i class="fa fa-eye"></i
                  ></a>
                  <button
                    (click)="confirmAndSend(item)"
                    *ngIf="item.case && item.document"
                    class="btn btn-warning btn-sm"
                    type="button"
                  >
                    <i class="fa fa-check"></i>
                  </button>
                </div>
                <button
                  (click)="deleteItem(item)"
                  class="btn btn-danger btn-sm"
                  style="margin-left: 20px"
                >
                  <i class="fa fa-trash"></i>
                </button>
              </td>
            </tr>
          </ng-container>
        </tbody>
      </table>
    </section>
  </div>
</div>

<ng-template #embedPdfFileModalTemplate bsModal>
  <div class="modal-header">
    <h4 class="modal-title pull-left">View Document</h4>
    <button
      (click)="embedPdfFileModalHide()"
      aria-label="Close"
      class="close pull-right"
      type="button"
    >
      <span aria-hidden="true">&times;</span>
    </button>
  </div>

  <div class="modal-body">
    <div *ngIf="embedPdfFileUrl" class="document-holder">
      <iframe
        [src]="embedPdfFileUrl"
        frameborder="0"
        height="475"
        type="application/pdf"
        width="100%"
      ></iframe>
    </div>
  </div>
</ng-template>

<ng-template #confirmDocumentModalTemplate bsModal>
  <div class="modal-header">
    <h4 class="modal-title pull-left">Modal</h4>
    <button
      (click)="confirmDocumentModalHide()"
      aria-label="Close"
      class="close pull-right"
      type="button"
    >
      <span aria-hidden="true">&times;</span>
    </button>
  </div>

  <div class="modal-body">
    <div class="form-group clearfix">
      <label class="col-sm-3 control-label" for="document-type"
        >Document Type</label
      >
      <div class="col-sm-8">
        <app-document-select
          [model]="itemToConfirm"
          id="document-type"
        ></app-document-select>
      </div>
      <div class="col-sm-1">
        <a
          *ngIf="itemToConfirm && itemToConfirm.filename"
          [href]="
            clickyPdfFileUrl(
              'https://s3.eu-west-2.amazonaws.com/cdn.portal.chief.gi/' +
                itemToConfirm.filename
            )
          "
          class="btn btn-default"
          target="_blank"
        >
          <i class="fa fa-eye"></i>
        </a>
      </div>
    </div>

    <div class="form-group clearfix">
      <label class="col-sm-3 control-label" for="client-search"
        >Find Case</label
      >
      <div *ngIf="itemToConfirm && itemToConfirm.case" class="col-sm-6">
        {{ itemToConfirm?.case?.unique_reference }} -
        {{ itemToConfirm?.case?.case_name }} ({{ itemToConfirm?.case?.status }})
      </div>
      <div
        *ngIf="itemToConfirm && itemToConfirm.case"
        class="col-sm-3 text-right"
      >
        <div aria-label="..." class="btn-group btn-group-xs" role="group">
          <a
            *ngIf="itemToConfirm && itemToConfirm.case"
            [routerLink]="['/cases', itemToConfirm.case.id]"
            class="btn btn-xs btn-primary"
            target="_blank"
            ><i class="fa fa-eye"></i
          ></a>
          <button
            (click)="removeSelectedCase()"
            *ngIf="itemToConfirm && itemToConfirm.case"
            class="btn btn-xs btn-danger"
            type="button"
          >
            <i class="fa fa-trash"></i>
          </button>
        </div>
      </div>

      <div *ngIf="itemToConfirm && !itemToConfirm.case" class="col-sm-6">
        <input
          [(ngModel)]="searchTerm"
          class="form-control"
          id="client-search"
          placeholder="Search..."
          type="text"
        />
      </div>
      <button
        (click)="performSearch(searchTerm)"
        *ngIf="itemToConfirm && !itemToConfirm.case"
        class="btn btn-success col-sm-3"
        type="button"
      >
        Search
      </button>
    </div>

    <div
      *ngIf="itemToConfirm && !itemToConfirm.case && foundCases.length > 0"
      class="clearfix"
    >
      <div class="col-sm-3"></div>
      <div class="col-sm-9">
        <table class="table table-striped">
          <thead>
            <tr>
              <th>Client</th>
              <th>Reference</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let case of foundCases">
              <td>
                {{ case.case_name }}<br /><small class="text-muted">{{
                  case.status
                }}</small>
              </td>
              <td>{{ case.unique_reference }}</td>
              <td>
                <button
                  (click)="selectCase(case)"
                  class="btn btn-xs btn-success"
                  type="button"
                >
                  <i class="fa fa-check"></i>
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

    <div
      *ngIf="foundCreditors && foundCreditors.length > 0"
      class="form-group clearfix"
    >
      <label class="col-sm-3 control-label" for="document-type">Creditor</label>
      <div class="col-sm-9">
        <select
          [(ngModel)]="itemToConfirm.caseCreditor"
          [compareWith]="byId"
          class="form-control"
        >
          <option
            *ngFor="let singleCreditor of foundCreditors"
            [ngValue]="singleCreditor"
          >
            {{ singleCreditor.creditor.name }}
          </option>
        </select>
      </div>
    </div>

    <div *ngIf="itemToConfirm" class="form-group clearfix">
      <div class="col-sm-6">
        <h4>Internal Note</h4>

        <textarea
          [(ngModel)]="itemToConfirm.internalNote"
          class="form-control"
          rows="7"
        ></textarea>
        <small class="text-muted"
          ><strong>Please Note: </strong> This note <strong>will not</strong> be
          saved into the case</small
        >
      </div>
      <div class="col-sm-6">
        <h4>Case Note</h4>

        <textarea
          [(ngModel)]="itemToConfirm.caseNote"
          class="form-control"
          rows="7"
        ></textarea>
        <button
          (click)="insertIntoCaseNote(itemToConfirm.caseCreditor.creditor.name)"
          class="btn btn-xs btn-default"
        >
          <i class="fa fa-gbp"></i>
        </button>
      </div>
    </div>
  </div>

  <div class="modal-footer">
    <button
      (click)="confirmDocumentModalHide()"
      class="btn btn-default"
      type="button"
    >
      Cancel
    </button>
    <button
      (click)="saveDocumentSettings()"
      [disabled]="saveButtonDisabled"
      class="btn btn-primary"
      type="button"
    >
      Save
    </button>
  </div>
</ng-template>
