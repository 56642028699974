import { Component, Input } from '@angular/core';
import { StatusCategory } from '../status-category';

@Component({
  selector: 'app-status-category-form',
  templateUrl: 'form.component.html',
})
export class StatusCategoryFormComponent {
  @Input() statusCategory: StatusCategory;
}
