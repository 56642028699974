import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { LogService } from './log.service';

@Injectable()
export class NotificationService {
  constructor(private toastService: ToastrService) {}

  public success(title: string, message: string, timer?: number) {
    LogService.info(title, message);
    this.toastService.success(message, title, {
      timeOut: timer * 1000,
    });
  }

  public warning(title: string, message: string, timer?: number) {
    LogService.warning(title, message);
    this.toastService.warning(message, title, {
      timeOut: timer * 1000,
    });
  }

  public error(title: string, message: string, timer?: number) {
    LogService.error(title, message);
    this.toastService.error(message, title, {
      timeOut: timer * 1000,
    });
  }
}
