import { Component, OnInit } from '@angular/core';
import { IncomeCategoryService } from '../income-category.service';
import { IncomeCategory } from '../income-category';
import { Router } from '@angular/router';
import { NotificationService } from '../../shared/notification.service';

@Component({
  selector: 'app-income-category-create',
  templateUrl: 'create.component.html',
})
export class IncomeCategoryCreateComponent implements OnInit {
  /**
   * Details of the incomeCategory being created.
   */
  public incomeCategory: IncomeCategory;

  /**
   * Are we currently performing a loading action?
   *
   * @type {boolean}
   */
  public isLoading: boolean = false;

  /**
   * Load up all our required services and subscribe to every observable we need.
   *
   * @param {IncomeCategoryService} incomeCategoryService
   * @param {NotificationService} notificationService
   * @param {Router} router
   */
  constructor(
    private incomeCategoryService: IncomeCategoryService,
    private notificationService: NotificationService,
    private router: Router,
  ) {
    this.incomeCategoryService.loading.subscribe(
      result => (this.isLoading = result),
    );
  }

  /**
   * Initialise the component, creating a new blank incomeCategory if required.
   */
  public ngOnInit(): void {
    if (!this.incomeCategory) {
      this.incomeCategory = new IncomeCategory();
    }
  }

  /**
   * Perform the create action which adds the new incomeCategory into the backend.
   */
  public create() {
    const createIncomeCategory = this.incomeCategoryService
      .createIncomeCategory(this.incomeCategory)
      .subscribe(
        (result: IncomeCategory) => {
          this.notificationService.success(
            `IncomeCategory Created`,
            `New incomeCategory ${result.name} has been created with ID of ${
              result.id
            }.`,
            5,
          );
          this.incomeCategory = new IncomeCategory();
          createIncomeCategory.unsubscribe();
          this.router.navigate(['/income-categories']);
        },
        err => {
          this.notificationService.error(
            `Could not create IncomeCategory`,
            err.error.message,
            10,
          );
        },
      );
  }
}
