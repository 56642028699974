import {Component} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';

import {NotificationService} from '../../shared/notification.service';
import {User} from '../user';
import {UserService} from '../user.service';

@Component({
  selector: 'app-user-edit',
  templateUrl: 'edit.component.html',
})
export class UserEditComponent {
  /**
   * Details of the user being edited.
   */
  public user: User;

  /**
   * Are we currently performing a loading action?
   *
   * @type {boolean}
   */
  public isLoading: boolean = false;

  /**
   * Load up all our required services and subscribe to every observable we need.
   *
   * @param {UserService} userService
   * @param {NotificationService} notificationService
   * @param {Router} router
   * @param {ActivatedRoute} route
   */
  constructor(
    private userService: UserService,
    private notificationService: NotificationService,
    private router: Router,
    private route: ActivatedRoute,
  ) {
    this.userService.loading.subscribe(result => (this.isLoading = result));
    this.route.params.subscribe(params => this.loadUser(params.id));
  }

  /**
   * Perform the edit action which send the edited user to the backend.
   */
  public edit() {
    const editUser = this.userService
      .updateUser(this.user.id, this.user)
      .subscribe(
        (result: User) => {
          this.notificationService.success(
            `User Updated`,
            `The user ${result.name} been updated with the specified changes.`,
            5,
          );
          this.user = new User();
          editUser.unsubscribe();
          this.router.navigate(['/users']);
        },
        err => {
          this.notificationService.error(
            `User Not Found`,
            err.error.message,
            10,
          );
          this.router.navigate(['/users']);
        },
      );
  }

  /**
   * Loads the requested user so we can display it and let the user edit the fields.
   *
   * @param {string} id
   */
  private loadUser(id: string) {
    this.userService.findUser(id).subscribe(
      result => {
        this.user = result;
      },
      err => {
        this.notificationService.error(`User Not Found`, err.error.message, 10);
        this.router.navigate(['/users']);
      },
    );
  }
}
