import { Component } from '@angular/core';
import { IncomeTypeService } from '../income-type.service';
import { IncomeType } from '../income-type';
import { ActivatedRoute, Router } from '@angular/router';
import { NotificationService } from '../../../shared/notification.service';

@Component({
  selector: 'app-income-type-edit',
  templateUrl: 'edit.component.html',
})
export class IncomeTypeEditComponent {
  /**
   * Details of the incomeType being edited.
   */
  public incomeType: IncomeType;

  /**
   * Are we currently performing a loading action?
   *
   * @type {boolean}
   */
  public isLoading: boolean = false;

  /**
   * Load up all our required services and subscribe to every observable we need.
   *
   * @param {IncomeTypeService} incomeTypeService
   * @param {NotificationService} notificationService
   * @param {Router} router
   * @param {ActivatedRoute} route
   */
  constructor(
    private incomeTypeService: IncomeTypeService,
    private notificationService: NotificationService,
    private router: Router,
    private route: ActivatedRoute,
  ) {
    this.incomeTypeService.loading.subscribe(
      result => (this.isLoading = result),
    );
    this.route.params.subscribe(params => this.loadIncomeType(params.id));
  }

  /**
   * Perform the edit action which send the edited incomeType to the backend.
   */
  public edit() {
    const editIncomeType = this.incomeTypeService
      .updateIncomeType(this.incomeType.id, this.incomeType)
      .subscribe(
        (result: IncomeType) => {
          this.notificationService.success(
            `Income Type Updated`,
            `The income Type ${
              result.name
            } been updated with the specified changes.`,
            5,
          );
          this.incomeType = new IncomeType();
          editIncomeType.unsubscribe();
          this.router.navigate(['/income-types']);
        },
        err => {
          this.notificationService.error(
            `Income Type Not Found`,
            err.error.message,
            10,
          );
          this.router.navigate(['/income-types']);
        },
      );
  }

  /**
   * Loads the requested incomeType so we can display it and let the user edit the fields.
   *
   * @param {string} id
   */
  private loadIncomeType(id: string) {
    this.incomeTypeService.findIncomeType(id).subscribe(
      result => {
        this.incomeType = result;
      },
      err => {
        this.notificationService.error(
          `Income Type Not Found`,
          err.error.message,
          10,
        );
        this.router.navigate(['/income-types']);
      },
    );
  }
}
