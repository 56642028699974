<section class="panel">
  <header class="panel-heading tab-bg-light" style="line-height: 30px">
    Report Viewer

    <div class="form-inline pull-right">
      <select [(ngModel)]="selectedReport" class="form-control">
        <option [ngValue]="undefined" style="display: none">
          Please select a report to run
        </option>
        <option *ngFor="let report of allPossibleReports" [ngValue]="report">
          {{ report.name }}
        </option>
      </select>

      <label style="margin-left: 10px">From: </label>
      <input
        style="margin-left: 2px"
        [(ngModel)]="startDate"
        class="form-control"
        placeholder="Start Date"
        type="date"
        max="{{ endDate }}"
      />

      <label style="margin-left: 10px">To: </label>
      <input
        style="margin-left: 2px"
        [(ngModel)]="endDate"
        class="form-control"
        placeholder="End Date"
        type="date"
        min="{{ startDate }}"
      />

      <button
        (click)="refreshReport()"
        [disabled]="selectedReport === undefined"
        class="btn btn-success btn"
        style="margin-left: 10px"
        type="button"
      >
        <i class="fa fa-check"></i>
      </button>

      <a
        [href]="reportUrl(true)"
        class="btn btn-danger btn"
        style="margin-left: 10px"
        target="_blank"
      >
        <i class="fa fa-download"></i>
      </a>
    </div>
  </header>

  <div class="panel-body">
    <div class="table-responsive">
      <table class="table table-striped">
        <thead *ngIf="loadedReport && loadedReport.length === 0">
          <tr>
            <th class="text-center">No Results Found</th>
          </tr>
        </thead>

        <thead *ngIf="loadedReport && loadedReport.length > 0">
          <tr>
            <th *ngFor="let header of reportHeaders">{{ header }}</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let item of loadedReport">
            <td *ngFor="let header of reportHeaders">{{ item[header] }}</td>
          </tr>
        </tbody>
      </table>
    </div>
    <div *ngIf="loadedReport" class="text-center">
      <app-pagination
        (pageChanged)="refreshReport($event)"
        [currentPage]="currentPage"
        [totalPages]="totalPages"
      ></app-pagination>
    </div>
  </div>
</section>
