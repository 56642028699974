import { EmploymentStatus } from '../../../employment-statuses/employment-status';

export interface EmploymentStatusState {
  selectList: EmploymentStatus[];
  list: EmploymentStatus[] | undefined;
  selected: EmploymentStatus | undefined;
}

export const INITIAL_EMPLOYMENT_STATUS_STATE: EmploymentStatusState = {
  selectList: [],
  list: undefined,
  selected: undefined,
};
