import useApi, { PaginationOptions } from '../../Hooks/UseApi';

import AddressType from '../../../../../chief-types/src/AddressType';

const useAddressTypes = (autoload: boolean = false) =>
  useApi<AddressType, PaginationOptions>(
    'address-types',
    { limit: 10, page: 1 },
    autoload,
  );

export default useAddressTypes;
