<app-shared-address
  [model]="model"
  [requiredFields]="['address1', 'postalCode']"
></app-shared-address>

<div class="form-group clearfix">
  <label for="reference" class="col-sm-4 control-label">Applicant</label>
  <div class="col-sm-8">
    <app-shared-person-select
      *ngIf="caseId"
      [caseId]="caseId"
      [model]="model"
      [required]="true"
    ></app-shared-person-select>
  </div>
</div>

<div class="form-group clearfix">
  <label for="reference" class="col-sm-4 control-label">Linked Creditor</label>
  <div class="col-sm-8">
    <app-shared-case-creditor-select
      *ngIf="caseId"
      [caseId]="caseId"
      [model]="model"
      [required]="false"
    ></app-shared-case-creditor-select>
  </div>
</div>

<div class="form-group clearfix">
  <label for="reference" class="col-sm-4 control-label">Value</label>
  <div class="col-sm-8">
    <app-shared-currency
      [required]="true"
      [(ngModel)]="model.value"
    ></app-shared-currency>
  </div>
</div>

<div class="form-group clearfix">
  <label for="valuation-source" class="col-sm-4 control-label"
    >Valuation Source</label
  >
  <div class="col-sm-8">
    <select
      class="form-control"
      id="valuation-source"
      [(ngModel)]="model.valuationFrom"
    >
      <option value="a local estate agent">Estate Agent</option>
      <option value="an online valuation">Online Valuation</option>
    </select>
  </div>
</div>

<div class="form-group clearfix">
  <label for="mortgage-type" class="col-sm-4 control-label"
    >Mortgage Type</label
  >
  <div class="col-sm-8">
    <select
      class="form-control"
      id="mortgage-type"
      [(ngModel)]="model.mortgageType"
    >
      <option value="interest only">Interest Only</option>
      <option value="repayment">Repayment</option>
    </select>
  </div>
</div>

<div class="form-group clearfix">
  <label for="reference" class="col-sm-4 control-label">Mortgage Balance</label>
  <div class="col-sm-8">
    <app-shared-currency
      [required]="false"
      [(ngModel)]="model.mortgageBalance"
    ></app-shared-currency>
  </div>
</div>

<div class="form-group clearfix">
  <label for="reference" class="col-sm-4 control-label">Excluded</label>
  <div class="col-sm-8">
    <app-boolean-answer [(ngModel)]="model.excluded"></app-boolean-answer>
  </div>
</div>

<div class="form-group clearfix" *ngIf="!model.excluded">
  <label for="receivedValue" class="col-sm-4 control-label"
    >Received Value</label
  >
  <div class="col-sm-8">
    <app-shared-currency
      [required]="false"
      [(ngModel)]="model.receivedValue"
    ></app-shared-currency>
  </div>
</div>

<div class="form-group clearfix" *ngIf="model.person === null">
  <label for="jointly-owned-with" class="col-sm-4 control-label"
    >Joint Ownership With</label
  >
  <div class="col-sm-8">
    <select
      class="form-control"
      id="jointly-owned-with"
      [(ngModel)]="model.jointlyOwnedWith"
    >
      <option value="my husband">Husband</option>
      <option value="my wife">wife</option>
      <option value="my partner">partner</option>
      <option value="">other</option>
    </select>
  </div>
  <div
    class="col-sm-offset-4 col-sm-8"
    *ngIf="
      model.jointlyOwnedWith !== 'my husband' &&
      model.jointlyOwnedWith !== 'my wife' &&
      model.jointlyOwnedWith !== 'my partner'
    "
  >
    <input
      type="text"
      class="form-control"
      [(ngModel)]="model.jointlyOwnedWith"
      style="margin-top: 10px;"
    />
  </div>
</div>

<div class="form-group clearfix">
  <label for="reference" class="col-sm-4 control-label">Note</label>
  <div class="col-sm-8">
    <quill-editor name="note" [(ngModel)]="model.note"></quill-editor>
  </div>
</div>
