import {Component, EventEmitter, Output, ViewChild} from '@angular/core';

import {CaseCreditor} from '../case-creditor';
import {CaseCreditorService} from '../case-creditor.service';

@Component({
  selector: 'app-case-creditor-create',
  templateUrl: 'create.component.html',
})
export class CaseCreditorCreateComponent {
  @ViewChild('staticModal') staticModal;

  @Output() completed = new EventEmitter<boolean>();

  public disabled: boolean = false;

  public model: CaseCreditor = new CaseCreditor();

  private givenCaseId: string;

  constructor(private readonly caseCreditorService: CaseCreditorService) {}

  public get caseId(): string {
    return this.givenCaseId;
  }

  public open(caseId: string): void {
    this.givenCaseId = caseId;
    this.staticModal.show();
  }

  public close() {
    this.workCompleted();
  }

  public confirmCreate($event) {
    $event.preventDefault();
    this.disabled = true;
    this.caseCreditorService
      .createCaseCreditor(this.model, { parameters: { id: this.caseId } })
      .subscribe(() => {
        this.workCompleted();
      });
  }

  private workCompleted() {
    this.model = new CaseCreditor();
    this.disabled = false;
    this.staticModal.hide();
    this.completed.next(true);
  }
}
