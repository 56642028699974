import { Component, Input } from '@angular/core';
import { AccountGroup } from '../account-group';

@Component({
  selector: 'app-account-group-form',
  templateUrl: 'form.component.html',
})
export class AccountGroupFormComponent {
  @Input() accountGroup: AccountGroup;
}
