<div
  class="modal fade"
  bsModal
  #staticModal="bs-modal"
  [config]="{ backdrop: 'static' }"
  tabindex="-1"
  role="dialog"
  aria-labelledby="mySmallModalLabel"
  aria-hidden="true"
>
  <div class="modal-dialog modal-md">
    <form (submit)="upload()" #completedForm="ngForm" ngNativeValidate>
      <div class="modal-content panel-success">
        <div class="modal-header panel-heading">
          <h4 class="modal-title pull-left">Upload Client Interest</h4>
          <button
            type="button"
            class="close pull-right"
            aria-label="Close"
            (click)="close()"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <div class="col-sm-12">
            <p>
              Attach Client Interest csv and upload to process all interest
              received for cases in the file.
            </p>
          </div>
          <div class="form-group clearfix">
            <label for="file" class="col-sm-4 control-label">File</label>

            <label class="col-sm-8" for="fileButton">
              <div
                class="well my-drop-zone"
                ng2FileDrop
                [uploader]="uploader"
                (fileOver)="fileOverBase($event)"
                [ngClass]="{ 'nv-file-over': hasBaseDropZoneOver }"
              >
                <div *ngFor="let item of uploader.queue">
                  <strong>{{ item?.file?.name }}</strong>
                </div>

                <div *ngIf="uploader?.queue?.length < 1">
                  Drag a file (or click here) to choose a file to upload.
                </div>

                <input
                  type="file"
                  id="fileButton"
                  style="display: none"
                  ng2FileSelect
                  [uploader]="uploader"
                />
              </div>
            </label>
          </div>

          <div class="modal-footer">
            <button type="button" class="btn btn-default" (click)="close()">
              Cancel
            </button>
            <button type="submit" class="btn btn-success" [disabled]="disabled">
              <div *ngIf="loading">
                <img
                  src="https://i0.wp.com/cdnjs.cloudflare.com/ajax/libs/semantic-ui/0.16.1/images/loader-large.gif"
                  class="tw-h-8 tw-w-8"
                  height="20"
                />
              </div>
              <div *ngIf="!loading">Upload</div>
            </button>
          </div>
        </div>
      </div>
    </form>
  </div>
</div>
