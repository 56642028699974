<div class="text-right button-menu">
  <button type="button" class="btn btn-sm btn-success" href="javascript:;" (click)="create.open(caseId)">Add
    Expenditure
  </button>
</div>

<table class="expenditures table table-striped">
  <thead>
  <tr>
    <th width="40" class="text-center"></th>
    <th>Expenditure Type</th>
    <th>Value</th>
    <th>Note</th>
    <th></th>
  </tr>
  </thead>
  <tbody>
  <tr *ngFor="let caseExpenditure of caseExpenditures">
    <td>
      <app-user-icon *ngIf="caseExpenditure.user" [userId]="caseExpenditure.user.id" [size]="20"
                     [passedUser]="caseExpenditure.user"></app-user-icon>
    </td>
    <td>{{ caseExpenditure.expenditure.name }}</td>
    <td>{{ caseExpenditure.value / 100 | toCurrency }}</td>
    <td>
      <div [innerHtml]="caseExpenditure.note"></div>
    </td>
    <td class="text-right">
      <div class="btn-group">
        <a type="button" class="btn btn-xs btn-warning" (click)="edit.open(caseId, caseExpenditure)">Edit</a>
        <button type="button" class="btn btn-xs btn-warning dropdown-toggle" data-toggle="dropdown"
                aria-haspopup="true" aria-expanded="false" *ngIf="authService.hasPermission('user.delete-cases')">
          <span class="caret" *ngIf="authService.hasPermission('user.delete-cases')"></span>
          <span class="sr-only" *ngIf="authService.hasPermission('user.delete-cases')">Toggle Dropdown</span>
        </button>
        <ul class="dropdown-menu" *ngIf="authService.hasPermission('user.delete-cases')">
          <li *ngIf="authService.hasPermission('user.delete-cases')"><a href="javascript:;"
                                                                        (click)="deleteModal.confirmDialog(caseExpenditure.id)">Delete</a>
          </li>
        </ul>
      </div>
    </td>
  </tr>
  </tbody>
  <tfoot>
  <tr>
    <td colspan="99" class="text-center">
      <app-pagination [totalPages]="totalPages" [currentPage]="currentPage"
                      (pageChanged)="onPageChanged($event)"></app-pagination>
    </td>
  </tr>
  </tfoot>
</table>

<app-case-expenditure-create (completed)="onPageChanged(1)" #create></app-case-expenditure-create>

<app-case-expenditure-edit (completed)="onPageChanged(1)" #edit></app-case-expenditure-edit>

<app-delete-modal type="income" (confirm)="delete($event)" [itemDeleted]="itemDeleted" #deleteModal></app-delete-modal>
