import { Component, EventEmitter, OnInit } from '@angular/core';
import { NotificationService } from '../../../shared/notification.service';
import { FinancialLedger } from '../ledger';
import { FinancialLedgerService } from '../ledger.service';

@Component({
  selector: 'app-financial-ledger-list',
  templateUrl: 'list.component.html',
  styleUrls: [],
})
export class FinancialLedgerListComponent implements OnInit {
  /**
   * A list of all the financial-ledgers currently loaded.
   *
   * @type {Ledger[]}
   */
  public financialLedger: FinancialLedger[] = [];

  /**
   * Are we currently performing a loading action?
   *
   * @type {boolean}
   */
  public isLoading: boolean = true;

  /**
   * The total number of pages in this list of financial-ledgers.
   *
   * @type {number}
   */
  public totalPages: number = 1;

  /**
   * The current page of financial-ledgers that we are viewing.
   *
   * @type {number}
   */
  public currentPage: number = 1;

  /**
   * The total number of financial-ledgers that can be viewed in the system.
   *
   * @type {number}
   */
  public totalResults: number = 0;

  /**
   * An Emitter to alert other components when a financialLedger has been deleted.
   *
   * @type {EventEmitter<boolean>}
   */
  public financialLedgerDeleted: EventEmitter<boolean> = new EventEmitter<
    boolean
  >();

  /**
   * Load up all our required services and subscribe to every observable we need.
   *
   * @param {LedgerService} financialLedgerService
   * @param {NotificationService} notificationService
   */
  constructor(
    private financialLedgerService: FinancialLedgerService,
    private notificationService: NotificationService,
  ) {
    this.financialLedgerService.loading.subscribe(isLoading => {
      this.isLoading = isLoading;
    });
    this.financialLedgerService.totalPages.subscribe(totalPages => {
      this.totalPages = totalPages;
    });
    this.financialLedgerService.currentPage.subscribe(currentPage => {
      this.currentPage = currentPage;
    });
    this.financialLedgerService.totalResults.subscribe(totalResults => {
      this.totalResults = totalResults;
    });
  }

  /**
   * When the component is initialised we set it to load
   * page one of the financialLedger list.
   */
  public ngOnInit(): void {
    this.loadFinancialLedger(1);
  }

  /**
   * When we receive a page change from the pagination
   * component we load the appropriate page for the
   * financialLedger list.
   *
   * @param {number} pageNumber
   */
  public onPageChanged(pageNumber: number): void {
    this.loadFinancialLedger(pageNumber);
  }

  /**
   * Loads a list of financial-ledgers using the given per page limit.
   *
   * @param {number} page The current page we require.
   * @param {number} limit The number of results per page.
   */
  public loadFinancialLedger(page?: number, limit?: number): void {
    const listFinancialLedger = this.financialLedgerService
      .listFinancialLedger(page, limit)
      .subscribe((financialLedger: FinancialLedger[]) => {
        this.financialLedger = financialLedger;
        listFinancialLedger.unsubscribe();
      });
  }

  /**
   * When receiving confirmation of an item being deleted
   * we call the back end with the appropriate financialLedger ID
   * to delete the financialLedger.
   *
   * @param {string} id
   */
  public delete(id: string) {
    this.financialLedgerService.deleteFinancialLedger(id).subscribe(
      deleted => {
        this.notificationService.success(
          `FinancialLedger Deleted`,
          `FinancialLedger with ID ${id} was deleted.`,
          5,
        );
        this.financialLedgerDeleted.next(true);
        this.onPageChanged(this.currentPage);
      },
      err => {
        this.notificationService.error(
          `FinancialLedger Not Deleted`,
          err.error.message,
          10,
        );
        this.financialLedgerDeleted.next(false);
        this.onPageChanged(this.currentPage);
      },
    );
  }
}
