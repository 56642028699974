import { NgModule } from '@angular/core';
import { PaperlessOfficeOutComponent } from './paperless-office-out/paperless-office-out.component';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from '../auth/auth.guard';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { SharedModule } from '../shared/shared.module';
import { WipComponent } from './wip/wip.component';
import { PaperlessOfficeInComponent } from './paperless-office-in/paperless-office-in.component';
import { ModalModule } from 'ngx-bootstrap/modal';
import { UnapprovedCreditorsComponent } from './unapproved-creditors/unapproved-creditors.component';
import { ClientsByCreditorComponent } from './clients-by-creditor/clients-by-creditor.component';
import { AllCreditorsComponent } from './all-creditors/all-creditors.component';
import { AnnualsDueComponent } from './annuals-due/annuals-due.component';
import { ReportViewerComponent } from './report-viewer/report-viewer.component';
import { WthDepStatementsComponent } from './wth-dep-statements/wth-dep-statements.component';
import { PortfolioService } from '../portfolios/portfolio.service';

const routes: Routes = [
  {
    path: 'reports/wth-dep-statements',
    component: WthDepStatementsComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'paperless-office/outbound',
    component: PaperlessOfficeOutComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'paperless-office/inbound',
    component: PaperlessOfficeInComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'reports/wip',
    component: WipComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'reports/unapproved-creditors',
    component: UnapprovedCreditorsComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'reports/annuals-due',
    component: AnnualsDueComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'reports/clients-by-creditor',
    component: AllCreditorsComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'reports/clients-by-creditor/:creditorId',
    component: ClientsByCreditorComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'reports/viewer',
    component: ReportViewerComponent,
    canActivate: [AuthGuard],
  },
];

@NgModule({
  imports: [
    BrowserModule,
    RouterModule,
    FormsModule,
    HttpClientModule,
    SharedModule,
    RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' }),
    ModalModule.forRoot(),
  ],
  declarations: [
    PaperlessOfficeOutComponent,
    PaperlessOfficeInComponent,
    AllCreditorsComponent,
    UnapprovedCreditorsComponent,
    ClientsByCreditorComponent,
    WipComponent,
    AnnualsDueComponent,
    ReportViewerComponent,
    WthDepStatementsComponent,
  ],
  providers: [PortfolioService],
  exports: [RouterModule],
})
export class ReportsModule {}
