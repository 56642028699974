import { AccountType } from '../../../settings/account-types/account-type';
import { Domain } from '../../../domains/domain';

export class BankingAccount {
  public id?: string;
  public domain: Domain;
  public type: AccountType;
  public name: string;
  public description?: string;
  public sortCode?: string;
  public accountNumber?: string;
  public balance?: number;
  public reconciledBalance?: number;
  public unreconciledBalance?: number;
}
