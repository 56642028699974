import { Component, OnInit } from '@angular/core';
import { FolderService } from '../folder.service';
import { Folder } from '../folder';
import { Router } from '@angular/router';
import { NotificationService } from '../../../shared/notification.service';

@Component({
  selector: 'app-folder-create',
  templateUrl: 'create.component.html',
})
export class FolderCreateComponent implements OnInit {
  /**
   * Details of the folder being created.
   */
  public folder: Folder;

  /**
   * Are we currently performing a loading action?
   *
   * @type {boolean}
   */
  public isLoading: boolean = false;

  /**
   * Load up all our required services and subscribe to every observable we need.
   *
   * @param {FolderService} folderService
   * @param {NotificationService} notificationService
   * @param {Router} router
   */
  constructor(
    private folderService: FolderService,
    private notificationService: NotificationService,
    private router: Router,
  ) {
    this.folderService.loading.subscribe(result => (this.isLoading = result));
  }

  /**
   * Initialise the component, creating a new blank folder if required.
   */
  public ngOnInit(): void {
    if (!this.folder) {
      this.folder = new Folder();
    }
  }

  /**
   * Perform the create action which adds the new folder into the backend.
   */
  public create() {
    const createFolder = this.folderService.createFolder(this.folder).subscribe(
      (result: Folder) => {
        this.notificationService.success(
          `Folder Created`,
          `New folder ${result.name} has been created with ID of ${result.id}.`,
          5,
        );
        this.folder = new Folder();
        createFolder.unsubscribe();
        this.router.navigate(['/folders']);
      },
      err => {
        this.notificationService.error(
          `Could not create Folder`,
          err.error.message,
          10,
        );
      },
    );
  }
}
