import { NgModule } from '@angular/core';
import { AngularFireModule } from '@angular/fire';
import { AngularFirestoreModule } from '@angular/fire/firestore';
import { AngularFireFunctionsModule } from '@angular/fire/functions';
import { FormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { ModalModule } from 'ngx-bootstrap/modal';
import { PopoverModule } from 'ngx-bootstrap/popover';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { MarkdownModule } from 'ngx-markdown';
import { QuillModule } from 'ngx-quill';
import { ToastrModule } from 'ngx-toastr';
import { environment } from '../../environments/environment';
import { CaseCalendarCreateComponent } from '../cases/calendars/create/create.component';
import { CaseCalendarFormComponent } from '../cases/calendars/form/form.component';
import { SharedCaseCreditorSelectComponent } from '../cases/creditors/select/select.component';
import { ComplaintStatusSelectComponent } from '../complaint-statuses/select/select.component';
import { BankingAccountItemSelectComponent } from '../financials/banking/account-items/select/select.component';
import { BankingAccountTypeSelectComponent } from '../financials/banking/account-types/select/select.component';
import { BankingAccountSelectComponent } from '../financials/banking/accounts/select/select.component';
import { PaymentTypeSelectComponent } from '../settings/payment-types/select/select.component';
import { AllocationStatusComponent } from './allocation-status/allocation-status.component';
import { AuditChartOfAccountsComponent } from './audit-chart-of-accounts/audit-chart-of-accounts.component';
import { AuditPaymentProfileComponent } from './audit-payment-profile/audit-payment-profile.component';
import { BankMatchStatusComponent } from './bank-match-status/bank-match-status.component';
import { BooleanAnswerComponent } from './boolean-answer/boolean-answer.component';
import { CaseCreditSummaryComponent } from './case-credit-summary/case-credit-summary.component';
import { CaseExpenditureFormItemComponent } from './case-expenditure-form/case-expenditure-form-item.component';
import { CaseExpenditureFormComponent } from './case-expenditure-form/case-expenditure-form.component';
import { CaseIncomeFormItemComponent } from './case-income-form/case-income-form-item.component';
import { CaseIncomeFormComponent } from './case-income-form/case-income-form.component';
import { ChiefUpdatesComponent } from './chief-updates/chief-updates.component';
import { CreateModalComponent } from './create-modal/create-modal.component';
import { DeleteModalComponent } from './delete-modal/delete-modal.component';
import { DependantListComponent } from './dependant-list/dependant-list.component';
import { DomainIconComponent } from './domain-icon/domain-icon.component';
import { EditModalComponent } from './edit-modal/edit-modal.component';
import { EditableFieldsModule } from './editable-fields/editable-fields.module';
import { PageTitleComponent } from './page-title/page-title.component';
import { PaginationComponent } from './pagination/pagination.component';
import {
  CapitalizeFirstPipe,
  SafePipe,
  StripHtmlPipe,
  TimeAgoPipe,
} from './pipes/formatting.pipe';
import { SearchComponent } from './search/search.component';
import { SharedSelectModule } from './shared-select.module';
import { TeamChatComponent } from './team-chat/team-chat.component';
import { UserIconComponent } from './user-icon/user-icon.component';
import { UserTaskComponent } from './user-task/user-task.component';

const domainName: string = environment.endpoint;

@NgModule({
  imports: [
    ModalModule.forRoot(),
    PopoverModule.forRoot(),
    TooltipModule.forRoot(),
    RouterModule,
    FormsModule,
    QuillModule,
    BrowserModule,
    SharedSelectModule,
    BrowserAnimationsModule,
    ToastrModule.forRoot(),
    AngularFireModule.initializeApp(environment.firebase),
    AngularFirestoreModule,
    MarkdownModule,
    AngularFireFunctionsModule,
    EditableFieldsModule,
  ],
  declarations: [
    SearchComponent,
    CapitalizeFirstPipe,
    SafePipe,
    TimeAgoPipe,
    StripHtmlPipe,
    PageTitleComponent,
    PaginationComponent,
    DeleteModalComponent,
    CreateModalComponent,
    EditModalComponent,
    UserIconComponent,
    DomainIconComponent,
    UserTaskComponent,
    CaseIncomeFormItemComponent,
    CaseIncomeFormComponent,
    CaseExpenditureFormItemComponent,
    CaseExpenditureFormComponent,
    DependantListComponent,
    BooleanAnswerComponent,
    PaymentTypeSelectComponent,
    BankingAccountTypeSelectComponent,
    BankingAccountSelectComponent,
    BankingAccountItemSelectComponent,
    SharedCaseCreditorSelectComponent,
    CaseCalendarCreateComponent,
    CaseCalendarFormComponent,
    TeamChatComponent,
    ChiefUpdatesComponent,
    ComplaintStatusSelectComponent,
    AllocationStatusComponent,
    BankMatchStatusComponent,
    CaseCreditSummaryComponent,
    AuditPaymentProfileComponent,
    AuditChartOfAccountsComponent,
  ],
  exports: [
    SearchComponent,
    SharedSelectModule,
    TimeAgoPipe,
    SafePipe,
    StripHtmlPipe,
    CapitalizeFirstPipe,
    PageTitleComponent,
    PaginationComponent,
    DeleteModalComponent,
    CreateModalComponent,
    EditModalComponent,
    UserIconComponent,
    DomainIconComponent,
    UserTaskComponent,
    CaseIncomeFormItemComponent,
    CaseIncomeFormComponent,
    CaseExpenditureFormItemComponent,
    CaseExpenditureFormComponent,
    DependantListComponent,
    BooleanAnswerComponent,
    PaymentTypeSelectComponent,
    BankingAccountTypeSelectComponent,
    BankingAccountSelectComponent,
    PaymentTypeSelectComponent,
    BankingAccountItemSelectComponent,
    SharedCaseCreditorSelectComponent,
    CaseCalendarCreateComponent,
    CaseCalendarFormComponent,
    TeamChatComponent,
    ChiefUpdatesComponent,
    ComplaintStatusSelectComponent,
    BankMatchStatusComponent,
    AllocationStatusComponent,
    CaseCreditSummaryComponent,
    AuditPaymentProfileComponent,
    AuditChartOfAccountsComponent,
    EditableFieldsModule,
  ],
})
export class SharedModule {}
