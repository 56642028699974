<div class="row clearfix">
  <div class="col-md-6">
    <div class="form-group clearfix">
      <label class=" control-label" for="contributionNotes"
      >Notes about Contributions</label
      >
      <textarea
              [(ngModel)]="model.contributionNotes"
              class="form-control"
              id="contributionNotes"
              name="contributionNotes"
              rows="3"
      ></textarea>
    </div>

    <div class="form-group clearfix">
      <label class="control-label" for="termNotes">Notes about Term</label>
      <textarea
              [(ngModel)]="model.termNotes"
              class="form-control"
              id="termNotes"
              name="termNotes"
              rows="3"
      ></textarea>
    </div>

    <div class="form-group clearfix">
      <label class=" control-label" for="iandeNotes"
      >Notes about Income & Expenditure</label
      >
      <textarea
              [(ngModel)]="model.iandeNotes"
              class="form-control"
              id="iandeNotes"
              name="iandeNotes"
              rows="3"
      ></textarea>
    </div>

    <div class="form-group clearfix">
      <label class=" control-label" for="assetNotes">Notes about Assets</label>
      <textarea
              [(ngModel)]="model.assetNotes"
              class="form-control"
              id="assetNotes"
              name="assetNotes"
              rows="3"
      ></textarea>
    </div>
    <div class="form-group clearfix">
      <label class=" control-label" for="iandeOutcome">I&E Outcome</label>
      <select
              [(ngModel)]="model.iandeOutcome"
              class="form-control"
              id="iandeOutcome"
              name="iandeOutcome"
      >
        <option>Increase</option>
        <option>Maintain</option>
        <option>15% Reduction</option>
        <option>To Be Confirmed</option>
      </select>
    </div>
  </div>

  <div class="col-md-6">
    <div class="form-group clearfix">
      <label class=" control-label" for="dividendApprovedNotes"
      >Notes about Approved Dividends</label
      >
      <textarea
              [(ngModel)]="model.dividendApprovedNotes"
              class="form-control"
              id="dividendApprovedNotes"
              name="dividendApprovedNotes"
              rows="3"
      ></textarea>
    </div>

    <div class="form-group clearfix">
      <label class=" control-label" for="dividendPaidNotes"
      >Notes about Paid Dividends</label
      >
      <textarea
              [(ngModel)]="model.dividendPaidNotes"
              class="form-control"
              id="dividendPaidNotes"
              name="dividendPaidNotes"
              rows="3"
      ></textarea>
    </div>

    <div class="form-group clearfix">
      <label class=" control-label" for="feeNotes">Notes about Fees</label>
      <textarea
              [(ngModel)]="model.feeNotes"
              class="form-control"
              id="feeNotes"
              name="feeNotes"
              rows="3"
      ></textarea>
    </div>
    <div class="form-group clearfix">
      <label class=" control-label" for="additionalMoniesNotes"
      >Notes about Additional Monies</label
      >
      <textarea
              [(ngModel)]="model.additionalMoniesNotes"
              class="form-control"
              id="additionalMoniesNotes"
              name="additionalMoniesNotes"
              rows="3"
      ></textarea>
    </div>

    <div class="form-group clearfix">
      <label class=" control-label" for="ppiOrPba"
      >PPI / PBA Investigations Ongoing</label
      >
      <select
              [(ngModel)]="model.ppiOrPba"
              class="form-control"
              id="ppiOrPba"
              name="ppiOrPba"
      >
        <option>Yes</option>
        <option>No</option>
      </select>
    </div>
  </div>
</div>

<h4>
  Additional Client Obligations

  <button (click)="addObligation()" class="btn btn-xs pull-right" type="button">
    <i class="fa fa-plus"></i>
  </button>
</h4>

<div
        *ngFor="let obligation of model.additionalClientObligations; index as i"
        class="row"
>
  <div class="col-md-8">
    <div class="form-group clearfix">
      <textarea
              [(ngModel)]="obligation.entry"
              class="form-control"
              id="obligation_entry"
              name="obligation_entry"
              rows="3"
      ></textarea>
    </div>
  </div>
  <div class="col-md-3">
    <div class="form-group clearfix">
      <select
              [(ngModel)]="obligation.result"
              class="form-control"
              id="obligation_result"
              name="obligation_result"
      >
        <option>Complied</option>
        <option>Ongoing</option>
        <option>Not Yet Due</option>
        <option>In Breach</option>
        <option>Referred to Failure</option>
        <option>Referred to Variation</option>
      </select>
    </div>
  </div>
  <div class="col-md-1 text-right">
    <button
            (click)="removeObligation(i)"
            class="btn btn-danger btn-sm"
            type="button"
    >
      <i class="fa fa-trash"></i>
    </button>
  </div>
</div>

<div class="row">
  <div class="col-md-12 text-center">
    <select [(ngModel)]="model.approve" class="form-control">
      <option value="save">Save Annual Review</option>
      <option value="confirm">Save Annual Review and generate confirmation documents</option>
      <option value="approve">Save Annual Review, create final documents and mark as completed</option>
    </select>
  </div>
</div>
