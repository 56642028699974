import { Component, EventEmitter, Input, OnInit } from '@angular/core';

import { AuthService } from '../../../auth/auth.service';
import { NotificationService } from '../../../shared/notification.service';
import { CaseComplaint } from '../case-complaint';
import { CaseComplaintService } from '../case-complaint.service';

@Component({
  selector: 'app-case-complaint-list',
  templateUrl: 'list.component.html',
  styleUrls: ['list.component.scss'],
})
export class CaseComplaintListComponent implements OnInit {
  @Input() caseRefreshed: EventEmitter<string>;

  @Input() caseId: string;

  public caseComplaints: CaseComplaint[] = [];

  public isLoading: boolean = true;

  public totalPages: number = 1;

  public currentPage: number = 1;

  public totalResults: number = 0;

  public complaintCreated: EventEmitter<boolean> = new EventEmitter<boolean>();

  public complaintDeleted: EventEmitter<boolean> = new EventEmitter<boolean>();

  public editorOptions = {
    placeholder: 'Enter your complaint...',
    modules: {
      toolbar: ['bold', 'italic', 'underline', 'strike', 'link'],
    },
  };

  constructor(
    private caseComplaintService: CaseComplaintService,
    private notificationService: NotificationService,
    public authService: AuthService,
  ) {
    this.caseComplaintService.loading.subscribe(isLoading => {
      this.isLoading = isLoading;
    });
    this.caseComplaintService.totalPages.subscribe(totalPages => {
      this.totalPages = totalPages;
    });
    this.caseComplaintService.currentPage.subscribe(currentPage => {
      this.currentPage = currentPage;
    });
    this.caseComplaintService.totalResults.subscribe(totalResults => {
      this.totalResults = totalResults;
    });
  }

  public ngOnInit(): void {
    this.loadCaseComplaints(1);
    this.caseRefreshed.subscribe(id => {
      this.caseId = id;
      this.onPageChanged(1);
    });
  }

  public onPageChanged(pageNumber: number): void {
    this.loadCaseComplaints(pageNumber);
  }

  public loadCaseComplaints(page?: number, limit?: number): void {
    const listCountries = this.caseComplaintService
      .listCaseComplaints(page, limit, { parameters: { id: this.caseId } })
      .subscribe((caseComplaints: CaseComplaint[]) => {
        this.caseComplaints = caseComplaints;
        listCountries.unsubscribe();
      });
  }

  public create(formData: CaseComplaint) {
    const subscription = this.caseComplaintService
      .createCaseComplaint(formData, { parameters: { id: this.caseId } })
      .subscribe(results => {
        this.complaintCreated.next(true);
        this.onPageChanged(1);
        subscription.unsubscribe();
      });
  }

  delete(id: string) {
    this.caseComplaintService.deleteCaseComplaint(id).subscribe(
      deleted => {
        this.notificationService.success(
          `Case Complaint Deleted`,
          `Case Complaint with ID ${id} was deleted.`,
          5,
        );
        this.complaintDeleted.next(true);
        this.onPageChanged(this.currentPage);
      },
      err => {
        this.notificationService.error(
          `Case Not Deleted`,
          err.error.message,
          10,
        );
        this.complaintDeleted.next(false);
        this.onPageChanged(this.currentPage);
      },
    );
  }

  public togglePin(complaint: CaseComplaint): void {
    complaint.pinned = !complaint.pinned;
    const subscription = this.caseComplaintService
      .updateCaseComplaint(complaint.id, complaint)
      .subscribe(result => {
        this.onPageChanged(1);
        subscription.unsubscribe();
      });
  }
}
