import { RouterModule, Routes } from '@angular/router';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule } from '@angular/common/http';
import { DocumentListComponent } from './list/list.component';
import { AuthGuard } from '../auth/auth.guard';
import { DocumentService } from './document.service';
import { SharedModule } from '../shared/shared.module';
import { DocumentCreateComponent } from './create/create.component';
import { DocumentFormComponent } from './form/form.component';
import { DocumentEditComponent } from './edit/edit.component';

const routes: Routes = [
  {
    path: 'documents',
    component: DocumentListComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'documents/create',
    component: DocumentCreateComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'documents/:id/edit',
    component: DocumentEditComponent,
    canActivate: [AuthGuard],
  },
];

@NgModule({
  declarations: [
    DocumentListComponent,
    DocumentCreateComponent,
    DocumentFormComponent,
    DocumentEditComponent,
  ],
  imports: [
    BrowserModule,
    RouterModule,
    FormsModule,
    HttpClientModule,
    SharedModule,
    RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' }),
  ],
  exports: [RouterModule],
  providers: [AuthGuard, DocumentService],
})
export class DocumentRoutingModule {}
