import {HttpClientModule} from '@angular/common/http';
import {NgModule} from '@angular/core';
import {FormsModule} from '@angular/forms';
import {BrowserModule} from '@angular/platform-browser';
import {RouterModule, Routes} from '@angular/router';
import {ModalModule} from 'ngx-bootstrap/modal';
import {TooltipModule} from 'ngx-bootstrap/tooltip';

import {AuthGuard} from '../../auth/auth.guard';
import {SharedModule} from '../../shared/shared.module';
import {FinancialsDistributionDistributeComponent} from './distribute/distribute.component';
import {FinancialsDistributionService} from './financials-distribution.service';
import {FinancialsDistributionListComponent} from './list/list.component';
import {FinancialsDistributionSummaryComponent} from './summary/summary.component';

const routes: Routes = [
  {
    path: 'financials/distributions/summary',
    component: FinancialsDistributionSummaryComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'financials/distributions',
    component: FinancialsDistributionListComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'financials/distributions/:id',
    component: FinancialsDistributionDistributeComponent,
    canActivate: [AuthGuard],
  },
];

@NgModule({
  declarations: [
    FinancialsDistributionListComponent,
    FinancialsDistributionDistributeComponent,
    FinancialsDistributionSummaryComponent,
  ],
  imports: [
    TooltipModule.forRoot(),
    ModalModule.forRoot(),
    BrowserModule,
    RouterModule,
    FormsModule,
    HttpClientModule,
    SharedModule,
    RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' }),
  ],
  exports: [RouterModule],
  providers: [AuthGuard, FinancialsDistributionService],
})
export class FinancialsDistributionRoutingModule {}
