import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { HttpRequestService } from '../../shared/http/http-request.service';
import { FinancialAccount } from './accounts';

@Injectable()
export class FinancialAccountsService extends HttpRequestService {
  /**
   * The endpoint that we call to get the financialAccounts information.
   *
   * @type {string}
   */
  private readonly endpoint: string = 'financial-accounts';

  /**
   * Brings back a list of all accessible financial-accounts.
   *
   * @param {number} page The page number we are currently on.
   * @param {number} limit The number of financial-accounts to display per page.
   * @returns {Observable<FinancialAccount[]>}
   */
  public listFinancialAccounts(
    page?: number,
    limit?: number,
  ): Observable<FinancialAccount[]> {
    return this.list(this.endpoint, page, undefined, limit);
  }

  /**
   * Finds details of the financialAccounts from the given ID.
   *
   * @param {string} financialAccountsId The ID of the financialAccounts we require details for.
   * @returns {Observable<FinancialAccount>}
   */
  public findFinancialAccounts(
    financialAccountsId: string,
  ): Observable<FinancialAccount> {
    return this.findById(this.endpoint, financialAccountsId);
  }

  /**
   * Creates a new financialAccounts from the given FinancialAccounts object.
   *
   * @param {FinancialAccount} newFinancialAccounts The details of the financialAccounts to be created.
   * @returns {Observable<FinancialAccount | boolean>}
   */
  public createFinancialAccounts(
    newFinancialAccounts: FinancialAccount,
  ): Observable<FinancialAccount | boolean> {
    return this.create(this.endpoint, newFinancialAccounts);
  }

  /**
   * Updates a financialAccounts with the given financialAccounts object for the given financialAccounts ID.
   *
   * @param {string} financialAccountsId The ID of the financialAccounts needing updating.
   * @param {FinancialAccount} newDetails The new financialAccounts object to update with.
   * @returns {Observable<FinancialAccount | boolean>}
   */
  public updateFinancialAccounts(
    financialAccountsId: string,
    newDetails: FinancialAccount,
  ): Observable<FinancialAccount | boolean> {
    return this.update(this.endpoint, financialAccountsId, newDetails);
  }

  /**
   * Deletes a financialAccounts based on the given financialAccounts ID.
   *
   * @param {string} financialAccountsId The ID of the financialAccounts needing deleting.
   * @returns {Observable<boolean>}
   */
  public deleteFinancialAccounts(
    financialAccountsId: string,
  ): Observable<boolean> {
    return this.delete(this.endpoint, financialAccountsId);
  }
}
