import { Injectable } from '@angular/core';
import { Supplier } from './supplier';
import { Observable } from 'rxjs';

import { HttpRequestService } from '../../shared/http/http-request.service';

@Injectable()
export class SupplierService extends HttpRequestService {
  /**
   * The endpoint that we call to get the supplier information.
   *
   * @type {string}
   */
  private readonly endpoint: string = 'suppliers';

  /**
   * Brings back a list of all accessible suppliers.
   *
   * @param {number} page The page number we are currently on.
   * @param {number} limit The number of suppliers to display per page.
   * @returns {Observable<Supplier[]>}
   */
  public listSuppliers(
    page?: number,
    limit?: number,
    filter?: string,
  ): Observable<Supplier[]> {
    return this.list(this.endpoint, page, { filter: filter }, limit);
  }

  /**
   * Finds details of the supplier from the given ID.
   *
   * @param {string} supplierId The ID of the supplier we require details for.
   * @returns {Observable<Supplier>}
   */
  public findSupplier(supplierId: string): Observable<Supplier> {
    return this.findById(this.endpoint, supplierId);
  }

  /**
   * Creates a new supplier from the given Supplier object.
   *
   * @param {Supplier} newSupplier The details of the supplier to be created.
   * @returns {Observable<Supplier | boolean>}
   */
  public createSupplier(newSupplier: Supplier): Observable<Supplier | boolean> {
    return this.create(this.endpoint, newSupplier);
  }

  /**
   * Updates a supplier with the given supplier object for the given supplier ID.
   *
   * @param {string} supplierId The ID of the supplier needing updating.
   * @param {Supplier} newDetails The new supplier object to update with.
   * @returns {Observable<Supplier | boolean>}
   */
  public updateSupplier(
    supplierId: string,
    newDetails: Supplier,
  ): Observable<Supplier | boolean> {
    return this.update(this.endpoint, supplierId, newDetails);
  }

  /**
   * Deletes a supplier based on the given supplier ID.
   *
   * @param {string} supplierId The ID of the supplier needing deleting.
   * @returns {Observable<boolean>}
   */
  public deleteSupplier(supplierId: string): Observable<boolean> {
    return this.delete(this.endpoint, supplierId);
  }
}
