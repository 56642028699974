import { UserProfile } from '../../users/user-profile';

export interface UserState {
  jwtToken: string | undefined;
  online: boolean;
  menubar: boolean;
  menuItem: string | undefined;
  profile: UserProfile | undefined;
}

export const INITIAL_USER_STATE: UserState = {
  jwtToken: undefined,
  online: true,
  menubar: true,
  menuItem: undefined,
  profile: undefined,
};
