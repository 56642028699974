import { Component, Input, OnInit } from '@angular/core';

import { HttpRequestService } from '../../shared/http/http-request.service';
import { Introducer } from '../introducer';

@Component({
  selector: 'app-introducer-form',
  templateUrl: 'form.component.html',
})
export class IntroducerFormComponent
  extends HttpRequestService
  implements OnInit {
  @Input() introducer: Introducer;
  @Input() creating: boolean = false;
  loadingDebtpanelDomains: boolean = false;
  shouldLoadDebtpanelDomains: boolean = false;
  debtpanelDomains: any[];

  ngOnInit(): void {
    if (
      this.creating &&
      this.authService.hasPermission('config.debtpanel-link')
    ) {
      this.shouldLoadDebtpanelDomains = true;
      this.loadDebtpanelDomains();
    }
  }

  protected loadDebtpanelDomains(): void {
    this.loadingDebtpanelDomains = true;
    this.get(
      'https://europe-west2-debtpanel-production.cloudfunctions.net/listDomains?log=false',
    ).subscribe((r) => {
      this.debtpanelDomains = r;
      this.loadingDebtpanelDomains = true;
    });
  }
}
