<ng-container *ngIf="direction === 'horizontal'">
  <div class="row person-item">
    <div class="col-xs-6 person-item-title">Secured</div>
    <div class="col-xs-6">{{ summary.secured | toCurrency }}</div>
  </div>
  <div class="row person-item">
    <div class="col-xs-6 person-item-title">Unsecured</div>
    <div class="col-xs-6">{{ summary.unsecured | toCurrency }}</div>
  </div>
  <div class="row person-item">
    <div class="col-xs-6 person-item-title">Income</div>
    <div class="col-xs-6">{{ summary.income | toCurrency }}</div>
  </div>
  <div class="row person-item">
    <div class="col-xs-6 person-item-title">Expenditure</div>
    <div class="col-xs-6">{{ summary.expenditure | toCurrency }}</div>
  </div>

  <hr />

  <div class="row person-item">
    <div class="col-xs-6 person-item-title">Total Credit</div>
    <div class="col-xs-6">
      {{ summary.secured + summary.unsecured | toCurrency }}
    </div>
  </div>

  <div class="row person-item">
    <div class="col-xs-6 person-item-title">Disposable</div>
    <div class="col-xs-6">
      {{ summary.income - summary.expenditure | toCurrency }}
    </div>
  </div>

  <hr />
  <div class="row person-item">
    <div class="col-xs-6 person-item-title">Total SoA Balance</div>
    <div class="col-xs-6">{{ summary.totalSoa / 100 | toCurrency }}</div>
  </div>

  <div class="row person-item">
    <div class="col-xs-6 person-item-title">Total POD Balance</div>
    <div class="col-xs-6">{{ summary.totalPod / 100 | toCurrency }}</div>
  </div>

  <div class="row person-item">
    <div class="col-xs-6 person-item-title">Total Admitted Balance</div>
    <div class="col-xs-6">{{ summary.totalAdmitted / 100 | toCurrency }}</div>
  </div>

  <div class="row person-item">
    <div class="col-xs-6 person-item-title">Total Dividends</div>
    <div class="col-xs-6">{{ summary.totalDividends / 100 | toCurrency }}</div>
  </div>

  <hr />

  <div class="row person-item">
    <div class="col-xs-6 person-item-title">Has Protection?</div>
    <div class="col-xs-6">
      <span
        class="fa"
        [class.fa-check]="case.lifeId && case.lifeId.length > 0"
        [class.text-success]="case.lifeId && case.lifeId.length > 0"
        [class.fa-times]="!case.lifeId || case.lifeId.length <= 0"
        [class.text-danger]="!case.lifeId || case.lifeId.length <= 0"
      ></span>
    </div>
  </div>

  <hr *ngIf="case && case?.introducerValue" />

  <div class="row person-item" *ngIf="case && case?.introducerValue">
    <div class="col-xs-6 person-item-title">Introducer Commission</div>
    <div class="col-xs-6">
      {{ case?.introducerValue / 100 | toCurrency }}
    </div>
  </div>

  <div class="row person-item" *ngIf="case && case?.introducerValue">
    <div class="col-xs-6 person-item-title">Payment Due</div>
    <div class="col-xs-6">
      {{ case?.introducerPaymentDate | date: 'dd/MM/yyyy' }}
    </div>
  </div>

  <div class="row person-item" *ngIf="case">
    <div class="col-xs-6 person-item-title">Introducer Paid?</div>
    <div class="col-xs-6">
      <span
        class="fa"
        [class.fa-check]="
          case.introducerPaidAt && case.introducerPaidAt.length > 0
        "
        [class.text-success]="
          case.introducerPaidAt && case.introducerPaidAt.length > 0
        "
        [class.fa-times]="
          !case.introducerPaidAt || case.introducerPaidAt.length <= 0
        "
        [class.text-danger]="
          !case.introducerPaidAt || case.introducerPaidAt.length <= 0
        "
      ></span>
    </div>
  </div>
</ng-container>
