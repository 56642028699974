import { HttpRequestService } from '../../../shared/http/http-request.service';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable()
export class CaseCreditorDistributionsService extends HttpRequestService {
  private readonly endpoint: string = 'cases/:id/creditor-distributions';

  public getDistributions = (
    caseId: string,
    creditorId?: string,
  ): Observable<any> =>
    this.list(this.endpoint, 1, { creditorId }, 100, {
      parameters: {
        id: caseId,
      },
    });
}
