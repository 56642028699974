import { Template } from '../../../templates/template';
import { DeliveryMethod } from '../../../delivery-methods/delivery-method';
import { Person } from '../../people/person';

export class CaseDocumentGenerator {
  public template?: Template;
  public deliveryMethod: DeliveryMethod;
  public person?: Person;
  public freeTypedItems?: FreeTypedItem[];
}

export class FreeTypedItem {
  id: string;
  value: string;
}
