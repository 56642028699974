import { Component, ElementRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ComponentClass, FunctionComponent } from 'react';
import {
  LinkProps,
  ReactWrapper,
  RouterWrapper,
  ElementRefWrapper,
} from 'react-inside-angular';

import EditTitle, {
  EditTitleProps,
} from '../../../chief-react/src/App/Pages/Titles/TitleEdit';

@Component({
  selector: 'app-titles-edit',
  template: '<div [id]="rootId"></div>',
})
export class TitleEditComponent extends ReactWrapper<EditTitleProps> {
  private id: string;

  protected router: RouterWrapper;
  protected elemRef: ElementRefWrapper;

  constructor(
    router: Router,
    elemRef: ElementRef,
    protected route: ActivatedRoute,
  ) {
    super();
    this.router = (router as unknown) as RouterWrapper;
    this.elemRef = (elemRef as unknown) as ElementRefWrapper;

    this.route.params.subscribe((params) => {
      this.id = params.id;
    });
  }

  // Transfer any props from Angular to the React component
  public setProps(): EditTitleProps {
    return {
      id: this.id,
    };
  }

  // Define the React component being wrapped
  public setComponent():
    | string
    | FunctionComponent<LinkProps<EditTitleProps>>
    | ComponentClass<LinkProps<EditTitleProps>, any> {
    return EditTitle;
  }
}
