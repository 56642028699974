import { Component, EventEmitter, OnInit } from '@angular/core';
import { IncomeCategoryService } from '../income-category.service';
import { IncomeCategory } from '../income-category';
import { NotificationService } from '../../shared/notification.service';

@Component({
  selector: 'app-income-category-list',
  templateUrl: 'list.component.html',
  styleUrls: [],
})
export class IncomeCategoryListComponent implements OnInit {
  public incomeCategory: IncomeCategory;

  /**
   * A list of all the incomeCategories currently loaded.
   *
   * @type {IncomeCategory[]}
   */
  public incomeCategories: IncomeCategory[] = [];

  /**
   * Are we currently performing a loading action?
   *
   * @type {boolean}
   */
  public isLoading: boolean = true;

  /**
   * The total number of pages in this list of incomeCategories.
   *
   * @type {number}
   */
  public totalPages: number = 1;

  /**
   * The current page of incomeCategories that we are viewing.
   *
   * @type {number}
   */
  public currentPage: number = 1;

  /**
   * The total number of incomeCategories that can be viewed in the system.
   *
   * @type {number}
   */
  public totalResults: number = 0;

  /**
   * An Emitter to alert other components when a incomeCategory has been deleted.
   *
   * @type {EventEmitter<boolean>}
   */
  public incomeCategoryDeleted: EventEmitter<boolean> = new EventEmitter<
    boolean
  >();

  /**
   * Load up all our required services and subscribe to every observable we need.
   *
   * @param {IncomeCategoryService} incomeCategoryService
   * @param {NotificationService} notificationService
   */
  constructor(
    private incomeCategoryService: IncomeCategoryService,
    private notificationService: NotificationService,
  ) {
    this.incomeCategoryService.loading.subscribe(isLoading => {
      this.isLoading = isLoading;
    });
    this.incomeCategoryService.totalPages.subscribe(totalPages => {
      this.totalPages = totalPages;
    });
    this.incomeCategoryService.currentPage.subscribe(currentPage => {
      this.currentPage = currentPage;
    });
    this.incomeCategoryService.totalResults.subscribe(totalResults => {
      this.totalResults = totalResults;
    });
  }

  /**
   * When the component is initialised we set it to load
   * page one of the incomeCategory list.
   */
  public ngOnInit(): void {
    this.loadIncomeCategories(1);
  }

  /**
   * When we receive a page change from the pagination
   * component we load the appropriate page for the
   * incomeCategory list.
   *
   * @param {number} pageNumber
   */
  public onPageChanged(pageNumber: number): void {
    this.loadIncomeCategories(pageNumber);
  }

  /**
   * Loads a list of incomeCategories using the given per page limit.
   *
   * @param {number} page The current page we require.
   * @param {number} limit The number of results per page.
   */
  public loadIncomeCategories(page?: number, limit?: number): void {
    const listIncomeCategories = this.incomeCategoryService
      .listIncomeCategories(page, limit)
      .subscribe((incomeCategories: IncomeCategory[]) => {
        this.incomeCategories = incomeCategories;
        listIncomeCategories.unsubscribe();
      });
  }

  /**
   * When receiving confirmation of an item being deleted
   * we call the back end with the appropriate incomeCategory ID
   * to delete the incomeCategory.
   *
   * @param {string} id
   */
  public delete(id: string) {
    this.incomeCategoryService.deleteIncomeCategory(id).subscribe(
      deleted => {
        this.notificationService.success(
          `IncomeCategory Deleted`,
          `IncomeCategory with ID ${id} was deleted.`,
          5,
        );
        this.incomeCategoryDeleted.next(true);
        this.onPageChanged(this.currentPage);
      },
      err => {
        this.notificationService.error(
          `IncomeCategory Not Deleted`,
          err.error.message,
          10,
        );
        this.incomeCategoryDeleted.next(false);
        this.onPageChanged(this.currentPage);
      },
    );
  }
}
