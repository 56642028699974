<div class="row">
  <div class="col-sm-12">
    <section class="panel">
      <app-page-title
        title="Create Substatus"
        [loading]="isLoading"
      ></app-page-title>

      <div class="panel-body">
        <form class="form-horizontal" (ngSubmit)="create()" ngNativeValidate>
          <app-substatus-form
            [substatus]="substatus"
            *ngIf="substatus"
          ></app-substatus-form>
        </form>
      </div>
    </section>
  </div>
</div>
