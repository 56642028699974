import { Component, Input, OnInit } from '@angular/core';
import { ExpenditureCategoryService } from '../expenditure-category.service';
import { ExpenditureCategory } from '../expenditure-category';
import { NotificationService } from '../../shared/notification.service';
import { SelectComparisons } from '../../shared/pipes/select.comparisons';

@Component({
  selector: 'app-expenditure-category-select',
  templateUrl: 'select.component.html',
})
export class ExpenditureCategorySelectComponent extends SelectComparisons
  implements OnInit {
  /**
   * The model that we will be adding the expenditureCategory to.
   */
  @Input() model: any;

  /**
   * Do we require this field in the main form it is
   * included in?
   */
  @Input() required: boolean;

  /**
   * A list of all the expenditureCategories currently loaded.
   *
   * @type {ExpenditureCategory[]}
   */
  public expenditureCategories: ExpenditureCategory[] = [];

  /**
   * Are we currently performing a loading action?
   *
   * @type {boolean}
   */
  public isLoading: boolean = true;

  /**
   * Load up all our required services and subscribe to every observable we need.
   *
   * @param {ExpenditureCategoryService} expenditureCategoryService
   * @param {NotificationService} notificationService
   */
  constructor(
    private expenditureCategoryService: ExpenditureCategoryService,
    private notificationService: NotificationService,
  ) {
    super();
    this.expenditureCategoryService.loading.subscribe(isLoading => {
      this.isLoading = isLoading;
    });
  }

  /**
   * When the component is initialised we set it to load
   * page one of the expenditureCategory list.
   */
  public ngOnInit(): void {
    this.loadExpenditureCategories(1, 10000);
  }

  /**
   * Loads a list of expenditureCategories using the given per page limit.
   *
   * @param {number} page The current page we require.
   * @param {number} limit The number of results per page.
   */
  public loadExpenditureCategories(page?: number, limit?: number): void {
    const listExpenditureCategories = this.expenditureCategoryService
      .listExpenditureCategories(page, limit)
      .subscribe((expenditureCategories: ExpenditureCategory[]) => {
        this.expenditureCategories = expenditureCategories;
        listExpenditureCategories.unsubscribe();
      });
  }
}
