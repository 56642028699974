<div class="input-group">
  <span class="input-group-addon">&pound;</span>
  <input
    type="number"
    step="0.01"
    class="form-control"
    name="{{ name }}"
    id="value"
    [min]="minValue"
    [max]="max"
    value="{{ currentValue }}"
    (change)="changeValue($event)"
    [required]="isRequired()"
    [disabled]="disabled"
  />
</div>
