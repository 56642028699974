import { HttpRequestService } from '../shared/http/http-request.service';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Substatus } from './substatus';

@Injectable()
export class SubstatusService extends HttpRequestService {
  /**
   * The endpoint that we call to get the substatus information.
   *
   * @type {string}
   */
  private readonly endpoint: string = 'substatuses';

  /**
   * Brings back a list of all accessible substatuses.
   *
   * @param {number} page The page number we are currently on.
   * @param {number} limit The number of substatuses to display per page.
   * @returns {Observable<Substatus[]>}
   */
  public listSubstatuses(
    page?: number,
    limit?: number,
  ): Observable<Substatus[]> {
    return this.list(this.endpoint, page, undefined, limit);
  }

  /**
   * Finds details of the substatus from the given ID.
   *
   * @param {string} substatusId The ID of the substatus we require details for.
   * @returns {Observable<Substatus>}
   */
  public findSubstatus(substatusId: string): Observable<Substatus> {
    return this.findById(this.endpoint, substatusId);
  }

  /**
   * Creates a new substatus from the given Substatus object.
   *
   * @param {Substatus} newSubstatus The details of the substatus to be created.
   * @returns {Observable<Substatus | boolean>}
   */
  public createSubstatus(
    newSubstatus: Substatus,
  ): Observable<Substatus | boolean> {
    return this.create(this.endpoint, newSubstatus);
  }

  /**
   * Updates a substatus with the given substatus object for the given substatus ID.
   *
   * @param {string} substatusId The ID of the substatus needing updating.
   * @param {Substatus} newDetails The new substatus object to update with.
   * @returns {Observable<Substatus | boolean>}
   */
  public updateSubstatus(
    substatusId: string,
    newDetails: Substatus,
  ): Observable<Substatus | boolean> {
    return this.update(this.endpoint, substatusId, newDetails);
  }

  /**
   * Deletes a substatus based on the given substatus ID.
   *
   * @param {string} substatusId The ID of the substatus needing deleting.
   * @returns {Observable<boolean>}
   */
  public deleteSubstatus(substatusId: string): Observable<boolean> {
    return this.delete(this.endpoint, substatusId);
  }
}
