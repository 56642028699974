import { Injectable } from '@angular/core';
import { DeliveryMethod } from './delivery-method';
import { Observable } from 'rxjs';

import { HttpRequestService } from '../shared/http/http-request.service';

@Injectable()
export class DeliveryMethodService extends HttpRequestService {
  /**
   * The endpoint that we call to get the deliveryMethod information.
   *
   * @type {string}
   */
  private readonly endpoint: string = 'delivery-methods';

  /**
   * Brings back a list of all accessible delivery-methods.
   *
   * @param {number} page The page number we are currently on.
   * @param {number} limit The number of delivery-methods to display per page.
   * @returns {Observable<DeliveryMethod[]>}
   */
  public listDeliveryMethods(
    page?: number,
    limit?: number,
  ): Observable<DeliveryMethod[]> {
    return this.list(this.endpoint, page, undefined, limit);
  }

  /**
   * Finds details of the deliveryMethod from the given ID.
   *
   * @param {string} deliveryMethodId The ID of the deliveryMethod we require details for.
   * @returns {Observable<DeliveryMethod>}
   */
  public findDeliveryMethod(
    deliveryMethodId: string,
  ): Observable<DeliveryMethod> {
    return this.findById(this.endpoint, deliveryMethodId);
  }

  /**
   * Creates a new deliveryMethod from the given DeliveryMethod object.
   *
   * @param {DeliveryMethod} newDeliveryMethod The details of the deliveryMethod to be created.
   * @returns {Observable<DeliveryMethod | boolean>}
   */
  public createDeliveryMethod(
    newDeliveryMethod: DeliveryMethod,
  ): Observable<DeliveryMethod | boolean> {
    return this.create(this.endpoint, newDeliveryMethod);
  }

  /**
   * Updates a deliveryMethod with the given deliveryMethod object for the given deliveryMethod ID.
   *
   * @param {string} deliveryMethodId The ID of the deliveryMethod needing updating.
   * @param {DeliveryMethod} newDetails The new deliveryMethod object to update with.
   * @returns {Observable<DeliveryMethod | boolean>}
   */
  public updateDeliveryMethod(
    deliveryMethodId: string,
    newDetails: DeliveryMethod,
  ): Observable<DeliveryMethod | boolean> {
    return this.update(this.endpoint, deliveryMethodId, newDetails);
  }

  /**
   * Deletes a deliveryMethod based on the given deliveryMethod ID.
   *
   * @param {string} deliveryMethodId The ID of the deliveryMethod needing deleting.
   * @returns {Observable<boolean>}
   */
  public deleteDeliveryMethod(deliveryMethodId: string): Observable<boolean> {
    return this.delete(this.endpoint, deliveryMethodId);
  }
}
