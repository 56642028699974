import { Component, Input, OnInit } from '@angular/core';

import { BankingAccountItem } from '../banking-account-item';
import { BankingAccountItemSelectService } from '../banking-account-item-select.service';
import { BankingAccountItemService } from '../banking-account-item.service';
import { NotificationService } from '../../../../shared/notification.service';
import { SelectComparisons } from '../../../../shared/pipes/select.comparisons';

@Component({
  selector: 'app-banking-account-item-select',
  templateUrl: 'select.component.html',
})
export class BankingAccountItemSelectComponent extends SelectComparisons
  implements OnInit {
  @Input() accountId: string;
  @Input() reconcileOptions: string = 'all';
  @Input() filter: string = undefined;
  @Input() public expectedValue: number = 0;
  @Input() public reconcilingId: string;

  @Input() public reconcileSelected: any[] = [];

  public makePositive(value: number) {
    return value < 0 ? value * -1 : value;
  }

  /**
   * The model that we will be adding the accountItem to.
   */
  @Input() model: any;

  /**
   * Do we require this field in the main form it is
   * included in?
   */
  @Input() required: boolean;

  /**
   * A list of all the banking-account-items currently loaded.
   *
   * @item {BankingAccountItem[]}
   */
  public accountItems: BankingAccountItem[] = [];

  /**
   * Are we currently performing a loading action?
   *
   * @item {boolean}
   */
  public isLoading: boolean = true;

  /**
   * The total number of pages in this list of banking-account-items.
   *
   * @item {number}
   */
  public totalPages: number = 1;

  /**
   * The current page of banking-account-items that we are viewing.
   *
   * @item {number}
   */
  public currentPage: number = 1;

  public currentMonetaryValue: number = 0;

  /**
   * The total number of banking-account-items that can be viewed in the system.
   *
   * @item {number}
   */
  public totalResults: number = 0;

  /**
   * Load up all our required services and subscribe to every observable we need.
   *
   * @param {BankingAccountItemService} accountItemService
   * @param {NotificationService} notificationService
   */
  constructor(
    private accountItemService: BankingAccountItemSelectService,
    private notificationService: NotificationService,
  ) {
    super();
    this.accountItemService.loading.subscribe((isLoading) => {
      this.isLoading = isLoading;
    });
    this.accountItemService.loading.subscribe((isLoading) => {
      this.isLoading = isLoading;
    });
    this.accountItemService.totalPages.subscribe((totalPages) => {
      this.totalPages = totalPages;
    });
    this.accountItemService.currentPage.subscribe((currentPage) => {
      this.currentPage = currentPage;
    });
    this.accountItemService.totalResults.subscribe((totalResults) => {
      this.totalResults = totalResults;
    });
  }

  /**
   * When the component is initialised we set it to load
   * page one of the accountItem list.
   */
  public ngOnInit(): void {
    this.loadBankingAccountItems(1, 5);
  }

  public isSelected(id: string): boolean {
    let isSelected: boolean = false;

    this.reconcileSelected.forEach((item) => {
      if (item === id) {
        isSelected = true;
      }
    });

    return isSelected;
  }

  public toggleItem(id: string, value: number) {
    if (this.isSelected(id)) {
      this.reconcileSelected.splice(this.reconcileSelected.indexOf(id), 1);
      this.currentMonetaryValue = this.currentMonetaryValue - value;
    } else {
      this.reconcileSelected.push(id);
      this.currentMonetaryValue = this.currentMonetaryValue + value;
    }
  }

  /**
   * When we receive a page change from the pagination
   * component we load the appropriate page for the
   * accountItem list.
   *
   * @param {number} pageNumber
   */
  public onPageChanged(pageNumber: number): void {
    this.loadBankingAccountItems(pageNumber, 5);
  }

  /**
   * Loads a list of banking-account-items using the given per page limit.
   *
   * @param {number} page The current page we require.
   * @param {number} limit The number of results per page.
   */
  public loadBankingAccountItems(page?: number, limit?: number): void {
    const query: any = {};
    if (this.reconcileOptions !== 'all') {
      query['reconciled'] = this.reconcileOptions === 'reconciled';
    }
    if (this.filter !== undefined) {
      query['filter'] = this.filter;
    }

    const listBankingAccountItems = this.accountItemService
      .listBankingAccountItems(
        page,
        limit,
        { parameters: { accountId: this.accountId } },
        query,
      )
      .subscribe((accountItems: BankingAccountItem[]) => {
        this.accountItems = accountItems.map((item) => {
          item.isChecking = true;
          item.isAllowed = false;

          this.accountItemService
            .canReconcile(this.reconcilingId, item.id)
            .subscribe((r) => {
              item.isChecking = false;
              item.isAllowed = r.success;
            });

          return item;
        });

        console.log(this.accountItems);

        listBankingAccountItems.unsubscribe();
      });
  }
}
