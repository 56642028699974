import { Component, Input } from '@angular/core';
import { AccountType } from '../account-type';

@Component({
  selector: 'app-account-type-form',
  templateUrl: 'form.component.html',
})
export class AccountTypeFormComponent {
  @Input() accountType: AccountType;
}
