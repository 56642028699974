import { Component, EventEmitter, OnInit } from '@angular/core';
import { LetterheadService } from '../letterhead.service';
import { Letterhead } from '../letterhead';
import { NotificationService } from '../../shared/notification.service';

@Component({
  selector: 'app-letterhead-list',
  templateUrl: 'list.component.html',
  styleUrls: [],
})
export class LetterheadListComponent implements OnInit {
  public letterhead: Letterhead;

  /**
   * A list of all the letterheads currently loaded.
   *
   * @type {Letterhead[]}
   */
  public letterheads: Letterhead[] = [];

  /**
   * Are we currently performing a loading action?
   *
   * @type {boolean}
   */
  public isLoading: boolean = true;

  /**
   * The total number of pages in this list of letterheads.
   *
   * @type {number}
   */
  public totalPages: number = 1;

  /**
   * The current page of letterheads that we are viewing.
   *
   * @type {number}
   */
  public currentPage: number = 1;

  /**
   * The total number of letterheads that can be viewed in the system.
   *
   * @type {number}
   */
  public totalResults: number = 0;

  /**
   * An Emitter to alert other components when a letterhead has been deleted.
   *
   * @type {EventEmitter<boolean>}
   */
  public letterheadDeleted: EventEmitter<boolean> = new EventEmitter<boolean>();

  /**
   * Load up all our required services and subscribe to every observable we need.
   *
   * @param {LetterheadService} letterheadService
   * @param {NotificationService} notificationService
   */
  constructor(
    private letterheadService: LetterheadService,
    private notificationService: NotificationService,
  ) {
    this.letterheadService.loading.subscribe(isLoading => {
      this.isLoading = isLoading;
    });
    this.letterheadService.totalPages.subscribe(totalPages => {
      this.totalPages = totalPages;
    });
    this.letterheadService.currentPage.subscribe(currentPage => {
      this.currentPage = currentPage;
    });
    this.letterheadService.totalResults.subscribe(totalResults => {
      this.totalResults = totalResults;
    });
  }

  /**
   * When the component is initialised we set it to load
   * page one of the letterhead list.
   */
  public ngOnInit(): void {
    this.loadLetterheads(1);
  }

  /**
   * When we receive a page change from the pagination
   * component we load the appropriate page for the
   * letterhead list.
   *
   * @param {number} pageNumber
   */
  public onPageChanged(pageNumber: number): void {
    this.loadLetterheads(pageNumber);
  }

  /**
   * Loads a list of letterheads using the given per page limit.
   *
   * @param {number} page The current page we require.
   * @param {number} limit The number of results per page.
   */
  public loadLetterheads(page?: number, limit?: number): void {
    const listLetterheads = this.letterheadService
      .listLetterheads(page, limit)
      .subscribe((letterheads: Letterhead[]) => {
        this.letterheads = letterheads;
        listLetterheads.unsubscribe();
      });
  }

  /**
   * When receiving confirmation of an item being deleted
   * we call the back end with the appropriate letterhead ID
   * to delete the letterhead.
   *
   * @param {string} id
   */
  public delete(id: string) {
    this.letterheadService.deleteLetterhead(id).subscribe(
      deleted => {
        this.notificationService.success(
          `Letterhead Deleted`,
          `Letterhead with ID ${id} was deleted.`,
          5,
        );
        this.letterheadDeleted.next(true);
        this.onPageChanged(this.currentPage);
      },
      err => {
        this.notificationService.error(
          `Letterhead Not Deleted`,
          err.error.message,
          10,
        );
        this.letterheadDeleted.next(false);
        this.onPageChanged(this.currentPage);
      },
    );
  }
}
