import {LinkWrapper} from 'react-inside-angular';
import {Link as RRDLink} from 'react-router-dom';

export type LinkButtonProps = {
  Navigate?: any;
  color: string;
  title: string;
  to: string;
  className?: string;
};

export const buttonStyle = (
  color: string,
  className: string = '',
  hasNavigation: boolean = false,
  padX: number = 2,
  padY: number = 1,
) =>
  ` tw-w-max tw-capitalize tw-rounded-md tw-text-white tw-font-semibold hover:tw-bg-${color}-500 tw-px-${padX} tw-py-${padY} tw-bg-${color}-400 ${className}`;

const LinkButton = ({
  Navigate,
  color,
  title,
  to,
  className,
}: LinkButtonProps) => {
  const Link: any = Navigate ? LinkWrapper(Navigate).Component : RRDLink;

  return (
    <Link
      to={to}
      className={`${buttonStyle(color, '', !!Navigate, 3, 2)} ${className}`}
    >
      {title}
    </Link>
  );
};

export default LinkButton;
