<div class="row">
  <div class="col-sm-12">
    <section class="panel">
        <app-page-title [loading]="false" title="List Employment Statuses">

        </app-page-title>

      <div class="panel-body">
        <table class="table table-striped">
          <thead>
          <tr>
            <th>Employment Status Name</th>
            <th class="text-right">
              <a [routerLink]="['/employment-statuses/create']" class="btn btn-success">Create</a>
            </th>
          </tr>
          </thead>

          <tbody>
          <tr *ngFor="let employmentStatus of employmentStatuses$ | async">
            <td>{{ employmentStatus?.name }}</td>
            <td class="text-right">
              <div class="btn-group">
                  <a [routerLink]="['/employment-statuses', employmentStatus?.id, 'edit']"
                     class="btn btn-sm btn-warning"
                     type="button">Edit</a>
                  <button aria-expanded="false" aria-haspopup="true"
                          class="btn btn-sm btn-warning dropdown-toggle" data-toggle="dropdown"
                          type="button">
                  <span class="caret"></span>
                  <span class="sr-only">Toggle Dropdown</span>
                </button>
                <ul class="dropdown-menu">
                    <li><a (click)="deleteModal.confirmDialog(employmentStatus.id)" href="javascript:">Delete</a>
                    </li>
                </ul>
              </div>
            </td>
          </tr>
          </tbody>

          <tfoot>
          <tr>
              <td class="text-center" colspan="99">
                  <!--              <app-pagination (pageChanged)="onPageChanged($event)" [currentPage]="currentPage"-->
                  <!--                              [totalPages]="totalPages"></app-pagination>-->
            </td>
          </tr>
          </tfoot>
        </table>
      </div>
    </section>
  </div>
</div>

<app-delete-modal #deleteModal (confirm)="delete($event)"
                  [itemDeleted]="employmentStatusDeleted"
                  type="employmentStatus"></app-delete-modal>
