<div
  class="modal fade"
  bsModal
  #staticModal="bs-modal"
  [config]="{ backdrop: 'static' }"
  tabindex="-1"
  role="dialog"
  aria-labelledby="mySmallModalLabel"
  aria-hidden="true"
>
  <div class="modal-dialog modal-md">
    <form class="form-horizontal" (submit)="submit()" ngNativeValidate>
      <div class="modal-content panel-success">
        <div class="modal-header">
          <h4 class="modal-title pull-left">Extend IVA Duration</h4>
          <button
            type="button"
            class="close pull-right"
            aria-label="Close"
            (click)="close()"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <div class="form-group">
            <label for="extension" class="col-sm-4 control-label"
              >Extension Length (months)</label
            >
            <div class="col-sm-8">
              <input
                required
                type="number"
                min="1"
                step="1"
                id="extension"
                class="form-control"
                [(ngModel)]="extensionDuration"
              />
            </div>
          </div>
        </div>
        <div class="modal-body">
          <div class="form-group">
            <label for="reason" class="col-sm-4 control-label">Reason</label>
            <div class="col-sm-8">
              <textarea
                required
                id="reason"
                class="form-control"
                [(ngModel)]="extensionReason"
              ></textarea>
            </div>
          </div>
        </div>
        <div class="modal-footer text-right">
          <button type="button" class="btn btn-default" (click)="close()">
            Cancel
          </button>
          <button type="submit" class="btn btn-success" [disabled]="disabled">
            Save
          </button>
        </div>
      </div>
    </form>
  </div>
</div>
