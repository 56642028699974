<div class="row">
  <div *ngIf="totalVotes > 0" class="col-md-10">
    <div
      [class.col-md-3]="votingSplit.declined / totalVotes >= 0.25"
      [class.col-md-4]="votingSplit.accepted / totalVotes < 0.75"
      [class.col-md-6]="votingSplit.accepted / totalVotes >= 0.75"
      class="bg-success text-white text-center text-bold"
      style="padding: 6px 0"
    >
      {{ votingSplit.accepted / totalVotes | percent }}
      Approved
    </div>
    <div
      [class.col-md-3]="votingSplit.accepted / totalVotes >= 0.75"
      [class.col-md-4]="votingSplit.declined / totalVotes < 0.25"
      [class.col-md-6]="votingSplit.declined / totalVotes >= 0.25"
      class="bg-danger text-center text-bold"
      style="padding: 6px 0"
    >
      {{ votingSplit.declined / totalVotes | percent }} Rejected
    </div>
    <div
      [class.col-md-3]="
        votingSplit.accepted / totalVotes >= 0.75 ||
        votingSplit.declined / totalVotes >= 0.25
      "
      [class.col-md-4]="
        !(
          votingSplit.accepted / totalVotes >= 0.75 ||
          votingSplit.declined / totalVotes >= 0.25
        )
      "
      class="bg-warning text-center text-bold"
      style="padding: 6px 0"
    >
      {{ votingSplit.pending / totalVotes | percent }} Pending
    </div>
  </div>

  <div
    [class.col-md-12]="totalVotes === 0"
    [class.col-md-2]="totalVotes > 0"
    class="text-right"
  >
    <button
      (click)="create.open(caseId)"
      class="btn btn-sm btn-success"
      href="javascript:;"
      type="button"
    >
      Add Creditor
    </button>
  </div>
</div>

<table class="creditors table table-striped" style="margin-top: 20px">
  <thead>
    <tr>
      <th class="text-center" width="40"></th>
      <th width="80"></th>
      <th>Creditor</th>
      <th>Reference</th>
      <th width="150">SOA Value</th>
      <th width="150">Value</th>
      <th>Applicant</th>
      <th>Vote</th>
      <th>Paid / Retained</th>
      <th>%</th>
      <th width="100"></th>
    </tr>
  </thead>
  <tbody>
    <tr *ngFor="let caseCreditor of caseCreditors">
      <td>
        <app-user-icon
          *ngIf="caseCreditor.user"
          [passedUser]="caseCreditor.user"
          [size]="20"
          [userId]="caseCreditor.user.id"
        ></app-user-icon>
      </td>
      <td class="text-center">
        <span
          [class.fa-lock]="caseCreditor.secured"
          [class.fa-unlock]="!caseCreditor.secured"
          [class.text-danger]="caseCreditor.secured"
          [class.text-success]="!caseCreditor.secured"
          class="fa fa-fw"
          container="body"
          tooltip="Secured Creditor"
        ></span>
        <span
          [class.fa-check]="caseCreditor.excluded === false"
          [class.fa-times]="caseCreditor.excluded === true"
          [class.text-danger]="caseCreditor.excluded === true"
          [class.text-success]="caseCreditor.excluded === false"
          class="fa fa-fw"
          container="body"
          tooltip="Excluded from IVA"
        ></span>
      </td>
      <td
        [class.text-danger]="
          !caseCreditor ||
          !caseCreditor.creditor ||
          !caseCreditor.creditor.address1 ||
          caseCreditor.creditor.address1.length <= 2
        "
        [class.text-success]="
          caseCreditor &&
          caseCreditor.creditor &&
          caseCreditor.creditor.address1 &&
          caseCreditor.creditor.address1.length > 2
        "
      >
        {{ caseCreditor.creditor ? caseCreditor.creditor.name : 'Unknown' }}
      </td>

      <td>{{ caseCreditor.reference }}</td>

      <td>{{ caseCreditor.value / 100 | toCurrency }}</td>

      <td
        *ngIf="
          caseCreditor.admittedValue === 0 &&
          caseCreditor.actualValue === 0 &&
          caseCreditor.debtPaid === false
        "
        class="text-danger"
        container="body"
        tooltip="This is currently an estimated value"
      >
        <i class="fa fa-question fa-fw"></i>
        {{ caseCreditor.value / 100 | toCurrency }}<br />{{
          caseCreditor?.creditorStatus?.name
        }}
      </td>
      <td
        *ngIf="
          (caseCreditor.admittedValue === 0 && caseCreditor.actualValue > 0) ||
          caseCreditor.debtPaid === true
        "
        [class]="
          caseCreditor.debtPaid === true ? 'text-success' : 'text-warning'
        "
        container="body"
        tooltip="This value has been confirmed via POD"
      >
        <i class="fa fa-check fa-fw"></i>
        {{ caseCreditor.actualValue / 100 | toCurrency }}<br />{{
          caseCreditor?.creditorStatus?.name
        }}
      </td>
      <td
        *ngIf="caseCreditor.admittedValue > 0"
        class="text-success"
        container="body"
        tooltip="This value has been admitted to"
      >
        <i class="fa fa-shield fa-fw"></i>
        {{ caseCreditor.admittedValue / 100 | toCurrency }}<br />{{
          caseCreditor?.creditorStatus?.name
        }}
      </td>

      <td *ngIf="caseCreditor.person">
        {{ caseCreditor?.person?.firstName }}
        {{ caseCreditor?.person?.lastName }}
      </td>
      <td *ngIf="!caseCreditor.person">Joint</td>
      <td>
        {{
          caseCreditor && caseCreditor.votingStatus
            ? capsString(caseCreditor.votingStatus)
            : ''
        }}
      </td>
      <td>
        <span
          *ngIf="
            !['Excluded', 'Secured'].includes(
              caseCreditor?.creditorStatus?.name
            )
          "
        >
          {{ caseCreditor.paidValue / 100 | toCurrency }}<br />{{
            caseCreditor?.creditorStatus?.name === 'Admitted'
              ? 'Paid'
              : 'Retained'
          }}
        </span>
      </td>
      <td>
        <span
          *ngIf="
            !['Excluded', 'Secured'].includes(
              caseCreditor?.creditorStatus?.name
            )
          "
        >
          {{
            getCurrentValue(caseCreditor) === 0
              ? 0
              : (
                  (caseCreditor.paidValue / getCurrentValue(caseCreditor)) *
                  100
                ).toFixed(2)
          }}%
        </span>
      </td>
      <td class="text-right">
        <div class="btn-group">
          <a
            (click)="edit.open(caseId, caseCreditor)"
            class="btn btn-xs btn-warning"
            type="button"
            >Edit</a
          >
          <button
            *ngIf="authService.hasPermission('user.delete-cases')"
            aria-expanded="false"
            aria-haspopup="true"
            class="btn btn-xs btn-warning dropdown-toggle"
            data-toggle="dropdown"
            type="button"
          >
            <span
              *ngIf="authService.hasPermission('user.delete-cases')"
              class="caret"
            ></span>
            <span
              *ngIf="authService.hasPermission('user.delete-cases')"
              class="sr-only"
              >Toggle Dropdown</span
            >
          </button>
          <ul
            *ngIf="authService.hasPermission('user.delete-cases')"
            class="dropdown-menu"
          >
            <li *ngIf="authService.hasPermission('user.delete-cases')">
              <a
                (click)="deleteModal.confirmDialog(caseCreditor.id)"
                href="javascript:"
                >Delete</a
              >
            </li>
          </ul>
        </div>
      </td>
    </tr>
  </tbody>
  <tfoot>
    <tr>
      <td colspan="4">Unsecured Total</td>
      <td>
        {{ sumSOAColumn('unsecured') / 100 | toCurrency }}
      </td>
      <td>
        {{ sumValueColumn('unsecured') / 100 | toCurrency }}
      </td>
      <td colspan="2"></td>
      <td>
        {{ sumPaidValueColumn('unsecured') / 100 | toCurrency }}
      </td>

      <td>
        {{
          (sumValueColumn('unsecured') === 0
            ? 0
            : (sumPaidValueColumn('unsecured') / sumValueColumn('unsecured')) *
              100
          ).toFixed(2)
        }}%
      </td>

      <td colspan="99"></td>
    </tr>

    <tr>
      <td colspan="4">Excluded Total</td>
      <td>
        {{ sumSOAColumn('excluded') / 100 | toCurrency }}
      </td>
      <td>
        {{ sumValueColumn('excluded') / 100 | toCurrency }}
      </td>
      <td colspan="2"></td>
      <td>
        {{ sumPaidValueColumn('excluded') / 100 | toCurrency }}
      </td>

      <td>
        {{
          (sumValueColumn('excluded') === 0
            ? 0
            : (sumPaidValueColumn('excluded') / sumValueColumn('excluded')) *
              100
          ).toFixed(2)
        }}%
      </td>

      <td colspan="99"></td>
    </tr>

    <tr>
      <td colspan="4">Secured Total</td>
      <td>
        {{ sumSOAColumn('secured') / 100 | toCurrency }}
      </td>
      <td>
        {{ sumValueColumn('secured') / 100 | toCurrency }}
      </td>
      <td colspan="2"></td>
      <td>
        {{ sumPaidValueColumn('secured') / 100 | toCurrency }}
      </td>

      <td>
        {{
          (sumValueColumn('secured') === 0
            ? 0
            : (sumPaidValueColumn('secured') / sumValueColumn('secured')) * 100
          ).toFixed(2)
        }}%
      </td>

      <td colspan="99"></td>
    </tr>

    <tr>
      <td colspan="4"><strong>Total</strong></td>
      <td>
        <strong>{{ sumSOAColumn() / 100 | toCurrency }}</strong>
      </td>
      <td>
        <strong>{{ sumValueColumn() / 100 | toCurrency }}</strong>
      </td>
      <td colspan="2"></td>
      <td>
        <strong>{{ sumPaidValueColumn() / 100 | toCurrency }}</strong>
      </td>

      <td>
        <strong
          >{{
            (sumValueColumn() === 0
              ? 0
              : (sumPaidValueColumn() / sumValueColumn()) * 100
            ).toFixed(2)
          }}%</strong
        >
      </td>

      <td colspan="99"></td>
    </tr>

    <tr>
      <td class="text-center" colspan="99">
        <app-pagination
          (pageChanged)="onPageChanged($event)"
          [currentPage]="currentPage"
          [totalPages]="totalPages"
        ></app-pagination>
      </td>
    </tr>
  </tfoot>
</table>

<app-delete-modal
  #deleteModal
  (confirm)="delete($event)"
  [itemDeleted]="itemDeleted"
  type="creditor"
></app-delete-modal>

<app-case-creditor-create
  #create
  (completed)="onPageChanged(1)"
></app-case-creditor-create>

<app-case-creditor-edit
  #edit
  (completed)="onPageChanged(1)"
></app-case-creditor-edit>
