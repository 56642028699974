import { Component, Input, OnInit } from '@angular/core';
import { Domain } from '../../domains/domain';
import { DomainService } from '../../domains/domain.service';
import * as urlencode from 'urlencode';

@Component({
  selector: 'app-domain-icon',
  templateUrl: './domain-icon.component.html',
  styleUrls: ['./domain-icon.component.css'],
})
export class DomainIconComponent implements OnInit {
  @Input() domainId: string;

  @Input() size: number;

  @Input() clickable: boolean = false;

  public domain: Domain;

  @Input() passedDomain: Domain;

  public loading: boolean = true;

  public constructor(private readonly domainService: DomainService) {}

  public ngOnInit(): void {
    if (this.passedDomain) {
      this.domain = this.passedDomain;
      this.loading = false;
    } else {
      this.domainService
        .findDomain(this.domainId)
        .subscribe((domain: Domain) => {
          this.loading = false;
          this.domain = domain;
        });
    }
  }

  public getDomainAvatar(): string {
    return (
      this.domain.avatar ||
      `https://ui-avatars.com/api/?name=${urlencode(
        this.getUppercaseCharacters(this.domain.code),
      )}&length=${this.countUppercaseCharacters(
        this.domain.code,
      )}&background=${this.intToRGB(
        this.hashCode(urlencode(this.domain.code)),
      )}&color=fff&size=200&rounded=false`
    );
  }

  private hashCode(str) {
    // java String#hashCode
    let hash = 0;
    for (let i = 0; i < str.length; i++) {
      hash = str.charCodeAt(i) + ((hash << 5) - hash);
    }
    return hash;
  }

  private intToRGB(i) {
    let c = (i & 0x00ffffff).toString(16).toUpperCase();

    return '00000'.substring(0, 6 - c.length) + c;
  }

  private countUppercaseCharacters(name: string): number {
    return name.replace(/[^A-Z]/g, '').length;
  }

  private getUppercaseCharacters(name: string): string {
    let characters = name.match(/[A-Z]/g);

    return characters.join(' ');
  }
}
