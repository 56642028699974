import { Component, EventEmitter, OnInit } from '@angular/core';
import { PackService } from '../pack.service';
import { Pack } from '../pack';
import { NotificationService } from '../../shared/notification.service';

@Component({
  selector: 'app-pack-list',
  templateUrl: 'list.component.html',
  styleUrls: [],
})
export class PackListComponent implements OnInit {
  public pack: Pack;

  /**
   * A list of all the packs currently loaded.
   *
   * @type {Pack[]}
   */
  public packs: Pack[] = [];

  /**
   * Are we currently performing a loading action?
   *
   * @type {boolean}
   */
  public isLoading: boolean = true;

  /**
   * The total number of pages in this list of packs.
   *
   * @type {number}
   */
  public totalPages: number = 1;

  /**
   * The current page of packs that we are viewing.
   *
   * @type {number}
   */
  public currentPage: number = 1;

  /**
   * The total number of packs that can be viewed in the system.
   *
   * @type {number}
   */
  public totalResults: number = 0;

  /**
   * An Emitter to alert other components when a pack has been deleted.
   *
   * @type {EventEmitter<boolean>}
   */
  public packDeleted: EventEmitter<boolean> = new EventEmitter<boolean>();

  /**
   * Load up all our required services and subscribe to every observable we need.
   *
   * @param {PackService} packService
   * @param {NotificationService} notificationService
   */
  constructor(
    private packService: PackService,
    private notificationService: NotificationService,
  ) {
    this.packService.loading.subscribe(isLoading => {
      this.isLoading = isLoading;
    });
    this.packService.totalPages.subscribe(totalPages => {
      this.totalPages = totalPages;
    });
    this.packService.currentPage.subscribe(currentPage => {
      this.currentPage = currentPage;
    });
    this.packService.totalResults.subscribe(totalResults => {
      this.totalResults = totalResults;
    });
  }

  /**
   * When the component is initialised we set it to load
   * page one of the pack list.
   */
  public ngOnInit(): void {
    this.loadPacks(1);
  }

  /**
   * When we receive a page change from the pagination
   * component we load the appropriate page for the
   * pack list.
   *
   * @param {number} pageNumber
   */
  public onPageChanged(pageNumber: number): void {
    this.loadPacks(pageNumber);
  }

  /**
   * Loads a list of packs using the given per page limit.
   *
   * @param {number} page The current page we require.
   * @param {number} limit The number of results per page.
   */
  public loadPacks(page?: number, limit?: number): void {
    const listPacks = this.packService
      .listPacks(page, limit)
      .subscribe((packs: Pack[]) => {
        this.packs = packs;
        listPacks.unsubscribe();
      });
  }

  /**
   * When receiving confirmation of an item being deleted
   * we call the back end with the appropriate pack ID
   * to delete the pack.
   *
   * @param {string} id
   */
  public delete(id: string) {
    this.packService.deletePack(id).subscribe(
      deleted => {
        this.notificationService.success(
          `Pack Deleted`,
          `Pack with ID ${id} was deleted.`,
          5,
        );
        this.packDeleted.next(true);
        this.onPageChanged(this.currentPage);
      },
      err => {
        this.notificationService.error(
          `Pack Not Deleted`,
          err.error.message,
          10,
        );
        this.packDeleted.next(false);
        this.onPageChanged(this.currentPage);
      },
    );
  }
}
