import { Injectable } from '@angular/core';
import { Pack } from './pack';
import { Observable } from 'rxjs';

import { HttpRequestService } from '../shared/http/http-request.service';

@Injectable()
export class PackService extends HttpRequestService {
  /**
   * The endpoint that we call to get the pack information.
   *
   * @type {string}
   */
  private readonly endpoint: string = 'packs';

  /**
   * Brings back a list of all accessible packs.
   *
   * @param {number} page The page number we are currently on.
   * @param {number} limit The number of packs to display per page.
   * @returns {Observable<Pack[]>}
   */
  public listPacks(page?: number, limit?: number): Observable<Pack[]> {
    return this.list(this.endpoint, page, undefined, limit);
  }

  /**
   * Finds details of the pack from the given ID.
   *
   * @param {string} packId The ID of the pack we require details for.
   * @returns {Observable<Pack>}
   */
  public findPack(packId: string): Observable<Pack> {
    return this.findById(this.endpoint, packId);
  }

  /**
   * Creates a new pack from the given Pack object.
   *
   * @param {Pack} newPack The details of the pack to be created.
   * @returns {Observable<Pack | boolean>}
   */
  public createPack(newPack: Pack): Observable<Pack | boolean> {
    return this.create(this.endpoint, newPack);
  }

  /**
   * Updates a pack with the given pack object for the given pack ID.
   *
   * @param {string} packId The ID of the pack needing updating.
   * @param {Pack} newDetails The new pack object to update with.
   * @returns {Observable<Pack | boolean>}
   */
  public updatePack(
    packId: string,
    newDetails: Pack,
  ): Observable<Pack | boolean> {
    return this.update(this.endpoint, packId, newDetails);
  }

  /**
   * Deletes a pack based on the given pack ID.
   *
   * @param {string} packId The ID of the pack needing deleting.
   * @returns {Observable<boolean>}
   */
  public deletePack(packId: string): Observable<boolean> {
    return this.delete(this.endpoint, packId);
  }
}
