<!-- Delete Modal -->
<div #createModalComponent class="modal fade">
  <div class="modal-dialog modal-lg" role="document">
    <form #completedForm="ngForm" (submit)="confirmCreation($event, completedForm)" ngNativeValidate>
      <div class="modal-content panel-success">
        <div class="modal-header panel-heading">
          <button (click)="cancelCreation()" aria-label="Close" class="close" type="button">
            <span aria-hidden="true">&times;</span>
          </button>
          <h4 class="modal-title" id="myModalLabel">Create new {{ type || 'item' }}</h4>
        </div>
        <div class="modal-body">
          <ng-content></ng-content>
        </div>
        <div class="modal-footer">
          <button (click)="cancelCreation()" class="btn btn-default" type="button">Cancel</button>
          <button [disabled]="disabled" class="btn btn-success" type="submit">Create</button>
        </div>
      </div>
    </form>
  </div>
</div>
