import {HttpClientModule} from '@angular/common/http';
import {NgModule} from '@angular/core';
import {AngularFireModule} from '@angular/fire';
import {AngularFirestoreModule} from '@angular/fire/firestore';
import {FormsModule} from '@angular/forms';
import {BrowserModule} from '@angular/platform-browser';
import {RouterModule, Routes} from '@angular/router';
import {FileUploadModule} from 'ng2-file-upload';
import {ModalModule} from 'ngx-bootstrap/modal';

import {AuthGuard} from '../../auth/auth.guard';
import {SharedModule} from '../../shared/shared.module';
import {FinancialsInvoicingService} from './financials-invoicing.service';
import {FinancialsInvoiceListComponent} from './list/list.component';
import {FinancialsInvoiceReconcileComponent} from './reconcile/reconcile.component';
import {FinancialsInvoiceUploadComponent} from './upload/upload.component';

const routes: Routes = [
  {
    path: 'financials/invoicing/:id',
    component: FinancialsInvoiceReconcileComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'financials/invoicing',
    component: FinancialsInvoiceListComponent,
    canActivate: [AuthGuard],
  },
];

@NgModule({
  declarations: [
    FinancialsInvoiceListComponent,
    FinancialsInvoiceUploadComponent,
    FinancialsInvoiceReconcileComponent,
  ],
  imports: [
    ModalModule.forRoot(),
    FileUploadModule,
    BrowserModule,
    RouterModule,
    FormsModule,
    HttpClientModule,
    SharedModule,
    RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' }),
    AngularFireModule,
    AngularFirestoreModule,
  ],
  exports: [RouterModule],
  providers: [AuthGuard, FinancialsInvoicingService],
})
export class FinancialsInvoicingRoutingModule {}
