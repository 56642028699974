import { Component, EventEmitter, OnInit } from '@angular/core';
import { TaskService } from '../task.service';
import { Task } from '../task';
import { NotificationService } from '../../shared/notification.service';
import { AuthService } from '../../auth/auth.service';

@Component({
  selector   : 'app-task-list',
  templateUrl: 'list.component.html',
  styleUrls  : [],
})
export class TaskListComponent implements OnInit {
  public task: Task;

  /**
   * A list of all the tasks currently loaded.
   *
   * @type {Task[]}
   */
  public tasks: Task[] = [];

  /**
   * Are we currently performing a loading action?
   *
   * @type {boolean}
   */
  public isLoading: boolean = true;

  /**
   * The total number of pages in this list of tasks.
   *
   * @type {number}
   */
  public totalPages: number = 1;

  /**
   * The current page of tasks that we are viewing.
   *
   * @type {number}
   */
  public currentPage: number = 1;

  /**
   * The total number of tasks that can be viewed in the system.
   *
   * @type {number}
   */
  public totalResults: number = 0;

  /**
   * An Emitter to alert other components when a task has been deleted.
   *
   * @type {EventEmitter<boolean>}
   */
  public taskDeleted: EventEmitter<boolean> = new EventEmitter<boolean>();

  /**
   * Load up all our required services and subscribe to every observable we need.
   *
   * @param {TaskService} taskService
   * @param {NotificationService} notificationService
   */
  constructor(
    private taskService: TaskService,
    private notificationService: NotificationService,
    private authService: AuthService,
  )
  {
    this.taskService.loading.subscribe(isLoading => {
      this.isLoading = isLoading;
    });
    this.taskService.totalPages.subscribe(totalPages => {
      this.totalPages = totalPages;
    });
    this.taskService.currentPage.subscribe(currentPage => {
      this.currentPage = currentPage;
    });
    this.taskService.totalResults.subscribe(totalResults => {
      this.totalResults = totalResults;
    });
  }

  /**
   * When the component is initialised we set it to load
   * page one of the task list.
   */
  public ngOnInit(): void {
    this.loadTasks(1);
  }

  /**
   * When we receive a page change from the pagination
   * component we load the appropriate page for the
   * task list.
   *
   * @param {number} pageNumber
   */
  public onPageChanged(pageNumber: number): void {
    this.loadTasks(pageNumber);
  }

  /**
   * Loads a list of tasks using the given per page limit.
   *
   * @param {number} page The current page we require.
   * @param {number} limit The number of results per page.
   */
  public loadTasks(page?: number, limit?: number): void {
    const listTasks = this.taskService
      .listTasks(page, limit)
      .subscribe((tasks: Task[]) => {
        this.tasks = tasks;
        listTasks.unsubscribe();
      });
  }

  /**
   * When receiving confirmation of an item being deleted
   * we call the back end with the appropriate task ID
   * to delete the task.
   *
   * @param {string} id
   */
  public delete(id: string) {
    this.taskService.deleteTask(id).subscribe(
      deleted => {
        this.notificationService.success(
          `Task Deleted`,
          `Task with ID ${id} was deleted.`,
          5,
        );
        this.taskDeleted.next(true);
        this.onPageChanged(this.currentPage);
      },
      err => {
        this.notificationService.error(
          `Task Not Deleted`,
          err.error.message,
          10,
        );
        this.taskDeleted.next(false);
        this.onPageChanged(this.currentPage);
      },
    );
  }
}
