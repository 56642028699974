import { Injectable } from '@angular/core';
import { IncomeType } from './income-type';
import { Observable } from 'rxjs';

import { HttpRequestService } from '../../shared/http/http-request.service';

@Injectable()
export class IncomeTypeService extends HttpRequestService {
  /**
   * The endpoint that we call to get the incomeType information.
   *
   * @type {string}
   */
  private readonly endpoint: string = 'income-types';

  /**
   * Brings back a list of all accessible income-types.
   *
   * @param {number} page The page number we are currently on.
   * @param {number} limit The number of income-types to display per page.
   * @returns {Observable<IncomeType[]>}
   */
  public listIncomeTypes(
    page?: number,
    limit?: number,
  ): Observable<IncomeType[]> {
    return this.list(this.endpoint, page, undefined, limit);
  }

  /**
   * Finds details of the incomeType from the given ID.
   *
   * @param {string} incomeTypeId The ID of the incomeType we require details for.
   * @returns {Observable<IncomeType>}
   */
  public findIncomeType(incomeTypeId: string): Observable<IncomeType> {
    return this.findById(this.endpoint, incomeTypeId);
  }

  /**
   * Creates a new incomeType from the given IncomeType object.
   *
   * @param {IncomeType} newIncomeType The details of the incomeType to be created.
   * @returns {Observable<IncomeType | boolean>}
   */
  public createIncomeType(
    newIncomeType: IncomeType,
  ): Observable<IncomeType | boolean> {
    return this.create(this.endpoint, newIncomeType);
  }

  /**
   * Updates a incomeType with the given incomeType object for the given incomeType ID.
   *
   * @param {string} incomeTypeId The ID of the incomeType needing updating.
   * @param {IncomeType} newDetails The new incomeType object to update with.
   * @returns {Observable<IncomeType | boolean>}
   */
  public updateIncomeType(
    incomeTypeId: string,
    newDetails: IncomeType,
  ): Observable<IncomeType | boolean> {
    return this.update(this.endpoint, incomeTypeId, newDetails);
  }

  /**
   * Deletes a incomeType based on the given incomeType ID.
   *
   * @param {string} incomeTypeId The ID of the incomeType needing deleting.
   * @returns {Observable<boolean>}
   */
  public deleteIncomeType(incomeTypeId: string): Observable<boolean> {
    return this.delete(this.endpoint, incomeTypeId);
  }
}
