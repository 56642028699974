import { Component, OnInit } from '@angular/core';

import { NotificationService } from '../../shared/notification.service';
import { Router } from '@angular/router';
import { Template } from '../template';
import { TemplateService } from '../template.service';

@Component({
  selector: 'app-template-create',
  templateUrl: 'create.component.html',
})
export class TemplateCreateComponent implements OnInit {
  /**
   * Details of the template being created.
   */
  public template: Template;

  /**
   * Are we currently performing a loading action?
   *
   * @type {boolean}
   */
  public isLoading: boolean = false;

  /**
   * Is the create action performed?
   *
   * @type {boolean}
   */
  public disabled: boolean = false;

  /**
   * Load up all our required services and subscribe to every observable we need.
   *
   * @param {TemplateService} templateService
   * @param {NotificationService} notificationService
   * @param {Router} router
   */
  constructor(
    private templateService: TemplateService,
    private notificationService: NotificationService,
    private router: Router,
  ) {
    this.templateService.loading.subscribe(
      (result) => (this.isLoading = result),
    );
  }

  /**
   * Initialise the component, creating a new blank template if required.
   */
  public ngOnInit(): void {
    if (!this.template) {
      this.template = new Template();
      this.template.templateType = 'html';
    }
  }

  /**
   * Perform the create action which adds the new template into the backend.
   */
  public create(filename: string | undefined) {
    if (filename !== undefined) {
      this.template.templateUrl = filename;
    }
    this.disabled = true;
    const createTemplate = this.templateService
      .createTemplate(this.template)
      .subscribe(
        (result: Template) => {
          this.notificationService.success(
            `Template Created`,
            `New template ${result.name} has been created with ID of ${result.id}.`,
            5,
          );
          this.template = new Template();
          this.disabled = false;
          createTemplate.unsubscribe();
          this.router.navigate(['/templates']);
        },
        (err) => {
          this.notificationService.error(
            `Could not create Template`,
            err.error.message,
            10,
          );
          this.disabled = false;
        },
      );
  }
}
