<div class="row">
  <div class="col-sm-12">
    <section class="panel">
      <app-page-title title="Support" [loading]="false"></app-page-title>

      <div class="panel-body">

        <p>
          Our support system to report bugs and request features is housed by a company called <a
          href="https://www.github.com" target="_blank">Github</a>. This service
          allows us to very easily track all bugs and requests, plot them on the <a [routerLink]="['/chief-timeline']">timeline</a>
          and keep you regularly updated with progress. It is also social so allows us to see how many people are having
          the same issue or have the same feature requests meaning we can prioritise effectively.
        </p>

        <p>
          To submit a request you can simply click on the button below to be taken over to github. You will need to
          register a username but from there you will always be informed. We hope in the future to integrate this system
          tightly into Chief to streamline the process for you. Once at the link and logged in simply create a new issue
          with as much information in as possible.
        </p>

        <p>
          <a href="https://github.com/ChiefSupportSystems/ChiefSupportSystems/issues"
             class="btn btn-xl btn-primary" target="_blank">
            Click here to view the Support System
          </a>
        </p>

      </div>
    </section>
  </div>
</div>
