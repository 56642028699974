import { Component, EventEmitter, Input, OnInit } from '@angular/core';

import { HttpClient } from '@angular/common/http';
import { environment } from '../../../../environments/environment';

@Component({
  selector: 'app-case-invoice-list',
  templateUrl: 'list.component.html',
})
export class CaseInvoiceListComponent implements OnInit {
  @Input() caseRefreshed: EventEmitter<string>;
  @Input() caseId: string;
  public invoices: any[] = [];
  @Input() public filter: any;

  constructor(protected http: HttpClient) {}

  ngOnInit(): void {
    this.caseRefreshed.subscribe(this.loadInvoices());
  }

  public generateInvoiceDownloadUrl(key: string): string {
    return `${environment.apiUrl}files/download?key=${key}`;
  }

  public runFilters() {
    this.loadInvoices();
  }

  public clearFilters() {
    this.filter = {
      chartOfAccounts: {},
    };
    this.runFilters();
  }

  protected loadInvoices() {
    if (this.caseId) {
      this.http
        .get(
          `${environment.apiUrl}cases/${this.caseId}/invoices${
            this.filter.chartOfAccounts.id
              ? '?chartOfAccountsId=' + this.filter.chartOfAccounts.id
              : ''
          }`,
        )
        .subscribe((data: any[]) => {
          this.invoices = data;
        });
    }
  }
}
