import { HttpOptions } from '../../shared/http/http-options';
import { HttpRequestService } from '../../shared/http/http-request.service';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';

@Injectable()
export class CaseFinancialSummaryService extends HttpRequestService {
  private readonly endpoint: string = 'cases/:id';

  public showClientDistributions(id, options?: any, dates?: Date[]) {
    return this.findById('financials/distributions', id, options, {
      showPaid: 'true',
      limit: 10000,
      startDate: dates ? dates[0].toISOString().slice(0, 10) : undefined,
      endDate: dates ? dates[1].toISOString().slice(0, 10) : undefined,
    });
  }

  public showClientCreditorDistributions(id, options?: any, dates?: Date[]) {
    return this.http.get(
      `${
        environment.apiUrl
      }reports/distributions-for-client?limit=10000&caseId=${id}&auth=${this.authService.getToken()}`,
    );
  }

  public addFee(
    chartOfAccountId: string,
    value: number,
    options?: HttpOptions,
  ) {
    return this.create(
      this.endpoint + '/add-fee',
      {
        chartOfAccountId,
        value,
      },
      options,
    );
  }

  public getTotalPayments(
    id: string,
    options?: HttpOptions,
  ): Observable<number> {
    return this.findById(this.endpoint + '/total-payments', '', options);
  }
}
