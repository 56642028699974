<div class="row">
  <div class="col-sm-12">
    <section class="panel">
      <app-page-title [loading]="isLoading" title="Payments Waiting to be Approved">
        {{ totalResults }} Results Found
      </app-page-title>

      <div class="panel-body">
        <table class="table table-striped">
          <thead>
          <tr>
            <th
              *ngIf="authService.hasPermission('config.multiple-domains') || authService.hasPermission('config.all-domains')">
              Domain
            </th>
            <th>Client</th>
            <th>Introducer Name</th>
            <th>Introducer Reference</th>
            <th>Introducer Payment</th>
            <th class="text-right">&nbsp;</th>
          </tr>
          </thead>

          <tbody>
          <tr *ngFor="let approval of approvals">
            <td
              *ngIf="authService.hasPermission('config.multiple-domains') || authService.hasPermission('config.all-domains')">
              <app-domain-icon *ngIf="approval?.domain?.id" [domainId]="approval?.domain?.id" [passedDomain]="approval?.domain"
                               [size]="24"></app-domain-icon>
            </td>
            <td>
              <div *ngFor="let singlePerson of approval.people">
                {{ singlePerson.firstName }} {{ singlePerson.lastName }}
              </div>
            </td>
            <td>{{ approval?.introducer?.name }}</td>
            <td>{{ approval?.introducerReference }}</td>
            <td>{{ approval?.introducerValue / 100 | toCurrency }}</td>
            <td class="text-right">
              <div class="btn-group">
                <a (click)="approvePayment(approval.id)" class="btn btn-sm btn-warning" href="javascript:;"
                   type="button">Approve</a>
                <button aria-expanded="false" aria-haspopup="true" class="btn btn-sm btn-warning dropdown-toggle"
                        data-toggle="dropdown" type="button">
                  <span class="caret"></span>
                  <span class="sr-only">Toggle Dropdown</span>
                </button>
                <ul class="dropdown-menu">
                  <li>
                    <a [routerLink]="['/cases', approval?.id]"
                       target="_blank">View</a>
                  </li>
                </ul>
              </div>
            </td>
          </tr>
          </tbody>

          <tfoot>
          <tr>
            <td class="text-center" colspan="99">
              <app-pagination (pageChanged)="onPageChanged($event)" [currentPage]="currentPage"
                              [totalPages]="totalPages"></app-pagination>
            </td>
          </tr>
          </tfoot>
        </table>
      </div>
    </section>
  </div>
</div>
