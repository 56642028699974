import {Component, Input} from '@angular/core';
import {CreditorStatus} from '../creditor-status';

@Component({
  selector: 'app-creditor-status-form',
  templateUrl: 'form.component.html',
})
export class CreditorStatusFormComponent {
  @Input() status: CreditorStatus;
}
