import { Component, Input, OnInit } from '@angular/core';

import { Address } from '../../shared/address/address';
import { AuthService } from '../../auth/auth.service';
import { Person } from '../people/person';

@Component({
  selector: 'app-case-form',
  templateUrl: 'form.component.html',
  styleUrls: ['form.component.scss'],
})
export class CaseFormComponent implements OnInit {
  @Input() case: any;
  @Input() editing: boolean = false;

  public editorOptions = {
    placeholder: 'Enter your note...',
    modules: {
      toolbar: ['bold', 'link', { list: 'ordered' }, { list: 'bullet' }],
    },
  };

  constructor(public readonly authService: AuthService) {}

  public ngOnInit(): void {
    this.case.people.forEach((item, key) => {
      if (!item.addresses) {
        item.addresses = [new Address()];
      }
    });
  }

  public resetAllocationCheckbox() {
    this.case.allocationTypeConfirmed = false;
  }

  public addApplicant(): void {
    console.log(this.case.people[0].addresses);
    const newPerson = new Person();

    // newPerson.addresses = this.case.people[0].addresses;
    newPerson.addresses = [];
    newPerson.addresses.push({
      address1: this.case.people[0].addresses[0].address1,
      address2: this.case.people[0].addresses[0].address2,
      address3: this.case.people[0].addresses[0].address3,
      town: this.case.people[0].addresses[0].town,
      county: this.case.people[0].addresses[0].county,
      postalCode: this.case.people[0].addresses[0].postalCode,
      country: this.case.people[0].addresses[0].country,
    });

    newPerson.maritalStatus = this.case.people[0].maritalStatus;
    newPerson.residentialStatus = this.case.people[0].residentialStatus;
    newPerson.addresses[0].id = undefined;

    this.case.people.push(newPerson);
  }

  public deleteApplicant(index): void {
    this.case.people.splice(index, 1);
  }
}
