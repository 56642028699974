import { Component, OnInit } from '@angular/core';
import { CreditorService } from '../creditor.service';
import { Creditor } from '../creditor';
import { Router } from '@angular/router';
import { NotificationService } from '../../shared/notification.service';

@Component({
  selector: 'app-creditor-create',
  templateUrl: 'create.component.html',
})
export class CreditorCreateComponent implements OnInit {
  /**
   * Details of the creditor being created.
   */
  public creditor: Creditor;

  /**
   * Are we currently performing a loading action?
   *
   * @type {boolean}
   */
  public isLoading: boolean = false;

  /**
   * Load up all our required services and subscribe to every observable we need.
   *
   * @param {CreditorService} creditorService
   * @param {NotificationService} notificationService
   * @param {Router} router
   */
  constructor(
    private creditorService: CreditorService,
    private notificationService: NotificationService,
    private router: Router,
  ) {
    this.creditorService.loading.subscribe(result => (this.isLoading = result));
  }

  /**
   * Initialise the component, creating a new blank creditor if required.
   */
  public ngOnInit(): void {
    if (!this.creditor) {
      this.creditor = new Creditor();
    }
  }

  /**
   * Perform the create action which adds the new creditor into the backend.
   */
  public create() {
    const createCreditor = this.creditorService
      .createCreditor(this.creditor)
      .subscribe(
        (result: Creditor) => {
          this.notificationService.success(
            `Creditor Created`,
            `New creditor ${result.name} has been created with ID of ${
              result.id
            }.`,
            5,
          );
          this.creditor = new Creditor();
          createCreditor.unsubscribe();
          this.router.navigate(['/creditors']);
        },
        err => {
          this.notificationService.error(
            `Could not create Creditor`,
            err.error.message,
            10,
          );
        },
      );
  }
}
