<div class="form-group">
    <label class="col-sm-2 control-label" for="name">Name</label>
  <div class="col-sm-10">
      <input [(ngModel)]="employmentStatus.name" class="form-control" id="name" name="name"
             placeholder="Employment Status Name"
             required type="text">
  </div>
</div>

<div class="form-group">
  <div class="col-sm-offset-2 col-sm-10 text-right">
      <button class="btn btn-success" type="submit">Save</button>
  </div>
</div>
