<div class="row">
  <div class="col-sm-12">
    <section class="panel">
      <app-page-title title="Create Account Group" [loading]="isLoading"></app-page-title>

      <div class="panel-body">
        <form class="form-horizontal" (ngSubmit)="create()" ngNativeValidate>
          <app-account-group-form [accountGroup]="accountGroup" *ngIf="accountGroup"></app-account-group-form>
        </form>
      </div>
    </section>
  </div>
</div>


