import {Component, Input, OnInit} from '@angular/core';
import {CreditorStatusService} from '../creditor-status.service';
import {CreditorStatus} from '../creditor-status';
import {NotificationService} from '../../shared/notification.service';
import {SelectComparisons} from '../../shared/pipes/select.comparisons';

@Component({
  selector: 'app-creditor-status-select',
  templateUrl: 'select.component.html',
})
export class CreditorStatusSelectComponent extends SelectComparisons implements OnInit {
  /**
   * The model that we will be adding the status to.
   */
  @Input() model: any;

  /**
   * Do we require this field in the main form it is
   * included in?
   */
  @Input() required: boolean;

  /**
   * A list of all the statuses currently loaded.
   *
   * @type {CreditorStatus[]}
   */
  public statuses: CreditorStatus[] = [];

  /**
   * Are we currently performing a loading action?
   *
   * @type {boolean}
   */
  public isLoading: boolean = true;

  /**
   * Load up all our required services and subscribe to every observable we need.
   *
   * @param {CreditorStatusService} statusService
   * @param {NotificationService} notificationService
   */
  constructor(
      private statusService: CreditorStatusService,
      private notificationService: NotificationService,
  ) {
    super();
    this.statusService.loading.subscribe(isLoading => {
      this.isLoading = isLoading;
    });
  }

  /**
   * When the component is initialised we set it to load
   * page one of the status list.
   */
  public ngOnInit(): void {
    this.loadStatuses(1, 10000);
  }

  /**
   * Loads a list of statuses using the given per page limit.
   *
   * @param {number} page The current page we require.
   * @param {number} limit The number of results per page.
   */
  public loadStatuses(page?: number, limit?: number): void {
    const listStatuses = this.statusService
        .listStatuses(page, limit)
        .subscribe((statuses: CreditorStatus[]) => {
          this.statuses = statuses;
          listStatuses.unsubscribe();
        });
  }
}
