import { Component, Input, OnInit } from '@angular/core';
import { PackService } from '../pack.service';
import { Pack } from '../pack';
import { NotificationService } from '../../shared/notification.service';
import { SelectComparisons } from '../../shared/pipes/select.comparisons';
import { Template } from '../../templates/template';

@Component({
  selector: 'app-pack-select',
  templateUrl: 'select.component.html',
})
export class PackSelectComponent extends SelectComparisons implements OnInit {
  /**
   * The model that we will be adding the pack to.
   */
  @Input() model: any;

  /**
   * Do we require this field in the main form it is
   * included in?
   */
  @Input() required: boolean;

  /**
   * A list of all the packs currently loaded.
   *
   * @type {Pack[]}
   */
  public packs: Pack[] = [];

  /**
   * Are we currently performing a loading action?
   *
   * @type {boolean}
   */
  public isLoading: boolean = true;

  /**
   * Load up all our required services and subscribe to every observable we need.
   *
   * @param {PackService} packService
   * @param {NotificationService} notificationService
   */
  constructor(
    private packService: PackService,
    private notificationService: NotificationService,
  ) {
    super();
    this.packService.loading.subscribe(isLoading => {
      this.isLoading = isLoading;
    });
  }

  /**
   * When the component is initialised we set it to load
   * page one of the pack list.
   */
  public ngOnInit(): void {
    this.loadPacks(1, 10000);
  }

  /**
   * Loads a list of packs using the given per page limit.
   *
   * @param {number} page The current page we require.
   * @param {number} limit The number of results per page.
   */
  public loadPacks(page?: number, limit?: number): void {
    const listPacks = this.packService
      .listPacks(page, limit)
      .subscribe((packs: Pack[]) => {
        this.packs = packs.map((pack: Pack) => {
          pack.templates = pack.templates
            .map((template: Template) => {
              template.content = undefined;

              return template;
            })
            .filter(() => true);

          return pack;
        });
        listPacks.unsubscribe();
      });
  }
}
