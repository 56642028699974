import { Case } from '../case';
import { Expenditure } from '../../expenditures/expenditure';
import { Person } from '../people/person';
import { User } from '../../users/user';

export class CaseExpenditure {
  public id?: string;
  public case?: Case;
  public user?: User;
  public expenditure?: Expenditure;
  public value: number = 0;
  public note?: string;
  public footnote?: string;
  public createdAt?: string;
}
