import {HttpClientModule} from '@angular/common/http';
import {NgModule} from '@angular/core';
import {FormsModule} from '@angular/forms';
import {BrowserModule} from '@angular/platform-browser';
import {ModalModule} from 'ngx-bootstrap/modal';
import {TooltipModule} from 'ngx-bootstrap/tooltip';
import {QuillModule} from 'ngx-quill';

import {SharedModule} from '../../shared/shared.module';
import {CasePersonService} from '../people/case-person.service';
import {CasePropertyService} from './case-property.service';
import {CasePropertyCreateComponent} from './create/create.component';
import {CasePropertyEditComponent} from './edit/edit.component';
import {CasePropertyFormComponent} from './form/form.component';
import {CasePropertyListComponent} from './list/list.component';

@NgModule({
  declarations: [
    CasePropertyFormComponent,
    CasePropertyCreateComponent,
    CasePropertyEditComponent,
    CasePropertyListComponent,
  ],
  imports: [
    ModalModule.forRoot(),
    TooltipModule.forRoot(),
    QuillModule,
    BrowserModule,
    FormsModule,
    HttpClientModule,
    SharedModule,
  ],
  exports: [CasePropertyListComponent],
  providers: [CasePropertyService, CasePersonService],
})
export class CasePropertyRoutingModule {}
