import { Component, EventEmitter, OnInit } from '@angular/core';
import { OccupationService } from '../occupation.service';
import { Occupation } from '../occupation';
import { NotificationService } from '../../shared/notification.service';

@Component({
  selector: 'app-occupation-list',
  templateUrl: 'list.component.html',
  styleUrls: [],
})
export class OccupationListComponent implements OnInit {
  public occupation: Occupation;

  /**
   * A list of all the occupations currently loaded.
   *
   * @type {Occupation[]}
   */
  public occupations: Occupation[] = [];

  /**
   * Are we currently performing a loading action?
   *
   * @type {boolean}
   */
  public isLoading: boolean = true;

  /**
   * The total number of pages in this list of occupations.
   *
   * @type {number}
   */
  public totalPages: number = 1;

  /**
   * The current page of occupations that we are viewing.
   *
   * @type {number}
   */
  public currentPage: number = 1;

  /**
   * The total number of occupations that can be viewed in the system.
   *
   * @type {number}
   */
  public totalResults: number = 0;

  /**
   * An Emitter to alert other components when a occupation has been deleted.
   *
   * @type {EventEmitter<boolean>}
   */
  public occupationDeleted: EventEmitter<boolean> = new EventEmitter<boolean>();

  /**
   * Load up all our required services and subscribe to every observable we need.
   *
   * @param {OccupationService} occupationService
   * @param {NotificationService} notificationService
   */
  constructor(
    private occupationService: OccupationService,
    private notificationService: NotificationService,
  ) {
    this.occupationService.loading.subscribe(isLoading => {
      this.isLoading = isLoading;
    });
    this.occupationService.totalPages.subscribe(totalPages => {
      this.totalPages = totalPages;
    });
    this.occupationService.currentPage.subscribe(currentPage => {
      this.currentPage = currentPage;
    });
    this.occupationService.totalResults.subscribe(totalResults => {
      this.totalResults = totalResults;
    });
  }

  /**
   * When the component is initialised we set it to load
   * page one of the occupation list.
   */
  public ngOnInit(): void {
    this.loadOccupations(1);
  }

  /**
   * When we receive a page change from the pagination
   * component we load the appropriate page for the
   * occupation list.
   *
   * @param {number} pageNumber
   */
  public onPageChanged(pageNumber: number): void {
    this.loadOccupations(pageNumber);
  }

  /**
   * Loads a list of occupations using the given per page limit.
   *
   * @param {number} page The current page we require.
   * @param {number} limit The number of results per page.
   */
  public loadOccupations(page?: number, limit?: number): void {
    const listOccupations = this.occupationService
      .listOccupations(page, limit)
      .subscribe((occupations: Occupation[]) => {
        this.occupations = occupations;
        listOccupations.unsubscribe();
      });
  }

  /**
   * When receiving confirmation of an item being deleted
   * we call the back end with the appropriate occupation ID
   * to delete the occupation.
   *
   * @param {string} id
   */
  public delete(id: string) {
    this.occupationService.deleteOccupation(id).subscribe(
      deleted => {
        this.notificationService.success(
          `Occupation Deleted`,
          `Occupation with ID ${id} was deleted.`,
          5,
        );
        this.occupationDeleted.next(true);
        this.onPageChanged(this.currentPage);
      },
      err => {
        this.notificationService.error(
          `Occupation Not Deleted`,
          err.error.message,
          10,
        );
        this.occupationDeleted.next(false);
        this.onPageChanged(this.currentPage);
      },
    );
  }
}
