import { Component, OnInit } from '@angular/core';
import { PermissionService } from '../permission.service';
import { Permission } from '../permission';
import { Router } from '@angular/router';
import { NotificationService } from '../../shared/notification.service';

@Component({
  selector: 'app-permission-create',
  templateUrl: 'create.component.html',
})
export class PermissionCreateComponent implements OnInit {
  /**
   * Details of the permission being created.
   */
  public permission: Permission;

  /**
   * Are we currently performing a loading action?
   *
   * @type {boolean}
   */
  public isLoading: boolean = false;

  /**
   * Load up all our required services and subscribe to every observable we need.
   *
   * @param {PermissionService} permissionService
   * @param {NotificationService} notificationService
   * @param {Router} router
   */
  constructor(
    private permissionService: PermissionService,
    private notificationService: NotificationService,
    private router: Router,
  ) {
    this.permissionService.loading.subscribe(
      result => (this.isLoading = result),
    );
  }

  /**
   * Initialise the component, creating a new blank permission if required.
   */
  public ngOnInit(): void {
    if (!this.permission) {
      this.permission = new Permission();
    }
  }

  /**
   * Perform the create action which adds the new permission into the backend.
   */
  public create() {
    const createPermission = this.permissionService
      .createPermission(this.permission)
      .subscribe(
        (result: Permission) => {
          this.notificationService.success(
            `Permission Created`,
            `New permission ${result.name} has been created with ID of ${
              result.id
            }.`,
            5,
          );
          this.permission = new Permission();
          createPermission.unsubscribe();
          this.router.navigate(['/permissions']);
        },
        err => {
          this.notificationService.error(
            `Could not create Permission`,
            err.error.message,
            10,
          );
        },
      );
  }
}
