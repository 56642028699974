import { Component, OnInit } from '@angular/core';
import { PortfolioService } from '../portfolio.service';
import { Portfolio } from '../portfolio';
import { Router } from '@angular/router';
import { NotificationService } from '../../shared/notification.service';

@Component({
  selector: 'app-portfolio-create',
  templateUrl: 'create.component.html',
})
export class PortfolioCreateComponent implements OnInit {
  /**
   * Details of the portfolio being created.
   */
  public portfolio: Portfolio;

  /**
   * Are we currently performing a loading action?
   *
   * @type {boolean}
   */
  public isLoading: boolean = false;

  /**
   * Load up all our required services and subscribe to every observable we need.
   *
   * @param {PortfolioService} portfolioService
   * @param {NotificationService} notificationService
   * @param {Router} router
   */
  constructor(
    private portfolioService: PortfolioService,
    private notificationService: NotificationService,
    private router: Router,
  ) {
    this.portfolioService.loading.subscribe(
      result => (this.isLoading = result),
    );
  }

  /**
   * Initialise the component, creating a new blank portfolio if required.
   */
  public ngOnInit(): void {
    if (!this.portfolio) {
      this.portfolio = new Portfolio();
    }
  }

  /**
   * Perform the create action which adds the new portfolio into the backend.
   */
  public create() {
    const createPortfolio = this.portfolioService
      .createPortfolio(this.portfolio)
      .subscribe(
        (result: Portfolio) => {
          this.notificationService.success(
            `Portfolio Created`,
            `New portfolio ${result.name} has been created with ID of ${
              result.id
            }.`,
            5,
          );
          this.portfolio = new Portfolio();
          createPortfolio.unsubscribe();
          this.router.navigate(['/portfolios']);
        },
        err => {
          this.notificationService.error(
            `Could not create Portfolio`,
            err.error.message,
            10,
          );
        },
      );
  }
}
