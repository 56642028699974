<!-- Delete Modal -->
<div class="modal fade" #deleteModalComponent>
  <div class="modal-dialog" role="document">
    <div class="modal-content panel-danger">
      <div class="modal-header panel-heading">
        <button type="button" class="close" (click)="cancelDeletion()" aria-label="Close"><span aria-hidden="true">&times;</span>
        </button>
        <h4 class="modal-title" id="myModalLabel">Are You Sure?</h4>
      </div>
      <div class="modal-body">
        You are about to delete this {{ type || 'item' }}. Are you sure you wish to do this?
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-default" (click)="cancelDeletion()">Cancel</button>
        <button type="button" class="btn btn-danger" (click)="confirmDeletion()">Confirm</button>
      </div>
    </div>
  </div>
</div>
