import { Component, OnInit } from '@angular/core';
import { GenderService } from '../gender.service';
import { Gender } from '../gender';
import { Router } from '@angular/router';
import { NotificationService } from '../../shared/notification.service';

@Component({
  selector: 'app-gender-create',
  templateUrl: 'create.component.html',
})
export class GenderCreateComponent implements OnInit {
  /**
   * Details of the gender being created.
   */
  public gender: Gender;

  /**
   * Are we currently performing a loading action?
   *
   * @type {boolean}
   */
  public isLoading: boolean = false;

  /**
   * Load up all our required services and subscribe to every observable we need.
   *
   * @param {GenderService} genderService
   * @param {NotificationService} notificationService
   * @param {Router} router
   */
  constructor(
    private genderService: GenderService,
    private notificationService: NotificationService,
    private router: Router,
  ) {
    this.genderService.loading.subscribe(result => (this.isLoading = result));
  }

  /**
   * Initialise the component, creating a new blank gender if required.
   */
  public ngOnInit(): void {
    if (!this.gender) {
      this.gender = new Gender();
    }
  }

  /**
   * Perform the create action which adds the new gender into the backend.
   */
  public create() {
    const createGender = this.genderService.createGender(this.gender).subscribe(
      (result: Gender) => {
        this.notificationService.success(
          `Gender Created`,
          `New gender ${result.name} has been created with ID of ${result.id}.`,
          5,
        );
        this.gender = new Gender();
        createGender.unsubscribe();
        this.router.navigate(['/genders']);
      },
      err => {
        this.notificationService.error(
          `Could not create Gender`,
          err.error.message,
          10,
        );
      },
    );
  }
}
