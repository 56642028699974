import { Injectable } from '@angular/core';
import { StatusCategory } from './status-category';
import { Observable } from 'rxjs';

import { HttpRequestService } from '../shared/http/http-request.service';

@Injectable()
export class StatusCategoryService extends HttpRequestService {
  /**
   * The endpoint that we call to get the statusCategory information.
   *
   * @type {string}
   */
  private readonly endpoint: string = 'status-categories';

  /**
   * Brings back a list of all accessible statusCategories.
   *
   * @param {number} page The page number we are currently on.
   * @param {number} limit The number of statusCategories to display per page.
   * @returns {Observable<StatusCategory[]>}
   */
  public listStatusCategories(
    page?: number,
    limit?: number,
  ): Observable<StatusCategory[]> {
    return this.list(this.endpoint, page, undefined, limit);
  }

  /**
   * Finds details of the statusCategory from the given ID.
   *
   * @param {string} statusCategoryId The ID of the statusCategory we require details for.
   * @returns {Observable<StatusCategory>}
   */
  public findStatusCategory(
    statusCategoryId: string,
  ): Observable<StatusCategory> {
    return this.findById(this.endpoint, statusCategoryId);
  }

  /**
   * Creates a new statusCategory from the given StatusCategory object.
   *
   * @param {StatusCategory} newStatusCategory The details of the statusCategory to be created.
   * @returns {Observable<StatusCategory | boolean>}
   */
  public createStatusCategory(
    newStatusCategory: StatusCategory,
  ): Observable<StatusCategory | boolean> {
    return this.create(this.endpoint, newStatusCategory);
  }

  /**
   * Updates a statusCategory with the given statusCategory object for the given statusCategory ID.
   *
   * @param {string} statusCategoryId The ID of the statusCategory needing updating.
   * @param {StatusCategory} newDetails The new statusCategory object to update with.
   * @returns {Observable<StatusCategory | boolean>}
   */
  public updateStatusCategory(
    statusCategoryId: string,
    newDetails: StatusCategory,
  ): Observable<StatusCategory | boolean> {
    return this.update(this.endpoint, statusCategoryId, newDetails);
  }

  /**
   * Deletes a statusCategory based on the given statusCategory ID.
   *
   * @param {string} statusCategoryId The ID of the statusCategory needing deleting.
   * @returns {Observable<boolean>}
   */
  public deleteStatusCategory(statusCategoryId: string): Observable<boolean> {
    return this.delete(this.endpoint, statusCategoryId);
  }
}
