<div class="row" *ngIf="allocationsInProgress">
  <div class="col-md-12">
    <div class="alert alert-danger text-bold text-center">
      Allocations are currently Read Only whilst automatic allocations are
      performed.
    </div>
  </div>
</div>

<section class="panel">
  <header class="panel-heading tab-bg-light">WTH / DEP Statement Report</header>
  <div class="panel-body">
    <table class="table table-striped">
      <thead>
        <tr>
          <th>Statement ID</th>
          <th>Date</th>
          <th>Total Lines</th>
          <th>Deposit Value</th>
          <th>Withdrawal Value</th>
          <th>Status</th>
          <th class="text-right">
            <button
              class="btn btn-xs btn-warning"
              (click)="generateDep()"
              *ngIf="!allocationsInProgress"
            >
              Generate Revenue Statement
            </button>
          </th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let item of statements">
          <td>{{ item.id }}</td>
          <td>{{ item.created | date: 'dd/MM/yyyy HH:mm' }}</td>
          <td>{{ item.itemCount }}</td>
          <td>{{ item.depositValue / 100 | toAccountancy }}</td>
          <td>{{ item.withdrawalValue / 100 | toAccountancy }}</td>
          <td>{{ item.status }}</td>
          <td class="text-right">
            <div class="dropdown">
              <button
                class="btn btn-xs btn-primary dropdown-toggle"
                type="button"
                id="dropdownMenu1"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="true"
              >
                <i class="fa fa-question-circle"></i>
              </button>
              <ul
                class="dropdown-menu dropdown-menu-right"
                aria-labelledby="dropdownMenu1"
              >
                <li>
                  <a [href]="generateDownloadStatementUrl(item)">Download</a>
                </li>
                <li>
                  <a [href]="generateDownloadSummaryStatementUrl(item)"
                    >Download Statement Summary</a
                  >
                </li>
                <li>
                  <a [href]="generateDownloadBacsStatementUrl(item)"
                    >Download BACS Statement</a
                  >
                </li>
                <li
                  role="separator"
                  class="divider"
                  *ngIf="item.status === 'Downloaded'"
                ></li>
                <li *ngIf="item.status === 'Downloaded'">
                  <a
                    href="javascript:;"
                    (click)="setStatus(item.id, 'Imported')"
                    >Set Status: Imported</a
                  >
                </li>
                <li role="separator" class="divider"></li>
                <li>
                  <a
                    [href]="
                      generateDownloadRemittanceStatementUrl(item, 'creditor')
                    "
                    >Creditor Remittance Statement</a
                  >
                </li>
                <li>
                  <a
                    [href]="
                      generateDownloadRemittanceStatementUrl(item, 'eversheds')
                    "
                    >Eversheds Remittance Statement</a
                  >
                </li>
                <li>
                  <a
                    [href]="
                      generateDownloadRemittanceStatementUrl(
                        item,
                        'maxRecovery'
                      )
                    "
                    >Max Recovery Remittance Statement</a
                  >
                </li>
                <li>
                  <a
                    [href]="generateDownloadRemittanceStatementUrl(item, 'tdx')"
                    >TDX Remittance Statement</a
                  >
                </li>
                <li>
                  <a
                    [href]="generateDownloadRemittanceStatementUrl(item, 'wpm')"
                    >WPM Remittance Statement</a
                  >
                </li>
                <li *ngFor="let portfolio of portfolios">
                  <a
                    [href]="
                      generateDownloadRemittanceStatementUrl(
                        item,
                        'global',
                        portfolio.id
                      )
                    "
                    >{{ portfolio.name }} Remittance Statement</a
                  >
                </li>
              </ul>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</section>
