import { Component } from '@angular/core';
import { BankingAccountService } from '../banking-account.service';
import { BankingAccount } from '../banking-account';
import { ActivatedRoute, Router } from '@angular/router';
import { NotificationService } from '../../../../shared/notification.service';

@Component({
  selector: 'app-banking-account-edit',
  templateUrl: 'edit.component.html',
})
export class BankingAccountEditComponent {
  /**
   * Details of the account being edited.
   */
  public account: BankingAccount;

  /**
   * Are we currently performing a loading action?
   *
   * @type {boolean}
   */
  public isLoading: boolean = false;

  /**
   * Load up all our required services and subscribe to every observable we need.
   *
   * @param {BankingAccountService} accountService
   * @param {NotificationService} notificationService
   * @param {Router} router
   * @param {ActivatedRoute} route
   */
  constructor(
    private accountService: BankingAccountService,
    private notificationService: NotificationService,
    private router: Router,
    private route: ActivatedRoute,
  ) {
    this.accountService.loading.subscribe(
      result => (this.isLoading = result),
    );
    this.route.params.subscribe(params => this.loadBankingAccount(params.id));
  }

  /**
   * Perform the edit action which send the edited account to the backend.
   */
  public edit() {
    const editBankingAccount = this.accountService
      .updateBankingAccount(this.account.id, this.account)
      .subscribe(
        (result: BankingAccount) => {
          this.notificationService.success(
            `Account Type Updated`,
            `The account Type ${
              result.name
            } been updated with the specified changes.`,
            5,
          );
          this.account = new BankingAccount();
          editBankingAccount.unsubscribe();
          this.router.navigate(['/banking/accounts']);
        },
        err => {
          this.notificationService.error(
            `Account Type Not Found`,
            err.error.message,
            10,
          );
          this.router.navigate(['/banking/accounts']);
        },
      );
  }

  /**
   * Loads the requested account so we can display it and let the user edit the fields.
   *
   * @param {string} id
   */
  private loadBankingAccount(id: string) {
    this.accountService.findBankingAccount(id).subscribe(
      result => {
        this.account = result;
      },
      err => {
        this.notificationService.error(
          `Account Type Not Found`,
          err.error.message,
          10,
        );
        this.router.navigate(['/banking/accounts']);
      },
    );
  }
}
