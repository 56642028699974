import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { Address } from './address';
import { HttpRequestService } from '../http/http-request.service';
import { HttpOptions } from '../http/http-options';

@Injectable()
export class PostcodeService extends HttpRequestService {
  private readonly endpoint: string = 'postcodes';

  public listAddresses(postcode: string): Observable<Address[]> {
    return this.list(`${this.endpoint}/${postcode}`);
  }
}
