<div *ngIf="isLoading">Loading Account Type List...</div>

<select
  *ngIf="!isLoading"
  [compareWith]="byId"
  class="form-control"
  name="accountType"
  [(ngModel)]="model.type"
  [required]="required"
>
  <option
    *ngFor="let singleAccountType of accountTypes"
    [ngValue]="singleAccountType"
  >
    {{ singleAccountType.name }}
  </option>
</select>
