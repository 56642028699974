<button type="button" class="close" (click)="openModal(caseModificationModal)">
  <i class="fa fa-plus-circle"></i>
</button>

<ng-template #caseModificationModal>
  <form class="form-horizontal" (submit)="updateModifications()">
    <div class="modal-header">
      <div class="col-md-5">
        <h4 class="modal-title pull-left">Edit Case Modifications</h4>
      </div>
      <div class="col-md-6">
        <select
          class="form-control"
          (change)="setVisibleModifications($event.target.value)"
        >
          <option value="all" [selected]="visibleModifications === 'all'"
            >Show all modifications</option
          >
          <option
            value="selected"
            [selected]="visibleModifications === 'selected'"
            >Show selected modifications</option
          >
          <option
            value="override"
            [selected]="visibleModifications === 'override'"
            >Show override modifications</option
          >
        </select>
      </div>
      <div class="col-md-1">
        <button
          type="button"
          class="close pull-right"
          aria-label="Close"
          (click)="modalRef.hide()"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
    </div>
    <div class="modal-body">
      <div
        class="row"
        style="border-bottom: 1px solid #efefef; padding-bottom: 5px; margin-bottom: 5px;"
      >
        <div class="col-sm-2 text-center text-bold">
          Selected
        </div>
        <div class="col-sm-2 text-center text-bold">
          Override
        </div>
        <div class="col-sm-8 text-bold">
          Description
        </div>
      </div>

      <div class="form-group">
        <ng-container *ngFor="let modification of modifications">
          <div
            class="row"
            style="margin-bottom: 15px;"
            *ngIf="
              visibleModifications === 'all' ||
              (visibleModifications === 'selected' && modification.active) ||
              (visibleModifications === 'override' && modification.override)
            "
          >
            <div class="col-sm-2">
              <input
                type="checkbox"
                [(ngModel)]="modification.active"
                class="form-control"
                [ngModelOptions]="{ standalone: true }"
                [disabled]="modification.override"
              />
            </div>
            <div class="col-sm-2">
              <input
                type="checkbox"
                [(ngModel)]="modification.override"
                class="form-control"
                [ngModelOptions]="{ standalone: true }"
                (change)="updateActiveIfRequired(modification)"
              />
            </div>
            <div class="col-sm-8">
              {{ modification.name }}
              {{
                modification.portfolio
                  ? '(' + modification.portfolio.name + ')'
                  : ''
              }}<br />
              <i>{{ modification.description }}</i>
            </div>
          </div>
        </ng-container>
      </div>

      <div class="row" style="margin-bottom: 10px;">
        <div class="col-md-12 text-right">
          <button
            type="button"
            class="btn btn-xs btn-success"
            (click)="addCustomModification()"
          >
            Add Custom Modification
          </button>
        </div>
      </div>

      <div
        class="form-group"
        *ngFor="let custom of customModifications; let i = index"
      >
        <div class="col-sm-2">
          <input
            type="checkbox"
            [(ngModel)]="custom.obligation"
            class="form-control"
            [ngModelOptions]="{ standalone: true }"
          />
        </div>
        <div class="col-sm-9">
          <textarea
            class="form-control"
            [(ngModel)]="custom.text"
            [ngModelOptions]="{ standalone: true }"
          ></textarea>
        </div>
        <div class="col-sm-1">
          <button
            type="button"
            class="btn btn-xs btn-danger"
            (click)="deleteCustomModification(i)"
          >
            <i class="fa fa-trash"></i>
          </button>
        </div>
      </div>
    </div>
    <div class="modal-footer text-right">
      <button type="submit" class="btn btn-success">Save</button>
      <button type="button" class="btn btn-default" (click)="modalRef.hide()">
        Cancel
      </button>
    </div>
  </form>
</ng-template>
