import { RouterModule, Routes } from '@angular/router';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule } from '@angular/common/http';
import { SupplierListComponent } from './list/list.component';
import { AuthGuard } from '../../auth/auth.guard';
import { SupplierService } from './supplier.service';
import { SharedModule } from '../../shared/shared.module';
import { SupplierCreateComponent } from './create/create.component';
import { SupplierFormComponent } from './form/form.component';
import { SupplierEditComponent } from './edit/edit.component';

const routes: Routes = [
  {
    path: 'suppliers',
    component: SupplierListComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'suppliers/create',
    component: SupplierCreateComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'suppliers/:id/edit',
    component: SupplierEditComponent,
    canActivate: [AuthGuard],
  },
];

@NgModule({
  declarations: [
    SupplierListComponent,
    SupplierCreateComponent,
    SupplierFormComponent,
    SupplierEditComponent,
  ],
  imports: [
    BrowserModule,
    RouterModule,
    FormsModule,
    HttpClientModule,
    SharedModule,
    RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' }),
  ],
  exports: [RouterModule],
  providers: [AuthGuard, SupplierService],
})
export class SupplierRoutingModule {}
