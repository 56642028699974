<div class="row">
  <div class="col-sm-12">
    <section class="panel">
      <app-page-title [loading]="isLoading" title="List Substatuses">
        {{ totalResults }} Results Found
      </app-page-title>

      <div class="panel-body">
        <table class="table table-striped">
          <thead>
            <tr>
              <th>Domain</th>
              <th>Substatus Name</th>
              <th class="text-right">
                <a
                  [routerLink]="['/substatuses/create']"
                  class="btn btn-success"
                  >Create</a
                >
              </th>
            </tr>
          </thead>

          <tbody>
            <tr *ngFor="let substatus of substatuses">
              <td>{{ substatus?.domain?.name || 'Global' }}</td>
              <td>{{ substatus?.name }}</td>
              <td class="text-right">
                <div
                  *ngIf="
                    (substatus.domain && substatus.domain.name) ||
                    authService.hasPermission('config.all-domains')
                  "
                  class="btn-group"
                >
                  <a
                    [routerLink]="['/substatuses', substatus?.id, 'edit']"
                    class="btn btn-sm btn-warning"
                    type="button"
                    >Edit</a
                  >
                  <button
                    aria-expanded="false"
                    aria-haspopup="true"
                    class="btn btn-sm btn-warning dropdown-toggle"
                    data-toggle="dropdown"
                    type="button"
                  >
                    <span class="caret"></span>
                    <span class="sr-only">Toggle Dropdown</span>
                  </button>
                  <ul class="dropdown-menu">
                    <li>
                      <a
                        (click)="deleteModal.confirmDialog(substatus.id)"
                        href="javascript:;"
                        >Delete</a
                      >
                    </li>
                  </ul>
                </div>
              </td>
            </tr>
          </tbody>

          <tfoot>
            <tr>
              <td class="text-center" colspan="99">
                <app-pagination
                  (pageChanged)="onPageChanged($event)"
                  [currentPage]="currentPage"
                  [totalPages]="totalPages"
                ></app-pagination>
              </td>
            </tr>
          </tfoot>
        </table>
      </div>
    </section>
  </div>
</div>

<app-delete-modal
  #deleteModal
  (confirm)="delete($event)"
  [itemDeleted]="substatusDeleted"
  type="substatus"
></app-delete-modal>
