import { NgModule } from '@angular/core';
import { CaseStatusService } from './case-status.service';
import { HttpClientModule } from '@angular/common/http';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule } from '@angular/forms';
import { SharedModule } from '../../shared/shared.module';

@NgModule({
  imports: [BrowserModule, FormsModule, HttpClientModule, SharedModule],
  providers: [CaseStatusService],
})
export class CaseStatusRoutingModule {}
