import { Component, Input } from '@angular/core';
import { Country } from '../country';

@Component({
  selector: 'app-country-form',
  templateUrl: 'form.component.html',
})
export class CountryFormComponent {
  @Input() country: Country;
}
