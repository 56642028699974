import { useState } from 'react';

type AlertProps = {
  color: string;
  title?: string;
  message: string;
  allowClosing?: boolean;
  className?: string;
};

const Alert = ({
  color,
  title,
  message,
  allowClosing,
  className,
}: AlertProps) => {
  const [isVisible, setIsVisible] = useState<boolean>(true);

  return isVisible === true ? (
    <div
      className={`tw-bg-${color}-200 tw-border-${color}-400 tw-rounded-md tw-p-2 ${className}`}
    >
      {title && (
        <div
          className={`tw-text-${color}-800 tw-font-bold tw-mb-2 tw-text-2xl uppercase`}
        >
          {title}
        </div>
      )}
      <div className={`tw-text-${color}-700 tw-text-xs`}>{message}</div>
    </div>
  ) : null;
};

export default Alert;
