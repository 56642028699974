import {useState} from 'react';

const useToggle = (
  initialState: boolean = false,
): [boolean, () => void, (visible: boolean) => void] => {
  const [dropdownVisible, setDropdownVisible] = useState<boolean>(initialState);

  return [
    dropdownVisible,
    () => setDropdownVisible(!dropdownVisible),
    (visible: boolean) => setDropdownVisible(visible),
  ];
};

export default useToggle;
