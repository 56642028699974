import { Component, Input } from '@angular/core';
import { PaymentType } from '../payment-type';

@Component({
  selector: 'app-payment-type-form',
  templateUrl: 'form.component.html',
})
export class PaymentTypeFormComponent {
  @Input() paymentType: PaymentType;
}
