import {
  DevToolsExtension,
  NgRedux,
  NgReduxModule,
} from '@angular-redux/store';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { AngularFireModule } from '@angular/fire';
import { AngularFirestoreModule } from '@angular/fire/firestore';
import { AngularFireFunctionsModule, REGION } from '@angular/fire/functions';
import { AngularFirePerformanceModule } from '@angular/fire/performance';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { ModalModule } from 'ngx-bootstrap/modal';
import { PopoverModule } from 'ngx-bootstrap/popover';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { MarkdownModule } from 'ngx-markdown';
import { QuillModule } from 'ngx-quill';
import { combineReducers, Reducer } from 'redux';
import persistState from 'redux-localstorage';
import ReduxThunk from 'redux-thunk';

import { environment } from '../environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AuthService } from './auth/auth.service';
import { INITIAL_STATE, ReduxStore } from './common/redux.store';
import { EmploymentStatusActions } from './common/settings/employment-status/employment-status.actions';
import { employmentStatusReducer } from './common/settings/employment-status/employment-status.reducer';
import { UserActions } from './common/user/user.actions';
import { userReducer } from './common/user/user.reducer';
import { HttpErrorInterceptor } from './shared/http/http-error.interceptor';
import { NotificationService } from './shared/notification.service';
import { SharedModule } from './shared/shared.module';

@NgModule({
  declarations: [AppComponent],
  imports: [
    // ServiceWorkerModule.register('/ngsw-worker.js', {enabled: environment.production}),
    PopoverModule.forRoot(),
    TooltipModule.forRoot(),
    ModalModule.forRoot(),
    QuillModule.forRoot(),
    BrowserModule,
    AppRoutingModule,
    SharedModule,
    AngularFireModule.initializeApp(environment.firebase),
    AngularFirePerformanceModule,
    AngularFirestoreModule,
    MarkdownModule.forRoot(),
    BrowserAnimationsModule,
    BsDatepickerModule.forRoot(),
    AngularFireFunctionsModule,
    NgReduxModule,
  ],
  providers: [
    { provide: REGION, useValue: 'europe-west1' },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpErrorInterceptor,
      multi: true,
    },
    AuthService,
    NotificationService,
    UserActions,
    EmploymentStatusActions,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
  constructor(ngRedux: NgRedux<ReduxStore>, devTools: DevToolsExtension) {
    const allReducers: Reducer<ReduxStore> = combineReducers({
      user: userReducer,
      employmentStatuses: employmentStatusReducer,
    });

    const storeEnhancers = [persistState(['user'])];

    if (devTools.isEnabled()) {
      storeEnhancers.push(devTools.enhancer());
    }

    ngRedux.configureStore(
      allReducers,
      INITIAL_STATE,
      [ReduxThunk],
      storeEnhancers,
    );
  }
}
