import { Component, Input, OnInit } from '@angular/core';
import { ProductService } from '../product.service';
import { Product } from '../product';
import { NotificationService } from '../../shared/notification.service';
import { SelectComparisons } from '../../shared/pipes/select.comparisons';

@Component({
  selector: 'app-product-select',
  templateUrl: 'select.component.html',
})
export class ProductSelectComponent extends SelectComparisons
  implements OnInit {
  /**
   * The model that we will be adding the product to.
   */
  @Input() model: any;

  /**
   * Do we require this field in the main form it is
   * included in?
   */
  @Input() required: boolean;

  /**
   * A list of all the products currently loaded.
   *
   * @type {Product[]}
   */
  public products: Product[] = [];

  /**
   * Are we currently performing a loading action?
   *
   * @type {boolean}
   */
  public isLoading: boolean = true;

  /**
   * Load up all our required services and subscribe to every observable we need.
   *
   * @param {ProductService} productService
   * @param {NotificationService} notificationService
   */
  constructor(
    private productService: ProductService,
    private notificationService: NotificationService,
  ) {
    super();
    this.productService.loading.subscribe(isLoading => {
      this.isLoading = isLoading;
    });
  }

  /**
   * When the component is initialised we set it to load
   * page one of the product list.
   */
  public ngOnInit(): void {
    this.loadProducts(1, 10000);
  }

  /**
   * Loads a list of products using the given per page limit.
   *
   * @param {number} page The current page we require.
   * @param {number} limit The number of results per page.
   */
  public loadProducts(page?: number, limit?: number): void {
    const listProducts = this.productService
      .listProducts(page, limit)
      .subscribe((products: Product[]) => {
        this.products = products;
        listProducts.unsubscribe();
      });
  }
}
