import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';

import {Address} from '../../shared/address/address';
import {NotificationService} from '../../shared/notification.service';
import {Case} from '../case';
import {CaseService} from '../case.service';
import {Person} from '../people/person';

@Component({
  selector: 'app-case-create',
  templateUrl: 'create.component.html',
})
export class CaseCreateComponent implements OnInit {
  /**
   * Details of the case being created.
   */
  public case: Case;

  /**
   * Are we currently performing a loading action?
   *
   * @type {boolean}
   */
  public isLoading: boolean = false;

  /**
   * Load up all our required services and subscribe to every observable we need.
   *
   * @param {CaseService} caseService
   * @param {NotificationService} notificationService
   * @param {Router} router
   */
  constructor(
    private caseService: CaseService,
    private notificationService: NotificationService,
    private router: Router,
  ) {
    this.caseService.loading.subscribe((result) => (this.isLoading = result));
  }

  /**
   * Initialise the component, creating a new blank case if required.
   */
  public ngOnInit(): void {
    if (!this.case) {
      this.case = new Case();

      this.case.administrationPeriod = 12;
      this.case.allowPost = true;

      const newPerson = new Person();
      newPerson.addresses = [new Address()];

      this.case.people = [newPerson];
    }
  }

  /**
   * Perform the create action which adds the new case into the backend.
   */
  public create() {
    const createCase = this.caseService.createCase(this.case).subscribe(
      (result: Case) => {
        this.notificationService.success(
          `Case Created`,
          `New case ${result.people
            .map((person) => `${person.firstName} ${person.lastName}`)
            .join(', ')} has been created with ID of ${result.id}.`,
          5,
        );
        this.case = new Case();
        createCase.unsubscribe();
        this.router.navigate([`/cases/${result.id}`]);
      },
      (err) => {
        this.notificationService.error(
          `Could not create Case`,
          err.error.message,
          10,
        );
      },
    );
  }
}
