import { Component } from '@angular/core';
import { AccountTypeService } from '../account-type.service';
import { AccountType } from '../account-type';
import { ActivatedRoute, Router } from '@angular/router';
import { NotificationService } from '../../../shared/notification.service';

@Component({
  selector: 'app-account-type-edit',
  templateUrl: 'edit.component.html',
})
export class AccountTypeEditComponent {
  /**
   * Details of the accountType being edited.
   */
  public accountType: AccountType;

  /**
   * Are we currently performing a loading action?
   *
   * @type {boolean}
   */
  public isLoading: boolean = false;

  /**
   * Load up all our required services and subscribe to every observable we need.
   *
   * @param {AccountTypeService} accountTypeService
   * @param {NotificationService} notificationService
   * @param {Router} router
   * @param {ActivatedRoute} route
   */
  constructor(
    private accountTypeService: AccountTypeService,
    private notificationService: NotificationService,
    private router: Router,
    private route: ActivatedRoute,
  ) {
    this.accountTypeService.loading.subscribe(
      result => (this.isLoading = result),
    );
    this.route.params.subscribe(params => this.loadAccountType(params.id));
  }

  /**
   * Perform the edit action which send the edited accountType to the backend.
   */
  public edit() {
    const editAccountType = this.accountTypeService
      .updateAccountType(this.accountType.id, this.accountType)
      .subscribe(
        (result: AccountType) => {
          this.notificationService.success(
            `Account Type Updated`,
            `The account Type ${
              result.name
            } been updated with the specified changes.`,
            5,
          );
          this.accountType = new AccountType();
          editAccountType.unsubscribe();
          this.router.navigate(['/account-types']);
        },
        err => {
          this.notificationService.error(
            `Account Type Not Found`,
            err.error.message,
            10,
          );
          this.router.navigate(['/account-types']);
        },
      );
  }

  /**
   * Loads the requested accountType so we can display it and let the user edit the fields.
   *
   * @param {string} id
   */
  private loadAccountType(id: string) {
    this.accountTypeService.findAccountType(id).subscribe(
      result => {
        this.accountType = result;
      },
      err => {
        this.notificationService.error(
          `Account Type Not Found`,
          err.error.message,
          10,
        );
        this.router.navigate(['/account-types']);
      },
    );
  }
}
