import { Component, Input, OnInit } from '@angular/core';
import { PermissionService } from '../permission.service';
import { Permission } from '../permission';
import { NotificationService } from '../../shared/notification.service';
import { SelectComparisons } from '../../shared/pipes/select.comparisons';

@Component({
  selector: 'app-permission-select',
  templateUrl: 'select.component.html',
})
export class PermissionSelectComponent extends SelectComparisons
  implements OnInit {
  /**
   * The model that we will be adding the permission to.
   */
  @Input() model: any;

  /**
   * Do we require this field in the main form it is
   * included in?
   */
  @Input() required: boolean;

  /**
   * A list of all the permissions currently loaded.
   *
   * @type {Permission[]}
   */
  public permissions: Permission[] = [];

  /**
   * Are we currently performing a loading action?
   *
   * @type {boolean}
   */
  public isLoading: boolean = true;

  /**
   * Load up all our required services and subscribe to every observable we need.
   *
   * @param {PermissionService} permissionService
   * @param {NotificationService} notificationService
   */
  constructor(
    private permissionService: PermissionService,
    private notificationService: NotificationService,
  ) {
    super();
    this.permissionService.loading.subscribe(isLoading => {
      this.isLoading = isLoading;
    });
  }

  /**
   * When the component is initialised we set it to load
   * page one of the permission list.
   */
  public ngOnInit(): void {
    this.loadPermissions(1, 10000);
    if (!this.model.permissions) {
      this.model.permissions = [];
    }
  }

  public updatePermission(permission: Permission) {
    const validCheck = this.isValidPermission(permission);
    if (validCheck !== false) {
      this.model.permissions.splice(validCheck, 1);
    } else {
      this.model.permissions.push(permission);
    }
  }

  public isValidPermission(permission: Permission): number | boolean {
    let isValid: number | boolean = false;

    this.model.permissions.forEach((singlePermission, index) => {
      if (permission.id === singlePermission.id) {
        isValid = index;
      }
    });

    return isValid;
  }

  /**
   * Loads a list of permissions using the given per page limit.
   *
   * @param {number} page The current page we require.
   * @param {number} limit The number of results per page.
   */
  public loadPermissions(page?: number, limit?: number): void {
    const listPermissions = this.permissionService
      .listPermissions(page, limit)
      .subscribe((permissions: Permission[]) => {
        this.permissions = permissions;
        listPermissions.unsubscribe();
      });
  }
}
