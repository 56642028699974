import { Observable } from 'rxjs';
import { Address } from '../../../shared/address/address';
import { HttpRequestService } from '../../../shared/http/http-request.service';

export class CasePersonAddressService extends HttpRequestService {
  private endpoint: string = 'cases/:caseId/people/:personId/addresses';

  /**
   * Updates an already existing address in the system.
   * @param item The address to create.
   * @param caseId Used in creating the endpoint route.
   * @param personId Used in creating the endpoint route.
   */
  public updateAddress(
    id: string,
    address: Address,
    caseId: string,
    personId: string,
  ): Observable<Address> {
    return this.update(this.endpoint, id, address, {
      parameters: {
        caseId,
        personId,
      },
    });
  }

  /**
   * Creates a new address in the system.
   * @param item The address to create.
   * @param caseId Used in creating the endpoint route.
   * @param personId Used in creating the endpoint route.
   */
  public createAddress(
    item: Address,
    caseId: string,
    personId: string,
  ): Observable<Address> {
    return this.create(this.endpoint, item, {
      parameters: {
        caseId,
        personId,
      },
    });
  }
}
