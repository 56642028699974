import 'rxjs/Rx';

import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Router} from '@angular/router';
import * as moment from 'moment';
import {BsModalRef} from 'ngx-bootstrap/modal';

import {environment} from '../../../../../environments/environment';
import {AuthService} from '../../../../auth/auth.service';
import {HttpRequestResponse} from '../../../../shared/http/http-request-response';
import {NotificationService} from '../../../../shared/notification.service';

@Component({
  selector   : 'app-case-fee-comparison-schedule',
  templateUrl: 'schedule.component.html',
})
export class CaseFeeComparisonScheduleComponent implements OnInit {
  @Input() paymentFrequency: string;
  @Output()
  paymentScheduleCreatedAction: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Input() public caseId: string;
  @Input() modalRef: BsModalRef;
  @Input() initialDividend: number;
  @Input() disposableIncome: number;
  @Input() public duration: number;
  public startDate: string;
  public method: string;
  public payment: number;
  public isSaving: boolean = false;
  private readonly httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization : 'Bearer ' + this.authService.getToken(),
    }),
  };

  constructor(
    private http: HttpClient,
    private readonly authService: AuthService,
    protected readonly notification: NotificationService,
    private router: Router,
  )
  {
    this.startDate = moment().format('YYYY-MM-DD');
  }

  get paymentFrequencyFormatted(): string {
    return this.paymentFrequency
      .replace('month', 'Monthly')
      .replace('week', 'Weekly')
      .replace('bi', 'Bi')
      .replace('four', 'Four');
  }

  get frequency(): string {
    let returnFrequency = 'm';

    switch (this.paymentFrequency) {
      case 'week':
        returnFrequency = 'w';
        break;
      case 'bi-week':
        returnFrequency = '2w';
        break;
      case 'four-week':
        returnFrequency = '4w';
        break;
      default:
        returnFrequency = 'm';
    }

    return returnFrequency;
  }

  ngOnInit(): void {
    this.payment = Math.ceil(this.disposableIncome);
    this.changePaymentFrequency(this.frequency);
  }

  changePaymentFrequency(to: string) {
    if (to === 'w') {
      this.payment = Math.round(this.disposableIncome * 12 / 52);
    } else if (to === '2w') {
      this.payment = Math.round(this.disposableIncome * 12 / 52 * 2);
    } else if (to === '4w') {
      this.payment = Math.round(this.disposableIncome * 12 / 52 * 4);
    } else {
      this.payment = this.disposableIncome;
    }
  }

  public createSchedule(): void {
    this.isSaving = true;
    this.http
      .post<HttpRequestResponse>(
        `${environment.endpoint}cases/${this.caseId}/payment-schedule`,
        {
          startDate      : this.startDate,
          frequency      : this.frequency,
          payment        : this.payment,
          duration       : this.duration,
          method         : this.method,
          initialDividend: this.initialDividend,
        },
        this.httpOptions,
      )
      .map((data: HttpRequestResponse) => {
        return data.data;
      })
      .subscribe(item => {
        this.paymentScheduleCreatedAction.next(true);
        this.notification.success(
          'Success',
          'Payment Schedule has been Created',
          5,
        );
        this.isSaving = false;
        this.modalRef.hide();
        this.router.navigateByUrl(`cases/${this.caseId}`);
      });
  }
}
