import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import {
  CasePaymentSchedule,
  CasePaymentScheduleItem,
} from '../../../../../chief-types/src/CasePaymentSchedule';
import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  TemplateRef,
} from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { AuthService } from '../../../auth/auth.service';
import { CaseFinancialSummaryService } from '../../financial-summary/case-financial-summary.service';
import { HttpRequestResponse } from '../../../shared/http/http-request-response';
import { NotificationService } from '../../../shared/notification.service';
import { environment } from '../../../../environments/environment';

@Component({
  selector: 'app-case-payment-schedule-view',
  templateUrl: 'view.component.html',
  styleUrls: ['view.component.scss'],
})
export class CasePaymentScheduleViewComponent implements OnInit {
  @Output()
  paymentScheduleCreated: EventEmitter<boolean> = new EventEmitter<boolean>();

  @Output()
  paymentScheduleUpdated = new EventEmitter<boolean>();

  public scheduleDeleted: EventEmitter<boolean> = new EventEmitter<boolean>();

  @Input() receiptsAndPaymentsTotal = 0;

  financialSummary;
  @Input() caseId: string;
  @Input() caseRefreshed: EventEmitter<string>;
  @Input() paymentSchedule?: CasePaymentSchedule;
  modalRef: BsModalRef;
  private readonly httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + this.authService.getToken(),
    }),
  };

  constructor(
    private readonly http: HttpClient,
    public readonly authService: AuthService,
    private modalService: BsModalService,
    private notificationService: NotificationService,
    private readonly financialSummaryService: CaseFinancialSummaryService,
  ) {}

  get paymentMethod() {
    switch (this.paymentSchedule?.method) {
      case 'recurring-card':
        return 'Recurring Card Payment';
      default:
        return 'Standing Order';
    }
  }

  public loadFinancialSummary() {
    this.financialSummaryService
      .showClientDistributions(this.caseId)
      .subscribe((data) => {
        this.financialSummary = data;
        this.receiptsAndPaymentsTotal =
          this.sumAll(this.financialSummary.catOnes, 'paid') * -1;
      });
  }

  public sumAll(values: any[], field: string): number {
    return !values || values.length <= 0
      ? 0
      : values
          .map((v: any): number => v[field])
          .reduce((t: number, i: number): number => Number(t) + Number(i));
  }

  ngOnInit(): void {
    this.loadFinancialSummary();
    this.caseRefreshed.subscribe((id) => {
      this.caseId = id;
    });
  }

  deletePaymentHoliday(itemId: string) {
    this.http
      .delete<HttpRequestResponse>(
        `${environment.endpoint}cases/${this.caseId}/payment-schedule/${itemId}/holiday`,
        this.httpOptions,
      )
      .map((data: HttpRequestResponse) => data.data)
      .subscribe((item) => {
        this.paymentScheduleUpdated.next(true);
      });
  }

  addPaymentHoliday(itemId: string) {
    this.http
      .post<HttpRequestResponse>(
        `${environment.endpoint}cases/${this.caseId}/payment-schedule/${itemId}/holiday`,
        {},
        this.httpOptions,
      )
      .map((data: HttpRequestResponse) => data.data)
      .subscribe((item) => {
        this.paymentScheduleUpdated.next(true);
      });
  }

  openModal(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(template);
  }

  public deleteSinglePayment(id, scheduleItemId, { textDate, value }): void {
    this.http
      .delete<HttpRequestResponse>(
        `${environment.endpoint}cases/${this.caseId}/payment-schedule/${id}/delete-item/${scheduleItemId}/${textDate}/${value}`,
        this.httpOptions,
      )
      .map((data: HttpRequestResponse) => data.data)
      .subscribe((item) => {
        this.paymentScheduleCreated.next(true);
        this.notificationService.success(
          `Payment Schedule Item Deleted`,
          `Payment Schedule Item from ${textDate} with value £${
            value / 100
          } was deleted.`,
          5,
        );
        this.scheduleDeleted.next(true);
      });
  }

  public delete(id: string) {
    this.http
      .delete<HttpRequestResponse>(
        `${environment.endpoint}cases/${this.caseId}/payment-schedule/${id}`,
        this.httpOptions,
      )
      .map((data: HttpRequestResponse) => data.data)
      .subscribe((item) => {
        this.paymentScheduleCreated.next(true);
        this.notificationService.success(
          `Payment Schedule Deleted`,
          `Payment Schedule with ID ${id} was deleted.`,
          5,
        );
        this.scheduleDeleted.next(true);
      });
  }

  public canEditExpectedValue(item: CasePaymentScheduleItem) {
    const validStatuses = ['unpaid', 'partial'];
    return (
      this.authService.hasPermission('user.edit-payment-schedule-item') &&
      validStatuses.includes(item.status)
    );
  }

  public reload() {
    this.loadFinancialSummary();
    this.paymentScheduleUpdated.next(true);
  }

  formatTextDate(dateString) {
    const date = new Date(dateString);
    return `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}`;
  }
}
