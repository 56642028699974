import {Domain} from '../domains/domain';
import {Permission} from '../permissions/permission';
import {Address} from '../shared/address/address';
import {ContactDetails} from '../shared/contact-details/contact-details';
import {Country} from '../countries/country';
import {Department} from "../settings/departments/department";

export class User implements Address, ContactDetails {
  public id?: string;
  public name: string;
  public emailAddress: string;
  public password?: string;
  public domain: Domain;
  public permissions: Permission[];
  public address1: string;
  public address2: string;
  public address3: string;
  public town: string;
  public county: string;
  public postalCode: string;
  public country: Country;
  public landlineNumber: string;
  public mobileNumber: string;
  public faxNumber: string;
  public webAddress: string;
  public avatar: string;
  public additionalDomains: Domain[];
    public department?: Department;
}
