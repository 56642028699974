import { Component, Input, OnInit } from '@angular/core';
import { MaritalStatusService } from '../marital-status.service';
import { MaritalStatus } from '../marital-status';
import { NotificationService } from '../../shared/notification.service';
import { SelectComparisons } from '../../shared/pipes/select.comparisons';

@Component({
  selector: 'app-marital-status-select',
  templateUrl: 'select.component.html',
})
export class MaritalStatusSelectComponent extends SelectComparisons
  implements OnInit {
  /**
   * The model that we will be adding the maritalStatus to.
   */
  @Input() model: any;

  /**
   * Do we require this field in the main form it is
   * included in?
   */
  @Input() required: boolean;

  /**
   * A list of all the marital-statuses currently loaded.
   *
   * @type {MaritalStatus[]}
   */
  public maritalStatuses: MaritalStatus[] = [];

  /**
   * Are we currently performing a loading action?
   *
   * @type {boolean}
   */
  public isLoading: boolean = true;

  /**
   * Load up all our required services and subscribe to every observable we need.
   *
   * @param {MaritalStatusService} maritalStatusService
   * @param {NotificationService} notificationService
   */
  constructor(
    private maritalStatusService: MaritalStatusService,
    private notificationService: NotificationService,
  ) {
    super();
    this.maritalStatusService.loading.subscribe(isLoading => {
      this.isLoading = isLoading;
    });
  }

  /**
   * When the component is initialised we set it to load
   * page one of the maritalStatus list.
   */
  public ngOnInit(): void {
    this.loadMaritalStatuses(1, 10000);
  }

  /**
   * Loads a list of marital-statuses using the given per page limit.
   *
   * @param {number} page The current page we require.
   * @param {number} limit The number of results per page.
   */
  public loadMaritalStatuses(page?: number, limit?: number): void {
    const listMaritalStatuses = this.maritalStatusService
      .listMaritalStatuses(page, limit)
      .subscribe((maritalStatuses: MaritalStatus[]) => {
        this.maritalStatuses = maritalStatuses;
        listMaritalStatuses.unsubscribe();
      });
  }
}
