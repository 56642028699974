import { Component, EventEmitter, OnInit } from '@angular/core';
import { CreditService } from '../credit.service';
import { Credit } from '../credit';
import { NotificationService } from '../../shared/notification.service';

@Component({
  selector: 'app-credit-list',
  templateUrl: 'list.component.html',
  styleUrls: [],
})
export class CreditListComponent implements OnInit {
  public credit: Credit;

  /**
   * A list of all the credits currently loaded.
   *
   * @type {Credit[]}
   */
  public credits: Credit[] = [];

  /**
   * Are we currently performing a loading action?
   *
   * @type {boolean}
   */
  public isLoading: boolean = true;

  /**
   * The total number of pages in this list of credits.
   *
   * @type {number}
   */
  public totalPages: number = 1;

  /**
   * The current page of credits that we are viewing.
   *
   * @type {number}
   */
  public currentPage: number = 1;

  /**
   * The total number of credits that can be viewed in the system.
   *
   * @type {number}
   */
  public totalResults: number = 0;

  /**
   * An Emitter to alert other components when a credit has been deleted.
   *
   * @type {EventEmitter<boolean>}
   */
  public creditDeleted: EventEmitter<boolean> = new EventEmitter<boolean>();

  /**
   * Load up all our required services and subscribe to every observable we need.
   *
   * @param {CreditService} creditService
   * @param {NotificationService} notificationService
   */
  constructor(
    private creditService: CreditService,
    private notificationService: NotificationService,
  ) {
    this.creditService.loading.subscribe(isLoading => {
      this.isLoading = isLoading;
    });
    this.creditService.totalPages.subscribe(totalPages => {
      this.totalPages = totalPages;
    });
    this.creditService.currentPage.subscribe(currentPage => {
      this.currentPage = currentPage;
    });
    this.creditService.totalResults.subscribe(totalResults => {
      this.totalResults = totalResults;
    });
  }

  /**
   * When the component is initialised we set it to load
   * page one of the credit list.
   */
  public ngOnInit(): void {
    this.loadCredits(1);
  }

  /**
   * When we receive a page change from the pagination
   * component we load the appropriate page for the
   * credit list.
   *
   * @param {number} pageNumber
   */
  public onPageChanged(pageNumber: number): void {
    this.loadCredits(pageNumber);
  }

  /**
   * Loads a list of credits using the given per page limit.
   *
   * @param {number} page The current page we require.
   * @param {number} limit The number of results per page.
   */
  public loadCredits(page?: number, limit?: number): void {
    const listCredits = this.creditService
      .listCredits(page, limit)
      .subscribe((credits: Credit[]) => {
        this.credits = credits;
        listCredits.unsubscribe();
      });
  }

  /**
   * When receiving confirmation of an item being deleted
   * we call the back end with the appropriate credit ID
   * to delete the credit.
   *
   * @param {string} id
   */
  public delete(id: string) {
    this.creditService.deleteCredit(id).subscribe(
      deleted => {
        this.notificationService.success(
          `Credit Deleted`,
          `Credit with ID ${id} was deleted.`,
          5,
        );
        this.creditDeleted.next(true);
        this.onPageChanged(this.currentPage);
      },
      err => {
        this.notificationService.error(
          `Credit Not Deleted`,
          err.error.message,
          10,
        );
        this.creditDeleted.next(false);
        this.onPageChanged(this.currentPage);
      },
    );
  }
}
