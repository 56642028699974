import {HttpClient} from '@angular/common/http';
import {Component, EventEmitter, Output, ViewChild} from '@angular/core';
import {FileItem, FileUploader, ParsedResponseHeaders} from 'ng2-file-upload';

import {environment} from '../../../../environments/environment';
import {AuthService} from '../../../auth/auth.service';
import {ChartOfAccountsService} from '../../chart-of-accounts/chart-of-accounts.service';

@Component({
  selector: 'app-financials-invoicing-upload',
  templateUrl: 'upload.component.html',
})
export class FinancialsInvoiceUploadComponent {
  @ViewChild('staticModal') staticModal;

  @Output() completed = new EventEmitter<string>();

  public newInvoiceId: string;
  public uploader: FileUploader;
  public csvUploader: FileUploader;
  public hasBaseDropZoneOver: boolean = false;
  public hasBaseDropZoneOverCsv: boolean = false;

  public disabled: boolean = false;
  private givenCaseId: string;

  public invoiceUpload: any;

  constructor(
    private readonly authService: AuthService,
    private readonly chartOfAccountsService: ChartOfAccountsService,
    private http: HttpClient,
  ) {
    this.createUploader();
    this.createCsvUploader();

    this.invoiceUpload = {
      chartOfAccounts: undefined,
      value: 0,
    };
  }

  public get caseId(): string {
    return this.givenCaseId;
  }

  public fileOverBase(e: any): void {
    this.hasBaseDropZoneOver = e;
  }

  public fileOverBaseCsv(e: any): void {
    this.hasBaseDropZoneOverCsv = e;
  }

  upload() {
    this.disabled = true;
    this.uploader.options.additionalParameter = {
      chartOfAccountsId: this.invoiceUpload.chartOfAccounts.id,
      value: this.invoiceUpload.value * 100,
    };
    this.uploader.uploadAll();
  }

  public open(caseId: string): void {
    this.givenCaseId = caseId;
    this.staticModal.show();
  }

  public close() {
    this.workCompleted();
  }

  public confirmCreate($event) {
    $event.preventDefault();
    this.disabled = true;
  }

  private createUploader() {
    this.uploader = new FileUploader({
      url: `${environment.endpoint}financials/invoicing/upload`,
      authToken: `Bearer ${this.authService.getToken()}`,
    });

    this.uploader.onBeforeUploadItem = (item) => {
      item.withCredentials = false;
    };

    this.uploader.onSuccessItem = (
      item: FileItem,
      response: string,
      status: number,
      headers: ParsedResponseHeaders,
    ) => {
      if (response) {
        const parsedResponse = JSON.parse(response);
        this.newInvoiceId = parsedResponse.data;
        if (this.csvUploader.queue.length > 0) {
          this.csvUploader.uploadAll();
        } else {
          this.workCompleted();
        }
      }
    };
  }

  private createCsvUploader() {
    this.csvUploader = new FileUploader({
      url: `${environment.apiUrl}files/upload?path=financials/invoicing/supporting-csv`,
      authToken: `Bearer ${this.authService.getToken()}`,
    });

    this.csvUploader.onBeforeUploadItem = (item) => {
      item.withCredentials = false;
    };

    this.csvUploader.onSuccessItem = (
      item: FileItem,
      response: string,
      status: number,
      headers: ParsedResponseHeaders,
    ) => {
      if (response) {
        const parsedResponse = JSON.parse(response);
        const startImportingCsvFile = parsedResponse['key'];

        // Start the Supporting CSV file importing
        this.http
          .post(`${environment.apiUrl}invoicing/match-csv`, {
            invoiceId: this.newInvoiceId,
            filename: startImportingCsvFile,
            chartOfAccountsId: this.invoiceUpload.chartOfAccounts.id,
          })
          .subscribe((r) => {
            this.workCompleted();
          });
      }
    };
  }

  private workCompleted() {
    this.createUploader();
    this.disabled = false;
    this.staticModal.hide();
    this.completed.next(this.newInvoiceId);
    this.newInvoiceId = undefined;
  }
}
