import { Component, Input, OnInit } from '@angular/core';

import { BankingAccount } from '../../accounts/banking-account';
import { BankingAccountType } from '../banking-account-type';
import { BankingAccountTypeService } from '../banking-account-type.service';
import { NotificationService } from '../../../../shared/notification.service';
import { SelectComparisons } from '../../../../shared/pipes/select.comparisons';

@Component({
  selector: 'app-banking-account-type-select',
  templateUrl: 'select.component.html',
})
export class BankingAccountTypeSelectComponent
  extends SelectComparisons
  implements OnInit
{
  /**
   * The model that we will be adding the accountType to.
   */
  @Input() model: BankingAccount;

  /**
   * Do we require this field in the main form it is
   * included in?
   */
  @Input() required: boolean;

  /**
   * A list of all the banking-account-types currently loaded.
   *
   * @type {BankingAccountType[]}
   */
  public accountTypes: BankingAccountType[] = [];

  /**
   * Are we currently performing a loading action?
   *
   * @type {boolean}
   */
  public isLoading: boolean = true;

  /**
   * Load up all our required services and subscribe to every observable we need.
   *
   * @param {BankingAccountTypeService} accountTypeService
   * @param {NotificationService} notificationService
   */
  constructor(
    private accountTypeService: BankingAccountTypeService,
    private notificationService: NotificationService,
  ) {
    super();
    this.accountTypeService.loading.subscribe((isLoading) => {
      this.isLoading = isLoading;
    });
  }

  /**
   * When the component is initialised we set it to load
   * page one of the accountType list.
   */
  public ngOnInit(): void {
    this.loadBankingAccountTypes(1, 10000);
  }

  /**
   * Loads a list of banking-account-types using the given per page limit.
   *
   * @param {number} page The current page we require.
   * @param {number} limit The number of results per page.
   */
  public loadBankingAccountTypes(page?: number, limit?: number): void {
    const listBankingAccountTypes = this.accountTypeService
      .listBankingAccountTypes(page, limit)
      .subscribe((accountTypes: BankingAccountType[]) => {
        this.accountTypes = accountTypes;
        listBankingAccountTypes.unsubscribe();
      });
  }
}
