import { Injectable } from '@angular/core';
import { Expenditure } from './expenditure';
import { Observable } from 'rxjs';

import { HttpRequestService } from '../shared/http/http-request.service';

@Injectable()
export class ExpenditureService extends HttpRequestService {
  /**
   * The endpoint that we call to get the expenditure information.
   *
   * @type {string}
   */
  private readonly endpoint: string = 'expenditures';

  /**
   * Brings back a list of all accessible expenditures.
   *
   * @param {number} page The page number we are currently on.
   * @param {number} limit The number of expenditures to display per page.
   * @returns {Observable<Expenditure[]>}
   */
  public listExpenditures(
    page?: number,
    limit?: number,
  ): Observable<Expenditure[]> {
    return this.list(this.endpoint, page, undefined, limit);
  }

  /**
   * Finds details of the expenditure from the given ID.
   *
   * @param {string} expenditureId The ID of the expenditure we require details for.
   * @returns {Observable<Expenditure>}
   */
  public findExpenditure(expenditureId: string): Observable<Expenditure> {
    return this.findById(this.endpoint, expenditureId);
  }

  /**
   * Creates a new expenditure from the given Expenditure object.
   *
   * @param {Expenditure} newExpenditure The details of the expenditure to be created.
   * @returns {Observable<Expenditure | boolean>}
   */
  public createExpenditure(
    newExpenditure: Expenditure,
  ): Observable<Expenditure | boolean> {
    return this.create(this.endpoint, newExpenditure);
  }

  /**
   * Updates a expenditure with the given expenditure object for the given expenditure ID.
   *
   * @param {string} expenditureId The ID of the expenditure needing updating.
   * @param {Expenditure} newDetails The new expenditure object to update with.
   * @returns {Observable<Expenditure | boolean>}
   */
  public updateExpenditure(
    expenditureId: string,
    newDetails: Expenditure,
  ): Observable<Expenditure | boolean> {
    return this.update(this.endpoint, expenditureId, newDetails);
  }

  /**
   * Deletes a expenditure based on the given expenditure ID.
   *
   * @param {string} expenditureId The ID of the expenditure needing deleting.
   * @returns {Observable<boolean>}
   */
  public deleteExpenditure(expenditureId: string): Observable<boolean> {
    return this.delete(this.endpoint, expenditureId);
  }
}
