import { Component, EventEmitter, OnInit } from '@angular/core';
import { DepartmentService } from '../department.service';
import { Department } from '../department';
import { NotificationService } from '../../../shared/notification.service';
import { AuthService } from '../../../auth/auth.service';

@Component({
    selector   : 'app-department-list',
    templateUrl: 'list.component.html',
    styleUrls  : [],
})
export class DepartmentListComponent implements OnInit {
    public department: Department;

    /**
     * A list of all the departments currently loaded.
     *
     * @type {Department[]}
     */
    public departments: Department[] = [];

    /**
     * Are we currently performing a loading action?
     *
     * @type {boolean}
     */
    public isLoading: boolean = true;

    /**
     * The total number of pages in this list of departments.
     *
     * @type {number}
     */
    public totalPages: number = 1;

    /**
     * The current page of departments that we are viewing.
     *
     * @type {number}
     */
    public currentPage: number = 1;

    /**
     * The total number of departments that can be viewed in the system.
     *
     * @type {number}
     */
    public totalResults: number = 0;

    /**
     * An Emitter to alert other components when a department has been deleted.
     *
     * @type {EventEmitter<boolean>}
     */
    public departmentDeleted: EventEmitter<boolean> = new EventEmitter<boolean>();

    /**
     * Load up all our required services and subscribe to every observable we need.
     *
     * @param {DepartmentService} departmentService
     * @param {NotificationService} notificationService
     */
    constructor(
      private departmentService: DepartmentService,
      private notificationService: NotificationService,
      private authService: AuthService,
    )
    {
        this.departmentService.loading.subscribe(isLoading => {
            this.isLoading = isLoading;
        });
        this.departmentService.totalPages.subscribe(totalPages => {
            this.totalPages = totalPages;
        });
        this.departmentService.currentPage.subscribe(currentPage => {
            this.currentPage = currentPage;
        });
        this.departmentService.totalResults.subscribe(totalResults => {
            this.totalResults = totalResults;
        });
    }

    /**
     * When the component is initialised we set it to load
     * page one of the department list.
     */
    public ngOnInit(): void {
        this.loadDepartments(1);
    }

    /**
     * When we receive a page change from the pagination
     * component we load the appropriate page for the
     * department list.
     *
     * @param {number} pageNumber
     */
    public onPageChanged(pageNumber: number): void {
        this.loadDepartments(pageNumber);
    }

    /**
     * Loads a list of departments using the given per page limit.
     *
     * @param {number} page The current page we require.
     * @param {number} limit The number of results per page.
     */
    public loadDepartments(page?: number, limit?: number): void {
        const listDepartments = this.departmentService
          .listDepartments(page, limit)
          .subscribe((departments: Department[]) => {
              this.departments = departments;
              listDepartments.unsubscribe();
          });
    }

    /**
     * When receiving confirmation of an item being deleted
     * we call the back end with the appropriate department ID
     * to delete the department.
     *
     * @param {string} id
     */
    public delete(id: string) {
        this.departmentService.deleteDepartment(id).subscribe(
          deleted => {
              this.notificationService.success(
                `Department Deleted`,
                `Department with ID ${id} was deleted.`,
                5,
              );
              this.departmentDeleted.next(true);
              this.onPageChanged(this.currentPage);
          },
          err => {
              this.notificationService.error(
                `Department Not Deleted`,
                err.error.message,
                10,
              );
              this.departmentDeleted.next(false);
              this.onPageChanged(this.currentPage);
          },
        );
    }
}
