import {HttpClientModule} from '@angular/common/http';
import {NgModule} from '@angular/core';
import {FormsModule} from '@angular/forms';
import {BrowserModule} from '@angular/platform-browser';
import {RouterModule, Routes} from '@angular/router';
import {FileUploadModule} from 'ng2-file-upload';
import {QuillModule} from 'ngx-quill';

import {AuthGuard} from '../auth/auth.guard';
import {SharedModule} from '../shared/shared.module';
import {TemplateCreateComponent} from './create/create.component';
import {TemplateEditComponent} from './edit/edit.component';
import {TemplateFormComponent} from './form/form.component';
import {TemplateListComponent} from './list/list.component';
import {TemplateService} from './template.service';

const routes: Routes = [
  {
    path: 'templates',
    component: TemplateListComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'templates/create',
    component: TemplateCreateComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'templates/:id/edit',
    component: TemplateEditComponent,
    canActivate: [AuthGuard],
  },
];

@NgModule({
  declarations: [
    TemplateListComponent,
    TemplateCreateComponent,
    TemplateFormComponent,
    TemplateEditComponent,
  ],
  imports: [
    QuillModule,
    BrowserModule,
    RouterModule,
    FormsModule,
    HttpClientModule,
    SharedModule,
    FileUploadModule,
    RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' }),
  ],
  exports: [RouterModule],
  providers: [AuthGuard, TemplateService],
})
export class TemplateRoutingModule {
}
