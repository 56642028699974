import { Component, Input } from '@angular/core';

import { ChartOfAccounts } from '../chart-of-accounts';

@Component({
  selector: 'app-chart-of-accounts-form',
  templateUrl: 'form.component.html',
})
export class ChartOfAccountsFormComponent {
  @Input() chartOfAccounts: ChartOfAccounts;

  public toggleItem(field: string) {
    this.chartOfAccounts[field] = !this.chartOfAccounts[field];
  }
}
