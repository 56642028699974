import {Component, Input} from '@angular/core';

import {CreditorVote} from '../case-creditor-vote';

@Component({
  selector: 'app-case-creditor-votes-form',
  templateUrl: 'form.component.html',
})
export class CaseCreditorVotesFormComponent {
  @Input()
  public creditorVote: CreditorVote = undefined;
}
