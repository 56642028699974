import {RouterModule, Routes} from '@angular/router';
import {NgModule} from '@angular/core';
import {FormsModule} from '@angular/forms';
import {BrowserModule} from '@angular/platform-browser';
import {HttpClientModule} from '@angular/common/http';
import {CreditorStatusListComponent} from './list/list.component';
import {AuthGuard} from '../auth/auth.guard';
import {CreditorStatusService} from './creditor-status.service';
import {SharedModule} from '../shared/shared.module';
import {CreditorStatusCreateComponent} from './create/create.component';
import {CreditorStatusFormComponent} from './form/form.component';
import {CreditorStatusEditComponent} from './edit/edit.component';

const routes: Routes = [
  {
    path: 'creditor-statuses',
    component: CreditorStatusListComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'creditor-statuses/create',
    component: CreditorStatusCreateComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'creditor-statuses/:id/edit',
    component: CreditorStatusEditComponent,
    canActivate: [AuthGuard],
  },
];

@NgModule({
  declarations: [
    CreditorStatusListComponent,
    CreditorStatusCreateComponent,
    CreditorStatusFormComponent,
    CreditorStatusEditComponent,
  ],
  imports: [
    BrowserModule,
    RouterModule,
    FormsModule,
    HttpClientModule,
    SharedModule,
    RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' }),
  ],
  exports: [RouterModule],
  providers: [AuthGuard, CreditorStatusService],
})
export class CreditorStatusRoutingModule {
}
