import { Component, Input, OnInit } from '@angular/core';
import { IntroducerService } from '../introducer.service';
import { Introducer } from '../introducer';
import { NotificationService } from '../../shared/notification.service';
import { SelectComparisons } from '../../shared/pipes/select.comparisons';

@Component({
  selector: 'app-introducer-select',
  templateUrl: 'select.component.html',
})
export class IntroducerSelectComponent extends SelectComparisons
  implements OnInit {
  /**
   * The model that we will be adding the introducer to.
   */
  @Input() model: any;

  /**
   * Do we require this field in the main form it is
   * included in?
   */
  @Input() required: boolean;

  /**
   * A list of all the introducers currently loaded.
   *
   * @type {Introducer[]}
   */
  public introducers: Introducer[] = [];

  /**
   * Are we currently performing a loading action?
   *
   * @type {boolean}
   */
  public isLoading: boolean = true;

  /**
   * Load up all our required services and subscribe to every observable we need.
   *
   * @param {IntroducerService} introducerService
   * @param {NotificationService} notificationService
   */
  constructor(
    private introducerService: IntroducerService,
    private notificationService: NotificationService,
  ) {
    super();
    this.introducerService.loading.subscribe(isLoading => {
      this.isLoading = isLoading;
    });
  }

  /**
   * When the component is initialised we set it to load
   * page one of the introducer list.
   */
  public ngOnInit(): void {
    this.loadIntroducers(1, 10000);
  }

  /**
   * Loads a list of introducers using the given per page limit.
   *
   * @param {number} page The current page we require.
   * @param {number} limit The number of results per page.
   */
  public loadIntroducers(page?: number, limit?: number): void {
    const listIntroducers = this.introducerService
      .listIntroducers(page, limit)
      .subscribe((introducers: Introducer[]) => {
        this.introducers = introducers;
        listIntroducers.unsubscribe();
      });
  }
}
