import { Address } from '../shared/address/address';
import { ContactDetails } from '../shared/contact-details/contact-details';
import { Country } from '../countries/country';
import { Domain } from '../domains/domain';
import { Product } from '../products/product';

export class Introducer implements Address, ContactDetails {
  public id?: string;
  public name: string;
  public domain: Domain;
  public product: Product;
  public apiKey: string;
  public address1: string;
  public address2: string;
  public address3: string;
  public town: string;
  public county: string;
  public postalCode: string;
  public country: Country;
  public landlineNumber: string;
  public mobileNumber: string;
  public faxNumber: string;
  public webAddress: string;
  public emailAddress: string;
  public callbackUrl: string;
  public debtpanelDomainId?: string;
}
