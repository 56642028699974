import {Component, EventEmitter, Output, ViewChild} from '@angular/core';

import {Case} from '../../case';
import {CaseService} from '../../case.service';
import {CaseStatusService} from '../case-status.service';

@Component({
  selector: 'app-case-status-change',
  templateUrl: 'change.component.html',
})
export class CaseStatusChangeComponent {
  @ViewChild('staticModal') staticModal;

  @Output() completed = new EventEmitter<boolean>();

  public disabled: boolean = false;

  public model: Case;

  private givenCaseId: string;

  constructor(
    private readonly caseStatusService: CaseStatusService,
    private readonly caseService: CaseService,
  ) {}

  public get caseId(): string {
    return this.givenCaseId;
  }

  public open(caseId: string): void {
    this.givenCaseId = caseId;

    this.caseService.findCase(caseId).subscribe((result) => {
      this.model = result;
      this.staticModal.show();
    });
  }

  public close() {
    this.workCompleted();
  }

  public confirmChange($event) {
    $event.preventDefault();
    this.disabled = true;

    this.caseStatusService
      .createCaseStatus(
        {
          case: this.model,
          status: this.model.status,
          substatus: this.model.substatus,
        },
        { parameters: { id: this.model.id } },
      )
      .subscribe((results) => {
        this.workCompleted();
      });
  }

  private workCompleted() {
    this.model = undefined;
    this.disabled = false;
    this.staticModal.hide();
    this.completed.next(true);
  }
}
