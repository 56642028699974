import { Component, Input } from '@angular/core';
import { Permission } from '../permission';

@Component({
  selector: 'app-permission-form',
  templateUrl: 'form.component.html',
})
export class PermissionFormComponent {
  @Input() permission: Permission;
}
