import { Injectable } from '@angular/core';
import { ExpenditureCategory } from './expenditure-category';
import { Observable } from 'rxjs';

import { HttpRequestService } from '../shared/http/http-request.service';

@Injectable()
export class ExpenditureCategoryService extends HttpRequestService {
  /**
   * The endpoint that we call to get the expenditureCategory information.
   *
   * @type {string}
   */
  private readonly endpoint: string = 'expenditure-categories';

  /**
   * Brings back a list of all accessible expenditureCategories.
   *
   * @param {number} page The page number we are currently on.
   * @param {number} limit The number of expenditureCategories to display per page.
   * @returns {Observable<ExpenditureCategory[]>}
   */
  public listExpenditureCategories(
    page?: number,
    limit?: number,
  ): Observable<ExpenditureCategory[]> {
    return this.list(this.endpoint, page, undefined, limit);
  }

  /**
   * Finds details of the expenditureCategory from the given ID.
   *
   * @param {string} expenditureCategoryId The ID of the expenditureCategory we require details for.
   * @returns {Observable<ExpenditureCategory>}
   */
  public findExpenditureCategory(
    expenditureCategoryId: string,
  ): Observable<ExpenditureCategory> {
    return this.findById(this.endpoint, expenditureCategoryId);
  }

  /**
   * Creates a new expenditureCategory from the given ExpenditureCategory object.
   *
   * @param {ExpenditureCategory} newExpenditureCategory The details of the expenditureCategory to be created.
   * @returns {Observable<ExpenditureCategory | boolean>}
   */
  public createExpenditureCategory(
    newExpenditureCategory: ExpenditureCategory,
  ): Observable<ExpenditureCategory | boolean> {
    return this.create(this.endpoint, newExpenditureCategory);
  }

  /**
   * Updates a expenditureCategory with the given expenditureCategory object for the given expenditureCategory ID.
   *
   * @param {string} expenditureCategoryId The ID of the expenditureCategory needing updating.
   * @param {ExpenditureCategory} newDetails The new expenditureCategory object to update with.
   * @returns {Observable<ExpenditureCategory | boolean>}
   */
  public updateExpenditureCategory(
    expenditureCategoryId: string,
    newDetails: ExpenditureCategory,
  ): Observable<ExpenditureCategory | boolean> {
    return this.update(this.endpoint, expenditureCategoryId, newDetails);
  }

  /**
   * Deletes a expenditureCategory based on the given expenditureCategory ID.
   *
   * @param {string} expenditureCategoryId The ID of the expenditureCategory needing deleting.
   * @returns {Observable<boolean>}
   */
  public deleteExpenditureCategory(
    expenditureCategoryId: string,
  ): Observable<boolean> {
    return this.delete(this.endpoint, expenditureCategoryId);
  }
}
