import { BrowserModule } from '@angular/platform-browser';
import { CaseCalendarEditComponent } from './edit/edit.component';
import { CaseCalendarListComponent } from './list/list.component';
import { CaseCalendarService } from './case-calendar.service';
import { CasePersonService } from '../people/case-person.service';
import { FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { ModalModule } from 'ngx-bootstrap/modal';
import { NgModule } from '@angular/core';
import { PopoverModule } from 'ngx-bootstrap/popover';
import { QuillModule } from 'ngx-quill';
import { SharedModule } from '../../shared/shared.module';
import { TooltipModule } from 'ngx-bootstrap/tooltip';

@NgModule({
  declarations: [CaseCalendarEditComponent, CaseCalendarListComponent],
  imports: [
    ModalModule.forRoot(),
    TooltipModule.forRoot(),
    QuillModule,
    BrowserModule,
    FormsModule,
    HttpClientModule,
    SharedModule,
    PopoverModule,
  ],
  exports: [CaseCalendarListComponent],
  providers: [CaseCalendarService, CasePersonService],
})
export class CaseCalendarRoutingModule {}
