import { RouterModule, Routes } from '@angular/router';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule } from '@angular/common/http';
import { ProductListComponent } from './list/list.component';
import { AuthGuard } from '../auth/auth.guard';
import { ProductService } from './product.service';
import { SharedModule } from '../shared/shared.module';
import { ProductCreateComponent } from './create/create.component';
import { ProductFormComponent } from './form/form.component';
import { ProductEditComponent } from './edit/edit.component';

const routes: Routes = [
  {
    path: 'products',
    component: ProductListComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'products/create',
    component: ProductCreateComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'products/:id/edit',
    component: ProductEditComponent,
    canActivate: [AuthGuard],
  },
];

@NgModule({
  declarations: [
    ProductListComponent,
    ProductCreateComponent,
    ProductFormComponent,
    ProductEditComponent,
  ],
  imports: [
    BrowserModule,
    RouterModule,
    FormsModule,
    HttpClientModule,
    SharedModule,
    RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' }),
  ],
  exports: [RouterModule],
  providers: [AuthGuard, ProductService],
})
export class ProductRoutingModule {}
