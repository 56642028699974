import { Component, Input, OnInit } from '@angular/core';
import { FolderService } from '../folder.service';
import { Folder } from '../folder';
import { NotificationService } from '../../../shared/notification.service';
import { SelectComparisons } from '../../../shared/pipes/select.comparisons';

@Component({
  selector: 'app-folder-select',
  templateUrl: 'select.component.html',
})
export class FolderSelectComponent extends SelectComparisons implements OnInit {
  /**
   * The model that we will be adding the folder to.
   */
  @Input() model: any;

  /**
   * Do we require this field in the main form it is
   * included in?
   */
  @Input() required: boolean;

  /**
   * A list of all the folders currently loaded.
   *
   * @type {Folder[]}
   */
  public folders: Folder[] = [];

  /**
   * Are we currently performing a loading action?
   *
   * @type {boolean}
   */
  public isLoading: boolean = true;

  /**
   * Load up all our required services and subscribe to every observable we need.
   *
   * @param {FolderService} folderService
   * @param {NotificationService} notificationService
   */
  constructor(
    private folderService: FolderService,
    private notificationService: NotificationService,
  ) {
    super();
    this.folderService.loading.subscribe(isLoading => {
      this.isLoading = isLoading;
    });
  }

  /**
   * When the component is initialised we set it to load
   * page one of the folder list.
   */
  public ngOnInit(): void {
    this.loadFolders(1, 10000);
  }

  /**
   * Loads a list of folders using the given per page limit.
   *
   * @param {number} page The current page we require.
   * @param {number} limit The number of results per page.
   */
  public loadFolders(page?: number, limit?: number): void {
    const listFolders = this.folderService
      .listFolders(page, limit)
      .subscribe((folders: Folder[]) => {
        this.folders = folders;
        listFolders.unsubscribe();
      });
  }
}
