import {Component, EventEmitter, Output, ViewChild} from '@angular/core';

import {CaseComplaintService} from '../case-complaint.service';

@Component({
  selector: 'app-case-complaint-update',
  templateUrl: 'update.component.html',
})
export class CaseComplaintUpdateComponent {
  @ViewChild('staticModal') staticModal;

  @Output() completed = new EventEmitter<boolean>();

  public disabled: boolean = false;

  public model: string;

  private givenCaseId: string;

  public complaintStatus: any = {
    complaintStatus: undefined,
  };

  public note: string = '';

  constructor(private readonly caseComplaintService: CaseComplaintService) {}

  public get caseId(): string {
    return this.givenCaseId;
  }

  public open(caseId: string, model: string): void {
    this.givenCaseId = caseId;
    this.model = model;
    this.staticModal.show();
  }

  public close() {
    this.workCompleted();
  }

  public confirmUpdate($event) {
    $event.preventDefault();
    this.disabled = true;
    this.caseComplaintService
      .updateCaseComplaintStatus(
        this.model,
        this.complaintStatus.complaintStatus.id,
        this.note,
        {
          parameters: { id: this.caseId },
        },
      )
      .subscribe(() => {
        this.complaintStatus = {
          complaintStatus: undefined,
        };
        this.note = '';
        this.workCompleted();
      });
  }

  private workCompleted() {
    this.model = undefined;
    this.disabled = false;
    this.staticModal.hide();
    this.completed.next(true);
  }
}
