<div class="user-icon">
  <ng-template #userPopTemplate>
    <table class="table table-striped">
      <tr>
        <td class="text-center"><img [src]="getUserAvatar()" width="100%"></td>
      </tr>
      <tr>
        <td class="text-center">{{ user?.name }}</td>
      </tr>
      <tr>
        <td class="text-center">{{ user?.domain?.name }}</td>
      </tr>
      <tr *ngIf="user && user.department && user.department.name">
        <td class="text-center">{{ user?.department?.name }}</td>
      </tr>
      <tr>
        <td class="text-center">{{ user?.emailAddress }}</td>
      </tr>
    </table>
  </ng-template>
  <img
          *ngIf="!loading"
          [popover]="userPopTemplate"
          [src]="getUserAvatar()"
          [style.maxHeight]="size + 'px'"
          [style.maxWidth]="size + 'px'"
          placement="auto"
          triggers="mouseenter:mouseleave">

  <img
          *ngIf="loading"
          [style.maxHeight]="size + 'px'"
          [style.maxWidth]="size + 'px'"
          src="https://i0.wp.com/cdnjs.cloudflare.com/ajax/libs/semantic-ui/0.16.1/images/loader-large.gif"
          title="loading">
</div>
