<div class="modal fade" bsModal #staticModal="bs-modal" [config]="{backdrop: 'static'}"
     tabindex="-1" role="dialog" aria-labelledby="mySmallModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-md">
    <form (submit)="confirmCreate($event)" #completedForm="ngForm" ngNativeValidate>
      <div class="modal-content panel-success">
        <div class="modal-header panel-heading">
          <h4 class="modal-title pull-left">Create Asset</h4>
          <button type="button" class="close pull-right" aria-label="Close" (click)="close()">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <app-case-asset-form [caseId]="caseId" [model]="model"></app-case-asset-form>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-default" (click)="close()">Cancel</button>
          <button type="submit" class="btn btn-success" [disabled]="disabled">Create</button>
        </div>
      </div>
    </form>
  </div>
</div>
