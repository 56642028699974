import { Component, EventEmitter, OnInit } from '@angular/core';
import { IncomeService } from '../income.service';
import { Income } from '../income';
import { NotificationService } from '../../shared/notification.service';

@Component({
  selector: 'app-income-list',
  templateUrl: 'list.component.html',
  styleUrls: [],
})
export class IncomeListComponent implements OnInit {
  public income: Income;

  /**
   * A list of all the incomes currently loaded.
   *
   * @type {Income[]}
   */
  public incomes: Income[] = [];

  /**
   * Are we currently performing a loading action?
   *
   * @type {boolean}
   */
  public isLoading: boolean = true;

  /**
   * The total number of pages in this list of incomes.
   *
   * @type {number}
   */
  public totalPages: number = 1;

  /**
   * The current page of incomes that we are viewing.
   *
   * @type {number}
   */
  public currentPage: number = 1;

  /**
   * The total number of incomes that can be viewed in the system.
   *
   * @type {number}
   */
  public totalResults: number = 0;

  /**
   * An Emitter to alert other components when a income has been deleted.
   *
   * @type {EventEmitter<boolean>}
   */
  public incomeDeleted: EventEmitter<boolean> = new EventEmitter<boolean>();

  /**
   * Load up all our required services and subscribe to every observable we need.
   *
   * @param {IncomeService} incomeService
   * @param {NotificationService} notificationService
   */
  constructor(
    private incomeService: IncomeService,
    private notificationService: NotificationService,
  ) {
    this.incomeService.loading.subscribe(isLoading => {
      this.isLoading = isLoading;
    });
    this.incomeService.totalPages.subscribe(totalPages => {
      this.totalPages = totalPages;
    });
    this.incomeService.currentPage.subscribe(currentPage => {
      this.currentPage = currentPage;
    });
    this.incomeService.totalResults.subscribe(totalResults => {
      this.totalResults = totalResults;
    });
  }

  /**
   * When the component is initialised we set it to load
   * page one of the income list.
   */
  public ngOnInit(): void {
    this.loadIncomes(1);
  }

  /**
   * When we receive a page change from the pagination
   * component we load the appropriate page for the
   * income list.
   *
   * @param {number} pageNumber
   */
  public onPageChanged(pageNumber: number): void {
    this.loadIncomes(pageNumber);
  }

  /**
   * Loads a list of incomes using the given per page limit.
   *
   * @param {number} page The current page we require.
   * @param {number} limit The number of results per page.
   */
  public loadIncomes(page?: number, limit?: number): void {
    const listIncomes = this.incomeService
      .listIncomes(page, limit)
      .subscribe((incomes: Income[]) => {
        this.incomes = incomes;
        listIncomes.unsubscribe();
      });
  }

  /**
   * When receiving confirmation of an item being deleted
   * we call the back end with the appropriate income ID
   * to delete the income.
   *
   * @param {string} id
   */
  public delete(id: string) {
    this.incomeService.deleteIncome(id).subscribe(
      deleted => {
        this.notificationService.success(
          `Income Deleted`,
          `Income with ID ${id} was deleted.`,
          5,
        );
        this.incomeDeleted.next(true);
        this.onPageChanged(this.currentPage);
      },
      err => {
        this.notificationService.error(
          `Income Not Deleted`,
          err.error.message,
          10,
        );
        this.incomeDeleted.next(false);
        this.onPageChanged(this.currentPage);
      },
    );
  }
}
