import { Injectable } from '@angular/core';
import { AccountType } from './account-type';
import { Observable } from 'rxjs';

import { HttpRequestService } from '../../shared/http/http-request.service';

@Injectable()
export class AccountTypeService extends HttpRequestService {
  /**
   * The endpoint that we call to get the accountType information.
   *
   * @type {string}
   */
  private readonly endpoint: string = 'account-types';

  /**
   * Brings back a list of all accessible account-types.
   *
   * @param {number} page The page number we are currently on.
   * @param {number} limit The number of account-types to display per page.
   * @returns {Observable<AccountType[]>}
   */
  public listAccountTypes(
    page?: number,
    limit?: number,
  ): Observable<AccountType[]> {
    return this.list(this.endpoint, page, undefined, limit);
  }

  /**
   * Finds details of the accountType from the given ID.
   *
   * @param {string} accountTypeId The ID of the accountType we require details for.
   * @returns {Observable<AccountType>}
   */
  public findAccountType(accountTypeId: string): Observable<AccountType> {
    return this.findById(this.endpoint, accountTypeId);
  }

  /**
   * Creates a new accountType from the given AccountType object.
   *
   * @param {AccountType} newAccountType The details of the accountType to be created.
   * @returns {Observable<AccountType | boolean>}
   */
  public createAccountType(
    newAccountType: AccountType,
  ): Observable<AccountType | boolean> {
    return this.create(this.endpoint, newAccountType);
  }

  /**
   * Updates a accountType with the given accountType object for the given accountType ID.
   *
   * @param {string} accountTypeId The ID of the accountType needing updating.
   * @param {AccountType} newDetails The new accountType object to update with.
   * @returns {Observable<AccountType | boolean>}
   */
  public updateAccountType(
    accountTypeId: string,
    newDetails: AccountType,
  ): Observable<AccountType | boolean> {
    return this.update(this.endpoint, accountTypeId, newDetails);
  }

  /**
   * Deletes a accountType based on the given accountType ID.
   *
   * @param {string} accountTypeId The ID of the accountType needing deleting.
   * @returns {Observable<boolean>}
   */
  public deleteAccountType(accountTypeId: string): Observable<boolean> {
    return this.delete(this.endpoint, accountTypeId);
  }
}
