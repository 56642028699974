import { Component, EventEmitter, Input, OnInit } from '@angular/core';
import { IncomeCategoryService } from '../../income-categories/income-category.service';
import { IncomeCategory } from '../../income-categories/income-category';

@Component({
  selector: 'app-case-income-form',
  templateUrl: 'case-income-form.component.html',
})
export class CaseIncomeFormComponent implements OnInit {
  @Input() caseRefreshed: EventEmitter<string>;

  saveAllClicked: EventEmitter<boolean> = new EventEmitter<boolean>(false);

  @Input() public caseId: string = undefined;

  private categoryList: IncomeCategory[] = [];

  constructor(private readonly service: IncomeCategoryService) {}

  public saveAll() {
    this.saveAllClicked.next(true);
  }

  public get categories(): IncomeCategory[] {
    return this.categoryList;
  }

  public ngOnInit(): void {
    this.loadCategories();
    this.caseRefreshed.subscribe(id => {
      this.caseId = id;
      this.loadCategories();
    });
  }

  private loadCategories() {
    this.service
      .listIncomeCategories(1, 1000)
      .subscribe((categories: IncomeCategory[]) => {
        this.categoryList = categories;
      });
  }
}
