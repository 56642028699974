import { Component, EventEmitter, Input, OnInit } from '@angular/core';
import { NotificationService } from '../../../shared/notification.service';
import { CaseBreach } from '../case-breach';
import { CaseBreachService } from '../case-breach.service';
import { AuthService } from '../../../auth/auth.service';

@Component({
  selector: 'app-case-breach-list',
  templateUrl: 'list.component.html',
})
export class CaseBreachListComponent implements OnInit {
  @Input() caseRefreshed: EventEmitter<string>;

  @Input() caseId: string;

  public caseBreaches: CaseBreach[] = [];

  public isLoading: boolean = true;

  public totalPages: number = 1;

  public currentPage: number = 1;

  public totalResults: number = 0;

  public itemDeleted: EventEmitter<boolean> = new EventEmitter<boolean>();

  public breachCreated: EventEmitter<boolean> = new EventEmitter<boolean>();

  public newBreach: CaseBreach = new CaseBreach();

  public editorOptions = {
    placeholder: 'Enter your note...',
    modules: {
      toolbar: ['bold', 'link'],
    },
  };

  constructor(
    private caseBreachService: CaseBreachService,
    private notificationService: NotificationService,
    public authService: AuthService,
  ) {
    this.caseBreachService.loading.subscribe(isLoading => {
      this.isLoading = isLoading;
    });
    this.caseBreachService.totalPages.subscribe(totalPages => {
      this.totalPages = totalPages;
    });
    this.caseBreachService.currentPage.subscribe(currentPage => {
      this.currentPage = currentPage;
    });
    this.caseBreachService.totalResults.subscribe(totalResults => {
      this.totalResults = totalResults;
    });
  }

  public ngOnInit(): void {
    this.loadCaseBreaches(1);
    this.caseRefreshed.subscribe(id => {
      this.caseId = id;
      this.onPageChanged(1);
    });
  }

  public onPageChanged(pageNumber: number): void {
    this.loadCaseBreaches(pageNumber);
  }

  public loadCaseBreaches(page?: number, limit?: number): void {
    const listCountries = this.caseBreachService
      .listCaseBreaches(page, limit, { parameters: { id: this.caseId } })
      .subscribe((caseBreaches: CaseBreach[]) => {
        this.caseBreaches = caseBreaches;
        listCountries.unsubscribe();
      });
  }

  public create(formData: CaseBreach) {
    this.caseBreachService
      .createCaseBreach(this.newBreach, { parameters: { id: this.caseId } })
      .subscribe(results => {
        this.breachCreated.next(true);
        this.onPageChanged(1);
      });
    console.log('creating', this.newBreach);
  }

  public delete(id: string) {
    this.caseBreachService.deleteCaseBreach(id).subscribe(
      deleted => {
        this.notificationService.success(
          `Breach Deleted`,
          `Vehicle with ID ${id} was deleted.`,
          5,
        );
        this.itemDeleted.next(true);
        this.onPageChanged(this.currentPage);
      },
      err => {
        this.notificationService.error(
          `Breach Not Deleted`,
          err.error.message,
          10,
        );
        this.itemDeleted.next(false);
        this.onPageChanged(this.currentPage);
      },
    );
  }
}
