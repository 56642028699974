import { BankingAccountItem } from './banking-account-item';
import { HttpRequestService } from '../../../shared/http/http-request.service';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable()
export class BankingAccountItemService extends HttpRequestService {
  /**
   * The endpoint that we call to get the accountItem information.
   *
   * @item {string}
   */
  private readonly endpoint: string = 'banking/accounts/:accountId/items';

  /**
   * Brings back a list of all accessible account-items.
   *
   * @param {number} page The page number we are currently on.
   * @param {number} limit The number of account-items to display per page.
   * @returns {Observable<BankingAccountItem[]>}
   */
  public listBankingAccountItems(
    page?: number,
    limit?: number,
    options?: any,
    query?: any,
  ): Observable<BankingAccountItem[]> {
    return this.list(this.endpoint, page, query, limit, options);
  }

  canReconcile(itemOneId: string, itemTwoId: string) {
    const canReconcile = this.functions.httpsCallable('bankingCanReconcile');

    return canReconcile({
      itemOneId,
      itemTwoId,
    });
  }

  reconcileItems(id: string, items: string[], options) {
    return this.create(
      `${this.endpoint}/${id}/reconcile`,
      {
        entries: items,
        relink: true,
      },
      options,
    );
  }

  reconcileClient(
    id: string,
    clientId: string,
    date: string,
    value: number,
    paidDate: string,
    note: string,
    scheduleItemId: string,
    options,
  ) {
    return this.create(
      `${this.endpoint}/${id}/reconcile`,
      {
        id,
        otherEntries: [
          {
            clientId,
            expectedDate: date,
            value,
            paidDate,
            scheduleItemId,
          },
        ],
        note,
        value,
      },
      options,
    );
  }

  /**
   *         event.given_id.id,
        this.chosenClientLink,
        Math.round(event.given_id.value * 100),
        event.given_id.paidDate,
   */
  reconcileToLoan(
    id: string,
    clientId: string,
    value: number,
    paidDate: string,
  ) {
    const bankingReconcileToLoan = this.functions.httpsCallable(
      'bankingReconcileToLoan',
    );

    return bankingReconcileToLoan({
      transactionId: id,
      clientId,
      value,
      paidDate,
    });
  }

  /**
   * Finds details of the accountItem from the given ID.
   *
   * @param {string} accountItemId The ID of the accountItem we require details for.
   * @returns {Observable<BankingAccountItem>}
   */
  public findBankingAccountItem(
    accountItemId: string,
  ): Observable<BankingAccountItem> {
    return this.findById(this.endpoint, accountItemId);
  }

  /**
   * Creates a new accountItem from the given BankingAccountItem object.
   *
   * @param {BankingAccountItem} newBankingAccountItem The details of the accountItem to be created.
   * @returns {Observable<BankingAccountItem | boolean>}
   */
  public createBankingAccountItem(
    newBankingAccountItem: BankingAccountItem,
  ): Observable<BankingAccountItem | boolean> {
    return this.create(this.endpoint, newBankingAccountItem);
  }

  /**
   * Updates a accountItem with the given accountItem object for the given accountItem ID.
   *
   * @param {string} accountItemId The ID of the accountItem needing updating.
   * @param {BankingAccountItem} newDetails The new accountItem object to update with.
   * @returns {Observable<BankingAccountItem | boolean>}
   */
  public updateBankingAccountItem(
    accountItemId: string,
    newDetails: BankingAccountItem,
  ): Observable<BankingAccountItem | boolean> {
    return this.update(this.endpoint, accountItemId, newDetails);
  }

  /**
   * Deletes a accountItem based on the given accountItem ID.
   *
   * @param {string} accountItemId The ID of the accountItem needing deleting.
   * @returns {Observable<boolean>}
   */
  public deleteBankingAccountItem(accountItemId: string): Observable<boolean> {
    return this.delete(this.endpoint, accountItemId);
  }
}
