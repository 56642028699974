import { Component, EventEmitter, OnInit } from '@angular/core';
import { PaymentTypeService } from '../payment-type.service';
import { PaymentType } from '../payment-type';
import { NotificationService } from '../../../shared/notification.service';

@Component({
  selector: 'app-payment-type-list',
  templateUrl: 'list.component.html',
  styleUrls: [],
})
export class PaymentTypeListComponent implements OnInit {
  public paymentType: PaymentType;

  /**
   * A list of all the payment-types currently loaded.
   *
   * @type {PaymentType[]}
   */
  public paymentTypes: PaymentType[] = [];

  /**
   * Are we currently performing a loading action?
   *
   * @type {boolean}
   */
  public isLoading: boolean = true;

  /**
   * The total number of pages in this list of payment-types.
   *
   * @type {number}
   */
  public totalPages: number = 1;

  /**
   * The current page of payment-types that we are viewing.
   *
   * @type {number}
   */
  public currentPage: number = 1;

  /**
   * The total number of payment-types that can be viewed in the system.
   *
   * @type {number}
   */
  public totalResults: number = 0;

  /**
   * An Emitter to alert other components when a paymentType has been deleted.
   *
   * @type {EventEmitter<boolean>}
   */
  public paymentTypeDeleted: EventEmitter<boolean> = new EventEmitter<
    boolean
  >();

  /**
   * Load up all our required services and subscribe to every observable we need.
   *
   * @param {PaymentTypeService} paymentTypeService
   * @param {NotificationService} notificationService
   */
  constructor(
    private paymentTypeService: PaymentTypeService,
    private notificationService: NotificationService,
  ) {
    this.paymentTypeService.loading.subscribe(isLoading => {
      this.isLoading = isLoading;
    });
    this.paymentTypeService.totalPages.subscribe(totalPages => {
      this.totalPages = totalPages;
    });
    this.paymentTypeService.currentPage.subscribe(currentPage => {
      this.currentPage = currentPage;
    });
    this.paymentTypeService.totalResults.subscribe(totalResults => {
      this.totalResults = totalResults;
    });
  }

  /**
   * When the component is initialised we set it to load
   * page one of the paymentType list.
   */
  public ngOnInit(): void {
    this.loadPaymentTypes(1);
  }

  /**
   * When we receive a page change from the pagination
   * component we load the appropriate page for the
   * paymentType list.
   *
   * @param {number} pageNumber
   */
  public onPageChanged(pageNumber: number): void {
    this.loadPaymentTypes(pageNumber);
  }

  /**
   * Loads a list of payment-types using the given per page limit.
   *
   * @param {number} page The current page we require.
   * @param {number} limit The number of results per page.
   */
  public loadPaymentTypes(page?: number, limit?: number): void {
    const listPaymentTypes = this.paymentTypeService
      .listPaymentTypes(page, limit)
      .subscribe((paymentTypes: PaymentType[]) => {
        this.paymentTypes = paymentTypes;
        listPaymentTypes.unsubscribe();
      });
  }

  /**
   * When receiving confirmation of an item being deleted
   * we call the back end with the appropriate paymentType ID
   * to delete the paymentType.
   *
   * @param {string} id
   */
  public delete(id: string) {
    this.paymentTypeService.deletePaymentType(id).subscribe(
      deleted => {
        this.notificationService.success(
          `PaymentType Deleted`,
          `PaymentType with ID ${id} was deleted.`,
          5,
        );
        this.paymentTypeDeleted.next(true);
        this.onPageChanged(this.currentPage);
      },
      err => {
        this.notificationService.error(
          `PaymentType Not Deleted`,
          err.error.message,
          10,
        );
        this.paymentTypeDeleted.next(false);
        this.onPageChanged(this.currentPage);
      },
    );
  }
}
