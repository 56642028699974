import { Address } from '../../shared/address/address';
import { Gender } from '../../genders/gender';
import { MaritalStatus } from '../../marital-statuses/marital-status';
import { Occupation } from '../../occupations/occupation';
import { ResidentialStatus } from '../../residential-statuses/residential-status';
import { Title } from '../../titles/title';

export class Person {
  id: string;
  title: Title;
  firstName: string;
  middleNames: string;
  lastName: string;
  maidenName: string;
  dateOfBirth: string;
  addresses: Address[];
  occupation: Occupation;
  maritalStatus: MaritalStatus;
  residentialStatus: ResidentialStatus;
  residentialRestriction: string;
  gender?: Gender;
}
