import { Component, Input, OnInit } from '@angular/core';
import { UserService } from '../user.service';
import { User } from '../user';
import { NotificationService } from '../../shared/notification.service';
import { SelectComparisons } from '../../shared/pipes/select.comparisons';

@Component({
  selector: 'app-user-select',
  templateUrl: 'select.component.html',
})
export class UserSelectComponent extends SelectComparisons implements OnInit {
  /**
   * The model that we will be adding the user to.
   */
  @Input() model: any;

  /**
   * Do we require this field in the main form it is
   * included in?
   */
  @Input() required: boolean;

  /**
   * A list of all the users currently loaded.
   *
   * @type {User[]}
   */
  public users: User[] = [];

  /**
   * Are we currently performing a loading action?
   *
   * @type {boolean}
   */
  public isLoading: boolean = true;

  /**
   * Load up all our required services and subscribe to every observable we need.
   *
   * @param {UserService} userService
   * @param {NotificationService} notificationService
   */
  constructor(
    private userService: UserService,
    private notificationService: NotificationService,
  ) {
    super();
    this.userService.loading.subscribe(isLoading => {
      this.isLoading = isLoading;
    });
  }

  /**
   * When the component is initialised we set it to load
   * page one of the user list.
   */
  public ngOnInit(): void {
    this.loadUsers(1, 10000);
  }

  /**
   * Loads a list of users using the given per page limit.
   *
   * @param {number} page The current page we require.
   * @param {number} limit The number of results per page.
   */
  public loadUsers(page?: number, limit?: number): void {
    const listUsers = this.userService
      .listUsers(page, limit)
      .subscribe((users: User[]) => {
        this.users = users;
        listUsers.unsubscribe();
      });
  }
}
