import { Injectable } from '@angular/core';
import { Status } from './status';
import { Observable } from 'rxjs';

import { HttpRequestService } from '../shared/http/http-request.service';

@Injectable()
export class StatusService extends HttpRequestService {
  /**
   * The endpoint that we call to get the status information.
   *
   * @type {string}
   */
  private readonly endpoint: string = 'statuses';

  /**
   * Brings back a list of all accessible statuses.
   *
   * @param {number} page The page number we are currently on.
   * @param {number} limit The number of statuses to display per page.
   * @returns {Observable<Status[]>}
   */
  public listStatuses(page?: number, limit?: number): Observable<Status[]> {
    return this.list(this.endpoint, page, undefined, limit);
  }

  /**
   * Finds details of the status from the given ID.
   *
   * @param {string} statusId The ID of the status we require details for.
   * @returns {Observable<Status>}
   */
  public findStatus(statusId: string): Observable<Status> {
    return this.findById(this.endpoint, statusId);
  }

  /**
   * Creates a new status from the given Status object.
   *
   * @param {Status} newStatus The details of the status to be created.
   * @returns {Observable<Status | boolean>}
   */
  public createStatus(newStatus: Status): Observable<Status | boolean> {
    return this.create(this.endpoint, newStatus);
  }

  /**
   * Updates a status with the given status object for the given status ID.
   *
   * @param {string} statusId The ID of the status needing updating.
   * @param {Status} newDetails The new status object to update with.
   * @returns {Observable<Status | boolean>}
   */
  public updateStatus(
    statusId: string,
    newDetails: Status,
  ): Observable<Status | boolean> {
    return this.update(this.endpoint, statusId, newDetails);
  }

  /**
   * Deletes a status based on the given status ID.
   *
   * @param {string} statusId The ID of the status needing deleting.
   * @returns {Observable<boolean>}
   */
  public deleteStatus(statusId: string): Observable<boolean> {
    return this.delete(this.endpoint, statusId);
  }
}
