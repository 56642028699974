import { EventEmitter, Injectable } from '@angular/core';
import { User } from './user';
import { Observable } from 'rxjs';

import { HttpRequestService } from '../shared/http/http-request.service';

@Injectable()
export class UserService extends HttpRequestService {
  /**
   * The endpoint that we call to get the user information.
   *
   * @type {string}
   */
  private readonly endpoint: string = 'users';

  /**
   * Brings back a list of all accessible users.
   *
   * @param {number} page The page number we are currently on.
   * @param {number} limit The number of users to display per page.
   * @returns {Observable<User[]>}
   */
  public listUsers(page?: number, limit?: number): Observable<User[]> {
    return this.list(this.endpoint, page, undefined, limit);
  }

  /**
   * Finds details of the user from the given ID.
   *
   * @param {string} userId The ID of the user we require details for.
   * @returns {Observable<User>}
   */
  public findUser(userId: string): Observable<User> {
    return this.findById(this.endpoint, userId);
  }

  /**
   * Creates a new user from the given User object.
   *
   * @param {User} newUser The details of the user to be created.
   * @returns {Observable<User | boolean>}
   */
  public createUser(newUser: User): Observable<User | boolean> {
    return this.create(this.endpoint, newUser);
  }

  /**
   * Updates a user with the given user object for the given user ID.
   *
   * @param {string} userId The ID of the user needing updating.
   * @param {User} newDetails The new user object to update with.
   * @returns {Observable<User | boolean>}
   */
  public updateUser(
    userId: string,
    newDetails: User,
  ): Observable<User | boolean> {
    return this.update(this.endpoint, userId, newDetails);
  }

  /**
   * Deletes a user based on the given user ID.
   *
   * @param {string} userId The ID of the user needing deleting.
   * @returns {Observable<boolean>}
   */
  public deleteUser(userId: string): Observable<boolean> {
    return this.delete(this.endpoint, userId);
  }
}
