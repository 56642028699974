import { Component } from '@angular/core';
import { FolderService } from '../folder.service';
import { Folder } from '../folder';
import { ActivatedRoute, Router } from '@angular/router';
import { NotificationService } from '../../../shared/notification.service';

@Component({
  selector: 'app-folder-edit',
  templateUrl: 'edit.component.html',
})
export class FolderEditComponent {
  /**
   * Details of the folder being edited.
   */
  public folder: Folder;

  /**
   * Are we currently performing a loading action?
   *
   * @type {boolean}
   */
  public isLoading: boolean = false;

  /**
   * Load up all our required services and subscribe to every observable we need.
   *
   * @param {FolderService} folderService
   * @param {NotificationService} notificationService
   * @param {Router} router
   * @param {ActivatedRoute} route
   */
  constructor(
    private folderService: FolderService,
    private notificationService: NotificationService,
    private router: Router,
    private route: ActivatedRoute,
  ) {
    this.folderService.loading.subscribe(result => (this.isLoading = result));
    this.route.params.subscribe(params => this.loadFolder(params.id));
  }

  /**
   * Perform the edit action which send the edited folder to the backend.
   */
  public edit() {
    const editFolder = this.folderService
      .updateFolder(this.folder.id, this.folder)
      .subscribe(
        (result: Folder) => {
          this.notificationService.success(
            `Folder Updated`,
            `The folder ${result.name} been updated with the specified changes.`,
            5,
          );
          this.folder = new Folder();
          editFolder.unsubscribe();
          this.router.navigate(['/folders']);
        },
        err => {
          this.notificationService.error(
            `Folder Not Found`,
            err.error.message,
            10,
          );
          this.router.navigate(['/folders']);
        },
      );
  }

  /**
   * Loads the requested folder so we can display it and let the user edit the fields.
   *
   * @param {string} id
   */
  private loadFolder(id: string) {
    this.folderService.findFolder(id).subscribe(
      result => {
        this.folder = result;
      },
      err => {
        this.notificationService.error(`Folder Not Found`, err.error.message, 10);
        this.router.navigate(['/folders']);
      },
    );
  }
}
