import 'rxjs/Rx';

import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Directive, EventEmitter, Injectable} from '@angular/core';
import {AngularFirestore} from '@angular/fire/firestore';
import {AngularFireFunctions} from '@angular/fire/functions';
import {Observable} from 'rxjs';

import {environment} from '../../../environments/environment';
import {AuthService} from '../../auth/auth.service';
import {HttpOptions} from './http-options';
import {HttpRequestResponse} from './http-request-response';

@Directive()
@Injectable()
export class HttpRequestService {
  public loading: EventEmitter<boolean> = new EventEmitter<boolean>();
  public totalResults: EventEmitter<number> = new EventEmitter<number>();
  public totalPages: EventEmitter<number> = new EventEmitter<number>();
  public currentPage: EventEmitter<number> = new EventEmitter<number>();
  private readonly httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + this.authService.getToken(),
    }),
  };

  constructor(
    protected http: HttpClient,
    protected readonly authService: AuthService,
    protected db: AngularFirestore,
    protected functions: AngularFireFunctions,
  ) {}

  protected get(
    endpoint: string,
    page?: number,
    query?: any,
    limit?: number,
    options?: HttpOptions,
  ): Observable<any> {
    this.loading.emit(true);
    return this.http
      .get<HttpRequestResponse>(
        endpoint +
          `&page=${page || environment.pagination.startPage}&limit=${
            limit || environment.pagination.perPage
          }${this.parseQueryObject(query) || ''}`,
        this.httpOptions,
      )
      .map((data: HttpRequestResponse) => {
        this.loading.emit(false);
        this.totalPages.emit(
          data.meta && data.meta.totalPages
            ? data.meta.totalPages
            : Math.ceil(
                (data.meta && data.meta.totalResults
                  ? data.meta.totalResults
                  : data.data.length) /
                  (limit || environment.pagination.perPage),
              ),
        );
        this.totalResults.emit(
          data.meta && data.meta.totalResults
            ? data.meta.totalResults
            : data.data.length,
        );
        this.currentPage.emit(
          data.meta && data.meta.currentPage ? data.meta.currentPage : page,
        );

        return data.data;
      });
  }

  protected list(
    endpoint: string,
    page?: number,
    query?: any,
    limit?: number,
    options?: HttpOptions,
  ): Observable<any> {
    this.loading.emit(true);
    return this.http
      .get<HttpRequestResponse>(
        environment.endpoint +
          `${this.parseEndpoint(endpoint, options)}?page=${
            page || environment.pagination.startPage
          }&limit=${limit || environment.pagination.perPage}${
            this.parseQueryObject(query) || ''
          }`,
        this.httpOptions,
      )
      .map((data: HttpRequestResponse) => {
        this.loading.emit(false);
        this.totalPages.emit(
          data.meta && data.meta.totalPages
            ? data.meta.totalPages
            : Math.ceil(
                (data.meta && data.meta.totalResults
                  ? data.meta.totalResults
                  : data.data.length) /
                  (limit || environment.pagination.perPage),
              ),
        );
        this.totalResults.emit(
          data.meta && data.meta.totalResults
            ? data.meta.totalResults
            : data.data.length,
        );
        this.currentPage.emit(
          data.meta && data.meta.currentPage ? data.meta.currentPage : page,
        );

        return data.data;
      });
  }

  protected findById(
    endpoint: string,
    id: string,
    options?: HttpOptions,
    query?: any,
  ): Observable<any> {
    this.loading.emit(true);

    return this.http
      .get<HttpRequestResponse>(
        environment.endpoint +
          `${this.parseEndpoint(endpoint, options)}/${id}?f=1${
            this.parseQueryObject(query) || ''
          }`,
        this.httpOptions,
      )
      .map((data: HttpRequestResponse) => {
        this.loading.emit(false);
        return data.data;
      });
  }

  protected create(
    endpoint: string,
    item: any,
    options?: HttpOptions,
  ): Observable<any> {
    this.loading.emit(true);

    return this.http
      .post<HttpRequestResponse>(
        environment.endpoint + `${this.parseEndpoint(endpoint, options)}`,
        item,
        this.httpOptions,
      )
      .map((data: HttpRequestResponse) => {
        return data.data;
      });
  }

  protected update(
    endpoint: string,
    id: string,
    item: any,
    options?: HttpOptions,
  ): Observable<any> {
    this.loading.emit(true);

    return this.http
      .put<HttpRequestResponse>(
        environment.endpoint + `${this.parseEndpoint(endpoint, options)}/${id}`,
        item,
        this.httpOptions,
      )
      .map((data: HttpRequestResponse) => {
        return data.data;
      });
  }

  protected delete(
    endpoint: string,
    id: string,
    options?: HttpOptions,
  ): Observable<any> {
    this.loading.emit(true);

    return this.http
      .delete<HttpRequestResponse>(
        environment.endpoint + `${this.parseEndpoint(endpoint, options)}/${id}`,
        this.httpOptions,
      )
      .map((data: HttpRequestResponse) => {
        this.loading.emit(false);

        return data.data;
      });
  }

  public parseQueryObject(query: any): string {
    if (!query || Object.keys(query).length === 0) {
      return undefined;
    }

    return (
      '&' +
      Object.keys(query)
        .map((queryKey) => `${queryKey}=${query[queryKey]}`)
        .join('&')
    );
  }

  private parseEndpoint(endpoint: string, options?: HttpOptions): string {
    if (!options || !options.parameters) {
      return endpoint;
    }

    Object.keys(options.parameters).forEach((key) => {
      endpoint = endpoint.replace(':' + key, options.parameters[key]);
    });

    return endpoint;
  }
}
