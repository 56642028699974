<div class="row" *ngIf="ableToSend()">
  <div class="col-md-12 text-right">
    <a [href]="download('watch')" class="btn btn-xs btn-primary btn-disabled"
      >Send Watch XML</a
    >
  </div>
</div>

<div class="alert alert-danger" *ngIf="!ableToSend()">
  Cannot send Report unless items below in red are completed.
</div>

<div *ngIf="data">
  <div class="row">
    <div class="col-md-12">
      <h3>Report Preview</h3>
    </div>
  </div>
  <div class="row">
    <div class="col-md-12">
      <h4>Client Details</h4>
    </div>
  </div>

  <div class="row">
    <div
      class="col-md-3"
      [class.text-danger]="
        data.caseReference === undefined || data.caseReference === null
      "
    >
      <strong>Case Reference : </strong> {{ data.caseReference }}
    </div>
    <div
      class="col-md-3"
      [class.text-danger]="
        data.firstName === undefined || data.firstName === null
      "
    >
      <strong>First Name : </strong> {{ data.firstName }}
    </div>
    <div
      class="col-md-3"
      [class.text-danger]="
        data.lastName === undefined || data.lastName === null
      "
    >
      <strong>Last Name : </strong> {{ data.lastName }}
    </div>
    <div
      class="col-md-3"
      [class.text-danger]="
        data.dateOfBirth === undefined || data.dateOfBirth === null
      "
    >
      <strong>Date of Birth : </strong>
      {{ data.dateOfBirth | date: 'dd/MM/yyyy' }}
    </div>
    <div
      class="col-md-3"
      [class.text-danger]="
        data.postalCode === undefined || data.postalCode === null
      "
    >
      <strong>Postcode : </strong> {{ data.postalCode }}
    </div>
    <div class="col-md-3">
      <strong>Partner First Name : </strong> {{ data.partnerFirstName }}
    </div>
    <div class="col-md-3">
      <strong>Partner Last Name : </strong> {{ data.partnerLastName }}
    </div>
    <div class="col-md-3">
      <strong>Partner Date of Birth : </strong>
      {{ data.partnerDateOfBirth | date: 'dd/MM/yyyy' }}
    </div>
  </div>
</div>

<div class="row" style="margin-top: 30px">
  <div class="col-md-12">
    <h4>Meeting Outcome</h4>
  </div>
</div>

<div class="row">
  <div
    class="col-md-3"
    [class.text-danger]="
      data.dateChairmansIssued === undefined ||
      data.dateChairmansIssued === null
    "
  >
    <strong>Chairman Issued : </strong>
    {{ data.dateChairmansIssued | date: 'dd/MM/yyyy' }}
  </div>
  <div class="col-md-3">
    <strong>Adjourned MOC : </strong>
    {{ data.adjournedMocDate | date: 'dd/MM/yyyy' }}
  </div>
  <div
    class="col-md-3"
    [class.text-danger]="
      data.meetingOutcome === undefined || data.meetingOutcome === null
    "
  >
    <strong>Meeting Status : </strong> {{ data.meetingOutcome }}
  </div>
  <div
    class="col-md-3"
    [class.text-danger]="
      data.acceptedPercentage === undefined || data.acceptedPercentage === null
    "
  >
    <strong>Accepted Percentage : </strong> {{ data.acceptedPercentage }}%
  </div>
</div>

<div class="row" style="margin-top: 30px">
  <div class="col-md-12">
    <h4>Financial Summary</h4>
  </div>
</div>

<div class="row">
  <div
    class="col-md-3"
    [class.text-danger]="data.nomsFee === undefined || data.nomsFee === null"
  >
    <strong>Noms Fee : </strong>
    {{ data.nomsFee | toCurrency }}
  </div>
  <div
    class="col-md-3"
    [class.text-danger]="data.supsFee === undefined || data.supsFee === null"
  >
    <strong>Sups Fee : </strong>
    {{ data.supsFee | toCurrency }}
  </div>
  <div
    class="col-md-3"
    [class.text-danger]="
      data.disbursements === undefined || data.disbursements === null
    "
  >
    <strong>Disbursements : </strong>
    {{ data.disbursements | toCurrency }}
  </div>
  <div
    class="col-md-3"
    [class.text-danger]="data.dividend === undefined || data.dividend === null"
  >
    <strong>Dividend : </strong>
    {{ data.dividend }}p/£
  </div>
  <div
    class="col-md-3"
    [class.text-danger]="data.assets === undefined || data.assets === null"
  >
    <strong>Assets : </strong>
    {{ data.assets | toCurrency }}
  </div>
  <div
    class="col-md-3"
    [class.text-danger]="
      data.liabilities === undefined || data.liabilities === null
    "
  >
    <strong>Liabilities : </strong>
    {{ data.liabilities | toCurrency }}
  </div>
  <div
    class="col-md-3"
    [class.text-danger]="
      data.unsecuredLiabilities === undefined ||
      data.unsecuredLiabilities === null
    "
  >
    <strong>Unsecured Liabilities : </strong>
    {{ data.unsecuredLiabilities | toCurrency }}
  </div>
</div>

<div class="row" style="margin-top: 30px">
  <div
    class="col-md-12"
    [class.text-danger]="
      data.creditors === undefined || data.creditors === null
    "
  >
    <h4>Creditor Summary</h4>
  </div>
</div>

<div class="row">
  <div class="col-md-3"><strong>Name</strong></div>
  <div class="col-md-3"><strong>Reference</strong></div>
  <div class="col-md-3"><strong>Original Amount</strong></div>
  <div class="col-md-3"><strong>Admitted Amount</strong></div>
</div>

<div class="row" *ngFor="let creditor of data.creditors">
  <div class="col-md-3">{{ creditor.name }}</div>
  <div class="col-md-3">{{ creditor.reference }}</div>
  <div class="col-md-3">{{ creditor.originalAmount | toCurrency }}</div>
  <div class="col-md-3">{{ creditor.admittedAmount | toCurrency }}</div>
</div>

<div class="row" style="margin-top: 30px">
  <div
    class="col-md-12"
    [class.text-danger]="
      data.contributions === undefined || data.contributions === null
    "
  >
    <h4>Contribution Summary</h4>
  </div>
</div>

<div class="row">
  <div class="col-md-3"><strong>Expected Date</strong></div>
  <div class="col-md-3"><strong>Value</strong></div>
</div>

<div class="row" *ngFor="let contribution of data.contributions">
  <div class="col-md-3">{{ contribution.startDate | date: 'dd/MM/yyyy' }}</div>
  <div class="col-md-3">{{ contribution.value | toCurrency }}</div>
</div>
