<div class="row">
  <div class="col-sm-12">
    <section class="panel">
      <app-page-title title="Create Task" [loading]="isLoading"></app-page-title>

      <div class="panel-body">
        <form class="form-horizontal" (ngSubmit)="create()" ngNativeValidate>
          <app-task-form [task]="task" *ngIf="task"></app-task-form>
        </form>
      </div>
    </section>
  </div>
</div>


