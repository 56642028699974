import {Component} from '@angular/core';
import {CreditorStatusService} from '../creditor-status.service';
import {CreditorStatus} from '../creditor-status';
import {ActivatedRoute, Router} from '@angular/router';
import {NotificationService} from '../../shared/notification.service';

@Component({
    selector: 'app-creditor-status-edit',
    templateUrl: 'edit.component.html',
})
export class CreditorStatusEditComponent {
    /**
     * Details of the status being edited.
     */
    public status: CreditorStatus;

    /**
     * Are we currently performing a loading action?
     *
     * @type {boolean}
     */
    public isLoading: boolean = false;

    /**
     * Load up all our required services and subscribe to every observable we need.
     *
     * @param {CreditorStatusService} statusService
     * @param {NotificationService} notificationService
     * @param {Router} router
     * @param {ActivatedRoute} route
     */
    constructor(
        private statusService: CreditorStatusService,
        private notificationService: NotificationService,
        private router: Router,
        private route: ActivatedRoute,
    ) {
        this.statusService.loading.subscribe(result => (this.isLoading = result));
        this.route.params.subscribe(params => this.loadStatus(params.id));
    }

    /**
     * Perform the edit action which send the edited status to the backend.
     */
    public edit() {
        const editStatus = this.statusService
            .updateStatus(this.status.id, this.status)
            .subscribe(
                (result: CreditorStatus) => {
                    this.notificationService.success(
                        `Status Updated`,
                        `The status ${
                            result.name
                        } been updated with the specified changes.`,
                        5,
                    );
                    this.status = new CreditorStatus();
                    editStatus.unsubscribe();
                    this.router.navigate(['/creditor-statuses']);
                },
                err => {
                    this.notificationService.error(
                        `Status Not Found`,
                        err.error.message,
                        10,
                    );
                    this.router.navigate(['/creditor-statuses']);
                },
            );
    }

    /**
     * Loads the requested status so we can display it and let the user edit the fields.
     *
     * @param {string} id
     */
    private loadStatus(id: string) {
        this.statusService.findStatus(id).subscribe(
            result => {
                this.status = result;
            },
            err => {
                this.notificationService.error(
                    `Status Not Found`,
                    err.error.message,
                    10,
                );
                this.router.navigate(['/creditor-statuses']);
            },
        );
    }
}
