import { RouterModule, Routes } from '@angular/router';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule } from '@angular/common/http';
import { CreditorListComponent } from './list/list.component';
import { AuthGuard } from '../auth/auth.guard';
import { CreditorService } from './creditor.service';
import { SharedModule } from '../shared/shared.module';
import { CreditorCreateComponent } from './create/create.component';
import { CreditorFormComponent } from './form/form.component';
import { CreditorEditComponent } from './edit/edit.component';

const routes: Routes = [
  {
    path: 'creditors',
    component: CreditorListComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'creditors/create',
    component: CreditorCreateComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'creditors/:id/edit',
    component: CreditorEditComponent,
    canActivate: [AuthGuard],
  },
];

@NgModule({
  declarations: [
    CreditorListComponent,
    CreditorCreateComponent,
    CreditorFormComponent,
    CreditorEditComponent,
  ],
  imports: [
    BrowserModule,
    RouterModule,
    FormsModule,
    HttpClientModule,
    SharedModule,
    RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' }),
  ],
  exports: [RouterModule],
  providers: [AuthGuard, CreditorService],
})
export class CreditorRoutingModule {}
