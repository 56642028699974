import {Component, EventEmitter, Input, OnInit} from '@angular/core';
import {NotificationService} from '../../../shared/notification.service';
import {CaseDocument} from '../case-document';
import {CaseDocumentService} from '../case-document.service';
import {FileUploader} from 'ng2-file-upload';
import {environment} from '../../../../environments/environment';
import {AuthService} from '../../../auth/auth.service';

@Component({
    selector: 'app-case-document-list',
    templateUrl: 'list.component.html',
    styleUrls: ['list.component.css'],
})
export class CaseDocumentListComponent implements OnInit {
    @Input() caseRefreshed: EventEmitter<string>;

    @Input() caseId: string;

    public documentsInFolders = [];

    public openFolders = [];

    public uploader: FileUploader;

    public caseDocuments: CaseDocument[] = [];

    public isLoading: boolean = true;

    public totalPages: number = 1;

    public currentPage: number = 1;

    public totalResults: number = 0;

    public documentCreated: EventEmitter<boolean> = new EventEmitter<boolean>();

    public itemDeleted: EventEmitter<boolean> = new EventEmitter<boolean>();

    public newDocument: CaseDocument = new CaseDocument();

    public loading: boolean = false;

    public editorOptions = {
        placeholder: 'Enter your document...',
        modules: {
            toolbar: ['bold', 'italic', 'underline', 'strike', 'link'],
        },
    };

    constructor(
        private caseDocumentService: CaseDocumentService,
        private notificationService: NotificationService,
        private readonly authService: AuthService,
    ) {
        this.caseDocumentService.loading.subscribe(isLoading => {
            this.isLoading = isLoading;
        });
        this.caseDocumentService.totalPages.subscribe(totalPages => {
            this.totalPages = totalPages;
        });
        this.caseDocumentService.currentPage.subscribe(currentPage => {
            this.currentPage = currentPage;
        });
        this.caseDocumentService.totalResults.subscribe(totalResults => {
            this.totalResults = totalResults;
        });
        this.uploader = new FileUploader({
            url: `${environment.endpoint}uploads`,
            authToken: `Bearer ${this.authService.getToken()}`,
        });
    }

    public ngOnInit(): void {
        this.onPageChanged(1);
        this.caseRefreshed.subscribe(id => {
            this.caseId = id;
            this.onPageChanged(1);
        });
    }

    public toggleFolderOpen(folderName: string): void {
        if (this.folderOpen(folderName)) {
            this.openFolders.splice(this.openFolders.indexOf(folderName), 1);
        } else {
            this.openFolders.push(folderName);
        }
    }

    public folderOpen(folderName: string): boolean {
        return this.openFolders.includes(folderName);
    }

    public onPageChanged(pageNumber: number): void {
        this.loadCaseDocuments(pageNumber, 1000);
    }

    public loadCaseDocuments(page?: number, limit?: number): void {
        this.loading = true;
        const listCountries = this.caseDocumentService
            .listCaseDocuments(page, limit, {parameters: {id: this.caseId}})
            .subscribe((caseDocuments: CaseDocument[]) => {
                const folders = [...new Set(caseDocuments.map(cd => cd.document && cd.document.folder ? cd.document.folder.name : 'Other Documents'))];
                this.documentsInFolders = folders.filter((v) => !Object.keys(this.documentsInFolders).includes(v)).sort((a, b) => (a > b ? 1 : -1)).map(folder => {
                    return {
                        name: folder,
                        documents: caseDocuments.filter(cd => {
                            if (folder === 'Other Documents' && (!cd.document || !cd.document.folder)) {
                                return true;
                            } else if (!cd.document || !cd.document.folder) {
                                return false;
                            } else {
                                return cd.document.folder.name === folder;
                            }
                        })
                    };
                });
                this.loading = false;
                listCountries.unsubscribe();
            });
    }

    public create(formData: CaseDocument) {
        this.caseDocumentService
            .createCaseDocument(this.newDocument, {parameters: {id: this.caseId}})
            .subscribe(results => {
                this.documentCreated.next(true);
                this.onPageChanged(1);
            });
        console.log('creating', this.newDocument);
    }

    public delete(id: string) {
        this.caseDocumentService.deleteCaseDocument(id).subscribe(
            deleted => {
                this.notificationService.success(
                    `Document Deleted`,
                    `Document with ID ${id} was deleted.`,
                    5,
                );
                this.itemDeleted.next(true);
                this.onPageChanged(this.currentPage);
            },
            err => {
                this.notificationService.error(
                    `Document Not Deleted`,
                    err.error.message,
                    10,
                );
                this.itemDeleted.next(false);
                this.onPageChanged(this.currentPage);
            },
        );
    }
}
