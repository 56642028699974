<nav aria-label="Page navigation">
  <ul class="pagination">
    <li *ngIf="totalPages > maximumPages" class="hidden-xs">
      <a href="javascript:;" (click)="changePage(1)" aria-label="Previous" class="btn btn-xs"
         [class.disabled]="currentPage === 1">
        <i class="fa fa-backward" aria-hidden="true"></i>
      </a>
    </li>
    <li *ngIf="totalPages > 1 || totalPages === 0">
      <a href="javascript:;" (click)="previousPage()" aria-label="Previous" class="btn btn-xs"
         [class.disabled]="currentPage === 1">
        <i class="fa fa-caret-left" aria-hidden="true"></i>
      </a>
    </li>
    <li *ngFor="let page of createPageNumberArray()" [class.active]="currentPage == page"><a href="javascript:;"
                                                                                             (click)="changePage(page)"
                                                                                             class="btn btn-xs"
                                                                                             style="min-width: 40px;">{{
      page }}</a></li>
    <li *ngIf="totalPages > 1 || totalPages === 0">
      <a href="javascript:;" (click)="nextPage()" aria-label="Next" class="btn btn-xs"
         [class.disabled]="currentPage >= totalPages && totalPages !== 0">
        <i class="fa fa-caret-right" aria-hidden="true"></i>
      </a>
    </li>
    <li *ngIf="totalPages > maximumPages" class="hidden-xs">
      <a href="javascript:;" (click)="changePage(totalPages)" aria-label="Previous" class="btn btn-xs"
         [class.disabled]="currentPage >= totalPages">
        <i class="fa fa-forward" aria-hidden="true"></i>
      </a>
    </li>
    <li *ngIf="totalPages >= 20" class="hidden-xs">
      <a href="javascript:;" class="btn btn-xs" [popover]="pickPagePopover"
         placement="top"
         container="body"
         [outsideClick]="true">
        <i class="fa fa-asterisk" aria-hidden="true"></i>
      </a>
    </li>
  </ul>
</nav>

<ng-template #pickPagePopover>
  <div class="row">
    <div class="col-md-12">
      <input type="number" class="form-control" [(ngModel)]="skipToPage">
    </div>
  </div>
  <div class="row" style="margin-top: 10px;">
    <div class="col-md-12">
      <button type="button" class="btn btn-block btn-success" (click)="changePage(skipToPage)">Skip To Page
      </button>
    </div>
  </div>
</ng-template>
