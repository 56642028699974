import { Component } from '@angular/core';
import { AccountGroupService } from '../account-group.service';
import { AccountGroup } from '../account-group';
import { ActivatedRoute, Router } from '@angular/router';
import { NotificationService } from '../../../shared/notification.service';

@Component({
  selector: 'app-account-group-edit',
  templateUrl: 'edit.component.html',
})
export class AccountGroupEditComponent {
  /**
   * Details of the accountGroup being edited.
   */
  public accountGroup: AccountGroup;

  /**
   * Are we currently performing a loading action?
   *
   * @type {boolean}
   */
  public isLoading: boolean = false;

  /**
   * Load up all our required services and subscribe to every observable we need.
   *
   * @param {AccountGroupService} accountGroupService
   * @param {NotificationService} notificationService
   * @param {Router} router
   * @param {ActivatedRoute} route
   */
  constructor(
    private accountGroupService: AccountGroupService,
    private notificationService: NotificationService,
    private router: Router,
    private route: ActivatedRoute,
  ) {
    this.accountGroupService.loading.subscribe(
      result => (this.isLoading = result),
    );
    this.route.params.subscribe(params => this.loadAccountGroup(params.id));
  }

  /**
   * Perform the edit action which send the edited accountGroup to the backend.
   */
  public edit() {
    const editAccountGroup = this.accountGroupService
      .updateAccountGroup(this.accountGroup.id, this.accountGroup)
      .subscribe(
        (result: AccountGroup) => {
          this.notificationService.success(
            `Account Group Updated`,
            `The account Group ${
              result.name
            } been updated with the specified changes.`,
            5,
          );
          this.accountGroup = new AccountGroup();
          editAccountGroup.unsubscribe();
          this.router.navigate(['/account-groups']);
        },
        err => {
          this.notificationService.error(
            `Account Group Not Found`,
            err.error.message,
            10,
          );
          this.router.navigate(['/account-groups']);
        },
      );
  }

  /**
   * Loads the requested accountGroup so we can display it and let the user edit the fields.
   *
   * @param {string} id
   */
  private loadAccountGroup(id: string) {
    this.accountGroupService.findAccountGroup(id).subscribe(
      result => {
        this.accountGroup = result;
      },
      err => {
        this.notificationService.error(
          `Account Group Not Found`,
          err.error.message,
          10,
        );
        this.router.navigate(['/account-groups']);
      },
    );
  }
}
