<div *ngIf="isLoading">Loading Complaint Status List...</div>

<select
  *ngIf="!isLoading"
  [compareWith]="byId"
  class="form-control"
  name="complaintStatus"
  [(ngModel)]="model.complaintStatus"
  [required]="required"
>
  <option
    *ngFor="let singleComplaintStatus of complaintStatuses"
    [ngValue]="singleComplaintStatus"
    >{{ singleComplaintStatus.name }}</option
  >
</select>
