import { Component, EventEmitter, OnInit } from '@angular/core';
import { BreachTypeService } from '../breach-type.service';
import { BreachType } from '../breach-type';
import { NotificationService } from '../../../shared/notification.service';

@Component({
  selector: 'app-breach-type-list',
  templateUrl: 'list.component.html',
  styleUrls: [],
})
export class BreachTypeListComponent implements OnInit {
  public breachType: BreachType;

  /**
   * A list of all the breach-types currently loaded.
   *
   * @type {BreachType[]}
   */
  public breachTypes: BreachType[] = [];

  /**
   * Are we currently performing a loading action?
   *
   * @type {boolean}
   */
  public isLoading: boolean = true;

  /**
   * The total number of pages in this list of breach-types.
   *
   * @type {number}
   */
  public totalPages: number = 1;

  /**
   * The current page of breach-types that we are viewing.
   *
   * @type {number}
   */
  public currentPage: number = 1;

  /**
   * The total number of breach-types that can be viewed in the system.
   *
   * @type {number}
   */
  public totalResults: number = 0;

  /**
   * An Emitter to alert other components when a breachType has been deleted.
   *
   * @type {EventEmitter<boolean>}
   */
  public breachTypeDeleted: EventEmitter<boolean> = new EventEmitter<
    boolean
  >();

  /**
   * Load up all our required services and subscribe to every observable we need.
   *
   * @param {BreachTypeService} breachTypeService
   * @param {NotificationService} notificationService
   */
  constructor(
    private breachTypeService: BreachTypeService,
    private notificationService: NotificationService,
  ) {
    this.breachTypeService.loading.subscribe(isLoading => {
      this.isLoading = isLoading;
    });
    this.breachTypeService.totalPages.subscribe(totalPages => {
      this.totalPages = totalPages;
    });
    this.breachTypeService.currentPage.subscribe(currentPage => {
      this.currentPage = currentPage;
    });
    this.breachTypeService.totalResults.subscribe(totalResults => {
      this.totalResults = totalResults;
    });
  }

  /**
   * When the component is initialised we set it to load
   * page one of the breachType list.
   */
  public ngOnInit(): void {
    this.loadBreachTypes(1);
  }

  /**
   * When we receive a page change from the pagination
   * component we load the appropriate page for the
   * breachType list.
   *
   * @param {number} pageNumber
   */
  public onPageChanged(pageNumber: number): void {
    this.loadBreachTypes(pageNumber);
  }

  /**
   * Loads a list of breach-types using the given per page limit.
   *
   * @param {number} page The current page we require.
   * @param {number} limit The number of results per page.
   */
  public loadBreachTypes(page?: number, limit?: number): void {
    const listBreachTypes = this.breachTypeService
      .listBreachTypes(page, limit)
      .subscribe((breachTypes: BreachType[]) => {
        this.breachTypes = breachTypes;
        listBreachTypes.unsubscribe();
      });
  }

  /**
   * When receiving confirmation of an item being deleted
   * we call the back end with the appropriate breachType ID
   * to delete the breachType.
   *
   * @param {string} id
   */
  public delete(id: string) {
    this.breachTypeService.deleteBreachType(id).subscribe(
      deleted => {
        this.notificationService.success(
          `BreachType Deleted`,
          `BreachType with ID ${id} was deleted.`,
          5,
        );
        this.breachTypeDeleted.next(true);
        this.onPageChanged(this.currentPage);
      },
      err => {
        this.notificationService.error(
          `BreachType Not Deleted`,
          err.error.message,
          10,
        );
        this.breachTypeDeleted.next(false);
        this.onPageChanged(this.currentPage);
      },
    );
  }
}
