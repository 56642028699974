import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NotificationService } from '../../../shared/notification.service';
import { CaseIncome } from '../case-income';
import { CaseIncomeService } from '../case-income.service';
import { AuthService } from '../../../auth/auth.service';

@Component({
  selector: 'app-case-income-list',
  templateUrl: 'list.component.html',
  styleUrls: ['list.component.css'],
})
export class CaseIncomeListComponent implements OnInit {
  @Input() caseRefreshed: EventEmitter<string>;

  @Input() caseId: string;

  public caseIncomes: CaseIncome[] = [];

  public isLoading: boolean = true;

  public totalPages: number = 1;

  public currentPage: number = 1;

  public totalResults: number = 0;
  public itemDeleted: EventEmitter<boolean> = new EventEmitter<boolean>();
  public incomeCreated: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output()
  public incomeUpdated: EventEmitter<number> = new EventEmitter<number>();
  public newIncome: CaseIncome = new CaseIncome();
  public editorOptions = {
    placeholder: 'Enter your note...',
    modules: {
      toolbar: ['bold', 'link'],
    },
  };
  private limit: number = 1000;

  constructor(
    private caseIncomeService: CaseIncomeService,
    private notificationService: NotificationService,
    public readonly authService: AuthService,
  ) {
    this.caseIncomeService.loading.subscribe(isLoading => {
      this.isLoading = isLoading;
    });
    this.caseIncomeService.totalPages.subscribe(totalPages => {
      this.totalPages = totalPages;
    });
    this.caseIncomeService.currentPage.subscribe(currentPage => {
      this.currentPage = currentPage;
    });
    this.caseIncomeService.totalResults.subscribe(totalResults => {
      this.totalResults = totalResults;
    });
  }

  private get totalIncome(): number {
    let totalIncome = 0;

    this.caseIncomes.forEach(incomeItem => {
      totalIncome = totalIncome + incomeItem.value;
    });

    return totalIncome / 100;
  }

  public ngOnInit(): void {
    this.loadCaseIncomes(1, this.limit);
    this.caseRefreshed.subscribe(id => {
      this.caseId = id;
      this.onPageChanged(1);
    });
  }

  public onPageChanged(pageNumber: number): void {
    this.loadCaseIncomes(pageNumber, this.limit);
  }

  public loadCaseIncomes(page?: number, limit?: number): void {
    const listCountries = this.caseIncomeService
      .listCaseIncomes(page, limit, { parameters: { id: this.caseId } })
      .subscribe((caseIncomes: CaseIncome[]) => {
        this.caseIncomes = caseIncomes;
        this.incomeUpdated.next(this.totalIncome);
        listCountries.unsubscribe();
      });
  }

  public create(formData: CaseIncome) {
    this.caseIncomeService
      .createCaseIncome(this.newIncome, { parameters: { id: this.caseId } })
      .subscribe(results => {
        this.incomeCreated.next(true);
        this.onPageChanged(1);
      });
    console.log('creating', this.newIncome);
  }

  public delete(id: string) {
    this.caseIncomeService.deleteCaseIncome(id).subscribe(
      deleted => {
        this.notificationService.success(
          `Asset Deleted`,
          `Vehicle with ID ${id} was deleted.`,
          5,
        );
        this.itemDeleted.next(true);
        this.onPageChanged(this.currentPage);
      },
      err => {
        this.notificationService.error(
          `Asset Not Deleted`,
          err.error.message,
          10,
        );
        this.itemDeleted.next(false);
        this.onPageChanged(this.currentPage);
      },
    );
  }
}
