import { Injectable } from '@angular/core';
import { Action, AnyAction } from 'redux';
import { UserProfile } from '../../users/user-profile';

export enum UserActionTypes {
  TOGGLE_MENU_BAR = 'TOGGLE_MENU_BAR',
  SELECT_MENU_ITEM = 'SELECT_MENU_ITEM',
  SET_JWT_TOKEN = 'SET_JWT_TOKEN',
  REMOVE_JWT_TOKEN = 'REMOVE_JWT_TOKEN',
  SET_PROFILE = 'SET_PROFILE',
  REMOVE_PROFILE = 'REMOVE_PROFILE',
  BROWSER_ONLINE = 'SET_BROWSER_ONLINE',
  BROWSER_OFFLINE = 'SET_BROWSER_OFFLINE',
}

@Injectable()
export class UserActions {
    toggleMenubar(): Action {
      return { type: UserActionTypes.TOGGLE_MENU_BAR };
    }

    selectMenuItem(menuItem: string): AnyAction {
      return { type: UserActionTypes.SELECT_MENU_ITEM, payload: menuItem };
    }

  setJwtToken(payload: string): AnyAction {
    return { type: UserActionTypes.SET_JWT_TOKEN, payload };
  }

  removeJwtToken(): AnyAction {
    return { type: UserActionTypes.REMOVE_JWT_TOKEN };
    }

  setProfile(profile: UserProfile): AnyAction {
    return { type: UserActionTypes.SET_PROFILE, payload: profile };
    }

  removeProfile(): Action {
    return { type: UserActionTypes.REMOVE_PROFILE };
    }
}
