import * as moment from 'moment';

import { Component, EventEmitter, Input, OnInit } from '@angular/core';

import { AuthService } from '../../../auth/auth.service';
import { CaseCalendar } from '../case-calendar';
import { CaseCalendarService } from '../case-calendar.service';
import { Department } from '../../../settings/departments/department';
import { NotificationService } from '../../../shared/notification.service';
import { environment } from '../../../../environments/environment';

@Component({
  selector: 'app-case-calendar-list',
  templateUrl: 'list.component.html',
  styleUrls: ['list.component.scss'],
})
export class CaseCalendarListComponent implements OnInit {
  @Input() caseRefreshed: EventEmitter<string>;

  @Input() caseId: string;

  @Input() actionableOnly: boolean;

  @Input() statusesOnly: boolean;

  public caseCalendars: CaseCalendar[] = [];

  public isLoading: boolean = true;

  public totalPages: number = 1;

  public currentPage: number = 1;

  public totalResults: number = 0;

  public itemDeleted: EventEmitter<boolean> = new EventEmitter<boolean>();

  public calendarCreated: EventEmitter<boolean> = new EventEmitter<boolean>();

  public newCalendar: CaseCalendar = new CaseCalendar();

  public editorOptions = {
    placeholder: 'Enter your note...',
    modules: {
      toolbar: ['bold', 'link'],
    },
  };

  public filter?: {
    department?: Department;
  } = {
    department: undefined,
  };

  constructor(
    private caseCalendarService: CaseCalendarService,
    private notificationService: NotificationService,
    public readonly authService: AuthService,
  ) {
    this.caseCalendarService.loading.subscribe((isLoading) => {
      this.isLoading = isLoading;
    });
    this.caseCalendarService.totalPages.subscribe((totalPages) => {
      this.totalPages = totalPages;
    });
    this.caseCalendarService.currentPage.subscribe((currentPage) => {
      this.currentPage = currentPage;
    });
    this.caseCalendarService.totalResults.subscribe((totalResults) => {
      this.totalResults = totalResults;
    });
    this.newCalendar.toAction = true;
    this.newCalendar.icon = 'tasks';
  }

  public get downloadUrl() {
    return `${environment.apiUrl}cases/${this.caseId}/calendar?download=true`;
  }

  public ngOnInit(): void {
    this.loadCaseCalendars(1);
    const subscription = this.caseRefreshed.subscribe((id) => {
      this.caseId = id;
      this.onPageChanged(1);
    });
  }

  public onPageChanged(pageNumber: number): void {
    this.loadCaseCalendars(pageNumber);
  }

  public toggleActionable() {
    this.actionableOnly = !this.actionableOnly ? true : false;
    if (this.statusesOnly === true) {
      this.statusesOnly = false;
    }

    this.onPageChanged(1);
  }

  public toggleStatuses() {
    this.statusesOnly = !this.statusesOnly ? true : false;
    if (this.actionableOnly === true) {
      this.actionableOnly = false;
    }
    this.onPageChanged(1);
  }

  public resetFilters() {
    this.statusesOnly = false;
    this.actionableOnly = false;
    this.filter.department = undefined;
    this.loadCaseCalendars(1);
  }

  public loadCaseCalendars(page?: number, limit?: number): void {
    const filter = {
      actionable: !this.actionableOnly ? false : true,
      statuses: !this.statusesOnly ? false : true,
    };
    if (this.filter.department) {
      filter['departmentId'] = this.filter.department?.id;
    }
    const listCountries = this.caseCalendarService
      .listCaseCalendars(
        page,
        limit,
        { parameters: { id: this.caseId } },
        filter,
      )
      .subscribe((caseCalendars: CaseCalendar[]) => {
        this.caseCalendars = caseCalendars;
        listCountries.unsubscribe();
      });
  }

  public create(formData: CaseCalendar) {
    const subscription = this.caseCalendarService
      .createCaseCalendar(this.newCalendar, { parameters: { id: this.caseId } })
      .subscribe((results) => {
        this.calendarCreated.next(true);
        this.onPageChanged(1);
        subscription.unsubscribe();
      });
  }

  public taskOverdue(dueDate): boolean {
    const dueMoment = moment(dueDate);

    return dueMoment.isBefore(moment());
  }

  public markCompleted(id: string) {
    const subscription = this.caseCalendarService
      .completeTaskForUser(this.authService.userId, id)
      .subscribe(() => {
        this.notificationService.success(
          `Task Completed`,
          `Task with ID ${id} has been marked as completed.`,
          5,
        );
        this.onPageChanged(this.currentPage);
        subscription.unsubscribe();
      });
  }

  public delete(id: string) {
    const subscription = this.caseCalendarService
      .deleteCaseCalendar(id)
      .subscribe(
        (deleted) => {
          this.notificationService.success(
            `Task Deleted`,
            `Task with ID ${id} was deleted.`,
            5,
          );
          this.itemDeleted.next(true);
          this.onPageChanged(this.currentPage);
          subscription.unsubscribe();
        },
        (err) => {
          this.notificationService.error(
            `Task Not Deleted`,
            err.error.message,
            10,
          );
          this.itemDeleted.next(false);
          this.onPageChanged(this.currentPage);
          subscription.unsubscribe();
        },
      );
  }
}
