import { Component, EventEmitter, Input, OnInit } from '@angular/core';
import { ExpenditureCategoryService } from '../../expenditure-categories/expenditure-category.service';
import { ExpenditureCategory } from '../../expenditure-categories/expenditure-category';

@Component({
  selector: 'app-case-expenditure-form',
  templateUrl: 'case-expenditure-form.component.html',
})
export class CaseExpenditureFormComponent implements OnInit {
  @Input() caseRefreshed: EventEmitter<string>;

  saveAllClicked: EventEmitter<boolean> = new EventEmitter<boolean>(false);

  @Input() public caseId: string = undefined;

  private categoryList: ExpenditureCategory[] = [];

  constructor(private readonly service: ExpenditureCategoryService) {}

  public saveAll() {
    this.saveAllClicked.next(true);
  }

  public get categories(): ExpenditureCategory[] {
    return this.categoryList;
  }

  public ngOnInit(): void {
    this.loadCategories();
    this.caseRefreshed.subscribe(id => {
      this.caseId = id;
      this.loadCategories();
    });
  }

  private loadCategories() {
    this.service
      .listExpenditureCategories(1, 1000)
      .subscribe((categories: ExpenditureCategory[]) => {
        this.categoryList = categories;
      });
  }
}
