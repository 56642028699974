import { Component, Input } from '@angular/core';
import { IncomeCategory } from '../income-category';

@Component({
  selector: 'app-income-category-form',
  templateUrl: 'form.component.html',
})
export class IncomeCategoryFormComponent {
  @Input() incomeCategory: IncomeCategory;
}
