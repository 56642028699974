<div class="clearfix">
  <div class="input-group" *ngIf="hasDate">
    <input
      type="number"
      min="1"
      [max]="dayCheck(chosenDate.month)"
      class="input-number–noSpinners shared-date-input form-control"
      name="day"
      id="day"
      value="{{ chosenDate?.day }}"
      (change)="changeDateItem('day', $event)"
      [required]="isRequired()"
    />
    <span class="input-group-addon input-group-addon-small">/</span>
    <input
      type="number"
      min="1"
      max="12"
      class="input-number–noSpinners shared-date-input form-control"
      name="month"
      id="month"
      value="{{ chosenDate?.month }}"
      (change)="changeDateItem('month', $event)"
      [required]="isRequired()"
    />
    <span class="input-group-addon input-group-addon-small">/</span>
    <input
      type="number"
      [min]="minYearCheck()"
      [max]="maxYearCheck()"
      class="input-number–noSpinners shared-date-input form-control"
      name="year"
      id="year"
      value="{{ chosenDate?.year }}"
      (change)="changeDateItem('year', $event)"
      [required]="isRequired()"
    />
  </div>
</div>

<div class="clearfix" style="margin-top: 14px">
  <div class="input-group" *ngIf="hasTime">
    <input
      type="number"
      min="0"
      [max]="23"
      class="input-number–noSpinners shared-date-input form-control"
      name="hour"
      id="hour"
      value="{{ chosenDate?.hour }}"
      (change)="changeDateItem('hour', $event)"
      [required]="isRequired()"
    />
    <span class="input-group-addon input-group-addon-small">:</span>
    <input
      type="number"
      min="0"
      max="59"
      class="input-number–noSpinners shared-date-input form-control"
      name="minute"
      id="minute"
      value="{{ chosenDate?.minute }}"
      (change)="changeDateItem('minute', $event)"
      [required]="isRequired()"
    />
  </div>
</div>
