<div class="row">
  <div class="col-sm-12">
    <section class="panel">
      <app-page-title title="List Letterheads" [loading]="isLoading">
        {{ totalResults }} Results Found
      </app-page-title>

      <div class="panel-body">
        <table class="table table-striped">
          <thead>
          <tr>
            <th>Domain</th>
            <th>Letterhead Name</th>
            <th class="text-right">
              <a [routerLink]="['/letterheads/create']" class="btn btn-success">Create</a>
            </th>
          </tr>
          </thead>

          <tbody>
          <tr *ngFor="let letterhead of letterheads">
            <td>{{ letterhead?.domain?.name || 'Global' }}</td>
            <td>{{ letterhead?.name }}</td>
            <td class="text-right">
              <div class="btn-group">
                <a [routerLink]="['/letterheads', letterhead?.id, 'edit']" type="button" class="btn btn-sm btn-warning">Edit</a>
                <button type="button" class="btn btn-sm btn-warning dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                  <span class="caret"></span>
                  <span class="sr-only">Toggle Dropdown</span>
                </button>
                <ul class="dropdown-menu">
                  <li><a href="javascript:;" (click)="deleteModal.confirmDialog(letterhead.id)">Delete</a></li>
                </ul>
              </div>
            </td>
          </tr>
          </tbody>

          <tfoot>
          <tr>
            <td colspan="99" class="text-center">
              <app-pagination [totalPages]="totalPages" [currentPage]="currentPage" (pageChanged)="onPageChanged($event)"></app-pagination>
            </td>
          </tr>
          </tfoot>
        </table>
      </div>
    </section>
  </div>
</div>

<app-delete-modal type="letterhead" (confirm)="delete($event)" [itemDeleted]="letterheadDeleted" #deleteModal></app-delete-modal>
