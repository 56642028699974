import { Component, Input } from '@angular/core';
import { ExpenditureCategory } from '../expenditure-category';

@Component({
  selector: 'app-expenditure-category-form',
  templateUrl: 'form.component.html',
})
export class ExpenditureCategoryFormComponent {
  @Input() expenditureCategory: ExpenditureCategory;
}
