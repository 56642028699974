import {HttpClient} from '@angular/common/http';
import {Component, Input, OnInit, TemplateRef} from '@angular/core';
import {AngularFireFunctions} from '@angular/fire/functions';
import {BsModalRef, BsModalService} from 'ngx-bootstrap/modal';

import {environment} from '../../../environments/environment';

@Component({
  selector: 'app-audit-chart-of-accounts',
  templateUrl: 'audit-chart-of-accounts.component.html',
})
export class AuditChartOfAccountsComponent implements OnInit {
  @Input() clientId: string;
  public auditResults: AuditSummary[];
  public viewingProfile: AuditSummary;
  protected modalRef: BsModalRef;

  constructor(
    protected fireFuncton: AngularFireFunctions,
    private modalService: BsModalService,
    private http: HttpClient,
  ) {}
  ngOnInit(): void {
    this.http
      .get<AuditSummary[]>(
        `${environment.microservices.feeComparison}cases/${this.clientId}/fee-comparison/history`,
      )
      .subscribe((r) => (this.auditResults = r));
  }

  markAsOriginal() {
    this.http
      .post<AuditSummary[]>(
        `${environment.microservices.feeComparison}cases/${this.clientId}/fee-comparison/original`,
        {
          dateTime: this.viewingProfile.createdAt,
        },
      )
      .subscribe((r) => {
        this.auditResults = r;
        this.viewingProfile = undefined;
      });
  }

  openModal(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(template, {
      class: 'modal-lg',
    });
  }
}

class AuditSummary {
  items: any[];
  createdAt: string;
  deletedAt: string;
  original: boolean;
}
