import { Injectable } from '@angular/core';
import { Gender } from './gender';
import { Observable } from 'rxjs';

import { HttpRequestService } from '../shared/http/http-request.service';

@Injectable()
export class GenderService extends HttpRequestService {
  /**
   * The endpoint that we call to get the gender information.
   *
   * @type {string}
   */
  private readonly endpoint: string = 'genders';

  /**
   * Brings back a list of all accessible genders.
   *
   * @param {number} page The page number we are currently on.
   * @param {number} limit The number of genders to display per page.
   * @returns {Observable<Gender[]>}
   */
  public listGenders(page?: number, limit?: number): Observable<Gender[]> {
    return this.list(this.endpoint, page, undefined, limit);
  }

  /**
   * Finds details of the gender from the given ID.
   *
   * @param {string} genderId The ID of the gender we require details for.
   * @returns {Observable<Gender>}
   */
  public findGender(genderId: string): Observable<Gender> {
    return this.findById(this.endpoint, genderId);
  }

  /**
   * Creates a new gender from the given Gender object.
   *
   * @param {Gender} newGender The details of the gender to be created.
   * @returns {Observable<Gender | boolean>}
   */
  public createGender(newGender: Gender): Observable<Gender | boolean> {
    return this.create(this.endpoint, newGender);
  }

  /**
   * Updates a gender with the given gender object for the given gender ID.
   *
   * @param {string} genderId The ID of the gender needing updating.
   * @param {Gender} newDetails The new gender object to update with.
   * @returns {Observable<Gender | boolean>}
   */
  public updateGender(
    genderId: string,
    newDetails: Gender,
  ): Observable<Gender | boolean> {
    return this.update(this.endpoint, genderId, newDetails);
  }

  /**
   * Deletes a gender based on the given gender ID.
   *
   * @param {string} genderId The ID of the gender needing deleting.
   * @returns {Observable<boolean>}
   */
  public deleteGender(genderId: string): Observable<boolean> {
    return this.delete(this.endpoint, genderId);
  }
}
