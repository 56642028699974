import { Injectable } from '@angular/core';
import { EmploymentStatus } from './employment-status';
import { Observable } from 'rxjs';

import { HttpRequestService } from '../shared/http/http-request.service';

@Injectable()
export class EmploymentStatusService extends HttpRequestService {
  /**
   * The endpoint that we call to get the employmentStatus information.
   *
   * @type {string}
   */
  private readonly endpoint: string = 'employment-statuses';

  /**
   * Brings back a list of all accessible employment-statuses.
   *
   * @param {number} page The page number we are currently on.
   * @param {number} limit The number of employment-statuses to display per page.
   * @returns {Observable<EmploymentStatus[]>}
   */
  public listEmploymentStatuses(
    page?: number,
    limit?: number,
  ): Observable<EmploymentStatus[]> {
    return this.list(this.endpoint, page, undefined, limit);
  }

  /**
   * Finds details of the employmentStatus from the given ID.
   *
   * @param {string} employmentStatusId The ID of the employmentStatus we require details for.
   * @returns {Observable<EmploymentStatus>}
   */
  public findEmploymentStatus(
    employmentStatusId: string,
  ): Observable<EmploymentStatus> {
    return this.findById(this.endpoint, employmentStatusId);
  }

  /**
   * Creates a new employmentStatus from the given EmploymentStatus object.
   *
   * @param {EmploymentStatus} newEmploymentStatus The details of the employmentStatus to be created.
   * @returns {Observable<EmploymentStatus | boolean>}
   */
  public createEmploymentStatus(
    newEmploymentStatus: EmploymentStatus,
  ): Observable<EmploymentStatus | boolean> {
    return this.create(this.endpoint, newEmploymentStatus);
  }

  /**
   * Updates a employmentStatus with the given employmentStatus object for the given employmentStatus ID.
   *
   * @param {string} employmentStatusId The ID of the employmentStatus needing updating.
   * @param {EmploymentStatus} newDetails The new employmentStatus object to update with.
   * @returns {Observable<EmploymentStatus | boolean>}
   */
  public updateEmploymentStatus(
    employmentStatusId: string,
    newDetails: EmploymentStatus,
  ): Observable<EmploymentStatus | boolean> {
    return this.update(this.endpoint, employmentStatusId, newDetails);
  }

  /**
   * Deletes a employmentStatus based on the given employmentStatus ID.
   *
   * @param {string} employmentStatusId The ID of the employmentStatus needing deleting.
   * @returns {Observable<boolean>}
   */
  public deleteEmploymentStatus(
    employmentStatusId: string,
  ): Observable<boolean> {
    return this.delete(this.endpoint, employmentStatusId);
  }
}
