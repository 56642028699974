<div class="form-group">
  <label for="domain" class="col-sm-2 control-label">Domain</label>
  <div class="col-sm-10">
    <app-domain-select [model]="pack" [required]="false"></app-domain-select>
  </div>
</div>

<div class="form-group">
  <label for="document" class="col-sm-2 control-label">Document Type</label>
  <div class="col-sm-10">
    <app-document-select [model]="pack" [required]="false"></app-document-select>
  </div>
</div>

<div class="form-group">
  <label for="name" class="col-sm-2 control-label">Name</label>
  <div class="col-sm-10">
    <input type="text" class="form-control" name="name" id="name" placeholder="Pack Name" [(ngModel)]="pack.name"
           required>
  </div>
</div>

<div class="row">
  <div class="col-md-12">
    <button type="button" class="btn btn-warning btn-xs" (click)="addTemplateModal.show()">Add Template</button>
  </div>
</div>

<div class="row" *ngFor="let template of pack.templates">
  <div class="col-md-12">
    {{ template.name }}
  </div>
</div>

<div class="form-group">
  <div class="col-sm-offset-2 col-sm-10 text-right">
    <button type="submit" class="btn btn-success">Save</button>
  </div>
</div>


<div class="modal fade" bsModal #addTemplateModal="bs-modal" [config]="{backdrop: 'static'}"
     tabindex="-1" role="dialog" aria-labelledby="mySmallModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-md">
    <form (submit)="addTemplate($event)" #completedForm="ngForm" ngNativeValidate>
      <div class="modal-content panel-success">
        <div class="modal-header panel-heading">
          <h4 class="modal-title pull-left">Add Template To Pack</h4>
          <button type="button" class="close pull-right" aria-label="Close" (click)="addTemplateModal.hide()">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <app-template-select [model]="addTemplateItem" [required]="true"></app-template-select>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-default" (click)="addTemplateModal.hide()">Cancel</button>
          <button type="submit" class="btn btn-success">Add</button>
        </div>
      </div>
    </form>
  </div>
</div>
