import {Component} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';

import {NotificationService} from '../../shared/notification.service';
import {Domain} from '../domain';
import {DomainService} from '../domain.service';

@Component({
  selector: 'app-domain-edit',
  templateUrl: 'edit.component.html',
})
export class DomainEditComponent {
  /**
   * Details of the domain being edited.
   */
  public domain: Domain;

  /**
   * Are we currently performing a loading action?
   *
   * @type {boolean}
   */
  public isLoading: boolean = false;

  /**
   * Load up all our required services and subscribe to every observable we need.
   *
   * @param {DomainService} domainService
   * @param {NotificationService} notificationService
   * @param {Router} router
   * @param {ActivatedRoute} route
   */
  constructor(
    private domainService: DomainService,
    private notificationService: NotificationService,
    private router: Router,
    private route: ActivatedRoute,
  ) {
    this.domainService.loading.subscribe(result => (this.isLoading = result));
    this.route.params.subscribe(params => this.loadDomain(params.id));
  }

  /**
   * Perform the edit action which send the edited domain to the backend.
   */
  public edit() {
    const editDomain = this.domainService
      .updateDomain(this.domain.id, this.domain)
      .subscribe(
        (result: Domain) => {
          this.notificationService.success(
            `Domain Updated`,
            `The domain ${
              result.name
            } been updated with the specified changes.`,
            5,
          );
          this.domain = new Domain();
          editDomain.unsubscribe();
          this.router.navigate(['/domains']);
        },
        err => {
          this.notificationService.error(
            `Domain Not Found`,
            err.error.message,
            10,
          );
          this.router.navigate(['/domains']);
        },
      );
  }

  /**
   * Loads the requested domain so we can display it and let the user edit the fields.
   *
   * @param {string} id
   */
  private loadDomain(id: string) {
    this.domainService.findDomain(id).subscribe(
      result => {
        this.domain = result;
      },
      err => {
        this.notificationService.error(
          `Domain Not Found`,
          err.error.message,
          10,
        );
        this.router.navigate(['/domains']);
      },
    );
  }
}
