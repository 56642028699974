import { Component, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';

@Component({
  selector: 'app-allocation-status',
  templateUrl: 'allocation-status.component.html',
  styleUrls: ['./allocation-status.component.css'],
})
export class AllocationStatusComponent implements OnInit {
  public matchInProgress: boolean = false;
  public percentage: number = 0;

  constructor(private db: AngularFirestore) {}

  ngOnInit(): void {
    this.db
      .doc(
        `/logs/automatic-allocation/logs/6e29b661-21f8-4649-ae80-f7fd8860d557`,
      )
      .valueChanges()
      .subscribe((r: any) => {
        if (r) {
          this.matchInProgress = r.inProgress;
          this.percentage = Math.round(
            ((r.currentClient ?? 1) / (r.totalClients ?? 10)) * 100,
          );
        } else {
          this.matchInProgress = true;
        }
      });
  }
}
