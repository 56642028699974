import {HttpClient} from '@angular/common/http';
import {Component, EventEmitter, Input, Output, ViewChild} from '@angular/core';

import {environment} from '../../../../environments/environment';
import {CreditorVote} from '../case-creditor-vote';

@Component({
  selector: 'app-case-creditor-votes-edit',
  templateUrl: 'edit.component.html',
})
export class CaseCreditorVotesEditComponent {
  @ViewChild('staticModal') staticModal;
  public disabled = false;

  @Input()
  public creditorVote: CreditorVote = undefined;
  @Output() completed = new EventEmitter<boolean>();

  constructor(public http: HttpClient) {}

  public open(): void {
    this.staticModal.show();
  }

  public save($event): void {
    $event.preventDefault();

    this.http
      .put(
        `${environment.microservices.creditorVoting}creditor-voting/update/${this.creditorVote.caseCreditorId}`,
        this.creditorVote,
      )
      .subscribe((r) => this.close());
  }

  public close() {
    this.workCompleted();
  }

  private workCompleted() {
    this.staticModal.hide();
    this.completed.next(true);
  }
}
