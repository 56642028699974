import { Component, OnInit } from '@angular/core';
import { AccountGroupService } from '../account-group.service';
import { AccountGroup } from '../account-group';
import { Router } from '@angular/router';
import { NotificationService } from '../../../shared/notification.service';

@Component({
  selector: 'app-account-group-create',
  templateUrl: 'create.component.html',
})
export class AccountGroupCreateComponent implements OnInit {
  /**
   * Details of the accountGroup being created.
   */
  public accountGroup: AccountGroup;

  /**
   * Are we currently performing a loading action?
   *
   * @type {boolean}
   */
  public isLoading: boolean = false;

  /**
   * Load up all our required services and subscribe to every observable we need.
   *
   * @param {AccountGroupService} accountGroupService
   * @param {NotificationService} notificationService
   * @param {Router} router
   */
  constructor(
    private accountGroupService: AccountGroupService,
    private notificationService: NotificationService,
    private router: Router,
  ) {
    this.accountGroupService.loading.subscribe(
      result => (this.isLoading = result),
    );
  }

  /**
   * Initialise the component, creating a new blank accountGroup if required.
   */
  public ngOnInit(): void {
    if (!this.accountGroup) {
      this.accountGroup = new AccountGroup();
    }
  }

  /**
   * Perform the create action which adds the new accountGroup into the backend.
   */
  public create() {
    const createAccountGroup = this.accountGroupService
      .createAccountGroup(this.accountGroup)
      .subscribe(
        (result: AccountGroup) => {
          this.notificationService.success(
            `AccountGroup Created`,
            `New accountGroup ${result.name} has been created with ID of ${
              result.id
            }.`,
            5,
          );
          this.accountGroup = new AccountGroup();
          createAccountGroup.unsubscribe();
          this.router.navigate(['/account-groups']);
        },
        err => {
          this.notificationService.error(
            `Could not create AccountGroup`,
            err.error.message,
            10,
          );
        },
      );
  }
}
