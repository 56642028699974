import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule } from '@angular/common/http';
import { SharedModule } from '../../shared/shared.module';
import { CaseModificationRoutingModule } from '../modifications/modification-routing.module';
import { CaseDetailedTransactionsViewComponent } from './view/view.component';

@NgModule({
  declarations: [ CaseDetailedTransactionsViewComponent ],
  imports: [
    BrowserModule,
    FormsModule,
    HttpClientModule,
    SharedModule,
    CaseModificationRoutingModule,
  ],
  exports: [ CaseDetailedTransactionsViewComponent ]
})
export class DetailedTransactionsModule {}

