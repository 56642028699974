import { Dependant } from '../shared/dependant-list/dependant';
import { Domain } from '../domains/domain';
import { Introducer } from '../introducers/introducer';
import { Person } from './people/person';
import { Product } from '../products/product';
import { Status } from '../statuses/status';
import { Substatus } from '../substatuses/substatus';
import { User } from '../users/user';

export class Case {
  public id?: string;
  public people: Person[];
  public domain: Domain;
  public introducer: Introducer;
  public product: Product;
  public status: Status;
  public substatus: Substatus;
  public user: User;
  public ppiId: string;
  public lifeId: string;
  public introducerReference: string;
  public introducerValue: number;
  public introducerPaymentDate: string;
  public introducerPaidAt: string;
  public introducerPaymentApprovedAt: string;
  public uniqueReference: string;
  public dependants: Dependant[];
  public reasonForFinancialDifficulty: string;
  public reasonForFinancialDifficultySummary: string;
  public reasonForNonStandardProtocol: string;
  public stepsTakenToResolve: string;
  public childMaintenanceAgreement: string;
  public vulnerableClient: boolean;
  public paymentScheduleCreated: boolean;
  public duration: number;
  public supervisoryPercentage: number;
  public steppedPayments: any[];
  public courtName?: string;
  public currentIp?: string;
  public previousIp?: string;
  public previousIpRetired?: string;
  public initialDividend?: string;
  public caseLocale?: string;
  public paymentFrequency?: 'week' | 'month' | 'bi-week' | 'four-week';
  public allocationType: 'automatic' | 'manual';
  public allocationSavings: number;
  public allocationTypeConfirmed: boolean;
  public allocationTypeConfirmedAt: string;
  public allocationTypeConfirmedBy: string;
  public vulnerableClientDisclosed: boolean;
  public allowPost: boolean;
  public administrationPeriod: number;
  public deceased: boolean;
  public approvalDate?: string;
}
