import {Component, Input, OnInit} from '@angular/core';
import {TypeaheadMatch} from 'ngx-bootstrap/typeahead';
import {Observable} from 'rxjs';

import {NotificationService} from '../../shared/notification.service';
import {SelectComparisons} from '../../shared/pipes/select.comparisons';
import {Creditor} from '../creditor';
import {CreditorService} from '../creditor.service';

@Component({
  selector: 'app-creditor-select',
  templateUrl: 'select.component.html',
})
export class CreditorSelectComponent extends SelectComparisons
  implements OnInit {
  /**
   * The model that we will be adding the creditor to.
   */
  @Input() model: any;

  /**
   * Do we require this field in the main form it is
   * included in?
   */
  @Input() required: boolean;

  /**
   * A list of all the creditors currently loaded.
   *
   * @type {Creditor[]}
   */
  public creditors: Creditor[] = [];

  /**
   * Are we currently performing a loading action?
   *
   * @type {boolean}
   */
  public isLoading: boolean = true;

  selectedValue: string;
  dataSource: Observable<any>;
  asyncSelected: string;
  typeaheadNoResults: boolean;
  typeaheadLoading: boolean;

  public pleaseClickAlertVisible: boolean = false;

  /**
   * Load up all our required services and subscribe to every observable we need.
   *
   * @param {CreditorService} creditorService
   * @param {NotificationService} notificationService
   */
  constructor(
    private creditorService: CreditorService,
    private notificationService: NotificationService,
  ) {
    super();

    this.dataSource = Observable.create((observer: any) => {
      observer.next(this.selectedValue);
    }).mergeMap((token: string) => this.searchCreditors(token));

    this.creditorService.loading.subscribe(isLoading => {
      this.isLoading = isLoading;
    });
  }

  /**
   * When the component is initialised we set it to load
   * page one of the creditor list.
   */
  public ngOnInit(): void {
    if (this.model && this.model.creditor && this.model.creditor.name) {
      this.selectedValue = this.model.creditor.name;
    }
  }

  /**
   * Loads a list of creditors using the given per page limit.
   *
   * @param {number} page The current page we require.
   * @param {number} limit The number of results per page.
   */
  public searchCreditors(filter: string): Observable<Creditor[]> {
    return this.creditorService.listCreditors(1, 10, filter);
  }

  changeTypeaheadLoading(e: boolean): void {
    this.typeaheadLoading = e;
    this.pleaseClickAlertVisible = false;
  }

  changeTypeaheadNoResults(e: boolean): void {
    this.model.creditor = undefined;
    this.pleaseClickAlertVisible = false;
  }

  typeaheadOnSelect(e: TypeaheadMatch): void {
    this.model.creditor = e.item;
    this.selectedValue = this.model.creditor.name;
    this.pleaseClickAlertVisible = false;
  }

  typeaheadOnBlur(e: any): void {
    if (!this.model || !this.model.creditor || this.model.creditor.id) {
      this.selectedValue = '';
      this.pleaseClickAlertVisible = true;
      return;
    }
    this.model.creditor = e.item;
    this.selectedValue = this.model.creditor.name;
    this.pleaseClickAlertVisible = false;
  }

  public addressStringFromAddress(
    creditor: Creditor,
    splitter: string = ', ',
  ): string {
    const addressArray = [
      creditor.address1,
      creditor.town,
      creditor.postalCode,
    ];

    return addressArray.filter(e => e).join(splitter);
  }
}
