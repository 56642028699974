import { Component, Input, OnInit } from '@angular/core';
import { ExpenditureService } from '../expenditure.service';
import { Expenditure } from '../expenditure';
import { NotificationService } from '../../shared/notification.service';
import { SelectComparisons } from '../../shared/pipes/select.comparisons';

@Component({
  selector: 'app-expenditure-select',
  templateUrl: 'select.component.html',
})
export class ExpenditureSelectComponent extends SelectComparisons
  implements OnInit {
  /**
   * The model that we will be adding the expenditure to.
   */
  @Input() model: any;

  /**
   * Do we require this field in the main form it is
   * included in?
   */
  @Input() required: boolean;

  /**
   * A list of all the expenditures currently loaded.
   *
   * @type {Expenditure[]}
   */
  public expenditures: Expenditure[] = [];

  /**
   * Are we currently performing a loading action?
   *
   * @type {boolean}
   */
  public isLoading: boolean = true;

  /**
   * Load up all our required services and subscribe to every observable we need.
   *
   * @param {ExpenditureService} expenditureService
   * @param {NotificationService} notificationService
   */
  constructor(
    private expenditureService: ExpenditureService,
    private notificationService: NotificationService,
  ) {
    super();
    this.expenditureService.loading.subscribe(isLoading => {
      this.isLoading = isLoading;
    });
  }

  /**
   * When the component is initialised we set it to load
   * page one of the expenditure list.
   */
  public ngOnInit(): void {
    this.loadExpenditures(1, 10000);
  }

  /**
   * Loads a list of expenditures using the given per page limit.
   *
   * @param {number} page The current page we require.
   * @param {number} limit The number of results per page.
   */
  public loadExpenditures(page?: number, limit?: number): void {
    const listExpenditures = this.expenditureService
      .listExpenditures(page, limit)
      .subscribe((expenditures: Expenditure[]) => {
        this.expenditures = expenditures;
        listExpenditures.unsubscribe();
      });
  }
}
