import { Component, Input, OnInit } from '@angular/core';
import { TaskService } from '../task.service';
import { Task } from '../task';
import { NotificationService } from '../../shared/notification.service';
import { SelectComparisons } from '../../shared/pipes/select.comparisons';

@Component({
  selector: 'app-task-select',
  templateUrl: 'select.component.html',
})
export class TaskSelectComponent extends SelectComparisons implements OnInit {
  /**
   * The model that we will be adding the task to.
   */
  @Input() model: any;

  /**
   * Do we require this field in the main form it is
   * included in?
   */
  @Input() required: boolean;

  /**
   * A list of all the tasks currently loaded.
   *
   * @type {Task[]}
   */
  public tasks: Task[] = [];

  /**
   * Are we currently performing a loading action?
   *
   * @type {boolean}
   */
  public isLoading: boolean = true;

  /**
   * Load up all our required services and subscribe to every observable we need.
   *
   * @param {TaskService} taskService
   * @param {NotificationService} notificationService
   */
  constructor(
    private taskService: TaskService,
    private notificationService: NotificationService,
  ) {
    super();
    this.taskService.loading.subscribe(isLoading => {
      this.isLoading = isLoading;
    });
  }

  /**
   * When the component is initialised we set it to load
   * page one of the task list.
   */
  public ngOnInit(): void {
    this.loadTasks(1, 10000);
  }

  /**
   * Loads a list of tasks using the given per page limit.
   *
   * @param {number} page The current page we require.
   * @param {number} limit The number of results per page.
   */
  public loadTasks(page?: number, limit?: number): void {
    const listTasks = this.taskService
      .listTasks(page, limit)
      .subscribe((tasks: Task[]) => {
        this.tasks = tasks;
        listTasks.unsubscribe();
      });
  }
}
