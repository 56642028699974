import { LinkProps, LinkWrapper } from 'react-inside-angular';
import Panel from '../../Components/Panel';
import useTitles from './UseTitles';
import { Alert, Button } from 'react-bootstrap';
import TitleForm from './TitleForm';
import useForm from '../../Hooks/UseForm';
import Title from '../../../../../chief-types/src/Title';
import { useEffect } from 'react';
import { Link as rrdLink, useHistory as rrdUseHistory } from 'react-router-dom';

export type EditTitleProps = {
  id: string;
};

const EditTitle = ({ Navigate, id }: LinkProps<EditTitleProps>) => {
  const Link = Navigate ? LinkWrapper(Navigate).Component : rrdLink;
  const history = Navigate ? LinkWrapper(Navigate).useHistory : rrdUseHistory();

  const { error, item, loadItem, loading, updateItem, tokenStorageChecked } = useTitles(false);
  const [title, setTitleValue, setTitle] = useForm<Title>({
    name: '',
  });

  useEffect(() => {
    if (tokenStorageChecked) {
      loadItem(id);
    }
  }, [tokenStorageChecked]);

  useEffect(() => {
    if (item !== undefined) {
      setTitle(item);
    }
  }, [item]);

  return (
    <Panel title="Edit Title" loading={loading}>
      {error && (
        <Alert>
          <h4>Error</h4>
          <p>{error}</p>
        </Alert>
      )}

      {item && (
        <>
          <TitleForm title={title} setTitleValue={setTitleValue} />

          <Button
            className="pull-right"
            onClick={() =>
              updateItem(id, title).then(() => history.push('titles'))
            }
          >
            Save
          </Button>
        </>
      )}
    </Panel>
  );
};

export default EditTitle;
