import { Component } from '@angular/core';
import { TaskService } from '../task.service';
import { Task } from '../task';
import { ActivatedRoute, Router } from '@angular/router';
import { NotificationService } from '../../shared/notification.service';

@Component({
  selector: 'app-task-edit',
  templateUrl: 'edit.component.html',
})
export class TaskEditComponent {
  /**
   * Details of the task being edited.
   */
  public task: Task;

  /**
   * Are we currently performing a loading action?
   *
   * @type {boolean}
   */
  public isLoading: boolean = false;

  /**
   * Load up all our required services and subscribe to every observable we need.
   *
   * @param {TaskService} taskService
   * @param {NotificationService} notificationService
   * @param {Router} router
   * @param {ActivatedRoute} route
   */
  constructor(
    private taskService: TaskService,
    private notificationService: NotificationService,
    private router: Router,
    private route: ActivatedRoute,
  ) {
    this.taskService.loading.subscribe(result => (this.isLoading = result));
    this.route.params.subscribe(params => this.loadTask(params.id));
  }

  /**
   * Perform the edit action which send the edited task to the backend.
   */
  public edit() {
    const editTask = this.taskService
      .updateTask(this.task.id, this.task)
      .subscribe(
        (result: Task) => {
          this.notificationService.success(
            `Task Updated`,
            `The task ${result.name} been updated with the specified changes.`,
            5,
          );
          this.task = new Task();
          editTask.unsubscribe();
          this.router.navigate(['/tasks']);
        },
        err => {
          this.notificationService.error(
            `Task Not Found`,
            err.error.message,
            10,
          );
          this.router.navigate(['/tasks']);
        },
      );
  }

  /**
   * Loads the requested task so we can display it and let the user edit the fields.
   *
   * @param {string} id
   */
  private loadTask(id: string) {
    this.taskService.findTask(id).subscribe(
      result => {
        this.task = result;
      },
      err => {
        this.notificationService.error(`Task Not Found`, err.error.message, 10);
        this.router.navigate(['/tasks']);
      },
    );
  }
}
