import { Component, EventEmitter, Input, OnInit } from '@angular/core';
import { Expenditure } from '../../expenditures/expenditure';
import { CaseExpenditureService } from '../../cases/expenditures/case-expenditure.service';
import { CaseExpenditure } from '../../cases/expenditures/case-expenditure';
import { BehaviorSubject } from 'rxjs/BehaviorSubject';

@Component({
  selector: 'app-case-expenditure-form-item',
  templateUrl: 'case-expenditure-form-item.component.html',
})
export class CaseExpenditureFormItemComponent implements OnInit {
  public editorOptions = {
    placeholder: 'Enter your note...',
    modules: {
      toolbar: ['bold', 'link'],
    },
  };

  @Input() public caseId: string = undefined;

  @Input() public item: Expenditure;

  @Input() public size: number = 4;
  public foundItem: CaseExpenditure = undefined;
  public isSaved: boolean = true;
  private loading: boolean = true;

  @Input() saveAllClicked: EventEmitter<boolean>;

  constructor(private readonly service: CaseExpenditureService) {}

  public get isLoading(): boolean {
    return this.loading;
  }

  public ngOnInit(): void {
    this.saveAllClicked.subscribe(r => this.save());
    this.loadItem();
  }

  public valueChanged(): void {
    this.isSaved = false;
  }

  public save(): void {
    this.loading = true;
    this.service
      .updateCaseExpenditure(this.foundItem.id, this.foundItem, {
        parameters: { id: this.caseId },
      })
      .subscribe(() => {
        this.loading = false;
        this.isSaved = true;
      });
  }

  private loadItem(): void {
    this.loading = true;
    this.service
      .findCaseExpenditure(this.item.id, { parameters: { id: this.caseId } })
      .subscribe(foundItem => {
        this.foundItem = foundItem;
        this.loading = false;
        this.isSaved = true;
      });
  }
}
