import { RouterModule, Routes } from '@angular/router';

import { AuthGuard } from '../auth/auth.guard';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { SharedModule } from '../shared/shared.module';
import { SubstatusCreateComponent } from './create/create.component';
import { SubstatusEditComponent } from './edit/edit.component';
import { SubstatusFormComponent } from './form/form.component';
import { SubstatusListComponent } from './list/list.component';
import { SubstatusService } from './substatus.service';

const routes: Routes = [
  {
    path: 'substatuses',
    component: SubstatusListComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'substatuses/create',
    component: SubstatusCreateComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'substatuses/:id/edit',
    component: SubstatusEditComponent,
    canActivate: [AuthGuard],
  },
];

@NgModule({
  declarations: [
    SubstatusListComponent,
    SubstatusCreateComponent,
    SubstatusFormComponent,
    SubstatusEditComponent,
  ],
  imports: [
    BrowserModule,
    RouterModule,
    FormsModule,
    HttpClientModule,
    SharedModule,
    RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' }),
  ],
  exports: [RouterModule],
  providers: [AuthGuard, SubstatusService],
})
export class SubstatusRoutingModule {}
