import { Component, Input } from '@angular/core';
import { BreachType } from '../breach-type';

@Component({
  selector: 'app-breach-type-form',
  templateUrl: 'form.component.html',
})
export class BreachTypeFormComponent {
  @Input() breachType: BreachType;
}
