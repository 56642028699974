import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';

import {environment} from '../../../environments/environment';
import {CreditorVote} from './case-creditor-vote';

// Service to ensure all creditor vote API calls are contained
@Injectable()
export class CaseCreditorVotesService {
  // Inject the http service for connecting to the votes system
  constructor(private http: HttpClient) {}

  public voteListUrl(caseId: string) {
    return `${environment.microservices.creditorVoting}creditor-voting/summary/${caseId}`;
  }

  /**
   *  Gets a list of the current creditors and their appropriate votes
   *
   * @param caseId The ID of the current case
   * @returns Observable<CreditorVote[]>
   */
  public getCreditorVotes(caseId: string): Observable<CreditorVote[]> {
    return this.http.get<CreditorVote[]>(this.voteListUrl(caseId));
  }

  /**
   * Update a creditor vote
   *
   * @param creditorVote Details of the vote that requires saving
   * @returns Observable<{ success: boolean }>
   */
  public updateVote(
    creditorVote: CreditorVote,
  ): Observable<{ success: boolean }> {
    return this.http.put<{ success: boolean }>(
      `${environment.microservices.creditorVoting}creditor-voting/update/${creditorVote.caseCreditorId}`,
      {
        creditorVote,
      },
    );
  }
}
