import { Component, Input } from '@angular/core';
import { Portfolio } from '../portfolio';

@Component({
  selector: 'app-portfolio-form',
  templateUrl: 'form.component.html',
})
export class PortfolioFormComponent {
  @Input() portfolio: Portfolio;
}
