export interface PanelProps {
  title: string;
  edgeToEdge?: boolean;
  count?: number;
  loading?: boolean;
  children: any;
  footer?: any;
}

const Panel = ({
  title,
  edgeToEdge,
  count,
  loading,
  children,
  footer,
}: PanelProps) => {
  return (
    <div className="tw-flex tw-flex-col tw-bg-white tw-shadow-sm tw-rounded-md tw-overflow-hidden">
      <div className="tw-flex tw-w-full tw-bg-gray-50 tw-p-4 tw-py-3 tw-border-0 tw-border-b tw-border-solid tw-border-gray-100">
        {loading === true && (
          <div className="tw-flex-1 tw-uppercase">Loading...</div>
        )}
        {!loading && count && (
          <div className="tw-flex-1 tw-uppercase">{count} results found</div>
        )}
        <div className="tw-uppercase tw-text-right">{title}</div>
      </div>

      <div className={`tw-flex-1 tw-p-${edgeToEdge === true ? 0 : 6}`}>
        {children}
      </div>

      {footer && (
        <div className="tw-flex tw-w-full tw-bg-gray-50 tw-p-4 tw-py-3 tw-border-0 tw-border-t tw-border-solid tw-border-gray-100 tw-justify-center">
          {footer}
        </div>
      )}
    </div>
  );
};

export default Panel;
