import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { HttpRequestService } from '../../shared/http/http-request.service';

@Injectable()
export class FinancialsInvoicingService extends HttpRequestService {
  /**
   * The endpoint that we call to get the accountItem information.
   *
   * @item {string}
   */
  private readonly endpoint: string = 'financials/invoicing';

  /**
   * Brings back a list of all accessible account-items.
   *
   * @param {number} page The page number we are currently on.
   * @param {number} limit The number of account-items to display per page.
   * @returns {Observable<any[]>}
   */
  public listInvoices(
    page?: number,
    limit?: number,
    options?: any,
    query?: any,
  ): Observable<any[]> {
    return this.list(this.endpoint, page, query, limit, options);
  }

  public findInvoice(id, options?: any) {
    return this.findById(this.endpoint, id, options);
  }

  public reconcileInvoice(id, clients): Observable<boolean> {
    return this.create(`${this.endpoint}/${id}/reconcile`, clients);
  }

  /**
   * Deletes a accountItem based on the given accountItem ID.
   *
   * @param {string} accountItemId The ID of the accountItem needing deleting.
   * @returns {Observable<boolean>}
   */
  public deleteInvoice(invoiceId: string): Observable<boolean> {
    return this.delete(this.endpoint, invoiceId);
  }
}
