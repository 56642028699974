import { Component, EventEmitter, Output, ViewChild } from '@angular/core';

import { CaseCalendar } from '../case-calendar';
import { CaseCalendarService } from '../case-calendar.service';
import { NotificationService } from '../../../shared/notification.service';

@Component({
  selector: 'app-case-calendar-create',
  templateUrl: 'create.component.html',
})
export class CaseCalendarCreateComponent {
  @ViewChild('staticModal') staticModal;

  @Output() completed = new EventEmitter<boolean>();

  public disabled: boolean = false;

  public model: CaseCalendar = new CaseCalendar();

  private givenCaseId: string;

  constructor(
    private readonly caseCalendarService: CaseCalendarService,
    private readonly notificationService: NotificationService,
  ) {}

  public get caseId(): string {
    return this.givenCaseId;
  }

  public open(caseId: string): void {
    this.givenCaseId = caseId;
    this.staticModal.show();
  }

  public close() {
    this.workCompleted();
  }

  public confirmCreate($event) {
    $event.preventDefault();
    if (!this.model.user && !this.model.department) {
      this.notificationService.error(
        'Incorrect Selection',
        'Please select one of User or Department to create Task.',
        10,
      );
      return;
    }
    this.disabled = true;
    this.caseCalendarService
      .createCaseCalendar(this.model, { parameters: { id: this.caseId } })
      .subscribe(() => {
        this.workCompleted();
      });
  }

  private workCompleted() {
    this.model = new CaseCalendar();
    this.disabled = false;
    this.staticModal.hide();
    this.completed.next(true);
  }
}
