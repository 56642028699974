import { Component, OnInit } from '@angular/core';
import { ChartOfAccountsService } from '../chart-of-accounts.service';
import { ChartOfAccounts } from '../chart-of-accounts';
import { Router } from '@angular/router';
import { NotificationService } from '../../../shared/notification.service';

@Component({
  selector: 'app-chart-of-accounts-create',
  templateUrl: 'create.component.html',
})
export class ChartOfAccountsCreateComponent implements OnInit {
  /**
   * Details of the chartOfAccounts being created.
   */
  public chartOfAccounts: ChartOfAccounts;

  /**
   * Are we currently performing a loading action?
   *
   * @type {boolean}
   */
  public isLoading: boolean = false;

  /**
   * Load up all our required services and subscribe to every observable we need.
   *
   * @param {ChartOfAccountsService} chartOfAccountsService
   * @param {NotificationService} notificationService
   * @param {Router} router
   */
  constructor(
    private chartOfAccountsService: ChartOfAccountsService,
    private notificationService: NotificationService,
    private router: Router,
  ) {
    this.chartOfAccountsService.loading.subscribe(
      result => (this.isLoading = result),
    );
  }

  /**
   * Initialise the component, creating a new blank chartOfAccounts if required.
   */
  public ngOnInit(): void {
    if (!this.chartOfAccounts) {
      this.chartOfAccounts = new ChartOfAccounts();
    }
  }

  /**
   * Perform the create action which adds the new chartOfAccounts into the backend.
   */
  public create() {
    const createChartOfAccounts = this.chartOfAccountsService
      .createChartOfAccounts(this.chartOfAccounts)
      .subscribe(
        (result: ChartOfAccounts) => {
          this.notificationService.success(
            `ChartOfAccounts Created`,
            `New chartOfAccounts ${result.name} has been created with ID of ${
              result.id
            }.`,
            5,
          );
          this.chartOfAccounts = new ChartOfAccounts();
          createChartOfAccounts.unsubscribe();
          this.router.navigate(['/chart-of-accounts']);
        },
        err => {
          this.notificationService.error(
            `Could not create ChartOfAccounts`,
            err.error.message,
            10,
          );
        },
      );
  }
}
