import { Injectable } from '@angular/core';
import { IncomeCategory } from './income-category';
import { Observable } from 'rxjs';

import { HttpRequestService } from '../shared/http/http-request.service';

@Injectable()
export class IncomeCategoryService extends HttpRequestService {
  /**
   * The endpoint that we call to get the incomeCategory information.
   *
   * @type {string}
   */
  private readonly endpoint: string = 'income-categories';

  /**
   * Brings back a list of all accessible incomeCategories.
   *
   * @param {number} page The page number we are currently on.
   * @param {number} limit The number of incomeCategories to display per page.
   * @returns {Observable<IncomeCategory[]>}
   */
  public listIncomeCategories(
    page?: number,
    limit?: number,
  ): Observable<IncomeCategory[]> {
    return this.list(this.endpoint, page, undefined, limit);
  }

  /**
   * Finds details of the incomeCategory from the given ID.
   *
   * @param {string} incomeCategoryId The ID of the incomeCategory we require details for.
   * @returns {Observable<IncomeCategory>}
   */
  public findIncomeCategory(
    incomeCategoryId: string,
  ): Observable<IncomeCategory> {
    return this.findById(this.endpoint, incomeCategoryId);
  }

  /**
   * Creates a new incomeCategory from the given IncomeCategory object.
   *
   * @param {IncomeCategory} newIncomeCategory The details of the incomeCategory to be created.
   * @returns {Observable<IncomeCategory | boolean>}
   */
  public createIncomeCategory(
    newIncomeCategory: IncomeCategory,
  ): Observable<IncomeCategory | boolean> {
    return this.create(this.endpoint, newIncomeCategory);
  }

  /**
   * Updates a incomeCategory with the given incomeCategory object for the given incomeCategory ID.
   *
   * @param {string} incomeCategoryId The ID of the incomeCategory needing updating.
   * @param {IncomeCategory} newDetails The new incomeCategory object to update with.
   * @returns {Observable<IncomeCategory | boolean>}
   */
  public updateIncomeCategory(
    incomeCategoryId: string,
    newDetails: IncomeCategory,
  ): Observable<IncomeCategory | boolean> {
    return this.update(this.endpoint, incomeCategoryId, newDetails);
  }

  /**
   * Deletes a incomeCategory based on the given incomeCategory ID.
   *
   * @param {string} incomeCategoryId The ID of the incomeCategory needing deleting.
   * @returns {Observable<boolean>}
   */
  public deleteIncomeCategory(incomeCategoryId: string): Observable<boolean> {
    return this.delete(this.endpoint, incomeCategoryId);
  }
}
