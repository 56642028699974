import { Injectable } from '@angular/core';
import { BreachType } from './breach-type';
import { Observable } from 'rxjs';

import { HttpRequestService } from '../../shared/http/http-request.service';

@Injectable()
export class BreachTypeService extends HttpRequestService {
  /**
   * The endpoint that we call to get the breachType information.
   *
   * @type {string}
   */
  private readonly endpoint: string = 'breach-types';

  /**
   * Brings back a list of all accessible breach-types.
   *
   * @param {number} page The page number we are currently on.
   * @param {number} limit The number of breach-types to display per page.
   * @returns {Observable<BreachType[]>}
   */
  public listBreachTypes(
    page?: number,
    limit?: number,
  ): Observable<BreachType[]> {
    return this.list(this.endpoint, page, undefined, limit);
  }

  /**
   * Finds details of the breachType from the given ID.
   *
   * @param {string} breachTypeId The ID of the breachType we require details for.
   * @returns {Observable<BreachType>}
   */
  public findBreachType(breachTypeId: string): Observable<BreachType> {
    return this.findById(this.endpoint, breachTypeId);
  }

  /**
   * Creates a new breachType from the given BreachType object.
   *
   * @param {BreachType} newBreachType The details of the breachType to be created.
   * @returns {Observable<BreachType | boolean>}
   */
  public createBreachType(
    newBreachType: BreachType,
  ): Observable<BreachType | boolean> {
    return this.create(this.endpoint, newBreachType);
  }

  /**
   * Updates a breachType with the given breachType object for the given breachType ID.
   *
   * @param {string} breachTypeId The ID of the breachType needing updating.
   * @param {BreachType} newDetails The new breachType object to update with.
   * @returns {Observable<BreachType | boolean>}
   */
  public updateBreachType(
    breachTypeId: string,
    newDetails: BreachType,
  ): Observable<BreachType | boolean> {
    return this.update(this.endpoint, breachTypeId, newDetails);
  }

  /**
   * Deletes a breachType based on the given breachType ID.
   *
   * @param {string} breachTypeId The ID of the breachType needing deleting.
   * @returns {Observable<boolean>}
   */
  public deleteBreachType(breachTypeId: string): Observable<boolean> {
    return this.delete(this.endpoint, breachTypeId);
  }
}
