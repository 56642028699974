import { ActivatedRoute, Router } from '@angular/router';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { AuthService } from '../../../auth/auth.service';
import { Case } from '../../../cases/case';
import { CaseService } from '../../../cases/case.service';
import { FinancialsInvoicingService } from '../financials-invoicing.service';
import { NotificationService } from '../../../shared/notification.service';

@Component({
  selector: 'app-financials-invoice-list',
  templateUrl: 'list.component.html',
  styleUrls: [],
})
export class FinancialsInvoiceListComponent implements OnInit {
  invoices: any[];

  private readonly httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + this.authService.getToken(),
    }),
  };

  accountSelect: any = {
    account: undefined,
    reconcileOptions: 'all',
    filter: '',
  };

  /**
   * Are we currently performing a loading action?
   *
   * @item {boolean}
   */
  public isLoading: boolean = true;

  /**
   * The total number of pages in this list of banking-account-items.
   *
   * @item {number}
   */
  public totalPages: number = 1;

  /**
   * The current page of banking-account-items that we are viewing.
   *
   * @item {number}
   */
  public currentPage: number = 1;

  /**
   * The total number of banking-account-items that can be viewed in the system.
   *
   * @item {number}
   */
  public totalResults: number = 0;

  public currentMonetaryValue: number = 0;

  public reconcileItems: string[] = [];
  public reconciliationCreated: EventEmitter<boolean> =
    new EventEmitter<boolean>();

  /**
   * An Emitter to alert other components when a accountItem has been deleted.
   *
   * @item {EventEmitter<boolean>}
   */
  public accountItemDeleted: EventEmitter<boolean> =
    new EventEmitter<boolean>();

  public reconcileReady: boolean = false;
  public reconcileOptions: string = 'all';
  public filter: string = undefined;
  public accountId: string;

  constructor(
    private readonly http: HttpClient,
    private readonly authService: AuthService,
    private route: ActivatedRoute,
    private router: Router,
    private readonly invoicingService: FinancialsInvoicingService,
    private readonly notificationService: NotificationService,
  ) {
    this.invoicingService.loading.subscribe((isLoading) => {
      this.isLoading = isLoading;
    });
    this.invoicingService.totalPages.subscribe((totalPages) => {
      this.totalPages = totalPages;
    });
    this.invoicingService.currentPage.subscribe((currentPage) => {
      this.currentPage = currentPage;
    });
    this.invoicingService.totalResults.subscribe((totalResults) => {
      this.totalResults = totalResults;
    });
  }

  /**
   * When the component is initialised we set it to load
   * page one of the accountItem list.
   */
  public ngOnInit(): void {
    this.route.params.subscribe((params) => {
      this.accountId = params.accountId;
      this.loadBankingAccountItems(1);
    });
  }

  public canDeleteInvoice(invoice: any) {
    return invoice.reconciled === false || invoice.reconciled === 'false';
  }

  /**
   * When we receive a page change from the pagination
   * component we load the appropriate page for the
   * accountItem list.
   *
   * @param {number} pageNumber
   */
  public onPageChanged(pageNumber: number): void {
    this.loadBankingAccountItems(pageNumber);
  }

  /**
   * Loads a list of banking-account-items using the given per page limit.
   *
   * @param {number} page The current page we require.
   * @param {number} limit The number of results per page.
   */
  public loadBankingAccountItems(page?: number, limit?: number): void {
    const listBankingAccountItems = this.invoicingService
      .listInvoices(page, limit, { parameters: { accountId: this.accountId } })
      .subscribe((accountItems: any[]) => {
        this.invoices = accountItems;
        listBankingAccountItems.unsubscribe();
      });
  }

  public refreshAndMove(e) {
    this.router.navigate(['/financials/invoicing', e]);
    this.onPageChanged(1);
  }

  /**
   * When receiving confirmation of an item being deleted
   * we call the back end with the appropriate ID
   *
   * @param {string} id
   */
  public delete(id: string) {
    this.invoicingService.deleteInvoice(id).subscribe(
      (deleted) => {
        this.notificationService.success(
          `Invoice Deleted`,
          `Invoice with ID ${id} was deleted.`,
          5,
        );
        this.accountItemDeleted.next(true);
        this.onPageChanged(this.currentPage);
      },
      (err) => {
        this.notificationService.error(
          `Invoice Not Deleted`,
          err.error.message,
          10,
        );
        this.accountItemDeleted.next(false);
        this.onPageChanged(this.currentPage);
      },
    );
  }
}
