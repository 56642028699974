export const environment = {
  microservices: {
    search: 'https://api-search-yicvs4tngq-nw.a.run.app/',
    reports: 'https://api-reports-yicvs4tngq-nw.a.run.app/',
    feeComparison: 'https://api-fee-comparison-yicvs4tngq-nw.a.run.app/',
    creditorVoting: 'https://api-creditor-voting-yicvs4tngq-nw.a.run.app/',
    creditorCommunication: 'https://api-creditor-communication-yicvs4tngq-nw.a.run.app/'
  },
  production: true,
  // endpoint: 'https://api.chief.systems/v1/',
  endpoint: 'https://chief-backend-master.herokuapp.com/v1/',
  functionEndpoint: 'https://europe-west1-chief-develop.cloudfunctions.net/',
  euFunctionEndpoint: 'https://europe-west1-chief-frontend.cloudfunctions.net/',
  apiUrl: 'https://europe-west1-chief-frontend.cloudfunctions.net/api/',
  logOutput: ['error'],
  pagination: {
    perPage: 10,
    startPage: 1,
  },
  intercomId: 'k2o0tmm9',
  firebase: {
    apiKey: 'AIzaSyAJwn-nNDDdpE4bRL8HdGul8_6JO7H5c4U',
    authDomain: 'chief-frontend.firebaseapp.com',
    databaseURL: 'https://chief-frontend.firebaseio.com',
    projectId: 'chief-frontend',
    storageBucket: 'chief-frontend.appspot.com',
    messagingSenderId: '358322077009',
    appId: '1:358322077009:web:b13ceca19026370f',
  },
};
