<div class="container">
  <form (submit)="login()" class="form-signin">
    <h2 class="form-signin-heading">
      <img alt="" src="/assets/images/logo.png" />
    </h2>

    <div class="login-wrap">
      <div class="user-login-info">
        <div
          *ngIf="errorMessage"
          class="alert alert-danger text-center"
          role="alert"
        >
          {{ errorMessage }}
        </div>

        <input
          [(ngModel)]="username"
          [ngModelOptions]="{ standalone: true }"
          autofocus
          class="form-control"
          placeholder="E-Mail Address"
          required
          type="text"
        />
        <input
          [(ngModel)]="password"
          [ngModelOptions]="{ standalone: true }"
          class="form-control"
          placeholder="Password"
          required
          type="password"
        />
      </div>

      <button class="btn btn-lg btn-login btn-block" type="submit">
        Sign in
      </button>

      <div class="forgotten-password">
        <a [routerLink]="['/auth/forgot-password']" style="cursor: pointer"
          >Forgot Password?</a
        >
      </div>
    </div>
  </form>
</div>
