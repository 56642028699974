<input [(ngModel)]="selectedValue"
       [required]="required"
       [typeahead]="dataSource"
       (typeaheadLoading)="changeTypeaheadLoading($event)"
       (typeaheadNoResults)="changeTypeaheadNoResults($event)"
       (typeaheadOnSelect)="typeaheadOnSelect($event)"
       (typeaheadOnBlur)="typeaheadOnBlur($event)"
       [typeaheadMinLength]="2"
       typeaheadOptionField="name"
       placeholder="Please start typing your supplier name"
       class="form-control">
