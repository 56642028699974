import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {Distribute} from '@chief-support-systems/iva-distributions';
import * as moment from 'moment';
import {Observable} from 'rxjs/Observable';

import {environment} from '../../../../environments/environment';
import {AuthService} from '../../../auth/auth.service';
import {HttpRequestResponse} from '../../../shared/http/http-request-response';
import {SelectComparisons} from '../../../shared/pipes/select.comparisons';
import {FinancialsDistributionService} from '../financials-distribution.service';

@Component({
  selector: 'app-financials-distribution-distribute',
  templateUrl: 'distribute.component.html',
})
export class FinancialsDistributionDistributeComponent
  extends SelectComparisons
  implements OnInit {
  public displayLedger: boolean = false;
  public clientId: string;
  public isLoading: boolean = true;
  public clientDetails: any = undefined;
  public hideInvoiceless = false;
  public maximumAvailable: number = 0;
  public retainedMonies: number = 0;
  public possiblePayments: any[] = [];
  public allocationsInProgress = false;
  public pickedPayment = {
    id: undefined,
    date: moment().format('YYYY-MM-DD'),
    value: 0,
  };
  protected automaticallyDistributeOnLoad: boolean = false;
  protected remainingDistributions: number = 0;
  private readonly httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + this.authService.getToken(),
    }),
  };

  constructor(
    private readonly distributionService: FinancialsDistributionService,
    private route: ActivatedRoute,
    private authService: AuthService,
    private router: Router,
    private readonly http: HttpClient,
  ) {
    super();
    this.distributionService
      .automaticAllocationsInProgress()
      .subscribe((r) => (this.allocationsInProgress = r));
  }

  get canChangeDistributions(): boolean {
    return this.authService.hasPermission('financials.modify-distributions');
  }

  get totalDistributed() {
    let totalDistributed = 0;

    if (
      this.clientDetails &&
      this.clientDetails.catOnes &&
      this.clientDetails.catOnes.length > 0
    ) {
      totalDistributed +=
        Math.floor(
          this.clientDetails.catOnes.reduce((c, p) => {
            return c + p.toDistribute * 100;
          }, 0),
        ) / 100;
    }

    if (
      this.clientDetails &&
      this.clientDetails.creditors &&
      this.clientDetails.creditors.length > 0
    ) {
      totalDistributed +=
        Math.floor(
          this.clientDetails.creditors.reduce((c, p) => {
            return c + p.toDistribute * 100;
          }, 0),
        ) / 100;
    }

    // console.table(this.clientDetails.creditors);
    return totalDistributed;
  }

  get totalCatOnesDistributed() {
    let totalDistributed = 0;

    if (
      this.clientDetails &&
      this.clientDetails.catOnes &&
      this.clientDetails.catOnes.length > 0
    ) {
      this.clientDetails.catOnes.forEach((item) => {
        totalDistributed += item.toDistribute;
      });
    }

    return totalDistributed;
  }

  ngOnInit(): void {
    this.route.params.subscribe((params) => {
      this.clientId = params.id;
      this.loadClientDetails();
      this.loadSchedule();
    });
  }

  public confirmDistributions(reload: boolean = false) {
    this.saveAllocations().subscribe((result) => {
      if (reload && reload === true) {
        this.loadClientDetails();
        this.loadSchedule();
      } else {
        this.router.navigate(['/financials/distributions']);
      }
    });
  }

  public confirmDistributionsAndNext() {
    this.saveAllocations().subscribe((result) => {
      if (result && result.id) {
        this.router.navigate(['/financials/distributions/' + result.id]);
      } else {
        this.router.navigate(['/financials/distributions/summary']);
      }
    });
  }

  public setDistributionValueAndDate(event) {
    this.clientDetails.financialValues.cash_remaining = event.value;

    // this.allocateFundsAutomatically();
  }

  public allocateFundsAutomatically() {
    if (
      this.clientDetails.financialValues.cash_remaining > this.maximumAvailable
    ) {
      this.clientDetails.financialValues.cash_remaining = this.maximumAvailable;
    }

    this.remainingDistributions = this.clientDetails.financialValues.cash_remaining;
    this.clientDetails.catOnes = this.calculateCatOneDistributions(
      this.clientDetails.catOnes,
    );
    this.calculateCreditorDistributions();
  }

  public ensureCreatedDate(item) {
    if (item.toDistribute > 0 && !item.createdAt) {
      item.createdAt = this.pickedPayment.date;
    } else if (item.toDistribute === 0 && item.createdAt) {
      delete item.createdAt;
    }
  }

  loadSchedule() {
    this.pickedPayment = {
      id: undefined,
      date: moment().format('YYYY-MM-DD'),
      value: 0,
    };

    this.http
      .get<HttpRequestResponse>(
        `${environment.endpoint}cases/${this.clientId}/payment-schedule`,
        this.httpOptions,
      )
      .map((data: HttpRequestResponse) => data.data)
      .subscribe((item) => {
        this.possiblePayments = [];
        item.schedule.forEach((singleItem) => {
          singleItem.paymentLog.forEach((singleSchedule) => {
            if (
              !singleSchedule.allocated ||
              singleSchedule.allocated !== true
            ) {
              this.possiblePayments.push({
                id: singleItem.id,
                date: moment(singleSchedule.textDate).format('YYYY-MM-DD'),
                value: singleSchedule.allocatedValue
                  ? singleSchedule.value - singleSchedule.allocatedValue
                  : singleSchedule.value,
              });
            }
          });
        });
      });
  }

  protected saveAllocations(): Observable<any> {
    return this.distributionService.sendDistributions(this.clientId, {
      ...this.clientDetails,
      payment: this.pickedPayment,
      distributed: this.totalDistributed * 100,
    });
  }

  protected loadClientDetails() {
    this.isLoading = true;
    let clientDetailsQuery = this.distributionService
      .showClientDistributions(this.clientId)
      .subscribe((result) => {
        this.isLoading = false;
        this.clientDetails = result;

        this.maximumAvailable =
          Number(result.financialValues.cash_remaining) -
          Number(result.financialValues.pending_cash_out);

        this.retainedMonies =
          this.maximumAvailable > this.clientDetails.client.allocationSavings
            ? this.clientDetails.client.allocationSavings
            : this.maximumAvailable;

        this.maximumAvailable = this.maximumAvailable - this.retainedMonies;

        if (this.automaticallyDistributeOnLoad) {
          this.allocateFundsAutomatically();
        } else {
          this.clientDetails.catOnes = this.clientDetails.catOnes.map((c1) => {
            c1.toDistribute = 0;
            return c1;
          });
          this.clientDetails.creditors = this.clientDetails.creditors.map(
            (c1) => {
              c1.toDistribute = 0;
              return c1;
            },
          );
        }

        clientDetailsQuery.unsubscribe();
      });
  }

  public round(value: number): number {
    return Math.round(value);
  }
  protected calculateCatOneDistributions(items: any[]): any[] {
    return items.map((item) => {
      if (item.pending_paid > 0) {
        item.toDistribute = 0;
        return item;
      }

      if (item.invoice_required && item.invoice_count === 0) {
        item.toDistribute = 0;
        return item;
      }

      const remainingValue =
        (item.estimated ? item.invoice_value : item.required) +
        item.paid -
        item.pending_paid;

      if (
        this.remainingDistributions === 0 ||
        (item.invoice_required && item.invoice_id === null)
      ) {
        item.toDistribute = 0;
      } else if (remainingValue >= this.remainingDistributions) {
        item.toDistribute = this.remainingDistributions / 100;
        this.remainingDistributions = 0;
      } else {
        item.toDistribute = remainingValue / 100;
        this.remainingDistributions =
          this.remainingDistributions - item.remaining;
      }

      if (item.toDistribute > 0) {
        item.createdAt = this.pickedPayment.date;
      }

      return item;
    });
  }

  protected calculateCreditorDistributions() {
    let remainingDistributions =
      this.maximumAvailable +
      this.retainedMonies -
      this.totalCatOnesDistributed * 100;
    if (remainingDistributions < 0) {
      remainingDistributions = 0;
    }

    const creditorDistributionValues = Distribute({
      balanceOnCase: remainingDistributions,
      reserve:
        this.retainedMonies === undefined || this.retainedMonies === null
          ? 1500
          : this.retainedMonies,
    })(
      this.clientDetails.creditors.map((creditor) => ({
        id: creditor.id || '',
        name: creditor.creditor,
        reference: creditor.reference || '',
        soaBalance: creditor.value || 0,
        podBalance: creditor.actual_value || 0,
        debtStatus: creditor.status || '',
        distributedToDate: Number(creditor.paid) || 0,
      })),
    );

    this.clientDetails.creditors.map((item) => {
      // Find the updated creditor
      const completedCalculation = creditorDistributionValues.filter(
        (i) => i.id === item.id,
      );
      if (completedCalculation.length < 0) {
        return item;
      }

      if (completedCalculation[0].toDistribute > 0) {
        item.toDistribute =
          Math.floor(completedCalculation[0].toDistribute) / 100;
        item.willRetain = !completedCalculation[0].willDistribute;
        item.createdAt = this.pickedPayment.date;
      }

      return item;
    });

    // let totalOwing = 0;
    // this.clientDetails.creditors.forEach((item) => {
    //   totalOwing += Number(item.remaining);
    // });

    // this.clientDetails.creditors = this.clientDetails.creditors.map((item) => {
    //   if (item.pending_paid > 0) {
    //     item.toDistribute = 0;
    //     return item;
    //   }

    //   item.toDistribute =
    //     Math.floor(
    //       remainingDistributions * (item.remaining / totalOwing) * 100,
    //     ) / 100;

    //   if (item.toDistribute > 0) {
    //     item.createdAt = this.pickedPayment.date;
    //   }

    //   return item;
    // });
  }
}
