<div *ngIf="isLoading">Loading Substatus List...</div>

<select
  *ngIf="!isLoading"
  (change)="optionChanged.emit(true)"
  [compareWith]="byId"
  class="form-control"
  name="substatus"
  [(ngModel)]="model.substatus"
  [required]="required"
>
<option [ngValue]="null">--- No Substatus</option>
  <option
    *ngFor="let singleSubstatus of substatuses"
    [ngValue]="singleSubstatus"
  >
    {{ singleSubstatus.name }}
  </option>
</select>
