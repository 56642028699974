import { RouterModule, Routes } from '@angular/router';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule } from '@angular/common/http';
import { BankingAccountTypeListComponent } from './list/list.component';
import { AuthGuard } from '../../../auth/auth.guard';
import { BankingAccountTypeService } from './banking-account-type.service';
import { BankingAccountTypeCreateComponent } from './create/create.component';
import { BankingAccountTypeFormComponent } from './form/form.component';
import { BankingAccountTypeEditComponent } from './edit/edit.component';
import { SharedModule } from '../../../shared/shared.module';

const routes: Routes = [
  {
    path: 'banking/account-types',
    component: BankingAccountTypeListComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'banking/account-types/create',
    component: BankingAccountTypeCreateComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'banking/account-types/:id/edit',
    component: BankingAccountTypeEditComponent,
    canActivate: [AuthGuard],
  },
];

@NgModule({
  declarations: [
    BankingAccountTypeListComponent,
    BankingAccountTypeCreateComponent,
    BankingAccountTypeFormComponent,
    BankingAccountTypeEditComponent,
  ],
  imports: [
    BrowserModule,
    RouterModule,
    FormsModule,
    HttpClientModule,
    SharedModule,
    RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' }),
  ],
  exports: [RouterModule],
  providers: [AuthGuard, BankingAccountTypeService],
})
export class BankingAccountTypeRoutingModule {}
