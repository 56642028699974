import {Component, OnInit} from '@angular/core';
import {AngularFirestore} from '@angular/fire/firestore';

@Component({
  selector: 'app-banking-matching-list',
  templateUrl: 'list.component.html',
  styleUrls: [],
})
export class BankingMatchingListComponent implements OnInit {
  public matches;

  constructor(
      private db: AngularFirestore,
  ) {
  }

  ngOnInit(): void {
    this.matches = this.db
        .collection('/bank-matching', fn => fn.orderBy('status'))
        .valueChanges();
  }

}
