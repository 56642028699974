<div *ngIf="isLoading">Loading Account Item List...</div>

<div *ngIf="!isLoading">
  <div class="row">
    <div
      class="col-md-6"
      [class.text-danger]="
        makePositive(expectedValue) !== makePositive(currentMonetaryValue)
      "
      [class.text-success]="
        makePositive(expectedValue) === makePositive(currentMonetaryValue)
      "
    >
      <b>Total Expected:</b> {{ expectedValue | toAccountancy }}
    </div>
    <div
      class="col-md-6"
      [class.text-danger]="
        makePositive(expectedValue) !== makePositive(currentMonetaryValue)
      "
      [class.text-success]="
        makePositive(expectedValue) === makePositive(currentMonetaryValue)
      "
    >
      <b>Total Selected:</b> {{ currentMonetaryValue | toAccountancy }}
    </div>
  </div>

  <table class="table table-striped">
    <thead>
      <tr>
        <th>&nbsp;</th>
        <th>Date</th>
        <th>Reference</th>
        <th>Value</th>
      </tr>
    </thead>

    <tbody>
      <tr *ngFor="let accountItem of accountItems">
        <td>
          <i
            class="fa fa-spinner fa-spin"
            *ngIf="accountItem.isChecking === true"
          ></i>
          <i
            class="fa fa-exclamation-triangle text-danger"
            tooltip="This transaction cannot be reconciled at this time"
            *ngIf="
              accountItem.isChecking === false &&
              accountItem.isAllowed === false
            "
          ></i>
          <i
            class="fa"
            *ngIf="
              accountItem.isChecking === false && accountItem.isAllowed === true
            "
            [class.fa-check-square]="isSelected(accountItem.id)"
            [class.fa-square]="!isSelected(accountItem.id)"
            (click)="toggleItem(accountItem.id, accountItem.value / 100)"
          ></i>
        </td>
        <td>{{ accountItem?.createdAt | date: 'dd/MM/yyyy' }}</td>
        <td>{{ accountItem?.reference }}</td>
        <td>{{ accountItem?.value / 100 | toAccountancy }}</td>
      </tr>
    </tbody>

    <tfoot>
      <tr>
        <td colspan="99" class="text-center">
          <app-pagination
            [totalPages]="totalPages"
            [currentPage]="currentPage"
            (pageChanged)="onPageChanged($event)"
          ></app-pagination>
        </td>
      </tr>
    </tfoot>
  </table>
</div>
