import {Component, Input, OnInit} from '@angular/core';
import {User} from '../user';
import {AuthService} from '../../auth/auth.service';
import {DomainService} from '../../domains/domain.service';
import {Domain} from '../../domains/domain';

@Component({
  selector: 'app-user-form',
  templateUrl: 'form.component.html',
})
export class UserFormComponent implements OnInit {
  @Input() user: User;
  @Input() isEditing: boolean;

  public domains: Domain[] = [];
  public chosenDomains: Domain[] = [];

  public passwordBoxVisible: boolean = false;

  constructor(
    public readonly authService: AuthService,
    public readonly domainService: DomainService,
  ) {
  }

  public get userHasMultipleDomains(): boolean {
    return true;
  }

  public ngOnInit() {
    this.user.domain = this.authService.domain;

    if (!this.user.additionalDomains) {
      this.user.additionalDomains = [];
    }
    let domainList = this.domainService.listDomains(1, 10000)
      .subscribe(domains => {
        this.domains = domains;
        domainList.unsubscribe();
      });
  }

  public toggleChosenDomain(domain: Domain): void {
    const domainIsChosen = this.domainIsChosen(domain);

    if (domainIsChosen !== false) {
      this.user.additionalDomains.splice(domainIsChosen, 1);
    } else {
      this.user.additionalDomains.push(domain);
    }

  }

  public domainIsChosen(domain: Domain): false | number {
    let isInChosenList = false;
    let chosenIndex = 0;

    this.user.additionalDomains.forEach((chosenDomain, index) => {
      if (chosenDomain.id === domain.id) {
        isInChosenList = true;
        chosenIndex = index;
      }
    });

    return isInChosenList === false ? false : chosenIndex;
  }

  public toggleVisiblePassword(): void {
    this.passwordBoxVisible = !this.passwordBoxVisible;
  }

  public generatePassword(): void {
    this.passwordBoxVisible = true;
    this.user.password = this.randomPassword(12);
  }

  private randomPassword(length: number): string {
    const chars =
      'abcdefghijklmnopqrstuvwxyz!@#$%^&*()-+<>ABCDEFGHIJKLMNOP1234567890';
    let pass = '';

    for (let x = 0; x < length; x++) {
      const i = Math.floor(Math.random() * chars.length);
      pass += chars.charAt(i);
    }

    return pass;
  }
}
