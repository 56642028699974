import { Component, Input, OnInit } from '@angular/core';
import { StatusCategoryService } from '../status-category.service';
import { StatusCategory } from '../status-category';
import { NotificationService } from '../../shared/notification.service';
import { SelectComparisons } from '../../shared/pipes/select.comparisons';

@Component({
  selector: 'app-status-category-select',
  templateUrl: 'select.component.html',
})
export class StatusCategorySelectComponent extends SelectComparisons
  implements OnInit {
  /**
   * The model that we will be adding the statusCategory to.
   */
  @Input() model: any;

  /**
   * Do we require this field in the main form it is
   * included in?
   */
  @Input() required: boolean;

  /**
   * A list of all the statusCategories currently loaded.
   *
   * @type {StatusCategory[]}
   */
  public statusCategories: StatusCategory[] = [];

  /**
   * Are we currently performing a loading action?
   *
   * @type {boolean}
   */
  public isLoading: boolean = true;

  /**
   * Load up all our required services and subscribe to every observable we need.
   *
   * @param {StatusCategoryService} statusCategoryService
   * @param {NotificationService} notificationService
   */
  constructor(
    private statusCategoryService: StatusCategoryService,
    private notificationService: NotificationService,
  ) {
    super();
    this.statusCategoryService.loading.subscribe(isLoading => {
      this.isLoading = isLoading;
    });
  }

  /**
   * When the component is initialised we set it to load
   * page one of the statusCategory list.
   */
  public ngOnInit(): void {
    this.loadStatusCategories(1, 10000);
  }

  /**
   * Loads a list of statusCategories using the given per page limit.
   *
   * @param {number} page The current page we require.
   * @param {number} limit The number of results per page.
   */
  public loadStatusCategories(page?: number, limit?: number): void {
    const listStatusCategories = this.statusCategoryService
      .listStatusCategories(page, limit)
      .subscribe((statusCategories: StatusCategory[]) => {
        this.statusCategories = statusCategories;
        listStatusCategories.unsubscribe();
      });
  }
}
