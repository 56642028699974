import { Case } from '../case';
import { Department } from '../../settings/departments/department';
import { Task } from '../../tasks/task';
import { User } from '../../users/user';

export class CaseCalendar {
  public id?: string;
  public case?: Case;
  public user?: User;
  public task?: Task;
  public department?: Department;
  public content?: string;
  public icon?: string;
  public toAction: boolean;
  public actionAt: string;
  public completedAt?: string;
  public createdAt?: string;
  public createdBy?: User;
}
