import { Component, Input, OnInit } from '@angular/core';
import { BreachTypeService } from '../breach-type.service';
import { BreachType } from '../breach-type';
import { SelectComparisons } from '../../../shared/pipes/select.comparisons';
import { NotificationService } from '../../../shared/notification.service';

@Component({
  selector: 'app-breach-type-select',
  templateUrl: 'select.component.html',
})
export class BreachTypeSelectComponent extends SelectComparisons
  implements OnInit {
  /**
   * The model that we will be adding the breachType to.
   */
  @Input() model: any;

  /**
   * Do we require this field in the main form it is
   * included in?
   */
  @Input() required: boolean;

  /**
   * A list of all the breach-types currently loaded.
   *
   * @type {BreachType[]}
   */
  public breachTypes: BreachType[] = [];

  /**
   * Are we currently performing a loading action?
   *
   * @type {boolean}
   */
  public isLoading: boolean = true;

  /**
   * Load up all our required services and subscribe to every observable we need.
   *
   * @param {BreachTypeService} breachTypeService
   * @param {NotificationService} notificationService
   */
  constructor(
    private breachTypeService: BreachTypeService,
    private notificationService: NotificationService,
  ) {
    super();
    this.breachTypeService.loading.subscribe(isLoading => {
      this.isLoading = isLoading;
    });
  }

  /**
   * When the component is initialised we set it to load
   * page one of the breachType list.
   */
  public ngOnInit(): void {
    this.loadBreachTypes(1, 10000);
  }

  /**
   * Loads a list of breach-types using the given per page limit.
   *
   * @param {number} page The current page we require.
   * @param {number} limit The number of results per page.
   */
  public loadBreachTypes(page?: number, limit?: number): void {
    const listBreachTypes = this.breachTypeService
      .listBreachTypes(page, limit)
      .subscribe((breachTypes: BreachType[]) => {
        this.breachTypes = breachTypes;
        listBreachTypes.unsubscribe();
      });
  }
}
