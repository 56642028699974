import { Component, Input } from '@angular/core';
import { BankingAccountType } from '../banking-account-type';

@Component({
  selector: 'app-banking-account-type-form',
  templateUrl: 'form.component.html',
})
export class BankingAccountTypeFormComponent {
  @Input() accountType: BankingAccountType;
}
