<div class="row">
  <div class="col-md-6">
    <div
      *ngIf="
        user &&
        (authService.hasPermission('config.multiple-domains') ||
          authService.hasPermission('config.all-domains'))
      "
      class="form-group"
    >
      <label class="col-sm-2 control-label" for="domain">Domain</label>
      <div class="col-sm-10">
        <app-domain-select [model]="user" [required]="true"></app-domain-select>
      </div>
    </div>

    <div class="form-group">
      <label class="col-sm-2 control-label" for="department">Department</label>
      <div class="col-sm-10">
        <app-department-select
          [model]="user"
          [required]="true"
        ></app-department-select>
      </div>
    </div>

    <div class="form-group">
      <label class="col-sm-2 control-label" for="name-search">Name</label>
      <div class="col-sm-10">
        <input
          [(ngModel)]="user.name"
          autocomplete="nope"
          class="form-control"
          id="name-search"
          name="name-search"
          required
          type="text"
        />
      </div>
    </div>

    <div class="form-group">
      <label class="col-sm-2 control-label" for="password-search"
        >Password</label
      >
      <div class="col-sm-10">
        <div class="input-group">
          <input
            [(ngModel)]="user.password"
            [minlength]="5"
            [required]="!isEditing"
            [type]="passwordBoxVisible ? 'text' : 'password'"
            autocomplete="new-password"
            class="form-control"
            id="password-search"
            name="password-search"
            placeholder="Password"
          /><br />
          <span class="input-group-btn">
            <button
              (click)="generatePassword()"
              class="btn btn-default"
              type="button"
            >
              <i class="fa fa-key"></i>
            </button>
            <button
              (click)="toggleVisiblePassword()"
              [class.btn-danger]="passwordBoxVisible"
              [class.btn-warning]="!passwordBoxVisible"
              class="btn"
              type="button"
            >
              <i class="fa fa-eye"></i>
            </button>
          </span>
        </div>
        <!-- <ng2-password-strength-bar *ngIf="user.password && user.password.length > 0"
                                   [barLabel]="'Password Strength'"
                                   [passwordToCheck]="user.password">
        </ng2-password-strength-bar> -->
      </div>
    </div>

    <div class="form-group">
      <label class="col-sm-2 control-label" for="avatar-search"
        >Avatar Url</label
      >
      <div class="col-sm-10">
        <input
          [(ngModel)]="user.avatar"
          class="form-control"
          id="avatar-search"
          name="avatar-search"
          placeholder="Avatar Url"
          type="text"
        />
      </div>
    </div>

    <div class="form-group">
      <label class="col-sm-2 control-label" for="permissions"
        >Permissions</label
      >
      <div class="col-sm-10">
        <app-permission-select
          [model]="user"
          [required]="true"
        ></app-permission-select>
      </div>
    </div>
  </div>
  <div class="col-md-6">
    <div
      *ngIf="
        domains &&
        domains.length > 0 &&
        authService.hasPermission('config.multiple-domains') &&
        userHasMultipleDomains
      "
      class="form-group clearfix"
    >
      <label class="col-sm-4 control-label" for="extra-domains"
        >Extra Domains</label
      >
      <div class="col-sm-8">
        <div *ngFor="let singleDomain of domains">
          <input
            (change)="toggleChosenDomain(singleDomain)"
            [checked]="domainIsChosen(singleDomain) !== false"
            type="checkbox"
          />
          {{ singleDomain.name }}
        </div>

        <hr />
      </div>
    </div>

    <app-shared-address [model]="user"></app-shared-address>

    <app-shared-contact-details
      [model]="user"
      [requiredFields]="['emailAddress']"
    ></app-shared-contact-details>
  </div>
</div>

<div class="form-group">
  <div class="col-sm-offset-2 col-sm-10 text-right">
    <button class="btn btn-success" type="submit">Save</button>
  </div>
</div>
