<div class="form-group clearfix">
  <label for="reference" class="col-sm-4 control-label">Income Type</label>
  <div class="col-sm-8">
    <app-income-select [required]="true" [model]="model"></app-income-select>
  </div>
</div>

<div class="form-group clearfix">
  <label for="reference" class="col-sm-4 control-label">Value</label>
  <div class="col-sm-8">
    <app-shared-currency
      [required]="true"
      [(ngModel)]="model.value"
    ></app-shared-currency>
  </div>
</div>

<div class="form-group clearfix">
  <label for="note" class="col-sm-4 control-label">Internal Note</label>
  <div class="col-sm-8">
    <textarea
      class="form-control"
      rows="5"
      id="note"
      name="note"
      [(ngModel)]="model.note"
    ></textarea>
  </div>
</div>

<div class="form-group clearfix">
  <label for="footnote" class="col-sm-4 control-label">Footnote</label>
  <div class="col-sm-8">
    <textarea
      class="form-control"
      rows="5"
      id="footnote"
      name="footnote"
      [(ngModel)]="model.footnote"
    ></textarea>
  </div>
</div>
