import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { NotificationService } from '../../shared/notification.service';
import { ComplaintStatus } from '../complaint-status';
import { ComplaintStatusService } from '../complaint-status.service';

@Component({
  selector: 'app-complaint-status-create',
  templateUrl: 'create.component.html',
})
export class ComplaintStatusCreateComponent implements OnInit {
  /**
   * Details of the complaintStatus being created.
   */
  public complaintStatus: ComplaintStatus;

  /**
   * Are we currently performing a loading action?
   *
   * @type {boolean}
   */
  public isLoading: boolean = false;

  /**
   * Load up all our required services and subscribe to every observable we need.
   *
   * @param {ComplaintStatusService} complaintStatusService
   * @param {NotificationService} notificationService
   * @param {Router} router
   */
  constructor(
    private complaintStatusService: ComplaintStatusService,
    private notificationService: NotificationService,
    private router: Router,
  ) {
    this.complaintStatusService.loading.subscribe(
      result => (this.isLoading = result),
    );
  }

  /**
   * Initialise the component, creating a new blank complaintStatus if required.
   */
  public ngOnInit(): void {
    if (!this.complaintStatus) {
      this.complaintStatus = new ComplaintStatus();
    }
  }

  /**
   * Perform the create action which adds the new complaintStatus into the backend.
   */
  public create() {
    const createComplaintStatus = this.complaintStatusService
      .createComplaintStatus(this.complaintStatus)
      .subscribe(
        (result: ComplaintStatus) => {
          this.notificationService.success(
            `ComplaintStatus Created`,
            `New complaintStatus ${result.name} has been created with ID of ${
              result.id
            }.`,
            5,
          );
          this.complaintStatus = new ComplaintStatus();
          createComplaintStatus.unsubscribe();
          this.router.navigate(['/complaint-statuses']);
        },
        err => {
          this.notificationService.error(
            `Could not create ComplaintStatus`,
            err.error.message,
            10,
          );
        },
      );
  }
}
