import { Component } from '@angular/core';
import { LetterheadService } from '../letterhead.service';
import { Letterhead } from '../letterhead';
import { ActivatedRoute, Router } from '@angular/router';
import { NotificationService } from '../../shared/notification.service';

@Component({
  selector: 'app-letterhead-edit',
  templateUrl: 'edit.component.html',
})
export class LetterheadEditComponent {
  /**
   * Details of the letterhead being edited.
   */
  public letterhead: Letterhead;

  /**
   * Are we currently performing a loading action?
   *
   * @type {boolean}
   */
  public isLoading: boolean = false;

  /**
   * Load up all our required services and subscribe to every observable we need.
   *
   * @param {LetterheadService} letterheadService
   * @param {NotificationService} notificationService
   * @param {Router} router
   * @param {ActivatedRoute} route
   */
  constructor(
    private letterheadService: LetterheadService,
    private notificationService: NotificationService,
    private router: Router,
    private route: ActivatedRoute,
  ) {
    this.letterheadService.loading.subscribe(
      result => (this.isLoading = result),
    );
    this.route.params.subscribe(params => this.loadLetterhead(params.id));
  }

  /**
   * Perform the edit action which send the edited letterhead to the backend.
   */
  public edit() {
    const editLetterhead = this.letterheadService
      .updateLetterhead(this.letterhead.id, this.letterhead)
      .subscribe(
        (result: Letterhead) => {
          this.notificationService.success(
            `Letterhead Updated`,
            `The letterhead ${
              result.name
            } been updated with the specified changes.`,
            5,
          );
          this.letterhead = new Letterhead();
          editLetterhead.unsubscribe();
          this.router.navigate(['/letterheads']);
        },
        err => {
          this.notificationService.error(
            `Letterhead Not Found`,
            err.error.message,
            10,
          );
          this.router.navigate(['/letterheads']);
        },
      );
  }

  /**
   * Loads the requested letterhead so we can display it and let the user edit the fields.
   *
   * @param {string} id
   */
  private loadLetterhead(id: string) {
    this.letterheadService.findLetterhead(id).subscribe(
      result => {
        this.letterhead = result;
      },
      err => {
        this.notificationService.error(
          `Letterhead Not Found`,
          err.error.message,
          10,
        );
        this.router.navigate(['/letterheads']);
      },
    );
  }
}
