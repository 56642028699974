import {Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';

import {LogService} from '../log.service';

declare let $: any;

@Component({
  selector: 'app-delete-modal',
  templateUrl: './delete-modal.component.html',
})
export class DeleteModalComponent implements OnInit {
  @Output() confirm = new EventEmitter<string>();
  @Output() cancel = new EventEmitter();

  @Input() type: string = undefined;
  @Input() itemDeleted: EventEmitter<boolean>;

  @ViewChild('deleteModalComponent') modal: ElementRef;

  private id: string = undefined;

  public ngOnInit(): void {
    this.itemDeleted.subscribe(result => {
      this.close();
    });
  }

  public confirmDialog(id: string) {
    this.id = id;
    this.open();
  }

  public confirmDeletion() {
    LogService.info(`Confirming Deletion of item with ID ${this.id}`);
    this.confirm.next(this.id);
  }

  public cancelDeletion() {
    this.cancel.next();
    LogService.info(`Cancelling Deletion of item with ID ${this.id}`);
    this.close();
  }

  public open() {
    $(this.modal.nativeElement).modal('show');
  }

  public close() {
    $(this.modal.nativeElement).modal('hide');
  }
}
