import { Component, Input, OnInit } from '@angular/core';
import { Case } from '../../cases/case';
import { FinancialSummary } from './financial-summary';

@Component({
  selector: 'app-shared-financial-summary',
  templateUrl: 'financial-summary.component.html',
  styleUrls: ['financial-summary.component.css'],
})
export class SharedFinancialSummaryComponent {
  /**
   * The model that we will be adding the portfolio to.
   */
  @Input() case: Case;

  @Input() direction: string = 'horizontal';

  @Input() summary: FinancialSummary;
}
