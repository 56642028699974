import { Component, Input } from '@angular/core';
import { Income } from '../income';

@Component({
  selector: 'app-income-form',
  templateUrl: 'form.component.html',
})
export class IncomeFormComponent {
  @Input() income: Income;
}
