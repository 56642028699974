import {Component, EventEmitter, Output, ViewChild} from '@angular/core';

import {CaseComplaint} from '../case-complaint';
import {CaseComplaintService} from '../case-complaint.service';

@Component({
  selector: 'app-case-complaint-view',
  templateUrl: 'view.component.html',
})
export class CaseComplaintViewComponent {
  @ViewChild('staticModal') staticModal;

  @Output() completed = new EventEmitter<boolean>();

  public disabled: boolean = false;

  public model: CaseComplaint;

  private givenCaseId: string;

  constructor(private readonly caseComplaintService: CaseComplaintService) {}

  public get caseId(): string {
    return this.givenCaseId;
  }

  public open(caseId: string, model: CaseComplaint): void {
    this.givenCaseId = caseId;
    this.model = model;
    this.staticModal.show();
  }

  public close() {
    this.workCompleted();
  }

  public confirmView($event) {
    $event.preventDefault();
    this.disabled = true;
    this.caseComplaintService
      .updateCaseComplaint(this.model.id, this.model, {
        parameters: { id: this.caseId },
      })
      .subscribe(() => {
        this.workCompleted();
      });
  }

  private workCompleted() {
    this.model = undefined;
    this.disabled = false;
    this.staticModal.hide();
    this.completed.next(true);
  }
}
