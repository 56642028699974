<div class="row">
  <div class="col-sm-12">
    <section class="panel">
      <app-page-title [loading]="isLoading" title="List Account Items">
        {{ totalResults }} Results Found

        <select [(ngModel)]="reconcileOptions" style="margin-left: 30px;">
          <option value="all">View All Entries</option>
          <option value="reconciled">Reconciled Entries</option>
          <option value="unreconciled">Unreconciled Entries</option>
        </select>

        <input
          [(ngModel)]="filter"
          placeholder="Search filter..."
          type="text"
        />

        <button (click)="onPageChanged(1)">Filter</button>
      </app-page-title>

      <div class="panel-body">
        <table class="table table-striped">
          <thead>
            <tr>
              <th>Date</th>
              <th>Reference</th>
              <th>Value</th>
              <th>Description</th>
              <th>Reconciled</th>
              <th class="text-right">
                <button
                  (click)="upload.open(accountId)"
                  class="btn btn-sm btn-success"
                  href="javascript:;"
                  type="button"
                >
                  Upload CSV
                </button>
              </th>
            </tr>
          </thead>

          <tbody *ngIf="accountItems && accountItems.length > 0">
            <tr *ngFor="let accountItem of accountItems">
              <td>{{ accountItem?.createdAt | date: 'dd/MM/yyyy' }}</td>
              <td>{{ accountItem?.reference }}</td>
              <td>
                {{ accountItem?.value / 100 | toAccountancy }}
                <div
                  *ngIf="
                    !accountItem?.reconciled && accountItem?.reconciledValue > 0
                  "
                  class="text-warning"
                >
                  <strong>Reconciled: </strong
                  >{{ accountItem?.reconciledValue / 100 | toAccountancy }}
                </div>

                <div
                  *ngIf="
                    !accountItem?.reconciled && accountItem?.reconciledValue > 0
                  "
                  class="text-danger"
                >
                  <strong>Remaining: </strong
                  >{{
                    (accountItem.value - accountItem.reconciledValue) / 100
                      | toAccountancy
                  }}
                </div>
              </td>
              <td>{{ accountItem?.description }}</td>
              <td>
                <div
                  *ngIf="
                    (!accountItem.reconciliations ||
                      accountItem.reconciliations.length === 0) &&
                    (!accountItem.otherReconciliations ||
                      accountItem.otherReconciliations.length === 0)
                  "
                >
                  <b class="text-warning" *ngIf="!accountItem.reconciled"
                    >Not Reconciled</b
                  >
                  <b class="text-primary" *ngIf="accountItem.reconciled"
                    >Manually Reconciled</b
                  >
                </div>

                <div
                  *ngIf="
                    accountItem.reconciliations &&
                    accountItem.reconciliations.length > 0
                  "
                >
                  <div
                    *ngFor="let rec of accountItem.reconciliations"
                    style="margin-bottom: 10px;"
                  >
                    <b>{{ rec?.linkedEntry?.value < 0 ? 'From' : 'To' }}: </b
                    ><a
                      [routerLink]="[
                        '/banking/accounts',
                        rec.linkedEntry.account.id,
                        'items'
                      ]"
                      >{{ rec?.linkedEntry?.account?.name }}</a
                    >
                    - <b>On:</b>
                    {{ rec?.linkedEntry?.createdAt | date: 'dd/MM/yyyy' }}<br />
                    <b>Value: </b
                    >{{ rec?.linkedEntry?.value / 100 | toAccountancy }}<br />
                    <b>Note: </b>{{ rec?.note }}<br />
                  </div>
                </div>

                <div
                  *ngIf="
                    accountItem.otherReconciliations &&
                    accountItem.otherReconciliations.length > 0
                  "
                >
                  <div
                    *ngFor="let rec of accountItem.otherReconciliations"
                    style="margin-bottom: 10px;"
                  >
                    <ng-container *ngIf="rec.case && rec.case.people">
                      <b>{{ rec?.linkedEntry?.value < 0 ? 'From' : 'To' }}: </b
                      ><a
                        [routerLink]="['/cases', rec.entryId]"
                        target="_blank"
                      >
                        <span *ngFor="let person of rec.case.people"
                          >{{ person?.firstName }} {{ person?.lastName }} ,
                        </span> </a
                      ><br />
                      <b>Value: </b
                      >{{ rec?.reconciledValue / 100 | toAccountancy }}<br />
                      <b>Note: </b>{{ rec?.note }}<br />
                    </ng-container>

                    <ng-container *ngIf="!rec.case || !rec.case.people">
                      <span class="text-danger">
                        Reconciled to <strong>DELETED</strong> case.
                      </span>
                    </ng-container>
                  </div>
                </div>
              </td>
              <td class="text-right">
                <div class="btn-group">
                  <a
                    [routerLink]="[
                      '/banking/account-items',
                      accountItem?.id,
                      'edit'
                    ]"
                    class="btn btn-sm btn-warning"
                    item="button"
                    >Edit</a
                  >
                  <button
                    aria-expanded="false"
                    aria-haspopup="true"
                    class="btn btn-sm btn-warning dropdown-toggle"
                    data-toggle="dropdown"
                    item="button"
                  >
                    <span class="caret"></span>
                    <span class="sr-only">Toggle Dropdown</span>
                  </button>
                  <ul class="dropdown-menu dropdown-menu-right">
                    <li
                      *ngIf="
                        !accountItem.reconciled &&
                        (accountItem.reconciledValue < accountItem.value ||
                          ((!accountItem.reconciliations ||
                            accountItem.reconciliations.length === 0) &&
                            (!accountItem.otherReconciliations ||
                              accountItem.otherReconciliations.length === 0)))
                      "
                    >
                      <a
                        (click)="
                          createReconciliationModal.confirmDialog({
                            id: accountItem.id,
                            value: accountItem.value / 100,
                            paidDate: accountItem.createdAt,
                            transaction: accountItem
                          })
                        "
                        href="javascript:"
                        >Reconcile to Account</a
                      >
                    </li>
                    <li
                      *ngIf="
                        !accountItem.reconciled &&
                        (accountItem.reconciledValue < accountItem.value ||
                          ((!accountItem.reconciliations ||
                            accountItem.reconciliations.length === 0) &&
                            (!accountItem.otherReconciliations ||
                              accountItem.otherReconciliations.length === 0)))
                      "
                    >
                      <a
                        (click)="
                          createClientReconciliationModal.confirmDialog({
                            id: accountItem.id,
                            value: accountItem.value / 100,
                            paidDate: accountItem.createdAt,
                            transaction: accountItem
                          })
                        "
                        href="javascript:"
                        >Reconcile to Client</a
                      >
                    </li>
                    <li
                      *ngIf="
                        !accountItem.reconciled &&
                        (accountItem.reconciledValue < accountItem.value ||
                          ((!accountItem.reconciliations ||
                            accountItem.reconciliations.length === 0) &&
                            (!accountItem.otherReconciliations ||
                              accountItem.otherReconciliations.length === 0)))
                      "
                    >
                      <a
                        (click)="
                          createExtraordinaryReconciliationModal.confirmDialog({
                            id: accountItem.id,
                            value: accountItem.value / 100,
                            paidDate: accountItem.createdAt,
                            transaction: accountItem
                          })
                        "
                        href="javascript:"
                        >Reconcile Extraordinary</a
                      >
                    </li>
                    <li
                      *ngIf="
                        !accountItem.reconciled &&
                        (accountItem.reconciledValue < accountItem.value ||
                          ((!accountItem.reconciliations ||
                            accountItem.reconciliations.length === 0) &&
                            (!accountItem.otherReconciliations ||
                              accountItem.otherReconciliations.length === 0)))
                      "
                    >
                      <a
                        (click)="
                          createClientLoanReconciliationModal.confirmDialog({
                            id: accountItem.id,
                            value: accountItem.value / 100,
                            paidDate: accountItem.createdAt,
                            transaction: accountItem
                          })
                        "
                        href="javascript:"
                        >Reconcile As Loan</a
                      >
                    </li>
                  </ul>
                </div>
              </td>
            </tr>
          </tbody>

          <tfoot>
            <tr>
              <td class="text-center" colspan="99">
                <a
                  [href]="downloadLink"
                  class="btn btn-danger btn-xs pull-right"
                  target="_blank"
                >
                  <i class="fa fa-download"></i>
                </a>

                <app-pagination
                  (pageChanged)="onPageChanged($event)"
                  [currentPage]="currentPage"
                  [totalPages]="totalPages"
                ></app-pagination>
              </td>
            </tr>
          </tfoot>
        </table>
      </div>
    </section>
  </div>
</div>

<app-delete-modal
  #deleteModal
  (confirm)="delete($event)"
  [itemDeleted]="accountItemDeleted"
  item="accountItem"
></app-delete-modal>

<app-financial-banking-list-upload
  #upload
  (completed)="onPageChanged(1)"
></app-financial-banking-list-upload>

<!-- Reconcile to another transaction -->

<app-create-modal
  #createReconciliationModal
  (cancel)="clearReconcile()"
  (confirm)="createReconciliation($event)"
  [itemCreated]="reconciliationCreated"
  type="reconciliation"
>
  <table class="table">
    <tr>
      <td>
        {{
          createReconciliationModal?.id?.transaction?.createdAt
            | date: 'dd/MM/yyyy'
        }}
      </td>
      <td>{{ createReconciliationModal?.id?.transaction?.reference }}</td>
      <td>
        {{
          (createReconciliationModal?.id?.transaction?.value -
            createReconciliationModal?.id?.transaction?.value) /
            100 | toAccountancy
        }}
      </td>
      <td>{{ createReconciliationModal?.id?.transaction?.description }}</td>
    </tr>
  </table>

  <div class="form-group clearfix">
    <label class="col-sm-3 control-label" for="reference">Account</label>
    <div class="col-sm-9">
      <app-banking-account-select
        [model]="accountSelect"
      ></app-banking-account-select>
    </div>
  </div>

  <div class="form-group clearfix">
    <label class="col-sm-3 control-label" for="reference">Reconciled</label>
    <div class="col-sm-9">
      <select [(ngModel)]="accountSelect.reconcileOptions" class="form-control">
        <option value="all">View All Entries</option>
        <option value="reconciled">Reconciled Entries</option>
        <option value="unreconciled">Unreconciled Entries</option>
      </select>
    </div>
  </div>

  <div
    *ngIf="
      createReconciliationModal.id && createReconciliationModal.id.transaction
    "
    class="form-group clearfix"
  >
    <label class="col-sm-3 control-label" for="reference">Search</label>
    <div class="col-sm-6">
      <input
        [(ngModel)]="createReconciliationModal.id.transaction.reference"
        class="form-control"
        placeholder="Search filter..."
        type="text"
      />
    </div>
    <button
      (click)="startReconcile()"
      class="btn btn-success col-sm-3"
      type="button"
    >
      Filter
    </button>
  </div>

  <app-banking-account-item-select
    *ngIf="
      createReconciliationModal.id &&
      createReconciliationModal.id.transaction &&
      reconcileReady
    "
    [reconcilingId]="createReconciliationModal?.id?.id"
    [accountId]="accountSelect.account"
    [expectedValue]="createReconciliationModal.id.value"
    [filter]="createReconciliationModal.id.transaction.reference"
    [reconcileOptions]="accountSelect.reconcileOptions"
    [reconcileSelected]="reconcileItems"
  ></app-banking-account-item-select>

  <div class="alert alert-warning" *ngIf="checkingMatchAllowed">
    <i class="fa fa-spinner fa-spin" style="margin-right: 10px;"></i>
    <strong>Please Wait:</strong>
    Checking if these transactions can be reconciled.
  </div>

  <div class="alert alert-success" *ngIf="matchAllowed">
    <i class="fa fa-check" style="margin-right: 10px;"></i>
    <strong>Please Continue:</strong>
    These transactions can be reconciled.
  </div>
</app-create-modal>

<!-- Reconcile to client -->
<app-create-modal
  #createClientReconciliationModal
  (cancel)="clearReconcile()"
  (confirm)="createClientReconciliation($event)"
  [itemCreated]="reconciliationCreated"
  type="client reconciliation"
>
  <table class="table">
    <tr>
      <td>
        {{
          createClientReconciliationModal?.id?.transaction?.createdAt
            | date: 'dd/MM/yyyy'
        }}
      </td>
      <td>{{ createClientReconciliationModal?.id?.transaction?.reference }}</td>
      <td>
        {{
          (createClientReconciliationModal?.id?.transaction?.value -
            createClientReconciliationModal?.id?.transaction?.reconciledValue) /
            100 | toAccountancy
        }}
      </td>
      <td>
        {{ createClientReconciliationModal?.id?.transaction?.description }}
      </td>
    </tr>
  </table>

  <div
    *ngIf="
      createClientReconciliationModal.id &&
      createClientReconciliationModal.id.transaction
    "
    class="form-group clearfix"
  >
    <label class="col-sm-3 control-label" for="reference">Search</label>
    <div class="col-sm-6">
      <input
        [(ngModel)]="createClientReconciliationModal.id.transaction.reference"
        class="form-control"
        placeholder="Search filter..."
        type="text"
      />
    </div>
    <button
      (click)="
        performSearch(createClientReconciliationModal.id.transaction.reference)
      "
      class="btn btn-success col-sm-3"
      type="button"
    >
      Filter
    </button>
  </div>

  <div *ngIf="foundCases.length > 0">
    <div class="row">
      <!--<div class="col-md-6" [class.text-danger]="makePositive(createClientReconciliationModal.id.value) !== makePositive(currentMonetaryValue)" [class.text-success]="makePositive(createClientReconciliationModal.id.value) === makePositive(currentMonetaryValue)"><b>Total Expected:</b> {{ createClientReconciliationModal.id.value | toAccountancy }}</div>-->
      <!--<div class="col-md-6 text-right" [class.text-danger]="makePositive(createClientReconciliationModal.id.value) !== makePositive(paymentDate && paymentDate.expectedValue ? paymentDate.expectedValue/100 : 0)" [class.text-success]="makePositive(createClientReconciliationModal.id.value) === makePositive(paymentDate && paymentDate.expectedValue ? paymentDate.expectedValue/100 : 0)"><b>Total Selected:</b> {{ (paymentDate && paymentDate.expectedValue ? paymentDate.expectedValue/100 : 0) | toAccountancy }}</div>-->
    </div>

    <table class="table table-striped">
      <thead>
        <tr>
          <th>Client</th>
          <th>Reference</th>
          <th>Payment Amount</th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let case of foundCases">
          <td>{{ case.case_name }}</td>
          <td>{{ case.unique_reference }}</td>
          <td>{{ case.di | toAccountancy }}</td>
          <td>
            <button
              (click)="getPaymentScheduleForCase(case.id)"
              class="btn btn-xs btn-success"
              type="button"
            >
              <i class="fa fa-check"></i>
            </button>
          </td>
        </tr>
      </tbody>
    </table>
  </div>

  <div *ngIf="paymentSchedule && paymentSchedule.length > 0" class="row">
    <div class="form-group">
      <label class="col-sm-4 control-label" for="expectedDate"
        >Related Schedule</label
      >
      <div class="col-sm-8">
        <select
          (ngModelChange)="
            checkPaymentDate(
              $event,
              createClientReconciliationModal?.id?.transaction?.createdAt
            )
          "
          [ngModel]="paymentDate"
          [required]="true"
          class="form-control"
          id="expectedDate"
          name="expectedDate"
        >
          <option value="">--- Please choose a payment date to link to</option>
          <option *ngFor="let item of paymentSchedule" [ngValue]="item"
            >{{ item.expectedDate | date: 'dd/MM/yyyy' }} -
            {{ item.expectedValue / 100 | toCurrency }} -
            {{ item.status }}</option
          >
        </select>
      </div>
    </div>

    <div class="form-group">
      <label class="col-sm-4 control-label" for="description">Note</label>
      <div class="col-sm-8">
        <input
          [(ngModel)]="note"
          class="form-control"
          id="description"
          name="description"
          placeholder="Note"
          type="text"
        />
      </div>
    </div>
  </div>
</app-create-modal>

<!-- Reconcile ExtraOrdinary Income -->

<app-create-modal
  #createExtraordinaryReconciliationModal
  (cancel)="clearReconcile()"
  (confirm)="createExtraordinaryReconciliation($event)"
  [disabled]="invoiceIsRequired"
  [itemCreated]="reconciliationCreated"
  type="extraordinary reconciliation"
>
  <div *ngIf="uploader?.queue?.length < 1" class="form-group clearfix">
    <label
      [class.text-danger]="invoiceIsRequired"
      class="col-sm-2 control-label"
      for="file"
      >File</label
    >

    <label class="col-sm-10" for="fileButton">
      <div
        (fileOver)="fileOverBase($event)"
        [ngClass]="{ 'nv-file-over': hasBaseDropZoneOver }"
        [uploader]="uploader"
        class="well my-drop-zone"
        ng2FileDrop
      >
        <div *ngFor="let item of uploader.queue">
          <strong>{{ item?.file?.name }}</strong>
        </div>

        <div *ngIf="uploader?.queue?.length < 1">
          Drag a file (or click here) to choose a file to upload.
        </div>

        <input
          [uploader]="uploader"
          id="fileButton"
          ng2FileSelect
          style="display: none;"
          type="file"
        />
      </div>
    </label>
  </div>

  <div
    *ngIf="uploader?.queue?.length >= 1"
    class="progress text-center"
    style="margin-bottom: 0;"
  >
    Ready To Upload {{ (uploader?.queue)[0]?.file?.name }}
    <div
      [ngStyle]="{ width: uploader.progress + '%' }"
      class="progress-bar"
      role="progressbar"
    ></div>
  </div>

  <table class="table">
    <thead>
      <tr>
        <th>
          {{
            createExtraordinaryReconciliationModal?.id?.transaction?.createdAt
              | date: 'dd/MM/yyyy'
          }}
        </th>
        <th>
          {{
            createExtraordinaryReconciliationModal?.id?.transaction?.reference
          }}
        </th>
        <th>
          {{
            (createExtraordinaryReconciliationModal?.id?.transaction?.value -
              createExtraordinaryReconciliationModal?.id?.transaction
                ?.reconciledValue) /
              100 | toAccountancy
          }}
        </th>
        <th colspan="2">
          {{
            createExtraordinaryReconciliationModal?.id?.transaction?.description
          }}
        </th>
      </tr>
    </thead>
    <tr *ngFor="let selected of eoSelectedClients; let i = index">
      <td>{{ selected.case.case_name }}</td>
      <td>{{ selected?.incomeType?.name }}</td>
      <td>{{ selected.value / 100 | toAccountancy }}</td>
      <td>{{ selected.feeValue / 100 | toAccountancy }}</td>
      <td>
        {{ selected.supsPercentage }}% ({{
          (selected.value * (selected.supsPercentage / 100)) / 100
            | toAccountancy
        }})
        <button
          (click)="deleteSelectedItemAtIndex(i)"
          class="btn btn-danger btn-xs pull-right"
        >
          <i class="fa fa-trash"></i>
        </button>
      </td>
    </tr>
    <tr>
      <th>Total Reconciled:</th>
      <th>{{ totalReconciled / 100 | toAccountancy }}</th>
      <th>&nbsp;</th>
      <th>Total Remaining:</th>
      <th>
        {{
          (createExtraordinaryReconciliationModal?.id?.transaction?.value -
            createExtraordinaryReconciliationModal?.id?.transaction
              ?.reconciledValue -
            totalReconciled) /
            100 | toAccountancy
        }}
      </th>
    </tr>
  </table>

  <div
    *ngIf="
      createExtraordinaryReconciliationModal.id &&
      createExtraordinaryReconciliationModal.id.transaction
    "
    class="form-group clearfix"
  >
    <label class="col-sm-3 control-label" for="reference">Search</label>
    <div class="col-sm-6">
      <input
        [(ngModel)]="
          createExtraordinaryReconciliationModal.id.transaction.reference
        "
        class="form-control"
        placeholder="Search filter..."
        type="text"
      />
    </div>
    <button
      (click)="
        performSearch(
          createExtraordinaryReconciliationModal.id.transaction.reference
        )
      "
      class="btn btn-success col-sm-3"
      type="button"
    >
      Filter
    </button>
  </div>

  <!--<div *ngIf="!(foundCases.length > 0)">-->
  <!--No results found for this search filter.-->
  <!--</div>-->

  <table *ngIf="foundCases.length > 0" class="table">
    <tbody>
      <tr>
        <th>Case</th>
        <td colspan="99">
          <select
            (change)="caseChanged()"
            [(ngModel)]="eoSelected.case"
            class="form-control"
          >
            <option *ngFor="let case of foundCases" [ngValue]="case">{{
              case.case_name
            }}</option>
          </select>
        </td>
      </tr>

      <tr *ngIf="eoSelected.case.case_name">
        <th>Type</th>
        <th>&nbsp;</th>
        <th>Value</th>
        <th>Fee</th>
        <th>Sups %</th>
        <th>&nbsp;</th>
      </tr>

      <tr *ngIf="eoSelected.case.case_name">
        <td colspan="2">
          <app-income-type-select [model]="eoSelected"></app-income-type-select>
        </td>
        <td>
          <input
            [(ngModel)]="eoSelected.value"
            class="form-control"
            step="0.01"
            type="number"
          />
        </td>
        <td>
          <input
            [(ngModel)]="eoSelected.feeValue"
            class="form-control"
            step="0.01"
            type="number"
          />
        </td>
        <td>
          <input
            [(ngModel)]="eoSelected.supsPercentage"
            class="form-control"
            step="0.01"
            type="number"
          />
        </td>
        <td>
          <button (click)="addEoSelected()" class="btn btn-success">Add</button>
        </td>
      </tr>
    </tbody>
  </table>
</app-create-modal>

<!-- Reconcile to Loan -->

<app-create-modal
  #createClientLoanReconciliationModal
  (cancel)="clearReconcile()"
  (confirm)="createClientLoanReconciliation($event)"
  [itemCreated]="reconciliationCreated"
  type="client reconciliation"
>
  <table class="table">
    <tr>
      <td>
        {{
          createClientLoanReconciliationModal?.id?.transaction?.createdAt
            | date: 'dd/MM/yyyy'
        }}
      </td>
      <td>
        {{ createClientLoanReconciliationModal?.id?.transaction?.reference }}
      </td>
      <td>
        {{
          (createClientLoanReconciliationModal?.id?.transaction?.value -
            createClientLoanReconciliationModal?.id?.transaction
              ?.reconciledValue) /
            100 | toAccountancy
        }}
      </td>
      <td>
        {{ createClientLoanReconciliationModal?.id?.transaction?.description }}
      </td>
    </tr>
  </table>

  <div
    *ngIf="
      createClientLoanReconciliationModal.id &&
      createClientLoanReconciliationModal.id.transaction
    "
    class="form-group clearfix"
  >
    <label class="col-sm-3 control-label" for="reference">Search</label>
    <div class="col-sm-6">
      <input
        [(ngModel)]="
          createClientLoanReconciliationModal.id.transaction.reference
        "
        class="form-control"
        placeholder="Search filter..."
        type="text"
      />
    </div>
    <button
      (click)="
        performSearch(
          createClientLoanReconciliationModal.id.transaction.reference
        )
      "
      class="btn btn-success col-sm-3"
      type="button"
    >
      Filter
    </button>
  </div>

  <div *ngIf="foundCases.length > 0">
    <div class="row">
      <!--<div class="col-md-6" [class.text-danger]="makePositive(createClientLoanReconciliationModal.id.value) !== makePositive(currentMonetaryValue)" [class.text-success]="makePositive(createClientLoanReconciliationModal.id.value) === makePositive(currentMonetaryValue)"><b>Total Expected:</b> {{ createClientLoanReconciliationModal.id.value | toAccountancy }}</div>-->
      <!--<div class="col-md-6 text-right" [class.text-danger]="makePositive(createClientLoanReconciliationModal.id.value) !== makePositive(paymentDate && paymentDate.expectedValue ? paymentDate.expectedValue/100 : 0)" [class.text-success]="makePositive(createClientLoanReconciliationModal.id.value) === makePositive(paymentDate && paymentDate.expectedValue ? paymentDate.expectedValue/100 : 0)"><b>Total Selected:</b> {{ (paymentDate && paymentDate.expectedValue ? paymentDate.expectedValue/100 : 0) | toAccountancy }}</div>-->
    </div>

    <table class="table table-striped">
      <thead>
        <tr>
          <th>Client</th>
          <th>Reference</th>
          <th>Payment Amount</th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let case of foundCases">
          <td>{{ case.case_name }}</td>
          <td>{{ case.unique_reference }}</td>
          <td>{{ case.di | toAccountancy }}</td>
          <td>
            <button
              (click)="selectCase(case.id)"
              class="btn btn-xs"
              type="button"
              [class.btn-warning]="!chosenClientLink"
              [class.btn-success]="chosenClientLink"
            >
              <i class="fa fa-check"></i>
            </button>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</app-create-modal>
