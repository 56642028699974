<table class="annuals table table-striped">
  <thead>
  <tr>
    <th>Due Date</th>
    <th>Completed On</th>
    <th></th>
  </tr>
  </thead>
  <tbody>
  <tr *ngFor="let caseAnnual of caseAnnuals">
    <td>{{ caseAnnual?.dueOn | date:"dd/MM/yyyy" }}</td>
    <td *ngIf="caseAnnual.completedOn">{{ caseAnnual?.completedOn | date:"dd/MM/yyyy" }}</td>
    <td *ngIf="!caseAnnual.completedOn"><span [class.text-danger]="!isInTheFuture(caseAnnual.dueOn)">{{ isInTheFuture(caseAnnual.dueOn) ? 'Not Yet Due' : 'Overdue' }}</span></td>
    <td class="text-right">
      <div class="btn-group">
        <a type="button" class="btn btn-xs btn-warning" (click)="edit.open(caseId, caseAnnual)">Complete</a>
      </div>
    </td>
  </tr>
  </tbody>
  <tfoot>
  <tr>
    <td colspan="99" class="text-center">
      <app-pagination [totalPages]="totalPages" [currentPage]="currentPage"
                      (pageChanged)="onPageChanged($event)"></app-pagination>
    </td>
  </tr>
  </tfoot>
</table>


<app-case-annual-create (completed)="onPageChanged(1)" #create></app-case-annual-create>

<app-case-annual-edit (completed)="onPageChanged(1)" #edit></app-case-annual-edit>

<app-delete-modal type="annual" (confirm)="delete($event)" [itemDeleted]="itemDeleted"
                  #deleteModal></app-delete-modal>
