<section class="panel">
  <header class="panel-heading tab-bg-light">
    Annuals Due Report

    <a [href]="downloadReportUrl" target="_blank" class="btn btn-danger btn-xs pull-right">
      <i class="fa fa-download"></i>
    </a>

  </header>

  <div class="panel-body">
    <table class="table table-striped">
      <thead>
      <tr>
        <th>Reference</th>
        <th>Name</th>
        <th>Status</th>
        <th>IVA Approved</th>
        <th>Annual Due</th>
        <th>&nbsp;</th>
      </tr>
      </thead>
      <tbody>
      <tr *ngFor="let item of detailedReport" [class.text-danger]="isInPast(item.due_on)">
        <td>{{ item.unique_reference }}</td>
        <td>{{ item.first_name }} {{ item.last_name }}</td>
        <td>{{ item.status }}</td>
        <td>{{ item.approved_on | date:'dd/MM/yyyy' }}</td>
        <td>{{ item.due_on | date:'dd/MM/yyyy' }}</td>
        <td class="text-right">
          <div class="btn-group btn-group-sm" role="group" aria-label="...">
            <a [routerLink]="['/cases', item.id]" target="_blank" class="btn btn-success btn-sm"><i class="fa fa-eye"></i></a>
          </div>
        </td>
      </tr>
      </tbody>
    </table>
  </div>

</section>

