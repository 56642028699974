import { useState } from 'react';

type UseFormState<T> = [
  T,
  (key: keyof T, value: any) => void,
  (item: T) => void,
];

const useForm = <T>(initialObject: T): UseFormState<T> => {
  const [formObject, setFormObject] = useState<T>(initialObject);

  const setFormItem = (key: keyof T, value: any) => {
    const currentObject = {...formObject};
    currentObject[key] = value;

    setFormObject(currentObject);
  };

  return [formObject, setFormItem, setFormObject];
};

export default useForm;
