import {
  ChangeDetectorRef,
  NgZone,
  OnDestroy,
  Pipe,
  PipeTransform,
} from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Pipe({ name: 'safe' })
export class SafePipe implements PipeTransform {
  constructor(private sanitizer: DomSanitizer) {}

  transform(url) {
    return this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }
}

@Pipe({ name: 'toAccountancy' })
export class ToAccountancyPipe implements PipeTransform {
  transform(value: number, args: string[]): any {
    let newCurrencyValue = value < 0 ? value * -1 : value;
    let currencyValue: string = newCurrencyValue
      .toFixed(2)
      .toString()
      .replace(/\B(?=(\d{3})+(?!\d))/g, ',');

    return value < 0 ? `(£${currencyValue})` : `£${currencyValue}`;
  }
}

@Pipe({ name: 'toCurrency' })
export class ToCurrencyPipe implements PipeTransform {
  transform(value: number, args: string[]): any {
    return (
      '£' +
      value
        .toFixed(2)
        .toString()
        .replace(/\B(?=(\d{3})+(?!\d))/g, ',')
    );
  }
}

@Pipe({ name: 'age' })
export class AgePipe implements PipeTransform {
  transform(value: string, args: string[]): any {
    const timeDiff = Math.abs(Date.now() - new Date(value).getTime());
    return Math.floor(timeDiff / (1000 * 3600 * 24) / 365);
  }
}

@Pipe({
  name: 'capitalizeFirst',
})
export class CapitalizeFirstPipe implements PipeTransform {
  transform(value: string, args: any[]): string {
    if (value === null) return 'Not assigned';
    return value.charAt(0).toUpperCase() + value.slice(1);
  }
}

@Pipe({
  name: 'stripHTML',
})
export class StripHtmlPipe implements PipeTransform {
  transform(value: string): any {
    return value && value !== undefined && value !== null
      ? value.replace(/<.*?>/g, '')
      : value;
  }
}

@Pipe({
  name: 'timeAgo',
  pure: false,
})
export class TimeAgoPipe implements PipeTransform, OnDestroy {
  private timer: number;

  constructor(
    private changeDetectorRef: ChangeDetectorRef,
    private ngZone: NgZone,
  ) {}

  transform(value: string) {
    this.removeTimer();
    const d = new Date(value);
    const now = new Date();

    const future = now.getTime() - d.getTime() < 0;

    const seconds = Math.round(Math.abs((now.getTime() - d.getTime()) / 1000));
    const timeToUpdate = this.getSecondsUntilUpdate(seconds) * 1000;
    this.timer = this.ngZone.runOutsideAngular(() => {
      if (typeof window !== 'undefined') {
        return window.setTimeout(() => {
          this.ngZone.run(() => this.changeDetectorRef.markForCheck());
        }, timeToUpdate);
      }
      return null;
    });
    const minutes = Math.round(Math.abs(seconds / 60));
    const hours = Math.round(Math.abs(minutes / 60));
    const days = Math.round(Math.abs(hours / 24));
    const months = Math.round(Math.abs(days / 30.416));
    const years = Math.round(Math.abs(days / 365));
    if (seconds <= 45) {
      return future ? `in a few seconds` : `a few seconds ago`;
    } else if (seconds <= 90) {
      return future ? `in a minute` : `a minute ago`;
    } else if (minutes <= 45) {
      return future ? `in ${minutes} minutes` : `${minutes} minutes ago`;
    } else if (minutes <= 90) {
      return future ? `in an hour` : 'an hour ago';
    } else if (hours <= 22) {
      return future ? `in ${hours} hours` : `${hours} hours ago`;
    } else if (hours <= 36) {
      return future ? `in a day` : 'a day ago';
    } else if (days <= 25) {
      return future ? `in ${days} days` : `${days} days ago`;
    } else if (days <= 45) {
      return future ? `in a month` : 'a month ago';
    } else if (days <= 345) {
      return future ? `in ${months} days` : `${months} months ago`;
    } else if (days <= 545) {
      return future ? `in a year` : 'a year ago';
    } else {
      // (days > 545)
      return future ? `in ${years} years` : `${years} years ago`;
    }
  }

  ngOnDestroy(): void {
    this.removeTimer();
  }

  private removeTimer() {
    if (this.timer) {
      window.clearTimeout(this.timer);
      this.timer = null;
    }
  }

  private getSecondsUntilUpdate(seconds: number) {
    const min = 60;
    const hr = min * 60;
    const day = hr * 24;
    if (seconds < min) {
      // less than 1 min, update ever 2 secs
      return 2;
    } else if (seconds < hr) {
      // less than an hour, update every 30 secs
      return 30;
    } else if (seconds < day) {
      // less then a day, update every 5 mins
      return 300;
    } else {
      // update every hour
      return 3600;
    }
  }
}
