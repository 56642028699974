<div *ngIf="isLoading">Loading Department List...</div>

<select
  *ngIf="!isLoading"
  [(ngModel)]="model.department"
  (change)="optionChanged.emit(true)"
  [compareWith]="byId"
  [required]="required"
  [class.form-control]="!noStyle"
  name="department"
>
  <option
    *ngFor="let singleDepartment of departments"
    [ngValue]="singleDepartment"
  >
    {{ singleDepartment.name }}
  </option>
</select>
