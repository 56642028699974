import { Component } from '@angular/core';
import { PermissionService } from '../permission.service';
import { Permission } from '../permission';
import { ActivatedRoute, Router } from '@angular/router';
import { NotificationService } from '../../shared/notification.service';

@Component({
  selector: 'app-permission-edit',
  templateUrl: 'edit.component.html',
})
export class PermissionEditComponent {
  /**
   * Details of the permission being edited.
   */
  public permission: Permission;

  /**
   * Are we currently performing a loading action?
   *
   * @type {boolean}
   */
  public isLoading: boolean = false;

  /**
   * Load up all our required services and subscribe to every observable we need.
   *
   * @param {PermissionService} permissionService
   * @param {NotificationService} notificationService
   * @param {Router} router
   * @param {ActivatedRoute} route
   */
  constructor(
    private permissionService: PermissionService,
    private notificationService: NotificationService,
    private router: Router,
    private route: ActivatedRoute,
  ) {
    this.permissionService.loading.subscribe(
      result => (this.isLoading = result),
    );
    this.route.params.subscribe(params => this.loadPermission(params.id));
  }

  /**
   * Perform the edit action which send the edited permission to the backend.
   */
  public edit() {
    const editPermission = this.permissionService
      .updatePermission(this.permission.id, this.permission)
      .subscribe(
        (result: Permission) => {
          this.notificationService.success(
            `Permission Updated`,
            `The permission ${
              result.name
            } been updated with the specified changes.`,
            5,
          );
          this.permission = new Permission();
          editPermission.unsubscribe();
          this.router.navigate(['/permissions']);
        },
        err => {
          this.notificationService.error(
            `Permission Not Found`,
            err.error.message,
            10,
          );
          this.router.navigate(['/permissions']);
        },
      );
  }

  /**
   * Loads the requested permission so we can display it and let the user edit the fields.
   *
   * @param {string} id
   */
  private loadPermission(id: string) {
    this.permissionService.findPermission(id).subscribe(
      result => {
        this.permission = result;
      },
      err => {
        this.notificationService.error(
          `Permission Not Found`,
          err.error.message,
          10,
        );
        this.router.navigate(['/permissions']);
      },
    );
  }
}
