<div class="row">
  <div class="col-sm-12">
    <section class="panel">
      <app-page-title
        title="Create Complaint Status"
        [loading]="isLoading"
      ></app-page-title>

      <div class="panel-body">
        <form class="form-horizontal" (ngSubmit)="create()" ngNativeValidate>
          <app-complaint-status-form
            [complaintStatus]="complaintStatus"
            *ngIf="complaintStatus"
          ></app-complaint-status-form>
        </form>
      </div>
    </section>
  </div>
</div>
