import { CaseStatus } from './case-status';
import { HttpOptions } from '../../shared/http/http-options';
import { HttpRequestService } from '../../shared/http/http-request.service';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable()
export class CaseStatusService extends HttpRequestService {
  /**
   * The endpoint that we call to get the case information.
   *
   * @type {string}
   */
  private readonly endpoint: string = 'cases/:id/statuses';

  /**
   * Brings back a list of all accessible cases.
   *
   * @param {number} page The page number we are currently on.
   * @param {number} limit The number of cases to display per page.
   * @returns {Observable<CaseStatus[]>}
   */
  public listCaseStatuses(
    page?: number,
    limit?: number,
    options?: HttpOptions,
  ): Observable<CaseStatus[]> {
    return this.list(this.endpoint, page, undefined, limit, options);
  }

  /**
   * Finds details of the case from the given ID.
   *
   * @param {string} caseId The ID of the case we require details for.
   * @returns {Observable<CaseStatus>}
   */
  public findCaseStatus(
    caseId: string,
    options?: HttpOptions,
  ): Observable<CaseStatus> {
    return this.findById(this.endpoint, caseId, options);
  }

  /**
   * Creates a new case from the given Case object.
   *
   * @param {CaseStatus} newCase The details of the case to be created.
   * @returns {Observable<CaseStatus | boolean>}
   */
  public createCaseStatus(
    newCase: any,
    options?: HttpOptions,
  ): Observable<CaseStatus | boolean> {
    return this.create(this.endpoint, newCase, options);
  }

  /**
   * Updates a case with the given case object for the given case ID.
   *
   * @param {string} caseId The ID of the case needing updating.
   * @param {CaseStatus} newDetails The new case object to update with.
   * @returns {Observable<Case | boolean>}
   */
  public updateCaseStatus(
    caseId: string,
    newDetails: CaseStatus,
    options?: HttpOptions,
  ): Observable<CaseStatus | boolean> {
    return this.update(this.endpoint, caseId, newDetails, options);
  }

  /**
   * Deletes a case based on the given case ID.
   *
   * @param {string} caseId The ID of the case needing deleting.
   * @returns {Observable<boolean>}
   */
  public deleteCaseStatus(
    caseId: string,
    options?: HttpOptions,
  ): Observable<boolean> {
    return this.delete(this.endpoint, caseId, options);
  }
}
