import { Component, forwardRef, Input } from '@angular/core';
import { CustomDate } from './date';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'app-shared-date',
  templateUrl: 'date.component.html',
  styleUrls: ['date.component.css'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SharedDateComponent),
      multi: true,
    },
  ],
})
export class SharedDateComponent implements ControlValueAccessor {
  public chosenDate: CustomDate = undefined;
  @Input() disabled: boolean = false;
  @Input() required: boolean;
  @Input() allowFuture: boolean = false;
  @Input() allowPast: boolean = true;
  @Input() hasTime: boolean = false;
  @Input() hasDate: boolean = true;
  // Function to call when the rating changes.
  onChange = (date: string) => {};
  // Function to call when the input is touched (when a star is clicked).
  onTouched = () => {};
  private readonly longMonths: number[] = [4, 6, 9, 11];
  private readonly currentYear: number = Number(new Date().getFullYear());

  public changeDateItem(dateItem: string, $event): void {
    // console.log('changeDateItem', dateItem);
    this.chosenDate[dateItem] = $event.target.value;
    this.writeValue(this.parseCustomDateToStringDate(this.chosenDate));
  }

  public dayCheck(month: number): number {
    if (this.longMonths.indexOf(this.chosenDate.month) >= 0) {
      return 30;
    } else if (
      this.chosenDate.month === 2 &&
      this.chosenDate.year % 4 === 0 &&
      (this.chosenDate.year % 100 !== 0 || this.chosenDate.year % 400 === 0)
    ) {
      return 29;
    } else if (this.chosenDate.month === 2) {
      return 28;
    } else {
      return 31;
    }
  }

  public minYearCheck(): number {
    if (this.allowPast) {
      return 1800;
    } else {
      return this.currentYear;
    }
  }

  public maxYearCheck(): number {
    if (this.allowFuture) {
      return 3000;
    } else {
      return this.currentYear;
    }
  }

  public isRequired(): boolean {
    return this.required;
  }

  // Allows Angular to update the model (rating).
  // Update the model and changes needed for the view here.
  writeValue(date: string): void {
    // console.log('writeValue', date);
    this.chosenDate = this.parseStringDateToCustomDate(date);
    this.onChange(this.parseCustomDateToStringDate(this.chosenDate));
  }

  // Save the function as a property to call later here.
  registerOnChange(fn: (date: string) => void): void {
    this.onChange = fn;
  }

  // Save the function as a property to call later here.
  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  // Allows Angular to register a function to call when the model (rating) changes.

  private parseCustomDateToStringDate(date: CustomDate): string {
    // console.log('parseCustomDateToStringDate', date);
    return `${('0' + date.year).substr(-4)}-${('0' + date.month).substr(-2)}-${(
      '0' + date.day
    ).substr(-2)}T${('0' + date.hour).substr(-2)}:${('0' + date.minute).substr(
      -2,
    )}.000Z`;
  }

  // Allows Angular to register a function to call when the input has been touched.

  private parseStringDateToCustomDate(date: string): CustomDate {
    // console.log('parseStringDateToCustomDate1', date);
    if (!date) {
      return {
        day: Number(new Date().getDate()),
        month: Number(new Date().getMonth()) + 1,
        year: this.currentYear,
        hour: 10,
        minute: 0,
      };
    }

    // console.log('parseStringDateToCustomDate2', date);

    const splitTimestamp: string[] = date.split('T').join(' ').split(' ');
    const dateSplit: string[] = splitTimestamp[0].split('-');
    const timeSplit: string[] = !splitTimestamp[1]
      ? []
      : splitTimestamp[1].split(':');

    // console.log(
    //   'parseStringDateToCustomDate3',
    //   date,
    //   splitTimestamp,
    //   dateSplit,
    // );

    return {
      day: Number(dateSplit[2]),
      month: Number(dateSplit[1]),
      year: Number(dateSplit[0]),
      hour: timeSplit[0] ? Number(timeSplit[0]) : 10,
      minute: timeSplit[1] ? Number(timeSplit[1]) : 0,
    };
  }
}
