import { Component, OnInit } from '@angular/core';
import { BankingAccountService } from '../banking-account.service';
import { BankingAccount } from '../banking-account';
import { Router } from '@angular/router';
import { NotificationService } from '../../../../shared/notification.service';

@Component({
  selector: 'app-banking-account-create',
  templateUrl: 'create.component.html',
})
export class BankingAccountCreateComponent implements OnInit {
  /**
   * Details of the account being created.
   */
  public account: BankingAccount;

  /**
   * Are we currently performing a loading action?
   *
   * @type {boolean}
   */
  public isLoading: boolean = false;

  /**
   * Load up all our required services and subscribe to every observable we need.
   *
   * @param {BankingAccountService} accountService
   * @param {NotificationService} notificationService
   * @param {Router} router
   */
  constructor(
    private accountService: BankingAccountService,
    private notificationService: NotificationService,
    private router: Router,
  ) {
    this.accountService.loading.subscribe(
      result => (this.isLoading = result),
    );
  }

  /**
   * Initialise the component, creating a new blank account if required.
   */
  public ngOnInit(): void {
    if (!this.account) {
      this.account = new BankingAccount();
    }
  }

  /**
   * Perform the create action which adds the new account into the backend.
   */
  public create() {
    const createBankingAccount = this.accountService
      .createBankingAccount(this.account)
      .subscribe(
        (result: BankingAccount) => {
          this.notificationService.success(
            `BankingAccount Created`,
            `New account ${result.name} has been created with ID of ${
              result.id
            }.`,
            5,
          );
          this.account = new BankingAccount();
          createBankingAccount.unsubscribe();
          this.router.navigate(['/banking/accounts']);
        },
        err => {
          this.notificationService.error(
            `Could not create BankingAccount`,
            err.error.message,
            10,
          );
        },
      );
  }
}
