import { RouterModule, Routes } from '@angular/router';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule } from '@angular/common/http';
import { AssetListComponent } from './list/list.component';
import { AuthGuard } from '../auth/auth.guard';
import { AssetService } from './asset.service';
import { SharedModule } from '../shared/shared.module';
import { AssetCreateComponent } from './create/create.component';
import { AssetFormComponent } from './form/form.component';
import { AssetEditComponent } from './edit/edit.component';

const routes: Routes = [
  { path: 'assets', component: AssetListComponent, canActivate: [AuthGuard] },
  {
    path: 'assets/create',
    component: AssetCreateComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'assets/:id/edit',
    component: AssetEditComponent,
    canActivate: [AuthGuard],
  },
];

@NgModule({
  declarations: [
    AssetListComponent,
    AssetCreateComponent,
    AssetFormComponent,
    AssetEditComponent,
  ],
  imports: [
    BrowserModule,
    RouterModule,
    FormsModule,
    HttpClientModule,
    SharedModule,
    RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' }),
  ],
  exports: [RouterModule],
  providers: [AuthGuard, AssetService],
})
export class AssetRoutingModule {}
