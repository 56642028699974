import {Component, EventEmitter, Output, ViewChild} from '@angular/core';
import {DomSanitizer} from '@angular/platform-browser';
import * as moment from 'moment';

import {CaseDocument} from '../case-document';
import {CaseDocumentService} from '../case-document.service';

@Component({
  selector: 'app-case-document-approve',
  templateUrl: 'approve.component.html',
})
export class CaseDocumentApproveComponent {
  @ViewChild('staticModal') staticModal;

  @Output() completed = new EventEmitter<boolean>();

  public disabled: boolean = false;

  public model: CaseDocument;

  private givenCaseId: string;

  constructor(
    private readonly caseDocumentService: CaseDocumentService,
    public sanitizer: DomSanitizer,
  ) {}

  public get url() {
    if (!this.model || !this.model.url) {
      return undefined;
    }
    return `https://drive.google.com/viewerng/viewer?embedded=true&url=${encodeURIComponent(
      this.model.url,
    )}`;
  }

  public get caseId(): string {
    return this.givenCaseId;
  }

  public open(caseId: string, model: CaseDocument): void {
    this.givenCaseId = caseId;
    this.model = model;
    this.staticModal.show();
  }

  public close() {
    this.workCompleted();
  }

  public confirmApprove($event) {
    $event.preventDefault();
    this.disabled = true;
    this.model.approvedAt = moment().format('YYYY-MM-DD HH:mm:ss');
    this.caseDocumentService
      .updateCaseDocument(this.model.id, this.model, {
        parameters: { id: this.caseId },
      })
      .subscribe(() => {
        this.workCompleted();
      });
  }

  private workCompleted() {
    this.model = undefined;
    this.disabled = false;
    this.staticModal.hide();
    this.completed.next(true);
  }
}
