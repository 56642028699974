import {Injectable} from '@angular/core';
import {Department} from './department';
import {Observable} from 'rxjs';

import {HttpRequestService} from '../../shared/http/http-request.service';

@Injectable()
export class DepartmentService extends HttpRequestService {
    /**
     * The endpoint that we call to get the department information.
     *
     * @type {string}
     */
    private readonly endpoint: string = 'departments';

    /**
     * Brings back a list of all accessible departments.
     *
     * @param {number} page The page number we are currently on.
     * @param {number} limit The number of departments to display per page.
     * @returns {Observable<Department[]>}
     */
    public listDepartments(page?: number, limit?: number): Observable<Department[]> {
        return this.list(this.endpoint, page, undefined, limit);
    }

    /**
     * Finds details of the department from the given ID.
     *
     * @param {string} departmentId The ID of the department we require details for.
     * @returns {Observable<Department>}
     */
    public findDepartment(departmentId: string): Observable<Department> {
        return this.findById(this.endpoint, departmentId);
    }

    /**
     * Creates a new department from the given Department object.
     *
     * @param {Department} newDepartment The details of the department to be created.
     * @returns {Observable<Department | boolean>}
     */
    public createDepartment(newDepartment: Department): Observable<Department | boolean> {
        return this.create(this.endpoint, newDepartment);
    }

    /**
     * Updates a department with the given department object for the given department ID.
     *
     * @param {string} departmentId The ID of the department needing updating.
     * @param {Department} newDetails The new department object to update with.
     * @returns {Observable<Department | boolean>}
     */
    public updateDepartment(
        departmentId: string,
        newDetails: Department,
    ): Observable<Department | boolean> {
        return this.update(this.endpoint, departmentId, newDetails);
    }

    /**
     * Deletes a department based on the given department ID.
     *
     * @param {string} departmentId The ID of the department needing deleting.
     * @returns {Observable<boolean>}
     */
    public deleteDepartment(departmentId: string): Observable<boolean> {
        return this.delete(this.endpoint, departmentId);
    }
}
