import { Component, Input, OnInit } from '@angular/core';

import { NotificationService } from '../../shared/notification.service';
import { SelectComparisons } from '../../shared/pipes/select.comparisons';
import { Substatus } from '../substatus';
import { SubstatusService } from '../substatus.service';

@Component({
  selector: 'app-substatus-select',
  templateUrl: 'select.component.html',
})
export class SubstatusSelectComponent
  extends SelectComparisons
  implements OnInit {
  /**
   * The model that we will be adding the substatus to.
   */
  @Input() model: any;

  /**
   * Do we require this field in the main form it is
   * included in?
   */
  @Input() required: boolean;

  /**
   * A list of all the substatuses currently loaded.
   *
   * @type {Substatus[]}
   */
  public substatuses: Substatus[] = [];

  /**
   * Are we currently performing a loading action?
   *
   * @type {boolean}
   */
  public isLoading: boolean = true;

  /**
   * Load up all our required services and subscribe to every observable we need.
   *
   * @param {SubstatusService} substatusService
   * @param {NotificationService} notificationService
   */
  constructor(
    private substatusService: SubstatusService,
    private notificationService: NotificationService,
  ) {
    super();
    this.substatusService.loading.subscribe((isLoading) => {
      this.isLoading = isLoading;
    });
  }

  /**
   * When the component is initialised we set it to load
   * page one of the substatus list.
   */
  public ngOnInit(): void {
    this.loadSubstatuses(1, 10000);
  }

  /**
   * Loads a list of substatuses using the given per page limit.
   *
   * @param {number} page The current page we require.
   * @param {number} limit The number of results per page.
   */
  public loadSubstatuses(page?: number, limit?: number): void {
    const listSubstatuses = this.substatusService
      .listSubstatuses(page, limit)
      .subscribe((substatuses: Substatus[]) => {
        this.substatuses = substatuses;
        listSubstatuses.unsubscribe();
      });
  }
}
