import {HttpClientModule} from '@angular/common/http';
import {NgModule} from '@angular/core';
import {FormsModule} from '@angular/forms';
import {BrowserModule} from '@angular/platform-browser';
import {ModalModule} from 'ngx-bootstrap/modal';
import {TooltipModule} from 'ngx-bootstrap/tooltip';
import {QuillModule} from 'ngx-quill';

import {SharedModule} from '../../shared/shared.module';
import {CasePersonService} from '../people/case-person.service';
import {CaseCreditorService} from './case-creditor.service';
import {CaseCreditorCreateComponent} from './create/create.component';
import {CaseCreditorDistributionsService} from './distributions/distributions.service';
import {CaseCreditorEditComponent} from './edit/edit.component';
import {CaseCreditorFormComponent} from './form/form.component';
import {CaseCreditorListComponent} from './list/list.component';

@NgModule({
  declarations: [
    CaseCreditorFormComponent,
    CaseCreditorCreateComponent,
    CaseCreditorEditComponent,
    CaseCreditorListComponent,
  ],
  imports: [
    ModalModule.forRoot(),
    TooltipModule.forRoot(),
    QuillModule,
    BrowserModule,
    FormsModule,
    HttpClientModule,
    SharedModule,
  ],
  exports: [CaseCreditorListComponent],
  providers: [
    CaseCreditorService,
    CasePersonService,
    CaseCreditorDistributionsService,
  ],
})
export class CaseCreditorRoutingModule {}
