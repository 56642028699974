<div *ngIf="isLoading">Loading Pack List...</div>

<select *ngIf="!isLoading && model && model !== undefined" [compareWith]="byId" class="form-control" name="pack"
        [(ngModel)]="model.pack" [required]="required">
  <option *ngFor="let singlePack of packs" [ngValue]="singlePack">{{ singlePack.name }}</option>
</select>

<select *ngIf="!isLoading && (!model || model === undefined)" [compareWith]="byId" class="form-control" name="pack"
        ngModel [required]="required">
  <option *ngFor="let singlePack of packs" [ngValue]="singlePack">{{ singlePack.name }}</option>
</select>
