import { Component, Input, OnInit } from '@angular/core';
import { TemplateService } from '../template.service';
import { Template } from '../template';
import { NotificationService } from '../../shared/notification.service';
import { SelectComparisons } from '../../shared/pipes/select.comparisons';

@Component({
  selector: 'app-template-select',
  templateUrl: 'select.component.html',
})
export class TemplateSelectComponent extends SelectComparisons
  implements OnInit {
  /**
   * The model that we will be adding the template to.
   */
  @Input() model: any;

  /**
   * Do we require this field in the main form it is
   * included in?
   */
  @Input() required: boolean;

  /**
   * A list of all the templates currently loaded.
   *
   * @type {Template[]}
   */
  public templates: Template[] = [];

  /**
   * Are we currently performing a loading action?
   *
   * @type {boolean}
   */
  public isLoading: boolean = true;

  /**
   * Load up all our required services and subscribe to every observable we need.
   *
   * @param {TemplateService} templateService
   * @param {NotificationService} notificationService
   */
  constructor(
    private templateService: TemplateService,
    private notificationService: NotificationService,
  ) {
    super();
    this.templateService.loading.subscribe(isLoading => {
      this.isLoading = isLoading;
    });
  }

  /**
   * When the component is initialised we set it to load
   * page one of the template list.
   */
  public ngOnInit(): void {
    this.loadTemplates(1, 10000);
  }

  /**
   * Loads a list of templates using the given per page limit.
   *
   * @param {number} page The current page we require.
   * @param {number} limit The number of results per page.
   */
  public loadTemplates(page?: number, limit?: number): void {
    const listTemplates = this.templateService
      .listTemplates(page, limit)
      .subscribe((templates: Template[]) => {
        this.templates = templates
          .map((template: Template) => {
            // Remove the template content as it's not needed for a simple select
            template.content = undefined;

            return template;
          })
          .filter(() => true);
        listTemplates.unsubscribe();
      });
  }
}
