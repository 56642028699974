import { Component, EventEmitter, OnInit } from '@angular/core';
import { SupplierService } from '../supplier.service';
import { Supplier } from '../supplier';
import { NotificationService } from '../../../shared/notification.service';

@Component({
  selector: 'app-supplier-list',
  templateUrl: 'list.component.html',
  styleUrls: [],
})
export class SupplierListComponent implements OnInit {
  public supplier: Supplier;

  /**
   * A list of all the suppliers currently loaded.
   *
   * @type {Supplier[]}
   */
  public suppliers: Supplier[] = [];

  /**
   * Are we currently performing a loading action?
   *
   * @type {boolean}
   */
  public isLoading: boolean = true;

  /**
   * The total number of pages in this list of suppliers.
   *
   * @type {number}
   */
  public totalPages: number = 1;

  /**
   * The current page of suppliers that we are viewing.
   *
   * @type {number}
   */
  public currentPage: number = 1;

  /**
   * The total number of suppliers that can be viewed in the system.
   *
   * @type {number}
   */
  public totalResults: number = 0;

  /**
   * An Emitter to alert other components when a supplier has been deleted.
   *
   * @type {EventEmitter<boolean>}
   */
  public supplierDeleted: EventEmitter<boolean> = new EventEmitter<boolean>();

  /**
   * Load up all our required services and subscribe to every observable we need.
   *
   * @param {SupplierService} supplierService
   * @param {NotificationService} notificationService
   */
  constructor(
    private supplierService: SupplierService,
    private notificationService: NotificationService,
  ) {
    this.supplierService.loading.subscribe(isLoading => {
      this.isLoading = isLoading;
    });
    this.supplierService.totalPages.subscribe(totalPages => {
      this.totalPages = totalPages;
    });
    this.supplierService.currentPage.subscribe(currentPage => {
      this.currentPage = currentPage;
    });
    this.supplierService.totalResults.subscribe(totalResults => {
      this.totalResults = totalResults;
    });
  }

  /**
   * When the component is initialised we set it to load
   * page one of the supplier list.
   */
  public ngOnInit(): void {
    this.loadSuppliers(1);
  }

  /**
   * When we receive a page change from the pagination
   * component we load the appropriate page for the
   * supplier list.
   *
   * @param {number} pageNumber
   */
  public onPageChanged(pageNumber: number): void {
    this.loadSuppliers(pageNumber);
  }

  /**
   * Loads a list of suppliers using the given per page limit.
   *
   * @param {number} page The current page we require.
   * @param {number} limit The number of results per page.
   */
  public loadSuppliers(page?: number, limit?: number): void {
    const listSuppliers = this.supplierService
      .listSuppliers(page, limit)
      .subscribe((suppliers: Supplier[]) => {
        this.suppliers = suppliers;
        listSuppliers.unsubscribe();
      });
  }

  /**
   * When receiving confirmation of an item being deleted
   * we call the back end with the appropriate supplier ID
   * to delete the supplier.
   *
   * @param {string} id
   */
  public delete(id: string) {
    this.supplierService.deleteSupplier(id).subscribe(
      deleted => {
        this.notificationService.success(
          `Supplier Deleted`,
          `Supplier with ID ${id} was deleted.`,
          5,
        );
        this.supplierDeleted.next(true);
        this.onPageChanged(this.currentPage);
      },
      err => {
        this.notificationService.error(
          `Supplier Not Deleted`,
          err.error.message,
          10,
        );
        this.supplierDeleted.next(false);
        this.onPageChanged(this.currentPage);
      },
    );
  }
}
