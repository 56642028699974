import { Case } from '../case';
import { Income } from '../../incomes/income';
import { Person } from '../people/person';
import { User } from '../../users/user';

export class CaseIncome {
  public id?: string;
  public case?: Case;
  public user?: User;
  public income?: Income;
  public value: number = 0;
  public note?: string;
  public footnote?: string;
  public createdAt?: string;
}
