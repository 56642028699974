import {Component, EventEmitter, Input, OnDestroy, OnInit} from '@angular/core';
import {BsModalRef} from 'ngx-bootstrap/modal';

import {Modification} from '../../../settings/modifications/modification';
import {NotificationService} from '../../../shared/notification.service';
import {CaseModificationService} from '../modification.service';

@Component({
  selector: 'app-case-modification-alert',
  templateUrl: 'alert.component.html',
})
export class CaseModificationAlertComponent implements OnInit, OnDestroy {
  @Input() caseId: string;
  @Input() caseRefreshed: EventEmitter<string>;

  modificationsUpdated: EventEmitter<string>;

  public modifications: Modification[] = [];
  public customModifications: any[] = [];
  public activeModifications: Modification[] = [];
  public overrideModifications: Modification[] = [];

  private modificationSubscription: any;

  modalRef: BsModalRef;

  constructor(
    private readonly service: CaseModificationService,
    protected readonly notification: NotificationService,
  ) {}

  ngOnInit(): void {
    this.caseRefreshed.subscribe((id) => {
      this.caseId = id;
      this.loadModifications();
    });
    this.loadModifications();
    // this.modificationsUpdated.subscribe(item => {
    //   this.loadModifications();
    // });
  }

  loadModifications() {
    this.customModifications = [];
    this.activeModifications = [];
    this.modificationSubscription = this.service
      .listModifications(1, 1000, {
        parameters: {
          id: this.caseId,
        },
      })
      .subscribe((result: any) => {
        this.modifications = result.modifications;
        this.customModifications = result.customModifications;
        this.activeModifications = result.modifications.filter(
          item => item.active === true,
        );
        this.overrideModifications = result.modifications.filter(
          item => item.override === true,
        );
      });
  }

  ngOnDestroy(): void {
    this.modificationSubscription.unsubscribe();
  }
}
