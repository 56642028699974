import { Component, Input, OnInit } from '@angular/core';
import { CreditService } from '../credit.service';
import { Credit } from '../credit';
import { NotificationService } from '../../shared/notification.service';
import { SelectComparisons } from '../../shared/pipes/select.comparisons';

@Component({
  selector: 'app-credit-select',
  templateUrl: 'select.component.html',
})
export class CreditSelectComponent extends SelectComparisons implements OnInit {
  /**
   * The model that we will be adding the credit to.
   */
  @Input() model: any;

  /**
   * Do we require this field in the main form it is
   * included in?
   */
  @Input() required: boolean;

  /**
   * A list of all the credits currently loaded.
   *
   * @type {Credit[]}
   */
  public credits: Credit[] = [];

  /**
   * Are we currently performing a loading action?
   *
   * @type {boolean}
   */
  public isLoading: boolean = true;

  /**
   * Load up all our required services and subscribe to every observable we need.
   *
   * @param {CreditService} creditService
   * @param {NotificationService} notificationService
   */
  constructor(
    private creditService: CreditService,
    private notificationService: NotificationService,
  ) {
    super();
    this.creditService.loading.subscribe(isLoading => {
      this.isLoading = isLoading;
    });
  }

  /**
   * When the component is initialised we set it to load
   * page one of the credit list.
   */
  public ngOnInit(): void {
    this.loadCredits(1, 10000);
  }

  /**
   * Loads a list of credits using the given per page limit.
   *
   * @param {number} page The current page we require.
   * @param {number} limit The number of results per page.
   */
  public loadCredits(page?: number, limit?: number): void {
    const listCredits = this.creditService
      .listCredits(page, limit)
      .subscribe((credits: Credit[]) => {
        this.credits = credits;
        listCredits.unsubscribe();
      });
  }
}
