import { Component, OnInit } from '@angular/core';
import { ExpenditureService } from '../expenditure.service';
import { Expenditure } from '../expenditure';
import { Router } from '@angular/router';
import { NotificationService } from '../../shared/notification.service';

@Component({
  selector: 'app-expenditure-create',
  templateUrl: 'create.component.html',
})
export class ExpenditureCreateComponent implements OnInit {
  /**
   * Details of the expenditure being created.
   */
  public expenditure: Expenditure;

  /**
   * Are we currently performing a loading action?
   *
   * @type {boolean}
   */
  public isLoading: boolean = false;

  /**
   * Load up all our required services and subscribe to every observable we need.
   *
   * @param {ExpenditureService} expenditureService
   * @param {NotificationService} notificationService
   * @param {Router} router
   */
  constructor(
    private expenditureService: ExpenditureService,
    private notificationService: NotificationService,
    private router: Router,
  ) {
    this.expenditureService.loading.subscribe(
      result => (this.isLoading = result),
    );
  }

  /**
   * Initialise the component, creating a new blank expenditure if required.
   */
  public ngOnInit(): void {
    if (!this.expenditure) {
      this.expenditure = new Expenditure();
    }
  }

  /**
   * Perform the create action which adds the new expenditure into the backend.
   */
  public create() {
    const createExpenditure = this.expenditureService
      .createExpenditure(this.expenditure)
      .subscribe(
        (result: Expenditure) => {
          this.notificationService.success(
            `Expenditure Created`,
            `New expenditure ${result.name} has been created with ID of ${
              result.id
            }.`,
            5,
          );
          this.expenditure = new Expenditure();
          createExpenditure.unsubscribe();
          this.router.navigate(['/expenditures']);
        },
        err => {
          this.notificationService.error(
            `Could not create Expenditure`,
            err.error.message,
            10,
          );
        },
      );
  }
}
