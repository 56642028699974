import {Component, EventEmitter, Output, ViewChild} from '@angular/core';
import {FileItem, FileUploader, ParsedResponseHeaders} from 'ng2-file-upload';

import {environment} from '../../../../environments/environment';
import {AuthService} from '../../../auth/auth.service';
import {CaseDocument} from '../case-document';

@Component({
  selector: 'app-case-document-upload',
  templateUrl: 'upload.component.html',
})
export class CaseDocumentUploadComponent {
  @ViewChild('staticModal') staticModal;

  @Output() completed = new EventEmitter<boolean>();

  public uploader: FileUploader;
  public hasBaseDropZoneOver: boolean = false;

  public disabled: boolean = false;
  public newDocument: CaseDocument = new CaseDocument();
  private givenCaseId: string;

  constructor(private readonly authService: AuthService) {
    this.createUploader();
  }

  public get caseId(): string {
    return this.givenCaseId;
  }

  public fileOverBase(e: any): void {
    this.hasBaseDropZoneOver = e;
  }

  upload() {
    this.disabled = true;
    this.uploader.options.additionalParameter = {
      caseId: this.caseId,
      documentId: this.newDocument ? this.newDocument.document.id : undefined,
      deliveryMethod: this.newDocument.deliveryMethod
        ? this.newDocument.deliveryMethod.id
        : undefined,
      creditorId: this.newDocument.creditor
        ? this.newDocument.creditor.id
        : undefined,
    };
    this.uploader.uploadAll();
  }

  public open(caseId: string): void {
    this.givenCaseId = caseId;
    this.staticModal.show();
  }

  public close() {
    this.workCompleted();
  }

  public confirmCreate($event) {
    $event.preventDefault();
    this.disabled = true;
  }

  private createUploader() {
    this.uploader = new FileUploader({
      url: `${environment.endpoint}uploads`,
      authToken: `Bearer ${this.authService.getToken()}`,
    });

    this.uploader.onBeforeUploadItem = (item) => {
      item.withCredentials = false;
    };

    this.uploader.onSuccessItem = (
      item: FileItem,
      response: string,
      status: number,
      headers: ParsedResponseHeaders,
    ) => {
      if (response) {
        this.workCompleted();
      }
    };
  }

  private workCompleted() {
    this.newDocument = new CaseDocument();
    this.createUploader();
    this.disabled = false;
    this.staticModal.hide();
    this.completed.next(true);
  }
}
