import { Component, EventEmitter, OnInit } from '@angular/core';
import { ResidentialStatusService } from '../residential-status.service';
import { ResidentialStatus } from '../residential-status';
import { NotificationService } from '../../shared/notification.service';

@Component({
  selector: 'app-residential-status-list',
  templateUrl: 'list.component.html',
  styleUrls: [],
})
export class ResidentialStatusListComponent implements OnInit {
  public residentialStatus: ResidentialStatus;

  /**
   * A list of all the residential-statuses currently loaded.
   *
   * @type {ResidentialStatus[]}
   */
  public residentialStatuses: ResidentialStatus[] = [];

  /**
   * Are we currently performing a loading action?
   *
   * @type {boolean}
   */
  public isLoading: boolean = true;

  /**
   * The total number of pages in this list of residential-statuses.
   *
   * @type {number}
   */
  public totalPages: number = 1;

  /**
   * The current page of residential-statuses that we are viewing.
   *
   * @type {number}
   */
  public currentPage: number = 1;

  /**
   * The total number of residential-statuses that can be viewed in the system.
   *
   * @type {number}
   */
  public totalResults: number = 0;

  /**
   * An Emitter to alert other components when a residentialStatus has been deleted.
   *
   * @type {EventEmitter<boolean>}
   */
  public residentialStatusDeleted: EventEmitter<boolean> = new EventEmitter<
    boolean
  >();

  /**
   * Load up all our required services and subscribe to every observable we need.
   *
   * @param {ResidentialStatusService} residentialStatusService
   * @param {NotificationService} notificationService
   */
  constructor(
    private residentialStatusService: ResidentialStatusService,
    private notificationService: NotificationService,
  ) {
    this.residentialStatusService.loading.subscribe(isLoading => {
      this.isLoading = isLoading;
    });
    this.residentialStatusService.totalPages.subscribe(totalPages => {
      this.totalPages = totalPages;
    });
    this.residentialStatusService.currentPage.subscribe(currentPage => {
      this.currentPage = currentPage;
    });
    this.residentialStatusService.totalResults.subscribe(totalResults => {
      this.totalResults = totalResults;
    });
  }

  /**
   * When the component is initialised we set it to load
   * page one of the residentialStatus list.
   */
  public ngOnInit(): void {
    this.loadResidentialStatuses(1);
  }

  /**
   * When we receive a page change from the pagination
   * component we load the appropriate page for the
   * residentialStatus list.
   *
   * @param {number} pageNumber
   */
  public onPageChanged(pageNumber: number): void {
    this.loadResidentialStatuses(pageNumber);
  }

  /**
   * Loads a list of residential-statuses using the given per page limit.
   *
   * @param {number} page The current page we require.
   * @param {number} limit The number of results per page.
   */
  public loadResidentialStatuses(page?: number, limit?: number): void {
    const listResidentialStatuses = this.residentialStatusService
      .listResidentialStatuses(page, limit)
      .subscribe((residentialStatuses: ResidentialStatus[]) => {
        this.residentialStatuses = residentialStatuses;
        listResidentialStatuses.unsubscribe();
      });
  }

  /**
   * When receiving confirmation of an item being deleted
   * we call the back end with the appropriate residentialStatus ID
   * to delete the residentialStatus.
   *
   * @param {string} id
   */
  public delete(id: string) {
    this.residentialStatusService.deleteResidentialStatus(id).subscribe(
      deleted => {
        this.notificationService.success(
          `ResidentialStatus Deleted`,
          `ResidentialStatus with ID ${id} was deleted.`,
          5,
        );
        this.residentialStatusDeleted.next(true);
        this.onPageChanged(this.currentPage);
      },
      err => {
        this.notificationService.error(
          `ResidentialStatus Not Deleted`,
          err.error.message,
          10,
        );
        this.residentialStatusDeleted.next(false);
        this.onPageChanged(this.currentPage);
      },
    );
  }
}
