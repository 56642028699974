import {HttpClientModule} from '@angular/common/http';
import {NgModule} from '@angular/core';
import {FormsModule} from '@angular/forms';
import {BrowserModule} from '@angular/platform-browser';
import {ModalModule} from 'ngx-bootstrap/modal';
import {TooltipModule} from 'ngx-bootstrap/tooltip';
import {QuillModule} from 'ngx-quill';

import {SharedModule} from '../../shared/shared.module';
import {CasePersonService} from '../people/case-person.service';
import {CaseIncomeService} from './case-income.service';
import {CaseIncomeCreateComponent} from './create/create.component';
import {CaseIncomeEditComponent} from './edit/edit.component';
import {CaseIncomeStraightFormComponent} from './form/form.component';
import {CaseIncomeListComponent} from './list/list.component';

@NgModule({
  declarations: [
    CaseIncomeStraightFormComponent,
    CaseIncomeCreateComponent,
    CaseIncomeEditComponent,
    CaseIncomeListComponent,
  ],
  imports: [
    ModalModule.forRoot(),
    TooltipModule.forRoot(),
    QuillModule,
    BrowserModule,
    FormsModule,
    HttpClientModule,
    SharedModule,
  ],
  exports: [CaseIncomeListComponent],
  providers: [CaseIncomeService, CasePersonService],
})
export class CaseIncomeRoutingModule {}
