import { RouterModule, Routes } from '@angular/router';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule } from '@angular/common/http';
import { IncomeTypeListComponent } from './list/list.component';
import { AuthGuard } from '../../auth/auth.guard';
import { IncomeTypeService } from './income-type.service';
import { IncomeTypeCreateComponent } from './create/create.component';
import { IncomeTypeFormComponent } from './form/form.component';
import { IncomeTypeEditComponent } from './edit/edit.component';
import { SharedModule } from '../../shared/shared.module';

const routes: Routes = [
  {
    path: 'income-types',
    component: IncomeTypeListComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'income-types/create',
    component: IncomeTypeCreateComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'income-types/:id/edit',
    component: IncomeTypeEditComponent,
    canActivate: [AuthGuard],
  },
];

@NgModule({
  declarations: [
    IncomeTypeListComponent,
    IncomeTypeCreateComponent,
    IncomeTypeFormComponent,
    IncomeTypeEditComponent,
  ],
  imports: [
    BrowserModule,
    RouterModule,
    FormsModule,
    HttpClientModule,
    SharedModule,
    RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' }),
  ],
  exports: [RouterModule],
  providers: [AuthGuard, IncomeTypeService],
})
export class IncomeTypeRoutingModule {}
