<div class="col-md-{{ size }}" class="item clearfix">

  <ng-container *ngIf="isLoading">
    Loading {{ item.name }}...
  </ng-container>

  <ng-container *ngIf="!isLoading">
    <h4 class="category-item-title">{{ item.name }}</h4>

    <app-shared-currency class="category-item-value" [(ngModel)]="foundItem.value"
                         (change)="valueChanged()"></app-shared-currency>

    <textarea class="form-control category-item-note" [(ngModel)]="foundItem.note" name="note"
              (change)="valueChanged()"></textarea>

    <button class="btn btn-danger btn-block category-item-save" (click)="save()" *ngIf="!isSaved">Save Changes!</button>
  </ng-container>

</div>
