import {Component, EventEmitter, Output, ViewChild} from '@angular/core';

import {CaseExpenditure} from '../case-expenditure';
import {CaseExpenditureService} from '../case-expenditure.service';

@Component({
  selector: 'app-case-expenditure-edit',
  templateUrl: 'edit.component.html',
})
export class CaseExpenditureEditComponent {
  @ViewChild('staticModal') staticModal;

  @Output() completed = new EventEmitter<boolean>();

  public disabled: boolean = false;

  public model: CaseExpenditure;

  private givenCaseId: string;

  constructor(
    private readonly caseExpenditureService: CaseExpenditureService,
  ) {}

  public get caseId(): string {
    return this.givenCaseId;
  }

  public open(caseId: string, model: CaseExpenditure): void {
    this.givenCaseId = caseId;
    this.model = model;
    this.staticModal.show();
  }

  public close() {
    this.workCompleted();
  }

  public confirmEdit($event) {
    $event.preventDefault();
    this.disabled = true;
    this.caseExpenditureService
      .updateCaseExpenditure(this.model.id, this.model, {
        parameters: { id: this.caseId },
      })
      .subscribe(() => {
        this.workCompleted();
      });
  }

  private workCompleted() {
    this.model = undefined;
    this.disabled = false;
    this.staticModal.hide();
    this.completed.next(true);
  }
}
