import { Component, Input } from '@angular/core';
import { FinancialAccount } from '../accounts';

@Component({
  selector: 'app-financial-accounts-form',
  templateUrl: 'form.component.html',
})
export class FinancialAccountsFormComponent {
  @Input() financialAccount: FinancialAccount;
}
