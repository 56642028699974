import { Component, Input, OnInit } from '@angular/core';
import { EmploymentStatus } from '../employment-status';
import { SelectComparisons } from '../../shared/pipes/select.comparisons';
import { NgRedux, select } from '@angular-redux/store';
import { Observable } from 'rxjs/Observable';
import { ReduxStore } from '../../common/redux.store';
import { EmploymentStatusActions } from '../../common/settings/employment-status/employment-status.actions';

@Component({
  selector: 'app-employment-status-select',
  templateUrl: 'select.component.html',
})
export class EmploymentStatusSelectComponent extends SelectComparisons implements OnInit {
  @select(['employmentStatuses', 'selectList']) public employmentStatuses$: Observable<EmploymentStatus[]>;
  @Input() model: any;
  @Input() required: boolean;

  constructor(private readonly ngRedux: NgRedux<ReduxStore>, private readonly actions: EmploymentStatusActions) {
    super();
  }

  public ngOnInit(): void {
    this.ngRedux.dispatch<any>(this.actions.selectAll());
  }
}
