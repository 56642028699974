import { Portfolio } from '../../portfolios/portfolio';

export class Modification {
  public id?: string;
  public portfolio: Portfolio;
  public name: string;
  public description: string;
  public active?: boolean;
  public override?: boolean;
}
