import {Component, EventEmitter, Output, ViewChild} from '@angular/core';

import {CaseAsset} from '../case-asset';
import {CaseAssetService} from '../case-asset.service';

@Component({
  selector: 'app-case-asset-edit',
  templateUrl: 'edit.component.html',
})
export class CaseAssetEditComponent {
  @ViewChild('staticModal') staticModal;

  @Output() completed = new EventEmitter<boolean>();

  public disabled: boolean = false;

  public model: CaseAsset;

  private givenCaseId: string;

  constructor(private readonly caseAssetService: CaseAssetService) {}

  public get caseId(): string {
    return this.givenCaseId;
  }

  public open(caseId: string, model: CaseAsset): void {
    this.givenCaseId = caseId;
    this.model = model;
    this.staticModal.show();
  }

  public close() {
    this.workCompleted();
  }

  public confirmEdit($event) {
    $event.preventDefault();
    this.disabled = true;
    this.caseAssetService
      .updateCaseAsset(this.model.id, this.model, {
        parameters: { id: this.caseId },
      })
      .subscribe(() => {
        this.workCompleted();
      });
  }

  private workCompleted() {
    this.model = undefined;
    this.disabled = false;
    this.staticModal.hide();
    this.completed.next(true);
  }
}
