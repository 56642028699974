import { Injectable } from '@angular/core';
import { Folder } from './folder';
import { Observable } from 'rxjs';

import { HttpRequestService } from '../../shared/http/http-request.service';

@Injectable()
export class FolderService extends HttpRequestService {
  /**
   * The endpoint that we call to get the folder information.
   *
   * @type {string}
   */
  private readonly endpoint: string = 'folders';

  /**
   * Brings back a list of all accessible folders.
   *
   * @param {number} page The page number we are currently on.
   * @param {number} limit The number of folders to display per page.
   * @returns {Observable<Folder[]>}
   */
  public listFolders(page?: number, limit?: number): Observable<Folder[]> {
    return this.list(this.endpoint, page, undefined, limit);
  }

  /**
   * Finds details of the folder from the given ID.
   *
   * @param {string} folderId The ID of the folder we require details for.
   * @returns {Observable<Folder>}
   */
  public findFolder(folderId: string): Observable<Folder> {
    return this.findById(this.endpoint, folderId);
  }

  /**
   * Creates a new folder from the given Folder object.
   *
   * @param {Folder} newFolder The details of the folder to be created.
   * @returns {Observable<Folder | boolean>}
   */
  public createFolder(newFolder: Folder): Observable<Folder | boolean> {
    return this.create(this.endpoint, newFolder);
  }

  /**
   * Updates a folder with the given folder object for the given folder ID.
   *
   * @param {string} folderId The ID of the folder needing updating.
   * @param {Folder} newDetails The new folder object to update with.
   * @returns {Observable<Folder | boolean>}
   */
  public updateFolder(
    folderId: string,
    newDetails: Folder,
  ): Observable<Folder | boolean> {
    return this.update(this.endpoint, folderId, newDetails);
  }

  /**
   * Deletes a folder based on the given folder ID.
   *
   * @param {string} folderId The ID of the folder needing deleting.
   * @returns {Observable<boolean>}
   */
  public deleteFolder(folderId: string): Observable<boolean> {
    return this.delete(this.endpoint, folderId);
  }
}
