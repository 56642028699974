import { Component, OnInit } from '@angular/core';
import { AccountTypeService } from '../account-type.service';
import { AccountType } from '../account-type';
import { Router } from '@angular/router';
import { NotificationService } from '../../../shared/notification.service';

@Component({
  selector: 'app-account-type-create',
  templateUrl: 'create.component.html',
})
export class AccountTypeCreateComponent implements OnInit {
  /**
   * Details of the accountType being created.
   */
  public accountType: AccountType;

  /**
   * Are we currently performing a loading action?
   *
   * @type {boolean}
   */
  public isLoading: boolean = false;

  /**
   * Load up all our required services and subscribe to every observable we need.
   *
   * @param {AccountTypeService} accountTypeService
   * @param {NotificationService} notificationService
   * @param {Router} router
   */
  constructor(
    private accountTypeService: AccountTypeService,
    private notificationService: NotificationService,
    private router: Router,
  ) {
    this.accountTypeService.loading.subscribe(
      result => (this.isLoading = result),
    );
  }

  /**
   * Initialise the component, creating a new blank accountType if required.
   */
  public ngOnInit(): void {
    if (!this.accountType) {
      this.accountType = new AccountType();
    }
  }

  /**
   * Perform the create action which adds the new accountType into the backend.
   */
  public create() {
    const createAccountType = this.accountTypeService
      .createAccountType(this.accountType)
      .subscribe(
        (result: AccountType) => {
          this.notificationService.success(
            `AccountType Created`,
            `New accountType ${result.name} has been created with ID of ${
              result.id
            }.`,
            5,
          );
          this.accountType = new AccountType();
          createAccountType.unsubscribe();
          this.router.navigate(['/account-types']);
        },
        err => {
          this.notificationService.error(
            `Could not create AccountType`,
            err.error.message,
            10,
          );
        },
      );
  }
}
