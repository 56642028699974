<!-- Start Send Button -->
<button (click)="openModal(communicateSendModal)" class="btn btn-{{ buttonSize }}  btn-{{ buttonColor }}" tooltip="Contact this Client">
  <i class="fa fa-paper-plane" aria-hidden="true"></i>
</button>
<!-- End Send Button -->


<!-- Start Send Modal -->
<ng-template #communicateSendModal>
  <form class="form-horizontal" (submit)="sendMessage()" ngNativeValidate>
    <div class="modal-header">
      <h4 class="modal-title pull-left">Send a Message</h4>
      <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">

      <div class="form-group">
        <label for="template" class="col-sm-3 control-label">Template</label>
        <div class="col-sm-9">
          <app-template-select name="template" id="template" [model]="contact"></app-template-select>
        </div>
      </div>

      <div class="form-group" *ngIf="contact.template === undefined || contact.template.id === undefined">
        <label for="type" class="col-sm-3 control-label">Type</label>
        <div class="col-sm-9">
          <select class="form-control" id="type" name="type" [(ngModel)]="contact.type" [required]="true">
            <option value="sms">Text Message</option>
            <option value="email">E-Mail</option>
          </select>
        </div>
      </div>

      <div class="form-group" *ngIf="(contact.template === undefined || contact.template.id === undefined) && contact.type === 'email'">
        <label for="subject" class="col-sm-3 control-label">Subject</label>
        <div class="col-sm-9">
          <input type="text" id="subject" name="subject" class="form-control" [(ngModel)]="contact.subject" [required]="true">
        </div>
      </div>

      <div class="form-group" *ngIf="contact.template === undefined || contact.template.id === undefined">
        <label for="content" class="col-sm-3 control-label">Message</label>
        <div class="col-sm-9">
          <textarea id="content" name="content" class="form-control" rows="10" [(ngModel)]="contact.content" [required]="true"></textarea>
        </div>
      </div>


    </div>
    <div class="modal-footer text-right">
      <button type="submit" class="btn btn-success">Send</button>
      <button type="button" class="btn btn-default" (click)="modalRef.hide()">Cancel</button>
    </div>
  </form>
</ng-template>
<!-- End Send Modal -->
