import { Component, Input } from '@angular/core';
import { ResidentialStatus } from '../residential-status';

@Component({
  selector: 'app-residential-status-form',
  templateUrl: 'form.component.html',
})
export class ResidentialStatusFormComponent {
  @Input() residentialStatus: ResidentialStatus;
}
