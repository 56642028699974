import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import AddressType from '../../../chief-types/src/AddressType';
import { HttpRequestService } from '../shared/http/http-request.service';

@Injectable()
export class AddressTypeService extends HttpRequestService {
  protected readonly endpoint = 'address-types';

  /**
   * Returns a list of all addressTypes from the database with optional pagination.
   * @param page
   * @param limit
   */
  public listAddressTypes(page?: number, limit?: number): Observable<AddressType[]> {
    return this.list(this.endpoint, page, undefined, limit);
  }
}
