import { RouterModule, Routes } from '@angular/router';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule } from '@angular/common/http';
import { AccountTypeListComponent } from './list/list.component';
import { AuthGuard } from '../../auth/auth.guard';
import { AccountTypeService } from './account-type.service';
import { AccountTypeCreateComponent } from './create/create.component';
import { AccountTypeFormComponent } from './form/form.component';
import { AccountTypeEditComponent } from './edit/edit.component';
import { SharedModule } from '../../shared/shared.module';

const routes: Routes = [
  {
    path: 'account-types',
    component: AccountTypeListComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'account-types/create',
    component: AccountTypeCreateComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'account-types/:id/edit',
    component: AccountTypeEditComponent,
    canActivate: [AuthGuard],
  },
];

@NgModule({
  declarations: [
    AccountTypeListComponent,
    AccountTypeCreateComponent,
    AccountTypeFormComponent,
    AccountTypeEditComponent,
  ],
  imports: [
    BrowserModule,
    RouterModule,
    FormsModule,
    HttpClientModule,
    SharedModule,
    RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' }),
  ],
  exports: [RouterModule],
  providers: [AuthGuard, AccountTypeService],
})
export class AccountTypeRoutingModule {}
