import {Component, Input, OnInit, TemplateRef} from '@angular/core';
import {AngularFireFunctions} from '@angular/fire/functions';
import {BsModalRef, BsModalService} from 'ngx-bootstrap/modal';

@Component({
    selector: 'app-audit-payment-profile', templateUrl: 'audit-payment-profile.component.html',
})
export class AuditPaymentProfileComponent implements OnInit {

    @Input() clientId: string;
    public auditResults: AuditSummary[];
    public viewingProfile: AuditSummary;
    protected auditFunction = this.fireFuncton.httpsCallable('audit_paymentProfile');
    protected modalRef: BsModalRef;

    constructor(
      protected fireFuncton: AngularFireFunctions,
      private modalService: BsModalService,
    )
    {
    }

    ngOnInit(): void {
        this.auditFunction({
            clientID: this.clientId,
        }).subscribe((result) => {
            this.auditResults = result;
            console.log(result);
        });
    }

    openModal(template: TemplateRef<any>) {
        this.modalRef = this.modalService.show(template, {
            class: 'modal-lg',
        });
    }

}

class AuditSummary {
    id: string;
    schedule: any[];
    created_at: number;
    deleted_at: number;
}
