import {NgRedux, select} from '@angular-redux/store';
import {Component} from '@angular/core';
import {AngularFirestore} from '@angular/fire/firestore';
import {Observable} from 'rxjs/Observable';

import {environment} from '../environments/environment';
import {AuthService} from './auth/auth.service';
import {ReduxStore} from './common/redux.store';
import {UserActions} from './common/user/user.actions';
import {NotificationService} from './shared/notification.service';
import {UserProfile} from './users/user-profile';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: [],
})
export class AppComponent {
  @select(['user', 'menubar']) public menubar$: Observable<boolean>;
  @select(['user', 'menuItem']) public menuItem$: Observable<
    string | undefined
  >;
  @select(['user', 'profile']) public userProfile$: Observable<
    UserProfile | undefined
  >;
  public chiefUpdates: any[] = [];
  public apiUrl = environment.microservices.reports;

  private currentlySelectedMenu;

  constructor(
    public authService: AuthService,
    private db: AngularFirestore,
    private notificationService: NotificationService,
    private ngRedux: NgRedux<ReduxStore>,
    private userActions: UserActions,
  ) {
    db.collection('/chief-updates', (ref) =>
      ref.orderBy('created', 'desc').limit(10),
    )
      .valueChanges()
      .subscribe((items) => {
        if (
          this.chiefUpdates.length > 0 &&
          items[0]['created'] !== this.chiefUpdates[0]['created']
        ) {
          this.notificationService.warning(
            'Chief Updated',
            `Chief has had a new update. Please refresh as soon as you can to activate the new updates.`,
          );
        }

        this.chiefUpdates = items.map((item: any) => {
          item.content = item.content.split('\\n').join('\n');

          return item;
        });
      });
  }

  public set selectedMenu(item) {
    this.ngRedux.dispatch(this.userActions.selectMenuItem(item));
  }

  public toggleSidebar(): void {
    this.ngRedux.dispatch(this.userActions.toggleMenubar());
  }
}
