import { Component, EventEmitter, OnInit } from '@angular/core';
import { NotificationService } from '../../../../../shared/notification.service';
import {BankingAccountImport} from '../banking-account-import';
import {BankingAccountImportService} from '../banking-account-import.service';
import {ActivatedRoute} from '@angular/router';

@Component({
  selector: 'app-banking-account-list',
  templateUrl: 'list.component.html',
  styleUrls: [],
})
export class BankingAccountImportsListComponent implements OnInit {
  public account: BankingAccountImport;
  public accountId: string;

  /**
   * A list of all the banking-accounts currently loaded.
   *
   * @type {BankingAccountImport[]}
   */
  public accounts: BankingAccountImport[] = [];

  /**
   * Are we currently performing a loading action?
   *
   * @type {boolean}
   */
  public isLoading: boolean = true;

  /**
   * The total number of pages in this list of banking-accounts.
   *
   * @type {number}
   */
  public totalPages: number = 1;

  /**
   * The current page of banking-accounts that we are viewing.
   *
   * @type {number}
   */
  public currentPage: number = 1;

  /**
   * The total number of banking-accounts that can be viewed in the system.
   *
   * @type {number}
   */
  public totalResults: number = 0;

  /**
   * An Emitter to alert other components when a account has been deleted.
   *
   * @type {EventEmitter<boolean>}
   */
  public accountDeleted: EventEmitter<boolean> = new EventEmitter<
    boolean
  >();


  constructor(
    private accountService: BankingAccountImportService,
    private notificationService: NotificationService,
    private route: ActivatedRoute,
  ) {
    this.accountService.loading.subscribe(isLoading => {
      this.isLoading = isLoading;
    });
    this.accountService.totalPages.subscribe(totalPages => {
      this.totalPages = totalPages;
    });
    this.accountService.currentPage.subscribe(currentPage => {
      this.currentPage = currentPage;
    });
    this.accountService.totalResults.subscribe(totalResults => {
      this.totalResults = totalResults;
    });
  }

  /**
   * When the component is initialised we set it to load
   * page one of the account list.
   */
  public ngOnInit(): void {
    this.route.params.subscribe(params => {
      this.accountId = params.accountId;
      this.loadBankingAccountImports(1);
    });
  }

  /**
   * When we receive a page change from the pagination
   * component we load the appropriate page for the
   * account list.
   *
   * @param {number} pageNumber
   */
  public onPageChanged(pageNumber: number): void {
    this.loadBankingAccountImports(pageNumber);
  }

  /**
   * Loads a list of banking-accounts using the given per page limit.
   *
   * @param {number} page The current page we require.
   * @param {number} limit The number of results per page.
   */
  public loadBankingAccountImports(page?: number, limit?: number): void {
    const listBankingAccountImports = this.accountService
      .listBankingAccountImports(page, limit, {parameters: {accountId: this.accountId}})
      .subscribe((accounts: BankingAccountImport[]) => {
        this.accounts = accounts;
        listBankingAccountImports.unsubscribe();
      });
  }

  /**
   * When receiving confirmation of an item being deleted
   * we call the back end with the appropriate account ID
   * to delete the account.
   *
   * @param {string} id
   */
  public delete(id: string) {
    this.accountService.deleteBankingAccountImport(id).subscribe(
      deleted => {
        this.notificationService.success(
          `BankingAccountImport Deleted`,
          `BankingAccountImport with ID ${id} was deleted.`,
          5,
        );
        this.accountDeleted.next(true);
        this.onPageChanged(this.currentPage);
      },
      err => {
        this.notificationService.error(
          `BankingAccountImport Not Deleted`,
          err.error.message,
          10,
        );
        this.accountDeleted.next(false);
        this.onPageChanged(this.currentPage);
      },
    );
  }
}
