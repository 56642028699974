<div class="row">
  <div class="col-sm-12">
    <section class="panel">
      <app-page-title title="Create Breach Type" [loading]="isLoading"></app-page-title>

      <div class="panel-body">
        <form class="form-horizontal" (ngSubmit)="create()" ngNativeValidate>
          <app-breach-type-form [breachType]="breachType" *ngIf="breachType"></app-breach-type-form>
        </form>
      </div>
    </section>
  </div>
</div>


