import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

import { AuthService } from '../../../auth/auth.service';
import { Case } from '../../case';
import { CaseFinancialSummaryService } from '../case-financial-summary.service';
import { environment } from '../../../../environments/environment';

@Component({
  selector: 'app-case-financial-summary-view',
  templateUrl: 'view.component.html',
})
export class CaseFinancialSummaryViewComponent implements OnInit {
  @Input() caseRefreshed: EventEmitter<string>;
  @Input() filterInvoices: EventEmitter<string> = new EventEmitter<string>();
  @Input() caseDetails: Case;
  @Input() caseId: string;
  @Output()
  totalDistributedValue: EventEmitter<number> = new EventEmitter<number>();

  public apiUrl = environment.apiUrl;

  totalDistributions: any[] = [];

  daterangepickerModel: Date[];

  public newFee = {
    value: 0,
    chartOfAccounts: null,
  };

  public totalReceived: number = 0;

  public financialSummary: any = [];

  public feeCreated: EventEmitter<boolean> = new EventEmitter<boolean>();

  constructor(
    private readonly service: CaseFinancialSummaryService,
    public readonly authService: AuthService,
  ) {}

  public get totalExpected(): number {
    if (!this.financialSummary || !this.financialSummary.catOnes) {
      return 0;
    }
    let total = 0;

    this.financialSummary.catOnes.forEach((item) => {
      total += item.required;
    });

    return total;
  }

  public get totalRemaining(): number {
    if (!this.financialSummary || !this.financialSummary.catOnes) {
      return 0;
    }
    let total = 0;

    this.financialSummary.catOnes.forEach((item) => {
      total += item.remaining;
    });

    return total;
  }

  public get totalPaid(): number {
    if (!this.financialSummary || !this.financialSummary.catOnes) {
      return 0;
    }
    let total = 0;

    this.financialSummary.catOnes.forEach((item) => {
      total += item.paid;
    });

    return total;
  }

  public get totalToDistribute(): number {
    if (
      !this.financialSummary ||
      !this.financialSummary.financialValues ||
      !this.financialSummary.financialValues.cash_in
    ) {
      return 0;
    }
    let total = this.financialSummary.financialValues.cash_in - this.totalPaid;

    return total > 0 ? 0 : total * -1;
  }

  public get receiptsTotal(): number {
    return (
      Number(
        this.financialSummary.financialValues &&
          this.financialSummary.financialValues.customer_payments_in
          ? this.financialSummary.financialValues.customer_payments_in
          : 0,
      ) +
      this.sumAll(
        this.financialSummary.financialValues &&
          this.financialSummary.financialValues.extraordinary_income
          ? this.financialSummary.financialValues.extraordinary_income
          : [],
        'value',
      )
    );
  }

  public get totalPaymentsExpected(): number {
    return this.financialSummary?.catOnes
      ?.map((i) => (i.estimated ? i.invoice_value : i.total_over_life))
      .reduce((p, c) => p + c, 0);

    // return this.sumAll(this.financialSummary.catOnes, 'total_over_life');
  }

  public get totalPaymentsPaid(): number {
    return this.sumAll(this.financialSummary?.catOnes, 'paid') * -1;
  }

  public get totalPaymentsRemaining(): number {
    return this.totalPaymentsExpected - this.totalPaymentsPaid;
  }

  public get totalRemainingAllocations(): number {
    return this.receiptsTotal - this.totalPaymentsPaid;
  }

  public get totalCreditorDistributions() {
    return this.sumAll(this.totalDistributions, 'distributed');
  }

  public get totalCreditorRetained() {
    return this.sumAll(this.totalDistributions, 'retained');
  }

  public get totalCreditorAllocations() {
    return this.sumAll(this.totalDistributions, 'allocated');
  }

  public get totalCreditorPending() {
    return this.sumAll(this.totalDistributions, 'pending');
  }

  public get totalCreditorTotals() {
    return this.sumAll(this.totalDistributions, 'total');
  }

  public get totalCreditorValue() {
    return this.sumAll(this.totalDistributions, 'total');
  }

  /**
   * Calculates the minimum fee amount allowed to be taken off from a catOne.
   * Uses the remaining value.
   */
  public get minFeeValue() {
    if (!this.newFee.chartOfAccounts || !this.financialSummary) {
      return undefined;
    }
    const matchedCatOne = this.financialSummary?.catOnes.find(
      (catOne) => catOne.item === this.newFee.chartOfAccounts.name,
    );
    if (matchedCatOne) {
      return -this.remaining(matchedCatOne);
    }
    return 0.01;
  }

  ngOnInit(): void {
    this.loadFinancialSummary();
    this.caseRefreshed.subscribe((id) => {
      this.caseId = id;
      this.loadFinancialSummary();
    });
  }

  public sumAll(values: any[], field: string): number {
    return !values || values.length <= 0
      ? 0
      : values
          .map((v: any): number => v[field])
          .reduce((t: number, i: number): number => Number(t) + Number(i), 0);
  }

  public loadFinancialSummary(event?) {
    this.service
      .showClientDistributions(
        this.caseId,
        undefined,
        event || [new Date(2000, 1, 1), new Date(2200, 12, 31)],
      )
      .subscribe((data) => {
        this.financialSummary = data;
      });

    this.service
      .getTotalPayments(this.caseId, { parameters: { id: this.caseId } })
      .subscribe((data) => {
        this.totalReceived = data;
      });

    this.service
      .showClientCreditorDistributions(this.caseId)
      .subscribe((r: any) => {
        this.totalDistributions = r.data;
      });

    this.totalDistributedValue.next(this.totalToDistribute);
  }

  createFee(formData: any) {
    const subscription = this.service
      .addFee(this.newFee.chartOfAccounts.id, this.newFee.value, {
        parameters: { id: this.caseId },
      })
      .subscribe(
        (result) => {
          this.feeCreated.next(true);
          subscription.unsubscribe();
          this.loadFinancialSummary();
        },
        (err) => {
          this.feeCreated.next(false);
          subscription.unsubscribe();
        },
      );
  }

  /**
   * Calculate the remaining payments to be received for a catOne item.
   * @param item
   * @returns {number}
   */
  remaining(item) {
    return (
      ((item.estimated === false ? item.total_over_life : item.invoice_value) +
        item.paid) /
      100
    );
  }
}
