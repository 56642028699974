<div class="row">
    <div class="col-sm-12">
        <section class="panel">
            <app-page-title [loading]="isLoading" title="List Departments">
                {{ totalResults }} Results Found
            </app-page-title>

            <div class="panel-body">
                <table class="table table-striped">
                    <thead>
                    <tr>
                        <th>Domain</th>
                        <th>Department Name</th>
                        <th class="text-right">
                            <a [routerLink]="['/departments/create']" class="btn btn-success">Create</a>
                        </th>
                    </tr>
                    </thead>

                    <tbody>
                    <tr *ngFor="let department of departments">
                        <td>{{ department?.domain?.name || 'Global' }}</td>
                        <td>{{ department?.name }}</td>
                        <td class="text-right">
                            <div *ngIf="(department.domain && department.domain.name) || authService.hasPermission('config.all-domains')"
                                 class="btn-group">
                                <a [routerLink]="['/departments', department?.id, 'edit']"
                                   class="btn btn-sm btn-warning"
                                   type="button">Edit</a>
                                <button aria-expanded="false" aria-haspopup="true"
                                        class="btn btn-sm btn-warning dropdown-toggle" data-toggle="dropdown"
                                        type="button">
                                    <span class="caret"></span>
                                    <span class="sr-only">Toggle Dropdown</span>
                                </button>
                                <ul class="dropdown-menu">
                                    <li><a (click)="deleteModal.confirmDialog(department.id)"
                                           href="javascript:">Delete</a></li>
                                </ul>
                            </div>
                        </td>
                    </tr>
                    </tbody>

                    <tfoot>
                    <tr>
                        <td class="text-center" colspan="99">
                            <app-pagination (pageChanged)="onPageChanged($event)" [currentPage]="currentPage"
                                            [totalPages]="totalPages"></app-pagination>
                        </td>
                    </tr>
                    </tfoot>
                </table>
            </div>
        </section>
    </div>
</div>

<app-delete-modal #deleteModal (confirm)="delete($event)" [itemDeleted]="departmentDeleted"
                  type="department"></app-delete-modal>
