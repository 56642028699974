import { EventEmitter, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Domain } from './domain';
import { environment } from '../../environments/environment';
import { HttpRequestResponse } from '../shared/http/http-request-response';
import { Observable } from 'rxjs';

import { HttpRequestService } from '../shared/http/http-request.service';

@Injectable()
export class DomainService extends HttpRequestService {
  /**
   * The endpoint that we call to get the domain information.
   *
   * @type {string}
   */
  private readonly endpoint: string = 'domains';

  /**
   * Brings back a list of all accessible domains.
   *
   * @param {number} page The page number we are currently on.
   * @param {number} limit The number of domains to display per page.
   * @returns {Observable<Domain[]>}
   */
  public listDomains(page?: number, limit?: number): Observable<Domain[]> {
    return this.list(this.endpoint, page, undefined, limit);
  }

  /**
   * Finds details of the domain from the given ID.
   *
   * @param {string} domainId The ID of the domain we require details for.
   * @returns {Observable<Domain>}
   */
  public findDomain(domainId: string): Observable<Domain> {
    return this.findById(this.endpoint, domainId);
  }

  /**
   * Creates a new domain from the given Domain object.
   *
   * @param {Domain} newDomain The details of the domain to be created.
   * @returns {Observable<Domain | boolean>}
   */
  public createDomain(newDomain: Domain): Observable<Domain | boolean> {
    return this.create(this.endpoint, newDomain);
  }

  /**
   * Updates a domain with the given domain object for the given domain ID.
   *
   * @param {string} domainId The ID of the domain needing updating.
   * @param {Domain} newDetails The new domain object to update with.
   * @returns {Observable<Domain | boolean>}
   */
  public updateDomain(
    domainId: string,
    newDetails: Domain,
  ): Observable<Domain | boolean> {
    return this.update(this.endpoint, domainId, newDetails);
  }

  /**
   * Deletes a domain based on the given domain ID.
   *
   * @param {string} domainId The ID of the domain needing deleting.
   * @returns {Observable<boolean>}
   */
  public deleteDomain(domainId: string): Observable<boolean> {
    return this.delete(this.endpoint, domainId);
  }
}
