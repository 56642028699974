import {HttpClientModule} from '@angular/common/http';
import {NgModule} from '@angular/core';
import {FormsModule} from '@angular/forms';
import {BrowserModule} from '@angular/platform-browser';
import {RouterModule, Routes} from '@angular/router';

import {AuthGuard} from '../auth/auth.guard';
import {SharedModule} from '../shared/shared.module';
import {UserCreateComponent} from './create/create.component';
import {UserEditComponent} from './edit/edit.component';
import {UserFormComponent} from './form/form.component';
import {UserListComponent} from './list/list.component';
import {UserService} from './user.service';

// import {Ng9PasswordStrengthBarModule} from 'ng9-password-strength-bar';

const routes: Routes = [
  { path: 'users', component: UserListComponent, canActivate: [AuthGuard] },
  {
    path: 'users/create',
    component: UserCreateComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'users/:id/edit',
    component: UserEditComponent,
    canActivate: [AuthGuard],
  },
];

@NgModule({
  declarations: [
    UserListComponent,
    UserCreateComponent,
    UserFormComponent,
    UserEditComponent,
  ],
  imports: [
    // Ng9PasswordStrengthBarModule,
    BrowserModule,
    RouterModule,
    FormsModule,
    HttpClientModule,
    SharedModule,
    RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' }),
  ],
  exports: [RouterModule],
  providers: [AuthGuard, UserService],
})
export class UserRoutingModule {}
