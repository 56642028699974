import { Component, OnInit } from '@angular/core';
import { EmploymentStatus } from '../employment-status';
import { Router } from '@angular/router';
import { NgRedux } from '@angular-redux/store';
import { ReduxStore } from '../../common/redux.store';
import { EmploymentStatusActions } from '../../common/settings/employment-status/employment-status.actions';

@Component({
  selector: 'app-employment-status-create',
  templateUrl: 'create.component.html',
})
export class EmploymentStatusCreateComponent implements OnInit {
  public employmentStatus: EmploymentStatus;

  constructor(
    private ngRedux: NgRedux<ReduxStore>,
    private actions: EmploymentStatusActions,
    private router: Router,
  ) {
  }

  /**
   * Initialise the component, creating a new blank employmentStatus if required.
   */
  public ngOnInit(): void {
    if (!this.employmentStatus) {
      this.employmentStatus = new EmploymentStatus();
    }
  }

  public async create() {
    this.ngRedux.dispatch<any>(this.actions.create(this.employmentStatus));
    this.router.navigate(['/employment-statuses']);
  }

}
