import { Component, Input } from '@angular/core';
import { Supplier } from '../supplier';

@Component({
  selector: 'app-supplier-form',
  templateUrl: 'form.component.html',
})
export class SupplierFormComponent {
  @Input() supplier: Supplier;
}
