import {Component, Input, OnInit} from '@angular/core';
import {TypeaheadMatch} from 'ngx-bootstrap/typeahead';
import {Observable} from 'rxjs';

import {NotificationService} from '../../../shared/notification.service';
import {SelectComparisons} from '../../../shared/pipes/select.comparisons';
import {Supplier} from '../supplier';
import {SupplierService} from '../supplier.service';

@Component({
  selector: 'app-supplier-select',
  templateUrl: 'select.component.html',
})
export class SupplierSelectComponent extends SelectComparisons
  implements OnInit {
  /**
   * The model that we will be adding the supplier to.
   */
  @Input() model: any;

  /**
   * Do we require this field in the main form it is
   * included in?
   */
  @Input() required: boolean;

  /**
   * A list of all the suppliers currently loaded.
   *
   * @type {Supplier[]}
   */
  public suppliers: Supplier[] = [];

  /**
   * Are we currently performing a loading action?
   *
   * @type {boolean}
   */
  public isLoading: boolean = true;

  selectedValue: string;
  dataSource: Observable<any>;
  asyncSelected: string;
  typeaheadNoResults: boolean;
  typeaheadLoading: boolean;

  /**
   * Load up all our required services and subscribe to every observable we need.
   *
   * @param {SupplierService} supplierService
   * @param {NotificationService} notificationService
   */
  constructor(
    private supplierService: SupplierService,
    private notificationService: NotificationService,
  ) {
    super();

    this.dataSource = Observable.create((observer: any) => {
      observer.next(this.selectedValue);
    }).mergeMap((token: string) => this.searchSuppliers(token));

    this.supplierService.loading.subscribe(isLoading => {
      this.isLoading = isLoading;
    });
  }

  /**
   * When the component is initialised we set it to load
   * page one of the supplier list.
   */
  public ngOnInit(): void {
    if (this.model && this.model.supplier && this.model.supplier.name) {
      this.selectedValue = this.model.supplier.name;
    }
  }

  /**
   * Loads a list of suppliers using the given per page limit.
   *
   * @param {number} page The current page we require.
   * @param {number} limit The number of results per page.
   */
  public searchSuppliers(filter: string): Observable<Supplier[]> {
    return this.supplierService.listSuppliers(1, 10, filter);
  }

  changeTypeaheadLoading(e: boolean): void {
    this.typeaheadLoading = e;
  }

  changeTypeaheadNoResults(e: boolean): void {
    this.model.supplier = {
      name: this.selectedValue,
    };
  }

  typeaheadOnSelect(e: TypeaheadMatch): void {
    this.model.supplier = e.item;
    this.selectedValue = this.model.supplier.name;
  }

  typeaheadOnBlur(e: TypeaheadMatch): void {
    this.model.supplier = e.item;
    this.selectedValue = this.model.supplier.name;
  }
}
