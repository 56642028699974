import { Component, OnInit } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { ActivatedRoute } from '@angular/router';
import { AuthService } from '../../auth/auth.service';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-clients-by-creditor-report',
  templateUrl: 'clients-by-creditor.component.html',
})
export class ClientsByCreditorComponent implements OnInit {
  public loading: boolean = false;
  public filter: any = {};

  public creditorId = 'ad7489a7-b870-4df1-91d1-40262e392409';

  public detailedReport: any[];

  constructor(
    private readonly http: HttpClient,
    private readonly authService: AuthService,
    private route: ActivatedRoute,
  ) {}

  ngOnInit(): void {
    this.route.params.subscribe((params) => {
      this.creditorId = params.creditorId;
      this.loadReport(1);
    });
  }

  get detailedLink(): string {
    return `${environment.apiUrl}reports/wip-by-creditor?domainId=${
      this.authService.domain.id
    }&creditorId=${
      this.creditorId
    }&limit=100000&auth=${this.authService.getToken()}`;
  }

  get downloadReportUrl(): string {
    return `${this.detailedLink}&download=true`;
  }

  protected loadReport(page: number) {
    this.loading = true;
    this.http.get<any>(this.detailedLink).subscribe((data) => {
      this.loading = false;
      this.detailedReport = data.data;
    });
  }
}
