import {Component, OnInit} from '@angular/core';
import {DepartmentService} from '../department.service';
import {Department} from '../department';
import {Router} from '@angular/router';
import {NotificationService} from '../../../shared/notification.service';

@Component({
    selector: 'app-department-create',
    templateUrl: 'create.component.html',
})
export class DepartmentCreateComponent implements OnInit {
    /**
     * Details of the department being created.
     */
    public department: Department;

    /**
     * Are we currently performing a loading action?
     *
     * @type {boolean}
     */
    public isLoading: boolean = false;

    /**
     * Load up all our required services and subscribe to every observable we need.
     *
     * @param {DepartmentService} departmentService
     * @param {NotificationService} notificationService
     * @param {Router} router
     */
    constructor(
        private departmentService: DepartmentService,
        private notificationService: NotificationService,
        private router: Router,
    ) {
        this.departmentService.loading.subscribe(result => (this.isLoading = result));
    }

    /**
     * Initialise the component, creating a new blank department if required.
     */
    public ngOnInit(): void {
        if (!this.department) {
            this.department = new Department();
        }
    }

    /**
     * Perform the create action which adds the new department into the backend.
     */
    public create() {
        const createDepartment = this.departmentService.createDepartment(this.department).subscribe(
            (result: Department) => {
                this.notificationService.success(
                    `Department Created`,
                    `New department ${result.name} has been created with ID of ${result.id}.`,
                    5,
                );
                this.department = new Department();
                createDepartment.unsubscribe();
                this.router.navigate(['/departments']);
            },
            err => {
                this.notificationService.error(
                    `Could not create Department`,
                    err.error.message,
                    10,
                );
            },
        );
    }
}
