<div class="text-right button-menu">
  <button type="button" class="btn btn-sm btn-success" href="javascript:;" (click)="create.open(caseId)">
    Add Vehicle
  </button>
</div>

<table class="vehicles table table-striped">
  <thead>
  <tr>
    <th width="40" class="text-center"></th>
    <th width="40" class="text-center"></th>
    <th>Registration</th>
    <th>Make</th>
    <th>Model</th>
    <th>Year</th>
    <th>Value</th>
    <th>Applicant</th>
    <th>Creditor</th>
    <th></th>
  </tr>
  </thead>
  <tbody>
  <tr *ngFor="let caseVehicle of caseVehicles">
    <td>
      <app-user-icon *ngIf="caseVehicle.user" [userId]="caseVehicle.user.id" [size]="20"
                     [passedUser]="caseVehicle?.user"></app-user-icon>
    </td>
    <td><i class="fa" [class.fa-check-circle]="!caseVehicle.excluded"  [class.fa-times-circle]="caseVehicle.excluded"></i></td>
    <td>{{ caseVehicle.registration }}</td>
    <td>{{ caseVehicle.make }}</td>
    <td>{{ caseVehicle.model }}</td>
    <td>{{ caseVehicle.year }}</td>
    <td>{{ caseVehicle.value / 100 | toCurrency }}</td>
    <td *ngIf="caseVehicle.person">{{ caseVehicle?.person?.firstName }} {{ caseVehicle?.person?.lastName }}</td>
    <td *ngIf="!caseVehicle.person">Joint</td>
    <td>{{ caseVehicle?.caseCreditor?.creditor?.name }}</td>
    <td class="text-right">
      <div class="btn-group">
        <a type="button" class="btn btn-xs btn-warning" (click)="edit.open(caseId, caseVehicle)">Edit</a>
        <button type="button" class="btn btn-xs btn-warning dropdown-toggle" data-toggle="dropdown"
                aria-haspopup="true" aria-expanded="false" *ngIf="authService.hasPermission('user.delete-cases')">
          <span class="caret" *ngIf="authService.hasPermission('user.delete-cases')"></span>
          <span class="sr-only" *ngIf="authService.hasPermission('user.delete-cases')">Toggle Dropdown</span>
        </button>
        <ul class="dropdown-menu" *ngIf="authService.hasPermission('user.delete-cases')">
          <li *ngIf="authService.hasPermission('user.delete-cases')"><a href="javascript:;"
                                                                        (click)="deleteModal.confirmDialog(caseVehicle.id)">Delete</a>
          </li>
        </ul>
      </div>
    </td>
  </tr>
  </tbody>
  <tfoot>
  <tr>
    <td colspan="99" class="text-center">
      <app-pagination [totalPages]="totalPages" [currentPage]="currentPage"
                      (pageChanged)="onPageChanged($event)"></app-pagination>
    </td>
  </tr>
  </tfoot>
</table>

<app-case-vehicle-create (completed)="onPageChanged(1)" #create></app-case-vehicle-create>

<app-case-vehicle-edit (completed)="onPageChanged(1)" #edit></app-case-vehicle-edit>

<app-delete-modal type="vehicle" (confirm)="delete($event)" [itemDeleted]="itemDeleted" #deleteModal></app-delete-modal>
