import {HttpClientModule} from '@angular/common/http';
import {NgModule} from '@angular/core';
import {FormsModule} from '@angular/forms';
import {BrowserModule} from '@angular/platform-browser';
import {ModalModule} from 'ngx-bootstrap/modal';
import {TooltipModule} from 'ngx-bootstrap/tooltip';
import {QuillModule} from 'ngx-quill';

import {SharedModule} from '../../shared/shared.module';
import {CaseComplaintAssignComponent} from './assign/assign.component';
import {CaseComplaintService} from './case-complaint.service';
import {CaseComplaintListComponent} from './list/list.component';
import {CaseComplaintUpdateComponent} from './update/update.component';
import {CaseComplaintViewComponent} from './view/view.component';

@NgModule({
  declarations: [
    CaseComplaintListComponent,
    CaseComplaintAssignComponent,
    CaseComplaintUpdateComponent,
    CaseComplaintViewComponent,
  ],
  imports: [
    ModalModule.forRoot(),
    TooltipModule.forRoot(),
    QuillModule,
    BrowserModule,
    FormsModule,
    HttpClientModule,
    SharedModule,
  ],
  exports: [CaseComplaintListComponent],
  providers: [CaseComplaintService],
})
export class CaseComplaintRoutingModule {}
