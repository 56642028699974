import {HttpClientModule} from '@angular/common/http';
import {NgModule} from '@angular/core';
import {FormsModule} from '@angular/forms';
import {BrowserModule} from '@angular/platform-browser';
import {BsDatepickerModule} from 'ngx-bootstrap/datepicker';
import {TooltipModule} from 'ngx-bootstrap/tooltip';

import {SharedModule} from '../../shared/shared.module';
import {CaseModificationRoutingModule} from '../modifications/modification-routing.module';
import {CaseFinancialSummaryService} from './case-financial-summary.service';
import {CaseFinancialSummaryViewComponent} from './view/view.component';

@NgModule({
  declarations: [CaseFinancialSummaryViewComponent],
  imports: [
    BrowserModule,
    FormsModule,
    HttpClientModule,
    SharedModule,
    CaseModificationRoutingModule,
    BsDatepickerModule,
    TooltipModule,
  ],
  exports: [CaseFinancialSummaryViewComponent],
  providers: [CaseFinancialSummaryService],
})
export class CaseFinancialSummaryRoutingModule {}
