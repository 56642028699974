import {Component, Input} from '@angular/core';
import {Department} from '../department';

@Component({
    selector: 'app-department-form',
    templateUrl: 'form.component.html',
})
export class DepartmentFormComponent {
    @Input() department: Department;
}
