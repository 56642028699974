import {HttpClientModule} from '@angular/common/http';
import {NgModule} from '@angular/core';
import {FormsModule} from '@angular/forms';
import {BrowserModule} from '@angular/platform-browser';
import {TooltipModule} from 'ngx-bootstrap/tooltip';
import {QuillModule} from 'ngx-quill';

import {SharedModule} from '../../shared/shared.module';
import {DomainCreditService} from './domain-credit.service';
import {DomainCreditListComponent} from './list/list.component';

@NgModule({
  declarations: [DomainCreditListComponent],
  imports: [
    TooltipModule.forRoot(),
    QuillModule,
    BrowserModule,
    FormsModule,
    HttpClientModule,
    SharedModule,
  ],
  exports: [DomainCreditListComponent],
  providers: [DomainCreditService],
})
export class DomainCreditRoutingModule {}
