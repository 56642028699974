import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NotificationService } from '../../../shared/notification.service';
import { CasePersonAddressService } from '../addresses/case-person-address-service';
import { Person } from '../person';

@Component({
  selector: 'app-shared-person-view',
  templateUrl: 'view.component.html',
  styleUrls: ['view.component.css'],
})
export class SharedPersonViewComponent {
  /**
   * The model that we will be adding the portfolio to.
   */
  @Input() person: Person;

  /**
   * The case the person belongs to.
   */
  @Input() caseId: string;

  @Input() direction: string = 'horizontal';

  @Output() reloadCase = new EventEmitter<boolean>();

  constructor(
    protected readonly casePersonAddressService: CasePersonAddressService,
    protected readonly notificationService: NotificationService,
  ) {}
}
