<ng-container *ngIf="case">
  <div class="row clearfix" style="margin-bottom: 20px">
    <div class="col-md-10">
      <button
        class="btn btn-xs btn-default"
        type="button"
        style="margin-right: 5px"
      >
        {{ case?.domain?.name }}
      </button>
      <button
        class="btn btn-xs btn-inverse"
        type="button"
        style="margin-right: 5px"
      >
        {{ case?.introducer?.name }}
      </button>
      <button
        class="btn btn-xs btn-primary"
        type="button"
        style="margin-right: 5px"
      >
        {{ case?.status?.name }}
      </button>
      <button
        *ngIf="case?.substatus"
        class="btn btn-xs btn-danger"
        type="button"
        style="margin-right: 5px"
      >
        {{ case?.substatus?.name }}
      </button>
      <button
        *ngIf="case?.user"
        class="btn btn-xs btn-warning"
        type="button"
        style="margin-right: 5px"
      >
        {{ case?.user?.name }}
      </button>
      <button
        *ngIf="case?.uniqueReference"
        class="btn btn-xs btn-success"
        type="button"
      >
        {{ case?.uniqueReference }}
      </button>
    </div>
    <div class="col-md-2 text-right">
      <app-communicate-button
        [caseId]="case?.id"
        buttonColor="danger"
        buttonSize="xs"
      ></app-communicate-button>

      <div *ngIf="case" class="btn-group" dropdown placement="bottom right">
        <a
          [routerLink]="['/cases', case?.id, 'edit']"
          class="btn btn-warning btn-xs"
          >Edit</a
        >
        <button
          class="btn btn-warning btn-xs dropdown-toggle dropdown-toggle-split"
          dropdownToggle
          type="button"
        >
          <span class="caret"></span>
          <span class="sr-only">Split button!</span>
        </button>
        <ul *dropdownMenu class="dropdown-menu dropdown-menu-right" role="menu">
          <li role="menuitem">
            <a
              (click)="changeStatus.open(case.id)"
              class="dropdown-item"
              href="javascript:"
              >Change Status</a
            >
          </li>
          <li role="menuitem">
            <a
              (click)="changeUserModal.confirmDialog(caseId)"
              class="dropdown-item"
              href="javascript:"
              >{{ case?.user ? 'Change' : 'Assign' }} User</a
            >
          </li>
          <li role="menuitem">
            <a
              (click)="createDocumentModal.confirmDialog(caseId)"
              class="dropdown-item"
              href="javascript:"
              >Generate Document</a
            >
          </li>
          <!--<li role="menuitem"><a class="dropdown-item" href="javascript:;"-->
          <!--(click)="createPackModal.confirmDialog(caseId)">Generate Pack</a></li>-->

          <li role="menuitem">
            <a
              (click)="createIntroducerCommissionModal.confirmDialog(caseId)"
              class="dropdown-item"
              href="javascript:"
            >
              Introducer Commssion
            </a>
          </li>

          <li role="menuitem">
            <a
              (click)="toggleDeceased()"
              class="dropdown-item"
              href="javascript:;"
            >
              {{
                case.deceased === true ? 'Mark as Alive' : 'Mark as Deceased'
              }}
            </a>
          </li>

          <li
            *ngIf="canReferForProtection || canReferForPpi"
            class="divider"
            role="separator"
          ></li>

          <li *ngIf="canReferForPpi" role="menuitem">
            <a
              (click)="createPpiReferralModal.confirmDialog(caseId)"
              class="dropdown-item"
              href="javascript:"
              >Refer for PPI / PBA</a
            >
          </li>

          <li *ngIf="canReferForProtection" role="menuitem">
            <a
              (click)="createProtectionReferralModal.confirmDialog(caseId)"
              class="dropdown-item"
              href="javascript:"
              >Refer for Protection</a
            >
          </li>

          <li class="divider" role="separator"></li>

          <li role="menuitem">
            <a
              class="dropdown-item"
              target="_blank"
              [routerLink]="['/financials/distributions/', case.id]"
            >
              View Allocations
            </a>
          </li>
        </ul>
      </div>
    </div>
  </div>

  <div *ngIf="case.vulnerableClient" class="row clearfix">
    <div class="col-md-12">
      <div class="alert alert-danger">
        <i class="fa fa-warning"></i>
        &nbsp;<strong>VULNERABLE CLIENT:</strong> This client is classified as
        vulnerable and we
        <strong *ngIf="case.vulnerableClientDisclosed === false">DO NOT</strong>
        have permission to disclose this!
      </div>
    </div>
  </div>

  <app-case-credit-summary
    [clientId]="case.id"
    [warningOnly]="true"
  ></app-case-credit-summary>

  <div class="row clearfix">
    <div class="col-lg-4">
      <div class="panel panel-primary">
        <app-page-title [loading]="isLoading" [title]="'Case Details'">
          <div class="btn-group" role="group">
            <button
              (click)="changeApplicant(0)"
              *ngIf="case?.people"
              [class.btn-success]="currentApplicant === 0"
              class="btn btn-xs"
              container="body"
              tooltip="{{ (case?.people)[0]?.firstName }} {{
                (case?.people)[0]?.lastName
              }}"
            >
              <i
                [class.fa-female]="(case?.people)[0]?.gender?.name === 'Female'"
                [class.fa-male]="(case?.people)[0]?.gender?.name === 'Male'"
                class="fa"
                style="min-width: 20px"
              ></i>
            </button>
            <button
              (click)="changeApplicant(1)"
              *ngIf="case?.people && case?.people.length > 1"
              [class.btn-success]="currentApplicant === 1"
              class="btn btn-xs"
              container="body"
              tooltip="{{ (case?.people)[1]?.firstName }} {{
                (case?.people)[1]?.lastName
              }}"
            >
              <i
                [class.fa-female]="(case?.people)[1]?.gender?.name === 'Female'"
                [class.fa-male]="(case?.people)[1]?.gender?.name === 'Male'"
                class="fa"
                style="min-width: 20px"
              ></i>
            </button>
            <button
              (click)="changeApplicant(-2)"
              [class.btn-success]="currentApplicant === -2"
              class="btn btn-xs"
              container="body"
              tooltip="Dependants"
            >
              <i class="fa fa-child" style="min-width: 20px"></i>
            </button>
            <button
              (click)="changeApplicant(-1)"
              [class.btn-success]="currentApplicant === -1"
              class="btn btn-xs"
              container="body"
              tooltip="Financial Summary"
            >
              <i class="fa fa-gbp" style="min-width: 20px"></i>
            </button>
            <button
              (click)="changeApplicant(-3)"
              [class.btn-success]="currentApplicant === -3"
              class="btn btn-xs"
              container="body"
              tooltip="Extra Information"
            >
              <i class="fa fa-info-circle" style="min-width: 20px"></i>
            </button>
          </div>
        </app-page-title>
        <div class="panel-body">
          <app-shared-person-view
            *ngIf="case && case?.people"
            [class.hidden]="currentApplicant < 0"
            [person]="(case?.people)[currentApplicant]"
            [caseId]="case.id"
            (reloadCase)="reloadCase()"
          ></app-shared-person-view>
          <app-shared-dependant-list
            *ngIf="case"
            [case]="case"
            [class.hidden]="currentApplicant !== -2"
          ></app-shared-dependant-list>
          <app-shared-financial-summary
            *ngIf="case"
            [(case)]="case"
            [class.hidden]="currentApplicant !== -1"
            [summary]="totals"
          ></app-shared-financial-summary>

          <div *ngIf="case" [class.hidden]="currentApplicant !== -3">
            <div class="row" style="margin-bottom: 10px">
              <div class="col-md-4 text-bold">Approval Date</div>
              <div class="col-md-8">
                {{
                  case.approvalDate
                    ? (case.approvalDate | date: 'dd/MM/yyyy')
                    : 'Not Yet Approved'
                }}
              </div>
            </div>

            <div class="row" style="margin-bottom: 10px">
              <div class="col-md-4 text-bold">Deceased</div>
              <div class="col-md-8">
                {{ case.deceased === true ? 'Yes' : 'No' }}
              </div>
            </div>

            <div class="row" style="margin-bottom: 10px">
              <div class="col-md-4 text-bold">Fee Wizard</div>
              <div class="col-md-8">
                <div [ngSwitch]="case.allocationType">
                  <div *ngSwitchCase="'furtherRealisationsNoCap'">
                    Further Realisations No Cap
                  </div>
                  <div *ngSwitchCase="'furtherRealisationsWithCap'">
                    Further Realisations With Cap
                  </div>
                  <div *ngSwitchCase="'totalRealisationsNoCap'">
                    Total Realisations No Cap
                  </div>
                  <div *ngSwitchCase="'totalRealisationsWithCap'">
                    Total Realisations With Cap
                  </div>
                  <div *ngSwitchDefault>Manual</div>
                </div>
              </div>
            </div>

            <div class="row" style="margin-bottom: 10px">
              <div class="col-md-4 text-bold">Allocation Savings</div>
              <div class="col-md-8">
                {{ case.allocationSavings / 100 | toAccountancy }}
              </div>
            </div>

            <div
              *ngIf="case.caseLocale && case.caseLocale.length > 1"
              class="row"
              style="margin-bottom: 10px"
            >
              <div class="col-md-4 text-bold">Case Area</div>
              <div class="col-md-8">
                {{
                  case.caseLocale === 'england-wales'
                    ? 'England / Wales'
                    : 'Northern Ireland'
                }}
              </div>
            </div>
            <div
              *ngIf="case.courtName && case.courtName.length > 1"
              class="row"
              style="margin-bottom: 10px"
            >
              <div class="col-md-4 text-bold">Court Name</div>
              <div class="col-md-8">{{ case.courtName }}</div>
            </div>
            <div
              *ngIf="case.currentIp && case.currentIp.length > 1"
              class="row"
              style="margin-bottom: 10px"
            >
              <div class="col-md-4 text-bold">Current IP</div>
              <div class="col-md-8">{{ case.currentIp }}</div>
            </div>
            <div
              *ngIf="case.previousIp && case.previousIp.length > 1"
              class="row"
              style="margin-bottom: 10px"
            >
              <div class="col-md-4 text-bold">Previous IP</div>
              <div class="col-md-8">{{ case.previousIp }}</div>
            </div>
            <div
              *ngIf="case.previousIp && case.previousIp.length > 1"
              class="row"
              style="margin-bottom: 10px"
            >
              <div class="col-md-4 text-bold">Previous IP Retired</div>
              <div class="col-md-8">
                {{ case.previousIpRetired | date: 'dd/MM/yyyy' }}
              </div>
            </div>
            <div
              *ngIf="case.initialDividend"
              class="row"
              style="margin-bottom: 10px"
            >
              <div class="col-md-4 text-bold">Initial Dividend</div>
              <div class="col-md-8">{{ case.initialDividend }}p/£</div>
            </div>

            <div class="row" style="margin-bottom: 10px">
              <div class="col-md-4 text-bold">Administration Period</div>
              <div class="col-md-8">{{ case.administrationPeriod }} months</div>
            </div>

            <div class="row" style="margin-bottom: 10px">
              <div class="col-md-4 text-bold">Communication</div>
              <div class="col-md-8">
                Postal Mail
                <strong *ngIf="case.allowPost === false">NOT</strong> Allowed
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-lg-8">
      <section class="panel panel-primary">
        <header class="panel-heading tab-bg-light">
          <ul class="nav nav-tabs">
            <li
              [class.active]="tabSelected('calendar')"
              container="body"
              tooltip="Calendar"
            >
              <a (click)="setTab('calendar')" href="javascript:"
                ><i aria-hidden="true" class="fa fa-calendar"></i
              ></a>
            </li>

            <li
              [class.active]="tabSelected('notes')"
              container="body"
              tooltip="Notes"
            >
              <a (click)="setTab('notes')" href="javascript:"
                ><i aria-hidden="true" class="fa fa-comment"></i
              ></a>
            </li>

            <li
              [class.active]="tabSelected('communications')"
              container="body"
              tooltip="Communications"
            >
              <a (click)="setTab('communications')" href="javascript:"
                ><i aria-hidden="true" class="fa fa-envelope"></i
              ></a>
            </li>

            <li
              [class.active]="tabSelected('documents')"
              container="body"
              tooltip="Documents"
            >
              <a (click)="setTab('documents')" href="javascript:"
                ><i aria-hidden="true" class="fa fa-file-text"></i
              ></a>
            </li>

            <li
              [class.active]="
                tabSelected('creditors') ||
                tabSelected('income') ||
                tabSelected('expenditure')
              "
            >
              <a
                aria-expanded="false"
                aria-haspopup="true"
                class="dropdown-toggle"
                data-toggle="dropdown"
                href="javascript:"
                >Financials <span class="caret"></span
              ></a>
              <ul class="dropdown-menu">
                <li [class.active]="tabSelected('creditors')">
                  <a
                    (click)="setTab('creditors')"
                    href="javascript:"
                    i18n="Creditors Tab Title@@creditors"
                    >Creditors</a
                  >
                </li>
                <li [class.active]="tabSelected('creditor-votes')">
                  <a (click)="setTab('creditor-votes')" href="javascript:"
                    >Creditor Votes</a
                  >
                </li>
                <li [class.active]="tabSelected('income')">
                  <a
                    (click)="setTab('income')"
                    href="javascript:"
                    i18n="Income Tab Title@@income"
                    >Income</a
                  >
                </li>
                <li [class.active]="tabSelected('expenditure')">
                  <a
                    (click)="setTab('expenditure')"
                    href="javascript:"
                    i18n="Expenditure Tab Title@@expenditure"
                    >Expenditure</a
                  >
                </li>
                <li [class.active]="tabSelected('fee-comparison')">
                  <a
                    (click)="setTab('fee-comparison')"
                    href="javascript:"
                    i18n="Fee Comparison Tab Title@@feeComparison"
                    >Fee Comparison</a
                  >
                </li>
                <li
                  *ngIf="case && case.paymentScheduleCreated"
                  [class.active]="tabSelected('payment-schedule')"
                >
                  <a (click)="setTab('payment-schedule')" href="javascript:"
                    >Payment Schedule</a
                  >
                </li>
                <li [class.active]="tabSelected('financial-summary')">
                  <a (click)="setTab('financial-summary')" href="javascript:"
                    >R&P Summary</a
                  >
                </li>
                <li
                  *ngIf="auth.hasPermission('user.detailed-transaction-report')"
                  [class.active]="tabSelected('detailed-transactions')"
                >
                  <a
                    (click)="setTab('detailed-transactions')"
                    href="javascript:"
                    >Detailed Transactions</a
                  >
                </li>
                <li [class.active]="tabSelected('creditor-distributions')">
                  <a
                    (click)="setTab('creditor-distributions')"
                    href="javascript:"
                    >Distributions</a
                  >
                </li>
                <li [class.active]="tabSelected('invoices')">
                  <a (click)="setTab('invoices')" href="javascript:"
                    >Invoices</a
                  >
                </li>
              </ul>
            </li>

            <li
              [class.active]="
                tabSelected('properties') ||
                tabSelected('vehicles') ||
                tabSelected('assets')
              "
            >
              <a
                aria-expanded="false"
                aria-haspopup="true"
                class="dropdown-toggle"
                data-toggle="dropdown"
                href="javascript:"
                >Assets <span class="caret"></span
              ></a>
              <ul class="dropdown-menu">
                <li [class.active]="tabSelected('properties')">
                  <a
                    (click)="setTab('properties')"
                    href="javascript:"
                    i18n="Properties Tab Title@@properties"
                    >Properties</a
                  >
                </li>
                <li [class.active]="tabSelected('vehicles')">
                  <a
                    (click)="setTab('vehicles')"
                    href="javascript:"
                    i18n="Vehicles Tab Title@@vehicles"
                    >Vehicles</a
                  >
                </li>
                <li [class.active]="tabSelected('assets')">
                  <a
                    (click)="setTab('assets')"
                    href="javascript:"
                    i18n="Other Assets Tab Title@@otherAssets"
                    >Other Assets</a
                  >
                </li>
              </ul>
            </li>

            <li [class.active]="tabSelected('chairmans-report')">
              <a
                aria-expanded="false"
                aria-haspopup="true"
                class="dropdown-toggle"
                data-toggle="dropdown"
                href="javascript:"
                >Reports <span class="caret"></span
              ></a>
              <ul class="dropdown-menu">
                <li [class.active]="tabSelected('chairmans-report')">
                  <a (click)="setTab('chairmans-report')" href="javascript:"
                    >Chairman's Report</a
                  >
                </li>
              </ul>
            </li>

            <li
              [class.active]="tabSelected('breaches')"
              container="body"
              tooltip="Breaches"
            >
              <a (click)="setTab('breaches')" href="javascript:"
                ><i aria-hidden="true" class="fa fa-exclamation-triangle"></i
              ></a>
            </li>

            <li
              [class.active]="tabSelected('annuals')"
              container="body"
              tooltip="Annual Reviews"
            >
              <a (click)="setTab('annuals')" href="javascript:"
                ><i aria-hidden="true" class="fa fa-clock-o"></i
              ></a>
            </li>

            <li
              [class.active]="tabSelected('complaints')"
              class="pull-right"
              container="body"
              tooltip="Complaints"
            >
              <a (click)="setTab('complaints')" href="javascript:"
                ><i aria-hidden="true" class="fa fa-exclamation-circle"></i
              ></a>
            </li>
          </ul>
        </header>

        <div class="panel-body">
          <div class="tab-content">
            <div
              [class.active]="tabSelected('calendar')"
              class="tab-pane"
              id="calendar"
              role="tabpanel"
            >
              <app-case-calendar-list
                *ngIf="case && tabSelected('calendar')"
                [caseId]="case.id"
                [caseRefreshed]="caseRefreshed"
              ></app-case-calendar-list>
            </div>
            <div
              [class.active]="tabSelected('notes')"
              class="tab-pane"
              id="notes"
              role="tabpanel"
            >
              <app-case-note-list
                *ngIf="case && tabSelected('notes')"
                [caseId]="case.id"
                [caseRefreshed]="caseRefreshed"
              ></app-case-note-list>
            </div>

            <div
              [class.active]="tabSelected('communications')"
              class="tab-pane"
              id="communications"
              role="tabpanel"
            >
              <app-case-communication-list
                *ngIf="case && tabSelected('communications')"
                [caseId]="case.id"
                [caseRefreshed]="caseRefreshed"
              ></app-case-communication-list>
            </div>

            <div
              [class.active]="tabSelected('documents')"
              class="tab-pane"
              id="documents"
              role="tabpanel"
            >
              <app-case-document-list
                *ngIf="case && tabSelected('documents')"
                [caseId]="case.id"
                [caseRefreshed]="caseRefreshed"
              ></app-case-document-list>
            </div>
            <div
              [class.active]="tabSelected('creditors')"
              class="tab-pane"
              id="creditors"
              role="tabpanel"
            >
              <app-case-creditor-list
                (creditors)="creditorsUpdated($event)"
                (securedCreditorsUpdated)="valueChanged('secured', $event)"
                (unsecuredCreditorsUpdated)="valueChanged('unsecured', $event)"
                *ngIf="case"
                [caseId]="case.id"
                [caseRefreshed]="caseRefreshed"
                [class.hidden]="!tabSelected('creditors')"
              ></app-case-creditor-list>
            </div>

            <div
              [class.active]="tabSelected('creditor-votes')"
              class="tab-pane"
              id="creditor-votes"
              role="tabpanel"
            >
              <app-case-creditor-votes-list
                *ngIf="case && tabSelected('creditor-votes')"
                [caseId]="case.id"
                [caseRefreshed]="caseRefreshed"
                (votesUpdated)="reloadCase()"
                [class.hidden]="!tabSelected('creditor-votes')"
              ></app-case-creditor-votes-list>
            </div>

            <div
              [class.active]="tabSelected('income')"
              class="tab-pane"
              id="income"
              role="tabpanel"
            >
              <div [class.hidden]="!tabSelected('income')">
                <input [(ngModel)]="basicVersion" type="checkbox" />
                Toggle List / Form mode
              </div>

              <app-case-income-list
                (incomeUpdated)="valueChanged('income', $event)"
                *ngIf="case && basicVersion"
                [caseId]="case.id"
                [caseRefreshed]="caseRefreshed"
                [class.hidden]="!tabSelected('income')"
              ></app-case-income-list>

              <app-case-income-form
                *ngIf="case && !basicVersion"
                [caseId]="case.id"
                [caseRefreshed]="caseRefreshed"
                [class.hidden]="!tabSelected('income')"
              ></app-case-income-form>
            </div>

            <div
              [class.active]="tabSelected('expenditure')"
              class="tab-pane"
              id="expenditure"
              role="tabpanel"
            >
              <div [class.hidden]="!tabSelected('expenditure')">
                <input [(ngModel)]="basicVersion" type="checkbox" />
                Toggle List / Form mode
              </div>

              <app-case-expenditure-list
                (expenditureUpdated)="valueChanged('expenditure', $event)"
                (protectionValueUpdated)="protectionFound($event)"
                *ngIf="case && basicVersion"
                [caseId]="case.id"
                [caseRefreshed]="caseRefreshed"
                [class.hidden]="!tabSelected('expenditure')"
              ></app-case-expenditure-list>

              <app-case-expenditure-form
                *ngIf="case && !basicVersion"
                [caseId]="case.id"
                [caseRefreshed]="caseRefreshed"
                [class.hidden]="!tabSelected('expenditure')"
              ></app-case-expenditure-form>
            </div>

            <div
              [class.active]="tabSelected('fee-comparison')"
              class="tab-pane"
              id="fee-comparison"
              role="tabpanel"
            >
              <app-case-fee-comparison-form
                (paymentScheduleCreatedAction)="reloadCase()"
                (totalSoaValue)="setTotalSoa($event)"
                *ngIf="case"
                [caseId]="case.id"
                [caseRefreshed]="caseRefreshed"
                [class.hidden]="!(case && tabSelected('fee-comparison'))"
                [creditorList]="creditors"
                [disposableIncome]="(totals.income - totals.expenditure) * 100"
                [ivaDuration]="case.duration"
                [paymentFrequency]="case.paymentFrequency"
                [paymentScheduleCreated]="case.paymentScheduleCreated"
                [paymentScheduleItems]="
                  paymentSchedule && paymentSchedule.schedule
                "
                [steppedPayments]="
                  case.steppedPayments ? case.steppedPayments : []
                "
                [supervisorPercentage]="case.supervisoryPercentage"
              ></app-case-fee-comparison-form>
            </div>

            <div
              [class.active]="tabSelected('properties')"
              class="tab-pane"
              id="properties"
              role="tabpanel"
            >
              <app-case-property-list
                *ngIf="case && tabSelected('properties')"
                [caseId]="case.id"
                [caseRefreshed]="caseRefreshed"
              ></app-case-property-list>
            </div>
            <div
              [class.active]="tabSelected('vehicles')"
              class="tab-pane"
              id="vehicles"
              role="tabpanel"
            >
              <app-case-vehicle-list
                *ngIf="case && tabSelected('vehicles')"
                [caseId]="case.id"
                [caseRefreshed]="caseRefreshed"
              ></app-case-vehicle-list>
            </div>
            <div
              [class.active]="tabSelected('assets')"
              class="tab-pane"
              id="assets"
              role="tabpanel"
            >
              <app-case-asset-list
                *ngIf="case && tabSelected('assets')"
                [caseId]="case.id"
                [caseRefreshed]="caseRefreshed"
              ></app-case-asset-list>
            </div>
            <div
              [class.active]="tabSelected('payment-schedule')"
              class="tab-pane"
              id="payment-schedule"
              role="tabpanel"
            >
              <app-case-payment-schedule-view
                (paymentScheduleCreated)="reloadCase()"
                (paymentScheduleUpdated)="reloadCase()"
                *ngIf="case && tabSelected('payment-schedule')"
                [caseId]="case.id"
                [caseRefreshed]="caseRefreshed"
                [paymentSchedule]="paymentSchedule"
              ></app-case-payment-schedule-view>
            </div>
            <div
              [class.active]="tabSelected('financial-summary')"
              class="tab-pane"
              id="financial-summary"
              role="tabpanel"
            >
              <app-case-financial-summary-view
                (totalDistributedValue)="setTotalDividends($event)"
                *ngIf="case && tabSelected('financial-summary')"
                [caseDetails]="case"
                [caseId]="case.id"
                [filterInvoices]="invoicesFiltered"
                [caseRefreshed]="caseRefreshed"
              ></app-case-financial-summary-view>
            </div>

            <div
              [class.active]="tabSelected('detailed-transactions')"
              class="tab-pane"
              id="detailed-transactions"
              role="tabpanel"
            >
              <app-case-detailed-transactions-view
                *ngIf="case && tabSelected('detailed-transactions')"
                [caseId]="case.id"
                [caseRefreshed]="caseRefreshed"
              ></app-case-detailed-transactions-view>
            </div>

            <div
              [class.active]="tabSelected('invoices')"
              class="tab-pane"
              id="invoices"
              role="tabpanel"
            >
              <app-case-invoice-list
                *ngIf="case && tabSelected('invoices')"
                [caseRefreshed]="caseRefreshed"
                [caseId]="case.id"
                [filter]="invoiceFilter"
              ></app-case-invoice-list>
            </div>

            <div
              [class.active]="tabSelected('creditor-distributions')"
              class="tab-pane"
              id="creditor-distributions"
              role="tabpanel"
            >
              <app-case-creditor-distributions
                [caseId]="case.id"
                [caseRefreshed]="caseRefreshed"
              ></app-case-creditor-distributions>
            </div>

            <div
              [class.active]="tabSelected('breaches')"
              class="tab-pane"
              id="breaches"
              role="tabpanel"
            >
              <app-case-breach-list
                [caseId]="case.id"
                [caseRefreshed]="caseRefreshed"
              ></app-case-breach-list>
            </div>

            <div
              [class.active]="tabSelected('complaints')"
              class="tab-pane"
              id="complaints"
              role="tabpanel"
            >
              <app-case-complaint-list
                [caseId]="case.id"
                [caseRefreshed]="caseRefreshed"
              ></app-case-complaint-list>
            </div>

            <div
              [class.active]="tabSelected('annuals')"
              class="tab-pane"
              id="annuals"
              role="tabpanel"
            >
              <app-case-annual-list
                [caseId]="case.id"
                [caseRefreshed]="caseRefreshed"
              ></app-case-annual-list>
            </div>

            <div
              [class.active]="tabSelected('chairmans-report')"
              class="tab-pane"
              id="chairmans-report"
              role="tabpanel"
            >
              <app-case-chairmans-report-view
                [caseId]="case.id"
              ></app-case-chairmans-report-view>
            </div>
          </div>
        </div>
      </section>
    </div>
  </div>

  <app-create-modal
    #createProtectionReferralModal
    (confirm)="createProtectionReferral($event)"
    [disabled]="!canReferForProtection"
    [itemCreated]="protectionReferralCreated"
    type="protection"
  >
    <div class="row">
      <div class="col-md-12">
        Clicking the button below will refer this case for Protection to ensure
        that a monthly premium of {{ protectionValue | toCurrency }} is taken.
      </div>
    </div>
  </app-create-modal>

  <app-create-modal
    #createPpiReferralModal
    (confirm)="createPpiReferral($event)"
    [disabled]="!canReferForPpi"
    [itemCreated]="ppiReferralCreated"
    type="ppi"
  >
    <div class="row">
      <div class="col-md-12">
        Clicking the button below will refer this case to Helpful Law for PPI /
        PBA review.
      </div>
    </div>
  </app-create-modal>

  <app-case-status-change
    #changeStatus
    (completed)="reloadCase()"
  ></app-case-status-change>

  <app-create-modal
    #changeUserModal
    (confirm)="changeUser($event)"
    [itemCreated]="userUpdated"
    type="user"
  >
    <app-user-select [model]="case" [required]="true"></app-user-select>
  </app-create-modal>

  <app-create-modal
    #createDocumentModal
    (confirm)="createDocument($event)"
    [disabled]="!newDocumentCreated"
    [itemCreated]="documentCreated"
    type="document"
  >
    <div class="form-group clearfix">
      <label class="col-sm-4 control-label" for="reference">Template</label>
      <div class="col-sm-8">
        <app-template-select
          [model]="createNewDocument"
          [required]="true"
        ></app-template-select>
      </div>
    </div>

    <div class="form-group clearfix">
      <label class="col-sm-4 control-label" for="reference">Client</label>
      <div class="col-sm-8">
        <app-shared-person-select
          *ngIf="case"
          [caseId]="case.id"
          [model]="createNewDocument"
          [required]="false"
          [singleOnly]="true"
        ></app-shared-person-select>
      </div>
    </div>

    <div class="form-group clearfix">
      <label class="col-sm-4 control-label" for="reference"
        >Delivery Method</label
      >
      <div class="col-sm-8">
        <app-delivery-method-select
          [model]="createNewDocument"
          [required]="true"
        ></app-delivery-method-select>
      </div>
    </div>
  </app-create-modal>

  <app-create-modal
    #createPackModal
    (confirm)="createPack($event)"
    [disabled]="!newPackCreated"
    [itemCreated]="packCreated"
    type="pack"
  >
    <div class="form-group clearfix">
      <label class="col-sm-4 control-label" for="reference">Pack</label>
      <div class="col-sm-8">
        <app-pack-select
          [model]="createNewPack"
          [required]="true"
        ></app-pack-select>
      </div>
    </div>

    <div class="form-group clearfix">
      <label class="col-sm-4 control-label" for="reference">Client</label>
      <div class="col-sm-8">
        <app-shared-person-select
          *ngIf="case"
          [caseId]="case.id"
          [model]="createNewPack"
          [required]="false"
          [singleOnly]="true"
        ></app-shared-person-select>
      </div>
    </div>

    <div class="form-group clearfix">
      <label class="col-sm-4 control-label" for="reference"
        >Delivery Method</label
      >
      <div class="col-sm-8">
        <app-delivery-method-select
          [model]="createNewPack"
          [required]="true"
        ></app-delivery-method-select>
      </div>
    </div>
  </app-create-modal>

  <!-- Start Introducer Commissions -->
  <app-create-modal
    #createIntroducerCommissionModal
    (confirm)="createIntroducerCommission($event)"
    [itemCreated]="introducerCommissionCreated"
    type="introducer commission"
  >
    <ng-container *ngIf="case?.introducerPaymentApprovedAt">
      <div *ngIf="case" class="form-group clearfix">
        <label class="col-sm-4 control-label" for="reference"
          >Introducer Reference</label
        >
        <div class="col-sm-8">
          {{ case.introducerReference }}
        </div>
      </div>

      <div *ngIf="case" class="form-group clearfix">
        <label class="col-sm-4 control-label" for="reference"
          >Commission Value</label
        >
        <div class="col-sm-8">
          {{ case.introducerValue / 100 | toCurrency }}
        </div>
      </div>

      <div *ngIf="case" class="form-group clearfix">
        <label class="col-sm-4 control-label" for="reference">Paid On</label>
        <div class="col-sm-8">
          {{ case.introducerPaymentDate | date: 'dd/MM/yyyy' }}
        </div>
      </div>

      <div *ngIf="case" class="form-group clearfix">
        <label class="col-sm-4 control-label" for="reference"
          >Approved On</label
        >
        <div class="col-sm-8">
          {{ case.introducerPaymentApprovedAt | date: 'dd/MM/yyyy' }}
        </div>
      </div>

      <div class="row clearfix" *ngIf="auth.hasPermission('user.clawback')">
        <div class="col-md-12 text-right">
          <button
            class="btn btn-danger"
            type="button"
            [disabled]="clawingBack"
            (click)="clawbackCase(case.id)"
          >
            Clawback Case
          </button>
        </div>
      </div>
    </ng-container>

    <ng-container *ngIf="!case?.introducerPaymentApprovedAt">
      <div *ngIf="case" class="form-group clearfix">
        <label class="col-sm-4 control-label" for="reference"
          >Introducer Reference</label
        >
        <div class="col-sm-8">
          <input
            [(ngModel)]="case.introducerReference"
            class="form-control"
            type="text"
            name="introducerReference"
          />
        </div>
      </div>

      <div *ngIf="case" class="form-group clearfix">
        <label class="col-sm-4 control-label" for="reference"
          >Disposable Income</label
        >
        <div class="col-sm-8">
          {{ totals.income - totals.expenditure | toCurrency }}
        </div>
      </div>

      <div *ngIf="case" class="form-group clearfix">
        <label class="col-sm-4 control-label" for="reference"
          >Estimated Commission</label
        >
        <div class="col-sm-8">
          {{
            (totals.income - totals.expenditure) *
              case.domain.commissionMultiplier | toCurrency
          }}
        </div>
      </div>

      <div *ngIf="case" class="form-group clearfix">
        <label class="col-sm-4 control-label" for="reference">Value</label>
        <div class="col-sm-8">
          <app-shared-currency
            *ngIf="case"
            [(ngModel)]="case.introducerValue"
            [required]="true"
            name="introducerValue"
          ></app-shared-currency>
        </div>
      </div>

      <div *ngIf="case" class="form-group clearfix">
        <label class="col-sm-4 control-label" for="reference">Due Date</label>
        <div class="col-sm-8">
          <app-shared-date
            *ngIf="case"
            [(ngModel)]="case.introducerPaymentDate"
            [allowFuture]="true"
            name="introducerPaymentDate"
          ></app-shared-date>
        </div>
      </div>

      <div *ngIf="case" class="form-group clearfix">
        <label class="col-sm-4 control-label" for="reference"
          >Introducer Paid</label
        >
        <div class="col-sm-8">
          <input
            (change)="toggleIntroducerPaid()"
            [checked]="introducerPaid"
            class="form-control"
            type="checkbox"
            name="introducerPaid"
          />
        </div>
      </div>
    </ng-container>
  </app-create-modal>
  <!-- End Introducer Commissions -->
</ng-container>
