import AddressType from '../../../../chief-types/src/AddressType';
import { Country } from '../../countries/country';

export class Address {
  id?: string;
  address1: string;
  address2: string;
  address3: string;
  town: string;
  county: string;
  postalCode: string;
  country: Country;
  addressType?: AddressType;
  from?: string;
  till?: string;
}
