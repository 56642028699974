import { Component, Input, OnInit } from '@angular/core';
import { Person } from '../person';
import { CasePersonService } from '../case-person.service';
import { SelectComparisons } from '../../../shared/pipes/select.comparisons';

@Component({
  selector: 'app-shared-person-select',
  templateUrl: 'select.component.html',
})
export class SharedPersonSelectComponent extends SelectComparisons
  implements OnInit {
  @Input() caseId: string;

  @Input() model: any;

  @Input() required: boolean = false;

  @Input() singleOnly: boolean;

  public isLoading: boolean = true;

  public people: Person[];

  constructor(private readonly casePersonService: CasePersonService) {
    super();
    this.casePersonService.loading.subscribe(isLoading => {
      this.isLoading = isLoading;
    });
  }

  public ngOnInit(): void {
    this.casePersonService
      .listCasePeople(1, 10000, { parameters: { id: this.caseId } })
      .subscribe((people: Person[]) => {
        this.people = people;
        if (people.length === 1) {
          this.model.person = people[0];
        }
      });
  }
}
