import { RouterModule, Routes } from '@angular/router';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule } from '@angular/common/http';
import { CreditListComponent } from './list/list.component';
import { AuthGuard } from '../auth/auth.guard';
import { CreditService } from './credit.service';
import { SharedModule } from '../shared/shared.module';
import { CreditCreateComponent } from './create/create.component';
import { CreditFormComponent } from './form/form.component';
import { CreditEditComponent } from './edit/edit.component';

const routes: Routes = [
  { path: 'credits', component: CreditListComponent, canActivate: [AuthGuard] },
  {
    path: 'credits/create',
    component: CreditCreateComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'credits/:id/edit',
    component: CreditEditComponent,
    canActivate: [AuthGuard],
  },
];

@NgModule({
  declarations: [
    CreditListComponent,
    CreditCreateComponent,
    CreditFormComponent,
    CreditEditComponent,
  ],
  imports: [
    BrowserModule,
    RouterModule,
    FormsModule,
    HttpClientModule,
    SharedModule,
    RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' }),
  ],
  exports: [RouterModule],
  providers: [AuthGuard, CreditService],
})
export class CreditRoutingModule {}
