<div class="form-group clearfix">
  <label for="reference" class="col-sm-4 control-label">Task Type</label>
  <div class="col-sm-8">
    <app-task-select [model]="model" [required]="true"></app-task-select>
  </div>
</div>

<div class="form-group clearfix">
  <label for="reference" class="col-sm-4 control-label">User</label>
  <div class="col-sm-8">
    <app-user-select [model]="model" [required]="false"></app-user-select>
  </div>
</div>

<div class="form-group clearfix">
  <label for="reference" class="col-sm-4 control-label">Department</label>
  <div class="col-sm-8">
    <app-department-select
      [model]="model"
      [required]="false"
    ></app-department-select>
  </div>
</div>

<div class="form-group clearfix">
  <label for="reference" class="col-sm-4 control-label">Date</label>
  <div class="col-sm-8">
    <app-shared-date
      [(ngModel)]="model.actionAt"
      [hasDate]="true"
      [hasTime]="true"
      [allowPast]="false"
      [allowFuture]="true"
      [required]="true"
    ></app-shared-date>
  </div>
</div>

<div class="form-group clearfix">
  <label for="reference" class="col-sm-4 control-label">Note</label>
  <div class="col-sm-8">
    <quill-editor name="note" [(ngModel)]="model.content"></quill-editor>
  </div>
</div>
