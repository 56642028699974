import { Injectable } from '@angular/core';
import { Asset } from './asset';
import { Observable } from 'rxjs';

import { HttpRequestService } from '../shared/http/http-request.service';

@Injectable()
export class AssetService extends HttpRequestService {
  /**
   * The endpoint that we call to get the asset information.
   *
   * @type {string}
   */
  private readonly endpoint: string = 'assets';

  /**
   * Brings back a list of all accessible assets.
   *
   * @param {number} page The page number we are currently on.
   * @param {number} limit The number of assets to display per page.
   * @returns {Observable<Asset[]>}
   */
  public listAssets(page?: number, limit?: number): Observable<Asset[]> {
    return this.list(this.endpoint, page, undefined, limit);
  }

  /**
   * Finds details of the asset from the given ID.
   *
   * @param {string} assetId The ID of the asset we require details for.
   * @returns {Observable<Asset>}
   */
  public findAsset(assetId: string): Observable<Asset> {
    return this.findById(this.endpoint, assetId);
  }

  /**
   * Creates a new asset from the given Asset object.
   *
   * @param {Asset} newAsset The details of the asset to be created.
   * @returns {Observable<Asset | boolean>}
   */
  public createAsset(newAsset: Asset): Observable<Asset | boolean> {
    return this.create(this.endpoint, newAsset);
  }

  /**
   * Updates a asset with the given asset object for the given asset ID.
   *
   * @param {string} assetId The ID of the asset needing updating.
   * @param {Asset} newDetails The new asset object to update with.
   * @returns {Observable<Asset | boolean>}
   */
  public updateAsset(
    assetId: string,
    newDetails: Asset,
  ): Observable<Asset | boolean> {
    return this.update(this.endpoint, assetId, newDetails);
  }

  /**
   * Deletes a asset based on the given asset ID.
   *
   * @param {string} assetId The ID of the asset needing deleting.
   * @returns {Observable<boolean>}
   */
  public deleteAsset(assetId: string): Observable<boolean> {
    return this.delete(this.endpoint, assetId);
  }
}
