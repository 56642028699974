<div class="alert alert-success" role="alert" *ngIf="!(activeModifications.length > 0 || customModifications.length > 0)">
  <app-case-modification-modal [caseId]="caseId" (modificationsSaved)="modificationsUpdated"></app-case-modification-modal>
  No modifications for this case
</div>

<div class="alert alert-warning" role="alert" *ngIf="activeModifications.length > 0 || customModifications.length > 0">
  <app-case-modification-modal [caseId]="caseId" (modificationsSaved)="modificationsUpdated"></app-case-modification-modal>
  <strong *ngIf="activeModifications.length > 0">There are {{ activeModifications.length }} active modifications on this case. <span *ngIf="overrideModifications.length > 0">The following {{ overrideModifications.length }} are overridden.</span></strong>
  <ul *ngIf="overrideModifications.length > 0">
    <li *ngFor="let modification of overrideModifications">
      {{ modification.name }} {{ modification.portfolio ? "(" + modification.portfolio.name + ")" : '' }} <sup><i class="fa fa-info-circle" container="body" tooltip="{{ modification.description }}"></i></sup>
    </li>
  </ul>

  <p *ngIf="activeModifications.length > 0" style="margin-top: 10px;"></p>

  <strong *ngIf="customModifications.length > 0">The following custom modifications are active on this case</strong>
  <ul *ngIf="customModifications.length > 0">
    <li *ngFor="let modification of customModifications">
      {{ modification.text }}
    </li>
  </ul>
</div>
