import { RouterModule, Routes } from '@angular/router';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule } from '@angular/common/http';
import { StatusListComponent } from './list/list.component';
import { AuthGuard } from '../auth/auth.guard';
import { StatusService } from './status.service';
import { SharedModule } from '../shared/shared.module';
import { StatusCreateComponent } from './create/create.component';
import { StatusFormComponent } from './form/form.component';
import { StatusEditComponent } from './edit/edit.component';

const routes: Routes = [
  {
    path: 'statuses',
    component: StatusListComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'statuses/create',
    component: StatusCreateComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'statuses/:id/edit',
    component: StatusEditComponent,
    canActivate: [AuthGuard],
  },
];

@NgModule({
  declarations: [
    StatusListComponent,
    StatusCreateComponent,
    StatusFormComponent,
    StatusEditComponent,
  ],
  imports: [
    BrowserModule,
    RouterModule,
    FormsModule,
    HttpClientModule,
    SharedModule,
    RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' }),
  ],
  exports: [RouterModule],
  providers: [AuthGuard, StatusService],
})
export class StatusRoutingModule {}
