import { Injectable } from '@angular/core';

import { HttpOptions } from '../../shared/http/http-options';
import { Observable } from 'rxjs';
import { HttpRequestService } from '../../shared/http/http-request.service';
import { CaseCreditor } from '../creditors/case-creditor';
import { FeeComparison } from '../fee-comparison/fee-comparison';
import { Modification } from '../../settings/modifications/modification';

@Injectable()
export class CaseModificationService extends HttpRequestService {
  private readonly endpoint: string = 'cases/:id/modifications';

  public listModifications(
    page?: number,
    limit?: number,
    options?: HttpOptions,
  ): Observable<Modification[]> {
    return this.list(this.endpoint, page, undefined, limit, options);
  }

  public saveModifications(
    caseId: string,
    modifications: Modification[],
    customModifications: any[],
    options,
  ): Observable<boolean> {
    return this.create(this.endpoint, {
      modifications,
      customModifications,
    }, options);
  }
}
