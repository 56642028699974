import { ActivatedRoute, Router } from '@angular/router';

import { Component } from '@angular/core';
import { NotificationService } from '../../shared/notification.service';
import { Substatus } from '../substatus';
import { SubstatusService } from '../substatus.service';

@Component({
  selector: 'app-substatus-edit',
  templateUrl: 'edit.component.html',
})
export class SubstatusEditComponent {
  /**
   * Details of the substatus being edited.
   */
  public substatus: Substatus;

  /**
   * Are we currently performing a loading action?
   *
   * @type {boolean}
   */
  public isLoading: boolean = false;

  /**
   * Load up all our required services and subscribe to every observable we need.
   *
   * @param {SubstatusService} substatusService
   * @param {NotificationService} notificationService
   * @param {Router} router
   * @param {ActivatedRoute} route
   */
  constructor(
    private substatusService: SubstatusService,
    private notificationService: NotificationService,
    private router: Router,
    private route: ActivatedRoute,
  ) {
    this.substatusService.loading.subscribe(
      (result) => (this.isLoading = result),
    );
    this.route.params.subscribe((params) => this.loadSubstatus(params.id));
  }

  /**
   * Perform the edit action which send the edited substatus to the backend.
   */
  public edit() {
    const editSubstatus = this.substatusService
      .updateSubstatus(this.substatus.id, this.substatus)
      .subscribe(
        (result: Substatus) => {
          this.notificationService.success(
            `Substatus Updated`,
            `The substatus ${result.name} been updated with the specified changes.`,
            5,
          );
          this.substatus = new Substatus();
          editSubstatus.unsubscribe();
          this.router.navigate(['/substatuses']);
        },
        (err) => {
          this.notificationService.error(
            `Substatus Not Found`,
            err.error.message,
            10,
          );
          this.router.navigate(['/substatuses']);
        },
      );
  }

  /**
   * Loads the requested substatus so we can display it and let the user edit the fields.
   *
   * @param {string} id
   */
  private loadSubstatus(id: string) {
    this.substatusService.findSubstatus(id).subscribe(
      (result) => {
        this.substatus = result;
      },
      (err) => {
        this.notificationService.error(
          `Substatus Not Found`,
          err.error.message,
          10,
        );
        this.router.navigate(['/substatuses']);
      },
    );
  }
}
