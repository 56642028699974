import {
  Component,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import { Address } from '../../../../shared/address/address';
import { NotificationService } from '../../../../shared/notification.service';
import { CasePersonAddressService } from '../case-person-address-service';

@Component({
  selector: 'app-case-person-addresses-create',
  templateUrl: 'create.component.html',
})
export class CasePersonAddressCreateComponent {
  @ViewChild('staticModal') staticModal;

  @Output() completed = new EventEmitter<boolean>();

  disabled: boolean = false;

  address: Address;

  @Input() personId: string;
  @Input() caseId: string;

  constructor(
    protected readonly casePersonAddressService: CasePersonAddressService,
    protected readonly notificationService: NotificationService,
  ) {}

  /**
   * Initialise the component, creating a new blank address.
   */
  public ngOnInit(): void {
    this.address = new Address();
  }

  public open() {
    this.address = new Address();
    this.staticModal.show();
  }

  public close() {
    this.workCompleted();
  }

  public confirmCreate($event) {
    $event.preventDefault();
    this.disabled = true;
    const createAddress = this.casePersonAddressService
      .createAddress(this.address, this.caseId, this.personId)
      .subscribe((result: Address) => {
        this.notificationService.success(
          `Address Added`,
          `The address has been added with the specified changes.`,
          5,
        );
        createAddress.unsubscribe();
        this.workCompleted();
        this.completed.next(true);
      }, (err) => {
        this.notificationService.error(
          `Failed to add address`,
          err.error.message,
          10,
        );
        createAddress.unsubscribe();
        this.workCompleted();
        this.completed.next(true);
      });
  }

  private workCompleted() {
    this.address = undefined;
    this.disabled = false;
    this.staticModal.hide();
  }
}
