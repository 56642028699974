<form class="form-horizontal" (ngSubmit)="handleSubmit()" ngNativeValidate>
  <div class="form-group">
    <label class="col-sm-2 control-label" for="name">Name</label>
    <div class="col-sm-10">
      <input
        [(ngModel)]="template.name"
        class="form-control"
        id="name"
        name="name"
        placeholder="Template Name"
        required
        type="text"
      />
    </div>
  </div>

  <div class="form-group">
    <label for="letterhead" class="col-sm-2 control-label">Letterhead</label>
    <div class="col-sm-10">
      <app-letterhead-select
        [model]="template"
        [required]="false"
      ></app-letterhead-select>
    </div>
  </div>

  <div class="form-group">
    <label for="domain" class="col-sm-2 control-label">Domain</label>
    <div class="col-sm-10">
      <app-domain-select
        [model]="template"
        [required]="false"
      ></app-domain-select>
    </div>
  </div>

  <div class="form-group">
    <label for="document" class="col-sm-2 control-label">Document Type</label>
    <div class="col-sm-10">
      <app-document-select
        [model]="template"
        [required]="false"
      ></app-document-select>
    </div>
  </div>

  <div class="form-group">
    <label for="content" class="col-sm-2 control-label">Template Type</label>
    <div class="col-sm-10">
      <select
        name="template-type"
        [(ngModel)]="template.templateType"
        class="form-control"
      >
        <option value="html">HTML</option>
        <option value="doc">Word Document</option>
      </select>
    </div>
  </div>

  <div
    class="form-group"
    *ngIf="!template.templateType || template.templateType === 'html'"
  >
    <label for="content" class="col-sm-2 control-label">Contents</label>
    <div class="col-sm-10">
      <textarea
        name="content"
        rows="50"
        [(ngModel)]="template.content"
        class="form-control"
      ></textarea>
    </div>
  </div>

  <div
    class="form-group"
    *ngIf="template.templateType && template.templateType === 'doc'"
  >
    <label for="content" class="col-sm-2 control-label">Template File</label>
    <div class="col-sm-10">
      <div style="padding: 8px 0 20px 0" *ngIf="template.templateUrl">
        <a
          href="{{
            template.templateUrl
          }}"
          target="_blank"
          >Download Current Template</a
        >
      </div>
      <div>
        <label for="fileButton" class="col-sm-12" style="padding: 0px;">
          <div
            class="well my-drop-zone"
            ng2FileDrop
            [uploader]="uploader"
            (fileOver)="fileOverBase($event)"
            [ngClass]="{ 'nv-file-over': hasBaseDropZoneOver }"
          >
            <div *ngFor="let item of uploader.queue">
              <strong>{{ item?.file?.name }}</strong>
            </div>

            <div *ngIf="uploader?.queue?.length < 1">
              Drag a file (or click here) to choose a file to upload.
            </div>

            <input
              name="file"
              type="file"
              id="fileButton"
              style="display: none"
              ng2FileSelect
              [uploader]="uploader"
            />
          </div>

          <div class="progress" style="margin-bottom: 0">
            <div
              class="progress-bar"
              role="progressbar"
              [ngStyle]="{ width: uploader.progress + '%' }"
            ></div>
          </div>
        </label>
      </div>
    </div>
  </div>

  <div class="form-group">
    <label for="type" class="col-sm-2 control-label">Send Type</label>
    <div class="col-sm-10">
      <select
        class="form-control"
        name="type"
        id="type"
        [(ngModel)]="template.type"
        [required]="true"
      >
        <option value="client">Client</option>
        <option value="creditor">All Creditors</option>
        <option value="in-creditor">Included Creditor</option>
        <option value="ex-creditor">Excluded Creditor</option>
        <option value="other">Other</option>
      </select>
    </div>
  </div>

  <div class="form-group">
    <label for="quantity" class="col-sm-2 control-label">Send Quantity</label>
    <div class="col-sm-10">
      <input
        type="number"
        name="quantity"
        id="quantity"
        class="form-control"
        [(ngModel)]="template.quantity"
      />
    </div>
  </div>

  <div class="form-group">
    <div class="col-sm-offset-2 col-sm-10 text-right">
      <button type="submit" class="btn btn-success" [disabled]="disabled">
        Save
      </button>
    </div>
  </div>
</form>
