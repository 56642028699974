import { User } from '../../users/user';
import { Case } from '../case';
import { Document } from '../../documents/document';
import { Creditor } from '../../creditors/creditor';
import { DeliveryMethod } from '../../delivery-methods/delivery-method';
import { Person } from '../people/person';

export class CaseDocument {
  public id?: string;
  public url: string;
  public case?: Case;
  public user?: User;
  public document?: Document;
  public creditor?: Creditor;
  public deliveryMethod: DeliveryMethod;
  public createdAt?: string;
  public approvedAt?: string;
  public approvedBy?: User;
  public esignComplete: boolean;
  public esignSignature: string;
  public esignDate: string;
  public esignSecurity: string;
  public person?: Person;
}
