import { Component, Input, OnInit } from '@angular/core';
import { TitleService } from '../title.service';
import { Title } from '../title';
import { NotificationService } from '../../shared/notification.service';
import { SelectComparisons } from '../../shared/pipes/select.comparisons';

@Component({
  selector: 'app-title-select',
  templateUrl: 'select.component.html',
})
export class TitleSelectComponent extends SelectComparisons implements OnInit {
  /**
   * The model that we will be adding the title to.
   */
  @Input() model: any;

  /**
   * Do we require this field in the main form it is
   * included in?
   */
  @Input() required: boolean;

  /**
   * A list of all the titles currently loaded.
   *
   * @type {Title[]}
   */
  public titles: Title[] = [];

  /**
   * Are we currently performing a loading action?
   *
   * @type {boolean}
   */
  public isLoading: boolean = true;

  /**
   * Load up all our required services and subscribe to every observable we need.
   *
   * @param {TitleService} titleService
   * @param {NotificationService} notificationService
   */
  constructor(
    private titleService: TitleService,
    private notificationService: NotificationService,
  ) {
    super();
    this.titleService.loading.subscribe(isLoading => {
      this.isLoading = isLoading;
    });
  }

  /**
   * When the component is initialised we set it to load
   * page one of the title list.
   */
  public ngOnInit(): void {
    this.loadTitles(1, 10000);
  }

  /**
   * Loads a list of titles using the given per page limit.
   *
   * @param {number} page The current page we require.
   * @param {number} limit The number of results per page.
   */
  public loadTitles(page?: number, limit?: number): void {
    const listTitles = this.titleService
      .listTitles(page, limit)
      .subscribe((titles: Title[]) => {
        this.titles = titles;
        listTitles.unsubscribe();
      });
  }
}
