import {Component, EventEmitter, Output, ViewChild} from '@angular/core';

import {CaseVehicle} from '../case-vehicle';
import {CaseVehicleService} from '../case-vehicle.service';

@Component({
  selector: 'app-case-vehicle-create',
  templateUrl: 'create.component.html',
})
export class CaseVehicleCreateComponent {
  @ViewChild('staticModal') staticModal;

  @Output() completed = new EventEmitter<boolean>();

  public disabled: boolean = false;

  public model: CaseVehicle = new CaseVehicle();

  private givenCaseId: string;

  constructor(private readonly caseVehicleService: CaseVehicleService) {}

  public get caseId(): string {
    return this.givenCaseId;
  }

  public open(caseId: string): void {
    this.givenCaseId = caseId;
    this.staticModal.show();
  }

  public close() {
    this.workCompleted();
  }

  public confirmCreate($event) {
    $event.preventDefault();
    this.disabled = true;
    this.caseVehicleService
      .createCaseVehicle(this.model, { parameters: { id: this.caseId } })
      .subscribe(() => {
        this.workCompleted();
      });
  }

  private workCompleted() {
    this.model = new CaseVehicle();
    this.disabled = false;
    this.staticModal.hide();
    this.completed.next(true);
  }
}
