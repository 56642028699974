import { Component, OnInit } from '@angular/core';
import { ApprovalService } from '../approval.service';
import { NotificationService } from '../../shared/notification.service';
import { Case } from '../../cases/case';
import { AuthService } from '../../auth/auth.service';

@Component({
  selector: 'app-approval-list',
  templateUrl: 'list.component.html',
  styleUrls: [],
})
export class ApprovalListComponent implements OnInit {
  /**
   * A list of all the approvals currently loaded.
   *
   * @type {Case[]}
   */
  public approvals: Case[] = [];

  /**
   * Are we currently performing a loading action?
   *
   * @type {boolean}
   */
  public isLoading: boolean = true;

  /**
   * The total number of pages in this list of approvals.
   *
   * @type {number}
   */
  public totalPages: number = 1;

  /**
   * The current page of approvals that we are viewing.
   *
   * @type {number}
   */
  public currentPage: number = 1;

  /**
   * The total number of approvals that can be viewed in the system.
   *
   * @type {number}
   */
  public totalResults: number = 0;

  /**
   * Load up all our required services and subscribe to every observable we need.
   *
   * @param {ApprovalService} approvalService
   * @param {NotificationService} notificationService
   */
  constructor(
    private approvalService: ApprovalService,
    private notificationService: NotificationService,
    public readonly authService: AuthService,
  ) {
    this.approvalService.loading.subscribe(isLoading => {
      this.isLoading = isLoading;
    });
    this.approvalService.totalPages.subscribe(totalPages => {
      this.totalPages = totalPages;
    });
    this.approvalService.currentPage.subscribe(currentPage => {
      this.currentPage = currentPage;
    });
    this.approvalService.totalResults.subscribe(totalResults => {
      this.totalResults = totalResults;
    });
  }

  /**
   * When the component is initialised we set it to load
   * page one of the approval list.
   */
  public ngOnInit(): void {
    this.loadApprovals(1);
  }

  /**
   * When we receive a page change from the pagination
   * component we load the appropriate page for the
   * approval list.
   *
   * @param {number} pageNumber
   */
  public onPageChanged(pageNumber: number): void {
    this.loadApprovals(pageNumber);
  }

  /**
   * Loads a list of approvals using the given per page limit.
   *
   * @param {number} page The current page we require.
   * @param {number} limit The number of results per page.
   */
  public loadApprovals(page?: number, limit?: number): void {
    const listApprovals = this.approvalService
      .listApprovals(page, limit)
      .subscribe((approvals: Case[]) => {
        this.approvals = approvals;
        listApprovals.unsubscribe();
      });
  }

  public approvePayment(caseId: string): void {
    const approvePayment = this.approvalService
      .approvePayment(caseId)
      .subscribe(result => {
        this.onPageChanged(1);
        approvePayment.unsubscribe();
      });
  }
}
