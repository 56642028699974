import {Component, OnInit} from '@angular/core';
import {CreditorStatusService} from '../creditor-status.service';
import {CreditorStatus} from '../creditor-status';
import {Router} from '@angular/router';
import {NotificationService} from '../../shared/notification.service';

@Component({
    selector: 'app-creditor-status-create',
    templateUrl: 'create.component.html',
})
export class CreditorStatusCreateComponent implements OnInit {
    /**
     * Details of the status being created.
     */
    public status: CreditorStatus;

    /**
     * Are we currently performing a loading action?
     *
     * @type {boolean}
     */
    public isLoading: boolean = false;

    /**
     * Load up all our required services and subscribe to every observable we need.
     *
     * @param {CreditorStatusService} statusService
     * @param {NotificationService} notificationService
     * @param {Router} router
     */
    constructor(
        private statusService: CreditorStatusService,
        private notificationService: NotificationService,
        private router: Router,
    ) {
        this.statusService.loading.subscribe(result => (this.isLoading = result));
    }

    /**
     * Initialise the component, creating a new blank status if required.
     */
    public ngOnInit(): void {
        if (!this.status) {
            this.status = new CreditorStatus();
        }
    }

    /**
     * Perform the create action which adds the new status into the backend.
     */
    public create() {
        const createStatus = this.statusService.createStatus(this.status).subscribe(
            (result: CreditorStatus) => {
                this.notificationService.success(
                    `Status Created`,
                    `New status ${result.name} has been created with ID of ${result.id}.`,
                    5,
                );
                this.status = new CreditorStatus();
                createStatus.unsubscribe();
                this.router.navigate(['/creditor-statuses']);
            },
            err => {
                this.notificationService.error(
                    `Could not create Status`,
                    err.error.message,
                    10,
                );
            },
        );
    }
}
