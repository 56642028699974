import { Case } from '../case';
import { Credit } from '../../credits/credit';
import { Creditor } from '../../creditors/creditor';
import { CreditorStatus } from '../../creditor-statuses/creditor-status';
import { Person } from '../people/person';

export class CaseCreditor {
  public id?: string;
  public case?: Case;
  public creditor?: Creditor;
  public reference?: string;
  public credit?: Credit;
  public owner?: string;
  public person?: Person;
  public value: number = 0;
  public actualValue: number = 0;
  public admittedValue: number = 0;
  public status: string;
  public secured: boolean;
  public excluded: boolean;
  public note?: string;
  public createdAt?: string;
  public securedOn?: string;
  public debtPaid?: boolean;
  public votingStatus?: string;
  creditorStatus?: CreditorStatus;
  public paidValue?: number;
}
