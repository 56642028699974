import { RouterModule, Routes } from '@angular/router';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule } from '@angular/common/http';
import { ExpenditureListComponent } from './list/list.component';
import { AuthGuard } from '../auth/auth.guard';
import { ExpenditureService } from './expenditure.service';
import { SharedModule } from '../shared/shared.module';
import { ExpenditureCreateComponent } from './create/create.component';
import { ExpenditureFormComponent } from './form/form.component';
import { ExpenditureEditComponent } from './edit/edit.component';

const routes: Routes = [
  {
    path: 'expenditures',
    component: ExpenditureListComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'expenditures/create',
    component: ExpenditureCreateComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'expenditures/:id/edit',
    component: ExpenditureEditComponent,
    canActivate: [AuthGuard],
  },
];

@NgModule({
  declarations: [
    ExpenditureListComponent,
    ExpenditureCreateComponent,
    ExpenditureFormComponent,
    ExpenditureEditComponent,
  ],
  imports: [
    BrowserModule,
    RouterModule,
    FormsModule,
    HttpClientModule,
    SharedModule,
    RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' }),
  ],
  exports: [RouterModule],
  providers: [AuthGuard, ExpenditureService],
})
export class ExpenditureRoutingModule {}
