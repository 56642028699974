import { Address } from '../../shared/address/address';
import { Case } from '../case';
import { CaseCreditor } from '../creditors/case-creditor';
import { Person } from '../people/person';

export class CaseProperty extends Address {
  public id?: string;
  public case?: Case;
  public owner?: string;
  public person?: Person;
  public value: number;
  public mortgageBalance: number;
  public mortgageLengthYears: number;
  public mortgageLengthMonths: number;
  public note?: string;
  public createdAt?: string;
  public excluded: boolean;
  public caseCreditor: CaseCreditor;
  public valuationFrom?: string;
  public mortgageType?: string;
  public jointlyOwnedWith?: string;
  public receivedValue?: number = 0;
}
